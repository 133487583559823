import { v4 as uuid } from 'uuid';
import { StudyPlanDay, StudyPlanItem } from '../../types';

export function generateStudyPlanItem(
  day: StudyPlanDay,
  title: string,
  subItems: string[] = [],
): StudyPlanItem {
  return {
    title,
    id: uuid(),
    createdAt: new Date(),
    parentType: 'day',
    parent: day.id,
    done: false,
    donePreviously: false,
    date: day.date,
    subItems,
  };
}
