import React, { useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spaced,
  Typography,
} from '@21st-night/ui';
import { Card } from '@21st-night/cards-web';
import { deserializeDocument, RichText } from '@21st-night/editor-web';
import {
  ReviewRating,
  ReviewView,
  ReviewRatingButton,
} from '@21st-night/review-web';

export interface DeckOverdueCardDialogProps {
  open: boolean;
  onClose: () => void;
  onRate: (event: React.MouseEvent, rating: ReviewRating) => void;
  card: Card;
  imageUrl: string;
}

export const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 700,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'normal',
  },
  centerActions: {
    flex: 1,
  },
  sideAction: {
    width: 134,
  },
}));

export const DeckOverdueCardDialog: React.FC<DeckOverdueCardDialogProps> = ({
  open,
  onClose,
  card,
  imageUrl,
  onRate,
  ...other
}) => {
  const classes = useStyles();
  const [view, setView] = useState<ReviewView>('question');

  function showAnswer() {
    setView('answer');
  }

  function showExplanation() {
    setView('answer-explanation');
  }

  function showQuestion() {
    setView('question');
  }

  function showSummary() {
    setView('question-summary');
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      {...other}
    >
      <DialogTitle disableTypography>
        <Typography gutterBottom variant="h2">
          Hey! Remember me?
        </Typography>
        <Typography color="textSecondary">
          You have some long overdue cards in your deck. Do you remember the
          answer to this one?
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {['question', 'question-summary'].includes(view) && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.question)}
          />
        )}
        {['answer', 'answer-explanation'].includes(view) && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.answer)}
          />
        )}
        {view === 'question-summary' && card.summary && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.summary)}
          />
        )}
        {view === 'answer-explanation' && card.explanation && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.explanation)}
          />
        )}
        {view === 'question' && card.summary && (
          <Button onClick={showSummary}>Show summary</Button>
        )}
        {view === 'answer' && card.explanation && (
          <Button onClick={showExplanation}>Reveal explanation</Button>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Spaced align="start" className={classes.sideAction}>
          {['answer', 'answer-explanation'].includes(view) && (
            <Button size="small" color="primary" onClick={showQuestion}>
              Show question
            </Button>
          )}
        </Spaced>
        <Spaced centered spacing={2} className={classes.centerActions}>
          {['question', 'question-summary'].includes(view) && (
            <Button variant="contained" color="primary" onClick={showAnswer}>
              Show answer
            </Button>
          )}
          {['answer', 'answer-explanation'].includes(view) && (
            <>
              <ReviewRatingButton
                rating="wrong"
                size="small"
                onClick={onRate}
              />
              <ReviewRatingButton
                rating="more-work"
                size="small"
                onClick={onRate}
              />
              <ReviewRatingButton
                rating="correct"
                size="small"
                onClick={onRate}
              />
            </>
          )}
        </Spaced>
        <Spaced align="end" className={classes.sideAction}>
          <Button size="small" onClick={onClose}>
            Skip
          </Button>
        </Spaced>
      </DialogActions>
    </Dialog>
  );
};
