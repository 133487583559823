/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { DB, Storage, Functions } from '@21st-night/utils-web';
import {
  BraindropEditorPlugin,
  BraindropEditor,
  Transforms,
  isBlockAboveEmpty,
} from '@braindrop-editor/core';
import { addWebViewMessageListener } from '@21st-night/web-view';
import { ElementLinkedImageLegacy } from './ElementLinkedImageLegacy';
import { ElementImage } from './ElementImage';
import {
  TabsValue,
  ImageElement,
  EditorWithImagePlugin,
} from './ImagePlugins.types';
import { handleNativeEditorMessage } from './api';
import { ElementMobileImage, MobileImageElement } from './ElementImageMobile';

export interface ImagePluginOptions {
  mobile?: false;
  imageUrl: string;
  functions: Functions;
  storage: Storage;
  db: DB;
}
export interface MobileImagePluginOptions {
  mobile: true;
  imageUrl: string;
}

const ImagePlugin = (
  options: ImagePluginOptions | MobileImagePluginOptions,
) => (
  baseEditor: BraindropEditor,
): BraindropEditorPlugin<ImageElement | MobileImageElement> => {
  const editor = baseEditor as EditorWithImagePlugin;
  const fullOptions = options as ImagePluginOptions;
  const { mobile, imageUrl } = options;

  if (mobile) {
    addWebViewMessageListener(message =>
      handleNativeEditorMessage(editor, message),
    );
  }

  const insertImage = (type?: TabsValue) => {
    Transforms.insertNodes(
      editor,
      editor.generateElement('image', {
        initialTab: type || 'upload',
      }),
    );
  };

  const turnIntoImage = (type?: TabsValue) => {
    Transforms.unsetNodes(editor, [
      'file',
      'url',
      'originalUrl',
      'crop',
      'dimensions',
    ]);
    Transforms.setNodes(editor, {
      type: 'image',
      initialTab: type || 'upload',
    } as ImageElement);
  };

  editor.insertImage = insertImage;
  editor.turnIntoImage = turnIntoImage;

  const { renderEditable } = editor;

  editor.renderEditable = props =>
    renderEditable({
      ...props,
      // Handle dropped images
      onDrop: event => {
        event.preventDefault();
        Array.from(event.dataTransfer.files).forEach(file => {
          if (file.type.startsWith('image/')) {
            if (isBlockAboveEmpty(editor)) {
              Transforms.setNodes(editor, {
                uploadFile: file,
                type: 'image',
              } as Partial<ImageElement>);
            } else {
              Transforms.insertNodes(
                editor,
                editor.generateElement('image', {
                  uploadFile: file,
                }),
              );
            }
          }
        });

        if (props.onDrop) {
          props.onDrop(event);
        }
      },
      // Handle pasted images
      onPaste: event => {
        Array.from(event.clipboardData.files).forEach(file => {
          if (file.type.startsWith('image/')) {
            if (isBlockAboveEmpty(editor)) {
              Transforms.setNodes(editor, {
                uploadFile: file,
                type: 'image',
              } as Partial<ImageElement>);
            } else {
              Transforms.insertNodes(
                editor,
                editor.generateElement('image', { uploadFile: file }),
              );
            }
          }
        });

        if (props.onPaste) {
          props.onPaste(event);
        }
      },
    });

  return {
    elementDeserializers: {
      IMG: el => {
        const image = el as HTMLImageElement;
        if (image.src) {
          return { type: 'image', uploadUrl: image.src };
        }

        return undefined;
      },
    },
    elements: [
      {
        // @ts-ignore
        insert: insertImage,
        // @ts-ignore
        turnInto: turnIntoImage,
        isVoid: true,
        component: props =>
          options.mobile ? (
            <ElementMobileImage
              {...props}
              element={props.element as MobileImageElement}
              imageUrl={imageUrl}
            />
          ) : (
            <ElementImage
              {...props}
              element={props.element as ImageElement}
              imageUrl={imageUrl}
              storage={fullOptions.storage}
              db={fullOptions.db}
              functions={fullOptions.functions}
            />
          ),
        type: 'image',
        hotkeys: ['mod+alt+6'],
      },
      {
        isVoid: true,
        component: props => (
          <ElementLinkedImageLegacy
            {...props}
            element={props.element as ImageElement}
          />
        ),
        type: 'image-linked-legacy',
      },
    ],
  };
};

export default ImagePlugin;
