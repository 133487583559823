import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { InputBase, InputBaseProps } from '@material-ui/core';

export type TextInputProps = InputBaseProps;

export const useStyles = makeStyles(theme => {
  const darkMode = theme.palette.type === 'dark';

  return createStyles({
    root: {
      transition: theme.transitions.create(['border'], {
        duration: theme.transitions.duration.short,
      }),
      padding: theme.spacing(0, 0, 0, 1),
      border: `1px solid ${
        darkMode ? theme.palette.grey[400] : theme.palette.grey[300]
      }`,
      borderRadius: 3,
      background: darkMode ? theme.palette.grey[800] : theme.palette.grey[50],
      '&$disabled': {
        background: darkMode
          ? theme.palette.grey[900]
          : theme.palette.grey[100],
        border: `1px solid ${
          darkMode ? theme.palette.grey[300] : theme.palette.grey[200]
        }`,
      },
      '&$error': {
        background: darkMode ? '#B71C1C' : '#FFCDD2',
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    focused: {
      border: `1px solid ${
        darkMode ? theme.palette.primary.main : theme.palette.primary.light
      }`,
    },
    disabled: {},
    error: {},
    input: {
      padding: theme.spacing(1, 0),
    },
    adornedEnd: {
      paddingRight: theme.spacing(0.5),
    },
  });
});

export const TextInput = React.forwardRef<any, TextInputProps>(
  ({ classes: classesProp = {}, ...other }, ref) => {
    const classes = useStyles();

    return (
      <InputBase
        classes={{
          root: cn(classes.root, classesProp.root),
          focused: cn(classes.focused, classesProp.focused),
          input: cn(classes.input, classesProp.input),
          adornedEnd: cn(classes.adornedEnd, classesProp.adornedEnd),
          disabled: cn(classes.disabled, classesProp.disabled),
          error: cn(classes.error, classesProp.error),
        }}
        ref={ref}
        {...other}
      />
    );
  },
);
