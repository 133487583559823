import { WebViewEventPayload } from '../../types';

export function serializeWebViewEvent(
  event: string,
  payload?: WebViewEventPayload,
): string {
  let message: string = event;

  if (payload) {
    message = `${message}:__PAYLOAD__:${
      typeof payload === 'object' ? JSON.stringify(payload) : payload
    }`;
  }

  return message;
}
