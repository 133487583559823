import React, { useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import {
  RichText,
  EditorDocument,
  isDocumentEmpty,
} from '@21st-night/editor-web';
import { Button } from '@21st-night/ui';

export type CardPreviewView =
  | 'question'
  | 'answer'
  | 'question-summary'
  | 'answer-explanation';

export interface CardPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  question: EditorDocument;
  answer: EditorDocument;
  explanation: EditorDocument | null;
  summary: EditorDocument | null;
  imageUrl: string;
  defaultView?: CardPreviewView;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: 600,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }),
);

export const CardPreview: React.FC<CardPreviewProps> = ({
  className,
  question,
  answer,
  explanation,
  summary,
  imageUrl,
  defaultView = 'question',
  ...other
}) => {
  const classes = useStyles();
  const [view, setView] = useState<CardPreviewView>(defaultView);

  return (
    <div className={cn(classes.root, className)} {...other}>
      {['question', 'question-summary'].includes(view) && (
        <Button size="large" color="primary" onClick={() => setView('answer')}>
          Show answer
        </Button>
      )}
      {['answer', 'answer-explanation'].includes(view) && (
        <Button
          size="large"
          color="primary"
          onClick={() => setView('question')}
        >
          Show question
        </Button>
      )}
      {['question', 'question-summary'].includes(view) && (
        <RichText
          imageUrl={imageUrl}
          document={question}
          className={classes.content}
        />
      )}
      {['answer', 'answer-explanation'].includes(view) && (
        <RichText
          imageUrl={imageUrl}
          document={answer}
          className={classes.content}
        />
      )}
      {view === 'question-summary' && summary && (
        <RichText
          imageUrl={imageUrl}
          document={summary}
          className={classes.content}
        />
      )}
      {view === 'answer-explanation' && explanation && (
        <RichText
          imageUrl={imageUrl}
          document={explanation}
          className={classes.content}
        />
      )}
      {view === 'question' && summary && !isDocumentEmpty(summary) && (
        <Button onClick={() => setView('question-summary')}>
          Show summary
        </Button>
      )}
      {view === 'answer' && explanation && !isDocumentEmpty(explanation) && (
        <Button onClick={() => setView('answer-explanation')}>
          Reveal explanation
        </Button>
      )}
    </div>
  );
};
