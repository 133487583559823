import { WebViewEvent } from '../../types';

export function parseEventMessage(message: string): WebViewEvent {
  if (typeof message !== 'string') {
    return { type: '', payload: null };
  }

  const [type, payload] = message.split(':__PAYLOAD__:');

  if (payload && (payload.startsWith('{') || payload.startsWith('['))) {
    try {
      return { type, payload: JSON.parse(payload) };
    } catch {
      // Will return payload as string below
    }
  }

  return { type, payload: payload || null };
}
