import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography, Checkbox, CheckboxProps } from '@21st-night/ui';

export type DayKey =
  | 'day-0'
  | 'day-1'
  | 'day-2'
  | 'day-3'
  | 'day-4'
  | 'day-5'
  | 'day-6';

export interface Day {
  label: string;
  value: number;
  name: DayKey;
}

export interface TaskFormDaysOfTheWeekProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'defaultValue'> {
  CheckboxProps?: CheckboxProps;
  defaultValue?: number[];
  startWeekOn?: number;
}

export const useStyles = makeStyles(() =>
  createStyles({
    days: {
      display: 'flex',
      marginLeft: -8,
    },
    day: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

const days: Day[] = [
  { label: 'Sun', value: 0, name: 'day-0' },
  { label: 'Mon', value: 1, name: 'day-1' },
  { label: 'Tue', value: 2, name: 'day-2' },
  { label: 'Wed', value: 3, name: 'day-3' },
  { label: 'Thu', value: 4, name: 'day-4' },
  { label: 'Fri', value: 5, name: 'day-5' },
  { label: 'Sat', value: 6, name: 'day-6' },
];

export const TaskFormDaysOfTheWeek: React.FC<TaskFormDaysOfTheWeekProps> = ({
  CheckboxProps = {},
  defaultValue = [],
  startWeekOn = 0,
  ...other
}) => {
  const classes = useStyles();

  const orderedDays: Day[] = [
    ...days.slice(startWeekOn),
    ...days.slice(0, startWeekOn),
  ];

  return (
    <div className={classes.days} {...other}>
      {orderedDays.map(day => (
        <div key={day.value} className={classes.day}>
          <Typography variant="caption" color="textSecondary">
            {day.label}
          </Typography>
          <Checkbox
            color="default"
            value={day.value}
            name={`day-${day.value}`}
            defaultChecked={defaultValue.includes(day.value)}
            {...CheckboxProps}
          />
        </div>
      ))}
    </div>
  );
};
