import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export interface LightboxImageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  alt?: string;
  className?: string;
  disableHint?: boolean;
  maxHeight?: number | 'auto';
  src: string;
  expandedSrc: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    image: {
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      '&:visited': {
        color: 'inherit',
      },
    },
    caption: {
      display: 'block',
      textAlign: 'center',
    },
  }),
);

export const LightboxImage: React.FC<LightboxImageProps> = ({
  alt,
  src,
  expandedSrc,
  maxHeight = 300,
  disableHint = false,
  ...other
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function openLightbox(event: React.MouseEvent): void {
    event.preventDefault();
    setOpen(true);
  }

  return (
    <div {...other}>
      <a href="#" onClick={openLightbox} className={classes.link}>
        <img
          src={src}
          alt={alt}
          className={classes.image}
          style={{ maxHeight }}
        />
        {!disableHint && (
          <Typography variant="caption" className={classes.caption}>
            Click to enlarge
          </Typography>
        )}
      </a>
      {open && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 999999999 } }}
          mainSrc={expandedSrc}
          onCloseRequest={(): void => setOpen(false)}
        />
      )}
    </div>
  );
};

LightboxImage.defaultProps = {
  alt: '',
};
