import React from 'react';
import {
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
} from '@material-ui/core';

export type ListItemSecondaryActionProps = MuiListItemSecondaryActionProps;

export const ListItemSecondaryAction: React.FC<ListItemSecondaryActionProps> = ({
  ...other
}) => {
  return <MuiListItemSecondaryAction {...other} />;
};
