import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const TurnIntoFraction: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <mask id="path-1-inside-1" fill="white">
      <rect x="16" y="3" width="16" height="16" rx="2" />
    </mask>
    <rect
      x="16"
      y="3"
      width="16"
      height="16"
      rx="2"
      stroke="black"
      strokeWidth="6"
      mask="url(#path-1-inside-1)"
      fill="none"
    />
    <rect x="16" y="29" width="16" height="16" rx="2" fill="#2EB0FF" />
    <line
      x1="11.5"
      y1="23.5"
      x2="36.5"
      y2="23.5"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
