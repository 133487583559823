import { DocumentReference } from '@21st-night/utils';
import { StudyPlanTemplateUpdateData } from '../../types';
import { validateStudyPlanTemplateUpdate } from '../validators';

export function updateStudyPlanTemplate(
  templateRef: DocumentReference,
  data: StudyPlanTemplateUpdateData,
): Promise<void> {
  validateStudyPlanTemplateUpdate(data);

  return templateRef.update(data);
}
