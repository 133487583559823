import React from 'react';
import { Button, ButtonProps } from '@21st-night/ui';

export type DemoButtonProps = Omit<ButtonProps, 'variant' | 'color'>;

export const DemoButton: React.FC<DemoButtonProps> = ({ ...other }) => {
  return (
    <Button fullWidth variant="outlined" {...other}>
      Try a demo
    </Button>
  );
};
