import { FieldValue, SerializedUpdateData } from '@21st-night/utils';
import { DeckCardsUpdateData, DeckCardUpdateData } from '../../Deck.types';

type CardField = keyof DeckCardUpdateData;

export function serializeDeckCardsUpdateData(
  data: DeckCardsUpdateData,
): SerializedUpdateData {
  const serializedData: SerializedUpdateData = {};

  Object.keys(data).forEach(cardId => {
    const cardUpdate = data[cardId];

    Object.keys(cardUpdate).forEach(fieldName => {
      const field = fieldName as CardField;
      const value = cardUpdate[field] as FieldValue;

      serializedData[`cards.${cardId}.${field}`] = value;
    });
  });

  return serializedData;
}
