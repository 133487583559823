import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  Typography,
  MenuItem,
  Select,
  SelectProps,
  TextInput,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@21st-night/ui';
import { ColumnMap, ColumnType } from './ImportFromCsv.types';

export type View = 'file-selection' | 'column-configuration';

export interface ImportFromCsvSettingsProps {
  onSetColumnType: (column: number, type: ColumnType) => void;
  onSetColumnDelimiter: (value: string) => void;
  onSetCustomColumnDelimiter: (value: string) => void;
  onSetRowDelimiter: (value: string) => void;
  onSetCustomRowDelimiter: (value: string) => void;
  onSetSubcategoriesDelimiter: (value: string) => void;
  onChangeHasHeaderRow: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  hasMedia?: boolean;
  hasHeaderRow: boolean;
  headerRow?: string[];
  columnMap?: ColumnMap;
  columnCount: number;
  columnDelimiter: string;
  customColumnDelimiter: string;
  subcategoriesDelimiter: string;
  rowDelimiter: string;
  customRowDelimiter: string;
}

const basicSelectOptions: SelectOption[] = [
  {
    label: 'Question',
    value: 'question',
  },
  {
    label: 'Answer',
    value: 'answer',
  },
  {
    label: 'Process',
    value: 'explanation',
  },
  {
    label: 'Takeaways',
    value: 'summary',
  },
  {
    label: 'Category',
    value: 'category',
  },
  {
    label: 'Subcategories',
    value: 'subcategories',
  },
];

const mediaSelectOptions: SelectOption[] = [
  {
    label: 'Question image',
    value: 'questionImage',
  },
  {
    label: 'Answer image',
    value: 'answerImage',
  },
  {
    label: 'Explanation image',
    value: 'explanationImage',
  },
  {
    label: 'Summary image',
    value: 'summaryImage',
  },
  {
    label: 'Question audio',
    value: 'questionAudio',
  },
  {
    label: 'Answer audio',
    value: 'answerAudio',
  },
  {
    label: 'Explanation audio',
    value: 'explanationAudio',
  },
  {
    label: 'Summary audio',
    value: 'summaryAudio',
  },
];

const ignoreOption: SelectOption = {
  label: 'Ignore',
  value: 'ignore',
};

const selectOptions = [...basicSelectOptions, ignoreOption];
const selectOptionsWithMedia = [
  ...basicSelectOptions,
  ...mediaSelectOptions,
  ignoreOption,
];

export const useStyles = makeStyles(theme =>
  createStyles({
    delimiters: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
      '& > :not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    delimiterConfiguration: {
      flex: 1,
    },
    customDelimiterField: {
      marginTop: theme.spacing(1),
    },
    subcategoriesDelimiterField: {
      marginTop: theme.spacing(1),
      maxWidth: 140,
      display: 'block',
      marginLeft: 40,
    },
    subcategoriesDelimiterHelperText: {
      maxWidth: 180,
      textAlign: 'right',
    },
    headerCheckbox: {
      marginBottom: theme.spacing(1),
    },
    columnConfiguration: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      padding: theme.spacing(1, 0),
    },
    columnNumber: {
      flex: 1,
    },
    columnLabel: {
      color: theme.palette.text.disabled,
      marginTop: 4,
      fontSize: theme.typography.pxToRem(14),
      height: 20,
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    columnTypeSelect: {
      textAlign: 'right',
    },
  }),
);

interface SelectOption {
  label: string;
  value: ColumnType;
}

interface ColumnTypeSelectProps extends SelectProps {
  disableOptions: ColumnType[];
  options: SelectOption[];
}

export const ColumnTypeSelect: React.FC<ColumnTypeSelectProps> = ({
  disableOptions,
  options,
  value,
  ...other
}) => (
  <Select
    value={value}
    {...other}
    style={value === 'unknown' ? { color: '#9E9E9E' } : {}}
  >
    <MenuItem value="unknown" disabled style={{ display: 'none' }}>
      Please select
    </MenuItem>
    {options.map(option => (
      <MenuItem
        disabled={disableOptions.includes(option.value)}
        divider={[
          'summary',
          'subcategories',
          'summaryImage',
          'summaryAudio',
        ].includes(option.value)}
        key={option.value}
        value={option.value}
      >
        {option.label}
      </MenuItem>
    ))}
  </Select>
);

export const ImportFromCsvSettings: React.FC<ImportFromCsvSettingsProps> = ({
  onSetColumnType,
  onSetColumnDelimiter,
  onSetCustomColumnDelimiter,
  onSetRowDelimiter,
  onSetCustomRowDelimiter,
  onChangeHasHeaderRow,
  hasMedia = false,
  hasHeaderRow,
  headerRow,
  columnMap,
  columnCount,
  columnDelimiter,
  customColumnDelimiter,
  subcategoriesDelimiter,
  onSetSubcategoriesDelimiter,
  rowDelimiter,
  customRowDelimiter,
}) => {
  const classes = useStyles();

  function renderColumnTypeSelects(count: number, values: ColumnMap) {
    const selects = [];
    for (let col = 0; col < count; col += 1) {
      selects.push(
        <div key={col} className={classes.columnConfiguration}>
          <div className={classes.columnNumber}>
            Column {col + 1}
            {hasHeaderRow && headerRow && headerRow[col] && (
              <div className={classes.columnLabel}>{headerRow[col]}</div>
            )}
          </div>
          <div className={classes.columnTypeSelect}>
            <ColumnTypeSelect
              options={hasMedia ? selectOptionsWithMedia : selectOptions}
              disableOptions={Object.values(values).filter(
                type => type !== 'ignore',
              )}
              onChange={event =>
                onSetColumnType(col, event.target.value as ColumnType)
              }
              value={values[col] || ''}
            />
            {values[col] === 'subcategories' && (
              <>
                <TextInput
                  fullWidth
                  autoFocus
                  placeholder="Delimiter"
                  className={classes.subcategoriesDelimiterField}
                  value={subcategoriesDelimiter}
                  onChange={event =>
                    onSetSubcategoriesDelimiter(event.target.value)
                  }
                />
                <FormHelperText
                  className={classes.subcategoriesDelimiterHelperText}
                >
                  Leave empty if cards have a single subcategory
                </FormHelperText>
              </>
            )}
          </div>
        </div>,
      );
    }

    return selects;
  }

  return (
    <>
      <div className={classes.delimiters}>
        <div className={classes.delimiterConfiguration}>
          <Typography color="textSecondary">Column delimiter</Typography>
          <Select
            fullWidth
            value={columnDelimiter}
            onChange={event =>
              onSetColumnDelimiter(event.target.value as string)
            }
          >
            <MenuItem value="comma">Comma</MenuItem>
            <MenuItem value="tab">Tab</MenuItem>
            <MenuItem value="semicolon">Semicolon</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
          {columnDelimiter === 'custom' && (
            <TextInput
              fullWidth
              autoFocus
              placeholder="Delimiter"
              className={classes.customDelimiterField}
              value={customColumnDelimiter}
              onChange={event => onSetCustomColumnDelimiter(event.target.value)}
            />
          )}
        </div>
        <div className={classes.delimiterConfiguration}>
          <Typography color="textSecondary">Row delimiter</Typography>
          <Select
            fullWidth
            value={rowDelimiter}
            onChange={event => onSetRowDelimiter(event.target.value as string)}
          >
            <MenuItem value="newline">New line</MenuItem>
            <MenuItem value="tab">Tab</MenuItem>
            <MenuItem value="semicolon">Semicolon</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
          {rowDelimiter === 'custom' && (
            <TextInput
              fullWidth
              autoFocus
              placeholder="Delimiter"
              className={classes.customDelimiterField}
              value={customRowDelimiter}
              onChange={event => onSetCustomRowDelimiter(event.target.value)}
            />
          )}
        </div>
      </div>
      <div className={classes.headerCheckbox}>
        <FormControlLabel
          control={
            <Checkbox checked={hasHeaderRow} onChange={onChangeHasHeaderRow} />
          }
          label="First row is header"
        />
      </div>
      {columnMap && renderColumnTypeSelects(columnCount, columnMap)}
    </>
  );
};
