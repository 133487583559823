import { DB } from '@21st-night/utils';
import { UserStatistic } from '../../Gamification.types';

export function incrementStatistic(
  db: DB,
  userId: string,
  statistic: UserStatistic,
  value = 1,
): Promise<void> {
  return db
    .collection('users')
    .doc(userId)
    .update({
      [`statistics.${statistic}`]: db.increment(value),
    });
}
