import React from 'react';
import { useSlate } from 'slate-react';
import {
  Toolbar,
  ToolbarSpacer,
  ToolbarInsertInlineEquationButton,
  ToolbarRichTextFormattingButtons,
  ToolbarBlockFormattingButtons,
  ToolbarInsertLinkButton,
  ToolbarInsertCodeBlockButton,
  ToolbarInsertEquationBlockButton,
} from '../Toolbar';
import { BasicEditor } from './BasicEditor';

export const BasicEditorToolbar: React.FC = () => {
  const editor = useSlate() as BasicEditor;

  return (
    <Toolbar>
      <ToolbarRichTextFormattingButtons editor={editor} />
      <ToolbarInsertLinkButton editor={editor} />
      <ToolbarInsertInlineEquationButton editor={editor} />
      <ToolbarSpacer />
      <ToolbarBlockFormattingButtons editor={editor} />
      <ToolbarSpacer />
      <ToolbarInsertCodeBlockButton editor={editor} />
      <ToolbarInsertEquationBlockButton editor={editor} />
    </Toolbar>
  );
};
