import { CollectionReference } from '@21st-night/utils';
import { validateReviewSessionUpdate } from '../validators';
import { RatingEvent, ReviewSessionUpdateData } from '../../Review.types';

export function updateReviewSession(
  reviewSessionCollectionRef: CollectionReference,
  id: string,
  data: ReviewSessionUpdateData,
): Promise<void> {
  let cardAttempts: ReviewSessionUpdateData['cardAttempts'] = {};
  const update: ReviewSessionUpdateData = { ...data, lastActivity: new Date() };

  validateReviewSessionUpdate(update);

  if (update.cardAttempts) {
    cardAttempts = Object.keys(update.cardAttempts).reduce(
      (cardAttempts, cardId) => ({
        ...cardAttempts,
        [`cardAttempts.${cardId}`]: (update.cardAttempts as Record<
          string,
          RatingEvent[]
        >)[cardId],
      }),
      {},
    );

    delete update.cardAttempts;
  }

  return reviewSessionCollectionRef
    .doc(id)
    .update({ ...update, ...cardAttempts });
}
