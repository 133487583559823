import React from 'react';
import {
  getBlockAbove,
  isBlockAboveEmpty,
  Transforms,
  Element,
} from '@braindrop-editor/core';
import { Tex } from '@21st-night/icons';
import {
  EditorToolbarButton as Button,
  EditorToolbarButtonProps,
} from '../components/EditorToolbarButton';
import { EditorWithEquationBlockPlugin } from '../plugins/plugin-equation-block';
import { isFocusElementOfType } from '../api';

export interface ToolbarInsertEquationBlockButtonProps
  extends Partial<EditorToolbarButtonProps> {
  editor: EditorWithEquationBlockPlugin;
}

export const ToolbarInsertEquationBlockButton: React.FC<ToolbarInsertEquationBlockButtonProps> = ({
  editor,
  ...other
}) => {
  return (
    <Button
      tooltip="Insert TeX block"
      shortcut="ctrl+alt+0"
      active={isFocusElementOfType(editor, 'tex')}
      onMouseDown={(event): void => {
        event.preventDefault();
        if (isFocusElementOfType(editor, 'tex')) {
          Transforms.setNodes(editor, { type: 'paragraph' });
        } else {
          if (isBlockAboveEmpty(editor)) {
            const block = getBlockAbove(editor);
            editor.turnIntoBlockEquation(block[0] as Element);
          } else {
            editor.insertBlockEquation();
          }
        }
      }}
      {...other}
    >
      <Tex />
    </Button>
  );
};
