import {
  BraindropEditorPluginFactory,
  BraindropEditor,
  BraindropEditorPlugin,
} from '@braindrop-editor/core';

export interface EditorStylePluginOptions {
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  placeholder?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  disableScrolling?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const createEditorStylePlugin = ({
  height,
  minHeight,
  maxHeight,
  placeholder,
  autoFocus,
  readOnly,
  disableScrolling,
  className,
  style = {},
}: EditorStylePluginOptions): BraindropEditorPluginFactory => (
  editor: BraindropEditor,
): BraindropEditorPlugin => {
  const { renderEditable } = editor;

  editor.renderEditable = (props): JSX.Element =>
    renderEditable({
      ...props,
      placeholder,
      autoFocus,
      readOnly,
      className,
      style: {
        minHeight: minHeight || 200,
        height,
        maxHeight: maxHeight || 2000,
        overflowY: disableScrolling ? 'visible' : 'scroll',
        WebkitOverflowScrolling: disableScrolling ? 'auto' : 'touch',
        ...style,
      },
    });

  return {};
};
