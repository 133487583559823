import { Storage } from '@21st-night/utils-web';
import {
  serializeDocument,
  generateEditorElement,
  generateRichTextDocument,
  EditorDocument,
  stripDocumentMetadata,
  isDocumentEmpty,
  Node,
} from '@21st-night/editor-web';
import { CardData } from '@21st-night/cards';

export interface RawCardData {
  id: string;
  content: EditorDocument;
  questionImage: string;
  answerImage: string;
}

export interface ImageOcclusionCardData {
  question: string;
  answer: string;
}

function createImageDocument(
  storage: Storage,
  image: string,
): Promise<EditorDocument> {
  return new Promise(resolve => {
    const file = storage.ref(image);
    file.getDownloadURL().then(url => {
      const img = new Image();
      img.onload = (): void => {
        const document = generateRichTextDocument();
        document.push(
          generateEditorElement('image', '', {
            file: image,
            url,
            dimensions: {
              height: img.height,
              width: img.width,
              aspectRatio: img.width / img.height || 1,
            },
          }),
        );
        resolve(document);
      };

      img.src = url;
    });
  });
}

export async function imageOcclusionCreateCards(
  storage: Storage,
  rawCards: RawCardData[],
): Promise<CardData[]> {
  const cards: Promise<CardData>[] = rawCards.map(async rawCard => {
    const [question, answer] = await Promise.all([
      createImageDocument(storage, rawCard.questionImage),
      createImageDocument(storage, rawCard.answerImage),
    ]);

    const card: CardData = {
      id: rawCard.id,
      question: serializeDocument(question),
      answer: serializeDocument([
        ...answer,
        ...(!isDocumentEmpty(rawCard.content)
          ? (stripDocumentMetadata(rawCard.content)[0] as Node[])
          : []),
      ]),
    };

    return card;
  });

  return Promise.all(cards);
}
