import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress, CircularProgressProps } from '../CircularProgress';

export type ProficiencyProgressCircleProps = CircularProgressProps;

export const useStyles = makeStyles(theme =>
  createStyles({
    needsWork: {
      color: theme.palette.proficiency.needsWork,
    },
    inProgress: {
      color: theme.palette.proficiency.inProgress,
    },
    mastered: {
      color: theme.palette.proficiency.mastered,
    },
  }),
);

export const ProficiencyProgressCircle: React.FC<ProficiencyProgressCircleProps> = ({
  size = 34,
  value = 0,
  thickness,
  ...other
}) => {
  const classes = useStyles();
  return (
    <CircularProgress
      variant="static"
      value={value}
      classes={{
        circle: clsx({
          [classes.mastered]: value >= 75,
          [classes.inProgress]: value >= 25 && value < 75,
          [classes.needsWork]: value < 25,
        }),
      }}
      size={size}
      thickness={thickness}
      {...other}
    />
  );
};
