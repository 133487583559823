import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Power: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <rect x="30" y="4" width="14" height="14" rx="2" fill="#2EB0FF" />
    <rect
      x="8"
      y="12"
      width="18"
      height="23"
      rx="2"
      stroke="black"
      strokeWidth="3"
      fill="none"
    />
  </SvgIcon>
);
