import { DocumentReference } from '@21st-night/utils';
import { StudyPlanTaskTemplateUpdateData } from '../../types';
import { validateStudyPlanTaskTemplateUpdate } from '../validators';

export function updateStudyPlanTaskTemplate(
  templateRef: DocumentReference,
  id: string,
  data: StudyPlanTaskTemplateUpdateData,
): Promise<void> {
  validateStudyPlanTaskTemplateUpdate(data);

  return templateRef.collection('tasks').doc(id).update(data);
}
