import React, { useEffect, useRef, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { makeStyles } from '@21st-night/styles';
import { Editor } from './Editor';
import {
  ToolbarBlockFormattingButtons,
  ToolbarInsertCodeBlockButton,
  ToolbarInsertEquationBlockButton,
  ToolbarInsertInlineEquationButton,
  ToolbarInsertLinkButton,
  ToolbarRichTextFormattingButtons,
  ToolbarInsertMediaDropdown,
  ToolbarSpacer,
} from './Toolbar';

export interface ExternalEditorToolbarProps {
  editor: Editor;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowY: 'scroll',
    marginBottom: 8,
    marginLeft: -8,
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: 2,
    },
  },
}));

export const ExternalEditorToolbar: React.FC<ExternalEditorToolbarProps> = ({
  editor,
}) => {
  const classes = useStyles();
  const [lastSelection, setLastSelection] = useState(editor.selection);
  const rootRef = useRef<HTMLDivElement>(null);
  const width = useWindowWidth();
  const [toolbarWidth, setToolbarWidth] = useState(1000);

  useEffect(() => {
    if (rootRef.current) {
      setToolbarWidth(rootRef.current.scrollWidth);
    }
  }, [width, rootRef.current]);

  useEffect(() => {
    if (editor.selection) {
      setLastSelection(editor.selection);
    }
  }, [editor.selection]);

  return (
    <div ref={rootRef} className={classes.root}>
      {toolbarWidth > 480 && (
        <>
          <ToolbarRichTextFormattingButtons editor={editor} />
          <ToolbarInsertLinkButton editor={editor} />
        </>
      )}
      <ToolbarInsertInlineEquationButton editor={editor} />
      <ToolbarSpacer />
      <ToolbarBlockFormattingButtons editor={editor} />
      <ToolbarSpacer />
      <ToolbarInsertCodeBlockButton editor={editor} />
      <ToolbarInsertEquationBlockButton editor={editor} />
      <ToolbarSpacer />
      <ToolbarInsertMediaDropdown
        editor={editor}
        lastSelection={lastSelection}
      />
    </div>
  );
};
