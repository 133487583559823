/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import {
  makeStyles as makeStylesWithoutDefault,
  ClassNameMap,
  Styles,
  WithStylesOptions,
} from '@material-ui/styles';
import {
  createMuiTheme,
  Theme as DefaultTheme,
} from '@material-ui/core/styles';
import { theme } from './theme';

const defaultTheme = createMuiTheme(theme) as DefaultTheme;

/**
 * `makeStyles` where the passed `styles` do not depend on props
 */
export function makeStyles<
  Theme = DefaultTheme,
  ClassKey extends string = string
>(
  style: Styles<Theme, {}, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
): (props?: any) => ClassNameMap<ClassKey>;
/**
 * `makeStyles` where the passed `styles` do depend on props
 */
export function makeStyles<
  Theme = DefaultTheme,
  Props extends {} = {},
  ClassKey extends string = string
>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
): (props: Props) => ClassNameMap<ClassKey>;

export function makeStyles<
  Theme = DefaultTheme,
  ClassKey extends string = string
>(styles: any, options: any): (props?: any) => ClassNameMap<ClassKey> {
  return makeStylesWithoutDefault(styles, {
    defaultTheme,
    ...options,
  });
}
