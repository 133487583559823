import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { CardType } from '@21st-night/cards-web';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogActions,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Spaced,
  Button,
  Radio,
  Typography,
  LoadingIndicator,
} from '@21st-night/ui';
import { Deck } from '@21st-night/deck';

export interface DeckImportDialogProps extends Omit<DialogProps, 'onChange'> {
  decks: Deck[];
  variant: 'note' | CardType;
  selected?: string;
  onChange: (value: string) => void;
  onSubmit?: (event: React.MouseEvent) => void;
  loading?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    loadingText: {
      height: 36,
      lineHeight: '36px',
      marginRight: theme.spacing(1),
    },
  }),
);

function getCount(
  variant: DeckImportDialogProps['variant'],
  deck: Deck,
): string {
  const variantLabel = variant.replace('-', ' ');
  let count = 0;

  if (variant === 'note') {
    count = Object.keys(deck.notes).length;
  } else if (variant === 'flashcard') {
    count = Object.values(deck.cards).filter(card => card.type === 'flashcard')
      .length;
  } else if (variant === 'error-log') {
    count = Object.values(deck.cards).filter(
      card => card.type === 'error-log' || !card.type,
    ).length;
  }

  if (count === 0) {
    return `No ${variantLabel}s`;
  } else if (count === 1) {
    return `1 ${variantLabel}`;
  }

  return `${count} ${variantLabel}s`;
}

export const DeckImportDialog: React.FC<DeckImportDialogProps> = ({
  className,
  open,
  decks,
  variant,
  selected,
  onChange,
  onClose,
  onSubmit,
  loading,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      className={cn(classes.root, className)}
      {...other}
    >
      <DialogTitle>Import {variant} from another deck</DialogTitle>
      <List>
        {decks.map(deck => (
          <ListItem
            button
            key={deck.id}
            disabled={loading}
            onClick={() => onChange(deck.id)}
          >
            <ListItemIcon>
              <Radio checked={selected === deck.id} disabled={loading} />
            </ListItemIcon>
            <ListItemText
              primary={deck.name}
              secondary={getCount(variant, deck)}
            />
          </ListItem>
        ))}
      </List>
      <DialogActions>
        {!loading && (
          <Spaced>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              Import
            </Button>
          </Spaced>
        )}
        {loading && (
          <>
            <LoadingIndicator size="small" />
            <Typography variant="subtitle1" className={classes.loadingText}>
              Importing {variant}
            </Typography>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
