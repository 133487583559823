import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useFirebase } from '@21st-night/utils';
import { Card, deserializeCardDocument } from '@21st-night/cards';
import { Deck, DeckContentCreateCard } from '../../Deck.types';

export type AddCardFn = (
  type: Card['type'],
  data?: Partial<
    Pick<
      DeckContentCreateCard,
      'category' | 'subcategories' | 'note' | 'noteSelection'
    >
  >,
) => DeckContentCreateCard;

export type RemoveCardEditorFn = (id: string) => void;

export interface DeckCardsHook {
  loading: boolean;
  cards: Card[];
  cardEditors: DeckContentCreateCard[];
  addCard: AddCardFn;
  removeCardEditor: RemoveCardEditorFn;
}

export const useDeckCards = (deck: Deck): DeckCardsHook => {
  const { db } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<Card[]>([]);
  const [cardEditors, setCardEditors] = useState<DeckContentCreateCard[]>([]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    const deckIds = [deck.id];

    if (deck.parent) {
      deckIds.push(deck.parent);
    }

    const unsubscribe = db
      .collection('cards')
      .where('deck', 'in', deckIds)
      .onSnapshot(snapshot => {
        if (isMounted && snapshot) {
          setCards(snapshot.docs.map(doc => deserializeCardDocument(doc)));
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [deck.id, deck.parent]);

  const addCard: AddCardFn = useCallback((type, data = {}) => {
    const newCard: DeckContentCreateCard = {
      id: uuid(),
      createdAt: new Date(),
      type: type === 'flashcard' ? 'create-flashcard' : 'create-error-log',
      category: null,
      subcategories: [],
      ...data,
    };
    setCardEditors(value => [...value, newCard]);

    return newCard;
  }, []);

  const removeCardEditor: RemoveCardEditorFn = useCallback(id => {
    setCardEditors(value => value.filter(editor => editor.id !== id));
  }, []);

  return { cards, cardEditors, loading, addCard, removeCardEditor };
};
