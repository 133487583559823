import React from 'react';
import { Transforms, Node } from 'slate';
import { BraindropEditor, BraindropEditorPlugin } from '@braindrop-editor/core';
import {
  CardAnswerElement,
  CardExplanationElement,
  CardSummaryElement,
  isElement,
} from '@21st-night/editor-web';
import { ElementAnswer } from './ElementAnswer';
import {
  ElementAnswerReadOnly,
  ReadOnlyAnswerElement,
} from './ElementAnswerReadOnly';
import { ElementExplanation } from './ElementExplanation';
import { ElementSummary } from './ElementSummary';

export interface CardAnswerPluginOptions {
  answerElementMinHeight?: number;
  normalize?: boolean;
}

export const createCardAnswerPlugin = ({
  answerElementMinHeight,
  normalize: normalizeDefault,
}: CardAnswerPluginOptions = {}) => (
  editor: BraindropEditor,
): BraindropEditorPlugin<
  CardAnswerElement | CardExplanationElement | CardSummaryElement
> => {
  const normalize =
    typeof normalizeDefault === 'boolean' ? normalizeDefault : true;

  if (normalize) {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]) => {
      if (path.length === 0) {
        if (editor.children.length < 1) {
          Transforms.insertNodes(
            editor,
            editor.generateElement('card-answer', {
              children: [editor.generateElement('paragraph')],
            }),
            { at: path.concat(0) },
          );
        }

        if (editor.children.length < 2) {
          Transforms.insertNodes(
            editor,
            editor.generateElement('card-explanation', {
              children: [editor.generateElement('paragraph')],
            }),
            { at: path.concat(1) },
          );
        }

        if (editor.children.length < 3) {
          Transforms.insertNodes(
            editor,
            editor.generateElement('card-summary', {
              children: [editor.generateElement('paragraph')],
            }),
            { at: path.concat(2) },
          );
        }

        if (editor.children.length > 3) {
          Transforms.removeNodes(editor, { at: path.concat(3) });
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [child, childPath] of Node.children(editor, path)) {
          let type;
          switch (childPath[0]) {
            case 0:
              type = 'card-answer';
              break;
            case 1:
              type = 'card-explanation';
              break;
            case 2:
            default:
              type = 'card-summary';
              break;
          }

          if (isElement(child) && child.type !== type) {
            Transforms.setNodes(editor, { type }, { at: childPath });
          }
        }
      }

      return normalizeNode([node, path]);
    };
  }

  return {
    elements: [
      {
        type: 'card-answer',
        component: props => (
          <ElementAnswer
            {...props}
            element={props.element as CardAnswerElement}
            minHeight={answerElementMinHeight}
          />
        ),
      },
      {
        type: 'card-answer-disabled',
        isVoid: true,
        component: props => (
          <ElementAnswerReadOnly
            {...props}
            element={props.element as ReadOnlyAnswerElement}
          />
        ),
      },
      {
        type: 'card-explanation',
        component: props => (
          <ElementExplanation
            {...props}
            element={props.element as CardExplanationElement}
          />
        ),
      },
      {
        type: 'card-summary',
        component: props => (
          <ElementSummary
            {...props}
            element={props.element as CardSummaryElement}
          />
        ),
      },
    ],
  };
};
