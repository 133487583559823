import React, { Fragment, useState } from 'react';
import { useUI } from '@braindrop-editor/core';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Button, ButtonProps } from '@21st-night/ui';
import { ArrowDropDown } from '@21st-night/icons';
import { addModifierKeys } from '../EditorToolbarButton';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      color: theme.palette.grey[600],
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        '&$contrast': {
          backgroundColor: theme.palette.grey[800],
        },
      },
    },
    dropdownIcon: {
      marginLeft: 0,
    },
    contrast: {
      color: '#FFF',
    },
    list: {
      width: 300,
    },
  }),
);

export interface MenuItem {
  title: string;
  id: string;
  subtitle?: string;
  shortcut?: string;
  group?: string;
  action: () => void;
}

export interface EditorToolbarDropdownProps extends ButtonProps {
  label: string;
  items: MenuItem[];
}

export const EditorToolbarDropdown: React.FC<EditorToolbarDropdownProps> = ({
  className,
  label,
  items,
}) => {
  const classes = useStyles();
  const { Popover, List, MenuItem, MenuSectionHeading, MenuDivider } = useUI();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        dense
        size="small"
        onMouseDown={handleClick}
        className={cn(classes.button, className)}
        classes={{ endIcon: classes.dropdownIcon }}
        endIcon={<ArrowDropDown />}
      >
        {label}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List className={classes.list}>
          {items.map((item, index) => (
            <Fragment key={item.id}>
              {(index === 0 || item.group !== items[index - 1].group) && (
                <>
                  {index !== 0 && <MenuDivider />}
                  <MenuSectionHeading>{item.group}</MenuSectionHeading>
                </>
              )}
              <div id={`slash-menu-item-${item.id}`}>
                <MenuItem
                  shortcut={addModifierKeys(item.shortcut || '')}
                  onMouseDown={(event): void => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleClose();
                    item.action();
                  }}
                  primaryText={item.title}
                  secondaryText={item.subtitle}
                />
              </div>
            </Fragment>
          ))}
        </List>
      </Popover>
    </>
  );
};
