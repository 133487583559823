import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { AddBox, RemoveBox } from '@21st-night/icons';
import { Button, ButtonProps } from '@21st-night/ui';
import { ReactEditor, useSlate } from 'slate-react';
import { EditorDocument, isElementOfType } from '@21st-night/editor-web';
import { Range, Node, Transforms } from 'slate';
import { getSelectionCardLinks } from '@21st-night/cards';
import { useCardCreationPlugin } from '../CardCreationPluginProvider';

interface ElementCreateCardButtonProps extends Omit<ButtonProps, 'onClick'> {
  onRemoveCardLink: (cardId: string) => void;
  onClick?: (
    event: React.MouseEvent,
    fragment: Node[],
    selection: Range,
    position: DOMRect,
  ) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
  },
  startIcon: {
    marginRight: 4,
  },
}));

export const EditorCreateCardButton: React.FC<ElementCreateCardButtonProps> = ({
  onClick,
  onRemoveCardLink,
  ...other
}) => {
  const { setFragment, setSelection, setPosition } = useCardCreationPlugin();
  const [cardLinksInSelection, setCardLinksInSelection] = useState(0);
  const classes = useStyles();
  const editor = useSlate();
  const document = editor.children as EditorDocument;

  useEffect(() => {
    if (editor.selection) {
      setCardLinksInSelection(0);
      setCardLinksInSelection(
        getSelectionCardLinks(document, editor.selection).length,
      );
    }
  }, [editor.selection]);

  const handleClickAdd = useCallback(
    (event: React.MouseEvent) => {
      if (editor.selection) {
        const domRange = ReactEditor.toDOMRange(editor, editor.selection);
        if (domRange) {
          const position = domRange.getBoundingClientRect();
          const fragment = Node.fragment(editor, editor.selection as Range);

          setFragment(fragment);
          setPosition(position);
          setSelection(editor.selection);

          if (onClick) {
            onClick(event, fragment, editor.selection, position);
          }
        }
      }
    },
    [editor.selection],
  );

  const handleClickRemove = useCallback(() => {
    if (editor.selection) {
      const nodeEntries = getSelectionCardLinks(document, editor.selection);
      Transforms.unwrapNodes(editor, {
        at: editor.selection,
        match: node => isElementOfType(node, 'card-link'),
        split: true,
      });

      nodeEntries.forEach(nodeEntry => {
        if (nodeEntry[0].cardId) {
          onRemoveCardLink(nodeEntry[0].cardId as string);
        }
      });
    }
  }, [editor.selection]);

  return (
    <>
      {cardLinksInSelection === 0 && (
        <Button
          variant="text"
          size="small"
          onClick={handleClickAdd}
          classes={{
            root: classes.root,
            startIcon: classes.startIcon,
          }}
          startIcon={<AddBox />}
          {...other}
        >
          Create a card
        </Button>
      )}
      {cardLinksInSelection > 0 && (
        <Button
          variant="text"
          size="small"
          onClick={handleClickRemove}
          classes={{
            root: classes.root,
            startIcon: classes.startIcon,
          }}
          startIcon={<RemoveBox />}
          {...other}
        >
          Remove card link{cardLinksInSelection > 1 && 's'}
        </Button>
      )}
    </>
  );
};
