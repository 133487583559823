import React, { useRef, useState } from 'react';
import { Range } from 'slate';
import {
  deserializeDocument,
  EditorDocument,
  generateRichTextDocument,
  isDocumentEmpty,
  serializeDocument,
} from '@21st-night/editor-web';
import { createContext } from '@21st-night/utils';
import { getSelectionCardLinks } from '@21st-night/cards-web';
import { DeckCardEditorData, DeckContentNote } from '@21st-night/deck';

type View = 'editor' | 'preview' | 'select-note' | 'note';

export interface DeckCardEditorProviderProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  data?: Partial<DeckCardEditorData>;
  onSubmit: (data: DeckCardEditorData) => void;
  onDelete?: () => void;
  notes: DeckContentNote[];
}

export interface DeckCardEditorContext {
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
  view: View;
  setView: (view: View) => void;
  errors: string[];
  previewHeight: number | string;
  existingCardLinksCount: number;
  question: EditorDocument;
  answer: EditorDocument;
  explanation: EditorDocument;
  summary: EditorDocument;
  setQuestion: (value: EditorDocument) => void;
  setAnswer: (value: EditorDocument) => void;
  setExplanation: (value: EditorDocument) => void;
  setSummary: (value: EditorDocument) => void;
  category: string | null;
  subcategories: string[];
  setCategory: (value: string | null) => void;
  setSubcategories: (value: string[]) => void;
  onSelectNote: (note: DeckContentNote) => void;
  selectedNote: DeckContentNote | null;
  linkedNote: DeckContentNote | null;
  onClickBackFromNote: () => void;
  onClickBackFromNoteSelection: () => void;
  onClickLinkNote: () => void;
  onClickRemoveNoteLink: () => void;
  onClickSubmit: () => void;
  onClickDelete?: () => void;
  setNoteSelection: (range: Range | null) => void;
  noteSelection: Range | null;
  clearExistingCardLinksCount: () => void;
}

const [hook, Provider] = createContext<DeckCardEditorContext>();

function defaultDocument(document?: string | null) {
  return document ? deserializeDocument(document) : generateRichTextDocument();
}

export const DeckCardEditorProvider: React.FC<DeckCardEditorProviderProps> = ({
  data = {},
  onSubmit,
  onDelete,
  children,
  notes,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [view, setView] = useState<View>('editor');
  const [previewHeight, setPreviewHeight] = useState<number | string>('auto');
  const [selectedNote, setSelectedNote] = useState<DeckContentNote | null>(
    null,
  );
  const [linkedNote, setLinkedNote] = useState<DeckContentNote | null>(
    data.note ? notes.find(note => note.id === data.note) || null : null,
  );
  const [noteSelection, setNoteSelection] = useState<Range | null>(null);
  const [existingCardLinksCount, setExistingCardLinksCount] = useState(0);
  const [question, setQuestion] = useState<EditorDocument>(
    defaultDocument(data.question),
  );
  const [answer, setAnswer] = useState<EditorDocument>(
    defaultDocument(data.answer),
  );
  const [explanation, setExplanation] = useState<EditorDocument>(
    defaultDocument(data.explanation),
  );
  const [summary, setSummary] = useState<EditorDocument>(
    defaultDocument(data.summary),
  );
  const [category, setCategory] = useState(data.category || null);
  const [subcategories, setSubcategories] = useState(data.subcategories || []);

  function showView(nextView: View) {
    if (nextView !== 'editor') {
      if (contentRef.current) {
        setPreviewHeight(contentRef.current.clientHeight);
      } else {
        setPreviewHeight('auto');
      }
    }
    setView(nextView);
  }

  function handleSelectNote(note: DeckContentNote) {
    setSelectedNote(note);
    showView('note');
  }

  function handleClickBackFromNoteSelection() {
    showView('editor');
  }

  function handleClickBackFromNote() {
    setSelectedNote(null);
    showView('select-note');
  }

  function linkNote() {
    setLinkedNote(selectedNote);
    showView('editor');
    setExistingCardLinksCount(0);
  }

  function clearExistingCardLinksCount() {
    setExistingCardLinksCount(0);
  }

  function handleClickLinkNote() {
    if (selectedNote && noteSelection) {
      const existingLinks = getSelectionCardLinks(
        deserializeDocument(selectedNote.content),
        noteSelection,
      );

      if (existingLinks.length) {
        setExistingCardLinksCount(existingLinks.length);
      } else {
        linkNote();
      }
    } else {
      linkNote();
    }
  }

  function handleRemoveNoteLink() {
    setLinkedNote(null);
    setNoteSelection(null);
    setSelectedNote(null);
    showView('editor');
  }

  function handleSubmit() {
    const validationErrors = [];

    if (isDocumentEmpty(question)) {
      validationErrors.push('Question is required.');
    }

    if (isDocumentEmpty(answer)) {
      validationErrors.push('Answer is required.');
    }

    setErrors(validationErrors);

    if (validationErrors.length) {
      return;
    }

    if (onSubmit) {
      onSubmit({
        category,
        subcategories,
        noteSelection,
        note: linkedNote ? linkedNote.id : null,
        question: serializeDocument(question),
        answer: serializeDocument(answer),
        explanation: isDocumentEmpty(explanation)
          ? null
          : serializeDocument(explanation),
        summary: isDocumentEmpty(summary) ? null : serializeDocument(summary),
      });
    }
  }

  return (
    <Provider
      value={{
        contentRef,
        view,
        setView,
        errors,
        previewHeight,
        existingCardLinksCount,
        question,
        answer,
        explanation,
        summary,
        setQuestion,
        setAnswer,
        setExplanation,
        setSummary,
        category,
        subcategories,
        setCategory,
        setSubcategories,
        selectedNote,
        linkedNote,
        onSelectNote: handleSelectNote,
        onClickBackFromNoteSelection: handleClickBackFromNoteSelection,
        onClickBackFromNote: handleClickBackFromNote,
        onClickLinkNote: handleClickLinkNote,
        onClickRemoveNoteLink: handleRemoveNoteLink,
        onClickSubmit: handleSubmit,
        onClickDelete: onDelete,
        noteSelection,
        setNoteSelection,
        clearExistingCardLinksCount,
      }}
    >
      {children}
    </Provider>
  );
};

export const useDeckCardEditor = hook;
