import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Chip, ChipProps } from '@21st-night/ui';

export interface CategoryTagProps extends Omit<ChipProps, 'variant'> {
  variant?: 'category' | 'subcategory' | 'metadata';
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: 20,
      borderRadius: 3,
      border: 'none',
      cursor: 'pointer',
      transition: 'none',
    },
    label: {
      overflow: 'visible',
      fontSize: '14px',
      paddingLeft: 6,
      paddingRight: 6,
    },
    sizeSmall: {
      height: 16,
      paddingLeft: 2,
      paddingRight: 2,
    },
    labelSmall: {
      fontSize: '12px',
      paddingLeft: 2,
      paddingRight: 2,
    },
    clickable: {
      '&:hover': {
        '&$category': {
          backgroundColor:
            theme.palette.type === 'light' ? '#BBDEFB' : '#123B63',
        },
        '&$subcategory': {
          backgroundColor:
            theme.palette.type === 'light' ? '#CFD8DC' : '#123B63',
        },
        '&$metadata': {
          backgroundColor:
            theme.palette.type === 'light' ? '#EEEEEE' : '#123B63',
        },
      },
    },
    category: {
      backgroundColor: theme.palette.type === 'light' ? '#CFE6FC' : '#123B63',
      color: theme.palette.type === 'light' ? '#123B63' : '#CFE6FC',
    },
    subcategory: {
      backgroundColor: theme.palette.type === 'light' ? '#ECEFF1' : '#36362E',
      color: theme.palette.type === 'light' ? '#36362E' : '#D7D7D5',
    },
    metadata: {
      backgroundColor: theme.palette.type === 'light' ? '#F5F5F5' : '#E9EFF3',
      color: theme.palette.type === 'light' ? '#37474F' : '#000000',
    },
  }),
);

export const CategoryTag = React.forwardRef<HTMLDivElement, CategoryTagProps>(
  ({ className, label, size, variant = 'category', ...other }, ref) => {
    const classes = useStyles();
    return (
      <Chip
        ref={ref}
        size={size}
        className={cn(
          classes.root,
          variant === 'category' && classes.category,
          variant === 'subcategory' && classes.subcategory,
          variant === 'metadata' && classes.metadata,
          className,
        )}
        classes={{
          label: classes.label,
          labelSmall: classes.labelSmall,
          sizeSmall: classes.sizeSmall,
          clickable: classes.clickable,
        }}
        label={label}
        {...other}
      />
    );
  },
);
