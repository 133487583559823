import React from 'react';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import {
  ProficiencyKey,
  PROFICIENCY_LABELS,
  RatingEvent,
} from '@21st-night/review-web';
import { makeStyles } from '@21st-night/styles';
import { Popover, Typography } from '@21st-night/ui';
import { CardAnalytics } from '@21st-night/analytics-web';
import { useDeck } from '@21st-night/deck';

export interface DeckProficiencyTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  readonly?: boolean;
  value: ProficiencyKey;
  createdAt?: Date;
  ratings?: RatingEvent[];
}

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 400,
  },
  caption: {
    fontSize: '12px',
    marginTop: theme.spacing(2),
    color: theme.palette.text.hint,
  },
}));

export const DeckProficiencyTag: React.FC<DeckProficiencyTagProps> = ({
  readonly,
  value,
  createdAt,
  ratings,
  ...other
}) => {
  const classes = useStyles();
  const deck = useDeck();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  let icon = '';
  switch (value) {
    case 'needs-work':
      icon = '🔴';
      break;
    case 'in-progress':
      icon = '🟠';
      break;
    case 'mastered':
      icon = '🟢';
      break;
    default:
      icon = '⚪️';
  }

  const label = `${icon} ${PROFICIENCY_LABELS[value]}`;

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <CategoryTag
        variant="metadata"
        label={label}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={
          !readonly
            ? () =>
                deck.toggleFilter({
                  value,
                  type: 'proficiency',
                  group: 'metadata',
                  label,
                })
            : undefined
        }
        {...other}
      />

      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {createdAt && (
          <CardAnalytics createdAt={createdAt} ratings={ratings || []} />
        )}

        <Typography variant="body1" className={classes.caption}>
          Note: your proficiency only updates once per day. We&apos;re looking
          for long-term mastery, not short-term.
        </Typography>
      </Popover>
    </>
  );
};
