import React from 'react';
import { isEqual } from 'lodash';
import {
  getSelectionCardLinks,
  removeCardLinkFromDocument,
  removeNoteLink,
  useCard,
} from '@21st-night/cards-web';
import { useFirebase } from '@21st-night/utils';
import { deserializeDocument, serializeDocument } from '@21st-night/editor-web';
import {
  addCardLink,
  addContentCardLink,
  removeCardLink,
} from '@21st-night/notes-web';
import {
  useDeck,
  DeckCardEditorData,
  generateCardCheatSheetItem,
  DeckContentCard,
} from '@21st-night/deck';
import {
  DeckCardEditorDialog,
  DeckCardEditorDialogProps,
} from '../DeckCardEditorDialog';

export interface DeckEditCardDialogProps
  extends Omit<
    DeckCardEditorDialogProps,
    'card' | 'submitButtonLabel' | 'onSubmit' | 'imageUrl' | 'type'
  > {
  card: DeckContentCard;
}

export const DeckEditCardDialog: React.FC<DeckEditCardDialogProps> = ({
  open,
  card,
  ...other
}) => {
  const { db, imageUrl } = useFirebase();
  const cardApi = useCard(card.id);
  const deck = useDeck();
  const cardData: DeckCardEditorData = {
    category: card.category,
    subcategories: card.subcategories,
    question: card.question,
    answer: card.answer,
    explanation: card.explanation,
    summary: card.summary,
    note: card.note || null,
    noteSelection: null,
  };

  function handleSubmit(data: DeckCardEditorData) {
    const {
      question,
      answer,
      explanation,
      summary,
      category,
      subcategories,
    } = data;

    cardApi.update({
      question,
      answer,
      explanation,
      summary,
      note: data.note || db.delete(),
    });
    if (
      data.category !== card.category ||
      !isEqual(subcategories, card.subcategories)
    ) {
      deck.updateDeckCard(card.id, { category, subcategories });
    }

    if (card.summary && !data.summary) {
      deck.deleteCheatSheetItem(card.id);
    } else if (data.summary) {
      deck.addCardToCheatSheet(
        generateCardCheatSheetItem({ id: card.id, summary: data.summary }),
      );
    }

    if (data.note) {
      if (!data.noteSelection) {
        addCardLink(db, data.note, card.id);
      } else {
        const note = deck.notes.find(deckNote => deckNote.id === data.note);
        if (note) {
          const existingLinks = getSelectionCardLinks(
            deserializeDocument(note.content),
            data.noteSelection,
          );
          if (existingLinks.length) {
            existingLinks.forEach(([node]) => {
              removeNoteLink(db, node.cardId);
            });
          }
          addContentCardLink(
            db,
            {
              ...note,
              content: serializeDocument(
                removeCardLinkFromDocument(
                  deserializeDocument(note.content),
                  card.id,
                ),
              ),
            },
            data.noteSelection,
            card.id,
          );
        }
      }
    } else if (card.note) {
      removeCardLink(db, card.note, card.id);
    }
  }

  function handleDelete() {
    if (card.note) {
      removeCardLink(db, card.note, card.id);
    }
    cardApi.delete();
    deck.removeCardFromDeck(card.id);
    deck.deleteCheatSheetItem(card.id);
  }

  return (
    <DeckCardEditorDialog
      submitButtonLabel="Update"
      imageUrl={imageUrl}
      open={open}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      cardId={card.id}
      data={cardData}
      type={card.type}
      {...other}
    />
  );
};
