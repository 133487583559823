import { validateDoc, DocValidator } from '@21st-night/utils';
import { CARD_TYPES, Card, CardUpdateData } from '../types/Card';

export const NewDeckCardValidator: DocValidator<Card> = {
  type: [CARD_TYPES],
  id: 'string',
  createdAt: 'date',
  deck: 'string',
  author: 'string',
  owner: 'string',
  note: ['optional', 'string'],
  question: 'string',
  answer: 'string',
  explanation: ['null', 'string'],
  summary: ['null', 'string'],
};

export const DeckCardUpdateValidator: DocValidator<CardUpdateData> = {
  note: ['optional', 'string', 'delete'],
  question: ['optional', 'string'],
  answer: ['optional', 'string'],
  explanation: ['optional', 'string', 'null'],
  summary: ['optional', 'string', 'null'],
};

export function validateNewCard(doc: Card): boolean {
  return validateDoc(NewDeckCardValidator, doc);
}

export function validateCardUpdate(doc: CardUpdateData): boolean {
  return validateDoc(DeckCardUpdateValidator, doc);
}
