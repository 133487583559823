import React, { useCallback } from 'react';
import {
  createContext,
  selectRandomArrayElement,
  useFirebase,
  CollectionReference,
} from '@21st-night/utils';

import {
  ReviewRating,
  FlashcardReviewProgress,
  CardReviewProgress,
} from '../Review.types';
import { ReviewStateContext, useReviewState } from '../ReviewStateProvider';
import * as api from '../api';
import { generateReviewSession } from '../api';

export interface ReviewProviderProps {
  cardProgressCollectionRef: CollectionReference;
  reviewSessionCollectionRef: CollectionReference;
  onRateCard?: (
    cardId: string,
    newProgress: FlashcardReviewProgress | CardReviewProgress,
    sessionId: string | null,
    duration?: number,
  ) => void;
  onFinishReviewSession?: (sessionId: string) => void;
}

type RateFlashcard = (
  cardId: string,
  currentProgress: FlashcardReviewProgress | null,
  rating: ReviewRating,
  duration?: number,
) => void;
type RateErrorLog = (
  cardId: string,
  currentProgress: CardReviewProgress | null,
  rating: ReviewRating,
  duration?: number,
) => void;
type SetCardRating = (
  cardId: string,
  progress: CardReviewProgress | FlashcardReviewProgress,
  duration?: number,
) => void;
type SkipCurrentCard = () => void;
type StartReviewSession = (
  cards: string[],
  firstCard?: string,
) => Promise<void>;
type EndReviewSession = () => Promise<void>;
type CheckWrittenAnswer = (answerText: string) => void;
type ResetViewState = () => void;

export interface ReviewContext extends ReviewStateContext {
  rateFlashcard: RateFlashcard;
  rateErrorLog: RateErrorLog;
  setCardRating: SetCardRating;
  skipCurrentCard: SkipCurrentCard;
  startReviewSession: StartReviewSession;
  endReviewSession: EndReviewSession;
  checkWrittenAnswer: CheckWrittenAnswer;
  resetViewState: ResetViewState;
}

const [hook, Provider] = createContext<ReviewContext>();

export const ReviewProvider: React.FC<ReviewProviderProps> = ({
  children,
  onRateCard,
  onFinishReviewSession,
  cardProgressCollectionRef,
  reviewSessionCollectionRef,
}) => {
  const { db } = useFirebase();
  const state = useReviewState();

  const endReviewSession: EndReviewSession = useCallback(async () => {
    if (!state.sessionId) {
      return;
    }

    api.updateReviewSession(reviewSessionCollectionRef, state.sessionId, {
      completed: true,
    });

    if (onFinishReviewSession) {
      onFinishReviewSession(state.sessionId);
    }

    state.setSessionId(null);
  }, [reviewSessionCollectionRef, state.sessionId]);

  const resetViewState = useCallback(() => {
    const nextRemainingCards = state.remainingCards.filter(
      cardId => cardId !== state.currentCard,
    );
    const nextCard = nextRemainingCards.length
      ? selectRandomArrayElement(nextRemainingCards)
      : null;

    if (!nextCard) {
      endReviewSession();
    }

    state.setWrittenAnswer('');
    state.setWrittenAnswerState('unchecked');
    state.setView('question');

    state.setCurrentCard(nextCard);

    if (state.mode === 'answer-first') {
      state.setView('answer');
    } else {
      state.setView('question');
    }
  }, [state.remainingCards, state.mode, state.currentCard]);

  const updateCardState = useCallback(
    (cardId: string, rating: ReviewRating) => {
      if (rating === 'correct' || rating === 'retire') {
        state.addCompletedCard(cardId);
      } else {
        state.addAttemptedCard(cardId);
      }
    },
    [],
  );

  const setCardRating: SetCardRating = useCallback(
    (cardId, progress, duration) => {
      cardProgressCollectionRef.doc(cardId).set(progress);

      if (state.sessionId) {
        api.updateReviewSession(reviewSessionCollectionRef, state.sessionId, {
          duration: db.increment(duration || 0),
          cards: db.arrayUnion(cardId),
          cardAttempts: {
            [cardId]: db.arrayUnion(progress.attempts.slice(-1)[0]),
          },
        });
      }

      if (onRateCard) {
        onRateCard(cardId, progress, state.sessionId);
      }
    },
    [cardProgressCollectionRef, onRateCard, state.sessionId, state.mode],
  );

  const rateFlashcard: RateFlashcard = useCallback(
    (cardId, currentProgress, rating, duration) => {
      updateCardState(cardId, rating);

      // Only use the first rating attempt to calculate
      // the card progress
      if (!state.attemptedCards.includes(cardId)) {
        const nextProgress = api.rateFlashcard(
          currentProgress,
          rating,
          duration,
        );
        setCardRating(cardId, nextProgress, duration);
      }

      resetViewState();
    },
    [
      db,
      cardProgressCollectionRef,
      state.sessionId,
      state.mode,
      state.attemptedCards,
    ],
  );

  const rateErrorLog: RateErrorLog = useCallback(
    (cardId, currentProgress, rating, duration) => {
      updateCardState(cardId, rating);

      // Only use the first rating attempt to calculate
      // the card progress
      if (!state.attemptedCards.includes(cardId)) {
        const nextProgress = api.rateErrorLog(
          currentProgress,
          rating,
          duration,
        );
        setCardRating(cardId, nextProgress, duration);
      }

      resetViewState();
    },
    [
      db,
      cardProgressCollectionRef,
      state.sessionId,
      state.mode,
      state.attemptedCards,
    ],
  );

  const skipCurrentCard: SkipCurrentCard = useCallback(() => {
    if (!state.currentCard) {
      return;
    }

    state.addCompletedCard(state.currentCard);
    resetViewState();
  }, [state.currentCard]);

  const checkWrittenAnswer: CheckWrittenAnswer = useCallback(
    answerText => {
      const isCorrect = api.checkWrittenAnswer(
        answerText,
        state.writtenAnswer.trim(),
      );

      state.setWrittenAnswerState(isCorrect ? 'correct' : 'incorrect');
      state.setView('answer');
    },
    [state.writtenAnswer],
  );

  const startReviewSession: StartReviewSession = useCallback(
    async (cards, firstCard) => {
      const session = generateReviewSession();

      await api.createReviewSession(reviewSessionCollectionRef, session);

      state.reset({
        sessionId: session.id,
        cards,
        remainingCards: cards,
        currentCard: firstCard || selectRandomArrayElement(cards),
        view: state.mode === 'answer-first' ? 'answer' : 'question',
        mode: state.mode,
      });
    },
    [reviewSessionCollectionRef, state.mode],
  );

  return (
    <Provider
      value={{
        ...state,
        rateFlashcard,
        rateErrorLog,
        setCardRating,
        skipCurrentCard,
        checkWrittenAnswer,
        startReviewSession,
        endReviewSession,
        resetViewState,
      }}
    >
      {children}
    </Provider>
  );
};

export const useReview = hook;
