import { DB } from '@21st-night/utils';
import chunck from 'lodash/chunk';
import { StudyPlanDay } from '../../types';

export function createStudyPlanDays(
  db: DB,
  userId: string,
  days: StudyPlanDay[],
): Promise<void[]> {
  const promises: Promise<void>[] = [];
  const daysRef = db
    .collection('users')
    .doc(userId)
    .collection('study-plan')
    .doc('plan')
    .collection('days');
  // Firestore batch supports up to 500 opperations
  const chuncks = chunck(days, 500);

  chuncks.forEach(chunck => {
    const batch = db.batch();
    chunck.forEach(day => {
      batch.set(daysRef.doc(day.id), day);
    });

    promises.push(batch.commit());
  });

  return Promise.all(promises);
}
