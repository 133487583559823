import { toDate, DocumentSnapshot } from '@21st-night/utils';
import {
  OnboardingStudyingFor,
  OnboardingStudyMethod,
  StandardizedTest,
} from '@21st-night/onboarding';
import { UserDocument, User } from '../../User.types';

export function deserializeUserDocument(snapshot: DocumentSnapshot): User {
  const {
    lastActivity,
    lastDailyReport,
    ...data
  } = snapshot.data() as UserDocument;

  const user: User = {
    ...data,
    createdAt: toDate(data.createdAt),
    subscriptionEnd:
      data.subscriptionEnd === false ? false : toDate(data.subscriptionEnd),
    lastStreakIncrement: data.lastStreakIncrement
      ? toDate(data.lastStreakIncrement)
      : null,
  };

  if (lastActivity) {
    user.lastActivity = toDate(lastActivity);
  }

  if (lastDailyReport) {
    user.lastDailyReport = toDate(lastDailyReport);
  }

  // Set role for legacy users
  if (!data.role && data.segmentation && data.segmentation.values) {
    const segValues = data.segmentation.values;

    if (segValues[0] === 'tutoring' && segValues[1] === 'tutor') {
      user.role = 'tutor';
    } else if (
      segValues[0] === 'self-studying' ||
      (segValues[0] === 'tutoring' && segValues[1] === 'student')
    ) {
      user.role = 'student';
    } else if (segValues[0] === 'tutoring' && segValues[1] === 'parent') {
      user.role = 'parent';
    } else {
      user.role = 'student';
    }

    if (!user.role) {
      user.role = 'student';
    }
  }

  // Create SegmentationData from segmentation.values for legacy users
  if (user.segmentation && !user.segmentation.userType) {
    const studyingFor =
      user.segmentation.values[0] === 'self-studying'
        ? (user.segmentation.values[1] as OnboardingStudyingFor)
        : (user.segmentation.values[2] as OnboardingStudyingFor);
    user.segmentation = {
      ...user.segmentation,
      completed: true,
      userType: user.role,
      method: user.segmentation.values[0] as OnboardingStudyMethod,
      for: studyingFor || null,
    };

    const contentType =
      user.segmentation.values[0] === 'self-studying'
        ? (user.segmentation.values[2] as StandardizedTest)
        : (user.segmentation.values[3] as StandardizedTest);

    if (user.segmentation.userType !== 'parent') {
      user.segmentation.contentType = contentType;
    }

    if (studyingFor === 'standardized-test') {
      user.segmentation.standardizedTest = contentType;
    }
  }

  return user;
}
