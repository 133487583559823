import React from 'react';
import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
} from '@material-ui/core';

export type FormControlProps = MuiFormControlProps;

export const FormControl: React.FC<FormControlProps> = ({ ...other }) => {
  return <MuiFormControl {...other} />;
};
