import { Text } from 'slate';
import { stripDocumentMetadata } from '../stripDocumentMetadata';
import {
  EditorDocument,
  EditorBlockElement,
  EditorInlineElement,
  RichTextNode,
} from '../../Editor.types';
import { isElement } from '../isElement';

function renderLeaves(
  element: EditorBlockElement | EditorInlineElement,
): string {
  const children = element.children as
    | (Text | EditorInlineElement)[]
    | undefined;

  if (!children) {
    return '';
  }

  let html = '';

  children.forEach(child => {
    let htmlElement = '';

    if (isElement(child)) {
      if (child.type === 'link') {
        htmlElement = renderLeaves(child as EditorInlineElement);
        htmlElement = `<a href="${child.url}">${htmlElement}</a>`;
      } else if (child.type === 'equation-inline') {
        htmlElement = `<code>${child.tex}</code>`;
      }
    } else {
      const textNode = child as RichTextNode;

      htmlElement = `${textNode.text}`;

      if (textNode.i) {
        htmlElement = `<em>${htmlElement}</em>`;
      }

      if (textNode.b) {
        htmlElement = `<strong>${htmlElement}</strong>`;
      }

      if (textNode.u) {
        htmlElement = `<u>${htmlElement}</u>`;
      }

      if (textNode.s) {
        htmlElement = `<s>${htmlElement}</s>`;
      }
    }

    html = `${html}${htmlElement}`;
  });

  return html;
}

function renderList(type: string, elements: EditorBlockElement[]): string {
  let list = `<${type}>`;

  elements.forEach(element => {
    list = `${list}<li>${renderLeaves(element)}</li>`;
  });

  return `${list}</${type}>`;
}

export function toHTML(doc: EditorDocument): string {
  const [document] = stripDocumentMetadata(doc);
  let html = '';

  let olElements: EditorBlockElement[] = [];
  let ulElements: EditorBlockElement[] = [];

  document.forEach(node => {
    const element = node as EditorBlockElement;

    if (olElements.length && element.type !== 'ol') {
      html = `${html}${renderList('ol', olElements)}`;
      olElements = [];
    }

    if (ulElements.length && element.type !== 'ul') {
      html = `${html}${renderList('ul', ulElements)}`;
      ulElements = [];
    }

    if (element.type === 'ol') {
      olElements.push(element);
    } else if (element.type === 'ul') {
      ulElements.push(element);
    } else if (element.type === 'video') {
      html = `${html}<a href="${element.url}">${element.url}</a>`;
    } else if (element.type === 'tex') {
      html = `${html}<pre>${element.tex}</pre>`;
    } else if (element.type === 'image') {
      html = `${html}<img src="${element.id}" />`;
    } else if (element.type === 'audio') {
      html = `${html}<audio src="${element.id}" />`;
    } else if (element.children) {
      let htmlElement: string = element.type;

      if (element.type === 'paragraph') {
        htmlElement = 'p';
      } else if (element.type === 'code') {
        htmlElement = 'pre';
      }

      html = `${html}<${htmlElement}>${renderLeaves(element)}</${htmlElement}>`;
    }
  });

  return html;
}
