import { v4 as uuid } from 'uuid';
import { StudyPlanTask } from '../../types';

export function generateStudyPlanTask(
  startDate: Date,
  endDate: Date,
): StudyPlanTask {
  return {
    createdAt: new Date(),
    days: [],
    subtasks: [],
    action: '',
    count: 0,
    object: '',
    source: '',
    startDate,
    endDate,
    id: uuid(),
  };
}
