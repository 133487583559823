import React from 'react';
import { ChevronRight } from '@21st-night/icons';
import {
  MenuItem as MuiMenuItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
} from '@21st-night/ui';
import { makeStyles, createStyles, cn } from '@21st-night/styles';

export interface MobileMenuItemProps
  extends React.HTMLAttributes<HTMLLIElement> {
  hasSubmenu?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  icon?: React.ReactNode;
  image?: string;
  imageSize?: 'small' | 'large';
  secondaryText?: React.ReactNode;
  primaryText?: React.ReactNode;
}

const userStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 6,
      paddingRight: 6,
      borderRadius: 3,
      marginLeft: 6,
      marginRight: 6,
      //   '&:hover': {
      //     background: 'none',
      //     color: '#235CE0',
      //   },
    },
    listItemIcon: {
      minWidth: 18,
      marginRight: theme.spacing(1),
    },
    listItemAvatar: {
      minWidth: 0,
      marginRight: theme.spacing(1),
    },
    imageLarge: {
      borderRadius: 3,
      width: 38,
      height: 38,
      boxShadow: 'rgba(15, 15, 15, 0.1) 0px 0px 0px 1px',
    },
    imageSmall: {
      borderRadius: 3,
      width: 24,
      height: 24,
      boxShadow: 'rgba(15, 15, 15, 0.1) 0px 0px 0px 1px',
    },
    textPrimary: {
      fontSize: theme.typography.pxToRem(14),
    },
    textSecondary: {
      marginTop: 2,
      fontSize: theme.typography.pxToRem(12),
    },
    submenuIcon: {
      marginRight: -6,
    },
  }),
);

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  onClick,
  children,
  icon,
  image,
  imageSize = 'large',
  hasSubmenu,
  primaryText,
  secondaryText,
  ...other
}) => {
  const classes = userStyles();

  const menuItem = (
    <MuiMenuItem
      dense
      classes={{ root: classes.root }}
      {...other}
      onClick={onClick}
      button={onClick ? true : undefined}
    >
      {icon && (
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      )}
      {image && (
        <ListItemAvatar className={cn(classes.listItemAvatar)}>
          <Avatar
            src={image}
            className={cn({
              [classes.imageLarge]: imageSize === 'large',
              [classes.imageSmall]: imageSize === 'small',
            })}
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        classes={{
          primary: classes.textPrimary,
          secondary: classes.textSecondary,
        }}
      >
        {children}
      </ListItemText>
      {hasSubmenu && (
        <ChevronRight color="disabled" className={classes.submenuIcon} />
      )}
    </MuiMenuItem>
  );

  return menuItem;
};
