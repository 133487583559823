import React, { useEffect } from 'react';
import { Button, InputBase } from '@21st-night/ui';
import { makeStyles } from '@21st-night/styles';
import { useMobileEditorToolbar } from '../../../MobileEditor';

export interface MobileLinkToolbarProps {
  onClickOpenLink?: () => void;
  onClickRemoveLink: () => void;
  onClickDone: () => void;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    padding: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export const MobileLinkToolbar: React.FC<MobileLinkToolbarProps> = ({
  onClickDone,
  onClickOpenLink,
  onClickRemoveLink,
  onChange,
  value,
}) => {
  const classes = useStyles();

  const { hideEditorToolbar, showEditorToolbar } = useMobileEditorToolbar();

  useEffect(() => {
    hideEditorToolbar();

    return showEditorToolbar;
  }, []);

  function handleClickDone() {
    showEditorToolbar();
    onClickDone();
  }
  function handleClickRemove() {
    showEditorToolbar();
    onClickRemoveLink();
  }

  return (
    <div contentEditable={false} className={classes.root}>
      <InputBase
        fullWidth
        autoFocus={!value}
        autoCapitalize="none"
        spellCheck={false}
        placeholder="Paste your link, such as http://wikipedia.org..."
        value={value}
        onChange={onChange}
        className={classes.input}
      />
      <div className={classes.buttons}>
        <Button size="small" onMouseDown={handleClickRemove}>
          Remove link
        </Button>
        <Button size="small" onMouseDown={onClickOpenLink}>
          Open link
        </Button>
        <Button size="small" color="primary" onMouseDown={handleClickDone}>
          Done
        </Button>
      </div>
    </div>
  );
};
