import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Typography, Link, LightboxImage } from '@21st-night/ui';
import { Text } from 'slate';
import katex from 'katex';
import {
  EditorDocument,
  EditorBlockElement,
  ImageElement,
  EditorInlineElement,
  isText,
} from '@21st-night/editor';
import AudioPlayer from 'react-player';
import { BulletedListItem } from '../BulletedListItem';
import { NumberedListItem } from '../NumberedListItem';
import { RichTextLeaf } from '../RichTextLeaf';
import { MediaPlayer } from '../MediaPlayer';
import { renderCode } from './renderCode';

type Classes = Record<string, string>;

export interface RichTextProps extends React.HTMLAttributes<HTMLDivElement> {
  imageUrl: string;
  document: EditorDocument;
}

export interface RichTextOptions {
  imageUrl: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    image: {
      width: '100%',
      margin: '8px 0',
    },
    audioPlayer: {
      userSelect: 'none',
      maxHeight: 56,
      margin: theme.spacing(1, 0),
      '& > audio': {
        outline: 'none',
        height: 56,
      },
    },
  }),
);

function renderLeaves(
  element: EditorBlockElement | EditorInlineElement,
  classes: Classes,
  options: RichTextOptions,
) {
  const id = element.id;
  const children = element.children as Text[] | undefined;

  if (!children) {
    return;
  }

  return children.map((leaf, index) => {
    if (isText(leaf)) {
      const texts = leaf.text ? leaf.text.split('\n') : [''];

      return texts.map((text, textIndex) => (
        <>
          <RichTextLeaf
            key={`${id}-${index}-${textIndex}`}
            leaf={{ ...leaf, text }}
          />
          {textIndex !== texts.length - 1 && <br />}
        </>
      ));
    }

    return renderInlineElement(leaf, classes, options);
  });
}

function renderImage(element: ImageElement, options: RichTextOptions) {
  if (!element.file) {
    return '';
  }

  let srcCrop = '';

  if (element.crop && element.dimensions) {
    const { dimensions, crop } = element;
    const cropXPercent = (crop.x || 0) / 100;
    const cropYPercent = (crop.y || 0) / 100;
    const cropWidthPercent = (crop.width || 100) / 100;
    const cropHeightPercent = (crop.height || 100) / 100;
    const x = Math.round(cropXPercent * dimensions.width);
    const y = Math.round(cropYPercent * dimensions.height);
    const width = Math.round(dimensions.width * cropWidthPercent);
    const height = Math.round(dimensions.height * cropHeightPercent);

    srcCrop += `rect=${x},${y},${width},${height}`;
  }

  return (
    <div style={{ width: '100%', margin: '8px 0' }}>
      <LightboxImage
        src={`${options.imageUrl}/${element.file}?w=1400${
          srcCrop ? `&${srcCrop}` : ''
        }`}
        expandedSrc={`${options.imageUrl}/${element.file}${
          srcCrop ? `?${srcCrop}` : ''
        }`}
      />
    </div>
  );
}

function renderInlineElement(
  element: EditorInlineElement,
  classes: Classes,
  options: RichTextOptions,
) {
  switch (element.type) {
    case 'link':
      return (
        <Link
          href={element.url}
          target="blank"
          rel="noreferrer"
          key={element.id}
        >
          {renderLeaves(element, classes, options)}
        </Link>
      );
    case 'equation-inline':
      return element.tex ? (
        <span
          key={element.id}
          dangerouslySetInnerHTML={{
            __html: katex.renderToString(element.tex, {
              throwOnError: false,
            }),
          }}
        />
      ) : (
        <span key={element.id} />
      );
    case 'cloze-deletion':
      return element.hint ? (
        <span
          key={element.id}
          style={{ padding: 2, backgroundColor: '#80D8FF', borderRadius: 2 }}
        >
          {element.hint}
        </span>
      ) : (
        <span
          key={element.id}
          style={{
            padding: '2px 16px',
            backgroundColor: '#80D8FF',
            borderRadius: 2,
          }}
        >
          ?
        </span>
      );
    case 'card-link':
      return (
        <span key={element.id}>{renderLeaves(element, classes, options)}</span>
      );
    default:
      return '';
  }
}

function renderBlockElement(
  element: EditorBlockElement,
  classes: Classes,
  options: RichTextOptions,
  isLast = false,
) {
  const id = element.id;

  switch (element.type) {
    case 'h1':
      return (
        <Typography
          gutterBottom
          variant="h1"
          key={id}
          style={{
            maxWidth: '100%',
            width: '100%',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            caretColor: 'inherit',
            color: 'inherit',
            fontWeight: 600,
            fontSize: '1.875em',
            lineHeight: '1.3',
            marginTop: '1em',
            marginBottom: 4,
          }}
        >
          {renderLeaves(element, classes, options)}
        </Typography>
      );
    case 'h2':
      return (
        <Typography
          gutterBottom
          variant="h2"
          key={id}
          style={{
            maxWidth: '100%',
            width: '100%',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            caretColor: 'inherit',
            color: 'inherit',
            fontWeight: 600,
            fontSize: '1.5em',
            lineHeight: '1.3',
            marginTop: '0.7em',
            marginBottom: 4,
          }}
        >
          {renderLeaves(element, classes, options)}
        </Typography>
      );
    case 'blockquote':
      return (
        <Typography
          key={id}
          style={{
            margin: '8px 0',
            maxWidth: '100%',
            width: '100%',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            caretColor: 'inherit',
            color: 'inherit',
            fontSize: '1.2em',
            lineHeight: '1.3',
            marginBottom: 4,
            paddingLeft: '0.9em',
            paddingRight: '0.9em',
            borderLeft: '3px solid currentcolor',
          }}
        >
          {renderLeaves(element, classes, options)}
        </Typography>
      );
    case 'ul':
      return (
        <BulletedListItem key={id}>
          {renderLeaves(element, classes, options)}
        </BulletedListItem>
      );
    case 'ol':
      return (
        <NumberedListItem element={element} key={id}>
          {renderLeaves(element, classes, options)}
        </NumberedListItem>
      );
    case 'code':
      return renderCode(element);
    case 'image':
      return renderImage(element, options);
    case 'video':
      return element.url ? (
        <div key={id} style={{ margin: '8px 0' }}>
          <MediaPlayer url={element.url} />
        </div>
      ) : (
        <span key={id} />
      );
    case 'audio':
      return element.url ? (
        <AudioPlayer
          key={id}
          config={{ file: { forceAudio: true } }}
          controls
          url={element.url}
          width="100%"
          className={classes.audioPlayer}
        />
      ) : (
        <span key={id} />
      );
    case 'tex':
      return element.tex ? (
        <div
          key={id}
          dangerouslySetInnerHTML={{
            __html: katex.renderToString(element.tex, {
              throwOnError: false,
              displayMode: true,
            }),
          }}
        />
      ) : (
        <span key={id} />
      );
    default:
      return (
        <Typography paragraph={!isLast} key={id}>
          {renderLeaves(element, classes, options)}
        </Typography>
      );
  }
}

export const RichText: React.FC<RichTextProps> = ({
  className,
  document: documentProp,
  imageUrl,
  ...other
}) => {
  const classes = useStyles();
  let document = documentProp;

  // Remove metadata
  if (document.length && document[0].type === 'metadata') {
    document = documentProp.slice(1);
  }

  return (
    <div className={cn(classes.root, className)} {...other}>
      {document.map((element, index) =>
        renderBlockElement(
          element,
          classes,
          { imageUrl },
          document.length - 1 === index,
        ),
      )}
    </div>
  );
};
