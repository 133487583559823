import React from 'react';
import {
  ListItem,
  ListItemProps,
  ListItemText,
  ListItemIcon,
} from '@21st-night/ui';
import { CheckBoxOutline, CheckBoxOutlineBlank } from '@21st-night/icons';
import { makeStyles, createStyles, cn } from '@21st-night/styles';

export const CheckedIcon = CheckBoxOutline;
export const UncheckedIcon = CheckBoxOutlineBlank;

export interface OnboardingChecklistItemProps extends ListItemProps {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  checked?: boolean;
  onCheck?: () => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    primary: {
      fontSize: '1.2em',
      color: '#0D3C61',
    },
    secondary: {
      color: '#0D3C61',
      opacity: 0.86,
    },
    listItemIcon: {
      color: '#0D3C61',
      minWidth: 56,
    },
    iconContainer: {
      position: 'relative',
      outline: 'none',
      cursor: 'pointer',
    },
    checkedIcon: {
      transition: theme.transitions.create(['max-width'], {
        duration: '800ms',
      }),
      position: 'absolute',
      top: 0,
      left: 0,
      maxWidth: 0,
      overflow: 'hidden',
    },
    checkedIconIn: {
      maxWidth: 35,
    },
  }),
);

export const OnboardingChecklistItem: React.FC<OnboardingChecklistItemProps> = ({
  primary,
  secondary,
  checked,
  onCheck,
  ...other
}) => {
  const classes = useStyles();

  return (
    <ListItem {...other}>
      <ListItemIcon className={classes.listItemIcon}>
        <div
          role="button"
          className={classes.iconContainer}
          tabIndex={0}
          onClick={onCheck}
        >
          <UncheckedIcon color="inherit" fontSize="large" />
          <div
            className={cn(
              classes.checkedIcon,
              checked && classes.checkedIconIn,
            )}
          >
            <CheckedIcon color="inherit" fontSize="large" />
          </div>
        </div>
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.primary, secondary: classes.secondary }}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};
