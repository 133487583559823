import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '@21st-night/ui';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    title: {
      margin: theme.spacing(2, 0, 1),
    },
  }),
);

export const ImportFromAnkiError: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        An error occured while trying to import the deck. Please try again.
      </Typography>
    </div>
  );
};
