import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography, Button, Link } from '@21st-night/ui';

export interface ImportFromCsvFileSelectionProps {
  mediaFileInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  csvFileInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  csvFile?: File;
  mediaFile?: File;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    section: {
      marginBottom: theme.spacing(3),
    },
    fileInput: {
      opacity: 0,
      pointerEvents: 'none',
      position: 'fixed',
      left: -1000,
      top: -1000,
    },
    instructions: {
      marginTop: theme.spacing(2),
    },
  }),
);

function shortenFileName(file: File) {
  if (file.name.length > 30) {
    return `${file.name.slice(0, 30).trim()}...`;
  }

  return file.name;
}

export const ImportFromCsvFileSelection: React.FC<ImportFromCsvFileSelectionProps> = ({
  mediaFileInputProps = {},
  csvFileInputProps = {},
  mediaFile,
  csvFile,
}) => {
  const classes = useStyles();
  const mediaFileInput = useRef<HTMLInputElement>(null);
  const csvFileInput = useRef<HTMLInputElement>(null);

  function openCsvFileSelect() {
    if (csvFileInput.current) {
      csvFileInput.current.click();
    }
  }

  function openMediaFileSelect() {
    if (mediaFileInput.current) {
      mediaFileInput.current.click();
    }
  }

  return (
    <>
      <div className={classes.section}>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={openCsvFileSelect}
        >
          {csvFile ? shortenFileName(csvFile) : 'Choose a CSV file'}
        </Button>
      </div>
      <div className={classes.section}>
        <Typography variant="subtitle1">Media</Typography>
        <Typography gutterBottom color="textSecondary">
          You can include image and audio files by combining them into a single
          zip file.
        </Typography>
        <Button
          color="primary"
          fullWidth
          variant="outlined"
          onClick={openMediaFileSelect}
        >
          {mediaFile ? shortenFileName(mediaFile) : 'Add media'}
        </Button>
        <Typography className={classes.instructions} color="textSecondary">
          Detailed instructions:{' '}
          <Link
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/stnight-production.appspot.com/o/site-files%2FIncluding%20media%20in%20a%20CSV%20import%20(Windows).pdf?alt=media&token=91292b3e-1ada-4ae4-9b1f-35b9ef983bf4"
          >
            Windows
          </Link>{' '}
          |{' '}
          <Link
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/stnight-production.appspot.com/o/site-files%2FIncluding%20media%20in%20a%20CSV%20import%20(Mac).pdf?alt=media&token=59922636-fa22-4445-bd7c-b21d08c05029"
          >
            Mac
          </Link>
        </Typography>
      </div>
      {createPortal(
        <input
          accept=".csv, .tsv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .txt"
          {...csvFileInputProps}
          ref={csvFileInput}
          type="file"
          className={classes.fileInput}
        />,
        document.body,
      )}
      {createPortal(
        <input
          accept="application/zip"
          {...mediaFileInputProps}
          ref={mediaFileInput}
          type="file"
          className={classes.fileInput}
        />,
        document.body,
      )}
    </>
  );
};
