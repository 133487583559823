import { DocumentReference } from '@21st-night/utils';
import { updateDeckNote } from '../updateDeckNote';

export function setNoteCategory(
  deckRef: DocumentReference,
  noteId: string,
  category: string | null,
): Promise<void> {
  return updateDeckNote(deckRef, noteId, {
    category,
  });
}
