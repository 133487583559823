import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
} from '@material-ui/core';

export type AppBarProps = MuiAppBarProps;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
  }),
);

export const AppBar: React.FC<AppBarProps> = ({ className, ...other }) => {
  const classes = useStyles();
  return <MuiAppBar className={clsx(classes.root, className)} {...other} />;
};
