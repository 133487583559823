import { DB } from '@21st-night/utils';
import { deserializeDocument, serializeDocument } from '@21st-night/editor';
import { removeCardLinkFromDocument } from '@21st-night/cards';
import { Note } from '../../Notes.types';
import { updateNote } from '../updateNote';

export function removeContentCardLink(
  db: DB,
  note: Note,
  cardId: string,
): Promise<void> {
  const content = removeCardLinkFromDocument(
    deserializeDocument(note.content),
    cardId,
  );

  return updateNote(db, note.id, {
    content: serializeDocument(content),
    cards: db.arrayRemove(cardId),
  });
}
