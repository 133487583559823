import { parseNativeEditorEvent } from '../parseNativeEditorEvent';
import {
  NativeEditorEventType,
  NativeFileUploadCompleteEvent,
  NativeFileUploadErrorEvent,
  NativeFileUploadProgressEvent,
} from '../../types';

type FileEvent =
  | NativeFileUploadProgressEvent
  | NativeFileUploadErrorEvent
  | NativeFileUploadCompleteEvent;
type IgnoreEvent = { type: 'IGNORE' };

export function parseNativeEditorFileEvent(
  message: string,
  targetFileId: string,
): FileEvent | IgnoreEvent {
  const event = parseNativeEditorEvent(message) as FileEvent;
  const matchEvents: NativeEditorEventType[] = [
    'FILE_UPLOAD_PROGRESS',
    'FILE_UPLOAD_ERROR',
    'FILE_UPLOAD_COMPLETE',
  ];

  if (
    matchEvents.includes(event.type) &&
    event.payload.fileId === targetFileId
  ) {
    return event;
  }

  return { type: 'IGNORE' };
}
