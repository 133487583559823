/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { EditorHoveringToolbar } from '../EditorHoveringToolbar';
import { webEditorPlugins, WebEditor } from '../web-editor-plugins';
import { BaseEditor, BaseEditorProps } from '../BaseEditor';
import { BasicEditorToolbar } from './BasicEditorToolbar';

export type BasicEditorProps = BaseEditorProps;

export type BasicEditor = WebEditor;

export const BasicEditor: React.FC<BasicEditorProps> = ({
  children,
  plugins: pluginsProp = [],
  ...other
}) => {
  return (
    <BaseEditor plugins={[...webEditorPlugins, ...pluginsProp]} {...other}>
      <BasicEditorToolbar />
      <EditorHoveringToolbar />
      {children}
    </BaseEditor>
  );
};
