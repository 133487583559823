import React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';

export type CheckboxProps = MuiCheckboxProps;

export const Checkbox: React.FC<CheckboxProps> = ({ ...other }) => {
  return <MuiCheckbox disableRipple {...other} />;
};
