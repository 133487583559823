import { useState, useEffect } from 'react';
import { DB, toDate } from '@21st-night/utils';

import { StudyPlanTask } from '../../types';

export function useWatchStudyPlanTasks(
  db: DB,
  userId: string,
): [StudyPlanTask[]] {
  const [tasks, setTasks] = useState<StudyPlanTask[]>([]);

  useEffect(() => {
    let mounted = true;

    const unsubscribe = db
      .collection('users')
      .doc(userId)
      .collection('study-plan')
      .doc('plan')
      .collection('tasks')
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        if (mounted && snapshot) {
          setTasks(
            snapshot.docs.map(
              doc =>
                ({
                  ...doc.data(),
                  startDate: toDate(doc.data().startDate),
                  endDate: toDate(doc.data().endDate),
                } as StudyPlanTask),
            ),
          );
        }
      });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  return [tasks];
}
