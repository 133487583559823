import { DB } from '@21st-night/utils';
import { validateNoteUpdate } from '../note-validator';
import { NoteUpdateData } from '../../Notes.types';

export function updateNote(
  db: DB,
  id: string,
  data: NoteUpdateData,
): Promise<void> {
  validateNoteUpdate(data);

  return db.collection('notes').doc(id).update(data);
}
