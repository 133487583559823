import { toDate, DocumentSnapshot } from '@21st-night/utils';
import { StudyPlanDay, StudyPlanDayDocument } from '../../types';

export function deserializeStudyPlanDayDocument(
  doc: DocumentSnapshot,
): StudyPlanDay {
  const data = doc.data() as StudyPlanDayDocument;

  return {
    ...data,
    createdAt: toDate(data.createdAt),
    date: toDate(data.date),
  };
}
