import React from 'react';
import { makeStyles } from '@21st-night/styles';
import { deserializeDocument } from '@21st-night/editor-web';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  Button,
} from '@21st-night/ui';
import { Card } from '@21st-night/cards';
import { CardPreview, CardPreviewView } from '../CardPreview/CardPreview';

export interface CardPreviewDialogProps extends DialogProps {
  card: Card;
  imageUrl: string;
  defaultView?: CardPreviewView;
}

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
  },
}));

export const CardPreviewDialog: React.FC<CardPreviewDialogProps> = ({
  open,
  card,
  imageUrl,
  onClose,
  defaultView,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} {...other}>
      <DialogContent className={classes.content}>
        <CardPreview
          defaultView={defaultView}
          imageUrl={imageUrl}
          question={deserializeDocument(card.question)}
          answer={deserializeDocument(card.answer)}
          explanation={
            card.explanation ? deserializeDocument(card.explanation) : null
          }
          summary={card.summary ? deserializeDocument(card.summary) : null}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
