import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Tooltip } from '../Tooltip';

export interface InfoTextTooltipProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  tooltipContent: NonNullable<React.ReactNode>;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      color: theme.palette.primary.main,
    },
    tooltip: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.getContrastText(theme.palette.background.paper),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 3,
      boxShadow: theme.shadows[8],
    },
    arrow: {
      color: theme.palette.background.paper,
    },
  }),
);

export const InfoTextTooltip: React.FC<InfoTextTooltipProps> = ({
  className,
  children,
  tooltipContent,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      interactive
      arrow
      title={tooltipContent}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
    >
      <span className={cn(classes.text, className)} {...other}>
        {children}
      </span>
    </Tooltip>
  );
};
