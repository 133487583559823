import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Paper, Typography, LinearProgress, Button } from '@21st-night/ui';

export interface OnboardingBannerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  currentStep: number;
  stepCount: number;
  onClickSkip: (event: React.MouseEvent) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: '#E9F4FD',
      overflow: 'hidden',
      display: 'flex',
      maxHeight: 200,
    },
    imageContainer: {
      position: 'relative',
    },
    imageOverlay: {
      boxShadow: 'inset -7px 0 4px -7px rgba(0,0,0,0.1)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    image: {
      height: '100%',
      maxHeight: 200,
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    content: {
      padding: theme.spacing(3),
      maxWidth: 800,
      margin: '0 auto',
      flex: 1,
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      color: '#0D3C61',
      opacity: 0.86,
      [theme.breakpoints.only('xs')]: {
        alignItems: 'flex-start',
      },
    },
    title: {
      flex: 1,
      textAlign: 'center',
      [theme.breakpoints.only('xs')]: {
        textAlign: 'left',
      },
    },
    progressBar: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
    },
  }),
);

export const OnboardingBanner: React.FC<OnboardingBannerProps> = ({
  className,
  children,
  currentStep,
  stepCount,
  onClickSkip,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <div className={classes.imageContainer}>
        <img
          src={require('../images/headshot.jpg')}
          className={classes.image}
        />
        <div className={classes.imageOverlay} />
      </div>
      <div className={classes.content}>
        <div className={classes.heading}>
          <Typography color="inherit" className={classes.title}>
            <strong>Pre-study checklist</strong>
          </Typography>
          <Button size="small" color="inherit" onClick={onClickSkip}>
            Skip
          </Button>
        </div>
        <div>{children}</div>
      </div>
      <LinearProgress
        variant="determinate"
        color="primary"
        value={(currentStep / stepCount) * 100}
        className={classes.progressBar}
      />
    </Paper>
  );
};
