import { RATING_VALUES, RECENT_ATTEMPTS_COUNT } from '../../constants';

/**
 * Calculates a percentage value for the given
 * proficiency score.
 *
 * @param score         The current proficiency score.
 * @param attemptsCount The number of attempts used to calculate the proficiency score.
 */
export function calculateProficiencyPercentage(
  score: number,
  attemptsCount = RECENT_ATTEMPTS_COUNT,
): number {
  // Calculate the score percentage by dividing the
  // new score by the maximum potential score for the
  // number of attempts made
  const scorePercentage = Math.round(
    (score / (attemptsCount * RATING_VALUES.correct)) * 100,
  );

  return scorePercentage;
}
