import { v4 as uuid } from 'uuid';
import { Deck, DeckType } from '../../Deck.types';

export function generateDeck(
  type: DeckType,
  options: Partial<Deck> = {},
): Deck {
  return {
    type,
    name: '',
    id: uuid(),
    createdAt: new Date(),
    categories: [],
    subcategories: [],
    roles: ['owner'],
    cards: {},
    notes: {},
    ...options,
  };
}
