import { Shadows } from '@material-ui/core/styles/shadows';

const shadows: Shadows = [
  'none',
  // 1
  '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
  '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
  '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
  '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
  // 2
  '0 3px 6px rgba(0, 0, 0, .15), 0 2px 4px rgba(0, 0, 0, .12)',
  '0 3px 6px rgba(0, 0, 0, .15), 0 2px 4px rgba(0, 0, 0, .12)',
  '0 3px 6px rgba(0, 0, 0, .15), 0 2px 4px rgba(0, 0, 0, .12)',
  '0 3px 6px rgba(0, 0, 0, .15), 0 2px 4px rgba(0, 0, 0, .12)',
  // 3
  '0 10px 20px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .10)',
  '0 10px 20px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .10)',
  '0 10px 20px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .10)',
  '0 10px 20px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .10)',
  // 4
  '0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05)',
  '0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05)',
  '0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05)',
  '0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05)',
  // 5
  '0 20px 40px rgba(0, 0, 0, .2)',
  '0 20px 40px rgba(0, 0, 0, .2)',
  '0 20px 40px rgba(0, 0, 0, .2)',
  '0 20px 40px rgba(0, 0, 0, .2)',
  // 6
  '0 30px 60px rgba(0, 0, 0, .2)',
  '0 30px 60px rgba(0, 0, 0, .2)',
  '0 30px 60px rgba(0, 0, 0, .2)',
  '0 30px 60px rgba(0, 0, 0, .2)',
];

export default shadows;
