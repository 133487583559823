import React from 'react';
import { useUI, useHardReturn } from '@braindrop-editor/core';
import { makeStyles } from '@21st-night/styles';
import { EquationError } from '../EquationError';
import { EnterIcon } from '../icons';
import { EquationTextarea } from '../EquationTextarea';

export interface EquationBlockPopoverProps {
  onClose: () => void;
  open: boolean;
  value: string;
  error?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  anchorEl: React.RefObject<HTMLDivElement>;
}

const useStyles = makeStyles(() => ({
  form: {
    padding: '8px 10px',
    width: 380,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  helperText: {
    fontSize: '11px',
    color: '#B6B5B3',
    display: 'flex',
    alignItems: 'center',
  },
  enterIcon: {
    marginRight: 4,
    marginLeft: -2,
  },
}));

export const EquationBlockPopover: React.FC<EquationBlockPopoverProps> = ({
  open,
  anchorEl,
  value,
  error,
  onClose,
  onChange,
}) => {
  const classes = useStyles();
  const { Button, Popover } = useUI();

  const handleFormSubmission = (event: React.FormEvent): void => {
    event.preventDefault();
    onClose();
  };

  return (
    <Popover open={open} anchorEl={anchorEl.current} onClose={onClose}>
      <form onSubmit={handleFormSubmission} className={classes.form}>
        <EquationTextarea
          defaultValue={value}
          onKeyDown={useHardReturn(onClose)}
          onChange={onChange}
          placeholder="\begin{aligned}                                          &nbsp;y &= 2x+x+4-2 \\                                      &nbsp;&= 3x+2                                       \end{aligned}"
        />
        <div className={classes.footer}>
          <div className={classes.helperText}>
            Shift +
            <span className={classes.enterIcon}>
              <EnterIcon size={12} />
            </span>{' '}
            for new line
          </div>
          <Button onClick={onClose} endIcon={<EnterIcon />}>
            Done
          </Button>
        </div>
      </form>
      {error && <EquationError error={error} />}
    </Popover>
  );
};
