import React from 'react';
import { Email } from '@21st-night/icons';
import { LoginButton } from '../LoginButton';

export interface EmailButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: 'sign-in' | 'sign-up';
}

export const EmailButton: React.FC<EmailButtonProps> = ({
  variant = 'sign-in',
  ...other
}) => {
  return (
    <LoginButton {...other} backgroundColor="#78909C" icon={<Email />}>
      Sign {variant === 'sign-up' ? 'up' : 'in'} with Email
    </LoginButton>
  );
};
