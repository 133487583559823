import React from 'react';
import { motion, Variants } from 'framer-motion';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '@21st-night/ui';

export interface OnboardingQuestionTextProps
  extends React.HTMLAttributes<HTMLDivElement> {
  question: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    question: {
      textAlign: 'center',
      fontSize: '34px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '26px',
      },
    },
  }),
);

const questionVariants: Variants = {
  in: {
    y: 0,
    opacity: 1,
    transition: { type: 'spring', mass: 0.7, damping: 10 },
  },
  out: {
    y: 40,
    opacity: 0,
    transition: { type: 'spring', mass: 0.7, damping: 10, delay: 0.2 },
  },
};

export const OnboardingQuestionText: React.FC<OnboardingQuestionTextProps> = ({
  question,
}) => {
  const classes = useStyles();
  return (
    <motion.div initial={false} variants={questionVariants}>
      <Typography className={classes.question}>{question}</Typography>
    </motion.div>
  );
};
