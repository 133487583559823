import { EditorDocument } from '../../Editor.types';
import { containsText } from '../containsText';

export function isDocumentEmpty(
  document?: EditorDocument,
  allowEmptyVoids = false,
): boolean {
  if (!document) {
    return true;
  }

  if (containsText(document, allowEmptyVoids)) {
    return false;
  }

  let hasFilledVoid = false;

  document.forEach(element => {
    if (!hasFilledVoid) {
      switch (element.type) {
        case 'image':
          hasFilledVoid = allowEmptyVoids || !!element.file;
          break;
        case 'audio':
          hasFilledVoid = allowEmptyVoids || !!element.file;
          break;
        case 'video':
          hasFilledVoid = allowEmptyVoids || !!element.url;
          break;
        default:
          hasFilledVoid = false;
      }
    }
  });

  return !hasFilledVoid;
}
