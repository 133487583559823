import { validateDoc, DocValidator } from '@21st-night/utils';
import { CARD_TYPES } from '@21st-night/cards';
import { NewRatingEventValidator } from '@21st-night/review';
import { DeckCard, DeckCardUpdateData, DeckFlashcard } from '../../Deck.types';

export const NewDeckCardValidator: DocValidator<DeckCard> = {
  type: [CARD_TYPES],
  id: 'string',
  createdAt: 'date',
  category: ['string', 'null'],
  subcategories: 'string-array',
  question: ['string'],
  starred: ['optional', 'boolean'],
  retired: ['optional', 'boolean'],
  nextReview: ['optional', 'date'],
  firstReview: ['optional', 'date', 'null'],
  lastReview: ['optional', 'date', 'null'],
  proficiency: ['optional', 'number'],
  status: ['optional', 'string'],
  interval: ['optional', 'number'],
  attempts: {
    type: 'array',
    items: NewRatingEventValidator,
    optional: true,
  },
};

export const NewDeckFlashcardValidator: DocValidator<DeckFlashcard> = {
  ...NewDeckCardValidator,
  easeFactor: ['optional', 'number'],
  stepsIndex: ['optional', 'number'],
};

export const DeckCardUpdateValidator: DocValidator<DeckCardUpdateData> = {
  category: ['optional', 'string', 'null'],
  subcategories: ['optional', 'string-array', 'arrayRemove', 'arrayUnion'],
  starred: ['optional', 'boolean'],
  retired: ['optional', 'boolean'],
  nextReview: ['optional', 'date'],
  lastReview: ['optional', 'date'],
  proficiency: ['optional', 'number'],
  notStarted: ['optional', 'boolean'],
  question: ['optional', 'string'],
  firstReview: ['optional', 'date'],
  status: ['optional', 'string'],
  interval: ['optional', 'number'],
  easeFactor: ['optional', 'number'],
  stepsIndex: ['optional', 'number'],
  attempts: {
    type: 'array',
    items: NewRatingEventValidator,
    optional: true,
  },
};

export function validateNewDeckCard(doc: DeckCard): boolean {
  if (doc.type === 'flashcard') {
    return validateDoc(NewDeckFlashcardValidator, doc);
  }

  return validateDoc(NewDeckCardValidator, doc);
}

export function validateDeckCardUpdate(doc: DeckCard): boolean {
  return validateDoc(DeckCardUpdateValidator, doc);
}
