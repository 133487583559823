import { daysBetween } from '../daysBetween';

/**
 * Returns the specified week-days
 * between two dates (inclusive of both).
 *
 * @param start The starting date.
 * @param end   The ending date.
 * @param days  Array of javascript weekday numbers (0: Sunday, 1: Monday, 2: Tuesday...).
 */
export function daysOfTheWeekBetween(
  start: Date,
  end: Date,
  daysOfTheWeek: number[],
): Date[] {
  const days = daysBetween(start, end);
  return days.filter(day => daysOfTheWeek.includes(day.getDay()));
}
