import {
  EditorDocument,
  MediaFileReference,
  MediaFileType,
} from '../../Editor.types';

export function getDocumentMedia(
  document: EditorDocument,
  type: MediaFileType,
): MediaFileReference[] {
  return document.reduce((media, element) => {
    if (element.type === type && element.file && element.url) {
      return [
        ...media,
        {
          type,
          id: element.file,
          url: element.url,
        },
      ];
    }

    return media;
  }, [] as MediaFileReference[]);
}
