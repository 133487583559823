import React from 'react';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import { Typography, CheckboxProps, Paper, DeleteButton } from '@21st-night/ui';
import { TaskFormSlider, TaskFormSliderProps } from './TaskFormSlider';
import {
  TaskFormDaysOfTheWeek,
  TaskFormDaysOfTheWeekProps,
} from './TaskFormDaysOfTheWeek';
import {
  TaskFormTextFields,
  TaskFormTextFieldsProps,
} from './TaskFormTextFields';

export interface TaskFormProps
  extends TaskFormTextFieldsProps,
    React.HTMLAttributes<HTMLDivElement> {
  /**
   * The minimum starting date of the task.
   */
  minStartDate: Date;
  /**
   * The maximum ending date of the task.
   */
  maxEndDate: Date;
  /**
   * Properties applied to the day selection Checkbox components.
   */
  DayCheckboxProps?: CheckboxProps;
  /**
   * Properties applied to the start-date hidden input field.
   */
  startDateInputProps?: TaskFormSliderProps['startDateInputProps'];
  /**
   * Properties applied to the end-date hidden input field.
   */
  endDateInputProps?: TaskFormSliderProps['endDateInputProps'];
  /**
   * Properties applied to the TaskFormSlider component.
   */
  DateRangeSliderProps?: Partial<TaskFormSliderProps>;
  /**
   * Properties applied to the DaysOfTheWeekProps component.
   */
  DaysOfTheWeekProps?: TaskFormDaysOfTheWeekProps;
  /**
   * Callback fired when the delete confirmation button is clicked.
   */
  onClickDelete: () => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(3, 4),
      maxWidth: 600 + theme.spacing(4),
    },
    deleteButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    section: {
      marginBottom: theme.spacing(3),
    },
  }),
);

export const TaskForm: React.FC<TaskFormProps> = ({
  ActionInputProps = {},
  CountInputProps = {},
  ObjectInputProps = {},
  SourceInputProps = {},
  DayCheckboxProps = {},
  startDateInputProps = {},
  endDateInputProps = {},
  DateRangeSliderProps = {},
  DaysOfTheWeekProps = {},
  className,
  minStartDate,
  maxEndDate,
  subtasks,
  onClickAddSubtask,
  onClickDelete,
  onClickRemoveSubtask,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <DeleteButton
        variant="icon"
        textButtonsSize="small"
        onConfirm={onClickDelete}
        className={classes.deleteButton}
      />
      <Typography variant="h6">Task</Typography>
      <div className={classes.section}>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          What does this task involve?
        </Typography>
        <TaskFormTextFields
          ActionInputProps={ActionInputProps}
          CountInputProps={CountInputProps}
          ObjectInputProps={ObjectInputProps}
          SourceInputProps={SourceInputProps}
          subtasks={subtasks}
          onClickAddSubtask={onClickAddSubtask}
          onClickRemoveSubtask={onClickRemoveSubtask}
        />
      </div>
      <div className={classes.section}>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          Which days of the week will you work on this task?
        </Typography>
        <TaskFormDaysOfTheWeek
          CheckboxProps={DayCheckboxProps}
          {...DaysOfTheWeekProps}
        />
      </div>
      <div className={classes.section}>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          From when to when will you work on this task?
        </Typography>

        <TaskFormSlider
          min={minStartDate}
          max={maxEndDate}
          startDateInputProps={startDateInputProps}
          endDateInputProps={endDateInputProps}
          {...DateRangeSliderProps}
        />
      </div>
    </Paper>
  );
};
