import { Timestamp, FieldValue } from '@21st-night/utils';

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export const NOTE_TYPES = ['note', 'pdf', 'image'] as const;
export type NoteType = ElementType<typeof NOTE_TYPES>;

export interface Note {
  type: NoteType;
  deck: string;
  id: string;
  createdAt: Date;
  content: string;
  title: string;
  author: string;
  owner: string;
  file?: string;
  fileId?: string;
  cards: string[];
}

export interface NoteDocument extends Omit<Note, 'createdAt'> {
  createdAt: Timestamp;
}

export interface NoteUpdateData {
  content?: string;
  title?: string;
  cards?: FieldValue | string[];
}
