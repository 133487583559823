import React from 'react';
import { List } from '@21st-night/ui';
import { NoteListItem } from '@21st-night/notes-web';
import { useFirebase } from '@21st-night/utils';
import {
  DeckFilterOption,
  generateDeckCategoryFilters,
  generateDeckSubcategoryFilters,
  useDeck,
  useDeckFilter,
  DeckContentNote,
} from '@21st-night/deck';
import { DeckFilterBar } from '../DeckFilter';
import { DeckItemMetadata } from '../DeckItemMetadata';

export interface DeckNoteSelectorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onSelectNote: (note: DeckContentNote) => void;
  exclude?: string[];
}

const metadataFilters: DeckFilterOption[] = [
  {
    type: 'boolean',
    group: 'metadata',
    value: 'starred',
    label: '⭐️ Starred',
  },
];

export const DeckNoteSelector: React.FC<DeckNoteSelectorProps> = ({
  onSelectNote,
  exclude = [],
  ...other
}) => {
  const { imageUrl } = useFirebase();
  const deck = useDeck();
  const { setFilters, filters, filter } = useDeckFilter([
    ...deck.categoryFilters,
    ...deck.subcategoryFilters,
  ]);
  const notes = filter(deck.notes.filter(note => !exclude.includes(note.id)));

  return (
    <div {...other}>
      <DeckFilterBar
        placeholder="Filter notes"
        options={[
          ...metadataFilters,
          ...generateDeckCategoryFilters(deck.categories),
          ...generateDeckSubcategoryFilters(deck.subcategories),
        ]}
        onChange={(event, val) => setFilters(val)}
        value={filters}
      />
      <List>
        {notes.map(note => (
          <NoteListItem
            button
            onClick={() => onSelectNote(note)}
            key={note.id}
            imageUrl={imageUrl}
            note={note}
            secondary={<DeckItemMetadata readonly item={note} />}
          />
        ))}
      </List>
    </div>
  );
};
