import { DB } from '@21st-night/utils';
import chunck from 'lodash/chunk';
import { StudyPlanTask } from '../../types';

export function createStudyPlanTasks(
  db: DB,
  userId: string,
  tasks: StudyPlanTask[],
): Promise<void[]> {
  const promises: Promise<void>[] = [];
  const tasksRef = db
    .collection('users')
    .doc(userId)
    .collection('study-plan')
    .doc('plan')
    .collection('tasks');
  // Firestore batch supports up to 500 opperations
  const chuncks = chunck(tasks, 500);

  chuncks.forEach(chunck => {
    const batch = db.batch();
    chunck.forEach(task => {
      batch.set(tasksRef.doc(task.id), task);
    });

    promises.push(batch.commit());
  });

  return Promise.all(promises);
}
