import React from 'react';
import {
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@material-ui/core';

export type DialogContentTextProps = MuiDialogContentTextProps;

export const DialogContentText: React.FC<DialogContentTextProps> = ({
  ...other
}) => {
  return <MuiDialogContentText {...other} />;
};
