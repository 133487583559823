import React from 'react';
import { makeStyles } from '@21st-night/styles';
import {
  Dialog,
  DialogProps,
  DialogContent,
  IconButton,
  LoadingIndicator,
} from '@21st-night/ui';
import { Clear } from '@21st-night/icons';
import { SignupForm, SignupFormProps } from '../SignupForm';

export interface SignupDialogProps extends SignupFormProps {
  loading?: boolean;
  open: DialogProps['open'];
  onClose: DialogProps['onClose'];
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
  },
  form: {
    maxWidth: 360,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loadingIndicator: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const SignupDialog: React.FC<SignupDialogProps> = ({
  open,
  onClose,
  loading,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      {!loading && (
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Clear />
        </IconButton>
      )}
      <DialogContent className={classes.content}>
        {!loading && (
          <div className={classes.form}>
            <SignupForm {...other} />
          </div>
        )}
        {loading && (
          <LoadingIndicator size="large" className={classes.loadingIndicator} />
        )}
      </DialogContent>
    </Dialog>
  );
};
