import React from 'react';
import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
} from '@material-ui/lab';

export type AlertProps = MuiAlertProps;

export const Alert: React.FC<AlertProps> = ({ ...other }) => {
  return <MuiAlert {...other} />;
};
