import { DB, DocumentReference } from '@21st-night/utils';
import { StudyPlanItem } from '../../types';

export function createStudyPlanSubItem(
  db: DB,
  planRef: DocumentReference,
  item: StudyPlanItem,
): Promise<void[]> {
  return Promise.all([
    planRef.collection('items').doc(item.id).set(item),
    planRef
      .collection('items')
      .doc(item.parent)
      .update({ subItems: db.arrayUnion(item.id) }),
  ]);
}
