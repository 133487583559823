import React, { useRef } from 'react';
import { ArrowDropDown } from '@21st-night/icons';
import { makeStyles, cn } from '@21st-night/styles';
import { ButtonGroup, ButtonGroupProps } from '../ButtonGroup';
import { Button, ButtonProps } from '../Button';
import { Menu, MenuProps } from '../Menu';

export interface SplitButtonProps extends Omit<ButtonGroupProps, 'onClick'> {
  label: string;
  icon?: React.ReactElement;
  onClick: ButtonProps['onClick'];
  menuOpen: boolean;
  onCloseMenu: MenuProps['onClose'];
  onOpenMenu: (event: React.MouseEvent) => void;
}

const useStyles = makeStyles(theme => ({
  button: {
    height: 18 + 15 + 15,
    padding: '15px 24px 15px',
    lineHeight: '18px',
    fontSize: '18px',
    borderRadius: 8,
    border: 'none',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
    color: '#35469C',
    backgroundColor: '#BED0F7',
    '&:focus': {
      color: '#35469C',
      backgroundColor: theme.palette.secondary.light,
    },
    '&:hover': {
      color: '#35469C',
      backgroundColor: theme.palette.secondary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  iconButton: {
    minWidth: 0,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: '1px solid #CCD9FF',
  },
}));

export const SplitButton: React.FC<SplitButtonProps> = ({
  label,
  icon = <ArrowDropDown />,
  children,
  onClick,
  onCloseMenu,
  onOpenMenu,
  menuOpen,
  ...other
}) => {
  const classes = useStyles();
  const dropdownButton = useRef<HTMLButtonElement>(null);

  return (
    <ButtonGroup {...other}>
      <Button onClick={onClick} className={classes.button}>
        {label}
      </Button>
      <Button
        onClick={onOpenMenu}
        ref={dropdownButton}
        className={cn(classes.button, classes.iconButton)}
      >
        {icon}
      </Button>
      <Menu
        open={menuOpen}
        onClose={onCloseMenu}
        anchorEl={dropdownButton.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        anchorReference="anchorEl"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {children}
      </Menu>
    </ButtonGroup>
  );
};
