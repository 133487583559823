import { useCallback, useState } from 'react';
import {
  DeckContentItem,
  DeckSortByValue,
  DeckSortOrderValue,
  DeckSortValue,
  DeckContentCard,
  DeckContentNote,
} from '../../Deck.types';

export type SortFn = (
  content: DeckContentItem[],
  sortBy?: DeckSortByValue,
  order?: DeckSortOrderValue,
) => DeckContentItem[];
export type SetSortValueFn = (value: DeckSortValue) => void;

export interface DeckSortHook {
  sortValue: DeckSortValue;
  setSortValue: SetSortValueFn;
  sort: SortFn;
  sortBy: DeckSortByValue;
  order: DeckSortOrderValue;
}

function sortByDate(
  date1: Date,
  date2: Date,
  order: DeckSortOrderValue,
): number {
  if (order === 'asc') {
    return date1.getTime() - date2.getTime();
  }

  return date2.getTime() - date1.getTime();
}

function sortCreatedAtDesc<
  T extends DeckContentItem | DeckContentCard | DeckContentNote
>(content: T[]): T[] {
  return content.sort((a, b) => sortByDate(a.createdAt, b.createdAt, 'desc'));
}

function segmentContent(
  content: DeckContentItem[],
): [DeckContentItem[], DeckContentCard[], DeckContentNote[]] {
  const segments: [DeckContentItem[], DeckContentCard[], DeckContentNote[]] = [
    [],
    [],
    [],
  ];

  content.forEach(item => {
    switch (item.type) {
      case 'create-error-log':
      case 'create-flashcard':
      case 'image-upload':
      case 'pdf-upload':
      case 'text-upload':
        segments[0].push(item);
        break;
      case 'flashcard':
      case 'error-log':
        segments[1].push(item);
        break;
      default:
        segments[2].push(item);
    }
  });

  return [
    sortCreatedAtDesc(segments[0]),
    sortCreatedAtDesc(segments[1]),
    sortCreatedAtDesc(segments[2]),
  ];
}

export const useDeckSort = (): DeckSortHook => {
  const [sortBy, setSortBy] = useState<DeckSortByValue>('createdAt');
  const [order, setOrder] = useState<DeckSortOrderValue>('desc');

  const sort: SortFn = useCallback(
    (content, sortByValue = sortBy, orderValue = order) => {
      switch (sortByValue) {
        case 'createdAt':
          return content.sort((a, b) =>
            sortByDate(a.createdAt, b.createdAt, orderValue),
          );
        case 'lastReview':
          return segmentContent(content).reduce((sorted, segment, index) => {
            if (index === 1) {
              return [
                ...sorted,
                ...(segment as DeckContentCard[]).sort((a, b) =>
                  sortByDate(
                    a.lastReview || new Date(),
                    b.lastReview || new Date(),
                    orderValue,
                  ),
                ),
              ];
            }

            return [...sorted, ...segment];
          }, []);
        case 'nextReview':
          return segmentContent(content).reduce((sorted, segment, index) => {
            if (index === 1) {
              return [
                ...sorted,
                ...(segment as DeckContentCard[]).sort((a, b) =>
                  sortByDate(
                    a.nextReview || new Date(),
                    b.nextReview || new Date(),
                    orderValue,
                  ),
                ),
              ];
            }

            return [...sorted, ...segment];
          }, []);
        case 'proficiency':
          return segmentContent(content).reduce((sorted, segment, index) => {
            if (index === 1) {
              return [
                ...sorted,
                ...(segment as DeckContentCard[]).sort((a, b) =>
                  orderValue === 'asc'
                    ? (a.proficiency || 0) - (b.proficiency || 0)
                    : (b.proficiency || 0) - (a.proficiency || 0),
                ),
              ];
            }

            return [...sorted, ...segment];
          }, []);
        default:
          return content;
      }
    },
    [sortBy, order],
  );

  const handleSortValueChange: SetSortValueFn = useCallback(value => {
    const [nextSortBy, nextOrder] = value.split('_') as [
      DeckSortByValue,
      DeckSortOrderValue,
    ];
    setSortBy(nextSortBy);
    setOrder(nextOrder);
  }, []);

  return {
    sortValue: `${sortBy}_${order}` as DeckSortValue,
    setSortValue: handleSortValueChange,
    sort,
    sortBy,
    order,
  };
};
