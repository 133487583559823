import React, { Fragment } from 'react';
import Arrow from 'react-xarrows';
import { OcclusionBox, LabelBox } from '../ImageOcclusion.types';
import { isDocumentEmpty, RichText } from '@21st-night/editor-web';

interface Size {
  width: number;
  height: number;
}

export interface ImageOcclusionCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  canvasSize: Size;
  boxes: (OcclusionBox | LabelBox)[];
  boxId: string;
  mode: 'reveal-one' | 'reveal-all' | 'hide-one' | 'hide-all';
  disableRevealOutline?: boolean;
  onImageLoad?: () => void;
}

export const ImageOcclusionCard: React.FC<ImageOcclusionCardProps> = ({
  image,
  canvasSize,
  boxes: boxesProp,
  boxId,
  mode,
  onImageLoad,
}) => {
  const boxes = boxesProp.map(box =>
    box.type === 'label'
      ? {
          ...box,
          boxRef: React.createRef<HTMLDivElement>(),
          arrowHeadRef: React.createRef<HTMLDivElement>(),
        }
      : box,
  );

  function renderBoxes(boxes: (OcclusionBox | LabelBox)[]) {
    return boxes.map(box => (
      <Fragment key={box.id}>
        <div
          ref={box.type === 'label' ? box.boxRef : undefined}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 3,
            width: box.width,
            height: box.height,
            position: 'absolute',
            left: box.x,
            top: box.y,
            backgroundColor:
              box.type === 'label' ||
              mode === 'hide-all' ||
              (mode === 'hide-one' && box.id === boxId) ||
              (mode === 'reveal-one' && boxId !== box.id) ||
              (mode === 'hide-one' &&
                boxId !== box.id &&
                !isDocumentEmpty(box.content)) ||
              (mode === 'reveal-one' &&
                boxId === box.id &&
                !isDocumentEmpty(box.content)) ||
              (mode === 'reveal-all' && !isDocumentEmpty(box.content))
                ? '#FFF'
                : 'none',
            boxShadow:
              mode !== 'hide-all' &&
              ((mode === 'reveal-one' && boxId === box.id) ||
                mode === 'reveal-all' ||
                (mode === 'hide-one' && box.id !== boxId)) &&
              (box.type === 'label' || !isDocumentEmpty(box.content))
                ? 'rgba(15, 15, 15, 0.05) 0px 0px 0px 2px'
                : 'none',
          }}
        >
          {mode !== 'hide-all' &&
            ((mode === 'reveal-one' && boxId === box.id) ||
              mode === 'reveal-all' ||
              (mode === 'hide-one' && box.id !== boxId)) &&
            !isDocumentEmpty(box.content) && (
              <RichText imageUrl="" document={box.content} />
            )}
          {box.id === boxId && (mode === 'hide-all' || mode === 'hide-one') && (
            <div
              style={{
                fontSize: 48,
                color: '#1976D2',
                width: '100%',
                flex: 1,
                height: '100%',
                border: '2px solid #1976D2',
                borderRadius: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              ?
            </div>
          )}
        </div>
        {box.type === 'label' && (
          <>
            <div
              ref={box.arrowHeadRef}
              style={{
                width: 24,
                height: 24,
                position: 'absolute',
                left: box.arrowPosition.x,
                top: box.arrowPosition.y,
              }}
            />

            <Arrow
              path="straight"
              color="#000"
              headSize={6}
              strokeWidth={3}
              endAnchor={{
                position: 'middle',
                offset: { rightness: 4, bottomness: 0 },
              }}
              start={box.boxRef}
              end={box.arrowHeadRef}
            />
          </>
        )}
      </Fragment>
    ));
  }

  return (
    <div
      style={{
        position: 'relative',
        ...canvasSize,
      }}
    >
      <img
        src={image}
        onLoad={onImageLoad}
        style={{
          ...canvasSize,
        }}
      />
      {renderBoxes(boxes.filter(box => box.id !== boxId))}
      {renderBoxes(boxes.filter(box => box.id === boxId))}
    </div>
  );
};
