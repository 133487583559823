import React from 'react';
import { cn, makeStyles } from '@21st-night/styles';
import { Range } from 'slate';
import { deserializeDocument } from '@21st-night/editor-web';
import { useFirebase } from '@21st-night/utils';
import {
  Paper,
  Button,
  Spaced,
  Typography,
  Alert,
  Prompt,
  DeleteButton,
} from '@21st-night/ui';
import {
  Card,
  CardEditor,
  CardPreview,
  removeCardLinkFromDocument,
} from '@21st-night/cards-web';
import { NoteHighlighter, NoteListItem } from '@21st-night/notes-web';
import { useDeckCardEditor } from '../hooks';
import { DeckCategorySelect } from '../DeckCategorySelect';
import { DeckSubcategoriesSelect } from '../DeckSubcategoriesSelect';
import { DeckNoteSelector } from '../DeckNoteSelector';

type View = 'editor' | 'preview' | 'select-note' | 'note';

export interface DeckCardEditorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  submitButtonLabel: string;
  onClickCancel: () => void;
  cardId?: string;
  type: Card['type'];
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  preview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    minHeight: 400,
    paddingBottom: theme.spacing(2),
    position: 'relative',
  },
  rightColumn: {
    display: 'flex',
  },
  metadataItem: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  metadataLabel: {
    display: 'block',
    minWidth: 140,
  },
  metadataField: {
    flex: 1,
  },
  attachNoteButton: {
    flex: 1,
    display: 'block',
    textAlign: 'left',
    color: '#A2A2A2',
    width: '100%',
    fontWeight: 400,
    fontSize: '16px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 2,
    paddingBottom: 2,
  },
  columnSpacer: {
    flex: 1,
  },
  noteSelectionInfo: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
}));

export const DeckCardEditor: React.FC<DeckCardEditorProps> = ({
  className,
  submitButtonLabel,
  onClickCancel,
  cardId,
  type,
  ...other
}) => {
  const classes = useStyles();
  const { db, functions, storage, imageUrl } = useFirebase();
  const {
    contentRef,
    view,
    setView,
    errors,
    question,
    answer,
    explanation,
    summary,
    category,
    subcategories,
    setCategory,
    setSubcategories,
    setQuestion,
    setAnswer,
    setExplanation,
    setSummary,
    onSelectNote,
    selectedNote,
    setNoteSelection,
    existingCardLinksCount,
    clearExistingCardLinksCount,
    linkedNote,
    onClickRemoveNoteLink,
    previewHeight,
    noteSelection,
    onClickSubmit,
    onClickBackFromNoteSelection,
    onClickBackFromNote,
    onClickLinkNote,
    onClickDelete,
  } = useDeckCardEditor();

  return (
    <Paper className={cn(classes.root, className)} {...other}>
      {view !== 'preview' && (
        <>
          <div ref={contentRef} className={classes.content}>
            {errors.map(error => (
              <Typography paragraph key={error} color="error">
                {error}
              </Typography>
            ))}
            {view === 'editor' && (
              <CardEditor
                type={type}
                imageUrl={imageUrl}
                db={db}
                storage={storage}
                functions={functions}
                question={question}
                answer={answer}
                explanation={explanation}
                summary={summary}
                onQuestionChange={setQuestion}
                onAnswerChange={setAnswer}
                onExplanationChange={setExplanation}
                onSummaryChange={setSummary}
              />
            )}
            {view === 'select-note' && (
              <DeckNoteSelector onSelectNote={onSelectNote} />
            )}
            {view === 'note' && selectedNote && (
              <>
                <Alert severity="info" className={classes.noteSelectionInfo}>
                  Select text to link to a specific part of the note.
                </Alert>
                <NoteHighlighter
                  db={db}
                  storage={storage}
                  functions={functions}
                  imageUrl={imageUrl}
                  value={
                    cardId
                      ? removeCardLinkFromDocument(
                          deserializeDocument(selectedNote.content),
                          cardId,
                        )
                      : deserializeDocument(selectedNote.content)
                  }
                  onSelectionChange={setNoteSelection}
                />
                <Prompt
                  open={existingCardLinksCount > 0}
                  onClose={clearExistingCardLinksCount}
                  title="Remove existing card link?"
                  body="The selected text contains one or more existing card links. Existing links will be removed."
                  onClickPrimaryButton={onClickLinkNote}
                />
              </>
            )}
          </div>
          {view === 'editor' && (
            <>
              <div className={classes.metadataItem}>
                <Typography
                  variant="overline"
                  className={classes.metadataLabel}
                >
                  Category
                </Typography>
                <DeckCategorySelect
                  className={classes.metadataField}
                  value={category}
                  onChange={setCategory}
                />
              </div>
              <div className={classes.metadataItem}>
                <Typography
                  variant="overline"
                  className={classes.metadataLabel}
                >
                  Subcategories
                </Typography>
                <DeckSubcategoriesSelect
                  className={classes.metadataField}
                  value={subcategories}
                  onChange={setSubcategories}
                />
              </div>
              <div className={classes.metadataItem}>
                <Typography
                  variant="overline"
                  className={classes.metadataLabel}
                >
                  Note
                </Typography>
                {!linkedNote && (
                  <Button
                    color="inherit"
                    className={classes.attachNoteButton}
                    onClick={() => setView('select-note')}
                  >
                    Attach a note
                  </Button>
                )}
                {linkedNote && (
                  <div className={classes.metadataField}>
                    <NoteListItem
                      button
                      maxChars={56}
                      maxElements={1}
                      imageUrl={imageUrl}
                      note={linkedNote}
                      onClick={() => setView('select-note')}
                    />
                    <Button size="small" onClick={onClickRemoveNoteLink}>
                      Remove
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {view === 'preview' && (
        <div className={classes.preview} style={{ height: previewHeight }}>
          <CardPreview
            imageUrl={imageUrl}
            question={question}
            answer={answer}
            explanation={explanation}
            summary={summary}
          />
        </div>
      )}
      <div className={classes.actions}>
        {view === 'editor' && <Button onClick={onClickCancel}>Cancel</Button>}
        {view === 'select-note' && (
          <Button onClick={onClickBackFromNoteSelection}>Back</Button>
        )}
        {view === 'note' && (
          <Spaced>
            <Button onClick={onClickBackFromNote}>Back</Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={onClickLinkNote}
            >
              {noteSelection && !Range.isCollapsed(noteSelection)
                ? 'Link selection'
                : 'Link note'}
            </Button>
          </Spaced>
        )}
        <div style={{ flex: 1 }} />
        <Spaced spacing={2}>
          {view === 'preview' && (
            <Button onClick={() => setView('editor')}>Edit</Button>
          )}
          {view === 'editor' && (
            <>
              {onClickDelete && <DeleteButton onConfirm={onClickDelete} />}
              <Button onClick={() => setView('preview')}>Preview</Button>
            </>
          )}
          {(view === 'editor' || view === 'preview') && (
            <Button variant="contained" color="primary" onClick={onClickSubmit}>
              {submitButtonLabel}
            </Button>
          )}
        </Spaced>
      </div>
    </Paper>
  );
};
