import { DB } from '@21st-night/utils';
import chunk from 'lodash/chunk';
import { Card } from '../../types';
import { validateNewCard } from '../card-validator';

export function createCardBatch(db: DB, cards: Card[]): Promise<void>[] {
  cards.forEach(card => validateNewCard(card));
  const promises: Promise<void>[] = [];
  const chunks = chunk(cards, 500);

  chunks.forEach(chnk => {
    const batch = db.batch();

    chnk.forEach(card => {
      batch.set(db.collection('cards').doc(card.id), card);
    });

    promises.push(batch.commit());
  });

  return promises;
}
