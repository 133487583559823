import {
  EditorDocument,
  isElementOfType,
  stripDocumentMetadata,
  generateEditorElement,
  Transforms,
} from '@21st-night/editor';
import { createEditor, Range } from 'slate';

export function addCardLinkToDocument(
  document: EditorDocument,
  at: Range,
  cardId: string,
): EditorDocument {
  const [children] = stripDocumentMetadata(document);
  const editor = createEditor();
  const { isInline } = editor;
  editor.isInline = node =>
    isElementOfType(node, 'card-link') || isInline(node);

  editor.children = children;

  Transforms.wrapNodes(
    editor,
    generateEditorElement('card-link', '', { cardId }),
    {
      split: true,
      at,
    },
  );

  return editor.children as EditorDocument;
}
