import { Node } from 'slate';
import { EditorDocument } from '../../Editor.types';
import { generateEditorDocumentMetadata } from '../generateEditorDocumentMetadata';

export function serializeDocument(document: EditorDocument): string {
  if (!document || document.length === 0) {
    throw new Error('Invalid document');
  }

  if (document[0].type === 'metadata') {
    const metadata = document[0];
    const content: Node[] = document.slice(1);
    metadata.lastUpdatedAt = new Date();

    return JSON.stringify([metadata, ...content]);
  } else {
    const metadata = generateEditorDocumentMetadata();
    return JSON.stringify([metadata, ...document]);
  }

  return JSON.stringify(document);
}
