import { Node as SlateNode } from 'slate';
import { createLinkPlugin, EditorWithLinkPlugin } from './plugins/plugin-link';
import {
  createEquationPlugin,
  EditorWithInlineEquationPlugin,
} from './plugins/plugin-equation';
import {
  createEquationBlockPlugin,
  EditorWithEquationBlockPlugin,
} from './plugins/plugin-equation-block';
import { CoreEditor, corePlugins } from './core-editor-plugins';

export type Node = SlateNode;

export interface WebEditor
  extends CoreEditor,
    EditorWithLinkPlugin,
    EditorWithInlineEquationPlugin,
    EditorWithEquationBlockPlugin {}

export const LinkPlugin = createLinkPlugin();
export const EquationBlockPlugin = createEquationBlockPlugin();
export const EquationPlugin = createEquationPlugin();

export const webEditorPlugins = [
  ...corePlugins,
  LinkPlugin,
  EquationBlockPlugin,
  EquationPlugin,
];
