import React, { useCallback, useEffect, useState } from 'react';
import { motion, Variants, MotionProps } from 'framer-motion';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Button, Spaced } from '@21st-night/ui';
import { OnboardingQuestionSlider } from '../OnboardingQuestionSlider';
import { OnboardingQuestionText } from '../OnboardingQuestionText';
import {
  OnboardingRangeSelection,
  OnboardingRangeSelectionValue,
} from '@21st-night/onboarding';

type Values = Record<string, OnboardingRangeSelectionValue>;

export interface OnboardingRangeSelectionQuestionProps extends MotionProps {
  question: string;
  sliders: OnboardingRangeSelection[];
  defaultValues?: Values;
  onSubmit: (values: Values) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    sliders: {
      width: '100%',
      maxWidth: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(4),
    },
  }),
);

const sliderContainerVariants: Variants = {
  in: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  out: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const sliderVariants: Variants = {
  in: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  out: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

function generateDefaultValues(
  sliders: OnboardingRangeSelection[],
): Record<string, OnboardingRangeSelectionValue> {
  return sliders.reduce(
    (defaultValues, range) => ({
      ...defaultValues,
      [range.name]: range.defaultValue || 'unkown',
    }),
    {},
  );
}

export const OnboardingRangeSelectionQuestion: React.FC<OnboardingRangeSelectionQuestionProps> = ({
  question,
  sliders,
  onSubmit,
  defaultValues: defaultValuesProp,
  ...other
}) => {
  const classes = useStyles();
  const [displayed, setDisplayed] = useState(false);
  const defaultValues = defaultValuesProp || generateDefaultValues(sliders);
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    setTimeout(() => {
      setValues(generateDefaultValues(sliders));
      setDisplayed(true);
    }, 200);
  }, [question, sliders]);

  const handleChange = useCallback(
    (name: string, value: OnboardingRangeSelectionValue) => {
      setValues(vals => ({ ...vals, [name]: value }));
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    setDisplayed(false);
    setTimeout(() => {
      onSubmit(values);
    }, 500);
  }, [values, onSubmit]);

  return (
    <motion.div animate={displayed ? 'in' : 'out'} {...other}>
      <OnboardingQuestionText question={question} />
      <motion.div
        initial={false}
        variants={sliderContainerVariants}
        className={classes.sliders}
      >
        {sliders.map(slider => (
          <motion.div key={slider.name} variants={sliderVariants}>
            <OnboardingQuestionSlider
              {...slider}
              defaultValue={defaultValues[slider.name]}
              onChange={value => handleChange(slider.name, value)}
            />
          </motion.div>
        ))}
        <motion.div variants={sliderVariants}>
          <Spaced centered>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Next
            </Button>
          </Spaced>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
