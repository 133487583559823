import { DB } from '@21st-night/utils';
import { CardUpdateData } from '../../types';
import { validateCardUpdate } from '../card-validator';

export function updateCard(
  db: DB,
  id: string,
  card: CardUpdateData,
): Promise<void> {
  validateCardUpdate(card);

  return db.collection('cards').doc(id).update(card);
}
