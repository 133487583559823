import { toDate, DocumentSnapshot } from '@21st-night/utils';
import {
  CardReviewProgressDocument,
  CardReviewProgress,
  FlashcardReviewProgress,
} from '../../Review.types';
import { deserializeLegacyCardProgress } from '../deserializeLegacyCardProgress';

export function deserializeCardProgress(
  snapshot: DocumentSnapshot,
): CardReviewProgress | FlashcardReviewProgress {
  const data = snapshot.data() as CardReviewProgressDocument;
  if (typeof data.type === 'undefined') {
    return deserializeLegacyCardProgress(snapshot);
  }

  return {
    ...data,
    lastReview: data.lastReview === null ? null : toDate(data.lastReview),
    nextReview: toDate(data.nextReview),
    firstReview: data.firstReview === null ? null : toDate(data.firstReview),
    attempts: data.attempts.map(item => ({
      ...item,
      createdAt: toDate(item.createdAt),
    })),
  };
}
