import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeck } from '../updateDeck';

export function createSubcategory(
  db: DB,
  deckRef: DocumentReference,
  subcategory: string,
): Promise<void> {
  return updateDeck(deckRef, {
    subcategories: db.arrayUnion(subcategory),
  });
}
