import React, { useCallback, useReducer } from 'react';
import { createContext } from '@21st-night/utils';
import { ReviewView, ReviewMode, WrittenAnswerState } from '../Review.types';

export interface ReviewState {
  cards: string[];
  attemptedCards: string[];
  completedCards: string[];
  remainingCards: string[];
  currentCard: string | null;
  sessionId: string | null;
  view: ReviewView;
  mode: ReviewMode;
  writtenAnswer: string;
  writtenAnswerState: WrittenAnswerState;
}

type Reset = (initialValue?: Partial<ReviewState>) => void;
type SetReviewMode = (mode: ReviewMode) => void;
type SetView = (view: ReviewView) => void;
type SetCards = (cards: string[]) => void;
type SetCurrentCard = (card: string | null) => void;
type AddAttemptedCard = (card: string) => void;
type AddCompletedCard = (card: string) => void;
type SetWrittenAnswer = (value: string) => void;
type SetWrittenAnswerState = (state: WrittenAnswerState) => void;
type SetSessionId = (id: string | null) => void;

export interface ReviewStateContext extends ReviewState {
  reset: Reset;
  setView: SetView;
  setReviewMode: SetReviewMode;
  setCards: SetCards;
  setCurrentCard: SetCurrentCard;
  addAttemptedCard: AddAttemptedCard;
  addCompletedCard: AddCompletedCard;
  setWrittenAnswer: SetWrittenAnswer;
  setWrittenAnswerState: SetWrittenAnswerState;
  setSessionId: SetSessionId;
}

const [hook, Provider] = createContext<ReviewStateContext>();

type Action =
  | { type: 'RESET'; initialValue?: Partial<ReviewState> }
  | { type: 'SET_REVIEW_MODE'; mode: ReviewMode }
  | { type: 'SET_VIEW'; view: ReviewView }
  | { type: 'SET_CARDS'; cards: string[] }
  | { type: 'SET_CURRENT_CARD'; card: string | null }
  | { type: 'ADD_ATTEMPTED_CARD'; card: string }
  | { type: 'ADD_COMPLETED_CARD'; card: string }
  | { type: 'SET_WRITTEN_ANSWER'; value: string }
  | { type: 'SET_WRITTEN_ANSWER_STATE'; state: WrittenAnswerState }
  | { type: 'SET_SESSION_ID'; id: string | null };

function generateDefaultState(
  initialState?: Partial<ReviewState>,
): ReviewState {
  return {
    cards: [],
    attemptedCards: [],
    completedCards: [],
    remainingCards: [],
    currentCard: null,
    view: 'question',
    mode: 'standard',
    writtenAnswer: '',
    writtenAnswerState: 'unchecked',
    sessionId: null,
    ...initialState,
  };
}

const reducer = (state: ReviewState, action: Action): ReviewState => {
  switch (action.type) {
    case 'RESET':
      return generateDefaultState(action.initialValue);
    case 'SET_REVIEW_MODE':
      return {
        ...state,
        mode: action.mode,
      };
    case 'SET_VIEW':
      return {
        ...state,
        view: action.view,
      };
    case 'SET_CARDS':
      return {
        ...state,
        cards: action.cards,
        remainingCards: action.cards,
      };
    case 'SET_CURRENT_CARD':
      return {
        ...state,
        currentCard: action.card,
        writtenAnswer: '',
        writtenAnswerState: 'unchecked',
        view: state.mode === 'answer-first' ? 'answer' : 'question',
      };
    case 'ADD_ATTEMPTED_CARD':
      return {
        ...state,
        attemptedCards: state.attemptedCards.includes(action.card)
          ? state.attemptedCards
          : [...state.attemptedCards, action.card],
      };
    case 'ADD_COMPLETED_CARD':
      return {
        ...state,
        completedCards: state.completedCards.includes(action.card)
          ? state.completedCards
          : [...state.completedCards, action.card],
        attemptedCards: state.attemptedCards.includes(action.card)
          ? state.attemptedCards
          : [...state.attemptedCards, action.card],
        remainingCards: state.remainingCards.filter(
          card => card !== action.card,
        ),
      };
    case 'SET_WRITTEN_ANSWER':
      return {
        ...state,
        writtenAnswer: action.value,
      };
    case 'SET_WRITTEN_ANSWER_STATE':
      return {
        ...state,
        writtenAnswerState: action.state,
      };
    case 'SET_SESSION_ID':
      return {
        ...state,
        sessionId: action.id,
      };
    default:
      throw new Error(
        `ReviewStateProvider action ${JSON.stringify(action)} was not handled`,
      );
  }
};

export const ReviewStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, generateDefaultState());

  const reset: Reset = useCallback(initialValue => {
    dispatch({ type: 'RESET', initialValue });
  }, []);

  const setReviewMode: SetReviewMode = useCallback(mode => {
    dispatch({ type: 'SET_REVIEW_MODE', mode });
    if (mode === 'answer-first') {
      dispatch({ type: 'SET_VIEW', view: 'answer' });
    }

    if (mode === 'standard' || mode === 'quiz') {
      dispatch({ type: 'SET_VIEW', view: 'question' });
    }
  }, []);

  const setView: SetView = useCallback(view => {
    dispatch({ type: 'SET_VIEW', view });
  }, []);

  const setCards: SetCards = useCallback(cards => {
    dispatch({ type: 'SET_CARDS', cards });
  }, []);

  const setCurrentCard: SetCurrentCard = useCallback(card => {
    dispatch({ type: 'SET_CURRENT_CARD', card });
  }, []);

  const addAttemptedCard: AddAttemptedCard = useCallback(card => {
    dispatch({ type: 'ADD_ATTEMPTED_CARD', card });
  }, []);

  const addCompletedCard: AddCompletedCard = useCallback(card => {
    dispatch({ type: 'ADD_COMPLETED_CARD', card });
  }, []);

  const setWrittenAnswer: SetWrittenAnswer = useCallback(value => {
    dispatch({ type: 'SET_WRITTEN_ANSWER', value });
  }, []);

  const setWrittenAnswerState: SetWrittenAnswerState = useCallback(
    answerState => {
      dispatch({ type: 'SET_WRITTEN_ANSWER_STATE', state: answerState });
    },
    [],
  );

  const setSessionId: SetSessionId = useCallback(id => {
    dispatch({ type: 'SET_SESSION_ID', id });
  }, []);

  return (
    <Provider
      value={{
        ...state,
        reset,
        setReviewMode,
        setView,
        setCards,
        setCurrentCard,
        addAttemptedCard,
        addCompletedCard,
        setWrittenAnswer,
        setWrittenAnswerState,
        setSessionId,
      }}
    >
      {children}
    </Provider>
  );
};

export const useReviewState = hook;
