/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import { Editor } from '@braindrop-editor/core';
import {
  stripDocumentMetadata,
  addDocumentMetadata,
  EditorDocument,
} from '@21st-night/editor';
import { components as muiComponents } from '@braindrop-editor/material-ui';
import components from './components';
import { InlineEditorToolbar } from './InlineEditorToolbar';
import { EditorHoveringToolbar } from './EditorHoveringToolbar';
import { LinkPlugin, EquationPlugin } from './web-editor-plugins';
import {
  ParagraphPlugin,
  RichTextPlugin,
  CoreEditorProps,
} from './core-editor-plugins';
import {
  createEditorStylePlugin,
  EditorStylePluginOptions,
} from './plugins/plugin-editor-style';
import { EditorWithRichTextPlugin } from '@braindrop-editor/plugin-rich-text';
import { EditorWithLinkPlugin } from './plugins/plugin-link';
import { EditorWithInlineEquationPlugin } from './plugins/plugin-equation';

export interface InlineEditorProps
  extends CoreEditorProps,
    EditorStylePluginOptions {
  hideToolbar?: boolean;
}

export interface InlineEditor
  extends EditorWithRichTextPlugin,
    EditorWithLinkPlugin,
    EditorWithInlineEquationPlugin {}

export const InlineEditor: React.FC<InlineEditorProps> = ({
  children,
  onChange,
  value: valueProp,
  minHeight = 30,
  maxHeight,
  placeholder,
  hideToolbar = false,
  autoFocus,
  readOnly,
  className,
  plugins: pluginsProp = [],
}) => {
  const [value, metadata] = stripDocumentMetadata(valueProp);

  const StylePlugin = useMemo(
    () =>
      createEditorStylePlugin({
        minHeight,
        maxHeight,
        placeholder,
        autoFocus,
        readOnly,
        className,
      }),
    [],
  );

  return (
    <Editor
      // @ts-ignore
      components={{ ...muiComponents, ...components }}
      plugins={[
        ParagraphPlugin,
        RichTextPlugin,
        LinkPlugin,
        EquationPlugin,
        StylePlugin,
        ...pluginsProp,
      ]}
      onChange={nextValue =>
        onChange(addDocumentMetadata(nextValue as EditorDocument, metadata))
      }
      value={value}
    >
      {!hideToolbar && <InlineEditorToolbar />}
      <EditorHoveringToolbar />
      {children}
    </Editor>
  );
};
