import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '@21st-night/ui';

export interface DeckFilterBarGroupProps {
  group: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingBottom: 16,
      overflow: 'visible',
    },
    label: {
      color: '#6B6B6B',
      fontSize: theme.typography.pxToRem(13),
      marginBottom: 8,
    },
  }),
);

export const DeckFilterBarGroup: React.FC<DeckFilterBarGroupProps> = ({
  children,
  group,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {group === 'card-metadata' && (
        <Typography className={classes.label}>Cards</Typography>
      )}
      {group === 'categories' && (
        <Typography className={classes.label}>Categories</Typography>
      )}
      {group === 'subcategories' && (
        <Typography className={classes.label}>Subcategories</Typography>
      )}
      {children}
    </div>
  );
};
