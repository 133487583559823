import { validateDoc, DocValidator } from '@21st-night/utils';
import { Deck, DeckUpdateData, DECK_TYPES } from '../../Deck.types';
import {
  NewDeckCardValidator,
  DeckCardUpdateValidator,
} from './deckCardValidator';
import {
  DeckNoteUpdateValidator,
  NewDeckNoteValidator,
} from './deckNoteValidator';

export const NewDeckValidator: DocValidator<Deck> = {
  type: [DECK_TYPES],
  id: 'string',
  createdAt: 'date',
  parent: ['optional', 'string'],
  original: ['optional', 'string'],
  description: ['optional', 'string'],
  lastActivity: ['optional', 'date'],
  name: 'string',
  cards: {
    type: 'map',
    items: { type: 'doc', validator: NewDeckCardValidator },
  },
  notes: {
    type: 'map',
    items: { type: 'doc', validator: NewDeckNoteValidator },
  },
  categories: 'string-array',
  subcategories: 'string-array',
  roles: 'string-array',
};

export const UpdateDeckValidator: DocValidator<DeckUpdateData> = {
  cards: {
    optional: true,
    type: 'map',
    items: { type: 'doc', validator: DeckCardUpdateValidator },
  },
  notes: {
    optional: true,
    type: 'map',
    items: { type: 'doc', validator: DeckNoteUpdateValidator },
  },
  description: ['optional', 'string'],
  lastActivity: ['optional', 'date'],
  name: ['optional', 'string'],
  categories: ['optional', 'string-array', 'arrayRemove', 'arrayUnion'],
  subcategories: ['optional', 'string-array', 'arrayRemove', 'arrayUnion'],
  roles: ['optional', 'string-array', 'arrayRemove', 'arrayUnion'],
};

export function validateNewDeck(doc: Deck): boolean {
  return validateDoc(NewDeckValidator, doc);
}

export function validateDeckUpdate(doc: DeckUpdateData): boolean {
  return validateDoc(UpdateDeckValidator, doc);
}
