import React, { useState } from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Slider, SliderProps } from '@21st-night/ui';
import { TaskTemplateFormSliderCountMark } from './TaskTemplateFormSliderCountMark';

export interface TaskTemplateFormSliderProps
  extends Omit<SliderProps, 'onChange' | 'min' | 'max' | 'defaultValue'> {
  /**
   * The default value of the slider.
   */
  defaultValue?: number[];
  /**
   * The maximum value of the slider.
   */
  max: number;
  /**
   * onChange
   */
  onChange?: (event: React.ChangeEvent, value: number[]) => void;
  /**
   * Prop applied to the start-date hidden input.
   */
  startInputProps?: React.HTMLProps<HTMLInputElement>;
  /**
   * Prop applied to the end-date hidden input.
   */
  endInputProps?: React.HTMLProps<HTMLInputElement>;
  /**
   * count
   */
  taskCount?: number;
  /**
   *
   */
  taskDays?: number[];
  /**
   * TaskCountMarkLabelComponent
   */
  TaskCountMarkLabelComponent?: React.ReactNode;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
    },
    sliderRail: {
      backgroundColor: theme.palette.grey[500],
    },
    sliderCountLabel: {
      position: 'absolute',
      right: 0,
      transform: 'translate(50%, -40px)',
      color: theme.palette.text.secondary,
    },
    sliderMark: {
      display: 'none',
    },
    sliderMarkLabel: {
      transform: 'translateX(0)',
    },
  }),
);

export const TaskTemplateFormSlider: React.FC<TaskTemplateFormSliderProps> = ({
  defaultValue,
  taskCount = 0,
  max,
  startInputProps,
  endInputProps,
  taskDays = [],
  onChange,
  ...other
}) => {
  const classes = useStyles();
  const [range, setRange] = useState(defaultValue || [1, max]);

  function handleChange(event: React.ChangeEvent<any>, val: number | number[]) {
    const value = val as number[];
    setRange(value as number[]);
    if (onChange) {
      onChange(event, value);
    }
  }

  return (
    <>
      <Slider
        color="secondary"
        valueLabelDisplay="on"
        min={1}
        max={max}
        value={range}
        // valueLabelFormat={sliderValueLabelFormat}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleChange}
        classes={{
          rail: classes.sliderRail,
          mark: classes.sliderMark,
          markLabel: classes.sliderMarkLabel,
          root: classes.root,
        }}
        {...other}
      />
      <TaskTemplateFormSliderCountMark
        start={range[0]}
        end={range[1]}
        days={taskDays}
        count={taskCount || 0}
      />
      <input
        readOnly
        type="hidden"
        name="start"
        value={range[0]}
        {...startInputProps}
      />
      <input
        readOnly
        type="hidden"
        name="end"
        value={range[1]}
        {...endInputProps}
      />
    </>
  );
};
