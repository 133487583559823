import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography, CircularProgress, LoadingIndicator } from '@21st-night/ui';

export type MediaFileStatus = 'none' | 'uploading' | 'processing' | 'ready';

export interface ImportFromCsvMediaUploadProgressProps {
  progress: number;
  fileName: string;
  status: MediaFileStatus;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    title: {
      margin: theme.spacing(2, 0, 1),
    },
  }),
);

export const ImportFromCsvMediaUploadProgress: React.FC<ImportFromCsvMediaUploadProgressProps> = ({
  progress,
  fileName,
  status,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {status === 'uploading' && (
        <>
          <CircularProgress variant="static" value={progress} size={60} />
          <Typography variant="h6" className={classes.title}>
            Uploading Media {progress}%
          </Typography>
          <Typography variant="caption">{fileName}</Typography>
        </>
      )}
      {status === 'processing' && (
        <>
          <LoadingIndicator size={60} />
          <Typography variant="h6" className={classes.title}>
            Processing Media
          </Typography>
          <Typography variant="caption">This can take a few minutes</Typography>
        </>
      )}
    </div>
  );
};
