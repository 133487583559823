import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { DeckContentNoteFileUpload } from '../../Deck.types';

export interface DeckNoteFileUploadsHook {
  uploads: DeckContentNoteFileUpload[];
  addFiles: (
    files: File[],
    type: DeckContentNoteFileUpload['type'],
  ) => DeckContentNoteFileUpload[];
  removeFile: (file: File) => void;
}

export const useDeckNoteFileUploads = (): DeckNoteFileUploadsHook => {
  const [uploads, setUploads] = useState<DeckContentNoteFileUpload[]>([]);

  function addFiles(files: File[], type: DeckContentNoteFileUpload['type']) {
    const newUploads = files.map(file => ({
      file,
      id: uuid(),
      createdAt: new Date(),
      category: null,
      subcategories: [],
      type,
    }));

    setUploads(value => [...value, ...newUploads]);

    return newUploads;
  }

  function removeFile(file: File) {
    setUploads(value => value.filter(upload => upload.file !== file));
  }

  return { uploads, addFiles, removeFile };
};
