import { DB } from '@21st-night/utils';
import { SegmentationData } from '../../onboarding.types';

export function setUserSegmentation(
  db: DB,
  userId: string,
  segmentation: SegmentationData,
): Promise<void> {
  const values: string[] = [segmentation.method];

  if (segmentation.method === 'self-studying') {
    values.push(segmentation.for || 'other');
  } else if (segmentation.method === 'tutoring') {
    values.push(segmentation.userType);
    if (segmentation.userType !== 'parent') {
      values.push(segmentation.for || 'other');
    }
  }

  if (segmentation.contentType) {
    values.push(segmentation.contentType);
  }

  return db
    .collection('users')
    .doc(userId)
    .update({
      segmentation: { ...segmentation, completed: true, values },
    });
}
