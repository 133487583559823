/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldValue } from '../FirebaseProvider';

export type RequiredKeys<T> = {
  [k in keyof T]-?: undefined extends T[k] ? never : k;
}[keyof T];

export type ArrayUnion = (...elements: string[]) => FieldValue;

export type ValidationFieldValue = FieldValue | string | string[] | boolean;
export type SerializedUpdateData = Record<string, ValidationFieldValue>;
export type ValidatorType =
  | 'optional'
  | 'null'
  | 'date'
  | 'string'
  | 'empty-string'
  | 'number'
  | 'boolean'
  | 'string-array'
  | 'number-array'
  | 'empty-map'
  | 'delete'
  | 'increment'
  | 'arrayUnion'
  | 'arrayRemove';
export type Enum = readonly (string | number)[];
export type Validator = (
  | ValidatorType
  | Enum
  | MapValidator
  | ArrayValidator
  | DocFieldValidator
)[];
export type ValidatorFunction = (value: unknown) => boolean;

export type FieldValidator =
  | ValidatorType
  | Validator
  | MapValidator
  | ArrayValidator
  | DocFieldValidator
  | false;

export type DocValidator<T = Record<string, unknown>> = Record<
  keyof T,
  FieldValidator
>;

export interface MapValidator {
  type: 'map';
  items: FieldValidator;
  optional?: boolean;
  keys?: 'string' | 'number' | 'any';
}

export interface ArrayValidator {
  type: 'array';
  items: DocValidator;
  optional?: boolean;
}

export interface DocFieldValidator {
  type: 'doc';
  validator: DocValidator;
  optional?: boolean;
  keys?: 'string' | 'number' | 'any';
}

// export type DocValidator<T = Record<string, unknown>> =
//   | CustomDocValidator
//   | DocValidatorRequiredFields<T>;
export type ValidationDoc = any;

export function isNull(value: unknown): boolean {
  return value === null;
}

export function isNotUndefined(value: unknown): boolean {
  return value !== undefined;
}

export function isDate(value: unknown): boolean {
  return value instanceof Date;
}

export function isString(value: unknown): boolean {
  return typeof value === 'string' && value !== '';
}

export function isEmptyString(value: unknown): boolean {
  return typeof value === 'string' && value === '';
}

export function isNumber(value: unknown): boolean {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): boolean {
  return typeof value === 'boolean';
}

export function isStringArray(value: unknown): boolean {
  return (
    Array.isArray(value) &&
    value.reduce((isStr, val) => isStr && isString(val), true)
  );
}

export function isNumberArray(value: unknown): boolean {
  return (
    Array.isArray(value) &&
    value.reduce((isNum, val) => isNum && isNumber(val), true)
  );
}

export function isEmptyMap(value: unknown): boolean {
  return (
    !!value &&
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 0 &&
    value.constructor === Object
  );
}

// Bit of a hack but it works
function isFirestoreFieldValue(
  value: unknown,
  method: 'delete' | 'arrayUnion' | 'arrayRemove' | 'increment',
): boolean {
  return (value as any)['r_'] === `FieldValue.${method}`;
}

export function isDelete(value: unknown): boolean {
  return isFirestoreFieldValue(value, 'delete');
}

export function isArrayUnion(value: unknown): boolean {
  return isFirestoreFieldValue(value, 'arrayUnion');
}

export function isArrayRemove(value: unknown): boolean {
  return isFirestoreFieldValue(value, 'arrayRemove');
}

export function isIncrement(value: unknown): boolean {
  return isFirestoreFieldValue(value, 'increment');
}

export function isAnyFirestoreFieldValue(value: unknown): boolean {
  return (
    isDelete(value) ||
    isArrayUnion(value) ||
    isArrayRemove(value) ||
    isIncrement(value)
  );
}

const validatorFns: Record<ValidatorType, ValidatorFunction> = {
  // A bit counter-intuitive, but undefined values are not allowed
  // and there is a seperate check which validates optional fields
  // as not being present on the document.
  optional: isNotUndefined,
  null: isNull,
  date: isDate,
  string: isString,
  'empty-string': isEmptyString,
  number: isNumber,
  boolean: isBoolean,
  'string-array': isStringArray,
  'number-array': isNumberArray,
  'empty-map': isEmptyMap,
  delete: isDelete,
  arrayRemove: isArrayRemove,
  arrayUnion: isArrayUnion,
  increment: isIncrement,
};

export function validateMap(
  validator: FieldValidator,
  value: unknown,
  field?: string,
): boolean {
  if (typeof value !== 'object' || Array.isArray(value)) {
    throw new Error(`Field ${field} must be of type map.`);
  }

  const map = value as Record<string, unknown>;

  Object.keys(map).forEach(key => {
    validateDocField(validator, map[key], `${field}.${key}`);
  });

  return true;
}

function isRequired(validator: FieldValidator): boolean {
  if (validator === false) {
    return false;
  }

  if (Array.isArray(validator)) {
    return !validator.includes('optional');
  }

  if (typeof validator === 'object') {
    return !validator.optional;
  }

  return true;
}

export function validateDoc(
  validator: DocValidator<Record<string, FieldValidator | DocValidator>>,
  doc: ValidationDoc,
  field?: string,
): boolean {
  if (typeof doc !== 'object' || Array.isArray(doc)) {
    throw new Error(`Invalid document${field ? `for field: ${field}` : '.'}`);
  }

  const keys = Object.keys(doc);

  const requiredFields = Object.keys(validator).filter(key =>
    isRequired(validator[key]),
  );

  requiredFields.forEach(requiredField => {
    if (typeof doc[requiredField] === 'undefined') {
      throw new Error(
        `Field "${
          field ? `${field}.${requiredField}` : requiredField
        }" is required.`,
      );
    }
  });

  keys.forEach(key => {
    if (!validator[key]) {
      throw new Error(
        `Setting field "${field ? `${field}.${key}` : key}" is not allowed.`,
      );
    }

    validateDocField(validator[key], doc[key], key);
  });

  return true;
}

function validateArray(
  validator: DocValidator<Record<string, FieldValidator | DocValidator>>,
  docs: ValidationDoc[],
  field?: string,
) {
  if (!Array.isArray(docs)) {
    throw new Error(`Field ${field} must be an array.`);
  }

  docs.forEach(doc => {
    validateDoc(validator, doc);
  });

  return true;
}

function validateAdvanced(
  validator: DocFieldValidator | MapValidator | ArrayValidator,
  value: unknown,
  field?: string,
): boolean {
  switch (validator.type) {
    case 'map':
      return validateMap(validator.items, value, field);
    case 'array':
      return validateArray(validator.items, value as unknown[], field);
    case 'doc':
      return validateDoc(validator.validator, value, field);
    default:
      throw new Error(`Invalid validator for field: ${field}`);
  }
}

export function validateDocField(
  validator: FieldValidator,
  value: unknown,
  field?: string,
): boolean {
  let valid = false;

  if (validator === false) {
    throw new Error(`Setting ${field} is not allowed.`);
  }

  if (Array.isArray(validator)) {
    validator.forEach(v => {
      if (!valid) {
        // Validator is an enum
        if (Array.isArray(v)) {
          valid =
            (isString(value) || isNumber(value)) &&
            v.includes(value as string | number);
        } else if (typeof v === 'object') {
          valid = validateAdvanced(
            v as DocFieldValidator | MapValidator | ArrayValidator,
            value,
            field,
          );
        } else {
          valid = validatorFns[v](value);
        }
      }
    });
  } else if (typeof validator === 'object') {
    valid = validateAdvanced(validator, value, field);
  } else {
    valid = validatorFns[validator](value);
  }

  if (!valid) {
    let errorValue = value;

    if (isAnyFirestoreFieldValue(value)) {
      errorValue = `${(value as any)['r_']} is not allowed.`;
    }

    throw new Error(`Invalid field value ${field}: ${errorValue}`);
  }

  return valid;
}
