import { toDate, DocumentSnapshot } from '@21st-night/utils';
import {
  CardStatus,
  LegacyCardReviewProgressDocument,
  CardReviewProgress,
} from '../../Review.types';

export function deserializeLegacyCardProgress(
  snapshot: DocumentSnapshot,
): CardReviewProgress {
  const data = snapshot.data() as LegacyCardReviewProgressDocument;
  let status: CardStatus = 'learning';
  let interval = 1;

  if (data.proficiency > 2) {
    status = 'learned';
    interval = 7;
  }

  if (!data.attempts) {
    data.attempts = [];
  }

  return {
    ...data,
    type: 'error-log',
    status,
    interval,
    nextReview: toDate(data.nextReview),
    lastReview: data.attempts.length
      ? toDate(data.attempts.slice(-1)[0].createdAt)
      : null,
    firstReview: data.attempts.length
      ? toDate(data.attempts[0].createdAt)
      : null,
    attempts: data.attempts.map(item => ({
      createdAt: toDate(item.createdAt),
      interval: 0,
      duration: 0,
      rating: item.rating,
    })),
  };
}
