import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '@21st-night/ui';

export interface ImportFromQuizletDoneProps {
  count: number;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 336,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    title: {
      margin: theme.spacing(2, 0, 1),
    },
  }),
);

export const ImportFromQuizletDone: React.FC<ImportFromQuizletDoneProps> = ({
  count,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Successfully imported {count} cards
      </Typography>
    </div>
  );
};
