import dayjs from 'dayjs';
import { CardType } from '@21st-night/cards';
import { CardReviewProgress } from '../../Review.types';
import { NEW_INTERVAL } from '../../review-constants';

/**
 * Generates CardReviewProgress.
 */
export function generateCardReviewProgress(
  type: CardType,
  options: Partial<CardReviewProgress> = {},
): CardReviewProgress {
  return {
    type,
    nextReview: dayjs().add(1, 'day').toDate(),
    lastReview: null,
    firstReview: null,
    attempts: [],
    proficiency: 0,
    interval: NEW_INTERVAL,
    retired: false,
    status: 'new',
    ...options,
  };
}
