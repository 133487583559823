import React from 'react';
import {
  CategorySelect,
  CategorySelectProps,
} from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export interface DeckCategorySelectProps
  extends Omit<
    CategorySelectProps,
    | 'categories'
    | 'onCreate'
    | 'onUpdate'
    | 'onDelete'
    | 'variant'
    | 'onChange'
    | 'value'
  > {
  value: string | null;
  onChange: (value: string | null) => void;
}

export const DeckCategorySelect: React.FC<DeckCategorySelectProps> = ({
  value,
  onChange,
  ...other
}) => {
  const deck = useDeck();

  function handleChange(value: string[]) {
    onChange(value[0] || null);
  }

  return (
    <CategorySelect
      variant="category"
      categories={deck.categories}
      value={!value ? [] : [value]}
      onChange={handleChange}
      onCreate={deck.createCategory}
      onUpdate={deck.renameCategory}
      onDelete={deck.deleteCategory}
      {...other}
    />
  );
};
