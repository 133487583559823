import { DocumentReference } from '@21st-night/utils';
import { DeckNotesUpdateData } from '../../Deck.types';
import { serializeDeckNotesUpdateData } from '../serializeDeckNotesUpdateData';

export function updateDeckNotes(
  deckRef: DocumentReference,
  data: DeckNotesUpdateData,
): Promise<void> {
  const serializedData = serializeDeckNotesUpdateData(data);

  return deckRef.update(serializedData);
}
