import { useState, useEffect, useCallback } from 'react';
import { useIdle } from 'react-use';
import { useInterval } from '@21st-night/utils';

/**
 * Returns the time (in seconds) a user has
 * been active on the page for.
 *
 * @param timeToIdle Incative time in seconds before user is considered idle.
 */
export function useTimeActive(
  timeToIdle = 20,
  interval = 5,
): [number, () => void] {
  const isIdle = useIdle(timeToIdle * 1000);
  const [time, setTime] = useState(0);
  const [lastActive, setLastActive] = useState(new Date().getTime());

  useInterval(() => {
    if (!isIdle) {
      setTime(time + interval * 1000);
      setLastActive(new Date().getTime());
    }
  }, interval * 1000);

  useEffect(() => {
    if (isIdle) {
      const activeTime = new Date().getTime() - lastActive;
      setTime(time + activeTime);
    }
  }, [isIdle]);

  const reset = useCallback((): void => {
    setTime(0);
  }, []);

  return [Math.round(time / 1000), reset];
}
