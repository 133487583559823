import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { Range } from 'slate';
import {
  deserializeDocument,
  EditorDocument,
  generateRichTextDocument,
  isDocumentEmpty,
  serializeDocument,
} from '@21st-night/editor-web';
import { useFirebase } from '@21st-night/utils';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Spaced,
  Typography,
  DialogProps,
  Alert,
  Prompt,
  DeleteButton,
} from '@21st-night/ui';
import {
  Card,
  CardEditor,
  CardPreview,
  getSelectionCardLinks,
  removeCardLinkFromDocument,
} from '@21st-night/cards-web';
import { NoteHighlighter, NoteListItem } from '@21st-night/notes-web';
import { DeckContentNote, useDeck, DeckCardEditorData } from '@21st-night/deck';
import { DeckCategorySelect } from '../DeckCategorySelect';
import { DeckSubcategoriesSelect } from '../DeckSubcategoriesSelect';
import { DeckNoteSelector } from '../DeckNoteSelector';

type View = 'editor' | 'preview' | 'select-note' | 'note';

export interface DeckCardEditorDialogProps
  extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
  data?: Partial<DeckCardEditorData>;
  imageUrl: string;
  submitButtonLabel: string;
  onClose: () => void;
  onSubmit: (data: DeckCardEditorData) => void;
  onDelete?: () => void;
  cardId?: string;
  type: Card['type'];
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
  },
  preview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftColumn: {
    flex: 1,
    minHeight: 500,
    paddingBottom: 40,
    paddingRight: theme.spacing(4),
    position: 'relative',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 240,
  },
  metadataOption: {
    marginTop: theme.spacing(2),
  },
  metadataLabel: {
    display: 'block',
  },
  attachNoteButton: {
    display: 'block',
    textAlign: 'left',
    color: '#A2A2A2',
    width: '100%',
    fontWeight: 400,
    fontSize: '16px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 2,
    paddingBottom: 2,
  },
  columnSpacer: {
    flex: 1,
  },
  noteSelectionInfo: {
    marginBottom: theme.spacing(2),
  },
}));

function defaultDocument(document?: string | null) {
  return document ? deserializeDocument(document) : generateRichTextDocument();
}

export const DeckCardEditorDialog: React.FC<DeckCardEditorDialogProps> = ({
  data = {},
  submitButtonLabel,
  imageUrl,
  open,
  onClose,
  onSubmit,
  onDelete,
  cardId,
  type,
  ...other
}) => {
  const classes = useStyles();
  const deck = useDeck();
  const [openInternal, setOpenInternal] = useState(open);
  const { db, functions, storage } = useFirebase();
  const content = useRef<HTMLDivElement | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [view, setView] = useState<View>('editor');
  const [previewHeight, setPreviewHeight] = useState<number | string>('auto');
  const [selectedNote, setSelectedNote] = useState<DeckContentNote | null>(
    null,
  );
  const [linkedNote, setLinkedNote] = useState<DeckContentNote | null>(null);
  const [noteSelection, setNoteSelection] = useState<Range | null>(null);
  const [existingLinksPrompt, setExistingLinksPropmpt] = useState(0);
  const [question, setQuestion] = useState<EditorDocument>(
    defaultDocument(data.question),
  );
  const [answer, setAnswer] = useState<EditorDocument>(
    defaultDocument(data.answer),
  );
  const [explanation, setExplanation] = useState<EditorDocument>(
    defaultDocument(data.explanation),
  );
  const [summary, setSummary] = useState<EditorDocument>(
    defaultDocument(data.summary),
  );
  const [category, setCategory] = useState(data.category || null);
  const [subcategories, setSubcategories] = useState(data.subcategories || []);

  useEffect(() => {
    if (open && open !== openInternal) {
      setOpenInternal(true);
      setErrors([]);
      setView('editor');
      setCategory(data.category || null);
      setSubcategories(data.subcategories || []);
      setQuestion(defaultDocument(data.question));
      setAnswer(defaultDocument(data.answer));
      setExplanation(defaultDocument(data.explanation));
      setSummary(defaultDocument(data.summary));
      const note = data.note ? deck.notes.find(n => n.id === data.note) : null;
      setSelectedNote(note || null);
    } else {
      setOpenInternal(open);
    }
  }, [open, openInternal, data]);

  function showView(nextView: View) {
    if (nextView !== 'editor') {
      if (content.current) {
        setPreviewHeight(content.current.clientHeight);
      } else {
        setPreviewHeight('auto');
      }
    }
    setView(nextView);
  }

  function handleSelectNote(note: DeckContentNote) {
    setSelectedNote(note);
    showView('note');
  }

  function handleClickSelectNoteBack() {
    showView('editor');
  }

  function handleClickNoteBack() {
    setSelectedNote(null);
    showView('select-note');
  }

  function linkNote() {
    setLinkedNote(selectedNote);
    showView('editor');
    setExistingLinksPropmpt(0);
  }

  function handleClickLinkNote() {
    if (selectedNote && noteSelection) {
      const existingLinks = getSelectionCardLinks(
        deserializeDocument(selectedNote.content),
        noteSelection,
      );

      if (existingLinks.length) {
        setExistingLinksPropmpt(existingLinks.length);
      } else {
        linkNote();
      }
    } else {
      linkNote();
    }
  }

  function handleRemoveNoteLink() {
    setLinkedNote(null);
    setNoteSelection(null);
    setSelectedNote(null);
    showView('editor');
  }

  function handleSubmit() {
    const validationErrors = [];

    if (isDocumentEmpty(question)) {
      validationErrors.push('Question is required.');
    }

    if (isDocumentEmpty(answer)) {
      validationErrors.push('Answer is required.');
    }

    setErrors(validationErrors);

    if (validationErrors.length) {
      return;
    }

    if (onSubmit) {
      onSubmit({
        category,
        subcategories,
        noteSelection,
        note: linkedNote ? linkedNote.id : null,
        question: serializeDocument(question),
        answer: serializeDocument(answer),
        explanation: isDocumentEmpty(explanation)
          ? null
          : serializeDocument(explanation),
        summary: isDocumentEmpty(summary) ? null : serializeDocument(summary),
      });
    }
    onClose();
  }

  function handleDelete() {
    if (!onDelete) {
      return;
    }
    onDelete();
    onClose();
  }

  return (
    <Dialog open={openInternal} fullWidth maxWidth="md" {...other}>
      {view !== 'preview' && (
        <DialogContent ref={content} className={classes.content}>
          <div className={classes.leftColumn}>
            {errors.map(error => (
              <Typography paragraph key={error} color="error">
                {error}
              </Typography>
            ))}
            {view === 'editor' && (
              <CardEditor
                type={type}
                imageUrl={imageUrl}
                db={db}
                storage={storage}
                functions={functions}
                question={question}
                answer={answer}
                explanation={explanation}
                summary={summary}
                onQuestionChange={setQuestion}
                onAnswerChange={setAnswer}
                onExplanationChange={setExplanation}
                onSummaryChange={setSummary}
              />
            )}
            {view === 'select-note' && (
              <DeckNoteSelector onSelectNote={handleSelectNote} />
            )}
            {view === 'note' && selectedNote && (
              <>
                <Alert severity="info" className={classes.noteSelectionInfo}>
                  Select text to link to a specific part of the note.
                </Alert>
                <NoteHighlighter
                  db={db}
                  storage={storage}
                  functions={functions}
                  imageUrl={imageUrl}
                  value={
                    cardId
                      ? removeCardLinkFromDocument(
                          deserializeDocument(selectedNote.content),
                          cardId,
                        )
                      : deserializeDocument(selectedNote.content)
                  }
                  onSelectionChange={setNoteSelection}
                />
                <Prompt
                  open={existingLinksPrompt > 0}
                  onClose={() => setExistingLinksPropmpt(0)}
                  title="Remove existing card link?"
                  body="The selected text contains one or more existing card links. Existing links will be removed."
                  onClickPrimaryButton={linkNote}
                />
              </>
            )}
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.metadataOption}>
              <Typography variant="overline" className={classes.metadataLabel}>
                Category
              </Typography>
              <DeckCategorySelect value={category} onChange={setCategory} />
            </div>
            <div className={classes.metadataOption}>
              <Typography variant="overline" className={classes.metadataLabel}>
                Subcategories
              </Typography>
              <DeckSubcategoriesSelect
                value={subcategories}
                onChange={setSubcategories}
              />
            </div>
            <div className={classes.metadataOption}>
              <Typography variant="overline" className={classes.metadataLabel}>
                Note
              </Typography>
              {!linkedNote && (
                <Button
                  color="inherit"
                  className={classes.attachNoteButton}
                  onClick={() => setView('select-note')}
                >
                  Attach a note
                </Button>
              )}
              {linkedNote && (
                <>
                  <NoteListItem
                    button
                    maxChars={56}
                    maxElements={1}
                    imageUrl={imageUrl}
                    note={linkedNote}
                    onClick={() => setView('select-note')}
                  />
                  <Button size="small" onClick={handleRemoveNoteLink}>
                    Remove
                  </Button>
                </>
              )}
            </div>
          </div>
        </DialogContent>
      )}
      {view === 'preview' && (
        <DialogContent
          className={classes.preview}
          style={{ height: previewHeight }}
        >
          <CardPreview
            imageUrl={imageUrl}
            question={question}
            answer={answer}
            explanation={explanation}
            summary={summary}
          />
        </DialogContent>
      )}
      <DialogActions>
        {view === 'editor' && <Button onClick={onClose}>Cancel</Button>}
        {view === 'select-note' && (
          <Button onClick={handleClickSelectNoteBack}>Back</Button>
        )}
        {view === 'note' && (
          <Spaced>
            <Button onClick={handleClickNoteBack}>Back</Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleClickLinkNote}
            >
              {noteSelection && !Range.isCollapsed(noteSelection)
                ? 'Link selection'
                : 'Link note'}
            </Button>
          </Spaced>
        )}
        <div style={{ flex: 1 }} />
        <Spaced spacing={2}>
          {view === 'editor' && (
            <>
              {onDelete && <DeleteButton onConfirm={handleDelete} />}
              <Button onClick={() => setView('preview')}>Preview</Button>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={view === 'select-note' || view === 'note'}
          >
            {submitButtonLabel}
          </Button>
        </Spaced>
      </DialogActions>
    </Dialog>
  );
};
