import React from 'react';
import { Apple } from '@21st-night/icons';
import { LoginButton } from '../LoginButton';

export type AppleButtonProps = React.HTMLAttributes<HTMLButtonElement>;

export const AppleButton: React.FC<AppleButtonProps> = props => {
  return (
    <LoginButton {...props} dark backgroundColor="#000000" icon={<Apple />}>
      Sign in with Apple
    </LoginButton>
  );
};
