import { deserializeDocument } from '@21st-night/editor';
import { generateCard } from '../generateCard';
import { Card, CardData } from '../../types';

export function generateCardBatch(
  author: string,
  deck: string,
  type: Card['type'],
  data: CardData[],
): Card[] {
  return data.map(cardData =>
    generateCard({
      author,
      deck,
      type,
      id: cardData.id,
      question: deserializeDocument(cardData.question),
      answer: deserializeDocument(cardData.answer),
      explanation: cardData.explanation
        ? deserializeDocument(cardData.explanation)
        : null,
      summary: cardData.summary ? deserializeDocument(cardData.summary) : null,
    }),
  );
}
