import React, { useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@21st-night/ui';
import { useFileUpload, useFirebase } from '@21st-night/utils-web';
import { ImportFromAnkiFileSelection } from './ImportFromAnkiFileSelection';
import {
  ImportFromAnkiProcessing,
  ProcessingStatus,
} from './ImportFromAnkiProcessing';
import { ImportFromAnkiSuccess } from './ImportFromAnkiSuccess';
import { ImportFromAnkiError } from './ImportFromAnkiError';
import { CardType } from '@21st-night/cards';

type View = 'file-selection' | 'processing' | 'success' | 'error';

export interface ImportFromAnkiDialogProps
  extends Omit<DialogProps, 'onError'> {
  deckId: string;
  cardType: CardType;
  onError?: (error: string) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
    },
    flex: {
      flex: 1,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    stepCounter: {
      color: theme.palette.text.secondary,
    },
  }),
);

export const ImportFromAnkiDialog: React.FC<ImportFromAnkiDialogProps> = ({
  className,
  deckId,
  cardType,
  onError,
  onClose,
  ...other
}) => {
  const classes = useStyles();
  const { storage, functions } = useFirebase();
  const [processingStatus, setProcessingStatus] = useState<ProcessingStatus>(
    'uploading',
  );
  const [view, setView] = useState<View>('file-selection');
  const [importedCount, setImportedCount] = useState(0);
  const [file, setFile] = useState<File>();

  function close() {
    if (onClose) {
      onClose();
    }

    setTimeout(() => {
      setView('file-selection');
      setFile(undefined);
    }, 1000);
  }

  async function handleUploadSuccess(url: string, id: string) {
    setProcessingStatus('processing');

    const importCards = functions.httpsCallable('importCardsFromAnki');

    try {
      const result = await importCards({
        file: id,
        deck: deckId,
        type: cardType,
      });

      if (result && result.data) {
        setImportedCount(result.data.cardsImported as number);
        setView('success');
      }
    } catch (err) {
      if (onError) {
        onError(err);
      }
      setView('error');
    }
  }

  const { upload, progress } = useFileUpload(storage, {
    maxSize: 1024,
    onUploadSuccess: handleUploadSuccess,
  });

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length) {
      setFile(event.target.files[0]);
    }
  }

  function processFile() {
    if (!file) {
      return;
    }

    setView('processing');
    upload(file);
  }

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth="xs"
      className={cn(classes.root, className)}
      {...other}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Import from Anki</Typography>
      </DialogTitle>
      <DialogContent>
        {view === 'file-selection' && (
          <ImportFromAnkiFileSelection
            file={file}
            fileInputProps={{ onChange: handleFileChange }}
          />
        )}
        {view === 'processing' && file && (
          <ImportFromAnkiProcessing
            fileName={file.name}
            progress={progress}
            status={processingStatus}
          />
        )}
        {view === 'success' && (
          <ImportFromAnkiSuccess cardCount={importedCount} />
        )}
        {view === 'error' && <ImportFromAnkiError />}
      </DialogContent>
      <DialogActions>
        {view === 'file-selection' && (
          <Button size="small" onClick={close}>
            Cancel
          </Button>
        )}
        {(view === 'success' || view === 'error') && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={close}
          >
            Close
          </Button>
        )}
        {view === 'file-selection' && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!file}
            onClick={processFile}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
