import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LoadingIndicator,
} from '@21st-night/ui';

export interface OnboardingCoachingFormValues {
  name: string;
  email: string;
  phone?: string;
  date: string;
}

export interface OnboardingCoachingFormProps {
  loading?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmitForm: (values: OnboardingCoachingFormValues) => void;
}

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      position: 'relative',
      paddingTop: '42%',
      backgroundImage: `url(${require('./header.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  }),
);

export const OnboardingCoachingForm: React.FC<OnboardingCoachingFormProps> = ({
  loading,
  onClose,
  onSubmitForm,
  ...other
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { name: '', email: '', phone: '', date: '' },
  });

  useEffect(() => {
    new Image().src = require('./header.png');
  }, []);

  return (
    <Dialog onClose={onClose} {...other}>
      <div className={classes.header} />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent style={{ paddingTop: 0 }}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label={
              <span>
                Name<sup>*</sup>
              </span>
            }
            error={errors.name}
            disabled={loading}
            inputRef={register({ required: true })}
          />
          <TextField
            fullWidth
            margin="normal"
            name="email"
            label={
              <span>
                Email<sup>*</sup>
              </span>
            }
            error={errors.email}
            disabled={loading}
            inputRef={register({ required: true })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Phone"
            name="phone"
            error={errors.phone}
            disabled={loading}
            inputRef={register}
          />
          <TextField
            fullWidth
            margin="normal"
            label={
              <span>
                Best time to contact you<sup>*</sup>
              </span>
            }
            error={errors.date}
            disabled={loading}
            placeholder="e.g. Weekdays after 6 PM"
            name="date"
            inputRef={register({ required: true })}
          />
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Send
            </Button>
          </DialogActions>
        )}
        {loading && (
          <DialogActions>
            <LoadingIndicator />
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
