import { StreakDay, RecentStreak } from '../../Gamification.types';

/**
 * Returns an array of booleans indicating if the day
 * was a streak day.
 * Days are ordered in ascending order.
 *
 * @param {array} streakDays Array of the user's recent streak-days documents.
 * @param {number} count     Number of days to include in the result.
 *
 * @returns {array} Array of boolean values.
 */
export function generateRecentStreakDaysArray(
  streakDays: StreakDay[],
  count: number,
): RecentStreak {
  // Start with every day as not active
  const days = [];

  // Start with each day marked as not a streak day
  for (let i = 0; i < count; i += 1) {
    days.push(0);
  }

  // Get midnight today in milliseconds since epoch
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Calculate one day in milliseconds
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  streakDays.forEach(streakDay => {
    // Get midnight of the createdAt date
    const day = streakDay.createdAt;
    day.setHours(0, 0, 0, 0);

    // Calculate how many days ago the document was created
    const createdDaysAgo =
      (today.getTime() - day.getTime()) / dayInMilliseconds;

    // Mark that day as a streak day if it
    // is within the recent days limit
    if (createdDaysAgo < count) {
      days[createdDaysAgo] = streakDay.isFreeze ? 2 : 1;
    }
  });

  // The days were set in reverse order (today being at index 0)
  // so we need to reverse the array
  days.reverse();
  return days;
}
