import { shuffle } from '../shuffleArray';

/**
 * Randomly select an element from
 * an array.
 */
export function selectRandomArrayElement<A>(array: A[]): A {
  const shuffled = shuffle(array);
  return shuffled.slice(0, 1)[0];
}
