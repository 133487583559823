import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Dialog, DialogProps } from '../Dialog';
import { Typography } from '../Typography';
import { Spaced } from '../Spaced';
import { Button } from '../Button';

export interface PromptProps extends Omit<DialogProps, 'onClose' | 'title'> {
  onClose: () => void;
  title?: React.ReactNode;
  body?: React.ReactNode;
  primaryButtonLabel?: React.ReactNode;
  secondaryButtonLabel?: React.ReactNode;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      minHeight: 240,
      maxWidth: 260,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'bold',
      textAlign: 'center',
    },
    body: {},
    spacer: {
      flex: 1,
    },
    buttons: {
      alignSelf: 'stretch',
    },
  }),
);

export const Prompt: React.FC<PromptProps> = ({
  children,
  title,
  body,
  onClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
  primaryButtonLabel = 'Confirm',
  secondaryButtonLabel = 'Cancel',
  ...other
}) => {
  const classes = useStyles();
  return (
    <Dialog classes={{ paper: classes.paper }} onClose={onClose} {...other}>
      {title && (
        <Typography gutterBottom variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
      )}
      {body && <Typography className={classes.body}>{body}</Typography>}
      <div className={classes.spacer} />
      <Spaced className={classes.buttons}>
        {children ? (
          children
        ) : (
          <>
            <Button
              fullWidth
              variant="contained"
              onClick={
                onClickSecondaryButton ? onClickSecondaryButton : onClose
              }
            >
              {secondaryButtonLabel}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClickPrimaryButton}
            >
              {primaryButtonLabel}
            </Button>
          </>
        )}
      </Spaced>
    </Dialog>
  );
};
