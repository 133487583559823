import React from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core';

export type AvatarProps = MuiAvatarProps;

export const Avatar: React.FC<AvatarProps> = ({ ...other }) => {
  return <MuiAvatar {...other} />;
};
