import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, useTheme } from '@21st-night/styles';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core/IconButton';

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'size'> {
  /**
   * If `true`, the icon will have active styling.
   */
  active?: boolean;
  /**
   * The color of the icon when `active={true}`.
   */
  activeColor?: string;
  alt?: string;
  /**
   * If `true`, the hover & active scaling animation will be disabled.
   */
  disableScaling?: boolean;
  /**
   * The size of the icon button.
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * If `true`, the button will have toggle styling.
   */
  toggle?: boolean;
  children: React.ReactElement;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 6,
      color: theme.palette.action.active,
      transition: 'transform 0.25s ease-out',
      '&:hover, &:focus': {
        background: 'none',
        // transform: 'scale(1.1)',
      },
      '&:active': {
        // transform: 'scale(0.9)',
      },
    },
    disableScaling: {
      '&:hover, &:focus': {
        background: 'none',
        transform: 'scale(1)',
      },
      '&:active': {
        transform: 'scale(1)',
      },
    },
    sizeSmall: {
      padding: 3,
      fontSize: theme.typography.pxToRem(20),
    },
  }),
);

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      active,
      activeColor: activeColorProp,
      children,
      className,
      disableScaling = false,
      size: sizeProp = 'medium',
      style = {},
      toggle,
      ...other
    },
    ref,
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const activeColor = activeColorProp || theme.palette.text.primary;
    const inactiveColor = toggle
      ? theme.palette.action.disabled
      : theme.palette.action.active;
    let size: MuiIconButtonProps['size'];

    if (sizeProp === 'small' || sizeProp === 'medium') {
      size = sizeProp;
    }

    return (
      <MuiIconButton
        ref={ref}
        classes={{ sizeSmall: classes.sizeSmall }}
        className={clsx(
          classes.root,
          {
            [classes.disableScaling]: disableScaling,
          },
          className,
        )}
        size={size}
        style={{
          color: active ? activeColor : inactiveColor,
          ...style,
        }}
        {...other}
      >
        {React.cloneElement(children, {
          fontSize: sizeProp === 'large' ? 'large' : 'inherit',
        })}
      </MuiIconButton>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
