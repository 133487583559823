import React from 'react';
import {
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
} from '@material-ui/core';

export type RadioProps = MuiRadioProps;

export const Radio: React.FC<RadioProps> = ({ ...other }) => {
  return <MuiRadio {...other} />;
};
