import {
  WebViewNoteEditorEventPayload,
  WebViewNoteEditorEventType,
  WebViewOnChangeNoteContentEventPayload,
} from '../../types';
import { postEventFromWebView } from '../postEventFromWebView';

export function postWebViewNoteEditorEvent(event: 'OPEN_CATEGORY_SELECT'): void;
export function postWebViewNoteEditorEvent(
  event: 'OPEN_SUBCATEGORIES_SELECT',
): void;
export function postWebViewNoteEditorEvent(
  event: 'ON_CHANGE_CONTENT',
  payload: WebViewOnChangeNoteContentEventPayload,
): void;
export function postWebViewNoteEditorEvent(
  event: WebViewNoteEditorEventType,
  payload?: WebViewNoteEditorEventPayload,
): void {
  postEventFromWebView(event, payload);
}
