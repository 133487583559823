import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeck } from '../updateDeck';

export function createCategory(
  db: DB,
  deckRef: DocumentReference,
  category: string,
): Promise<void> {
  return updateDeck(deckRef, {
    categories: db.arrayUnion(category),
  });
}
