import React from 'react';
import { Select, SelectProps, MenuItem } from '@21st-night/ui';

export interface DayOfTheWeekSelectProps extends SelectProps {
  value: number;
}

export const DayOfTheWeekSelect: React.FC<DayOfTheWeekSelectProps> = ({
  ...other
}) => {
  return (
    <Select {...other}>
      <MenuItem value={0}>Sunday</MenuItem>
      <MenuItem value={1}>Monday</MenuItem>
      <MenuItem value={2}>Tuesday</MenuItem>
      <MenuItem value={3}>Wednesday</MenuItem>
      <MenuItem value={4}>Thursday</MenuItem>
      <MenuItem value={5}>Friday</MenuItem>
      <MenuItem value={6}>Saturday</MenuItem>
    </Select>
  );
};
