import { Text, Transforms } from 'slate';
import {
  BraindropEditorPlugin,
  BraindropEditor,
  isNodeType,
} from '@braindrop-editor/core';
import { ElementClozeDeletion } from './ElementClozeDeletion';
import { ElementClozeDeletionMobile } from './ElementClozeDeletionMobile';
import { ClozeDeletionElement } from './ClozeDeletionPlugin.types';

export interface ClozeDeletionPluginOptions {
  mobile?: boolean;
}

export const createClozeDeletionPlugin = (
  options?: ClozeDeletionPluginOptions,
) => (editor: BraindropEditor): BraindropEditorPlugin<ClozeDeletionElement> => {
  const mobile = (options && options.mobile) || false;

  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;
    if (isNodeType(entry, { allow: ['cloze-deletion'] })) {
      const cloze = node as ClozeDeletionElement;
      if (cloze.children && cloze.children.length === 1) {
        const { text } = cloze.children[0] as Text;

        if (text === ' ') {
          Transforms.delete(editor, { at: path });
        }

        if (!text.startsWith(' ')) {
          const textPath = [...path, 0];
          Transforms.insertText(editor, ' ', {
            at: {
              anchor: { path: textPath, offset: 0 },
              focus: { path: textPath, offset: 0 },
            },
          });
        }

        if (!text.endsWith(' ')) {
          const textPath = [...path, 0];
          Transforms.insertText(editor, ' ', {
            at: {
              anchor: { path: textPath, offset: text.length + 1 },
              focus: { path: textPath, offset: text.length + 1 },
            },
          });
          if (!cloze.wrapped) {
            Transforms.collapse(editor, { edge: 'start' });
            Transforms.move(editor, {
              unit: 'character',
              distance: 1,
              reverse: true,
            });
          }
        }
      }

      if (cloze.wrapped) {
        Transforms.unsetNodes(editor, ['wrapped'], { at: path });
      }
    }
    return normalizeNode(entry);
  };

  return {
    elements: [
      {
        isInline: true,
        component: mobile ? ElementClozeDeletionMobile : ElementClozeDeletion,
        type: 'cloze-deletion',
      },
    ],
  };
};
