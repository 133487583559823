import { DocumentReference } from '@21st-night/utils';
import { StudyPlanTemplate } from '../../types';
import { validateNewStudyPlanTemplate } from '../validators';

export function createStudyPlanTemplate(
  templateRef: DocumentReference,
  template: StudyPlanTemplate,
): Promise<void> {
  validateNewStudyPlanTemplate(template);

  return templateRef.set(template);
}
