import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '@21st-night/ui';
import { CategoryTag } from '../CategoryTag';

interface BaseProps extends React.HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
}

export interface CategoryTagsButtonProps extends BaseProps {
  value: string | null;
  variant?: 'category';
}

export interface SubcategoryTagsButtonProps extends BaseProps {
  value: string[];
  variant: 'subcategory';
}

export interface MetadataTagsButtonProps extends BaseProps {
  value: string[];
  variant: 'metadata';
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(0.5),
      maxWidth: '100%',
      cursor: 'pointer',
      marginLeft: -theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      transition: theme.transitions.create('background', {
        duration: theme.transitions.duration.short,
      }),
      '&:active': {
        background: theme.palette.action.hover,
      },
    },
    tag: {
      userSelect: 'none',
      margin: '0 2px',
    },
    placeholderText: {
      color: theme.palette.text.hint,
      marginLeft: 4,
      userSelect: 'none',
    },
  }),
);

export const CategoryTagsButton: React.FC<
  CategoryTagsButtonProps | SubcategoryTagsButtonProps | MetadataTagsButtonProps
> = ({ className, value, variant, placeholder, ...other }) => {
  const classes = useStyles();
  const categories = Array.isArray(value) ? value : value ? [value] : [];

  return (
    <div role="button" className={cn(classes.root, className)} {...other}>
      {!categories.length && placeholder && (
        <Typography variant="subtitle1" className={classes.placeholderText}>
          {placeholder}
        </Typography>
      )}
      {categories.map(category => (
        <CategoryTag
          size="medium"
          key={category}
          variant={variant}
          label={category}
          className={classes.tag}
        />
      ))}
    </div>
  );
};
