import { FieldValue, SerializedUpdateData } from '@21st-night/utils';
import { DeckNotesUpdateData, DeckNoteUpdateData } from '../../Deck.types';

type NoteField = keyof DeckNoteUpdateData;

export function serializeDeckNotesUpdateData(
  data: DeckNotesUpdateData,
): SerializedUpdateData {
  const serializedData: SerializedUpdateData = {};

  Object.keys(data).forEach(noteId => {
    const noteUpdate = data[noteId];

    Object.keys(noteUpdate).forEach(fieldName => {
      const field = fieldName as NoteField;
      const value = noteUpdate[field] as FieldValue;

      serializedData[`notes.${noteId}.${field}`] = value;
    });
  });

  return serializedData;
}
