import { DocumentReference } from '@21st-night/utils';
import { DeckNote } from '../../Deck.types';
import { validateNewDeckNote } from '../validators';

export function addNoteToDeck(
  deckRef: DocumentReference,
  note: DeckNote,
): Promise<void> {
  validateNewDeckNote(note);

  return deckRef.update({
    [`notes.${note.id}`]: note,
    lastActivity: new Date(),
  });
}
