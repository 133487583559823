import React from 'react';
import {
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
} from '@material-ui/core';

export type ListSubheaderProps = MuiListSubheaderProps;

export const ListSubheader: React.FC<ListSubheaderProps> = ({ ...other }) => {
  return <MuiListSubheader {...other} />;
};
