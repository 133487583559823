import { DocumentReference } from '@21st-night/utils';
import { DeckNoteUpdateData } from '../../Deck.types';
import { serializeDeckNotesUpdateData } from '../serializeDeckNotesUpdateData';

export function updateDeckNote(
  deckRef: DocumentReference,
  noteId: string,
  data: DeckNoteUpdateData,
): Promise<void> {
  const serializedData = serializeDeckNotesUpdateData({ [noteId]: data });

  return deckRef.update(serializedData);
}
