import React from 'react';
import { createContext, DocumentReference } from '@21st-night/utils';
import { Deck } from '../Deck.types';
import { useDeckCards, DeckCardsHook } from '../hooks/useDeckCards';
import { useDeckNotes } from '../hooks/useDeckNotes';
import {
  useDeckNoteFileUploads,
  DeckNoteFileUploadsHook,
} from '../hooks/useDeckNoteFileUploads';
import { DeckProvider, DeckContext, DeckConsumer } from '../DeckProvider';

export interface DeckContentProviderProps {
  deck: Deck;
  docRef: DocumentReference;
}

export interface DeckContentContext extends DeckContext {
  cardEditors: DeckCardsHook['cardEditors'];
  addCard: DeckCardsHook['addCard'];
  removeCardEditor: DeckCardsHook['removeCardEditor'];
  noteFileUploads: DeckNoteFileUploadsHook['uploads'];
  addNoteFileUploads: DeckNoteFileUploadsHook['addFiles'];
  removeNoteFileUpload: DeckNoteFileUploadsHook['removeFile'];
}

const [hook, Provider, Consumer] = createContext<DeckContentContext>();

export const DeckContentProvider: React.FC<DeckContentProviderProps> = ({
  children,
  deck,
  docRef,
}) => {
  const {
    cards,
    loading: loadingCards,
    addCard,
    cardEditors,
    removeCardEditor,
  } = useDeckCards(deck);
  const [notes, loadingNotes] = useDeckNotes(deck);
  const {
    uploads: noteFileUploads,
    addFiles: addNoteFileUploads,
    removeFile: removeNoteFileUpload,
  } = useDeckNoteFileUploads();

  return (
    <DeckProvider
      deck={deck}
      docRef={docRef}
      cards={cards}
      notes={notes}
      loadingCards={loadingCards}
      loadingNotes={loadingNotes}
    >
      <DeckConsumer>
        {deckContext => (
          <Provider
            value={{
              ...deckContext,
              addCard,
              removeCardEditor,
              cardEditors,
              noteFileUploads,
              addNoteFileUploads,
              removeNoteFileUpload,
              content: [
                ...noteFileUploads,
                ...cardEditors,
                ...deckContext.content,
              ],
            }}
          >
            {children}
          </Provider>
        )}
      </DeckConsumer>
    </DeckProvider>
  );
};

export const DeckContentConsumer = Consumer;
export const useDeckContent = hook;
