import { useCallback, useState } from 'react';
import { getProficiencyKey, ProficiencyKey } from '@21st-night/review';
import {
  filterByCategory,
  filterBySubcategory,
  isCardDueNow,
  isCard,
} from '../../api';
import {
  DeckFilterOption,
  DeckFilterType,
  DeckContentItem,
  DeckFilterGroup,
  DeckContentCard,
  DeckContentNote,
} from '../../Deck.types';
import dayjs from 'dayjs';

export interface DeckFilterHook {
  filters: DeckFilterOption[];
  categoryFilters: DeckFilterOption[];
  subcategoryFilters: DeckFilterOption[];
  filter: <T extends DeckContentItem | DeckContentCard | DeckContentNote>(
    items: T[],
    filters?: DeckFilterOption[],
  ) => T[];
  setFilters: (filters: DeckFilterOption[]) => void;
  toggleFilter: (filter: DeckFilterOption) => void;
  toggleCategoryFilter: (category: string) => void;
  toggleSubcategoryFilter: (subcategory: string) => void;
  filterDate: Date;
  setFilterDate: (value: Date) => void;
}

export const useDeckFilter = (
  defaultFilters: DeckFilterOption[] = [],
): DeckFilterHook => {
  const [filters, setFilters] = useState<DeckFilterOption[]>(defaultFilters);
  const [filterDate, setFilterDate] = useState<Date>(new Date());

  const toggleFilter = useCallback(
    (filter: DeckFilterOption): void => {
      const removed = filters.filter(
        option => option.type !== filter.type || option.value !== filter.value,
      );

      if (removed.length !== filters.length) {
        setFilters(removed);
      } else {
        setFilters([...filters, filter]);
      }
    },
    [filters],
  );

  const toggleCategoryFilter = useCallback(
    (category: string) =>
      toggleFilter({
        type: 'category',
        group: 'categories',
        label: category,
        value: category,
      }),
    [filters],
  );

  const toggleSubcategoryFilter = useCallback(
    (subcategory: string) =>
      toggleFilter({
        type: 'subcategory',
        group: 'subcategories',
        label: subcategory,
        value: subcategory,
      }),
    [filters],
  );

  const isDeckFilterGroupActive = useCallback(
    (group: DeckFilterGroup, filterSet: DeckFilterOption[]) => {
      return !!filterSet.find(filter => filter.group === group);
    },
    [],
  );

  const isDeckFilterTypeActive = useCallback(
    (type: DeckFilterType, filterSet: DeckFilterOption[]) => {
      return !!filterSet.find(filter => filter.type === type);
    },
    [],
  );

  const getDeckFilterTypeValues = useCallback(
    (type: DeckFilterType, filterSet: DeckFilterOption[]) => {
      return filterSet
        .filter(filter => filter.type === type)
        .map(filter => filter.value);
    },
    [],
  );

  const isFilterActive = useCallback(
    (type: DeckFilterType, value: string, filterSet: DeckFilterOption[]) => {
      return !!filterSet.find(
        filter => filter.type === type && filter.value === value,
      );
    },
    [],
  );

  const filter = useCallback(
    <T extends DeckContentItem | DeckContentCard | DeckContentNote>(
      items: T[],
      customFilters?: DeckFilterOption[],
    ): T[] => {
      const filterSet = customFilters || filters;
      let filteredItems: T[] = [];

      if (isDeckFilterTypeActive('item-type', filterSet)) {
        if (isFilterActive('item-type', 'card', filterSet)) {
          filteredItems = [
            ...filteredItems,
            ...items.filter(item => isCard(item)),
          ];
        } else {
          if (isFilterActive('item-type', 'flashcard', filterSet)) {
            filteredItems = [
              ...filteredItems,
              ...items.filter(item => item.type === 'flashcard'),
            ];
          }

          if (isFilterActive('item-type', 'error-log', filterSet)) {
            filteredItems = [
              ...filteredItems,
              ...items.filter(item => item.type === 'error-log'),
            ];
          }
        }

        if (isFilterActive('item-type', 'note', filterSet)) {
          filteredItems = [
            ...filteredItems,
            ...items.filter(item => item.type === 'note'),
          ];
        }
      } else {
        filteredItems = [...items];
      }

      if (isDeckFilterTypeActive('category', filterSet)) {
        const categories = getDeckFilterTypeValues('category', filterSet);
        filteredItems = filterByCategory(filteredItems, categories);
      }

      if (isDeckFilterTypeActive('subcategory', filterSet)) {
        const subcategories = getDeckFilterTypeValues('subcategory', filterSet);
        filteredItems = filterBySubcategory(filteredItems, subcategories);
      }

      if (isDeckFilterTypeActive('boolean', filterSet)) {
        const booleanFilters = getDeckFilterTypeValues('boolean', filterSet);
        booleanFilters.forEach(booleanFilter => {
          if (booleanFilter.startsWith('!')) {
            filteredItems = filteredItems.filter(
              item => !(item as any)[booleanFilter.slice(1)],
            );
          } else {
            filteredItems = filteredItems.filter(
              item => (item as any)[booleanFilter],
            );
          }
        });
      }

      if (isDeckFilterTypeActive('link', filterSet)) {
        const linkFilters = getDeckFilterTypeValues('link', filterSet);
        linkFilters.forEach(linkFilter => {
          filteredItems = filteredItems.filter(
            item =>
              (item as DeckContentNote).id === linkFilter ||
              (item as DeckContentCard).note === linkFilter,
          );
        });
      }

      if (isFilterActive('date', 'date-added', filterSet) && filterDate) {
        filteredItems = filteredItems.filter(item =>
          dayjs(item.createdAt).isSame(filterDate, 'date'),
        );
      }

      if (isDeckFilterGroupActive('card-metadata', filterSet)) {
        filteredItems = filteredItems.filter(item => isCard(item));
      }

      if (isFilterActive('date', 'due-now', filterSet) && filterDate) {
        filteredItems = filteredItems.filter(
          item => isCard(item) && isCardDueNow(item as DeckContentCard),
        );
      }

      if (isDeckFilterTypeActive('proficiency', filterSet)) {
        const profFilters = getDeckFilterTypeValues('proficiency', filterSet);
        profFilters.forEach(profFilter => {
          filteredItems = filteredItems.filter(item =>
            (profFilter as ProficiencyKey) === 'not-started'
              ? !(item as DeckContentCard).lastReview
              : getProficiencyKey({
                  proficiency: (item as DeckContentCard).proficiency || 0,
                  notStarted: !(item as DeckContentCard).lastReview,
                }) === profFilter,
          );
        });
      }

      return filteredItems;
    },
    [filters, filterDate],
  );

  const categoryFilters = filters.filter(option => option.type === 'category');
  const subcategoryFilters = filters.filter(
    option => option.type === 'subcategory',
  );

  return {
    filter,
    filterDate,
    setFilterDate,
    categoryFilters,
    subcategoryFilters,
    filters,
    setFilters,
    toggleFilter,
    toggleCategoryFilter,
    toggleSubcategoryFilter,
  };
};
