import React from 'react';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@material-ui/core';

export interface DialogProps
  extends Omit<MuiDialogProps, 'onClose' | 'onSubmit'> {
  onClose?: () => void;
}

export const Dialog: React.FC<DialogProps> = ({ ...other }) => {
  return <MuiDialog {...other} />;
};
