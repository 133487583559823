import InlinePlaceholder from './InlinePlaceholder';
import BlockPlaceholder from './BlockPlaceholder';
import VoidBlock from './VoidBlock';
import { Button as UiButton } from '@21st-night/ui';

export * from './EditorToolbarDropdown';
export * from './EditorToolbarButton';

export * from './RichText';
export * from './RichTextLeaf';

export { default as InlinePlaceholder } from './InlinePlaceholder';
export * from './InlinePlaceholder';

export { default as BlockPlaceholder } from './BlockPlaceholder';
export * from './BlockPlaceholder';

export { default as VoidBlock } from './VoidBlock';
export * from './VoidBlock';

export const Button = UiButton;

const components = {
  InlinePlaceholder,
  BlockPlaceholder,
  VoidBlock,
  Button,
};

export default components;
