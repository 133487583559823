import React from 'react';
import {
  ButtonBase as MuiButtonBase,
  ButtonBaseProps as MuiButtonBaseProps,
} from '@material-ui/core';

export type ButtonBaseProps = MuiButtonBaseProps;

export const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({ ...other }, ref) => {
    return <MuiButtonBase {...other} ref={ref} />;
  },
);
