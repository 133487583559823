import React from 'react';
import { OnboardingType, OnboardingStep } from '@21st-night/onboarding';
import { InfoTextTooltip, Link } from '@21st-night/ui';

const examDecks: OnboardingType[] = ['GMAT', 'GRE', 'LSAT', 'MCAT', 'ARE'];

export function typeToLabel(
  type: OnboardingType,
  prefix = '',
  suffix = '',
  defaultValue = '',
): string {
  let label = defaultValue;

  if (examDecks.includes(type)) {
    label = `${prefix}${type}${suffix}`;
  }

  return label;
}

export function step1(type: OnboardingType): OnboardingStep {
  return {
    id: 'check-deck',
    title: `Check your${typeToLabel(type, ' ')} deck`,
  };
}

export function step2(): OnboardingStep {
  return {
    id: 'create-note',
    title: 'Create a note',
    subtitle: (
      <span>
        <strong>Tip</strong>: You’re not limited to just boring text notes. Try
        inserting a picture from your phone, or putting a pdf and text
        side-by-side!
      </span>
    ),
  };
}

export function step3(type: OnboardingType): OnboardingStep {
  let link = '';

  switch (type) {
    case 'GMAT':
      link =
        'https://get21stnight.com/why-you-should-use-21st-night-to-study-for-the-gmat/';
      break;
    case 'LSAT':
      link =
        'https://get21stnight.com/why-to-use-21st-night-to-study-for-the-lsat/';
      break;
    case 'GRE':
      link =
        'https://get21stnight.com/why-to-use-21st-night-to-study-for-the-gre/';
      break;
    case 'TUTOR':
      link =
        'https://get21stnight.com/why-to-use-21st-night-to-help-your-tutoring-clients/';
      break;
    default:
      link = 'https://get21stnight.com/how-to-learn-processes/';
  }
  let title = (
    <span>
      Create an{' '}
      <InfoTextTooltip
        tooltipContent={
          <div>
            <p>
              An error log is the best way to master and remember the skills
              you’ll need for {typeToLabel(type, 'the ', '', 'your studying')}.
            </p>
            <p>
              Create a card for each question you get wrong, along with an
              answer and a self-created explanation of the right way to solve
              it. The explanation is the most important step, as being able to
              explain a problem is the first step to mastering it Check out this
              link to learn more about{' '}
              <Link target="_blank" href={link}>
                how to create an error log
              </Link>
              .
            </p>
          </div>
        }
      >
        error log
      </InfoTextTooltip>
    </span>
  );
  let subtitle = (
    <span>
      <strong>Tip</strong>: Don’t be shy: copy-paste an entire question you had
      trouble with into your cards and start working on an explanation! It’s the
      only way to master tough questions.
    </span>
  );

  if (['MCAT', 'ARE', 'FACT'].includes(type)) {
    title = (
      <span>
        Create a{' '}
        <InfoTextTooltip
          tooltipContent={
            <div>
              <p>
                Spaced repetition flashcards, like 21st Night, are the most
                effective method for memorization, bar none.
              </p>
              <p>
                Tips for creating a flashcard:
                <ol>
                  <li>Test one fact at a time, not a list.</li>
                  <li>Include context or a mnemonic in the explanation.</li>
                  <li>Categorize the cards.</li>
                </ol>
              </p>
            </div>
          }
        >
          flash card
        </InfoTextTooltip>
      </span>
    );
    subtitle = (
      <span>
        <strong>Idea</strong>: create a flashcard about the molecular formula
        for glucose, C<sub>6</sub>H<sub>12</sub>O<sub>6</sub>.
      </span>
    );
  }

  return {
    id: 'create-card',
    title,
    subtitle,
  };
}

export function step4(): OnboardingStep {
  return {
    id: 'study-deck',
    title: 'Study your deck',
    subtitle: (
      <span>
        <strong>Tip</strong>: Study your deck every day. Humans forget facts
        quickly. Just try remembering what you had for lunch last week!
      </span>
    ),
  };
}

export function tutorStep4(): OnboardingStep {
  return {
    id: 'invite-student',
    title: 'Invite your students',
    subtitle: (
      <span>
        Share your deck with a student (if you don&apos;t have one in mind, you
        can share it with our test account, <strong>student@example.com</strong>
        )
      </span>
    ),
  };
}

export function generateSteps(type: OnboardingType): OnboardingStep[] {
  const steps = [step1(type), step2(), step3(type)];

  if (type !== 'TUTOR') {
    steps.push(step4());
  } else {
    steps.push(tutorStep4());
  }

  return steps;
}
