import React, { useCallback, useState } from 'react';
import { RenderElementProps } from '@braindrop-editor/core';
import { makeStyles, cn } from '@21st-night/styles';
import { ExpandMore } from '@21st-night/icons';
import { isDocumentEmpty, EditorDocument } from '@21st-night/editor-web';
import { Typography, Collapse } from '@21st-night/ui';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

export interface ElementSummaryProps extends RenderElementProps {
  variant?: 'flashcard' | 'error-log';
}

const useStyles = makeStyles(theme => ({
  toggleButton: {},
  heading: {
    fontWeight: 'bold',
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
  },
  icon: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
    marginLeft: -4,
    transform: 'rotate(-90deg)',
  },
  iconExpanded: {
    transform: 'rotate(0deg)',
  },
  content: {
    minHeight: 100,
  },
  contentInner: {
    position: 'relative',
  },
  placeholder: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    color: '#BDBDBD',
    cursor: 'text',
    top: 5,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  editable: {
    position: 'relative',
    zIndex: 10,
  },
}));

export const ElementSummary: React.FC<ElementSummaryProps> = ({
  attributes,
  children,
  element,
  variant = 'error-log',
}) => {
  const classes = useStyles();
  const editor = useSlate();
  const hasContent = !isDocumentEmpty(element.children as EditorDocument, true);
  const [expanded, setExpanded] = useState(hasContent);

  const handleClickToggle = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const wasExpanded = expanded;
      setExpanded(!expanded);
      if (!wasExpanded) {
        Transforms.select(editor, ReactEditor.findPath(editor, element));
        Transforms.collapse(editor, { edge: 'end' });
        setTimeout(() => {
          ReactEditor.focus(editor);
        });
      }
    },
    [expanded],
  );

  return (
    <div {...attributes}>
      <Typography
        variant="subtitle1"
        role="button"
        style={{ color: expanded ? '#616161' : '#9E9E9E' }}
        className={classes.heading}
        tabIndex={0}
        contentEditable={false}
        onClick={handleClickToggle}
      >
        <ExpandMore
          className={cn(classes.icon, expanded && classes.iconExpanded)}
        />
        {variant === 'error-log'
          ? 'Key takeaways (for your cheat sheet)'
          : 'Mnemonic'}
      </Typography>
      <Collapse in={expanded}>
        <div className={classes.content}>
          <div className={classes.contentInner}>
            {!hasContent && variant === 'error-log' && (
              <div contentEditable={false} className={classes.placeholder}>
                What type of problem is this, and how should you solve similar
                problems in the future?
              </div>
            )}
            <div className={classes.editable}>{children}</div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
