export function secondsToHumanReadable(
  seconds: number,
  numbersOnly?: boolean,
): string {
  const remainderSeconds = seconds % 60;
  const minutes = (seconds - remainderSeconds) / 60;
  const remainderMinutes = minutes % 60;
  const hours = (minutes - remainderMinutes) / 60;

  const remainderMinutesString = `${
    hours > 0 && remainderMinutes < 10 ? '0' : ''
  }${remainderMinutes}`;
  const remainderSecondsString = `${
    remainderSeconds < 10 ? '0' : ''
  }${remainderSeconds}`;

  return !numbersOnly
    ? `${
        hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''} ` : ''
      }${`${remainderMinutesString} minute${
        remainderMinutes !== 1 ? 's' : ''
      }`}`
    : `${hours > 0 ? `${hours}:` : ''}${`${remainderMinutesString}`}${
        hours === 0 ? `:${remainderSecondsString}` : ''
      }`;
}
