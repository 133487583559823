import React from 'react';
import { makeStyles } from '@21st-night/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 8,
    marginLeft: -8,
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: 2,
    },
  },
}));

export const Toolbar: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
