import { BraindropEditor, Element } from '@braindrop-editor/core';
import { Editor } from 'slate';

export function isFocusElementOfType(
  editor: BraindropEditor,
  type: string,
): boolean {
  const [match] = Editor.nodes(editor, {
    match: n => Element.isElement(n) && n.type === type,
  });

  return !!match;
}
