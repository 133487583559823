/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { DB, Storage, Functions } from '@21st-night/utils-web';
import {
  BraindropEditorPlugin,
  BraindropEditor,
  Transforms,
  isBlockAboveEmpty,
} from '@braindrop-editor/core';
import { addWebViewMessageListener } from '@21st-night/web-view';
import { ElementAudio } from './ElementAudio';
import {
  AudioElement,
  EditorWithAudioPlugin,
  TabsValue,
} from './AudioPlugin.types';
import { ElementAudioMobile, MobileAudioElement } from './ElementAudioMobile';
import { handleNativeEditorMessage } from './api';

export interface AudioPluginOptions {
  mobile?: false;
  functions: Functions;
  storage: Storage;
  db: DB;
}

export interface MobileAudioPluginOptions {
  mobile: true;
}

const AudioPlugin = (
  options: AudioPluginOptions | MobileAudioPluginOptions,
) => (
  baseEditor: BraindropEditor,
): BraindropEditorPlugin<AudioElement | MobileAudioElement> => {
  const editor = baseEditor as EditorWithAudioPlugin;
  const fullOptions = options as AudioPluginOptions;

  if (options.mobile) {
    addWebViewMessageListener(message =>
      handleNativeEditorMessage(editor, message),
    );
  }

  const insertAudio = (type?: TabsValue) => {
    Transforms.insertNodes(
      editor,
      editor.generateElement('audio', { initialTab: type }),
    );
  };

  const turnIntoAudio = (type?: TabsValue) => {
    Transforms.unsetNodes(editor, ['url']);
    Transforms.setNodes(editor, {
      type: 'audio',
      initialTab: type,
    } as AudioElement);
  };

  editor.insertAudio = insertAudio;
  editor.turnIntoAudio = turnIntoAudio;

  const { renderEditable } = editor;

  editor.renderEditable = props =>
    renderEditable({
      ...props,
      // Handle dropped audio files
      onDrop: event => {
        event.preventDefault();
        Array.from(event.dataTransfer.files).forEach(file => {
          if (file.type.startsWith('audio/')) {
            if (isBlockAboveEmpty(editor)) {
              Transforms.setNodes(editor, {
                uploadFile: file,
                type: 'audio',
              } as AudioElement);
            } else {
              Transforms.insertNodes(
                editor,
                editor.generateElement('audio', {
                  uploadFile: file,
                }),
              );
            }
          }
        });

        if (props.onDrop) {
          props.onDrop(event);
        }
      },
      // Handle pasted images
      onPaste: event => {
        Array.from(event.clipboardData.files).forEach(file => {
          if (file.type.startsWith('audio/')) {
            if (isBlockAboveEmpty(editor)) {
              Transforms.setNodes(editor, {
                uploadFile: file,
                type: 'audio',
              } as AudioElement);
            } else {
              Transforms.insertNodes(
                editor,
                editor.generateElement('audio', {
                  uploadFile: file,
                }),
              );
            }
          }
        });

        if (props.onPaste) {
          props.onPaste(event);
        }
      },
    });

  return {
    elementDeserializers: {
      AUDIO: el => {
        const audio = el as HTMLAudioElement;

        if (audio.src) {
          return { type: 'audio', uploadUrl: audio.src };
        }

        return undefined;
      },
    },
    elements: [
      {
        isVoid: true,
        component: props =>
          options.mobile ? (
            <ElementAudioMobile
              {...props}
              element={props.element as MobileAudioElement}
            />
          ) : (
            <ElementAudio
              {...props}
              element={props.element as AudioElement}
              storage={fullOptions.storage}
              db={fullOptions.db}
              functions={fullOptions.functions}
            />
          ),
        type: 'audio',
        hotkeys: ['mod+alt+8'],
      },
    ],
  };
};

export default AudioPlugin;
