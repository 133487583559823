import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { InputBase, Button } from '@21st-night/ui';
import { RenderElementProps } from '@braindrop-editor/core';
import { ReactEditor, useSlate } from 'slate-react';
import { Range, Transforms } from 'slate';
import { useMobileEditorToolbar } from '@21st-night/editor-web';
import { ClozeDeletionElement } from '../ClozeDeletionPlugin.types';

type ElementClozeDeletionMobileProps = RenderElementProps<ClozeDeletionElement>;

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    padding: 2,
    backgroundColor: '#80D8FF',
    borderRadius: 2,
  },
  hintInput: {
    padding: theme.spacing(2),
    flex: 1,
  },
  hintContainer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 42,
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ElementClozeDeletionMobile: React.FC<ElementClozeDeletionMobileProps> = ({
  children,
  element,
  attributes,
}) => {
  const classes = useStyles();
  const editor = useSlate();
  const editorToolbar = useMobileEditorToolbar();
  const span = useRef<HTMLSpanElement>(null);
  const [hintOpen, setHintOpen] = useState(false);

  function openHint() {
    setHintOpen(true);
  }

  function closeHint() {
    setHintOpen(false);
  }

  useEffect(() => {
    if (element.showHintField) {
      Transforms.unsetNodes(editor, ['showHintField'], {
        at: ReactEditor.findPath(editor, element),
      });
      openHint();
    }
  }, [element.showHintField]);

  useEffect(() => {
    if (element.inserted) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.unsetNodes(editor, ['inserted'], {
        at: path,
      });
      Transforms.setSelection(editor, {
        anchor: { path: [...path, 0], offset: 1 },
        focus: { path: [...path, 0], offset: 1 },
      });
    }
  }, [element.inserted]);

  useEffect(() => {
    const path = ReactEditor.findPath(editor, element);
    if (
      editor.selection &&
      Range.includes(editor.selection, path) &&
      Range.isCollapsed(editor.selection)
    ) {
      openHint();
    } else if (editor.selection && !Range.includes(editor.selection, path)) {
      closeHint();
    }
  }, [editor.selection]);

  function handleHintChange(event: React.ChangeEvent<HTMLInputElement>) {
    Transforms.setNodes(
      editor,
      { hint: event.target.value } as Partial<ClozeDeletionElement>,
      { at: ReactEditor.findPath(editor, element) },
    );
  }

  function handleClickDone(event: React.MouseEvent) {
    event.preventDefault();
    closeHint();
  }

  function handleFocusHint() {
    setTimeout(() => {
      editorToolbar.showEditorToolbar();
    }, 50);
  }

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        onClick={openHint}
        className={classes.root}
        {...attributes}
      >
        <span ref={span}>{children}</span>
      </span>
      {hintOpen && (
        <div contentEditable={false} className={classes.hintContainer}>
          <InputBase
            type="text"
            className={classes.hintInput}
            placeholder="Cloze deletion hint (optional)"
            value={element.hint}
            onChange={handleHintChange}
            onFocus={handleFocusHint}
            onBlur={closeHint}
          />
          <div>
            <Button size="small" color="primary" onMouseDown={handleClickDone}>
              Done
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
