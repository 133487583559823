import { v4 as uuid } from 'uuid';
import { StudyPlanDay } from '../../types';
import { daysBetween } from '../daysBetween';

export function generateStudyPlanDays(
  startDate: Date,
  endDate: Date,
): StudyPlanDay[] {
  const days = daysBetween(startDate, endDate);

  return days.map(day => {
    const date = day;
    day.setHours(0, 0, 0, 0);

    return {
      date,
      id: uuid(),
      createdAt: new Date(),
      items: [],
    };
  });
}
