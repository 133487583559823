import React, { useRef } from 'react';
import { EditorDocument, EditorProps } from '@21st-night/editor-web';
import { CardCreationPluginProvider } from '../CardCreationPluginProvider';
import { EditorCardForm } from '../EditorCardForm';
import { CardFormData } from '../CardCreationPlugin.types';

interface CardCreationPluginEditableProps {
  EditorProps: Omit<EditorProps, 'value' | 'onChange'>;
  onSubmitCreateCard: (data: CardFormData, document: EditorDocument) => void;
}

export const CardCreationPluginEditable: React.FC<CardCreationPluginEditableProps> = ({
  children,
  EditorProps,
  onSubmitCreateCard,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={editorRef}>
      <CardCreationPluginProvider
        editorRef={editorRef}
        onSubmitCreateCard={onSubmitCreateCard}
      >
        {children}
        <EditorCardForm EditorProps={EditorProps} />
      </CardCreationPluginProvider>
    </div>
  );
};
