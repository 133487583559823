import React from 'react';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import merge from 'lodash/merge';
import { Clear } from '@21st-night/icons';
import {
  Typography,
  TextField,
  TextFieldProps,
  Button,
  IconButton,
} from '@21st-night/ui';

export interface Subtask {
  /**
   * Properties applied to the subtask TextField components.
   */
  InputProps?: TextFieldProps;
  /**
   * Field id used as key property.
   */
  id: string;
}

export interface TaskFormTextFieldsProps {
  /**
   * Properties applied to the action TextField component.
   */
  ActionInputProps?: TextFieldProps;
  /**
   * Properties applied to the count TextField component.
   */
  CountInputProps?: TextFieldProps;
  /**
   * Properties applied to the object TextField component.
   */
  ObjectInputProps?: TextFieldProps;
  /**
   * Properties applied to the source TextField component.
   */
  SourceInputProps?: TextFieldProps;
  /**
   * Subtask fields.
   */
  subtasks: Subtask[];
  /**
   * Callback fired when the "Add a task" button is clicked.
   */
  onClickAddSubtask: () => void;
  /**
   * Callback fired when a remove subtask button is clicked.
   */
  onClickRemoveSubtask: (event: React.MouseEvent, index: number) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    fields: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      fontWeight: 'bold',
      '&::placeholder': {
        fontWeight: 'normal',
      },
    },
    actionField: {
      marginRight: theme.spacing(1),
    },
    countField: {
      marginRight: theme.spacing(1),
      width: 70,
    },
    objectField: {
      marginRight: theme.spacing(1),
    },
    sourceField: {
      marginLeft: theme.spacing(1),
    },
    subtask: {
      padding: theme.spacing(2, 0, 0, 2),
      display: 'flex',
      alignItems: 'center',
    },
    removeSubtaskButton: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1),
    },
    addSubtaskButton: {
      marginTop: theme.spacing(2),
    },
    addSubtaskButtonIndented: {
      marginLeft: theme.spacing(6),
    },
  }),
);

export const TaskFormTextFields: React.FC<TaskFormTextFieldsProps> = ({
  subtasks,
  onClickAddSubtask,
  onClickRemoveSubtask,
  ActionInputProps = {},
  CountInputProps = {},
  ObjectInputProps = {},
  SourceInputProps = {},
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.fields}>
        <TextField
          placeholder="read"
          name="action"
          className={classes.actionField}
          {...ActionInputProps}
          inputProps={merge(
            {
              className: classes.field,
              min: 1,
            },
            ActionInputProps.inputProps || {},
          )}
        />
        <TextField
          type="number"
          name="count"
          placeholder="20"
          className={classes.countField}
          {...CountInputProps}
          inputProps={merge(
            {
              className: classes.field,
            },
            CountInputProps.inputProps || {},
          )}
        />
        <TextField
          placeholder="chapters"
          name="object"
          className={classes.objectField}
          {...ObjectInputProps}
          inputProps={merge(
            {
              className: classes.field,
            },
            ObjectInputProps.inputProps || {},
          )}
        />
        <Typography color="textSecondary">from</Typography>
        <TextField
          placeholder="book (optional)"
          name="source"
          className={classes.sourceField}
          {...SourceInputProps}
          inputProps={merge(
            {
              className: classes.field,
            },
            SourceInputProps.inputProps || {},
          )}
        />
      </div>
      {subtasks.map((subtask, index) => (
        <div key={subtask.id} className={classes.subtask}>
          <IconButton
            size="small"
            color="inherit"
            className={classes.removeSubtaskButton}
            onClick={event => onClickRemoveSubtask(event, index)}
          >
            <Clear />
          </IconButton>
          <TextField
            fullWidth
            placeholder="Subtask"
            {...subtask.InputProps}
            inputProps={merge(
              {
                className: classes.field,
              },
              (subtask.InputProps || {}).inputProps || {},
            )}
          />
        </div>
      ))}
      <Button
        onClick={onClickAddSubtask}
        size="small"
        color="primary"
        className={cn(
          classes.addSubtaskButton,
          subtasks.length && classes.addSubtaskButtonIndented,
        )}
      >
        + Add a subtask
      </Button>
    </div>
  );
};
