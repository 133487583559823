import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { isEqual } from 'lodash';
import { cn, makeStyles } from '@21st-night/styles';
import { useFirebase } from '@21st-night/utils';
import {
  deserializeDocument,
  EditorDocument,
  serializeDocument,
} from '@21st-night/editor-web';
import {
  Button,
  IconButton,
  Paper,
  PdfViewer,
  LightboxImage,
  Spaced,
  DeleteButton,
} from '@21st-night/ui';
import {
  CardFormData,
  createCard,
  generateCard,
  removeNoteLink,
} from '@21st-night/cards-web';
import { NoteEditor, useNote } from '@21st-night/notes-web';
import { Add, Star } from '@21st-night/icons';
import { DeckContentNote, useDeck, generateDeckCard } from '@21st-night/deck';
import { DeckCategoryTag } from '../DeckCategoryTag';
import { DeckSubcategoryTag } from '../DeckSubcategoryTag';
import { DeckCategorySelect } from '../DeckCategorySelect';
import { DeckSubcategoriesSelect } from '../DeckSubcategoriesSelect';
import { DeckLinkedCardsTag } from '../DeckLinkedCardsTag';

export interface DeckItemNoteProps
  extends React.HTMLAttributes<HTMLDivElement> {
  note: DeckContentNote;
  autoFocus?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  label: {},
  content: {
    flex: 1,
  },
  metaToolbar: {
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
  menu: {
    minWidth: 170,
  },
}));

export const DeckItemNote: React.FC<DeckItemNoteProps> = ({
  note,
  className,
  autoFocus,
  ...other
}) => {
  const classes = useStyles();
  const { db, storage, functions, auth, imageUrl } = useFirebase();
  const deck = useDeck();
  const noteApi = useNote(note.id);
  const [editCategories, setEditCategories] = useState(false);
  // const [focused, setFocused] = useState(autoFocus);
  const [content, setContent] = useState<EditorDocument>(
    deserializeDocument(note.content),
  );
  const [debouncedContent] = useDebounce(content, 1000, { maxWait: 5000 });
  const [category, setCategory] = useState(note.category);
  const [subcategories, setSubcategories] = useState(note.subcategories);

  useEffect(() => {
    if (JSON.stringify(debouncedContent) !== note.content) {
      noteApi.updateContent(serializeDocument(debouncedContent));
    }
    // eslint-disable-next-line
  }, [debouncedContent]);

  function handleClickStar() {
    if (note.starred) {
      deck.unstarNote(note.id);
    } else {
      deck.starNote(note.id);
    }
  }

  function toggleEditCategories() {
    setEditCategories(value => !value);
  }

  function handleDelete() {
    deck.removeNoteFromDeck(note.id);
    noteApi.delete();
  }

  function handleSubmitCardForm(data: CardFormData, document: EditorDocument) {
    noteApi.updateContent(serializeDocument(document));
    const card = generateCard({
      ...data,
      deck: deck.id,
      author: auth.currentUser ? auth.currentUser.uid : '',
      note: note.id,
    });
    createCard(db, card);
    noteApi.addCardLink(note.id);
    deck.addCardToDeck(
      generateDeckCard(card, {
        subcategories: note.subcategories,
        category: note.category,
      }),
    );
  }

  function handleSaveCategories() {
    if (
      note.category !== category ||
      !isEqual(note.subcategories, subcategories)
    ) {
      deck.updateDeckNote(note.id, { category, subcategories });
    }
    toggleEditCategories();
  }

  function handleRemoveCardLink(noteId: string) {
    noteApi.removeCardLink(noteId);
    removeNoteLink(db, noteId);
  }

  return (
    <Paper className={cn(classes.root, className)} {...other}>
      {note.type === 'note' && (
        <NoteEditor
          db={db}
          functions={functions}
          storage={storage}
          autoFocus={autoFocus}
          imageUrl={imageUrl}
          value={content}
          maxHeight={500}
          onChange={setContent}
          onClickPreviewCard={() => null}
          onClickStudyCard={() => null}
          onRemoveCardLink={handleRemoveCardLink}
          onSubmitCardForm={handleSubmitCardForm}
        />
      )}
      {note.type === 'pdf' && note.file && <PdfViewer file={note.file} />}
      {note.type === 'image' && note.file && (
        <LightboxImage
          maxHeight={500}
          alt={note.title}
          src={note.file}
          expandedSrc={note.file}
        />
      )}
      <Spaced className={classes.metaToolbar}>
        {!editCategories && (
          <>
            <IconButton
              toggle
              size="small"
              activeColor="#FFAB00"
              onClick={handleClickStar}
              active={note.starred}
            >
              <Star />
            </IconButton>
            {note.category && <DeckCategoryTag category={note.category} />}
            {note.subcategories.map(subcategory => (
              <DeckSubcategoryTag key={subcategory} subcategory={subcategory} />
            ))}
            {note.cards.length > 0 && (
              <DeckLinkedCardsTag value={note.id} count={note.cards.length} />
            )}
            {!note.category && !note.subcategories.length && (
              <Button size="small" onClick={toggleEditCategories}>
                Add category
              </Button>
            )}
            {(note.category || note.subcategories.length > 0) && (
              <IconButton size="small" onClick={toggleEditCategories}>
                <Add />
              </IconButton>
            )}
            <div style={{ flex: 1 }} />
            <DeleteButton onConfirm={handleDelete} />
          </>
        )}
        {editCategories && (
          <>
            <DeckCategorySelect value={category} onChange={setCategory} />
            <DeckSubcategoriesSelect
              value={subcategories}
              onChange={value => setSubcategories(value)}
            />
            <Button size="small" color="primary" onClick={handleSaveCategories}>
              Save
            </Button>
          </>
        )}
      </Spaced>
    </Paper>
  );
};
