import { useFirebase } from '@21st-night/utils';
import { useCallback } from 'react';
import { updateCard, deleteCard, removeNoteLink } from '../../api';
import { CardUpdateData } from '../../types';

interface CardHook {
  addNoteLink: (noteId: string) => Promise<void>;
  delete: () => Promise<void>;
  removeNoteLink: (noteId: string) => Promise<void>;
  update: (data: CardUpdateData) => Promise<void>;
}

export const useCard = (cardId: string): CardHook => {
  const { db } = useFirebase();

  const update = useCallback(
    (data: CardUpdateData) => {
      return updateCard(db, cardId, data);
    },
    [db, cardId],
  );

  const addNoteLink = useCallback(
    (noteId: string) => {
      return updateCard(db, cardId, {
        note: noteId,
      });
    },
    [db, cardId],
  );

  const delCard = useCallback(() => {
    return deleteCard(db, cardId);
  }, [db, cardId]);

  return {
    addNoteLink,
    delete: delCard,
    removeNoteLink: () => removeNoteLink(db, cardId),
    update,
  };
};
