import { DB } from '@21st-night/utils';
import { Range } from 'slate';
import {
  addDocumentMetadata,
  deserializeDocument,
  serializeDocument,
  stripDocumentMetadata,
} from '@21st-night/editor';
import {
  addCardLinkToDocument,
  getSelectionCardLinks,
  replaceCardLinksInDocument,
} from '@21st-night/cards';
import { Note } from '../../Notes.types';
import { updateNote } from '../updateNote';

export function addContentCardLink(
  db: DB,
  note: Note,
  at: Range,
  cardId: string,
): Promise<void> {
  let cards = note.cards;
  const [doc, metadata] = stripDocumentMetadata(
    deserializeDocument(note.content),
  );
  let document = doc;
  const existingLinks = getSelectionCardLinks(document, at);

  if (existingLinks.length) {
    existingLinks.forEach(([node]) => {
      cards = cards.filter(cId => cId !== node.cardId);
    });

    document = replaceCardLinksInDocument(document, at, cardId);
  }

  const content = addCardLinkToDocument(document, at, cardId);

  cards.push(cardId);

  return updateNote(db, note.id, {
    content: serializeDocument(addDocumentMetadata(content, metadata)),
    cards,
  });
}
