import { DocumentReference } from '@21st-night/utils';
import { DeckCardsUpdateData } from '../../Deck.types';
import { serializeDeckCardsUpdateData } from '../serializeDeckCardsUpdateData';

export function updateDeckCards(
  deckRef: DocumentReference,
  data: DeckCardsUpdateData,
): Promise<void> {
  const serializedData = serializeDeckCardsUpdateData(data);

  return deckRef.update(serializedData);
}
