import { DB, DocumentReference } from '@21st-night/utils';
import { StudyPlanItem } from '../../types';

export function createStudyPlanItem(
  db: DB,
  planRef: DocumentReference,
  item: StudyPlanItem,
): Promise<void[]> {
  return Promise.all([
    planRef.collection('items').doc(item.id).set(item),
    planRef
      .collection('days')
      .doc(item.parent)
      .update({ items: db.arrayUnion(item.id) }),
  ]);
}
