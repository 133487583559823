import {
  AudioElement,
  BlockQuoteElement,
  CardLinkElement,
  ClozeDeletionElement,
  CodeElement,
  EditorBlockElementType,
  EditorInlineElementType,
  EquationBlockElement,
  EquationElement,
  HeadingOneElement,
  HeadingTwoElement,
  ImageElement,
  LinkElement,
  MetadataElement,
  OrderedListElement,
  ParagraphElement,
  UnorderedListElement,
  VideoElement,
  Element,
} from '../../Editor.types';
import { Node, Element as SlateElement } from 'slate';

export function isElementOfType(node: Node, type: 'link'): node is LinkElement;
export function isElementOfType(
  node: Node,
  type: 'equation-inline',
): node is EquationElement;
export function isElementOfType(
  node: Node,
  type: 'cloze-deletion',
): node is ClozeDeletionElement;
export function isElementOfType(
  node: Node,
  type: 'card-link',
): node is CardLinkElement;
export function isElementOfType(
  node: Node,
  type: 'metadata',
): node is MetadataElement;
export function isElementOfType(
  node: Node,
  type: 'paragraph',
): node is ParagraphElement;
export function isElementOfType(
  node: Node,
  type: 'h1',
): node is HeadingOneElement;
export function isElementOfType(
  node: Node,
  type: 'h2',
): node is HeadingTwoElement;
export function isElementOfType(
  node: Node,
  type: 'blockquote',
): node is BlockQuoteElement;
export function isElementOfType(
  node: Node,
  type: 'blockquote',
): node is BlockQuoteElement;
export function isElementOfType(
  node: Node,
  type: 'tex',
): node is EquationBlockElement;
export function isElementOfType(
  node: Node,
  type: 'image',
): node is ImageElement;
export function isElementOfType(
  node: Node,
  type: 'video',
): node is VideoElement;
export function isElementOfType(
  node: Node,
  type: 'audio',
): node is AudioElement;
export function isElementOfType(
  node: Node,
  type: 'ol',
): node is OrderedListElement;
export function isElementOfType(
  node: Node,
  type: 'ul',
): node is UnorderedListElement;
export function isElementOfType(node: Node, type: 'code'): node is CodeElement;
export function isElementOfType(
  node: Node,
  type: EditorInlineElementType | EditorBlockElementType,
): node is
  | LinkElement
  | EquationElement
  | ClozeDeletionElement
  | CardLinkElement
  | MetadataElement
  | ParagraphElement
  | HeadingOneElement
  | HeadingTwoElement
  | BlockQuoteElement
  | EquationBlockElement
  | ImageElement
  | VideoElement
  | AudioElement
  | CodeElement
  | OrderedListElement
  | UnorderedListElement {
  return SlateElement.isElement(node) && (node as Element).type === type;
}
