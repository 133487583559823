import React from 'react';
import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@material-ui/core';

export type DividerProps = MuiDividerProps;

export const Divider: React.FC<DividerProps> = ({ ...other }) => {
  return <MuiDivider {...other} />;
};
