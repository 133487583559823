import { DeckCard, DeckItem, DeckNote } from '../../Deck.types';

export function filterBySubcategory<T extends DeckItem | DeckCard | DeckNote>(
  items: T[],
  filterSubategories: string[],
): T[] {
  return items.filter(
    item =>
      filterSubategories.filter(subcategory =>
        item.subcategories.includes(subcategory),
      ).length > 0,
  );
}
