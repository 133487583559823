import { toDate, DocumentSnapshot } from '@21st-night/utils';
import { StudyPlanItem, StudyPlanItemDocument } from '../../types';

export function deserializeStudyPlanItemDocument(
  doc: DocumentSnapshot,
): StudyPlanItem {
  const data = doc.data() as StudyPlanItemDocument;

  return {
    ...data,
    createdAt: toDate(data.createdAt),
    date: toDate(data.date),
    donePreviously: !!data.donePreviously,
  };
}
