import React from 'react';
import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps as MuiFormHelperTextProps,
} from '@material-ui/core';

export type FormHelperTextProps = MuiFormHelperTextProps;

export const FormHelperText: React.FC<FormHelperTextProps> = ({ ...other }) => {
  return <MuiFormHelperText {...other} />;
};
