import { DB } from '@21st-night/utils';

export function completeStep(
  db: DB,
  userId: string,
  step: string,
): Promise<void> {
  return db
    .collection('users')
    .doc(userId)
    .update({
      'onboarding.completedSteps': db.arrayUnion(step),
    });
}
