import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@material-ui/core';
import { Clear } from '@21st-night/icons';

export type ChipProps = MuiChipProps;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 2,
      border: '1px solid rgb(232, 232, 232)',
      background: 'rgb(250, 250, 250)',
    },
    label: {
      // fontWeight: 500,
    },
    colorSecondary: {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    colorPrimary: {
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  }),
);

export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  ({ ...other }, ref) => {
    const classes = useStyles();
    return (
      <MuiChip
        ref={ref}
        classes={classes}
        deleteIcon={<Clear style={{ width: 16, height: 16 }} />}
        {...other}
      />
    );
  },
);
