import { ReviewRating } from '../../Review.types';
import { addDays } from '@21st-night/utils';

/**
 * Calculates the next review date of a card.
 *
 * @param {string} rating         The rating of the latest attempt.
 * @param {string} previousRating The rating of the previous attempt.
 */
export function calculateNextReviewDate(
  rating: ReviewRating,
  previousRating?: ReviewRating,
): Date {
  // Response was correct
  if (rating === 'correct') {
    // Previous response was not correct
    // Study again in 3 days
    if (previousRating !== 'correct') {
      return addDays(new Date(), 3);
    }

    // Previous answer was also correct
    // Study again in a week
    return addDays(new Date(), 7);
  }

  // If the response is not correct the card
  // must be studied again tomorrow
  return addDays(new Date(), 1);
}
