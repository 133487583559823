import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@material-ui/core';

export interface CircularProgressProps extends MuiCircularProgressProps {
  contrast?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
    },
    backgroundRoot: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    backgroundCircle: {
      color: theme.palette.grey[200],
    },
    backgroundCircleDark: {
      color: theme.palette.grey[300],
    },
    ring: {
      position: 'absolute',
      strokeLinecap: 'round',
      top: 0,
      left: 0,
    },
  }),
);

export const CircularProgress: React.FC<CircularProgressProps> = ({
  className,
  contrast,
  size = 40,
  thickness = 5,
  variant = 'static',
  ...other
}) => {
  const classes = useStyles();
  const showBackground = ['static', 'determinate'].includes(variant);

  return (
    <div
      className={classes.root}
      style={{
        height: size,
        width: size,
      }}
    >
      {showBackground && (
        <MuiCircularProgress
          {...other}
          classes={{
            root: classes.backgroundRoot,
            circle: contrast
              ? classes.backgroundCircleDark
              : classes.backgroundCircle,
          }}
          size={size}
          thickness={thickness}
          variant={variant}
          value={100}
        />
      )}
      <MuiCircularProgress
        {...other}
        className={clsx(classes.ring, className)}
        size={size}
        thickness={thickness}
        variant={variant}
      />
    </div>
  );
};
