import React from 'react';
import { Link } from '@21st-night/icons';
import {
  EditorToolbarButton as Button,
  EditorToolbarButtonProps,
} from '../components/EditorToolbarButton';
import { EditorWithLinkPlugin } from '../plugins/plugin-link';
import { isFocusElementOfType } from '../api';
import { CoreEditor } from '../core-editor-plugins';

export interface ToolbarInsertLinkButtonProps
  extends Partial<EditorToolbarButtonProps> {
  editor: CoreEditor & EditorWithLinkPlugin;
}

export const ToolbarInsertLinkButton: React.FC<ToolbarInsertLinkButtonProps> = ({
  editor,
  ...other
}) => {
  return (
    <Button
      tooltip="Insert link"
      shortcut="ctrl+K"
      active={isFocusElementOfType(editor, 'link')}
      onMouseDown={(event): void => {
        event.preventDefault();
        editor.openLinkPopover();
      }}
      {...other}
    >
      <Link />
    </Button>
  );
};
