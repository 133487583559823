import { DB, DocumentReference } from '@21st-night/utils';

export function removeCardFromDeck(
  db: DB,
  deckRef: DocumentReference,
  cardId: string,
): Promise<void> {
  return deckRef.update({
    [`cards.${cardId}`]: db.delete(),
  });
}
