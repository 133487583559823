import React from 'react';
import { makeStyles } from '@21st-night/styles';
import katex from 'katex';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@21st-night/ui';

const useStyles = makeStyles(theme => ({
  table: {
    borderSpacing: 0,
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(4),
  },
  tbody: {
    '& > :nth-child(2n)': {
      backgroundColor: '#f6f8fa',
    },
  },
  tr: {
    borderTop: '1px solid #dfe2e5',
  },
  td: {
    margin: 0,
    border: '1px solid #dfe2e5',
    padding: '6px 13px',
  },
  tex: {
    marginRight: 8,
  },
  code: {
    backgroundColor: 'rgba(27,31,35,.05)',
    borderRadius: 3,
    padding: '3px 6px',
  },
}));

export interface EquationInputProps {
  open: boolean;
  onClose: () => void;
}

const Table: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <tbody className={classes.tbody}>{children}</tbody>
    </table>
  );
};

const Row: React.FC = ({ children }) => {
  const classes = useStyles();

  return <tr className={classes.tr}>{children}</tr>;
};

const Cell: React.FC = ({ children }) => {
  const classes = useStyles();

  return <td className={classes.td}>{children}</td>;
};

const Tex: React.FC<{ tex: string }> = ({ tex }) => {
  const classes = useStyles();
  const html = katex.renderToString(tex, { throwOnError: false });

  return (
    <span className={classes.tex} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

const Code: React.FC = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.code}>{children}</span>;
};

export const EquationInputHelp: React.FC<EquationInputProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent>
        <Typography gutterBottom variant="h5">
          Supported Functions
        </Typography>
        <Typography paragraph>
          This is a list of TeX functions supported by the equation editor. It
          is sorted into logical groups.
        </Typography>
        <Typography paragraph>
          To use a function, type the <Code>\highlighted</Code> text into the
          equation editor followed by the Enter key.
        </Typography>

        <Typography gutterBottom variant="h6">
          Symbols
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\mathbb{N}" />
              <Code>\N</Code>
            </Cell>
            <Cell>
              <Tex tex="\mathbb{Z}" />
              <Code>\Z</Code>
            </Cell>
            <Cell>
              <Tex tex="\mathbb{Q}" />
              <Code>\Q</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\mathbb{P}" />
              <Code>\P</Code>
            </Cell>
            <Cell>
              <Tex tex="\mathbb{C}" />
              <Code>\C</Code>
            </Cell>
            <Cell>
              <Tex tex="\mathbb{H}" />
              <Code>\H</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Relations
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\ne" />
              <Code>\ne</Code>
            </Cell>
            <Cell>
              <Tex tex="\ge" />
              <Code>\ge</Code>
            </Cell>
            <Cell>
              <Tex tex="\le" />
              <Code>\le</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\approx" />
              <Code>\approx</Code>
            </Cell>
            <Cell>
              <Tex tex="\gg" />
              <Code>\gg</Code>
            </Cell>
            <Cell>
              <Tex tex="\ll" />
              <Code>\ll</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\doteq" />
              <Code>\doteq</Code>
            </Cell>
            <Cell>
              <Tex tex="\nless" />
              <Code>\nless</Code>
            </Cell>
            <Cell>
              <Tex tex="\ngtr" />
              <Code>\ngtr</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Operators
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\cdot" />
              <Code>\cdot</Code>
            </Cell>
            <Cell>
              <Tex tex="\times" />
              <Code>\times</Code>
            </Cell>
            <Cell>
              <Tex tex="\mp" />
              <Code>\mp</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\div" />
              <Code>\div</Code>
            </Cell>
            <Cell>
              <Tex tex="\frac{x}{y}" />
              <Code>\frac</Code>
            </Cell>
            <Cell>
              <Tex tex="\sim" />
              <Code>\sim</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\sqrt{x}" />
              <Code>\sqrt</Code>
            </Cell>
            <Cell>
              <Tex tex="\sqrt[3]{x}" />
              <Code>\nthroot</Code>
            </Cell>
            <Cell>
              <Tex tex="\displaystyle\sum_{i=1}^n" />
              <Code>\sum</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\displaystyle\int_{a}^b" />
              <Code>\int</Code>
            </Cell>
            <Cell>
              <Tex tex="\displaystyle\prod_{i=1}^n" />
              <Code>\prod</Code>
            </Cell>
            <Cell>
              <Tex tex="\binom{n}{k}" />
              <Code>\binom</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Greek Letters
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\Gamma" />
              <Code>\Gamma</Code>
            </Cell>
            <Cell>
              <Tex tex="\Delta" />
              <Code>\Delta</Code>
            </Cell>
            <Cell>
              <Tex tex="\Theta" />
              <Code>\Theta</Code>
            </Cell>
            <Cell>
              <Tex tex="\Lambda" />
              <Code>\Lambda</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\Xi" />
              <Code>\Xi</Code>
            </Cell>
            <Cell>
              <Tex tex="\Pi" />
              <Code>\Pi</Code>
            </Cell>
            <Cell>
              <Tex tex="\Sigma" />
              <Code>\Sigma</Code>
            </Cell>
            <Cell>
              <Tex tex="\Phi" />
              <Code>\Phi</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\Psi" />
              <Code>\Psi</Code>
            </Cell>
            <Cell>
              <Tex tex="\alpha" />
              <Code>\alpha</Code>
            </Cell>
            <Cell>
              <Tex tex="\beta" />
              <Code>\beta</Code>
            </Cell>
            <Cell>
              <Tex tex="\gamma" />
              <Code>\gamma</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\delta" />
              <Code>\delta</Code>
            </Cell>
            <Cell>
              <Tex tex="\zeta" />
              <Code>\zeta</Code>
            </Cell>
            <Cell>
              <Tex tex="\eta" />
              <Code>\eta</Code>
            </Cell>
            <Cell>
              <Tex tex="\theta" />
              <Code>\theta</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\iota" />
              <Code>\iota</Code>
            </Cell>
            <Cell>
              <Tex tex="\kappa" />
              <Code>\kappa</Code>
            </Cell>
            <Cell>
              <Tex tex="\mu" />
              <Code>\mu</Code>
            </Cell>
            <Cell>
              <Tex tex="\nu" />
              <Code>\nu</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\xi" />
              <Code>\xi</Code>
            </Cell>
            <Cell>
              <Tex tex="\rho" />
              <Code>\rho</Code>
            </Cell>
            <Cell>
              <Tex tex="\sigma" />
              <Code>\sigma</Code>
            </Cell>
            <Cell>
              <Tex tex="\tau" />
              <Code>\tau</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\chi" />
              <Code>\chi</Code>
            </Cell>
            <Cell>
              <Tex tex="\psi" />
              <Code>\psi</Code>
            </Cell>
            <Cell>
              <Tex tex="\pi" />
              <Code>\pi</Code>
            </Cell>
            <Cell>
              <Tex tex="\omega" />
              <Code>\omega</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\phi" />
              <Code>\phi</Code>
            </Cell>
            <Cell>
              <Tex tex="\epsilon" />
              <Code>\epsilon</Code>
            </Cell>
            <Cell>
              <Tex tex="\lambda" />
              <Code>\lambda</Code>
            </Cell>
            <Cell>
              <Tex tex="\digamma" />
              <Code>\digamma</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\varrho" />
              <Code>\varrho</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Accents
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\overline{AB}" />
              <Code>\overline</Code>
            </Cell>
            <Cell>
              <Tex tex="\underline{AB}" />
              <Code>\underline</Code>
            </Cell>
            <Cell>
              <Tex tex="\bar{y}" />
              <Code>\bar</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\overleftarrow{AB}" />
              <Code>\overleftarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\overrightarrow{AB}" />
              <Code>\overrightarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\vec{F}" />
              <Code>\vec</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Logic and Set Theory
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\forall" />
              <Code>\forall</Code>
            </Cell>
            <Cell>
              <Tex tex="\therefore" />
              <Code>\therefore</Code>
            </Cell>
            <Cell>
              <Tex tex="\because" />
              <Code>\because</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\exists" />
              <Code>\exists</Code>
            </Cell>
            <Cell>
              <Tex tex="\nexists" />
              <Code>\nexists</Code>
            </Cell>
            <Cell>
              <Tex tex="\empty" />
              <Code>\empty</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\mapsto" />
              <Code>\mapsto</Code>
            </Cell>
            <Cell>
              <Tex tex="\gets" />
              <Code>\gets</Code>
            </Cell>
            <Cell>
              <Tex tex="\to" />
              <Code>\to</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\subset" />
              <Code>\subset</Code>
            </Cell>
            <Cell>
              <Tex tex="\supset" />
              <Code>\supset</Code>
            </Cell>
            <Cell>
              <Tex tex="\cup" />
              <Code>\cup</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\in" />
              <Code>\in</Code>
            </Cell>
            <Cell>
              <Tex tex="\notin" />
              <Code>\notin</Code>
            </Cell>
            <Cell>
              <Tex tex="\cap" />
              <Code>\cap</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\ni" />
              <Code>\ni</Code>
            </Cell>
            <Cell>
              <Tex tex="\notni" />
              <Code>\notni</Code>
            </Cell>
            <Cell>
              <Tex tex="\sube" />
              <Code>\sube</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\land" />
              <Code>\land</Code>
            </Cell>
            <Cell>
              <Tex tex="\lor" />
              <Code>\lor</Code>
            </Cell>
            <Cell>
              <Tex tex="\supe" />
              <Code>\supe</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\implies" />
              <Code>\implies</Code>
            </Cell>
            <Cell>
              <Tex tex="\impliedby" />
              <Code>\impliedby</Code>
            </Cell>
            <Cell>
              <Tex tex="\iff" />
              <Code>\iff</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\mid" />
              <Code>\mid</Code>
            </Cell>
            <Cell>
              <Tex tex="⊈" />
              <Code>\nsube</Code>
            </Cell>
            <Cell>
              <Tex tex="⊉" />
              <Code>\nsupe</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Delimiters
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\lbrace" />
              <Code>\lbrace</Code>
            </Cell>
            <Cell>
              <Tex tex="\rbrace" />
              <Code>\rbrace</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\lceil" />
              <Code>\lceil</Code>
            </Cell>
            <Cell>
              <Tex tex="\rceil" />
              <Code>\rceil</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\lfloor" />
              <Code>\lfloor</Code>
            </Cell>
            <Cell>
              <Tex tex="\rfloor" />
              <Code>\rfloor</Code>
            </Cell>
          </Row>
        </Table>

        <Typography gutterBottom variant="h6">
          Arrows
        </Typography>
        <Table>
          <Row>
            <Cell>
              <Tex tex="\uparrow" />
              <Code>\uparrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\downarrow" />
              <Code>\downarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\updownarrow" />
              <Code>\updownarrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\Uparrow" />
              <Code>\Uparrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\Downarrow" />
              <Code>\Downarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\Updownarrow" />
              <Code>\Updownarrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\leftarrow" />
              <Code>\leftarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\rightarrow" />
              <Code>\rightarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\leftrightarrow" />
              <Code>\leftrightarrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\Leftarrow" />
              <Code>\Leftarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\Rightarrow" />
              <Code>\Rightarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\Leftrightarrow" />
              <Code>\Leftrightarrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\mapsto" />
              <Code>\mapsto</Code>
            </Cell>
            <Cell>
              <Tex tex="\nearrow" />
              <Code>\nearrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\searrow" />
              <Code>\searrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\hookleftarrow" />
              <Code>\hookleftarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\hookrightarrow" />
              <Code>\hookrightarrow</Code>
            </Cell>
            <Cell>
              <Tex tex="\swarrow" />
              <Code>\swarrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\leftharpoonup" />
              <Code>\leftharpoonup</Code>
            </Cell>
            <Cell>
              <Tex tex="\rightharpoonup" />
              <Code>\rightharpoonup</Code>
            </Cell>
            <Cell>
              <Tex tex="\nwarrow" />
              <Code>\nwarrow</Code>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Tex tex="\leftharpoondown" />
              <Code>\leftharpoondown</Code>
            </Cell>
            <Cell>
              <Tex tex="\rightharpoondown" />
              <Code>\rightharpoondown</Code>
            </Cell>
          </Row>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
