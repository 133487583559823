import { Timestamp, FieldValue } from '@21st-night/utils';

// Review rating
export const REVIEW_RATING = [
  'wrong',
  'more-work',
  'correct',
  'retire',
] as const;
type ReviewRatingType<
  T extends ReadonlyArray<unknown>
> = T extends ReadonlyArray<infer ReviewRatingType> ? ReviewRatingType : never;
export type ReviewRating = ReviewRatingType<typeof REVIEW_RATING>;

// Card status
export const CARD_STATUS = [
  'new',
  'learning',
  'learned',
  'relearning',
  'retired',
] as const;
type CardStatusType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer CardStatusType
>
  ? CardStatusType
  : never;
export type CardStatus = CardStatusType<typeof CARD_STATUS>;

export type ReviewMode = 'standard' | 'answer-first' | 'quiz';

export type WrittenAnswerState = 'unchecked' | 'incorrect' | 'correct';

export type ReviewView =
  | 'question'
  | 'answer'
  | 'question-summary'
  | 'answer-explanation'
  | 'note';

export type ProficiencyKey =
  | 'needs-work'
  | 'in-progress'
  | 'mastered'
  | 'not-started';

export interface RatingEvent {
  createdAt: Date;
  rating: ReviewRating;
  interval: number;
  duration: number;
}

export interface CardReviewProgress {
  type: 'flashcard' | 'error-log';
  attempts: RatingEvent[];
  lastReview: Date | null;
  firstReview: Date | null;
  nextReview: Date;
  interval: number;
  retired?: boolean;
  proficiency: number;
  status: CardStatus;
}

export interface FlashcardReviewProgress extends CardReviewProgress {
  easeFactor: number;
  stepsIndex: number;
}

export interface ReviewSession {
  id: string;
  startedAt: Date;
  lastActivity: Date;
  completed: boolean;
  duration: number;
  cards: string[];
  cardAttempts: Record<string, RatingEvent[]>;
}

// DB document types

export interface SerializedRatingEvent extends Omit<RatingEvent, 'createdAt'> {
  createdAt: Timestamp;
}

export interface CardReviewProgressDocument
  extends Omit<
    CardReviewProgress,
    'attempts' | 'lastReview' | 'firstReview' | 'nextReview'
  > {
  type: 'flashcard' | 'error-log';
  attempts: SerializedRatingEvent[];
  lastReview: null | Timestamp;
  firstReview: null | Timestamp;
  nextReview: Timestamp;
  status: CardStatus;
  easeFactor?: number;
  stepsIndex?: number;
}

export interface ReviewSessionDocument
  extends Omit<ReviewSession, 'startedAt' | 'lastActivity' | 'cardAttempts'> {
  startedAt: Timestamp;
  lastActivity: Timestamp;
  attempts: SerializedRatingEvent[];
}

// Update data

export interface ReviewSessionUpdateData {
  lastActivity?: Date;
  cardAttempts?: Record<string, RatingEvent[] | FieldValue>;
  cards?: string[] | FieldValue;
  completed?: boolean;
  duration?: number | FieldValue;
}

// Legacy types

type SerializedCardReviewAttempt = {
  createdAt: Timestamp;
  rating: ReviewRating;
};

export interface LegacyCardReviewProgressDocument {
  attempts: SerializedCardReviewAttempt[];
  nextReview: Timestamp;
  proficiency: number;
  retired: boolean;
}
