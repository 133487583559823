import { ReviewRating, CardReviewProgress } from '../../Review.types';
import { calculateNewProficiencyScore } from '../calculateNewProficiencyScore';
import { calculateProficiencyPercentage } from '../calculateProficiencyPercentage';
import { calculateNextReviewDate } from '../calculateNextReviewDate';
import { generateCardReviewProgress } from '../generateCardReviewProgress';
import dayjs from 'dayjs';

export function rateErrorLog(
  currentProgress: CardReviewProgress | null,
  rating: ReviewRating,
  duration = 0,
): CardReviewProgress {
  // Override generated review progress data to support legacy progress data
  const current = generateCardReviewProgress(
    'error-log',
    currentProgress || { firstReview: new Date() },
  );
  // Get the ratings of previous attmepts
  const pastRatings = current.attempts.map(attempt => attempt.rating);
  // Get the rating of the attempt before this one
  const previousRating = pastRatings.slice(-1)[0] || null;
  // Calculate the new proficiency score
  const proficiency = calculateNewProficiencyScore(rating, pastRatings);
  // Calculate the new proficiency percentage
  const proficiencyPercentage = calculateProficiencyPercentage(proficiency);
  // Check if the card should be retired
  const shouldRetire = rating === 'retire' || proficiencyPercentage === 100;

  const nextReview = calculateNextReviewDate(rating, previousRating);

  if (!current.firstReview) {
    current.firstReview = new Date();
  }

  if (proficiency > 2) {
    current.status = 'learned';
  } else if (currentProgress && currentProgress.proficiency > 2) {
    current.status = 'relearning';
  } else {
    current.status = 'learning';
  }

  if (shouldRetire) {
    current.status = 'retired';
  }

  const progress = {
    ...current,
    proficiency,
    retired: shouldRetire,
    lastReview: new Date(),
    nextReview,
    attempts: [
      ...current.attempts,
      {
        createdAt: new Date(),
        rating,
        duration,
        interval: dayjs(nextReview).diff(new Date(), 'day'),
      },
    ],
  };

  return progress;
}
