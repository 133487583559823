/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  FirebaseFirestore,
  FieldValue as FirebaseFieldValue,
  Timestamp as FirebaseTimestamp,
  DocumentSnapshot as FirebaseDocumentSnapshot,
  QueryDocumentSnapshot as FirebaseQueryDocumentSnapshot,
  DocumentData as FirebaseDocumentData,
  DocumentReference as FirebaseDocumentReference,
  CollectionReference as FirebaseCollectionReference,
} from '@firebase/firestore-types/index.d';
import {
  FirebaseStorage,
  UploadTask as FBUploadTask,
} from '@firebase/storage-types/index.d';
import { FirebaseAuth, User } from '@firebase/auth-types/index.d';
import {
  FirebaseFunctions,
  HttpsCallableResult as FirebaseHttpsCallableResult,
} from '@firebase/functions-types/index.d';
import { createContext } from '../createContext';

export type DocumentSnapshot =
  | FirebaseDocumentSnapshot
  | FirebaseDocumentSnapshot<FirebaseDocumentData>;
export type QueryDocumentSnapshot =
  | FirebaseQueryDocumentSnapshot
  | FirebaseQueryDocumentSnapshot<FirebaseDocumentData>;
export type DocumentData = FirebaseDocumentData;
export type DocumentReference = FirebaseDocumentReference;
export type CollectionReference = FirebaseCollectionReference;
export type FieldValue = FirebaseFieldValue;
export type Timestamp = FirebaseTimestamp;
export type FirebaseUser = User;
export type HttpsCallableResult = FirebaseHttpsCallableResult;

type ArrayRemove = (...elements: any[]) => FieldValue;
type ArrayUnion = (...elements: any[]) => FieldValue;
type Increment = (n: number) => FieldValue;
type Delete = () => FieldValue;

export type Storage = FirebaseStorage;
export type UploadTask = FBUploadTask;
export type Functions = FirebaseFunctions;
export type Auth = FirebaseAuth;
export type Firestore = FirebaseFirestore;

export interface DB extends FirebaseFirestore {
  arrayRemove: ArrayRemove;
  arrayUnion: ArrayUnion;
  increment: Increment;
  delete: Delete;
}

export interface FirebaseProviderProps {
  db: DB;
  auth: FirebaseAuth;
  functions: FirebaseFunctions;
  storage: FirebaseStorage;
  imageUrl: string;
}

export interface FirebaseContext {
  db: DB;
  auth: FirebaseAuth;
  functions: FirebaseFunctions;
  storage: FirebaseStorage;
  imageUrl: string;
}

const [hook, Provider, Consumer] = createContext<FirebaseContext>();

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({
  children,
  ...other
}) => {
  return <Provider value={other}>{children}</Provider>;
};

export const useFirebase = hook;
export const FirebaseConsumer = Consumer;
