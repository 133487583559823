/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { cn, useTheme, makeStyles, createStyles } from '@21st-night/styles';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@21st-night/ui';

export interface EditorToolbarButtonProps extends IconButtonProps {
  tooltip?: TooltipProps['title'];
  tooltipPlacement?: 'top' | 'bottom';
  shortcut?: string;
  contrast?: boolean;
  touch?: boolean;
}

export function addModifierKeys(shortcut: string): string {
  const ctrlModifier =
    navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? '⌘' : 'Ctrl';
  const altModifier =
    navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? 'Option' : 'Alt';

  const modified = shortcut.replace('ctrl', ctrlModifier);
  return modified.replace('alt', altModifier);
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 3,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        '&$contrast': {
          backgroundColor: theme.palette.grey[800],
        },
        '&$touch': {
          backgroundColor: 'transparent',
        },
      },
    },
    active: {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
      '&:hover': {
        // @ts-ignore
        backgroundColor: theme.palette.primary[50],
      },
      '&:focus': {
        // @ts-ignore
        backgroundColor: theme.palette.primary[50],
      },
    },
    tooltip: {
      backgroundColor: '#0F0F0F',
      color: '#E7E7E7',
      fontSize: '12px',
      borderRadius: 3,
      boxShadow: theme.shadows[6],
    },
    shortcut: {
      color: '#82817F',
      marginTop: 4,
    },
    contrast: {
      color: '#FFF',
    },
    touch: {},
  }),
);

export const EditorToolbarButton: React.FC<EditorToolbarButtonProps> = ({
  active,
  className,
  children,
  disabled,
  tooltip: tooltipProp,
  tooltipPlacement = 'top',
  shortcut,
  contrast,
  touch = false,
  ...other
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const button = (
    <IconButton
      disableScaling
      disableRipple
      active={active}
      disabled={disabled}
      size="small"
      activeColor={theme.palette.primary.main}
      style={contrast ? { color: '#FFF' } : {}}
      className={cn(
        classes.root,
        active && classes.active,
        contrast && classes.contrast,
        touch && classes.touch,
        className,
      )}
      {...other}
    >
      {children}
    </IconButton>
  );

  if (!tooltipProp) {
    return button;
  }

  const tooltip = (
    <span>
      {tooltipProp}
      {shortcut ? (
        <span className={classes.shortcut}>
          <br />
          {addModifierKeys(shortcut)}
        </span>
      ) : (
        ''
      )}
    </span>
  );

  return touch || disabled ? (
    button
  ) : (
    <Tooltip
      placement={tooltipPlacement}
      enterDelay={400}
      title={tooltip}
      classes={{ tooltip: classes.tooltip }}
    >
      {button}
    </Tooltip>
  );
};
