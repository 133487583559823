import React from 'react';
import {
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
} from '@21st-night/icons';
import { EditorBlockElementType } from '@21st-night/editor';
import { EditorToolbarButton as Button } from '../components/EditorToolbarButton';
import { isFocusElementOfType } from '../api';
import { CoreEditor } from '../core-editor-plugins';

export interface ToolbarBlockFormattingButtonsProps {
  editor: CoreEditor;
  touch?: boolean;
}

export const ToolbarBlockFormattingButtons: React.FC<ToolbarBlockFormattingButtonsProps> = ({
  editor,
  touch,
}) => {
  function toggleElementType(type: EditorBlockElementType) {
    return (event: React.MouseEvent): void => {
      event.preventDefault();
      editor.toggleElementType(type);
    };
  }

  return (
    <>
      <Button
        tooltip="Heading 1"
        shortcut="ctrl+alt+1"
        touch={touch}
        active={isFocusElementOfType(editor, 'h1')}
        onMouseDown={toggleElementType('h1')}
      >
        <span style={{ fontSize: '16px' }}>H1</span>
      </Button>
      <Button
        tooltip="Heading 2"
        shortcut="ctrl+alt+2"
        touch={touch}
        active={isFocusElementOfType(editor, 'h2')}
        onMouseDown={toggleElementType('h2')}
      >
        <span style={{ fontSize: '16px' }}>H2</span>
      </Button>
      <Button
        tooltip="Block quote"
        shortcut="ctrl+alt+3"
        touch={touch}
        active={isFocusElementOfType(editor, 'blockquote')}
        onMouseDown={toggleElementType('blockquote')}
      >
        <FormatQuote />
      </Button>
      <Button
        shortcut="ctrl+alt+4"
        tooltip="Buletted list"
        touch={touch}
        active={isFocusElementOfType(editor, 'ul')}
        onMouseDown={toggleElementType('ul')}
      >
        <FormatListBulleted />
      </Button>
      <Button
        tooltip="Numbered list"
        shortcut="ctrl+alt+5"
        touch={touch}
        active={isFocusElementOfType(editor, 'ol')}
        onMouseDown={toggleElementType('ol')}
      >
        <FormatListNumbered />
      </Button>
    </>
  );
};
