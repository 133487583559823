import React, { useCallback, useEffect, useState } from 'react';
import { CollectionReference, createContext } from '@21st-night/utils';
import { StudyPlanTemplate, StudyPlanTemplateDocument } from '../../types';
import {
  createStudyPlanTemplate,
  deserializeStudyPlanTemplate,
  generateStudyPlanTemplate,
} from '../../api';

export interface StudyPlanTemplatesProviderProps {
  templatesCollectionRef: CollectionReference;
}

export type CreateStudyPlanTemplateFn = (
  data?: Partial<StudyPlanTemplate>,
) => Promise<StudyPlanTemplate>;

export interface StudyPlanTemplatesContext {
  templates: StudyPlanTemplate[];
  createTemplate: CreateStudyPlanTemplateFn;
}

const [hook, Provider] = createContext<StudyPlanTemplatesContext>();

export const StudyPlanTemplatesProvider: React.FC<StudyPlanTemplatesProviderProps> = ({
  templatesCollectionRef,
  children,
}) => {
  const [templates, setTemplates] = useState<StudyPlanTemplate[]>([]);

  useEffect(() => {
    let isMounted = false;

    const unsubscribe = templatesCollectionRef
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        if (isMounted && snapshot) {
          setTemplates(
            snapshot.docs.map(doc =>
              deserializeStudyPlanTemplate(
                doc.data() as StudyPlanTemplateDocument,
              ),
            ),
          );
        }
      });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [templatesCollectionRef]);

  const createTemplate: CreateStudyPlanTemplateFn = useCallback(
    async data => {
      const template = generateStudyPlanTemplate(data);
      const templateRef = templatesCollectionRef.doc(template.id);
      await createStudyPlanTemplate(templateRef, template);

      return template;
    },
    [templatesCollectionRef],
  );

  return <Provider value={{ templates, createTemplate }}>{children}</Provider>;
};

export const useStudyPlanTemplates = hook;
