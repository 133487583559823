import { RatingEvent } from '@21st-night/review';

export function calculateRetentionRate(ratings: RatingEvent[]): number {
  const total = ratings.reduce(
    (t, rating) => t + (rating.rating === 'wrong' ? 0 : 1),
    0,
  );

  return Math.round((total / (ratings.length || 1)) * 100);
}
