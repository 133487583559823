import { validateDoc, DocValidator } from '@21st-night/utils';
import { NOTE_TYPES } from '@21st-night/notes';
import { DeckNote, DeckNoteUpdateData } from '../../Deck.types';

export const NewDeckNoteValidator: DocValidator<DeckNote> = {
  type: [NOTE_TYPES],
  id: 'string',
  createdAt: 'date',
  category: ['string', 'null'],
  subcategories: 'string-array',
  starred: ['optional', 'boolean'],
};

export const DeckNoteUpdateValidator: DocValidator<DeckNoteUpdateData> = {
  category: ['optional', 'string', 'null'],
  subcategories: ['optional', 'string-array', 'arrayRemove', 'arrayUnion'],
  starred: ['optional', 'boolean'],
};

export function validateNewDeckNote(doc: DeckNote): boolean {
  return validateDoc(NewDeckNoteValidator, doc);
}

export function validateDeckNoteUpdate(doc: DeckNote): boolean {
  return validateDoc(DeckNoteUpdateValidator, doc);
}
