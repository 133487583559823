import { ReviewRating } from '../../Review.types';
import { calculateProficiencyScore } from '../calculateProficiencyScore';
import { RATING_VALUES, RECENT_ATTEMPTS_COUNT } from '../../constants';

/**
 * Calculates a new rating based on past ratings
 * and latest rating.
 *
 * @param {string} rating The latest rating.
 * @param {array} ratings The previous ratings.
 */
export function calculateNewProficiencyScore(
  rating: ReviewRating,
  pastRatings: ReviewRating[],
): number {
  // Get the current rating using a recent attempts
  // count that is 1 smaller than the number used
  // to calculate the current score (because we are
  // adding a new attempt to it).
  const currentScore = calculateProficiencyScore(
    pastRatings,
    RECENT_ATTEMPTS_COUNT - 1,
  );

  // Add the new score the the total of recent ones
  const newScore = currentScore + RATING_VALUES[rating];

  return newScore;
}
