import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@21st-night/ui';
import { RadioButtonUnchecked } from '@21st-night/icons';
import {
  StudyPlan,
  StudyPlanTask,
  StudyPlanItem,
  StudyPlanDay,
  generateStudyPlanItems,
  generateStudyPlanDays,
  addStudyPlanItemsToDays,
} from '@21st-night/study-plan';
import { RichText } from '@21st-night/editor-web';

export interface StudyPlanPreviewProps {
  tasks: StudyPlanTask[];
  plan: StudyPlan;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    startWeekSelectContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    startWeekSelect: {
      marginLeft: theme.spacing(1),
    },
    secondary: {
      color: theme.palette.text.secondary,
    },
    week: {
      maxWidth: 600,
      width: '100%',
      marginBottom: theme.spacing(8),
    },
    weekTitle: {
      textAlign: 'center',
    },
    weekTitleDate: {
      display: 'inline-block',
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(1),
    },
    day: {
      display: 'flex',
      transition: 'padding 200ms',
      padding: theme.spacing(1, 0),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },

    dayLabel: {
      fontWeight: 600,
      fontSize: '1.2em',
      color: theme.palette.grey[400],
      padding: theme.spacing(1.5, 2, 1, 0),
      textAlign: 'right',
      minWidth: 110,
    },
    dayLabelDate: {
      fontWeight: 400,
      fontSize: '1em',
    },
    dayContent: {
      flex: 1,
    },
    currentDay: {
      color: theme.palette.grey[700],
    },
  }),
);

export const StudyPlanPreview: React.FC<StudyPlanPreviewProps> = ({
  tasks,
  plan,
}) => {
  const classes = useStyles();

  let days = generateStudyPlanDays(new Date(), plan.endDate);

  const items: StudyPlanItem[] = tasks.reduce((itms: StudyPlanItem[], task) => {
    return [...itms, ...generateStudyPlanItems(task, days)];
  }, []);

  days = addStudyPlanItemsToDays(days, items);

  const weeks = days.reduce(
    (value: StudyPlanDay[][], day) => {
      if (day.date.getDay() === plan.startWeekOn) {
        return [...value, [day]];
      }

      const newValue = value;
      newValue[newValue.length - 1].push(day);
      return newValue;
    },
    [[]],
  );

  return (
    <div className={classes.root}>
      {weeks
        .filter(week => week.length > 0)
        .map((week, index) => (
          <div key={week[0].id} className={classes.week}>
            <Typography gutterBottom variant="h5" className={classes.weekTitle}>
              Week {index + 1}{' '}
              <span className={classes.weekTitleDate}>
                {dayjs(week[0].date).format('MMM D')} -{' '}
                {dayjs(week[week.length - 1].date).format('D')}
              </span>
            </Typography>
            {week.map((day, dayIndex) => (
              <div key={day.id} className={classes.day}>
                <div
                  className={cn(
                    classes.dayLabel,
                    index === 0 && dayIndex === 0 && classes.currentDay,
                  )}
                >
                  {dayjs(day.date).format('dddd')}
                  <br />
                  <span className={classes.dayLabelDate}>
                    {dayjs(day.date).format('MMM D')}
                  </span>
                </div>
                <div className={classes.dayContent}>
                  {items
                    .filter(item => item.parent === day.id)
                    .map(item => (
                      <Fragment key={item.id}>
                        <ListItem>
                          <ListItemIcon style={{ minWidth: 36 }}>
                            <RadioButtonUnchecked />
                          </ListItemIcon>
                          <ListItemText disableTypography>
                            {
                              <RichText
                                imageUrl={
                                  process.env.REACT_APP_IMGIX_URL as string
                                }
                                document={JSON.parse(item.title)}
                              />
                            }
                          </ListItemText>
                        </ListItem>
                        <div style={{ marginLeft: 40 }}>
                          {items
                            .filter(subitem =>
                              item.subItems.includes(subitem.id),
                            )
                            .map(subitem => (
                              <ListItem key={subitem.id}>
                                <ListItemIcon style={{ minWidth: 36 }}>
                                  <RadioButtonUnchecked />
                                </ListItemIcon>
                                <ListItemText disableTypography>
                                  {
                                    <RichText
                                      imageUrl={
                                        process.env
                                          .REACT_APP_IMGIX_URL as string
                                      }
                                      document={JSON.parse(subitem.title)}
                                    />
                                  }
                                </ListItemText>
                              </ListItem>
                            ))}
                        </div>
                      </Fragment>
                    ))}
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};
