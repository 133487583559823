import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  CategoryTagsButton,
  CategoryTagsButtonProps,
  SubcategoryTagsButtonProps,
} from '../CategoryTagsButton';
import { LabelledRow, LabelledRowProps } from '@21st-night/ui';

export type CategorySelectMobileProps = CategoryTagsButtonProps &
  Partial<LabelledRowProps>;
export type SubcategorySelectMobileProps = SubcategoryTagsButtonProps &
  Partial<LabelledRowProps>;

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      flex: 1,
    },
  }),
);

export const CategorySelectMobile: React.FC<
  CategorySelectMobileProps | SubcategorySelectMobileProps
> = ({ value, variant = 'category', onClick, label, ...other }) => {
  const classes = useStyles();

  return (
    <LabelledRow
      label={label || variant === 'category' ? 'Category' : 'Subcategories'}
      {...other}
    >
      {variant === 'category' && (
        <CategoryTagsButton
          className={classes.button}
          value={value as string}
          variant={variant}
          onClick={onClick}
          placeholder="Add a category"
        />
      )}
      {variant === 'subcategory' && (
        <CategoryTagsButton
          className={classes.button}
          value={value as string[]}
          variant="subcategory"
          onClick={onClick}
          placeholder="Add subcategories"
        />
      )}
    </LabelledRow>
  );
};
