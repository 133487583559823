import { DeckCard } from '@21st-night/deck';

export function groupCardsByCategory(
  cards: DeckCard[],
): Record<string, DeckCard[]> {
  const categories: Record<string, DeckCard[]> = {};

  cards.forEach(card => {
    const category = card.category || '__uncategorized__';
    if (!categories[category]) {
      categories[category] = [];
    }

    categories[category].push(card);
  });

  return categories;
}
