import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { motion, MotionProps, Variants } from 'framer-motion';
import { OnboardingQuestionText } from '../OnboardingQuestionText';
import {
  OnboardingQuestionDateSelection,
  OnboardingQuestionDateSelectionProps,
} from '../OnboardingQuestionDateSelection';
import { OnboardingQuestionSkipButton } from '../OnboardingQuestionSkipButton';

export interface OnboardingDateSelectionQuestionProps
  extends MotionProps,
    Pick<OnboardingQuestionDateSelectionProps, 'inputFormat' | 'maxDate'> {
  question: string;
  defaultValue?: Date;
  onSubmit: (value: Date) => void;
  onSkip?: () => void;
}

const inputsContainerVariants: Variants = {
  in: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  out: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const inputVariants: Variants = {
  in: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  out: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const OnboardingDateSelectionQuestion: React.FC<OnboardingDateSelectionQuestionProps> = ({
  question,
  onSubmit,
  defaultValue = new Date(),
  inputFormat,
  maxDate,
  onSkip,
  ...other
}) => {
  const [displayed, setDisplayed] = useState(false);
  const [value, setValue] = useState(dayjs(defaultValue));

  useEffect(() => {
    setValue(dayjs(defaultValue));
    setTimeout(() => {
      setDisplayed(true);
    }, 200);
  }, [question]);

  const handleSubmit = useCallback(() => {
    setDisplayed(false);
    setTimeout(() => {
      onSubmit(value.toDate());
    }, 500);
  }, [onSubmit, value]);

  const handleSkip = useCallback(() => {
    if (!onSkip) {
      return;
    }
    setDisplayed(false);
    setTimeout(() => {
      onSkip();
    }, 500);
  }, [onSkip]);

  return (
    <motion.div initial={false} animate={displayed ? 'in' : 'out'} {...other}>
      <OnboardingQuestionText question={question} />
      <motion.div variants={inputsContainerVariants} initial={false}>
        <motion.div initial={false} variants={inputVariants} {...other}>
          <OnboardingQuestionDateSelection
            value={value}
            onChange={setValue}
            onSubmit={handleSubmit}
            inputFormat={inputFormat}
            maxDate={maxDate}
          />
        </motion.div>
        {onSkip && (
          <OnboardingQuestionSkipButton
            onClick={handleSkip}
            variants={inputVariants}
          />
        )}
      </motion.div>
    </motion.div>
  );
};
