// New cards
export const NEW_STEPS = [15, 25, 35]; // in minutes
export const GRADUATING_INTERVAL = 15; // in days
export const EASY_INTERVAL = 4; // in days
export const STARTING_EASE = 2.5; // in percent

// Reviews
export const EASY_BONUS = 1.3;
export const INTERVAL_MODIFIER = 1;
export const MAXIMUM_INTERVAL = 36500; // in days

// Lapses
export const LAPSES_STEPS = [20]; // in minutes
export const NEW_INTERVAL = 0.7;
export const MINIMUM_INTERVAL = 2; // in days
