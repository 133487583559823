import React from 'react';
import {
  Backdrop as MuiBackdrop,
  BackdropProps as MuiBackdropProps,
} from '@material-ui/core';

export type BackdropProps = MuiBackdropProps;

export const Backdrop: React.FC<BackdropProps> = ({ ...other }) => {
  return <MuiBackdrop {...other} />;
};
