import React from 'react';
import {
  FormLabel as MuiFormLabel,
  FormLabelProps as MuiFormLabelProps,
} from '@material-ui/core';
import { cn, makeStyles } from '@21st-night/styles';

export type FormLabelProps = MuiFormLabelProps;

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 6,
  },
}));

export const FormLabel: React.FC<FormLabelProps> = ({
  classes: classesProp = {},
  ...other
}) => {
  const classes = useStyles();

  return (
    <MuiFormLabel
      classes={{ root: cn(classes.root, classesProp.root) }}
      {...other}
    />
  );
};
