import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';

export interface SpacedProps extends React.HTMLAttributes<HTMLDivElement> {
  spacing?: number;
  centered?: boolean;
  align?: 'start' | 'center' | 'end';
  vertical?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
    },
    horizontal: {
      alignItems: 'center',
      '& > :not(:first-child)': {
        marginLeft: (props: { spacing: number }) =>
          theme.spacing(props.spacing),
      },
    },
    vertical: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginTop: (props: { spacing: number }) => theme.spacing(props.spacing),
      },
    },
    centered: {
      justifyContent: 'center',
    },
    alignEnd: {
      justifyContent: 'flex-end',
    },
    alignCenter: {
      justifyContent: 'center',
    },
  }),
);

export const Spaced: React.FC<SpacedProps> = ({
  className,
  children,
  centered,
  align,
  vertical = false,
  spacing = 1,
  ...other
}) => {
  const classes = useStyles({ spacing });
  return (
    <div
      className={cn(
        classes.root,
        centered && classes.centered,
        vertical ? classes.vertical : classes.horizontal,
        align === 'end' && classes.alignEnd,
        align === 'center' && classes.alignCenter,
        className,
      )}
      {...other}
    >
      {children}
    </div>
  );
};
