import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  toDate,
} from '@21st-night/utils';
import { StreakDay, StreakDayDocument } from '../../Gamification.types';

export function deserializeStreakDay(
  doc: DocumentSnapshot | QueryDocumentSnapshot,
): StreakDay {
  const data = doc.data() as StreakDayDocument;

  return {
    ...data,
    id: doc.id,
    createdAt: toDate(data.createdAt),
  };
}
