import React, { useEffect } from 'react';
import { makeStyles } from '@21st-night/styles';
import { Delete, AddBlockAbove, AddBlockBelow } from '@21st-night/icons';
import { Button } from '@21st-night/ui';
import { EquationTextarea } from '../EquationTextarea';
import { EditorToolbarButton } from '../../../components';
import { useMobileEditorToolbar } from '../../../MobileEditor';

export interface EquationBlockMobileToolbarProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClickDone: (event: React.MouseEvent) => void;
  onClickRemove: (event: React.MouseEvent) => void;
  onClickAddBelow: (event: React.MouseEvent) => void;
  onClickAddAbove: (event: React.MouseEvent) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  preview: {
    padding: '8px 10px',
    backgroundColor: theme.palette.grey[200],
  },
  editor: {
    padding: '8px 10px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  iconButtons: {
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
}));

export const EquationBlockMobileToolbar: React.FC<EquationBlockMobileToolbarProps> = ({
  value,
  children,
  onChange,
  onClickDone,
  onClickRemove,
  onClickAddAbove,
  onClickAddBelow,
}) => {
  const classes = useStyles();
  const { hideEditorToolbar, showEditorToolbar } = useMobileEditorToolbar();

  useEffect(() => {
    hideEditorToolbar();

    return showEditorToolbar;
  }, []);

  function handleClickDone(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    showEditorToolbar();
    onClickDone(event);
  }

  function handleClickRemove(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    showEditorToolbar();
    onClickRemove(event);
  }

  function handleClickAddAbove(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    showEditorToolbar();
    onClickAddAbove(event);
  }

  function handleClickAddBelow(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    showEditorToolbar();
    onClickAddBelow(event);
  }

  return (
    <div contentEditable={false} className={classes.root}>
      {value && <div className={classes.preview}>{children}</div>}
      <div className={classes.editor}>
        <EquationTextarea
          autoSelect={false}
          defaultValue={value}
          onChange={onChange}
          placeholder="\begin{aligned}                                          &nbsp;y &= 2x+x+4-2 \\                                      &nbsp;&= 3x+2                                       \end{aligned}"
        />
      </div>
      <div className={classes.actions}>
        <div className={classes.iconButtons}>
          <EditorToolbarButton touch onClick={handleClickRemove}>
            <Delete />
          </EditorToolbarButton>
          <EditorToolbarButton touch onMouseDown={handleClickAddAbove}>
            <AddBlockAbove />
          </EditorToolbarButton>
          <EditorToolbarButton touch onMouseDown={handleClickAddBelow}>
            <AddBlockBelow />
          </EditorToolbarButton>
        </div>
        <Button size="small" color="primary" onMouseDown={handleClickDone}>
          Done
        </Button>
      </div>
    </div>
  );
};
