import {
  AudioElement,
  ImageElement,
  ParagraphElement,
  CardQuestionElement,
  CardAnswerElement,
  CardExplanationElement,
  CardSummaryElement,
  EquationBlockElement,
  EquationElement,
  CardLinkElement,
  RichTextNode,
  VideoElement,
  CodeElement,
  Element,
} from '../../Editor.types';
import { Descendant } from 'slate';
import { v4 as uuid } from 'uuid';
import { isElement } from '../isElement';
import { isText } from '../isText';

type Content =
  | string
  | Descendant
  | Descendant[]
  | RichTextNode
  | RichTextNode[];

export function generateEditorElement(
  type: 'paragraph',
  content?: Content,
): ParagraphElement;
export function generateEditorElement(
  type: 'image',
  content: '',
  props?: Partial<ImageElement>,
): ImageElement;
export function generateEditorElement(
  type: 'audio',
  content: '',
  props?: Partial<AudioElement>,
): AudioElement;
export function generateEditorElement(
  type: 'video',
  content: '',
  props?: Partial<VideoElement>,
): VideoElement;
export function generateEditorElement(
  type: 'code',
  content?: Content,
  props?: Partial<CodeElement>,
): CodeElement;
export function generateEditorElement(
  type: 'tex',
  content: '',
  props: Pick<EquationBlockElement, 'tex'> & Partial<EquationBlockElement>,
): EquationBlockElement;
export function generateEditorElement(
  type: 'equation-inline',
  content: '',
  props: Pick<EquationElement, 'tex'> & Partial<EquationElement>,
): EquationElement;
export function generateEditorElement(
  type: 'card-link',
  content: Content,
  props: Pick<CardLinkElement, 'cardId'> & Partial<CardLinkElement>,
): EquationElement;
export function generateEditorElement(
  type: 'card-question',
  content: Content,
): CardQuestionElement;
export function generateEditorElement(
  type: 'card-answer',
  content: Content,
): CardAnswerElement;
export function generateEditorElement(
  type: 'card-explanation',
  content: Content,
): CardExplanationElement;
export function generateEditorElement(
  type: 'card-summary',
  content: Content,
): CardSummaryElement;
export function generateEditorElement(
  type: string,
  content?: Content,
  props: Record<string, unknown> = {},
): Element {
  const element: Element = {
    type,
    id: uuid(),
    children: [{ text: '' }],
    ...props,
  };

  if (content && typeof content === 'string') {
    element.children = [{ text: content }];
  } else if (Array.isArray(content)) {
    element.children = content;
  } else if (isElement(content) || isText(content)) {
    element.children = [content];
  }

  return element;
}
