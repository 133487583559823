import React from 'react';
import isUrl from 'is-url';
import { v4 as uuid } from 'uuid';
import { Editor, Range, Node } from 'slate';
import {
  BraindropEditorPluginFactory,
  BraindropEditor,
  Transforms,
  isNodeType,
  BraindropEditorPlugin,
} from '@braindrop-editor/core';
import { ElementLink } from './ElementLink';
import isHotkey from 'is-hotkey';
import { isElementOfType } from '@21st-night/editor';
import { EditorWithLinkPlugin, LinkElement } from './LinkPlugin.types';
import { LinkPopover } from './LinkPopover';

export interface LinkPluginOptions {
  hotkey?: string;
  mobile?: boolean;
}

const unwrapLink = (editor: BraindropEditor): void => {
  Transforms.unwrapNodes(editor, {
    match: n => isElementOfType(n, 'link'),
    split: true,
  });
};

const isLinkActive = (editor: BraindropEditor): boolean => {
  const [link] = Editor.nodes(editor, {
    match: n => isElementOfType(n, 'link'),
  });
  return !!link;
};

const wrapLink = (editor: BraindropEditor, url: string): void => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    id: uuid(),
    properties: {},
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const createLinkPlugin = (
  options: LinkPluginOptions = {},
): BraindropEditorPluginFactory => (
  baseEditor: BraindropEditor,
): BraindropEditorPlugin => {
  const editor = baseEditor as EditorWithLinkPlugin;
  const { normalizeNode, renderEditable } = editor;

  editor.renderEditable = (props): JSX.Element => (
    <>
      {renderEditable(props)}
      <LinkPopover mobile={options.mobile} />
    </>
  );

  editor.insertLink = (url: string): void => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  editor.removeLink = (): void => {
    if (editor.selection) {
      unwrapLink(editor);
    }
  };

  editor.normalizeNode = (entry): void => {
    const [baseNode, path] = entry;
    const node = baseNode as LinkElement;

    if (isNodeType(entry, { allow: ['link'] })) {
      // Don't allow links without a URL
      if (!node.url) {
        Transforms.unwrapNodes(editor, {
          match: n => isElementOfType(n, 'link'),
          split: true,
          at: path,
        });
      }

      // Don't allow nested links
      const descendants = Node.descendants(node);
      const links = Array.from(descendants).filter(descEntry =>
        isNodeType(descEntry, { allow: ['link'] }),
      );

      links.forEach(link => {
        Transforms.unwrapNodes(editor, {
          match: n => isElementOfType(n, 'link'),
          split: true,
          at: link[1],
        });
      });
    }

    return normalizeNode(entry);
  };

  return {
    elementDeserializers: {
      A: el => {
        const a = el as HTMLAnchorElement;
        if (a.href) {
          return { type: 'link', url: a.href };
        }
      },
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (
        isHotkey(options.hotkey || 'mod+k', (event as unknown) as KeyboardEvent)
      ) {
        (editor as EditorWithLinkPlugin).openLinkPopover();
      }
    },
    elements: [
      {
        type: 'link',
        component: ({ element, ...props }) => (
          <ElementLink
            {...props}
            element={element as LinkElement}
            mobile={options.mobile}
          />
        ),
        isInline: true,
      },
    ],
    insertData: (data): void | boolean => {
      const text = data.getData('text/plain');

      if (text && isUrl(text)) {
        if (editor.selection) {
          wrapLink(editor, text);
        } else {
          Transforms.insertNodes(
            editor,
            editor.generateElement('link', {
              properties: { url: text },
              children: [{ text }],
            }),
          );
        }

        return true;
      }
    },
  };
};
