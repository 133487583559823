import * as React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';
import { makeStyles } from '@21st-night/styles';

export type OnboardingQuestionSkipButtonProps = HTMLMotionProps<'button'>;

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '18px',
    padding: '8px 16px',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    background: 'transparent',
    margin: 8,
    width: 100,
    marginTop: 32,
    display: 'block',
    border: 'none',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const OnboardingQuestionSkipButton: React.FC<OnboardingQuestionSkipButtonProps> = props => {
  const classes = useStyles();

  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      className={classes.root}
      {...props}
    >
      Skip
    </motion.button>
  );
};
