import { EditorDocument, EditorDocumentMetadata } from '../../Editor.types';

export function stripDocumentMetadata(
  document: EditorDocument,
): [EditorDocument, EditorDocumentMetadata | null] {
  let doc = document;
  let metadata: EditorDocumentMetadata | null = null;
  if (doc[0] && doc[0].type === 'metadata') {
    metadata = doc[0] as EditorDocumentMetadata;
    doc = doc.slice(1);
  }

  return [doc, metadata];
}
