import React, { useRef, useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { useFirebase } from '@21st-night/utils-web';
import { Star } from '@21st-night/icons';
import { isCardDueNow, DeckContentCard, useDeck } from '@21st-night/deck';
import { IconButton, Paper, Spaced, Typography, Button } from '@21st-night/ui';
import { deserializeDocument, RichText } from '@21st-night/editor-web';
import {
  ReviewRating,
  getProficiencyKey,
  ReviewView,
  ReviewRatingButton,
} from '@21st-night/review-web';
import { DeckCategoryTag } from '../DeckCategoryTag';
import { DeckSubcategoryTag } from '../DeckSubcategoryTag';
import { DeckNextReviewTag } from '../DeckNextReviewTag';

import { DeckEditCardDialog } from '../DeckEditCardDialog';
import { DeckRetiredTag } from '../DeckRetiredTag';
import { DeckLinkedNoteTag } from '../DeckLinkedNoteTag';
import { DeckProficiencyTag } from '../DeckProficiencyTag';

export interface DeckItemCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  card: DeckContentCard;
  onRate?: (rating: ReviewRating) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      userSelect: 'none',
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#90A4AE',
      height: 34,
      lineHeight: '34px',
    },
    content: {
      flex: 1,
    },
    metaToolbar: {
      marginTop: theme.spacing(1),
      flexWrap: 'wrap',
    },
    metaToolbarSpacer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    padded: {
      paddingTop: theme.spacing(2),
    },
    ratingButtons: {
      padding: theme.spacing(2, 0),
    },
    menu: {
      minWidth: 170,
    },
    retired: {
      color: theme.palette.text.secondary,
    },
    showAnswerButton: {
      minWidth: 103,
    },
    showQuestionButton: {
      minWidth: 112,
    },
  }),
);

export const DeckItemCard: React.FC<DeckItemCardProps> = ({
  className,
  card,
  onRate,
  ...other
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { imageUrl } = useFirebase();
  const classes = useStyles();
  const deck = useDeck();
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState<ReviewView>('question');

  function handleClickStar() {
    if (card.starred) {
      deck.unstarCard(card.id);
    } else {
      deck.starCard(card.id);
    }
  }

  function scrollIntoView() {
    setTimeout(() => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top < 80 || rect.bottom > window.innerHeight) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  function openEditDialog() {
    setEdit(true);
  }

  function closeEditDialog() {
    setEdit(false);
  }

  function handleRetire() {
    deck.retireCard(card.id);
  }

  function handleUnretire() {
    deck.unretireCard(card.id);
  }

  function showAnswer() {
    setView('answer');
    scrollIntoView();
  }

  function showExplanation(event: React.MouseEvent) {
    event.stopPropagation();
    setView('answer-explanation');
  }

  function showQuestion() {
    setView('question');
    scrollIntoView();
  }

  function handleRate(event: React.MouseEvent, rating: ReviewRating) {
    event.stopPropagation();
    setView('question');
    scrollIntoView();
    if (onRate) {
      onRate(rating);
    }
  }

  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <div ref={ref} className={classes.content}>
        <Typography variant="subtitle1" className={classes.label}>
          {card.type === 'flashcard' && 'Flashcard'}
          {card.type === 'error-log' && 'Error log'}
        </Typography>
        <RichText
          className={cn(card.retired && classes.retired)}
          imageUrl={imageUrl}
          document={deserializeDocument(
            view === 'question' || view === 'question-summary'
              ? card.question
              : card.answer,
          )}
        />
        {view === 'answer-explanation' && card.explanation && (
          <RichText
            className={classes.padded}
            imageUrl={imageUrl}
            document={deserializeDocument(card.explanation)}
          />
        )}
        {view === 'answer' && card.explanation && (
          <Spaced centered className={classes.padded}>
            <Button size="small" color="primary" onClick={showExplanation}>
              Reveal Explanation
            </Button>
          </Spaced>
        )}
        {onRate &&
          isCardDueNow(card) &&
          (view === 'answer' || view === 'answer-explanation') && (
            <Spaced centered spacing={2} className={classes.ratingButtons}>
              <ReviewRatingButton
                rating="wrong"
                size="small"
                onClick={handleRate}
              />
              <ReviewRatingButton
                rating="more-work"
                size="small"
                onClick={handleRate}
              />
              <ReviewRatingButton
                rating="correct"
                size="small"
                onClick={handleRate}
              />
            </Spaced>
          )}
      </div>
      <Spaced className={classes.metaToolbar}>
        <IconButton
          toggle
          size="small"
          activeColor="#FFAB00"
          onClick={handleClickStar}
          active={card.starred}
        >
          <Star />
        </IconButton>
        {typeof card.proficiency !== 'undefined' && !card.retired && (
          <DeckProficiencyTag
            value={getProficiencyKey({
              proficiency: card.proficiency || 0,
              notStarted: !card.lastReview,
            })}
            createdAt={card.createdAt}
            ratings={card.attempts}
          />
        )}
        {card.nextReview && !card.retired && (
          <DeckNextReviewTag
            createdAt={card.createdAt}
            nextReview={card.nextReview}
            lastReview={card.lastReview}
          />
        )}
        {card.retired && <DeckRetiredTag />}
        {card.note && <DeckLinkedNoteTag value={card.note} />}
        {card.category && <DeckCategoryTag category={card.category} />}
        {card.subcategories.map(subcategory => (
          <DeckSubcategoryTag key={subcategory} subcategory={subcategory} />
        ))}
        <div className={classes.metaToolbarSpacer}>
          {(view === 'question' || view === 'question-summary') && (
            <Button
              size="small"
              variant="outlined"
              className={classes.showAnswerButton}
              onClick={showAnswer}
            >
              Show Answer
            </Button>
          )}
          {(view === 'answer' || view === 'answer-explanation') && (
            <Button
              size="small"
              variant="outlined"
              className={classes.showAnswerButton}
              onClick={showQuestion}
            >
              Show Question
            </Button>
          )}
        </div>
        {deck.type === 'student' && !card.retired && (
          <Button size="small" onClick={handleRetire}>
            Retire
          </Button>
        )}
        {deck.type === 'student' && card.retired && (
          <Button size="small" onClick={handleUnretire}>
            Unretire
          </Button>
        )}
        <Button size="small" onClick={openEditDialog}>
          Edit
        </Button>
      </Spaced>
      <DeckEditCardDialog card={card} open={edit} onClose={closeEditDialog} />
    </Paper>
  );
};
