import { CollectionReference } from '@21st-night/utils';
import { ReviewSession } from '../../Review.types';
import { validateNewReviewSession } from '../validators';

export function createReviewSession(
  reviewSessionCollectionRef: CollectionReference,
  session: ReviewSession,
): Promise<void> {
  validateNewReviewSession(session);

  return reviewSessionCollectionRef.doc(session.id).set(session);
}
