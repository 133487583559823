import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  toDate,
} from '@21st-night/utils';
import { Card, CardDocument } from '../../types';

export function deserializeCardDocument(
  doc: QueryDocumentSnapshot | DocumentSnapshot,
): Card {
  const card = doc.data() as CardDocument;

  return {
    ...card,
    createdAt: toDate(card.createdAt),
    // Legacy cards may be missing these
    type: card.type || 'error-log',
    id: doc.id,
    explanation: card.explanation || null,
    summary: card.summary || null,
  };
}
