import React, { useCallback, useMemo } from 'react';
import {
  createContext,
  DocumentReference,
  useFirebase,
} from '@21st-night/utils';
import { Card } from '@21st-night/cards';
import { Note } from '@21st-night/notes';
import {
  CardReviewProgress,
  FlashcardReviewProgress,
} from '@21st-night/review';
import {
  Deck,
  DeckContentItem,
  DeckContentCard,
  DeckContentNote,
} from '../Deck.types';
import { generateDeckCardReviewData } from '../api';
import { DeckApiHook, useDeckApi } from '../hooks/useDeckApi';
import { DeckFilterHook, useDeckFilter } from '../hooks/useDeckFilter';
import { DeckSortHook, useDeckSort } from '../hooks/useDeckSort';

export interface DeckProviderProps {
  deck: Deck;
  cards: Card[];
  notes: Note[];
  docRef: DocumentReference;
  loadingCards?: boolean;
  loadingNotes?: boolean;
}

type GetCard = (id: string) => DeckContentCard | undefined;
type GetNote = (id: string) => DeckContentNote | undefined;
type GetCardProgress = (
  id: string,
) => CardReviewProgress | FlashcardReviewProgress | undefined;

export interface DeckContext
  extends Omit<Deck, 'cards' | 'notes'>,
    DeckApiHook,
    DeckFilterHook,
    DeckSortHook {
  cards: DeckContentCard[];
  notes: DeckContentNote[];
  content: DeckContentItem[];
  loadingContent: boolean;
  docRef: DocumentReference;
  getCard: GetCard;
  getNote: GetNote;
  getCardProgress: GetCardProgress;
}

const [hook, Provider, Consumer] = createContext<DeckContext>();

export const DeckProvider: React.FC<DeckProviderProps> = ({
  children,
  deck,
  cards,
  notes,
  docRef,
  loadingCards,
  loadingNotes,
}) => {
  const { db } = useFirebase();
  const api = useDeckApi(db, deck, docRef);
  const filter = useDeckFilter();
  const sort = useDeckSort();
  const loadingContent = !!loadingCards || !!loadingNotes;

  const deckCards = useMemo(
    () =>
      Object.keys(deck.cards).reduce((value, cardId): DeckContentCard[] => {
        const card = cards.find(c => c.id === cardId);

        if (card) {
          return [...value, { ...deck.cards[cardId], ...card }];
        }

        return value;
      }, [] as DeckContentCard[]),
    [cards, deck.cards],
  );

  const deckNotes = useMemo(
    () =>
      Object.keys(deck.notes).reduce((value, noteId): DeckContentNote[] => {
        const note = notes.find(n => n.id === noteId);

        if (note) {
          return [...value, { ...deck.notes[noteId], ...note }];
        }

        return value;
      }, [] as DeckContentNote[]),
    [notes, deck.notes],
  );

  const getCard: GetCard = useCallback(
    id => deckCards.find(card => card.id === id),
    [deckCards],
  );

  const getNote: GetNote = useCallback(
    id => deckNotes.find(note => note.id === id),
    [deckNotes],
  );

  const getCardProgress: GetCardProgress = useCallback(
    id => {
      const card = deckCards.find(card => card.id === id);

      if (!card) {
        return;
      }

      // Existing progrss values will override generated ones
      const cardWithProgress = generateDeckCardReviewData(card.type, card);

      type ProgressKeys =
        | (keyof CardReviewProgress)[]
        | (keyof FlashcardReviewProgress)[];

      let keys: ProgressKeys = [
        'attempts',
        'firstReview',
        'interval',
        'lastReview',
        'nextReview',
        'proficiency',
        'retired',
        'status',
        'type',
      ];

      if (card.type === 'flashcard') {
        const flashcardKeys: (keyof FlashcardReviewProgress)[] = [
          'easeFactor',
          'stepsIndex',
        ];
        keys = [...keys, ...flashcardKeys];
      }

      return (keys as (keyof CardReviewProgress)[]).reduce(
        (progress, key) => ({
          ...progress,
          [key]: cardWithProgress[key],
        }),
        {} as CardReviewProgress,
      );
    },
    [deckCards],
  );

  return (
    <Provider
      value={{
        ...deck,
        ...api,
        ...filter,
        ...sort,
        docRef,
        loadingContent,
        cards: deckCards,
        notes: deckNotes,
        content: [...deckCards, ...deckNotes],
        categories: deck.categories.sort(),
        subcategories: deck.subcategories.sort(),
        getCard,
        getNote,
        getCardProgress,
      }}
    >
      {children}
    </Provider>
  );
};

export const DeckConsumer = Consumer;
export const useDeck = hook;
