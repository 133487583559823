import { DB, DocumentReference } from '@21st-night/utils';

export function deleteCheatSheetItem(
  db: DB,
  deckRef: DocumentReference,
  id: string,
): Promise<void> {
  return db.runTransaction(async t => {
    const itemRef = deckRef.collection('cheat-sheet').doc(id);
    const doc = await t.get(itemRef);

    if (doc.exists) {
      itemRef.delete();
    }
  });
}
