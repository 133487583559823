import { Descendant, Node } from 'slate';
import { v4 as uuid } from 'uuid';
import {
  EditorDocument,
  CodeElement,
  OrderedListElement,
  EditorDocumentMetadata,
  EditorBlockElement,
  ImageElement,
  RichTextNode,
  EquationElement,
  Element,
} from '../../Editor.types';
import { isElementOfType } from '../isElementOfType';
import { isText } from '../isText';

interface LegacyRichTextElement {
  text: string;
  bold?: true;
  italic?: true;
  underline?: true;
  strikethrough?: true;
}

interface LegacyElement extends Element {
  type:
    | 'bulleted-list'
    | 'block-quote'
    | 'image-uploaded'
    | 'image-linked'
    | 'media'
    | 'heading-one'
    | 'heading-two';
  url?: string;
}

interface LegacyOrderedListElement {
  type: 'numbered-list';
  children: OrderedListElement[];
}

function mergeSubsequentCodeNodes(
  document: EditorDocument,
): (EditorBlockElement | LegacyElement | LegacyOrderedListElement)[] {
  const nodes: (EditorBlockElement | LegacyElement)[] = [];
  let codeBlock: CodeElement | null = null;

  document.forEach(node => {
    if (node.type === 'code') {
      if (codeBlock) {
        codeBlock.children.push(node.children[0]);
      } else {
        codeBlock = {
          type: 'code',
          language: 'javascript',
          id: uuid(),
          children: [node.children[0]],
        };
      }
    } else {
      if (codeBlock && codeBlock.children) {
        codeBlock.children = [
          { text: codeBlock.children.map(leaf => leaf.text).join('\n') },
        ];
        nodes.push(codeBlock);
        codeBlock = null;
      }
      nodes.push(node);
    }
  });

  return nodes;
}

function deserializeLeaves(
  node: EditorBlockElement | LegacyElement,
): EditorBlockElement {
  return {
    ...node,
    children: (node.children as (
      | LegacyRichTextElement
      | EquationElement
    )[]).reduce((leaves, leaf) => {
      if (isElementOfType(leaf, 'equation-inline')) {
        return [
          ...leaves,
          { text: '' },
          {
            type: 'equation-inline',
            tex: leaf.tex,
            children: [{ text: '' }],
            id: uuid(),
          },
          { text: '' },
        ];
      }

      const updatedLeaf: RichTextNode = leaf;

      if (isText(leaf)) {
        if (leaf.bold) {
          updatedLeaf.b = true;
        }

        if (leaf.italic) {
          updatedLeaf.i = true;
        }

        if (leaf.underline) {
          updatedLeaf.u = true;
        }

        if (leaf.strikethrough) {
          updatedLeaf.s = true;
        }
      }

      return [...leaves, updatedLeaf];
    }, [] as Descendant[]),
  } as EditorBlockElement;
}

function deserializeParagraph(
  node: LegacyElement | EditorBlockElement,
): EditorBlockElement {
  return deserializeLeaves({
    ...node,
    id: uuid(),
    type: 'paragraph',
  });
}

function deserializeBlockquote(node: LegacyElement): EditorBlockElement {
  return deserializeLeaves({ ...node, id: uuid(), type: 'blockquote' });
}

function deserializeH1(node: LegacyElement): EditorBlockElement {
  return deserializeLeaves({ ...node, id: uuid(), type: 'h1' });
}

function deserializeH2(node: LegacyElement): EditorBlockElement {
  return deserializeLeaves({ ...node, id: uuid(), type: 'h2' });
}

function deserializeOrderedList(
  node: LegacyOrderedListElement,
): EditorBlockElement[] {
  const items = node.children;
  return items.map((item, index) =>
    deserializeLeaves({
      ...item,
      id: uuid(),
      type: 'ol',
      number: index + 1,
    }),
  );
}

function deserializeUnorderedList(node: LegacyElement): EditorBlockElement[] {
  const items = node.children as EditorBlockElement[];
  return items.map(item =>
    deserializeLeaves({
      ...item,
      id: uuid(),
      type: 'ul',
    }),
  );
}

function deserializeUploadedImage(node: LegacyElement): EditorBlockElement {
  return {
    ...node,
    id: uuid(),
    type: 'image',
    file: node.id,
  } as ImageElement;
}

function deserializeLinkedImage(node: LegacyElement): EditorBlockElement {
  let url = node.url;

  if (!url) {
    url = Node.string(node);
  }

  if (!url) {
    return deserializeParagraph(node);
  }

  return {
    ...node,
    url,
    id: uuid(),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    type: 'image-linked-legacy',
  };
}

function deserializeCode(node: CodeElement): CodeElement {
  return {
    ...node,
    id: uuid(),
    language: node.language || 'javascript',
  };
}

function deserializeVideo(node: LegacyElement): EditorBlockElement {
  let url = node.url;

  if (!url) {
    url = Node.string(node);
  }

  if (!url) {
    return deserializeParagraph(node);
  }

  return {
    ...node,
    id: uuid(),
    url,
    type: 'video',
  };
}

export function deserializeLegacyDocument(
  document: string,
): EditorBlockElement[] {
  const nodes = mergeSubsequentCodeNodes(JSON.parse(document));
  const metadata: EditorDocumentMetadata = {
    type: 'metadata',
    id: uuid(),
    children: [{ text: '' }],
    apiVersion: '2.0.0',
    createdAt: new Date(),
    lastUpdatedAt: new Date(),
  };

  return nodes.reduce(
    (nodes, node) => {
      switch (node.type) {
        case 'numbered-list':
          return [...nodes, ...deserializeOrderedList(node)];
        case 'bulleted-list':
          return [...nodes, ...deserializeUnorderedList(node)];
        case 'block-quote':
          return [...nodes, deserializeBlockquote(node)];
        case 'image-uploaded':
          return [...nodes, deserializeUploadedImage(node)];
        case 'image-linked':
          return [...nodes, deserializeLinkedImage(node)];
        case 'media':
          return [...nodes, deserializeVideo(node)];
        case 'code':
          return [...nodes, deserializeCode(node)];
        case 'heading-one':
          return [...nodes, deserializeH1(node)];
        case 'heading-two':
          return [...nodes, deserializeH2(node)];
        case 'h1':
        case 'h2':
        case 'ol':
        case 'ul':
        case 'blockquote':
        case 'video':
        case 'audio':
        case 'image':
          return [...nodes, node];
        default:
          return [...nodes, deserializeParagraph(node)];
      }
    },
    [metadata] as EditorBlockElement[],
  );
}
