import { DeckFilterOption } from '../../Deck.types';

export function generateDeckCategoryFilters(
  categories: string[],
): DeckFilterOption[] {
  return categories.map(category => ({
    type: 'category',
    group: 'categories',
    label: category,
    value: category,
  }));
}
