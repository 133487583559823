import { DocumentReference } from '@21st-night/utils';
import { updateDeckCard } from '../updateDeckCard';

export function removeCategoryFromCard(
  deckRef: DocumentReference,
  cardId: string,
): Promise<void> {
  return updateDeckCard(deckRef, cardId, {
    category: null,
  });
}
