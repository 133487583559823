import React from 'react';
import { Tab as UiTab, TabProps as UiTabProps } from '@21st-night/ui';
import { makeStyles } from '@21st-night/styles';

export type TabProps = UiTabProps;

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    borderRadius: 4,
    minHeight: 28,
    padding: '4px 8px',
    minWidth: 0,
    margin: 6,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export const Tab: React.FC<TabProps> = ({ ...other }) => {
  const classes = useStyles();

  return <UiTab disableRipple classes={classes} {...other} />;
};
