import React from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { Tooltip } from '@21st-night/ui';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

dayjs.extend(relativeTime);

export interface DeckNextReviewTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  createdAt: Date;
  nextReview: Date;
  lastReview?: Date | null;
  readonly?: boolean;
}

export const DeckNextReviewTag: React.FC<DeckNextReviewTagProps> = ({
  createdAt,
  nextReview,
  lastReview,
  readonly,
  ...other
}) => {
  const deck = useDeck();
  let label = 'Now';

  if (nextReview > new Date() && !dayjs().isSame(nextReview, 'day')) {
    const diff = dayjs(nextReview).diff(new Date(), 'day');
    label = diff > 1 ? `${diff} days` : 'Tomorrow';
  }

  return (
    <Tooltip
      placement="top"
      title={
        <span>
          Next review: {dayjs(nextReview).format('ddd D MMM')}
          <br />
          Last review: {lastReview ? dayjs(lastReview).fromNow() : 'Never'}
          <br />
          Added: {dayjs(createdAt).fromNow()}
        </span>
      }
    >
      <CategoryTag
        variant="metadata"
        label={`🗓 ${label}`}
        onClick={
          !readonly
            ? () =>
                deck.toggleFilter({
                  type: 'boolean',
                  group: 'metadata',
                  value: 'due-now',
                  label: '🗓 Due now',
                })
            : undefined
        }
        {...other}
      />
    </Tooltip>
  );
};
