import React from 'react';
import { cn, makeStyles } from '@21st-night/styles';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@material-ui/core';

export type TabProps = MuiTabProps;

const useStyles = makeStyles(() => ({
  root: {
    textTransform: 'none',
  },
}));

export const Tab: React.FC<TabProps> = ({
  classes: classesProp = {},
  ...other
}) => {
  const classes = useStyles();

  return (
    <MuiTab
      classes={{
        ...classes,
        root: cn(classes.root, classesProp.root),
      }}
      {...other}
    />
  );
};
