import { DB, DocumentReference } from '@21st-night/utils';

export function removeNoteFromDeck(
  db: DB,
  deckRef: DocumentReference,
  noteId: string,
): Promise<void> {
  return deckRef.update({
    [`notes.${noteId}`]: db.delete(),
  });
}
