import React, { useCallback, useState } from 'react';
import { cn, makeStyles } from '@21st-night/styles';
import { RenderElementProps, useUI } from '@braindrop-editor/core';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms, Range } from 'slate';
import { CardLinkElement, isElementOfType } from '@21st-night/editor-web';

interface ElementCardLink extends RenderElementProps<CardLinkElement> {
  emphasize?: boolean;
  onClick?: (cardId: string) => void;
  onClickPreview?: (cardId: string) => void;
  onClickStudy?: (cardId: string) => void;
  onRemoveCardLink?: (cardId: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    borderRadius: 3,
    backgroundColor: '#DDEBF1',
  },
  emphasized: {
    backgroundColor: '#EA80FC',
  },
}));

export const ElementCardLink: React.FC<ElementCardLink> = ({
  children,
  element,
  emphasize,
  attributes,
  onClick,
  onClickPreview,
  onClickStudy,
  onRemoveCardLink,
}) => {
  const editor = useSlate();
  const classes = useStyles();
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const { Popover, List, MenuItem } = useUI();
  const [open, setOpen] = useState(false);
  const cardId = element.cardId;

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (onClick) {
        onClick(cardId);
        return;
      }

      if (!editor.selection || Range.isCollapsed(editor.selection)) {
        let top = event.clientY;
        if (editor.selection) {
          const domNode = ReactEditor.toDOMNode(editor, element);
          top = domNode.getBoundingClientRect().top;
          if (event.clientY - top > 30) {
            top = event.clientY;
          }
        }
        setPosition({ top, left: event.clientX });
        setOpen(true);
      }
    },
    [cardId, onClick],
  );

  const closePopover = useCallback(() => {
    setOpen(false);
  }, [cardId]);

  const handleClickPreview = useCallback(() => {
    closePopover();
    if (onClickPreview) {
      onClickPreview(cardId);
    }
  }, [cardId]);

  const handleClickStudy = useCallback(() => {
    closePopover();
    if (onClickStudy) {
      onClickStudy(cardId);
    }
  }, [cardId]);

  const handleClickDismiss = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const { selection } = editor;
      closePopover();
      if (selection) {
        Transforms.select(editor, selection);
        setTimeout(() => {
          ReactEditor.focus(editor);
        });
      }
    },
    [cardId],
  );

  const handleClickRemoveLink = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const { selection } = editor;
      closePopover();
      if (selection) {
        Transforms.unwrapNodes(editor, {
          split: !Range.isCollapsed(selection),
          at: selection,
          match: node => isElementOfType(node, 'card-link'),
        });
        if (onRemoveCardLink) {
          onRemoveCardLink(element.cardId as string);
        }
      }
    },
    [cardId],
  );

  return (
    <>
      <span
        role="button"
        onClick={handleClick}
        className={cn(classes.root, emphasize && classes.emphasized)}
        {...attributes}
      >
        {children}
      </span>
      {!onClick && (
        <Popover
          open={open}
          onClose={closePopover}
          anchorReference="anchorPosition"
          anchorPosition={position}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <List>
            {onClickPreview && (
              <MenuItem onClick={handleClickPreview}>Preview card</MenuItem>
            )}
            {onClickStudy && (
              <MenuItem onClick={handleClickStudy}>Study card</MenuItem>
            )}
            {onRemoveCardLink && (
              <MenuItem onClick={handleClickRemoveLink}>
                Remove card link
              </MenuItem>
            )}
            <MenuItem onClick={handleClickDismiss}>Dismiss</MenuItem>
          </List>
        </Popover>
      )}
    </>
  );
};
