import {
  EditorDocument,
  stripDocumentMetadata,
  isElementOfType,
} from '@21st-night/editor';
import { createEditor, Transforms, Range } from 'slate';

export function removeCardLinksFromDocument(
  document: EditorDocument,
  at: Range,
): EditorDocument {
  const [children] = stripDocumentMetadata(document);
  const editor = createEditor();
  const { isInline } = editor;
  editor.isInline = node =>
    isElementOfType(node, 'card-link') || isInline(node);

  editor.children = children;

  Transforms.unwrapNodes(editor, {
    at,
    match: node => isElementOfType(node, 'card-link'),
    split: true,
  });

  return editor.children as EditorDocument;
}
