import React from 'react';
import {
  useDeck,
  generateDeckCategoryFilters,
  generateDeckSubcategoryFilters,
} from '@21st-night/deck';
import { DeckFilterOption } from '@21st-night/deck';
import { DeckFilterBar, DeckFilterBarProps } from './DeckFilterBar';

type DeckFilterProps = Omit<
  DeckFilterBarProps,
  'onChange' | 'options' | 'value'
>;

const metadataFilters: DeckFilterOption[] = [
  {
    type: 'item-type',
    group: 'metadata',
    value: 'card',
    label: '🗂 Cards',
  },
  {
    type: 'item-type',
    group: 'metadata',
    value: 'flashcard',
    label: '❓ Flashcards',
  },
  {
    type: 'item-type',
    group: 'metadata',
    value: 'error-log',
    label: '❗ Error logs',
  },
  {
    type: 'item-type',
    group: 'metadata',
    value: 'note',
    label: '📔 Notes',
  },
  {
    type: 'date',
    group: 'metadata',
    value: 'date-added',
    label: '📅 Date added',
  },
  {
    type: 'boolean',
    group: 'metadata',
    value: 'starred',
    label: '⭐️ Starred',
  },
  {
    type: 'date',
    group: 'card-metadata',
    value: 'due-now',
    label: '⏰ Due now',
  },
  {
    type: 'proficiency',
    group: 'card-metadata',
    value: 'not-started',
    label: '⚪️ New',
  },
  {
    type: 'proficiency',
    group: 'card-metadata',
    value: 'needs-work',
    label: '🔴 Needs work',
  },
  {
    type: 'proficiency',
    group: 'card-metadata',
    value: 'in-progress',
    label: '🟠 In progress',
  },
  {
    type: 'proficiency',
    group: 'card-metadata',
    value: 'mastered',
    label: '🟢 Mastered',
  },
  {
    type: 'boolean',
    group: 'card-metadata',
    value: 'retired',
    label: '🗃️ Retired',
  },
  {
    type: 'boolean',
    group: 'card-metadata',
    value: 'explanation',
    label: '📖 Has explanation',
  },
  {
    type: 'boolean',
    group: 'card-metadata',
    value: '!explanation',
    label: '🚫 Missing explanation',
  },
];

export const DeckFilter: React.FC<DeckFilterProps> = props => {
  const deck = useDeck();
  const filters = [
    ...metadataFilters,
    ...generateDeckCategoryFilters(deck.categories),
    ...generateDeckSubcategoryFilters(deck.subcategories),
  ];

  return (
    <DeckFilterBar
      options={filters}
      onChange={(event, val) => deck.setFilters(val)}
      // For some reason, when a new category/subcategory is created
      // with a filter selected in the filter bar, the selected filter
      // is no longer equal to its option in the filter dropdown list,
      // so clicking it in the list causes it to be added again.
      // To fix this, we get the value from filters defined above, instead
      // of from deck.filters directly.
      // value={filters.filter(option =>
      //   deck.filters.find(
      //     deckOption =>
      //       option.value === deckOption.value &&
      //       option.type === deckOption.type,
      //   ),
      // )}
      value={deck.filters}
      {...props}
    />
  );
};
