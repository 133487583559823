import { CardData, Card } from '@21st-night/cards';
import { generateDeckCard } from '../generateDeckCard';
import { DeckCard } from '../../Deck.types';

export function generateDeckCardBatch(
  cardData: CardData[],
  cards: Card[],
): DeckCard[] {
  return cards.map(card => {
    const data = cardData.find(cData => cData.id === card.id);

    return {
      ...generateDeckCard(card),
      category: (data && data.category) || null,
      subcategories: (data && data.subcategories) || [],
    };
  });
}
