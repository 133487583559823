import React, { useRef, useState } from 'react';
import {
  Card,
  CardData,
  createCardBatch,
  generateCardBatch,
  ImportFromCsvDialog,
  ImportFromQuizletDialog,
} from '@21st-night/cards-web';
import { createAnalyticsEvent } from '@21st-night/analytics-web';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import { MenuItem, ListItemText } from '@21st-night/ui';
import {
  DeckAddContentButton,
  DeckAddContentButtonProps,
} from '../DeckAddContentButton';
import { useFirebase } from '@21st-night/utils';
import { DeckCreateCardDialog } from '../DeckCreateCardDialog';
import {
  useDeck,
  generateDeckCardBatch,
  generateDeckCardReviewData,
  Deck,
} from '@21st-night/deck';
import { DeckImageOcclusionDialog } from '../DeckImageOcclusionDialog';
import { DeckImportCardsDialog } from '../DeckImportCardsDialog';

export interface DeckAddCardButtonProps
  extends Omit<DeckAddContentButtonProps, 'label' | 'id' | 'onClick'> {
  type: Card['type'];
  importDecks?: Deck[];
  enableGamification?: boolean;
  enableAnalytics?: boolean;
}

type Dialog = 'new-card' | 'csv' | 'quizlet' | 'import';

export const DeckAddCardButton: React.FC<DeckAddCardButtonProps> = ({
  importDecks,
  type,
  enableAnalytics,
  enableGamification,
  ...other
}) => {
  const occlusionFileSelection = useRef<HTMLInputElement | null>(null);
  const { imageUrl, db, storage, functions, auth } = useFirebase();
  const deck = useDeck();
  const [dialog, setDialog] = useState<Dialog | null>(null);
  const importFromDeckEnabled = importDecks && importDecks.length > 0;
  const typeLabel = type.replace('-', ' ');

  const closeDialog = () => setDialog(null);
  const handleClick = () => setDialog('new-card');
  const handleClickCsv = () => setDialog('csv');
  const handleClickQuizlet = () => setDialog('quizlet');
  const handleClickImport = () => setDialog('import');

  function handleClickImageOcclusion() {
    if (occlusionFileSelection.current) {
      occlusionFileSelection.current.click();
    }
  }

  async function handleCreateCardBatch(data: CardData[], time = 0) {
    if (!auth.currentUser) {
      return;
    }

    const cards = generateCardBatch(auth.currentUser.uid, deck.id, type, data);

    await createCardBatch(db, cards);

    if (enableAnalytics && time !== 0) {
      // Add time spent creating cards to deck analytics
      createAnalyticsEvent(deck.docRef, {
        type: 'create-card',
        card: cards[0].id,
        time,
        createdAt: new Date(),
      });
    }

    if (enableGamification) {
      incrementStreak(db, auth.currentUser.uid);
      incrementStatistic(
        db,
        auth.currentUser.uid,
        'cards-created',
        cards.length,
      );
    }

    let deckCards = generateDeckCardBatch(data, cards);
    if (deck.type === 'student') {
      deckCards = deckCards.map(card => ({
        ...card,
        ...generateDeckCardReviewData(type),
      }));
    }

    return deck.addCardsToDeck(deckCards);
  }

  return (
    <>
      <DeckAddContentButton
        label={`Add ${typeLabel}`}
        id={`deck-add-${type}-button`}
        onClick={handleClick}
        {...other}
      >
        <MenuItem dense key="image" onClick={handleClickImageOcclusion}>
          <ListItemText primary={`Create ${typeLabel}s from an image`} />
        </MenuItem>
        <MenuItem dense key="csv" onClick={handleClickCsv}>
          <ListItemText primary={`Import ${typeLabel}s from a CSV`} />
        </MenuItem>
        <MenuItem dense key="quizlet" onClick={handleClickQuizlet}>
          <ListItemText primary={`Import ${typeLabel}s from Quizlet`} />
        </MenuItem>
        {importFromDeckEnabled && (
          <MenuItem dense key="import" onClick={handleClickImport}>
            <ListItemText primary={`Import ${typeLabel}s from another deck`} />
          </MenuItem>
        )}
      </DeckAddContentButton>
      <DeckCreateCardDialog
        enableAnalytics
        enableGamification
        type={type}
        onClose={closeDialog}
        open={dialog === 'new-card'}
        imageUrl={imageUrl}
      />
      <ImportFromCsvDialog
        {...{ db, storage, functions }}
        onClose={closeDialog}
        open={dialog === 'csv'}
        createCards={handleCreateCardBatch}
      />
      <ImportFromQuizletDialog
        onClose={closeDialog}
        open={dialog === 'quizlet'}
        createCards={handleCreateCardBatch}
      />
      <DeckImageOcclusionDialog
        fileInputRef={occlusionFileSelection}
        createCards={handleCreateCardBatch}
      />
      {importFromDeckEnabled && (
        <DeckImportCardsDialog
          type={type}
          open={dialog === 'import'}
          onClose={closeDialog}
          decks={importDecks || []}
          to={deck.id}
        />
      )}
    </>
  );
};
