import { getPlainTextFileContent } from '@21st-night/utils';
import {
  generateRichTextDocument,
  serializeDocument,
} from '@21st-night/editor';
import { Note } from '../../Notes.types';
import { generateNote } from '../generateNote';

export interface FileData {
  url: string;
  id: string;
  file: File;
}

export async function generateNoteFromFile(
  deck: string,
  author: string,
  fileData: FileData,
  options: Partial<Omit<Note, 'createdAt'>> = {},
): Promise<Note> {
  const { file, url, id } = fileData;
  let noteType: Note['type'] = 'note';
  let content = generateRichTextDocument([file.name]);

  if (file.type === 'application/pdf') {
    noteType = 'pdf';
  } else if (file.type.startsWith('image/')) {
    noteType = 'image';
  } else if (file.type === 'text/plain') {
    const text = await getPlainTextFileContent(file);
    content = generateRichTextDocument(text);
  }

  const noteData: Partial<Note> = {
    content: serializeDocument(content),
    ...options,
  };

  if (noteType !== 'note') {
    noteData.type = noteType;
    noteData.title = file.name;
    noteData.fileId = id;
    noteData.file = url;
  }

  return generateNote(deck, author, noteData);
}
