import React, { useState } from 'react';
import { Button, Menu, MenuItem, ListItemText } from '@21st-night/ui';
import { ExpandMore } from '@21st-night/icons';

export type ReviewMode = 'standard' | 'quiz' | 'answer-first';

export interface ReviewModeOption {
  value: ReviewMode;
  label: string;
}

export interface ReviewModeSelectProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: ReviewMode;
  onChange: (value: ReviewMode) => void;
}

const options: ReviewModeOption[] = [
  {
    value: 'standard',
    label: 'Standard mode',
  },
  {
    value: 'quiz',
    label: 'Quiz mode',
  },
  {
    value: 'answer-first',
    label: 'Answer first',
  },
];

export const ReviewModeSelect: React.FC<ReviewModeSelectProps> = ({
  onChange,
  value,
  ...other
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    val: ReviewMode,
  ) => {
    onChange(val);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} {...other}>
      <Button
        style={{ padding: '2px 8px' }}
        variant="contained"
        onClick={handleButtonItem}
        endIcon={<ExpandMore />}
      >
        {
          (options.find(option => option.value === value) as ReviewModeOption)
            .label
        }
      </Button>
      <Menu
        id="review-type-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            selected={option.value === value}
            onClick={event => handleMenuItemClick(event, option.value)}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
