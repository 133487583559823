import { DocumentReference } from '@21st-night/utils';
import { StudyPlanDayUpdateData } from '../../types';

export function updateStudyPlanDay(
  planRef: DocumentReference,
  id: string,
  data: StudyPlanDayUpdateData,
): Promise<void> {
  return planRef.collection('days').doc(id).update(data);
}
