import React from 'react';
import {
  BraindropEditorPluginFactory,
  BraindropEditorPlugin,
} from '@braindrop-editor/core';
import {
  CardAnswerElement,
  CardQuestionElement,
  Editor,
} from '@21st-night/editor-web';
import {
  ElementAnswer,
  ElementExplanation,
  ElementSummary,
  ElementAnswerReadOnly,
  ReadOnlyAnswerElement,
} from '../plugin-card-answer';
import { ElementQuestion, addCardFormNormalizer } from '../plugin-card-form';

type CardEditorPluginVariant = 'flashcard' | 'error-log';

export interface CardEditorPluginOptions {
  answerElementMinHeight?: number;
  questionElementMinHeight?: number;
  normalize?: boolean;
  variant?: CardEditorPluginVariant;
  autoFocus?: boolean;
}

export const createCardEditorPlugin = ({
  answerElementMinHeight,
  questionElementMinHeight,
  autoFocus = true,
  normalize: normalizeDefault,
  variant = 'error-log',
}: CardEditorPluginOptions = {}): BraindropEditorPluginFactory => (
  baseEditor,
): BraindropEditorPlugin => {
  const editor = baseEditor as Editor;
  const normalize =
    typeof normalizeDefault === 'boolean' ? normalizeDefault : true;
  const questionMinHeight =
    typeof questionElementMinHeight === 'number'
      ? questionElementMinHeight
      : 80;
  const answerMinHeight =
    typeof answerElementMinHeight === 'number' ? answerElementMinHeight : 80;

  if (normalize) {
    addCardFormNormalizer(editor);
  }

  return {
    elements: [
      {
        type: 'card-question',
        component: props => (
          <ElementQuestion
            {...props}
            element={props.element as CardQuestionElement}
            minHeight={questionMinHeight}
            autoFocus={autoFocus}
          />
        ),
      },
      {
        type: 'card-answer',
        component: props => (
          <ElementAnswer
            {...props}
            element={props.element as CardAnswerElement}
            minHeight={answerMinHeight}
          />
        ),
      },
      {
        type: 'card-answer-disabled',
        isVoid: true,
        component: props => (
          <ElementAnswerReadOnly
            {...props}
            element={props.element as ReadOnlyAnswerElement}
          />
        ),
      },
      {
        type: 'card-explanation',
        component: props => <ElementExplanation variant={variant} {...props} />,
      },
      {
        type: 'card-summary',
        component: props => <ElementSummary variant={variant} {...props} />,
      },
    ],
  };
};
