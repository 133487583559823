import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AddAbove: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M6.79171 13.4195L10.75 13.4195L10.75 9.46114C10.75 9.02572 11.1063 8.66947 11.5417 8.66947C11.9771 8.66947 12.3334 9.02572 12.3334 9.46114L12.3334 13.4195L16.2917 13.4195C16.7271 13.4195 17.0834 13.7757 17.0834 14.2111C17.0834 14.6466 16.7271 15.0028 16.2917 15.0028L12.3334 15.0028L12.3334 18.9611C12.3334 19.3966 11.9771 19.7528 11.5417 19.7528C11.1063 19.7528 10.75 19.3966 10.75 18.9611L10.75 15.0028L6.79171 15.0028C6.35629 15.0028 6.00004 14.6466 6.00004 14.2111C6.00004 13.7757 6.35629 13.4195 6.79171 13.4195Z"
      fill="inherit"
    />
    <path
      d="M15.8518 8.98157C16.1606 8.67282 16.1606 8.17406 15.8518 7.86531L12.2181 4.23156C11.9093 3.92281 11.4106 3.92281 11.1018 4.23156L7.46806 7.86531C7.15931 8.17406 7.15931 8.67281 7.46806 8.98156C7.77681 9.29032 8.27556 9.29032 8.58431 8.98156L11.6639 5.9099L14.7356 8.98157C15.0443 9.29032 15.551 9.2824 15.8518 8.98157V8.98157Z"
      fill="inherit"
    />
  </SvgIcon>
);
