import React, { useEffect, useState } from 'react';
import { createContext, useFirebase, FirebaseUser } from '@21st-night/utils';
import { Deck, deserializeDeckDocument } from '@21st-night/deck';
import { User } from '../User.types';
import { deserializeUserDocument } from '../api';

export interface LoadingUserContext {
  uid: null;
  loading: boolean;
  authenticated: false;
  user: null;
}

export interface UnauthenticatedUserContext {
  uid: null;
  loading: boolean;
  authenticated: false;
  user: null;
}

export interface LoadingAuthenticatedUserContext {
  uid: string;
  loading: boolean;
  loadingDecks: boolean;
  authenticated: true;
  decks: Deck[];
  user: FirebaseUser | null;
}

export interface AuthenticatedUserContext extends User {
  uid: string;
  loading: boolean;
  authenticated: true;
  user: FirebaseUser | null;
  decks: Deck[];
  loadingDecks: boolean;
}

export type UserContext =
  | UnauthenticatedUserContext
  | LoadingAuthenticatedUserContext
  | AuthenticatedUserContext;

const [hook, Provider] = createContext<UserContext>();

export const UserProvider: React.FC = ({ children }) => {
  const { auth, db } = useFirebase();
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [decks, setDecks] = useState<Deck[]>([]);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [loadingDecks, setLoadingDecks] = useState(true);
  const [userData, setUserData] = useState<User | null>(null);

  useEffect(() => {
    let isMounted = true;
    let subscriptions: VoidFunction[] = [];

    const unsubscribeFromAuthState = auth.onAuthStateChanged(user => {
      if (user && isMounted) {
        setUser(user);
        setLoadingAuthState(false);

        subscriptions.push(
          db
            .collection('users')
            .doc(user.uid)
            .onSnapshot(doc => {
              if (isMounted && doc && doc.exists) {
                setUserData(deserializeUserDocument(doc));
              }
            }),
        );
        subscriptions.push(
          db
            .collection('users')
            .doc(user.uid)
            .collection('decks')
            .onSnapshot(snapshot => {
              if (isMounted && snapshot) {
                setDecks(
                  snapshot.docs.map(doc => deserializeDeckDocument(doc)),
                );
                setLoadingDecks(false);
              }
            }),
        );
      } else {
        if (isMounted) {
          setLoadingAuthState(false);
          setUser(null);
          setUserData(null);
          setDecks([]);
          setLoadingDecks(true);
        }
        subscriptions.forEach(unsubscribe => unsubscribe());
        subscriptions = [];
      }
    });

    return () => {
      isMounted = false;
      unsubscribeFromAuthState();
      subscriptions.forEach(unsubscribe => unsubscribe());
    };
  }, []);

  // Loading (unknown authentication state)
  if (loadingAuthState) {
    const state: LoadingUserContext = {
      user: null,
      loading: true,
      authenticated: false,
      uid: null,
    };

    return <Provider value={state}>{children}</Provider>;
  }

  // Unauthenticated
  if (!user) {
    const state: UnauthenticatedUserContext = {
      user: null,
      loading: false,
      authenticated: false,
      uid: null,
    };

    return <Provider value={state}>{children}</Provider>;
  }

  // Loading authenticated user data
  if (!userData) {
    const state: LoadingAuthenticatedUserContext = {
      user,
      loadingDecks,
      decks,
      loading: true,
      authenticated: true,
      uid: user.uid,
    };

    return <Provider value={state}>{children}</Provider>;
  }

  // Authenticated
  const state: AuthenticatedUserContext = {
    user,
    loadingDecks,
    loading: false,
    authenticated: true,
    uid: user.uid,
    ...userData,
    // Legacy users have a string[] decks property
    decks,
  };

  return <Provider value={state}>{children}</Provider>;
};

export const useUser = hook;
