import React, { useEffect, useRef } from 'react';
import { DatePicker } from '@21st-night/ui';
import { useDeck } from '@21st-night/deck';
import { Dayjs } from 'dayjs';

export const DeckFilterDateInput: React.FC = props => {
  const { filterDate, setFilterDate } = useDeck();
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      const button = document.getElementById('filter-date-picker-button');
      if (button) {
        button.click();
      }
    });
  }, []);

  return (
    <DatePicker
      OpenPickerButtonProps={{ ref, id: 'filter-date-picker-button' }}
      value={filterDate}
      onChange={value => setFilterDate((value as Dayjs).toDate())}
      {...props}
    />
  );
};
