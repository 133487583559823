import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const InsertFraction: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <rect x="26" y="3" width="16" height="16" rx="2" fill="#2EB0FF" />
    <rect x="26" y="29" width="16" height="16" rx="2" fill="#2EB0FF" />
    <path
      d="M24 24L44 24"
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="5"
      y="14"
      width="14"
      height="20"
      rx="2"
      stroke="black"
      strokeWidth="3"
      fill="none"
    />
  </SvgIcon>
);
