import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Root: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <path
      d="M11.67 41.1633C13.3804 41.1633 14.2721 39.9625 14.7634 38.3976L23.0428 11.7395H42.4222C43.4594 11.7395 44.1692 11.1026 44.1692 10.1018C44.1692 9.13736 43.4594 8.50049 42.4222 8.50049H23.0064C21.1504 8.50049 20.3134 9.19196 19.822 10.7933L11.8701 36.9053H11.5608L6.13982 25.841C5.84867 24.8402 5.30277 24.3671 4.44753 24.3671C3.48311 24.3671 2.77344 25.0585 2.77344 25.9502C2.77344 26.3141 2.86443 26.6416 2.93721 26.9147L8.7949 38.5977C9.24981 40.0716 10.0687 41.1633 11.67 41.1633Z"
      fill="black"
    />
    <rect x="24" y="18" width="16" height="16" rx="2" fill="#2EB0FF" />
    <rect x="6" y="13" width="8" height="10" rx="2" fill="#2EB0FF" />
  </SvgIcon>
);
