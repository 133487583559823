import React, { useMemo } from 'react';
import {
  Editor,
  EditorProps,
  EditorHoveringToolbar,
  EditorDocument,
} from '@21st-night/editor-web';
import {
  CardFormData,
  CardCreationPluginEditable,
  createCardCreationPlugin,
  EditorCreateCardButton,
  createCardLinkPlugin,
} from '@21st-night/cards-web';
import { NoteEditorToolbar } from './NoteEditorToolbar';

export interface NoteEditorProps extends EditorProps {
  onSubmitCardForm: (data: CardFormData, document: EditorDocument) => void;
  onRemoveCardLink: (id: string) => void;
  onClickPreviewCard: (id: string) => void;
  onClickStudyCard: (id: string) => void;
}

export const NoteEditor: React.FC<NoteEditorProps> = ({
  db,
  functions,
  storage,
  plugins = [],
  onSubmitCardForm,
  onRemoveCardLink,
  onClickPreviewCard,
  onClickStudyCard,
  ...other
}) => {
  const CardCreationPlugin = useMemo(
    () =>
      createCardCreationPlugin({
        EditorProps: {
          db,
          storage,
          functions,
          imageUrl: process.env.REACT_APP_IMGIX_URL as string,
        },
      }),
    [db, storage, functions],
  );
  const CardLinkPlugin = useMemo(
    () =>
      createCardLinkPlugin({
        onClickPreviewCard,
        onClickStudyCard,
        onRemoveCardLink,
      }),
    [onClickPreviewCard, onClickStudyCard],
  );

  return (
    <Editor
      placeholder="Empty note"
      db={db}
      functions={functions}
      storage={storage}
      {...other}
      plugins={[CardCreationPlugin, CardLinkPlugin, ...plugins]}
    >
      <CardCreationPluginEditable
        onSubmitCreateCard={onSubmitCardForm}
        EditorProps={{
          db,
          storage,
          functions,
          imageUrl: process.env.REACT_APP_IMGIX_URL as string,
          plugins: [CardCreationPlugin],
        }}
      >
        <NoteEditorToolbar />
        <EditorHoveringToolbar>
          <EditorCreateCardButton onRemoveCardLink={onRemoveCardLink} />
        </EditorHoveringToolbar>
      </CardCreationPluginEditable>
    </Editor>
  );
};
