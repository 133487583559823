import { DB, DocumentReference } from '@21st-night/utils';
import chunck from 'lodash/chunk';

export function deleteStudyPlan(
  db: DB,
  planRef: DocumentReference,
  dayIds: string[],
  itemIds: string[],
): Promise<void[]> {
  const promises = [planRef.delete()];

  const itemChunks = chunck(itemIds, 500);
  const dayChunks = chunck(dayIds, 500);

  itemChunks.forEach(items => {
    const batch = db.batch();
    items.forEach(itemId => {
      batch.delete(planRef.collection('items').doc(itemId));
    });

    promises.push(batch.commit());
  });

  dayChunks.forEach(days => {
    const batch = db.batch();
    days.forEach(dayId => {
      batch.delete(planRef.collection('days').doc(dayId));
    });

    promises.push(batch.commit());
  });

  return Promise.all(promises);
}
