import React from 'react';
import { makeStyles, cn } from '@21st-night/styles';
import { Button, ButtonProps, Hidden } from '@21st-night/ui';
import { RatingCorrect, RatingTricky, RatingWrong } from '@21st-night/icons';
import { ReviewRating } from '@21st-night/review';

export interface ReviewRatingButtonProps extends Omit<ButtonProps, 'onClick'> {
  rating: ReviewRating;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    rating: ReviewRating,
  ) => void;
}

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 3,
    textTransform: 'none',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    transition: theme.transitions.create(
      ['background-color', 'transform', 'box-shadow'],
      {
        duration: theme.transitions.duration.shortest,
      },
    ),
    '&:hover, &:focus': {
      background: 'none',
      transform: 'scale(1.03)',
      boxShadow: theme.shadows[2],
    },
    '&:active': {
      transform: 'scale(1)',
      boxShadow: theme.shadows[0],
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 150,
      padding: theme.spacing(1, 2),
    },
  },
  sizeSmall: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 120,
      padding: '6px 8px',
    },
  },
  startIcon: {
    marginRight: 4,
    [theme.breakpoints.up('sm')]: {
      marginRight: 8,
    },
  },
  correct: {
    color: '#166A34',
    backgroundColor: '#9EE1B7',
    '&:focus': {
      backgroundColor: '#E3F9E5',
    },
    '&:hover': {
      backgroundColor: '#91E697',
    },
    '&:active': {
      backgroundColor: '#51CA58',
    },
  },
  'more-work': {
    color: '#843D1E',
    backgroundColor: '#FEDE89',
    '&:focus': {
      backgroundColor: '#FFF3C4',
    },
    '&:hover': {
      backgroundColor: '#FCE588',
    },
    '&:active': {
      backgroundColor: '#F7C948',
    },
  },
  wrong: {
    color: '#8C282A',
    backgroundColor: '#FBB8B9',
    '&:focus': {
      backgroundColor: '#FFBDBD',
    },
    '&:hover': {
      backgroundColor: '#FFBDBD',
    },
    '&:active': {
      backgroundColor: '#EF4E4E',
    },
  },
  retire: {},
}));

export const ReviewRatingButton: React.FC<ReviewRatingButtonProps> = ({
  className: classNameProp,
  rating,
  onClick,
  size,
  ...other
}) => {
  const classes = useStyles();
  const labels = {
    correct: 'Got it!',
    'more-work': size === 'small' ? 'Tricky' : 'Found it tricky',
    wrong: 'Didn’t get it',
    retire: 'Retire card',
  };
  const labelsMobile = {
    correct: 'Got it!',
    'more-work': 'Tricky',
    wrong: 'Wrong',
    retire: 'Retire',
  };
  const icons = {
    correct: <RatingCorrect />,
    'more-work': <RatingTricky />,
    wrong: <RatingWrong />,
    retire: undefined,
  };

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    onClick(event, rating);
  }

  return (
    <Button
      className={cn(classes.root, classes[rating], classNameProp)}
      startIcon={icons[rating]}
      classes={{ startIcon: classes.startIcon, sizeSmall: classes.sizeSmall }}
      onClick={handleClick}
      size={size}
      {...other}
    >
      <Hidden xsDown>{labels[rating]}</Hidden>
      <Hidden smUp>{labelsMobile[rating]}</Hidden>
    </Button>
  );
};
