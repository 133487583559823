import React, { useEffect, useRef } from 'react';
import { makeStyles, cn } from '@21st-night/styles';
import isHotkey, { HotKeyOptions } from 'is-hotkey';
import {
  Root,
  SquareRoot,
  Squared,
  Power,
  Subscript,
  InsertFraction,
  TurnIntoFraction,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage,
  Delete,
} from '@21st-night/icons';
import { Button } from '@21st-night/ui';
// Import JQuery, required for the functioning of the equation editor
import $ from 'jquery';
// Import the styles from the Mathquill editor
import 'mathquill/build/mathquill.css';
import './equation-input.css';
import { EditorToolbarButton } from '../../../components';
import { useMobileEditorToolbar } from '../../../MobileEditor';

interface MathField {
  latex(): string;
  latex(code: string): void;
  focus(): void;
  blur(): void;
  reflow(): void;
  select(): void;
  cmd(latex: string): void;
  write(value: string): void;
  typedText(value: string): void;
  keystroke(value: string): void;
  moveToLeftEnd(): void;
  moveToRightEnd(): void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.jQuery = $;

// Import Mathquill editor
require('mathquill/build/mathquill');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
const mathQuill = window.MathQuill.getInterface(2);

const useStyles = makeStyles(theme => ({
  popover: {
    backgroundColor: theme.palette.background.paper,
    padding: 2,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000, // Must be higher than EditorToolbarMobile
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  buttons: {
    padding: theme.spacing(1),
    flex: 1,
    overflowY: 'scroll',
    display: 'flex',
    MsOverflowStyle: 'none',
    scrollbarWidth: 'none',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > button': {
      margin: 2,
    },
  },
  buttonsSpread: {
    justifyContent: 'space-around',
  },
  spacer: {
    width: 16,
    display: 'inline-block',
  },
  doneButton: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export interface EquationInputMobileProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, 'onChange'> {
  focus?: boolean;
  focused: boolean;
  highlight?: boolean;
  onChange(latex: string): void;
  value: string;
  onDoneEditing: () => void;
  onDelete: () => void;
}

export const EquationInputMobile: React.FC<EquationInputMobileProps> = ({
  focus,
  focused,
  highlight,
  onChange,
  value,
  onDoneEditing,
  onDelete,
  ...other
}) => {
  const classes = useStyles();
  const { showEditorToolbar, hideEditorToolbar } = useMobileEditorToolbar();
  const element = useRef<HTMLSpanElement>(null);
  const mathField = useRef<MathField>();

  useEffect(() => {
    hideEditorToolbar();

    return showEditorToolbar;
  }, []);

  useEffect(() => {
    if (focus && mathField.current) {
      mathField.current.focus();
    } else if (mathField.current) {
      mathField.current.blur();
    }
  }, [focus]);

  useEffect(() => {
    const field = mathQuill.MathField(element.current, config);
    field.latex(value || '');
    mathField.current = field;
    setTimeout(() => {
      if (!value) {
        field.focus();
      }
    });
  }, []);

  const config = {
    autoCommands: 'pi theta sqrt sum prod alpha beta gamma rho dot',
    autoOperatorNames: 'sin cos tan',
    // substituteTextarea: () => textarea,
    handlers: {
      edit: (field: MathField) => {
        onChange(field.latex());
      },
    },
  };

  function applyCommand(command: string): (event: React.MouseEvent) => void {
    return event => {
      event.preventDefault();
      if (mathField.current) {
        mathField.current.cmd(command);
        mathField.current.focus();
      }
    };
  }

  function writeLatex(latex: string): (event: React.MouseEvent) => void {
    return event => {
      event.preventDefault();
      if (mathField.current) {
        mathField.current.write(latex);
        mathField.current.focus();
      }
    };
  }

  function moveToDirEnd(
    direction: 'left' | 'right',
  ): (event: React.MouseEvent) => void {
    return event => {
      event.preventDefault();
      if (mathField.current) {
        if (direction === 'left') {
          mathField.current.moveToLeftEnd();
        } else {
          mathField.current.moveToRightEnd();
        }
        mathField.current.focus();
      }
    };
  }

  function keystroke(key: string): (event: React.MouseEvent) => void {
    return event => {
      event.preventDefault();
      if (mathField.current) {
        mathField.current.keystroke(key);
        mathField.current.focus();
      }
    };
  }

  function handleClickDone(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    onDoneEditing();
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (mathField.current) {
      const options = (event as unknown) as HotKeyOptions;
      if (isHotkey('Enter', options)) {
        event.preventDefault();
        onDoneEditing();
      } else if (isHotkey('Backspace', options)) {
        if (value === '') {
          onDelete();
        }
      }
    }
  }

  return (
    <>
      {focused && (
        <div className={classes.popover}>
          <div className={classes.topRow}>
            <div className={classes.buttons}>
              <EditorToolbarButton touch onMouseDown={onDelete}>
                <Delete />
              </EditorToolbarButton>
              <span className={classes.spacer} />
              <EditorToolbarButton touch onMouseDown={moveToDirEnd('left')}>
                <FirstPage />
              </EditorToolbarButton>
              <EditorToolbarButton touch onMouseDown={moveToDirEnd('right')}>
                <LastPage />
              </EditorToolbarButton>
              <span className={classes.spacer} />
              <EditorToolbarButton touch onMouseDown={keystroke('Left')}>
                <KeyboardArrowLeft />
              </EditorToolbarButton>
              <EditorToolbarButton touch onMouseDown={keystroke('Right')}>
                <KeyboardArrowRight />
              </EditorToolbarButton>
            </div>
            <div className={classes.doneButton}>
              <Button
                size="small"
                color="primary"
                onMouseDown={handleClickDone}
              >
                Done
              </Button>
            </div>
          </div>
          <div className={cn(classes.buttons, classes.buttonsSpread)}>
            <EditorToolbarButton touch onMouseDown={applyCommand('\\sqrt')}>
              <SquareRoot />
            </EditorToolbarButton>
            <EditorToolbarButton touch onMouseDown={applyCommand('\\nthroot')}>
              <Root />
            </EditorToolbarButton>
            <EditorToolbarButton touch onMouseDown={applyCommand('/')}>
              <TurnIntoFraction />
            </EditorToolbarButton>
            <EditorToolbarButton touch onMouseDown={applyCommand('\\frac')}>
              <InsertFraction />
            </EditorToolbarButton>
            <EditorToolbarButton touch onMouseDown={writeLatex('^2')}>
              <Squared />
            </EditorToolbarButton>
            <EditorToolbarButton touch onMouseDown={applyCommand('^')}>
              <Power />
            </EditorToolbarButton>
            <EditorToolbarButton touch onMouseDown={applyCommand('_')}>
              <Subscript />
            </EditorToolbarButton>
          </div>
        </div>
      )}
      <span
        onKeyDown={handleKeyDown}
        onFocus={hideEditorToolbar}
        style={highlight ? { borderRadius: 0, backgroundColor: '#b3d7ff' } : {}}
        ref={element}
        {...other}
      />
    </>
  );
};
