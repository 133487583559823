import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createNote, generateNote } from '@21st-night/notes-web';
import { useFirebase } from '@21st-night/utils';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import { MenuItem, ListItemText } from '@21st-night/ui';
import {
  DeckAddContentButton,
  DeckAddContentButtonProps,
} from '../DeckAddContentButton';
import { useDeck, generateDeckNote, Deck } from '@21st-night/deck';
import { DeckImportNotesDialog } from '../DeckImportNotesDialog';

export interface DeckAddNoteButtonProps
  extends Omit<DeckAddContentButtonProps, 'label' | 'id' | 'onClick'> {
  onSelectPdfFiles: (files: File[]) => void;
  onSelectImageFiles: (files: File[]) => void;
  onSelectTextFiles: (files: File[]) => void;
  importDecks?: Deck[];
  enableGamification?: boolean;
  enableAnalytics?: boolean;
}

export const DeckAddNoteButton: React.FC<DeckAddNoteButtonProps> = ({
  onSelectPdfFiles,
  onSelectImageFiles,
  onSelectTextFiles,
  importDecks,
  enableGamification,
  ...other
}) => {
  const { auth, db } = useFirebase();
  const deck = useDeck();
  const pdfFileInput = useRef<HTMLInputElement | null>(null);
  const imageFileInput = useRef<HTMLInputElement | null>(null);
  const textFileInput = useRef<HTMLInputElement | null>(null);
  const [importDialog, setImportDialog] = useState(false);
  const importFromDeckEnabled = importDecks && importDecks.length > 0;

  function handleClick() {
    if (!auth.currentUser) {
      return;
    }

    const note = generateNote(deck.id, auth.currentUser.uid);
    createNote(db, note);
    deck.addNoteToDeck(generateDeckNote(note));

    if (enableGamification) {
      incrementStreak(db, auth.currentUser.uid);
      incrementStatistic(db, auth.currentUser.uid, 'notes-created');
    }
  }

  function openPdfFileSelect() {
    if (pdfFileInput.current) {
      pdfFileInput.current.click();
    }
  }

  function openImageFileSelect() {
    if (imageFileInput.current) {
      imageFileInput.current.click();
    }
  }

  function openTextFileSelect() {
    if (textFileInput.current) {
      textFileInput.current.click();
    }
  }

  function handlePdfFilesChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      if (enableGamification && auth.currentUser) {
        incrementStreak(db, auth.currentUser.uid);
        incrementStatistic(
          db,
          auth.currentUser.uid,
          'notes-created',
          files.length,
        );
      }

      onSelectPdfFiles(files);
    }
  }

  function handleImageFilesChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      if (enableGamification && auth.currentUser) {
        incrementStreak(db, auth.currentUser.uid);
        incrementStatistic(
          db,
          auth.currentUser.uid,
          'notes-created',
          files.length,
        );
      }

      onSelectImageFiles(Array.from(event.target.files));
    }
  }

  function handleTextFilesChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      if (enableGamification && auth.currentUser) {
        incrementStreak(db, auth.currentUser.uid);
        incrementStatistic(
          db,
          auth.currentUser.uid,
          'notes-created',
          files.length,
        );
      }

      onSelectTextFiles(Array.from(event.target.files));
    }
  }

  function openImportDialog() {
    setImportDialog(true);
  }

  function closeImportDialog() {
    setImportDialog(false);
  }

  return (
    <>
      <DeckAddContentButton
        label="Add note"
        id="deck-add-note-button"
        onClick={handleClick}
        {...other}
      >
        <MenuItem onClick={openPdfFileSelect}>
          <ListItemText primary="Upload a PDF" />
        </MenuItem>
        <MenuItem onClick={openImageFileSelect}>
          <ListItemText primary="Upload an image" />
        </MenuItem>
        <MenuItem onClick={openTextFileSelect}>
          <ListItemText primary="Import from a text file" />
        </MenuItem>
        {importFromDeckEnabled && (
          <MenuItem onClick={openImportDialog}>
            <ListItemText primary="Import notes from another deck" />
          </MenuItem>
        )}
      </DeckAddContentButton>
      {ReactDOM.createPortal(
        <input
          ref={pdfFileInput}
          type="file"
          accept="application/pdf"
          style={{
            opacity: 0,
            pointerEvents: 'none',
            position: 'fixed',
            left: -1000,
            top: -1000,
          }}
          onChange={handlePdfFilesChange}
        />,
        document.body,
      )}
      {ReactDOM.createPortal(
        <input
          ref={imageFileInput}
          type="file"
          accept="image/*"
          style={{
            opacity: 0,
            pointerEvents: 'none',
            position: 'fixed',
            left: -1000,
            top: -1000,
          }}
          onChange={handleImageFilesChange}
        />,
        document.body,
      )}
      {ReactDOM.createPortal(
        <input
          ref={textFileInput}
          type="file"
          accept="text/plain"
          style={{
            opacity: 0,
            pointerEvents: 'none',
            position: 'fixed',
            left: -1000,
            top: -1000,
          }}
          onChange={handleTextFilesChange}
        />,
        document.body,
      )}
      {importFromDeckEnabled && (
        <DeckImportNotesDialog
          open={importDialog}
          onClose={closeImportDialog}
          decks={importDecks || []}
          to={deck.id}
        />
      )}
    </>
  );
};
