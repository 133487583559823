import React from 'react';
import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@material-ui/core';

export type DialogActionsProps = MuiDialogActionsProps;

export const DialogActions: React.FC<DialogActionsProps> = ({ ...other }) => {
  return <MuiDialogActions {...other} />;
};
