import React, { useState, useRef, useEffect } from 'react';
import { useEditor, ReactEditor, useSelected } from 'slate-react';
import { Transforms, Range } from 'slate';
import { useUI, RenderElementProps } from '@braindrop-editor/core';
import { makeStyles } from '@21st-night/styles';
import { Delete, AddBlockAbove, AddBlockBelow } from '@21st-night/icons';
import { useTex } from '../utils/useTex';
import { TexIcon } from '../icons';
import { BlockEquationElement } from '../EquationBlockPlugin.types';
import { WebEditor } from '../../../web-editor-plugins';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';
import { EquationBlockPopover } from './EquationBlockPopover';
import { EquationBlockMobileToolbar } from './EquationBlockMobileToolbar';

export interface ElementEquationBlockProps extends RenderElementProps {
  element: BlockEquationElement;
  mobile?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
}));

export const ElementEquationBlock: React.FC<ElementEquationBlockProps> = ({
  attributes,
  children,
  element,
  mobile,
}) => {
  const classes = useStyles();
  const { BlockPlaceholder, VoidBlock } = useUI();
  const selected = useSelected();
  const [open, setOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { html, error, onChange, value } = useTex(element.tex, {
    displayMode: true,
  });
  const editor = useEditor() as WebEditor;

  useEffect(() => {
    if (
      !element.tex &&
      editor.selection &&
      selected &&
      Range.isCollapsed(editor.selection)
    ) {
      setOpen(true);
    }
  }, [selected, editor.selection, element.tex]);

  useEffect(() => {
    Transforms.setNodes(
      editor,
      {
        tex: value,
      } as Partial<BlockEquationElement>,
      { at: ReactEditor.findPath(editor, element) },
    );
  }, [value]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleDelete = (event: React.MouseEvent): void => {
    event.preventDefault();
    editor.deleteElement(element);
  };

  const addParagraphAbove = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    editor.insertParagraphAbove(element);
    handleClose();
  };

  const addParagraphBelow = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    editor.insertParagraphBelow(element);
    handleClose();
  };

  return (
    <div {...attributes} className={classes.root}>
      <div ref={divRef}>
        {value && !mobile && !open && (
          <div className={classes.toolbar} contentEditable={false}>
            <EditorToolbarButton
              contrast
              tooltip="Add a paragraph above"
              onClick={addParagraphAbove}
            >
              <AddBlockAbove />
            </EditorToolbarButton>
            <EditorToolbarButton
              contrast
              tooltip="Add a paragraph below"
              onClick={addParagraphBelow}
            >
              <AddBlockBelow />
            </EditorToolbarButton>
            <EditorToolbarButton
              contrast
              tooltip="Delete"
              onClick={handleDelete}
            >
              <Delete />
            </EditorToolbarButton>
          </div>
        )}
        {children}
        {!value && (
          <BlockPlaceholder
            label="Add a TeX equation"
            icon={<TexIcon />}
            onClick={handleOpen}
            onClickDelete={handleDelete}
          />
        )}
        {value && (
          <VoidBlock
            onMouseDown={handleOpen}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4px 8px',
              boxSizing: 'border-box',
            }}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </VoidBlock>
        )}
        {!mobile && (
          <EquationBlockPopover
            anchorEl={divRef}
            open={open}
            value={value}
            error={error}
            onChange={onChange}
            onClose={handleClose}
          />
        )}
        {mobile && open && (
          <EquationBlockMobileToolbar
            value={value}
            onChange={onChange}
            onClickDone={handleClose}
            onClickRemove={handleDelete}
            onClickAddAbove={addParagraphAbove}
            onClickAddBelow={addParagraphBelow}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </EquationBlockMobileToolbar>
        )}
      </div>
    </div>
  );
};
