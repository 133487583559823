import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { Delete, AddBlockAbove, AddBlockBelow } from '@21st-night/icons';
import { makeStyles } from '@21st-night/styles';
import { ReactEditor, useSlate } from 'slate-react';
import { RenderElementProps } from '@braindrop-editor/core';
import { ImageElement } from '@21st-night/editor';
import { WebEditor } from '../../../web-editor-plugins';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
}));

export const ElementLinkedImageLegacy: React.FC<
  RenderElementProps<ImageElement>
> = ({ children, element, attributes }) => {
  const classes = useStyles();
  const editor = useSlate() as WebEditor;

  const deleteNode = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  }, [element]);

  return (
    <div className={classes.root} {...attributes}>
      <div contentEditable={false} className={classes.toolbar}>
        <EditorToolbarButton
          contrast
          tooltip="Add a paragraph above"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            editor.insertParagraphAbove(element);
          }}
        >
          <AddBlockAbove />
        </EditorToolbarButton>
        <EditorToolbarButton
          contrast
          tooltip="Add a paragraph below"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            editor.insertParagraphBelow(element);
          }}
        >
          <AddBlockBelow />
        </EditorToolbarButton>
        <EditorToolbarButton contrast tooltip="Delete" onClick={deleteNode}>
          <Delete />
        </EditorToolbarButton>
      </div>
      {children}
      <img src={element.url as string} style={{ width: '100%' }} />
    </div>
  );
};
