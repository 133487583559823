import React from 'react';
import {
  BraindropEditorPluginFactory,
  BraindropEditor,
  BraindropEditorPlugin,
  TurnInto,
  Insert,
  InsertOptions,
  TurnIntoOptions,
  Element,
} from '@braindrop-editor/core';
import { Transforms, Node } from 'slate';
import 'katex/dist/katex.min.css';
import 'katex/dist/contrib/mhchem.js';
import { ElementEquationBlock } from './ElementEquationBlock';
import { BlockEquationElement } from './EquationBlockPlugin.types';

export interface EquationBlockPluginOptions {
  mobile?: boolean;
}

const TYPE = 'tex';

export interface EditorWithEquationBlockPlugin extends BraindropEditor {
  insertBlockEquation: (options?: InsertOptions) => void;
  turnIntoBlockEquation: (element: Element, options?: TurnIntoOptions) => void;
}
export const createEquationBlockPlugin = (
  options?: EquationBlockPluginOptions,
): BraindropEditorPluginFactory => (
  editor: BraindropEditor,
): BraindropEditorPlugin => {
  const equationEditor = editor as EditorWithEquationBlockPlugin;

  const insertBlockEquation: Insert = (editor, options) => {
    Transforms.insertNodes(
      editor,
      {
        type: TYPE,
        tex: '',
        children: [{ text: '' }],
      } as BlockEquationElement,
      options,
    );
  };

  const turnIntoBlockEquation: TurnInto = (editor, element, options): void => {
    Transforms.setNodes(
      editor,
      {
        type: TYPE,
        tex: Node.string(element),
        children: [{ text: '' }],
      } as BlockEquationElement,
      options,
    );
  };

  equationEditor.insertBlockEquation = (options?: InsertOptions) =>
    insertBlockEquation(equationEditor, options);
  equationEditor.turnIntoBlockEquation = (
    element: Element,
    options?: TurnIntoOptions,
  ) => turnIntoBlockEquation(equationEditor, element, options);

  return {
    elements: [
      {
        isVoid: true,
        component: ({ element, ...props }) => (
          <ElementEquationBlock
            {...props}
            mobile={options && options.mobile}
            element={element as BlockEquationElement}
          />
        ),
        type: TYPE,
        shortcuts: ['$$$ '],
        hotkeys: ['mod+alt+0'],
        insert: insertBlockEquation,
        turnInto: turnIntoBlockEquation,
      },
    ],
  };
};
