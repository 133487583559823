import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { CardEditorMobile, CardEditorMobileProps } from '@21st-night/cards-web';
import {
  CategorySelectMobile,
  CategoryTagsButton,
} from '@21st-night/categories-web';
import { LabelledRow, Spaced } from '@21st-night/ui';

export interface DeckCardEditorMobileProps
  extends React.HTMLAttributes<HTMLDivElement>,
    CardEditorMobileProps {
  category: string | null;
  subcategories: string[];
  note: string | null;
  onClickSelectCategory: React.MouseEventHandler;
  onClickSelectSubcategory: React.MouseEventHandler;
  onClickSelectNote: React.MouseEventHandler;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    metadata: {
      marginBottom: theme.spacing(3),
    },
  }),
);

export const DeckCardEditorMobile: React.FC<DeckCardEditorMobileProps> = ({
  className,
  imageUrl,
  category,
  subcategories,
  note,
  onClickSelectCategory,
  onClickSelectSubcategory,
  question,
  answer,
  explanation,
  summary,
  onQuestionChange,
  onAnswerChange,
  onExplanationChange,
  onSummaryChange,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, className)} {...other}>
      <Spaced vertical className={classes.metadata}>
        <CategorySelectMobile
          value={category}
          onClick={onClickSelectCategory}
        />
        <CategorySelectMobile
          variant="subcategory"
          value={subcategories}
          onClick={onClickSelectSubcategory}
        />
        <LabelledRow label="Note">
          <CategoryTagsButton
            variant="metadata"
            placeholder="Attach a note"
            value={note ? [`📔 ${note}`] : []}
          />
        </LabelledRow>
      </Spaced>
      <CardEditorMobile
        imageUrl={imageUrl}
        question={question}
        answer={answer}
        explanation={explanation}
        summary={summary}
        onQuestionChange={onQuestionChange}
        onAnswerChange={onAnswerChange}
        onExplanationChange={onExplanationChange}
        onSummaryChange={onSummaryChange}
      />
    </div>
  );
};
