import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AddBelow: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M16.2917 10.3333H12.3333V14.2917C12.3333 14.7271 11.9771 15.0833 11.5417 15.0833C11.1063 15.0833 10.75 14.7271 10.75 14.2917V10.3333H6.79167C6.35625 10.3333 6 9.97708 6 9.54167C6 9.10625 6.35625 8.75 6.79167 8.75H10.75V4.79167C10.75 4.35625 11.1063 4 11.5417 4C11.9771 4 12.3333 4.35625 12.3333 4.79167V8.75H16.2917C16.7271 8.75 17.0833 9.10625 17.0833 9.54167C17.0833 9.97708 16.7271 10.3333 16.2917 10.3333Z"
      fill="inherit"
    />
    <path
      d="M7.23156 14.7712C6.92281 15.08 6.92281 15.5787 7.23156 15.8875L10.8653 19.5212C11.1741 19.83 11.6728 19.83 11.9816 19.5212L15.6153 15.8875C15.9241 15.5787 15.9241 15.08 15.6153 14.7712C15.3066 14.4625 14.8078 14.4625 14.4991 14.7712L11.4195 17.8429L8.34781 14.7712C8.03906 14.4625 7.5324 14.4704 7.23156 14.7712V14.7712Z"
      fill="inherit"
    />
  </SvgIcon>
);
