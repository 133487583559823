import { DocumentReference } from '@21st-night/utils';
import { updateDeckCard } from '../updateDeckCard';

export function starCard(
  deckRef: DocumentReference,
  cardId: string,
): Promise<void> {
  return updateDeckCard(deckRef, cardId, {
    starred: true,
  });
}
