import { DB, DocumentReference } from '@21st-night/utils';

export function deleteStudyPlanSubItem(
  db: DB,
  planRef: DocumentReference,
  parentId: string,
  itemId: string,
): Promise<void[]> {
  return Promise.all([
    planRef.collection('items').doc(itemId).delete(),
    planRef
      .collection('items')
      .doc(parentId)
      .update({ items: db.arrayRemove(itemId) }),
  ]);
}
