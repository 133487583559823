import { validateDoc, DocValidator } from '@21st-night/utils';
import { ReviewSessionUpdateData } from '../../Review.types';
import { NewRatingEventValidator } from './validateNewRatingEvent';

export const ReviewSessionUpdateValidator: DocValidator<ReviewSessionUpdateData> = {
  lastActivity: 'date',
  completed: ['optional', 'boolean'],
  duration: ['optional', 'number', 'increment'],
  cards: ['optional', 'string-array', 'arrayUnion'],
  cardAttempts: [
    'optional',
    {
      type: 'map',
      keys: 'string',
      items: {
        type: 'array',
        items: NewRatingEventValidator,
      },
    },
  ],
};

export function validateReviewSessionUpdate(
  doc: ReviewSessionUpdateData,
): boolean {
  return validateDoc(ReviewSessionUpdateValidator, doc);
}
