import { DB } from '@21st-night/utils';
import { Deck } from '../../Deck.types';
import { validateNewDeck } from '../validators';

export function createUserDeck(
  db: DB,
  userId: string,
  deck: Deck,
): Promise<void[]> {
  validateNewDeck(deck);

  return Promise.all([
    db
      .collection('users')
      .doc(userId)
      .collection('decks')
      .doc(deck.id)
      .set(deck),
    db
      .collection('users')
      .doc(userId)
      .update({ deckIds: db.arrayUnion(deck.id) }),
  ]);
}
