import React from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@material-ui/core';

export type RadioGroupProps = MuiRadioGroupProps;

export const RadioGroup: React.FC<RadioGroupProps> = ({ ...other }) => {
  return <MuiRadioGroup {...other} />;
};
