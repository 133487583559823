import { DB } from '@21st-night/utils';
import { StudyPlan } from '../../types';

type Options = Pick<StudyPlan, 'startDate' | 'endDate' | 'startWeekOn'>;

export function createStudyPlan(
  db: DB,
  userId: string,
  options: Options,
): Promise<void> {
  const plan: StudyPlan = {
    createdAt: new Date(),
    description: null,
    ...options,
  };

  return db
    .collection('users')
    .doc(userId)
    .collection('study-plan')
    .doc('plan')
    .set(plan);
}
