import React from 'react';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export interface DeckRetiredTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  readonly?: boolean;
}

export const DeckRetiredTag: React.FC<DeckRetiredTagProps> = ({
  readonly,
  ...other
}) => {
  const deck = useDeck();

  return (
    <CategoryTag
      variant="metadata"
      label="🗃️ Retired"
      onClick={
        !readonly
          ? () =>
              deck.toggleFilter({
                type: 'boolean',
                group: 'metadata',
                label: '🗃️ Retired',
                value: 'retired',
              })
          : undefined
      }
      {...other}
    />
  );
};
