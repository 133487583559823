import React, { useRef } from 'react';
import { Range } from 'slate';
import {
  Element,
  RenderElementProps,
  Transforms,
} from '@braindrop-editor/core';
import { useSlate, ReactEditor } from 'slate-react';
import 'katex/dist/katex.min.css';
import 'katex/dist/contrib/mhchem.js';
import { EquationInput, EquationInputMobile } from './EquationInput';
import { EquationPlaceholder } from './EquationPlaceholder';

export interface EquationElement extends Element {
  tex: string;
}

export interface RnederEquationElementProps
  extends Omit<RenderElementProps, 'element'> {
  element: EquationElement;
  mobile?: boolean;
}

export const Equation: React.FC<RnederEquationElementProps> = ({
  mobile,
  attributes,
  children,
  element,
}) => {
  const span = useRef<HTMLSpanElement>(null);
  const editor = useSlate();
  const { tex } = element as EquationElement;
  const path = ReactEditor.findPath(editor, element);
  const isFocused = Boolean(
    editor.selection &&
      Range.includes(editor.selection, path) &&
      Range.isCollapsed(editor.selection),
  );
  const isHighlighted =
    !!editor.selection &&
    Range.includes(editor.selection, path) &&
    !Range.isCollapsed(editor.selection);

  function handleChange(value: string): void {
    Transforms.setNodes(
      editor,
      {
        tex: value,
      } as Partial<EquationElement>,
      { at: ReactEditor.findPath(editor, element) },
    );
  }

  function handleMoveOutLeft() {
    const newPath = [...path.slice(0, -1), path.slice(-1)[0] - 1];

    Transforms.select(editor, newPath);
    Transforms.collapse(editor, { edge: 'end' });
    ReactEditor.focus(editor);
  }

  function handleMoveOutRight() {
    const newPath = [...path.slice(0, -1), path.slice(-1)[0] + 1];

    Transforms.select(editor, newPath);
    Transforms.collapse(editor, { edge: 'start' });
    ReactEditor.focus(editor);
  }

  function handleDelete() {
    Transforms.removeNodes(editor, { at: path });

    ReactEditor.focus(editor);
  }

  function handleDeleteOut(value: string) {
    if (!value) {
      handleDelete();
    } else {
      ReactEditor.focus(editor);
    }
  }

  function handleModOutLeft() {
    handleMoveOutLeft();
    Transforms.move(editor, {
      distance: 1,
      unit: 'line',
      edge: 'start',
      reverse: true,
    });
    Transforms.collapse(editor, { edge: 'start' });
  }

  function handleModOutRight() {
    handleMoveOutRight();
    Transforms.move(editor, { distance: 1, unit: 'line', edge: 'end' });
    Transforms.collapse(editor, { edge: 'end' });
  }

  if (!isFocused && !tex) {
    return (
      <span {...attributes}>
        <EquationPlaceholder />
        {children}
      </span>
    );
  }

  if (mobile) {
    return (
      <span {...attributes}>
        <EquationInputMobile
          focus={!!isFocused}
          focused={isFocused}
          highlight={isHighlighted}
          value={tex}
          onChange={handleChange}
          onDoneEditing={handleMoveOutRight}
          onDelete={handleDelete}
        />
        {children}
      </span>
    );
  }

  return (
    <span {...attributes}>
      <EquationInput
        focus={!!isFocused}
        focused={isFocused}
        highlight={isHighlighted}
        onMoveOutLeft={handleMoveOutLeft}
        onMoveOutRight={handleMoveOutRight}
        onModOutLeft={handleModOutLeft}
        onModOutRight={handleModOutRight}
        onDeleteOutLeft={handleDeleteOut}
        onDeleteOutRight={() => null}
        value={tex}
        onChange={handleChange}
      />
      {children}
    </span>
  );
};
