import React, { useCallback, useMemo } from 'react';
import {
  EditorProps,
  Editor,
  EditorHoveringToolbar,
} from '@21st-night/editor-web';
import { createCardAnswerPlugin } from '../../plugin-card-answer';
import { createCardFormPlugin } from '../../plugin-card-form';
import { useCardCreationPlugin } from '../CardCreationPluginProvider';

interface EditorCardFormProps {
  EditorProps: Omit<EditorProps, 'value' | 'onChange'>;
}

const CardAnswerPlugin = createCardAnswerPlugin({ normalize: false });

export const EditorCardForm: React.FC<EditorCardFormProps> = ({
  EditorProps,
}) => {
  const {
    position: positionValue,
    createCard,
    setPosition,
    setCardFormValue,
    cardFormValue,
    editorRef,
  } = useCardCreationPlugin();
  let position = positionValue;

  if (position && editorRef.current) {
    const editorBox = editorRef.current.getBoundingClientRect();
    position = {
      ...position,
      y: position.y,
      left: editorBox.left + editorBox.width / 2,
      width: editorBox.width,
    };
  }

  const handleClickCancel = useCallback(() => {
    setPosition(undefined);
  }, []);

  const handleClickSubmitFlashcard = useCallback(() => {
    createCard('flashcard');
  }, []);

  const handleClickSubmitErrorLog = useCallback(() => {
    createCard('error-log');
  }, []);

  const CardFormPlugin = useMemo(
    () =>
      createCardFormPlugin({
        position,
        onClickCancel: handleClickCancel,
        onClickSubmitFlashcard: handleClickSubmitFlashcard,
        onClickSubmitErrorLog: handleClickSubmitErrorLog,
      }),
    [position],
  );

  if (!position) {
    return null;
  }

  return (
    <Editor
      {...EditorProps}
      onChange={setCardFormValue}
      value={cardFormValue}
      plugins={[
        CardAnswerPlugin,
        CardFormPlugin,
        ...(EditorProps.plugins || []),
      ]}
    >
      <EditorHoveringToolbar />
    </Editor>
  );
};
