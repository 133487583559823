import React from 'react';
import { Editor, ExternalEditorToolbar } from '@21st-night/editor-web';
import { Button } from '@21st-night/ui';
import { useFocused, useSlate } from 'slate-react';
import { makeStyles } from '@21st-night/styles';
import { useUI } from '@braindrop-editor/core';

interface CardFormEditableProps {
  position?: DOMRect;
  onClickSubmitFlashcard: (event: React.MouseEvent) => void;
  onClickSubmitErrorLog: (event: React.MouseEvent) => void;
  onClickCancel: (event: React.MouseEvent) => void;
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    boxSizing: 'border-box',
    padding: theme.spacing(2, 2, 10, 2),
    maxHeight: '50vh',
    overflowY: 'scroll',
  },
  typeSelect: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& > :not(:first-child)': {
      marginLeft: 8,
    },
    zIndex: 99999999,
  },
}));

export const CardFormEditable: React.FC<CardFormEditableProps> = ({
  children,
  position,
  onClickCancel,
  onClickSubmitFlashcard,
  onClickSubmitErrorLog,
}) => {
  const classes = useStyles();
  const focused = useFocused();
  const editor = useSlate() as Editor;
  const { Popover } = useUI();
  let verticalOrigin = 'top';

  if (position && window.innerHeight && position.y > window.innerHeight / 2) {
    verticalOrigin = 'bottom';
  }

  return (
    <>
      {focused && (
        <div className={classes.toolbar} contentEditable={false}>
          <ExternalEditorToolbar editor={editor} />
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          left: -1000,
          top: -1000,
          display: 'none',
        }}
      >
        {children}
      </div>
      {position && (
        <Popover
          disableBackdrop
          open
          onClose={() => null}
          anchorReference="anchorPosition"
          transformOrigin={{ vertical: verticalOrigin, horizontal: 'center' }}
          anchorOrigin={{ vertical: verticalOrigin, horizontal: 'center' }}
          anchorPosition={{ top: position.y, left: position.left }}
        >
          <div className={classes.content} style={{ width: position.width }}>
            {children}
          </div>
          <div contentEditable={false} className={classes.actions}>
            <Button size="small" variant="text" onClick={onClickCancel}>
              Cancel
            </Button>
            <div style={{ flex: 1 }} />
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={onClickSubmitFlashcard}
            >
              Create flashcard
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={onClickSubmitErrorLog}
            >
              Create error log
            </Button>
          </div>
        </Popover>
      )}
    </>
  );
};
