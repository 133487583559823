import React from 'react';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export interface DeckLinkedNoteTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  readonly?: boolean;
  value: string;
}

export const DeckLinkedNoteTag: React.FC<DeckLinkedNoteTagProps> = ({
  readonly,
  value,
  ...other
}) => {
  const deck = useDeck();

  return (
    <CategoryTag
      variant="metadata"
      label="🔗 Note"
      onClick={
        !readonly
          ? () =>
              deck.toggleFilter({
                value,
                type: 'link',
                group: 'metadata',
                label: '🔗 Linked',
              })
          : undefined
      }
      {...other}
    />
  );
};
