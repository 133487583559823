import React from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  TextField,
  LoadingIndicator,
} from '@21st-night/ui';
import { makeStyles, createStyles } from '@21st-night/styles';

export interface OnboardingCompletedDialogTutorFormValues {
  name: string;
  email: string;
  phone?: string;
  date: string;
}

export interface OnboardingCompletedDialogTutorProps {
  loading?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmitForm: (values: OnboardingCompletedDialogTutorFormValues) => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

export const OnboardingCompletedDialogTutor: React.FC<OnboardingCompletedDialogTutorProps> = ({
  loading,
  onClose,
  onSubmitForm,
  ...other
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { name: '', email: '', phone: '', date: '' },
  });

  return (
    <Dialog classes={{ paper: classes.paper }} onClose={onClose} {...other}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <DialogContentText>
            <Typography paragraph color="textPrimary">
              You completed your pre-studying checklist. Congrats!
            </Typography>
            <Typography color="textPrimary">
              Want a demo on how 21st Night can skyrocket your students&apos;
              learning potential? Contact us to set up a 15 minute demo!
            </Typography>
          </DialogContentText>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label={
              <span>
                Name<sup>*</sup>
              </span>
            }
            error={errors.name}
            disabled={loading}
            inputRef={register({ required: true })}
          />
          <TextField
            fullWidth
            margin="normal"
            name="email"
            label={
              <span>
                Email<sup>*</sup>
              </span>
            }
            error={errors.email}
            disabled={loading}
            inputRef={register({ required: true })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Phone"
            name="phone"
            error={errors.phone}
            disabled={loading}
            inputRef={register}
          />
          <TextField
            fullWidth
            margin="normal"
            label={
              <span>
                When are you free for a demo?<sup>*</sup>
              </span>
            }
            error={errors.date}
            disabled={loading}
            placeholder={dayjs()
              .add(2, 'day')
              .format('[e.g. ]D MMMM[ at 3 PM]')}
            name="date"
            inputRef={register({ required: true })}
          />
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Button onClick={onClose}>Skip</Button>
            <Button type="submit" variant="contained" color="primary">
              Send
            </Button>
          </DialogActions>
        )}
        {loading && (
          <DialogActions>
            <LoadingIndicator />
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};
