import { DB, DocumentReference } from '@21st-night/utils';
import {
  Deck,
  DeckCard,
  DeckCardUpdateData,
  DeckNote,
  DeckNoteUpdateData,
  DeckCardCheatSheetItem,
} from '../../Deck.types';
import {
  addCardToDeck,
  addCardsToDeck,
  addCardToCheatSheet,
  addNoteToDeck,
  addSubcategoryToCard,
  addSubcategoryToNote,
  createCategory,
  createSubcategory,
  deleteCategory,
  deleteCheatSheetItem,
  deleteDeck,
  deleteSubcategory,
  removeCardFromDeck,
  removeCategoryFromCard,
  removeCategoryFromNote,
  removeNoteFromDeck,
  removeSubcategoryFromCard,
  removeSubcategoryFromNote,
  renameCategory,
  renameSubcategory,
  retireCard,
  setCardCategory,
  setCardSubcategories,
  setNoteCategory,
  setNoteSubcategories,
  starCard,
  starNote,
  unretireCard,
  unstarCard,
  unstarNote,
  updateDeckCard,
  updateDeckNote,
  updateLastActivity,
} from '../../api';

export interface DeckApiHook {
  addCardsToDeck: (cards: DeckCard[]) => Promise<void>;
  addCardToDeck: (card: DeckCard) => Promise<void>;
  addCardToCheatSheet: (item: DeckCardCheatSheetItem) => Promise<void>;
  addNoteToDeck: (note: DeckNote) => Promise<void>;
  addSubcategoryToCard: (cardId: string, subcategory: string) => Promise<void>;
  addSubcategoryToNote: (noteId: string, subcategory: string) => Promise<void>;
  createCategory: (name: string) => Promise<void>;
  createSubcategory: (name: string) => Promise<void>;
  deleteCategory: (category: string) => Promise<void>;
  deleteCheatSheetItem: (id: string) => Promise<void>;
  deleteDeck: () => Promise<void>;
  deleteSubcategory: (subcategory: string) => Promise<void>;
  removeCardFromDeck: (cardId: string) => Promise<void>;
  removeCategoryFromCard: (cardId: string) => Promise<void>;
  removeCategoryFromNote: (noteId: string) => Promise<void>;
  removeNoteFromDeck: (noteId: string) => Promise<void>;
  removeSubcategoryFromCard: (
    cardId: string,
    subcategory: string,
  ) => Promise<void>;
  removeSubcategoryFromNote: (
    noteId: string,
    subcategory: string,
  ) => Promise<void>;
  renameCategory: (oldName: string, newName: string) => Promise<void>;
  renameSubcategory: (oldName: string, newName: string) => Promise<void>;
  retireCard: (cardId: string) => Promise<void>;
  setCardCategory: (
    cardId: string,
    category: DeckCard['category'],
  ) => Promise<void>;
  setCardSubcategories: (
    cardId: string,
    subcategories: string[],
  ) => Promise<void>;
  setNoteCategory: (
    noteId: string,
    category: DeckNote['category'],
  ) => Promise<void>;
  setNoteSubcategories: (
    noteId: string,
    subcategories: string[],
  ) => Promise<void>;
  starCard: (cardId: string) => Promise<void>;
  starNote: (noteId: string) => Promise<void>;
  updateDeckCard: (cardId: string, data: DeckCardUpdateData) => Promise<void>;
  updateDeckNote: (noteId: string, data: DeckNoteUpdateData) => Promise<void>;
  unretireCard: (cardId: string) => Promise<void>;
  unstarCard: (cardId: string) => Promise<void>;
  unstarNote: (noteId: string) => Promise<void>;
  updateLastActivity: () => Promise<void>;
}

export const useDeckApi = (
  db: DB,
  deck: Deck,
  deckRef: DocumentReference,
): DeckApiHook => {
  return {
    addCardsToDeck: cards => addCardsToDeck(db, deckRef, cards),
    addCardToDeck: card => addCardToDeck(deckRef, card),
    addCardToCheatSheet: item => addCardToCheatSheet(deckRef, item),
    addNoteToDeck: note => addNoteToDeck(deckRef, note),
    addSubcategoryToCard: (cardId, subcategory) =>
      addSubcategoryToCard(db, deckRef, cardId, subcategory),
    addSubcategoryToNote: (noteId, subcategory) =>
      addSubcategoryToNote(db, deckRef, noteId, subcategory),
    createCategory: name => createCategory(db, deckRef, name),
    createSubcategory: name => createSubcategory(db, deckRef, name),
    deleteCategory: category => deleteCategory(db, deckRef, deck, category),
    deleteCheatSheetItem: id => deleteCheatSheetItem(db, deckRef, id),
    deleteDeck: () => deleteDeck(deckRef),
    deleteSubcategory: subcategory =>
      deleteSubcategory(db, deckRef, deck, subcategory),
    removeCardFromDeck: cardId => removeCardFromDeck(db, deckRef, cardId),
    removeCategoryFromCard: cardId => removeCategoryFromCard(deckRef, cardId),
    removeCategoryFromNote: noteId => removeCategoryFromNote(deckRef, noteId),
    removeNoteFromDeck: noteId => removeNoteFromDeck(db, deckRef, noteId),
    removeSubcategoryFromCard: (cardId, subcategory) =>
      removeSubcategoryFromCard(db, deckRef, cardId, subcategory),
    removeSubcategoryFromNote: (noteId, subcategory) =>
      removeSubcategoryFromNote(db, deckRef, noteId, subcategory),
    renameCategory: (oldName, newName) =>
      renameCategory(deckRef, deck, oldName, newName),
    renameSubcategory: (oldName, newName) =>
      renameSubcategory(deckRef, deck, oldName, newName),
    retireCard: cardId => retireCard(deckRef, cardId),
    setCardCategory: (cardId, category) =>
      setCardCategory(deckRef, cardId, category),
    setCardSubcategories: (cardId, subcategories) =>
      setCardSubcategories(deckRef, cardId, subcategories),
    setNoteCategory: (noteId, category) =>
      setNoteCategory(deckRef, noteId, category),
    setNoteSubcategories: (noteId, subcategories) =>
      setNoteSubcategories(deckRef, noteId, subcategories),
    starCard: cardId => starCard(deckRef, cardId),
    starNote: noteId => starNote(deckRef, noteId),
    unretireCard: cardId => unretireCard(db, deckRef, cardId),
    unstarCard: cardId => unstarCard(db, deckRef, cardId),
    unstarNote: noteId => unstarNote(db, deckRef, noteId),
    updateDeckCard: (cardId, data) => updateDeckCard(deckRef, cardId, data),
    updateDeckNote: (noteId, data) => updateDeckNote(deckRef, noteId, data),
    updateLastActivity: () => updateLastActivity(deckRef),
  };
};
