import React from 'react';
import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from '@material-ui/core';

export type ListItemTextProps = MuiListItemTextProps;

export const ListItemText: React.FC<ListItemTextProps> = ({ ...other }) => {
  return <MuiListItemText {...other} />;
};
