import { CardStatus, ProficiencyKey } from '../../Review.types';
import { PROFICIENCY_KEYS } from '../../constants';
import { calculateProficiencyPercentage } from '../calculateProficiencyPercentage';

const {
  NOT_STARTED,
  NEEDS_WORK,
  IN_PROGRESS,
  MASTERED,
  RETIRED,
} = PROFICIENCY_KEYS;

interface ProgressData {
  proficiency?: number;
  notStarted?: boolean;
  satus?: CardStatus;
  retired?: boolean;
}

export function getProficiencyKey(data: ProgressData): ProficiencyKey {
  const { proficiency, notStarted, satus, retired } = data;
  if (notStarted || satus === 'new' || typeof proficiency === 'undefined') {
    return NOT_STARTED;
  }

  if (retired) {
    return RETIRED;
  }

  const score = calculateProficiencyPercentage(proficiency);

  if (score < 26) {
    return NEEDS_WORK;
  }
  if (score < 75) {
    return IN_PROGRESS;
  }
  return MASTERED;
}
