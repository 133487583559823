import React from 'react';
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core';

export type TypographyProps = MuiTypographyProps;

export const Typography: React.FC<TypographyProps> = ({ ...other }) => {
  return <MuiTypography {...other} />;
};
