import { WebViewWindow } from '../../types';

declare let window: WebViewWindow;

export function removeWebViewMessageListener(
  callback: (message: string) => void,
): void {
  if (!window.webViewEventListeners) {
    window.webViewEventListeners = [];
  }

  window.webViewEventListeners.filter(listener => listener !== callback);
}
