import { useFirebase } from '@21st-night/utils';
import { useCallback } from 'react';
import { updateNote, deleteNote, addCardLink, removeCardLink } from '../../api';

interface NoteHook {
  addCardLink: (cardId: string) => Promise<void>;
  delete: () => Promise<void>;
  removeCardLink: (cardId: string) => Promise<void>;
  updateContent: (content: string) => Promise<void>;
}

export const useNote = (noteId: string): NoteHook => {
  const { db } = useFirebase();

  const updateContent = useCallback(
    (content: string) => {
      return updateNote(db, noteId, { content });
    },
    [db, noteId],
  );

  const delNote = useCallback(() => {
    return deleteNote(db, noteId);
  }, [db, noteId]);

  return {
    addCardLink: (cardId: string) => addCardLink(db, noteId, cardId),
    delete: delNote,
    removeCardLink: (cardId: string) => removeCardLink(db, noteId, cardId),
    updateContent,
  };
};
