import React from 'react';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export interface DeckSubcategoryTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  subcategory: string;
  readonly?: boolean;
}

export const DeckSubcategoryTag: React.FC<DeckSubcategoryTagProps> = ({
  subcategory,
  readonly,
  ...other
}) => {
  const deck = useDeck();

  return (
    <CategoryTag
      variant="subcategory"
      label={subcategory}
      onClick={
        !readonly ? () => deck.toggleSubcategoryFilter(subcategory) : undefined
      }
      {...other}
    />
  );
};
