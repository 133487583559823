import pluralize from 'pluralize';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { generateRichTextDocument } from '@21st-night/editor';
import { StudyPlanTask, StudyPlanItem, StudyPlanDay } from '../../types';
import { daysOfTheWeekBetween } from '../daysOfTheWeekBetween';

/**
 * Generates study plan items for a given task.
 */
export function generateStudyPlanItems(
  task: StudyPlanTask,
  days: StudyPlanDay[],
): StudyPlanItem[] {
  const daysOfTheWeek = daysOfTheWeekBetween(
    task.startDate,
    task.endDate,
    task.days,
  );
  // Calculate whole tasks per day
  const tasksPerDay = Math.floor(task.count / (daysOfTheWeek.length || 1));
  // Calculate remaining tasks
  const remainder = task.count % (daysOfTheWeek.length || 1);

  // Tasks title is in the form:
  // Action count object[s] [from source]
  const capitalizedAction =
    task.action.charAt(0).toUpperCase() + task.action.slice(1);
  const source = task.source ? ` from ${task.source}` : '';
  const items: StudyPlanItem[] = [];

  let activeDays = daysOfTheWeek;

  if (task.count < activeDays.length) {
    const dayTaskCount: { [key: number]: number } = {};

    const period = daysOfTheWeek.length / task.count || 1;
    for (let i = 0; i < task.count; i += 1) {
      const dayIndex = Math.floor(i * period);
      if (dayTaskCount[dayIndex]) {
        dayTaskCount[dayIndex] += 1;
      } else {
        dayTaskCount[dayIndex] = 1;
      }
    }

    activeDays = Object.keys(dayTaskCount).map(
      dayIndex => daysOfTheWeek[parseInt(dayIndex)],
    );
  }

  activeDays.forEach((date, index) => {
    // Remainder tasks are distributed over to the first items
    const count = index + 1 <= remainder ? tasksPerDay + 1 : tasksPerDay;
    const day = days.find(d => dayjs(date).isSame(d.date, 'day'));

    if (!day) {
      throw new Error('Provided days do not correspond to task dates.');
    }

    if (count > 0) {
      const object = pluralize(task.object, count);
      const subItems: string[] = [];
      const itemId = uuid();

      task.subtasks.forEach(subtask => {
        const id = uuid();

        subItems.push(id);

        items.push({
          title: JSON.stringify(generateRichTextDocument([subtask])),
          date,
          id,
          parent: itemId,
          createdAt: new Date(),
          parentType: 'item',
          subItems: [],
          done: false,
          donePreviously: false,
        });
      });

      items.push({
        subItems,
        title: JSON.stringify(
          generateRichTextDocument([
            `${capitalizedAction} ${count} ${object}${source}`,
          ]),
        ),
        parentType: 'day',
        parent: day.id,
        date,
        id: itemId,
        task: task.id,
        createdAt: new Date(),
        done: false,
        donePreviously: false,
      });
    }
  });

  return items;
}
