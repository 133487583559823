import React, { useMemo, useState } from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { makeStyles, useTheme, cn } from '@21st-night/styles';
import { Statistic } from '../Statistic';

type DataItem = Record<string, number | string>;

export type SimpleLineChartDataItem = DataItem;

export interface SimpleLineChartProps
  extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  data: DataItem[];
  dataKey: keyof DataItem;
  valueFormatter?: (value: number) => number | string;
  totalFormatter?: (data: DataItem[]) => number;
  syncId?: string;
  mini?: boolean;
}

interface RenderDotProps {
  payload: DataItem;
  dataKey: keyof DataItem;
  dataDisplayKey?: keyof DataItem;
  cx: number;
  cy: number;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export const SimpleLineChart: React.FC<SimpleLineChartProps> = ({
  label,
  data,
  dataKey,
  syncId,
  className,
  valueFormatter,
  totalFormatter,
  mini,
  ...other
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const total = useMemo(
    () =>
      totalFormatter
        ? totalFormatter(data)
        : data.reduce((tot, item) => tot + (item[dataKey] as number), 0),
    [data, dataKey, totalFormatter],
  );
  const [value, setValue] = useState<number>(total);
  const [valueLabel, setValueLabel] = useState<string | number>('');

  function renderDot(props: RenderDotProps) {
    setValue(props.payload[dataKey] as number);

    if (props.payload.label) {
      setValueLabel(props.payload.label);
    }
    return (
      <svg height="100%" width="100%">
        <circle
          cx={props.cx}
          cy={props.cy}
          r="4"
          strokeWidth="0"
          fill="#2D7EF6"
        />
      </svg>
    );
  }

  function handleMouseLeave() {
    setValue(total);
    setValueLabel('');
  }

  return (
    <div className={cn(classes.root, className)} {...other}>
      <Statistic
        mini={mini}
        title={label}
        value={valueFormatter ? valueFormatter(value) : value}
        label={valueLabel}
      />
      <ResponsiveContainer width="100%" height={mini ? 60 : 160}>
        <AreaChart
          syncId={syncId}
          data={data}
          margin={{ top: mini ? 20 : 40, left: 4, right: 4 }}
          onMouseLeave={handleMouseLeave}
        >
          <Tooltip
            contentStyle={{ display: 'none' }}
            cursor={{
              strokeWidth: 1,
              strokeDasharray: '1',
              stroke: '#b6b6b6',
            }}
          />
          <CartesianGrid horizontal={false} stroke="#EBEBEB" />
          <Area
            dataKey={dataKey}
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            fill={theme.palette.primary.main}
            fillOpacity={0.25}
            activeDot={renderDot}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
