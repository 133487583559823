import { DB, DocumentReference } from '@21st-night/utils';
import { Deck } from '../../Deck.types';
import { updateDeck } from '../updateDeck';

export function deleteSubcategory(
  db: DB,
  deckRef: DocumentReference,
  deck: Deck,
  subcategory: string,
): Promise<void> {
  // Find cards with this subcategory and create a field update
  // for each one which will remove it from the subcategories field.
  const cardUpdates = Object.keys(deck.cards).reduce(
    (updates, cardId) =>
      deck.cards[cardId].subcategories.includes(subcategory)
        ? {
            ...updates,
            [cardId]: {
              subcategories: db.arrayRemove(subcategory),
            },
          }
        : updates,
    {},
  );

  // Find notes with this subcategory and create a field update
  // for each one which will remove it from the subcategories field.
  const noteUpdates = Object.keys(deck.notes).reduce(
    (updates, noteId) =>
      deck.notes[noteId].subcategories.includes(subcategory)
        ? {
            ...updates,
            [noteId]: {
              subcategories: db.arrayRemove(subcategory),
            },
          }
        : updates,
    {},
  );

  // Remove category from deck and cards
  return updateDeck(deckRef, {
    cards: cardUpdates,
    notes: noteUpdates,
    subcategories: db.arrayRemove(subcategory),
  });
}
