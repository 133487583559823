import React, { useEffect, useMemo, useState } from 'react';
import { Card } from '@21st-night/cards';
import {
  MobileEditor,
  MobileEditorToolbar,
  ToolbarSpacer,
  EditorProps,
  EditorDocument,
  stripDocumentMetadata,
  generateRichTextDocument,
  addDocumentMetadata,
  isElementOfType,
  generateEditorElement,
} from '@21st-night/editor-web';
import { Element, Node } from 'slate';
import { createCardEditorPlugin } from '../editor-plugins/plugin-card-editor';
import {
  createClozeDeletionPlugin,
  EditorClozeDeletionButton,
} from '../editor-plugins/plugin-cloze-deletion';

export interface CardEditorMobileProps
  extends Pick<EditorProps, 'plugins' | 'imageUrl' | 'style'> {
  type?: Card['type'];
  question: EditorDocument;
  answer: EditorDocument;
  explanation?: EditorDocument;
  summary?: EditorDocument;
  onQuestionChange: (value: EditorDocument) => void;
  onAnswerChange: (value: EditorDocument) => void;
  onExplanationChange: (value: EditorDocument) => void;
  onSummaryChange: (value: EditorDocument) => void;
}

const ClozeDeletionPlugin = createClozeDeletionPlugin({ mobile: true });

export const CardEditorMobile: React.FC<CardEditorMobileProps> = ({
  imageUrl,
  plugins = [],
  question,
  answer,
  explanation,
  summary,
  type,
  onQuestionChange,
  onAnswerChange,
  onExplanationChange,
  onSummaryChange,
  ...other
}) => {
  const CardFormPlugin = useMemo(
    () => createCardEditorPlugin({ variant: type, autoFocus: false }),
    [],
  );
  const [values] = useState([
    stripDocumentMetadata(question),
    stripDocumentMetadata(answer),
    stripDocumentMetadata(explanation || generateRichTextDocument()),
    stripDocumentMetadata(summary || generateRichTextDocument()),
  ]);
  const [value, setValue] = useState(([
    ({
      type: 'card-question',
      children: (values[0][0] as unknown) as Node[],
    } as unknown) as Node,
    ({
      type: 'card-answer',
      children: (values[1][0] as unknown) as Node[],
    } as unknown) as Node,
    ({
      type: 'card-explanation',
      children: (values[2][0] as unknown) as Node[],
    } as unknown) as Node,
    ({
      type: 'card-summary',
      children: (values[3][0] as unknown) as Node[],
    } as unknown) as Node,
  ] as unknown) as EditorDocument);

  useEffect(() => {
    if (value[0]) {
      onQuestionChange(
        addDocumentMetadata(value[0].children as EditorDocument, values[0][1]),
      );
    }
    if (value[1]) {
      const descendants = Node.descendants({
        children: value[0].children,
      } as Element);
      const clozes = Array.from(descendants)
        .filter(node => isElementOfType(node[0], 'cloze-deletion'))
        .map(entry => entry[0]);

      if (clozes.length) {
        const clozesText = clozes.reduce((text, node) => {
          return `${text}${text.length ? ', ' : ''}${Node.string(node)}`;
        }, '');

        if (Node.string(value[1]) !== clozesText) {
          setValue(
            val =>
              [
                val[0],
                {
                  ...val[1],
                  children: [
                    generateEditorElement('paragraph', {
                      text: clozesText,
                    }),
                  ],
                },
                val[2],
                val[3],
              ] as EditorDocument,
          );
        }

        onAnswerChange(
          addDocumentMetadata(
            [
              generateEditorElement('paragraph', {
                text: clozesText,
              }),
            ],
            values[1][1],
          ),
        );
      } else {
        onAnswerChange(
          addDocumentMetadata(
            value[1].children as EditorDocument,
            values[1][1],
          ),
        );
      }
    }
    if (value[2]) {
      onExplanationChange(
        addDocumentMetadata(value[2].children as EditorDocument, values[2][1]),
      );
    }
    if (value[3]) {
      onSummaryChange(
        addDocumentMetadata(value[3].children as EditorDocument, values[3][1]),
      );
    }
  }, [value]);

  return (
    <MobileEditor
      autoFocus={false}
      value={value}
      imageUrl={imageUrl}
      onChange={setValue}
      {...other}
      plugins={[CardFormPlugin, ClozeDeletionPlugin, ...plugins]}
    >
      <MobileEditorToolbar>
        <span>
          <EditorClozeDeletionButton mobile />
          <ToolbarSpacer />
        </span>
      </MobileEditorToolbar>
    </MobileEditor>
  );
};
