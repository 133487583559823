import dayjs from 'dayjs';

/**
 * Creates an array of date objects for
 * each day between two dates, both of
 * which are included.
 *
 * @param start The starting date.
 * @param end   The ending date.
 */
export function daysBetween(start: Date, end: Date): Date[] {
  const days = [start];

  while (!dayjs(days[days.length - 1]).isSame(end, 'day')) {
    const day = dayjs(days[days.length - 1])
      .add(1, 'day')
      .toDate();
    day.setHours(0, 0, 0, 0);
    days.push(day);
  }

  return days;
}
