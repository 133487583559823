import React, { useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles, createStyles } from '@21st-night/styles';
import { MenuItem, Select, Typography } from '@21st-night/ui';
import {
  StudyPlanTemplate,
  StudyPlanTaskTemplate,
  StudyPlanTask,
  StudyPlanItem,
  generateStudyPlanItems,
  generateStudyPlanDays,
  addStudyPlanItemsToDays,
} from '@21st-night/study-plan';

import { StudyPlanPreview } from '../StudyPlanPreview';

export interface StudyPlanTemplatePreviewProps {
  tasks: StudyPlanTaskTemplate[];
  template: StudyPlanTemplate;
}

const useStyles = makeStyles(theme =>
  createStyles({
    startWeekSelectContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    startWeekSelect: {
      marginLeft: theme.spacing(1),
    },
  }),
);

export const StudyPlanTemplatePreview: React.FC<StudyPlanTemplatePreviewProps> = ({
  tasks: taskTemplates,
  template,
}) => {
  const classes = useStyles();
  const [startWeekOn, setStartWeekOn] = useState(0);
  const endDate = dayjs(new Date()).add(template.length, 'day').toDate();

  function handleChnageStartWeekOn(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) {
    setStartWeekOn(event.target.value as number);
  }

  const tasks: StudyPlanTask[] = taskTemplates.map((taskTemplate, index) => ({
    id: `task-${index}`,
    createdAt: new Date(),
    action: taskTemplate.action || '',
    count: taskTemplate.count || 0,
    object: taskTemplate.object || '',
    source: taskTemplate.source,
    days: taskTemplate.days,
    subtasks: taskTemplate.subtasks || [],
    startDate: dayjs(new Date())
      .add(taskTemplate.start - 1, 'day')
      .toDate(),
    endDate: dayjs(new Date())
      .add(taskTemplate.end - 1, 'day')
      .toDate(),
  }));
  let days = generateStudyPlanDays(new Date(), endDate);

  const items: StudyPlanItem[] = tasks.reduce((itms: StudyPlanItem[], task) => {
    return [...itms, ...generateStudyPlanItems(task, days)];
  }, []);

  days = addStudyPlanItemsToDays(days, items);

  return (
    <>
      <div className={classes.startWeekSelectContainer}>
        <Typography>Start week on </Typography>
        <Select
          value={startWeekOn}
          className={classes.startWeekSelect}
          onChange={handleChnageStartWeekOn}
        >
          <MenuItem value={0}>Sunday</MenuItem>
          <MenuItem value={1}>Monday</MenuItem>
          <MenuItem value={2}>Tuesday</MenuItem>
          <MenuItem value={3}>Wednesday</MenuItem>
          <MenuItem value={4}>Thursday</MenuItem>
          <MenuItem value={4}>Friday</MenuItem>
          <MenuItem value={5}>Saturday</MenuItem>
          <MenuItem value={6}>Sunday</MenuItem>
        </Select>
      </div>
      <StudyPlanPreview
        tasks={tasks}
        plan={{ ...template, endDate, startDate: new Date(), startWeekOn }}
      />
    </>
  );
};
