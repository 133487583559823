import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { TextField, AutocompleteRenderInputParams } from '@21st-night/ui';

export interface DeckFilterBarInputProps extends AutocompleteRenderInputParams {
  showPlaceholder: boolean;
  placeholder?: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    focused: {
      border: '1px solid transparent',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const DeckFilterBarInput = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  DeckFilterBarInputProps
>(
  (
    {
      showPlaceholder,
      placeholder = 'Filter cards and notes',
      InputProps,
      ...other
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <TextField
        ref={ref}
        size="large"
        variant="standard"
        InputProps={{
          classes: {
            focused: classes.focused,
          },
          ...InputProps,
        }}
        {...other}
        placeholder={showPlaceholder ? placeholder : ''}
        // InputProps={{
        //   ...params.InputProps,
        //   className: classes.input,
        // }}
      />
    );
  },
);
