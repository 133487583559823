import { DeckCard } from '@21st-night/deck';
import { RatingEvent } from '@21st-night/review';
import dayjs, { Dayjs } from 'dayjs';
import { getDayRatings } from '../getDayRatings';
import { getTotalStudyTime } from '../getTotalStudyTime';

export interface DateRating {
  date: Date;
  ratings: RatingEvent[];
  added: number;
  duration: number;
}

export function generateDateRangeAnalyticsData(
  startDate: Date,
  cards: DeckCard[],
): DateRating[] {
  const dates: Dayjs[] = [];
  const dateRatings: DateRating[] = [];
  let date = dayjs(startDate);

  while (!dayjs(date).isAfter(new Date(), 'date')) {
    dates.push(date);
    const ratings = getDayRatings(date.toDate(), cards);

    dateRatings.push({
      ratings,
      date: date.toDate(),
      duration: getTotalStudyTime(ratings),
      added: cards.filter(card => dayjs(card.createdAt).isSame(date, 'date'))
        .length,
    });
    date = dayjs(date).add(1, 'day');
  }

  return dateRatings;
}
