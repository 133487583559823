import React from 'react';
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core';

export type SwitchProps = MuiSwitchProps;

export const Switch: React.FC<SwitchProps> = ({ ...other }) => {
  return <MuiSwitch {...other} />;
};
