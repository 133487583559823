import React from 'react';
import { RenderElementProps } from '@braindrop-editor/core';
import {
  isDocumentEmpty,
  EditorDocument,
  CardAnswerElement,
} from '@21st-night/editor-web';
import { cn, makeStyles } from '@21st-night/styles';

interface RenderAnswerElementProps
  extends RenderElementProps<CardAnswerElement> {
  minHeight?: number;
}

const useStyles = makeStyles(theme => ({
  root: {
    '&::before': {
      content: 'attr(data-pseudo-content)',
      ...theme.typography.subtitle1,
      display: 'block',
      color: '#616161',
      fontWeight: 'bold',
      userSelect: 'none',
      pointerEvents: 'none',
    },
  },
  error: {
    '&::before': {
      color: theme.palette.error.main,
    },
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  contentInner: {
    position: 'relative',
  },
  placeholder: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    color: '#BDBDBD',
    cursor: 'text',
    top: 5,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  editable: {
    position: 'relative',
    zIndex: 10,
  },
}));

export const ElementAnswer: React.FC<RenderAnswerElementProps> = ({
  attributes,
  children,
  element,
  minHeight = 30,
}) => {
  const classes = useStyles();
  const hasContent = !isDocumentEmpty(element.children as EditorDocument, true);
  const { error } = element;

  return (
    <div
      {...attributes}
      className={cn(classes.root, element.error && classes.error)}
      data-pseudo-content={`Correct answer* ${error ? `- ${error}` : ''}`}
    >
      <div className={classes.content} style={{ minHeight }}>
        <div className={classes.contentInner}>
          {!hasContent && (
            <div contentEditable={false} className={classes.placeholder}>
              Write the correct answer here
            </div>
          )}
          <div className={classes.editable}>{children}</div>
        </div>
      </div>
    </div>
  );
};
