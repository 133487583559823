import {
  toDate,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@21st-night/utils';
import {
  Deck,
  DeckCard,
  DeckDocument,
  DeckDocumentCard,
  DeckDocumentNote,
  DeckNote,
  OfficialDeck,
  PremiumDeck,
} from '../../Deck.types';

type Doc = DocumentSnapshot | QueryDocumentSnapshot;

export function deserializeDeckDocumentCard(
  cardData: DeckDocumentCard,
  id: string,
): DeckCard {
  const { nextReview, lastReview, type, attempts, ...rest } = cardData;
  const card: DeckCard = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    subcategories: [],
    category: null,
    ...rest,
    type: type || 'error-log',
    id,
    createdAt: toDate(cardData.createdAt),
    attempts: attempts
      ? attempts.map(attempt => ({
          ...attempt,
          createdAt: toDate(attempt.createdAt),
        }))
      : [],
  };

  if (nextReview) {
    card.nextReview = toDate(nextReview);
  }

  if (lastReview) {
    card.lastReview = toDate(lastReview);
  }

  return card;
}

export function deserializeDeckDocumentNote(
  noteData: DeckDocumentNote,
  id: string,
): DeckNote {
  const note: DeckNote = {
    ...noteData,
    id,
    createdAt: toDate(noteData.createdAt),
  };

  return note;
}

export function deserializeDeckDocument(
  doc: Doc,
  overrides?: Partial<Deck>,
): Deck;
export function deserializeDeckDocument(
  doc: Doc,
  overrides?: Partial<OfficialDeck>,
): OfficialDeck;
export function deserializeDeckDocument(
  doc: Doc,
  overrides?: Partial<PremiumDeck>,
): PremiumDeck;
export function deserializeDeckDocument(
  doc: Doc,
  overrides: Partial<Deck> = {},
): Deck {
  const deck = doc.data() as DeckDocument;

  return {
    ...deck,
    createdAt: toDate(deck.createdAt),
    cards: Object.keys(deck.cards || {}).reduce(
      (cards, cardId) => ({
        ...cards,
        [cardId]: deserializeDeckDocumentCard(deck.cards[cardId], cardId),
      }),
      {},
    ),
    notes: Object.keys(deck.notes || {}).reduce(
      (notes, noteId) => ({
        ...notes,
        [noteId]: deserializeDeckDocumentNote(deck.notes[noteId], noteId),
      }),
      {},
    ),
    // Legacy decks may be missing these
    type: deck.type || 'student',
    id: doc.id,
    ...overrides,
  };
}
