import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  deserializeDocument,
  RichText,
  truncateDocument,
} from '@21st-night/editor-web';

import {
  ListItem,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
} from '@21st-night/ui';
import { Note } from '@21st-night/notes';

export interface NoteListItemProps extends ListItemProps {
  note: Note;
  imageUrl: string;
  secondary?: ListItemTextProps['secondary'];
  ListItemTextProps?: ListItemTextProps;
  maxChars?: number;
  maxElements?: number;
}

export const useStyles = makeStyles(() =>
  createStyles({
    primary: {
      maxHeight: 100,
      overflow: 'hidden',
      textOverflow: 'elipsis',
    },
  }),
);

export const NoteListItem: React.FC<NoteListItemProps> = ({
  note,
  imageUrl,
  secondary,
  ListItemTextProps,
  maxChars = 200,
  maxElements = 2,
  ...other
}) => {
  const classes = useStyles();
  return (
    <ListItem {...other}>
      <ListItemText
        disableTypography
        classes={{ primary: classes.primary }}
        secondary={secondary}
        {...ListItemTextProps}
      >
        <RichText
          imageUrl={imageUrl}
          document={truncateDocument(deserializeDocument(note.content), {
            maxChars,
            maxElements,
            stripMedia: true,
          })}
        />
      </ListItemText>
    </ListItem>
  );
};
