import React from 'react';
import { Tabs as UiTabs, TabsProps as UiTabsProps } from '@21st-night/ui';
import { makeStyles } from '@21st-night/styles';

export type TabsProps = UiTabsProps;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 40,
    boxShadow: `inset 0px -1px 0px 0px ${theme.palette.grey[200]}`,
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
  },
}));

export const Tabs: React.FC<TabsProps> = ({ ...other }) => {
  const classes = useStyles();

  return <UiTabs classes={classes} {...other} />;
};
