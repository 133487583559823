import { DocumentReference, SerializedUpdateData } from '@21st-night/utils';

import { DeckUpdateData } from '../../Deck.types';
import { validateDeckUpdate } from '../validators';
import { serializeDeckCardsUpdateData } from '../serializeDeckCardsUpdateData';
import { serializeDeckNotesUpdateData } from '../serializeDeckNotesUpdateData';

export function updateDeck(
  deckRef: DocumentReference,
  data: DeckUpdateData,
): Promise<void> {
  validateDeckUpdate(data);

  let serializedData = { ...data } as SerializedUpdateData;

  if (data.cards) {
    delete serializedData.cards;
    serializedData = {
      ...serializeDeckCardsUpdateData(data.cards),
      ...serializedData,
    };
  }

  if (data.notes) {
    delete serializedData.notes;
    serializedData = {
      ...serializeDeckNotesUpdateData(data.notes),
      ...serializedData,
    };
  }

  return deckRef.update(serializedData);
}
