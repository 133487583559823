import React from 'react';
import { RichTextNode } from '@21st-night/editor';

export interface RichTextLeafProps
  extends React.HTMLAttributes<HTMLDivElement> {
  leaf: RichTextNode;
}

export const RichTextLeaf: React.FC<RichTextLeafProps> = ({ leaf }) => {
  let children = leaf.text as React.ReactNode;

  if (leaf.b) {
    children = <strong>{children}</strong>;
  }

  if (leaf.i) {
    children = <em>{children}</em>;
  }

  if (leaf.u) {
    children = <u>{children}</u>;
  }

  if (leaf.s) {
    children = <s>{children}</s>;
  }

  return <span>{children}</span>;
};
