import React, { useCallback, useMemo, useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Paper, Grid, Typography, Select, MenuItem } from '@21st-night/ui';
import { DeckCard } from '@21st-night/deck';
import {
  getDayRatings,
  getCardsCreatedOnDay,
  getTotalStudyTime,
  secondsToHumanReadable,
  calculateRetentionRate,
  groupCardsByCategory,
  generateDateRangeAnalyticsData,
} from '@21st-night/analytics';
import { Statistic } from '../Statistic';
import { CategoryAnalytics } from '../CategoryAnalytics';
import dayjs from 'dayjs';
import { SimpleLineChart } from '../SimpleLineChart';
import { StudyTimeAreaChart } from '../StudyTimeAreaChart';
import { RatingsAreaChart } from '../RatingsAreaChart';
import { RetentionRateAreaChart } from '../RetentionRateAreaChart';

export interface AnalyticsViewProps {
  cards: DeckCard[];
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    section: {
      marginBottom: theme.spacing(5),
    },
    sectionTitle: {
      marginBottom: theme.spacing(2),
    },
    historyTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    historyTitle: {
      marginRight: theme.spacing(2),
    },
    card: {
      borderRadius: theme.shape.borderRadius,
    },
    todayGridItem1: {
      [theme.breakpoints.only('xs')]: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    todayGridItem2: {
      [theme.breakpoints.only('xs')]: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      [theme.breakpoints.up('sm')]: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    todayGridItem3: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    todayGridItem4: {
      [theme.breakpoints.up('sm')]: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    gridItemContent: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  }),
);

export const AnalyticsView: React.FC<AnalyticsViewProps> = ({
  cards,
  ...other
}) => {
  const classes = useStyles();
  const [rangeDays, setRangeDays] = useState(7);
  const rangeStart = useMemo(
    () =>
      dayjs(new Date())
        .subtract(rangeDays - 1, 'day')
        .toDate(),
    [rangeDays],
  );
  const todaysRatings = useMemo(() => getDayRatings(new Date(), cards), [
    cards,
  ]);
  const todaysCreated = useMemo(() => getCardsCreatedOnDay(new Date(), cards), [
    cards,
  ]);
  const categories = useMemo(() => groupCardsByCategory(cards), [cards]);
  const range = useMemo(
    () => generateDateRangeAnalyticsData(rangeStart, cards),
    [rangeStart, cards],
  );
  const rangeChartsData = useMemo(
    () =>
      range.map(day => {
        const retention = calculateRetentionRate(day.ratings);

        return {
          label: dayjs(day.date).format('MMM D'),
          reviewed: day.ratings.length,
          added: day.added,
          duration: day.duration,
          retention,
          retentionDisplay: `${day.ratings.length ? retention : '--'}%`,
        };
      }),
    [range],
  );

  const ratingCounts = useMemo(() => {
    return range.map(day => ({
      date: day.date,
      retire: day.ratings.filter(({ rating }) => rating === 'retire').length,
      wrong: day.ratings.filter(({ rating }) => rating === 'wrong').length,
      'more-work': day.ratings.filter(({ rating }) => rating === 'more-work')
        .length,
      correct: day.ratings.filter(({ rating }) => rating === 'correct').length,
    }));
  }, [range]);

  const handleRangeDaysChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setRangeDays(event.target.value as number);
    },
    [],
  );

  return (
    <div className={cn(classes.root)} {...other}>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Today
        </Typography>
        <Paper elevation={2} className={classes.card}>
          <Grid container>
            <Grid item xs={6} sm={3} className={classes.todayGridItem1}>
              <div className={classes.gridItemContent}>
                <Statistic
                  title="Cards reviewed"
                  value={todaysRatings.length}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.todayGridItem2}>
              <div className={classes.gridItemContent}>
                <Statistic title="Cards added" value={todaysCreated.length} />
              </div>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.todayGridItem3}>
              <div className={classes.gridItemContent}>
                <Statistic
                  title="Study time"
                  value={secondsToHumanReadable(
                    getTotalStudyTime(todaysRatings),
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.todayGridItem4}>
              <div className={classes.gridItemContent}>
                <Statistic
                  title="Retention rate"
                  value={`${calculateRetentionRate(todaysRatings)}%`}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className={classes.section}>
        <div className={classes.historyTitleContainer}>
          <Typography variant="h5" className={classes.historyTitle}>
            History
          </Typography>
          <Select value={rangeDays} onChange={handleRangeDaysChange}>
            <MenuItem value={7}>Last 7 days</MenuItem>
            <MenuItem value={28}>Last 4 weeks</MenuItem>
            {/* <MenuItem>Last 3 months</MenuItem> */}
            {/* <MenuItem>Last 6 months</MenuItem> */}
            {/* <MenuItem>Last 12 months</MenuItem> */}
          </Select>
        </div>
        <Paper elevation={2} className={classes.card}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <SimpleLineChart
                label="Cards reviewed"
                data={rangeChartsData}
                dataKey="reviewed"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SimpleLineChart
                label="Cards added"
                data={rangeChartsData}
                dataKey="added"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StudyTimeAreaChart data={rangeChartsData} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RetentionRateAreaChart data={rangeChartsData} />
            </Grid>
            <Grid item xs={12}>
              <RatingsAreaChart data={ratingCounts} />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Categories
        </Typography>
        {Object.keys(categories).filter(
          category => category !== '__uncategorized__',
        ).length === 0 && (
          <Typography>
            Add categories to your cards to see detailed analytics for each
            category.
          </Typography>
        )}
        <Grid container spacing={2}>
          {Object.keys(categories)
            .filter(category => category !== '__uncategorized__')
            .map(cateogory => (
              <Grid item key={cateogory} xs={12} sm={6}>
                <CategoryAnalytics
                  rangeStart={rangeStart}
                  category={cateogory}
                  cards={categories[cateogory]}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
};
