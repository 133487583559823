import dayjs from 'dayjs';
import { minutesToDays } from '@21st-night/utils';
import {
  FlashcardReviewProgress,
  ReviewRating,
  RatingEvent,
} from '../../Review.types';
import {
  NEW_STEPS,
  GRADUATING_INTERVAL,
  EASY_INTERVAL,
  EASY_BONUS,
  INTERVAL_MODIFIER,
  MAXIMUM_INTERVAL,
  LAPSES_STEPS,
  NEW_INTERVAL,
  MINIMUM_INTERVAL,
} from '../../review-constants';
import { generateFlashcardReviewProgress } from '../generateFlashcardReviewProgress';

export function rateFlashcard(
  currentCardReviewProgress: FlashcardReviewProgress | null,
  rating: ReviewRating,
  duration = 0,
): FlashcardReviewProgress {
  // Override generated review progress data to support legacy progress data
  const cardProgress = generateFlashcardReviewProgress(
    currentCardReviewProgress || { firstReview: new Date() },
  );

  if (cardProgress.status === 'learning' || cardProgress.status === 'new') {
    if (rating === 'wrong') {
      cardProgress.stepsIndex = 0;
      cardProgress.interval = minutesToDays(NEW_STEPS[cardProgress.stepsIndex]);
    } else if (rating === 'more-work') {
      cardProgress.stepsIndex += 1;
      if (cardProgress.stepsIndex < NEW_STEPS.length) {
        cardProgress.interval = minutesToDays(
          NEW_STEPS[cardProgress.stepsIndex],
        );
      } else {
        // We have graduated
        cardProgress.status = 'learned';
        cardProgress.interval = GRADUATING_INTERVAL;
      }
    } else if (rating === 'correct') {
      cardProgress.status = 'learned';
      cardProgress.interval = EASY_INTERVAL;
    } else if (rating !== 'retire') {
      throw new Error('Invalid review rating');
    }
  } else if (cardProgress.status === 'learned') {
    if (rating === 'wrong') {
      cardProgress.status = 'relearning';
      cardProgress.stepsIndex = 0;
      cardProgress.easeFactor = Math.max(1.3, cardProgress.easeFactor - 0.2);
      // the anki manual says "the current interval is multiplied by the
      // value of new interval", but I have no idea what the "new
      // interval" is
      cardProgress.interval = minutesToDays(LAPSES_STEPS[0]);
    } else if (rating === 'more-work') {
      cardProgress.easeFactor = Math.max(1.3, cardProgress.easeFactor - 0.15);
      cardProgress.interval = cardProgress.interval * 1.2 * INTERVAL_MODIFIER;
      cardProgress.interval = Math.min(MAXIMUM_INTERVAL, cardProgress.interval);
    } else if (rating === 'correct') {
      cardProgress.easeFactor += 0.15;
      cardProgress.interval =
        cardProgress.interval *
        cardProgress.easeFactor *
        INTERVAL_MODIFIER *
        EASY_BONUS;
      cardProgress.interval = Math.min(MAXIMUM_INTERVAL, cardProgress.interval);
    } else if (rating !== 'retire') {
      throw new Error('Invalid review rating');
    }
  } else if (cardProgress.status === 'relearning') {
    if (rating === 'wrong') {
      cardProgress.stepsIndex = 0;
      cardProgress.interval = minutesToDays(LAPSES_STEPS[0]);
    } else if (rating === 'more-work') {
      cardProgress.stepsIndex += 1;
      if (cardProgress.stepsIndex < LAPSES_STEPS.length) {
        cardProgress.interval = minutesToDays(
          LAPSES_STEPS[cardProgress.stepsIndex],
        );
      }
    } else {
      // We have re-graduated
      cardProgress.status = 'learned';
      cardProgress.interval = Math.max(
        MINIMUM_INTERVAL,
        cardProgress.interval * NEW_INTERVAL,
      );
    }
  }

  const event: RatingEvent = {
    rating,
    createdAt: new Date(),
    interval: cardProgress.interval,
    duration,
  };

  let proficiency = 0;
  if (cardProgress.interval > 30) {
    proficiency = 6;
  } else if (cardProgress.interval > 21) {
    proficiency = 5;
  } else if (cardProgress.interval > 14) {
    proficiency = 4;
  } else if (cardProgress.interval > 8) {
    proficiency = 3;
  } else if (cardProgress.interval > 4) {
    proficiency = 2;
  } else if (cardProgress.interval > 1) {
    proficiency = 1;
  }

  if (rating === 'retire') {
    cardProgress.status = 'retired';
  }

  return {
    ...cardProgress,
    proficiency,
    retired: rating === 'retire' ? true : cardProgress.retired,
    lastReview: new Date(),
    nextReview: dayjs().add(cardProgress.interval, 'day').toDate(),
    attempts: [...cardProgress.attempts, event],
  };
}
