import * as React from 'react';
import { Dayjs } from 'dayjs';
import { DatePicker, Button } from '@21st-night/ui';
import { makeStyles } from '@21st-night/styles';

export interface OnboardingQuestionDateSelectionProps {
  inputFormat?: string | ((date: Date) => string);
  maxDate?: Date;
  onChange: (date: Dayjs) => void;
  onSubmit: () => void;
  value: Dayjs;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 24,
  },
  button: {
    marginLeft: 8,
  },
}));

export const OnboardingQuestionDateSelection: React.FC<OnboardingQuestionDateSelectionProps> = ({
  onChange,
  value,
  inputFormat = 'D MMMM, YYYY',
  maxDate,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DatePicker
        disablePast
        maxDate={maxDate}
        inputFormat={
          typeof inputFormat === 'function'
            ? inputFormat(value.toDate())
            : inputFormat
        }
        value={value}
        onChange={value => onChange(value as Dayjs)}
      />
      <Button
        gutter="start"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onSubmit}
      >
        Next
      </Button>
    </div>
  );
};
