import React from 'react';
import { makeStyles } from '@21st-night/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    padding: '3px 0 3px 2px',
  },
  bulletContainer: {
    marginRight: 4,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(1em + 3px + 3px)',
    height: '100%',
    flexGrow: 0,
    flexShrink: 0,
    paddingRight: 2,
  },
  bullet: {
    width: 6,
    height: 6,
    borderRadius: 6,
    background: 'currentcolor',
    marginTop: '0.1em',
  },
  contentContainer: {
    flex: '1 1 0px',
    minWidth: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '3px 0',
  },
}));

export const BulletedListItem: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.bulletContainer}>
        <div className={classes.bullet} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
