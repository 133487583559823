import { Card } from '@21st-night/cards';
import { DeckCard } from '../../Deck.types';

type Options = Partial<Pick<DeckCard, 'category' | 'subcategories'>>;

/**
 * Generates a DeckCard from a Card with optional
 * DeckCard properties configuration.
 */
export function generateDeckCard(card: Card, options: Options = {}): DeckCard {
  return {
    id: card.id,
    type: card.type,
    createdAt: card.createdAt,
    subcategories: options.subcategories || [],
    category: options.category || null,
    question: card.question,
  };
}
