import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Slider, SliderProps } from '@21st-night/ui';
import { daysBetween } from '@21st-night/study-plan';
import { TaskFormSliderValueLabel } from './TaskFormSliderValueLabel';
import { TaskFormSliderCountMark } from './TaskFormSliderCountMark';

export interface TaskFormSliderProps
  extends Omit<SliderProps, 'onChange' | 'min' | 'max' | 'defaultValue'> {
  /**
   * The default value of the slider.
   */
  defaultValue?: Date[];
  /**
   * The maximum value of the slider.
   */
  max: Date;
  /**
   * The minimum value of the slider.
   */
  min: Date;
  /**
   * onChange
   */
  onChange?: (event: React.ChangeEvent, value: Date[]) => void;
  /**
   * Prop applied to the start-date hidden input.
   */
  startDateInputProps?: React.HTMLProps<HTMLInputElement>;
  /**
   * Prop applied to the end-date hidden input.
   */
  endDateInputProps?: React.HTMLProps<HTMLInputElement>;
  /**
   * count
   */
  taskCount?: number;
  /**
   *
   */
  taskDays?: number[];
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(0, 1),
    },
    slider: {
      marginTop: theme.spacing(2),
    },
    sliderRail: {
      backgroundColor: theme.palette.grey[500],
    },
    sliderCountLabel: {
      position: 'absolute',
      right: 0,
      transform: 'translate(50%, -40px)',
      color: theme.palette.text.secondary,
    },
    sliderMark: {
      display: 'none',
    },
    sliderMarkLabel: {
      transform: 'translateX(0)',
    },
  }),
);

export const TaskFormSlider: React.FC<TaskFormSliderProps> = ({
  defaultValue,
  taskCount = 0,
  max,
  min,
  startDateInputProps,
  endDateInputProps,
  taskDays = [],
  onChange,
  ...other
}) => {
  const classes = useStyles();
  const dateRange = useMemo(() => daysBetween(min, max), [min, max]);
  let startIndex = defaultValue
    ? dateRange.findIndex(day => dayjs(defaultValue[0]).isSame(day, 'day'))
    : 0;
  if (startIndex === -1) {
    startIndex = 0;
  }
  let endIndex = defaultValue
    ? dateRange.findIndex(day => dayjs(defaultValue[1]).isSame(day, 'day'))
    : dateRange.length - 1;
  if (endIndex === -1) {
    endIndex = dateRange.length - 1;
  }
  const [range, setRange] = useState(
    defaultValue ? [startIndex, endIndex] : [0, dateRange.length - 1],
  );

  function handleChange(event: React.ChangeEvent<any>, val: number | number[]) {
    const value = val as number[];
    setRange(value as number[]);
    if (onChange) {
      onChange(event, [dateRange[value[0]], dateRange[value[1]]]);
    }
  }

  function sliderValueLabelFormat(value: number, index: number): string {
    if (index === 0 && value === 0) {
      return 'Today';
    }

    if (index === 1 && value === dateRange.length - 1) {
      return 'Exam day';
    }

    return dayjs(dateRange[value]).format('ddd D MMM');
  }

  return (
    <div className={classes.root}>
      <Slider
        color="secondary"
        valueLabelDisplay="on"
        ValueLabelComponent={TaskFormSliderValueLabel}
        className={classes.slider}
        max={dateRange.length - 1}
        value={range}
        valueLabelFormat={sliderValueLabelFormat}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleChange}
        classes={{
          rail: classes.sliderRail,
          mark: classes.sliderMark,
          markLabel: classes.sliderMarkLabel,
        }}
        {...other}
      />
      <input
        readOnly
        type="hidden"
        name="startDate"
        value={dateRange[range[0]].getTime()}
        {...startDateInputProps}
      />
      <input
        readOnly
        type="hidden"
        name="endDate"
        value={dateRange[range[1]].getTime()}
        {...endDateInputProps}
      />
      <TaskFormSliderCountMark
        startDate={dateRange[range[0]]}
        endDate={dateRange[range[1]]}
        days={taskDays}
        count={taskCount || 0}
      />
    </div>
  );
};
