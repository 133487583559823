import { v4 as uuid } from 'uuid';
import {
  EditorDocument,
  serializeDocument,
  generateRichTextDocument,
  isDocumentEmpty,
} from '@21st-night/editor';
import { Card } from '../../types';

export interface GenerateCardOptions
  extends Pick<Card, 'author' | 'deck' | 'type'>,
    Pick<Partial<Card>, 'owner' | 'id'> {
  question?: EditorDocument | string;
  answer?: EditorDocument | string;
  explanation?: EditorDocument | string | null;
  summary?: EditorDocument | string | null;
  note?: string | null;
}

function contentFromOption(value: EditorDocument | string | undefined): string;
function contentFromOption(
  value: EditorDocument | string | null | undefined,
  emptyToNull: boolean,
): string | null;
function contentFromOption(
  value: EditorDocument | string | null | undefined,
  emptyToNull?: boolean,
): string | null {
  if (typeof value === 'string') {
    return value;
  }

  if (value === null) {
    return null;
  }

  if (value) {
    return isDocumentEmpty(value) && emptyToNull
      ? null
      : serializeDocument(value);
  }

  return emptyToNull ? null : serializeDocument(generateRichTextDocument());
}

export function generateCard(options: GenerateCardOptions): Card {
  const { question, answer, explanation, summary, note, ...other } = options;

  const card: Card = {
    id: uuid(),
    owner: other.author,
    createdAt: new Date(),
    question: contentFromOption(question),
    answer: contentFromOption(answer),
    explanation: contentFromOption(explanation, true),
    summary: contentFromOption(summary, true),
    ...other,
  };

  if (note) {
    card.note = note;
  }

  return card;
}
