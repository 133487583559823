import React from 'react';
import { DeckSortSelect, DeckSortSelectProps } from './DeckSortSelect';
import { useDeck } from '@21st-night/deck';

export type DeckSortProps = DeckSortSelectProps;

export const DeckSort: React.FC<DeckSortProps> = ({ ...other }) => {
  const { setSortValue, sortValue } = useDeck();

  return (
    <DeckSortSelect value={sortValue} onChange={setSortValue} {...other} />
  );
};
