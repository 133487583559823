import React, { useCallback, useEffect, useState } from 'react';
import { Path, Transforms } from 'slate';
import { RenderElementProps } from '@braindrop-editor/core';
import { makeStyles, cn } from '@21st-night/styles';
import { ExpandMore } from '@21st-night/icons';
import { EditorDocument, isDocumentEmpty } from '@21st-night/editor-web';
import {
  Typography,
  Collapse,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@21st-night/ui';

import { ReactEditor, useSlate } from 'slate-react';

export interface ElementExplanationProps extends RenderElementProps {
  variant?: 'flashcard' | 'error-log';
}

const useStyles = makeStyles(theme => ({
  toggleButton: {},
  heading: {
    fontWeight: 'bold',
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
  },
  icon: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
    marginLeft: -4,
    transform: 'rotate(-90deg)',
  },
  iconExpanded: {
    transform: 'rotate(0deg)',
  },
  content: {
    minHeight: 100,
  },
  contentInner: {
    position: 'relative',
  },
  placeholder: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    color: '#BDBDBD',
    display: 'flex',
    cursor: 'text',
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  editable: {
    position: 'relative',
    zIndex: 10,
  },
  infoButton: {
    marginLeft: -4,
    color: theme.palette.text.secondary,
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
  },
}));

export const ElementExplanation: React.FC<ElementExplanationProps> = ({
  attributes,
  children,
  variant = 'error-log',
  element,
}) => {
  const classes = useStyles();
  const editor = useSlate();
  const hasContent = !isDocumentEmpty(element.children as EditorDocument, true);
  const [expanded, setExpanded] = useState(hasContent);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  useEffect(() => {
    if (
      editor.selection &&
      !Path.equals(editor.selection.anchor.path, editor.selection.focus.path)
    ) {
      Transforms.select(editor, editor.selection.anchor.path);
    }
  }, [editor.selection]);

  function handleMouseUp() {
    setTimeout(() => {
      if (
        editor.selection &&
        !Path.equals(editor.selection.anchor.path, editor.selection.focus.path)
      ) {
        Transforms.select(editor, editor.selection.anchor.path);
      }
    }, 50);
  }

  const handleClickToggle = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      const wasExpanded = expanded;
      setExpanded(!expanded);
      if (!wasExpanded) {
        Transforms.select(editor, ReactEditor.findPath(editor, element));
        Transforms.collapse(editor, { edge: 'end' });
        setTimeout(() => {
          ReactEditor.focus(editor);
        });
      }
    },
    [expanded],
  );

  const openInfoDialog = useCallback(() => {
    setInfoDialogOpen(true);
  }, []);

  const closeInfoDialog = useCallback(() => {
    setInfoDialogOpen(false);
  }, []);

  return (
    <div {...attributes} onMouseUp={handleMouseUp}>
      <Typography
        variant="subtitle1"
        role="button"
        style={{ color: expanded ? '#616161' : '#9E9E9E' }}
        className={classes.heading}
        tabIndex={0}
        contentEditable={false}
        onClick={handleClickToggle}
      >
        <ExpandMore
          className={cn(classes.icon, expanded && classes.iconExpanded)}
        />
        {variant === 'error-log'
          ? 'Process for answering the question'
          : 'Context'}
      </Typography>
      <Collapse in={expanded}>
        <div contentEditable={false}>
          <Button
            variant="text"
            size="small"
            className={classes.infoButton}
            onClick={openInfoDialog}
          >
            What should I write?
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentInner}>
            {!hasContent && (
              <div contentEditable={false} className={classes.placeholder}>
                Write as if you were explaining step by step to another
                person...
              </div>
            )}
            <div className={classes.editable}>{children}</div>
          </div>
        </div>
      </Collapse>
      <Dialog open={infoDialogOpen} onClose={closeInfoDialog}>
        <DialogContent contentEditable={false}>
          <Typography variant="h6" gutterBottom>
            What to write as an explanation for a process
          </Typography>
          <Typography paragraph>
            If you’re writing an explanation for a process (like a math problem
            or logic problem), write a step-by-step explanation of how to solve
            it, including your <em>motivation</em> for each step.
          </Typography>
          <Typography paragraph>
            How exactly did you solve the problem? How did you know what step to
            do when?
          </Typography>

          <Typography paragraph>
            For example, if your question was: “if x+3=5, what is x?”, you’d
            write as your explanation:
          </Typography>
          <Typography paragraph>
            <ol>
              <li>I need to get x on its own.</li>
              <li>To do that, I subtract 3 from both sides.</li>
              <li>(x+3) - 3 = 5 - 3</li>
              <li>x=2</li>
            </ol>
          </Typography>
          <Typography variant="h6" gutterBottom>
            What to write as an explanation for content
          </Typography>
          <Typography paragraph>
            If you’re writing an explanation for content (like facts or
            vocabulary), write context or a mnemonic for the explanation.
          </Typography>

          <Typography paragraph>
            For instance, if your question was “What does the word
            <em>dictionary</em> mean?”
          </Typography>

          <Typography paragraph>
            You could write as your context explanation: “I looked up the words
            I didn’t know in a <em>dictionary</em>.”
          </Typography>
          <Typography paragraph>OR</Typography>
          <Typography paragraph>
            You could write as your mnemonic explanation: “Dictionary contains
            diction, which means speaking words. A dictionary contains words.”
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={closeInfoDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
