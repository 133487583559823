import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { daysOfTheWeekBetween } from '@21st-night/study-plan';

export interface TaskFormSliderCountMarkProps {
  days: number[];
  count: number;
  startDate: Date;
  endDate: Date;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
  }),
);

function tasksPerReviewDay(daysCount: number, tasksCount: number): string {
  const max = Math.ceil(tasksCount / daysCount || 1);
  const min = Math.floor(tasksCount / daysCount || 1);

  if (min !== max && min !== 0) {
    return `${min}-${max} tasks per review day`;
  }

  return `${max} task${max !== 1 ? 's' : ''} per review day`;
}

export const TaskFormSliderCountMark: React.FC<TaskFormSliderCountMarkProps> = ({
  days,
  count,
  startDate,
  endDate,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {count > 0 && days.length > 0
        ? tasksPerReviewDay(
            daysOfTheWeekBetween(startDate, endDate, days).length,
            count,
          )
        : 'No review days'}
    </span>
  );
};
