import React, { useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { useFirebase } from '@21st-night/utils';
import { Paper, Spaced, Typography, Button } from '@21st-night/ui';
import { ReviewView } from '@21st-night/review-web';
import {
  deserializeDocument,
  isDocumentEmpty,
  RichText,
} from '@21st-night/editor-web';
import { DeckCategoryTag } from '../DeckCategoryTag';
import { DeckSubcategoryTag } from '../DeckSubcategoryTag';
import { DeckContentCard } from '@21st-night/deck';
import { DeckLinkedNoteTag } from '../DeckLinkedNoteTag';

export interface DeckItemCardPreviewProps
  extends React.HTMLAttributes<HTMLDivElement> {
  card: DeckContentCard;
  onClick?: (event: React.MouseEvent) => void;
  onClickStudy?: (event: React.MouseEvent) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      userSelect: 'none',
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#90A4AE',
      height: 34,
      lineHeight: '34px',
    },
    content: {
      flex: 1,
      outline: 'none',
      cursor: 'pointer',
    },
    metaToolbar: {
      marginTop: theme.spacing(1),
      flexWrap: 'wrap',
    },
    menu: {
      minWidth: 170,
    },
    retired: {
      color: theme.palette.text.secondary,
    },
    explantation: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const DeckItemCardPreview: React.FC<DeckItemCardPreviewProps> = ({
  className,
  card,
  onClick,
  ...other
}) => {
  const { imageUrl } = useFirebase();
  const classes = useStyles();
  const [view, setView] = useState<ReviewView>('question');

  function showAnswer() {
    setView('answer');
  }

  function showQuestion() {
    setView('question');
  }

  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <div
        onClick={onClick}
        role="button"
        tabIndex={0}
        className={classes.content}
      >
        <Typography variant="subtitle1" className={classes.label}>
          {card.type === 'flashcard' && 'Flashcard'}
          {card.type === 'error-log' && 'Error log'}
        </Typography>
        {['question', 'question-summary'].includes(view) && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.question)}
            className={classes.content}
          />
        )}
        {['answer', 'answer-explanation'].includes(view) && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.answer)}
            className={classes.content}
          />
        )}
        {view === 'question-summary' && card.summary && (
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(card.summary)}
            className={classes.content}
          />
        )}
        {card.explanation &&
          !isDocumentEmpty(deserializeDocument(card.explanation)) && (
            <div className={classes.explantation}>
              {view === 'answer-explanation' && (
                <RichText
                  imageUrl={imageUrl}
                  document={deserializeDocument(card.explanation)}
                  className={classes.content}
                />
              )}
              <Spaced align="center">
                {view === 'answer' && (
                  <Button onClick={() => setView('answer-explanation')}>
                    Reveal explanation
                  </Button>
                )}
              </Spaced>
            </div>
          )}
      </div>
      <Spaced className={classes.metaToolbar}>
        {card.note && <DeckLinkedNoteTag value={card.note} />}
        {card.category && <DeckCategoryTag category={card.category} />}
        {card.subcategories.map(subcategory => (
          <DeckSubcategoryTag key={subcategory} subcategory={subcategory} />
        ))}
        <div style={{ flex: 1 }} />
        {view === 'question' && (
          <Button size="small" color="primary" onClick={showAnswer}>
            Show Answer
          </Button>
        )}
        {view !== 'question' && (
          <Button size="small" color="primary" onClick={showQuestion}>
            Show Question
          </Button>
        )}
      </Spaced>
    </Paper>
  );
};
