import React, { useState } from 'react';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import { Autocomplete } from '@21st-night/ui';
import { DeckFilterOption } from '@21st-night/deck';
import { DeckFilterBarInput } from './DeckFilterBarInput';
import { DeckFilterBarGroup } from './DeckFilterBarGroup';
import { DeckFilterBarPaper } from './DeckFilterBarPaper';
import { DeckFilterBarOption } from './DeckFilterBarOption';
import { DeckFilterBarTag } from './DeckFilterBarTag';

export interface DeckFilterBarProps {
  className?: string;
  placeholder?: string;
  value: DeckFilterOption[];
  options: DeckFilterOption[];
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: DeckFilterOption[],
  ) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    input: {
      padding: theme.spacing(0.5, 1),
      minHeight: 48,
    },
    focused: {
      backgroundColor: theme.palette.background.paper,
      borderTopRightRadius: 3,
      borderTopLeftRadius: 3,
      boxShadow:
        'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
    },
    chip: {
      marginRight: theme.spacing(1),
    },
    listbox: {
      overflow: 'visible',
      padding: 0,
      maxHeight: '80vh',
    },
    option: {
      minHeight: 'auto',
      display: 'inline-block',
      padding: 0,
      margin: 0,
      borderRadius: 3,
      lineHeight: 1,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
      '&[aria-disabled="true"]': {
        opacity: theme.palette.action.disabledOpacity,
        pointerEvents: 'none',
      },
    },
    optionHighlightEnabled: {
      '&[data-focus="true"]': {
        backgroundColor: 'transparent',
        boxShadow: '#80D8FF 0px 0px 0px 2px',
      },
    },
    tag: {
      lineHeight: 1,
      '&:focus': {
        outlineColor: '#80D8FF',
      },
    },
  }),
);

export const DeckFilterBar: React.FC<DeckFilterBarProps> = ({
  className,
  placeholder,
  value,
  onChange,
  options,
}) => {
  const classes = useStyles();
  const [highlight, setHighlight] = useState(false);

  function handleHighlightChange(
    event: React.ChangeEvent<unknown>,
    option: DeckFilterOption | null,
    reason: 'mouse' | 'keyboard' | 'auto',
  ) {
    if (reason === 'keyboard' && option !== null) {
      setHighlight(true);
    } else {
      setHighlight(false);
    }
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      id="deck-filter"
      onHighlightChange={handleHighlightChange}
      onChange={onChange}
      options={options}
      PaperComponent={DeckFilterBarPaper}
      value={value}
      groupBy={option => option.group}
      getOptionLabel={option => option.label}
      className={className}
      classes={{
        option: cn(classes.option, highlight && classes.optionHighlightEnabled),
        tag: classes.tag,
        listbox: classes.listbox,
        focused: classes.focused,
      }}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          <DeckFilterBarTag
            key={option.value}
            {...option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={option => (
        <DeckFilterBarOption
          selected={!!value.find(opt => opt.value === option.value)}
          {...option}
        />
      )}
      renderGroup={params => <DeckFilterBarGroup {...params} />}
      renderInput={params => (
        <DeckFilterBarInput
          showPlaceholder={!value.length}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  );
};
