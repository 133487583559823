import React from 'react';
import dayjs from 'dayjs';
import {
  DeckPrimaryActionButton,
  DeckPrimaryActionButtonProps,
} from '../DeckPrimaryActionButton';
import { useDeck } from '@21st-night/deck';

export type DeckStudyButtonProps = Omit<DeckPrimaryActionButtonProps, 'label'>;

export const DeckStudyButton: React.FC<DeckStudyButtonProps> = ({
  ...other
}) => {
  const deck = useDeck();
  const filteredCards = deck.filter(deck.cards);
  const dueCards = filteredCards.filter(
    card =>
      !card.retired &&
      (!card.nextReview ||
        (card.nextReview &&
          (dayjs(card.nextReview).isBefore(new Date()) ||
            dayjs(card.nextReview).isSame(new Date(), 'date')))),
  );

  let label = dueCards.length
    ? `Study Deck (${dueCards.length} card${dueCards.length === 1 ? '' : 's'})`
    : 'No cards due today';

  if (deck.filters.length && dueCards.length) {
    label = `Study Filtered (${dueCards.length} card${
      dueCards.length === 1 ? '' : 's'
    })`;
  }

  return (
    <DeckPrimaryActionButton
      tooltip="21st Night works on a spaced repetition algorithm, assigning you cards to study just as you're about to forget them. If you want to study a card before it's due, click directly on the card!"
      label={label}
      disabled={dueCards.length === 0}
      {...other}
    />
  );
};
