import React from 'react';
import {
  CategorySelect,
  CategorySelectProps,
} from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export type DeckSubcategoriesSelectProps = Omit<
  CategorySelectProps,
  'categories' | 'onCreate' | 'onUpdate' | 'onDelete' | 'variant'
>;

export const DeckSubcategoriesSelect: React.FC<DeckSubcategoriesSelectProps> = ({
  value,
  onChange,
  ...other
}) => {
  const deck = useDeck();

  return (
    <CategorySelect
      variant="subcategory"
      categories={deck.subcategories}
      value={value}
      onChange={onChange}
      onCreate={deck.createSubcategory}
      onUpdate={deck.renameSubcategory}
      onDelete={deck.deleteSubcategory}
      {...other}
    />
  );
};
