import React, { useEffect, useRef, useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';

interface Size {
  width: number;
  height: number;
}

interface ClassNameMap {
  wrapper: string;
  container: string;
  content: string;
}

export interface AutoscaleProps extends React.HTMLAttributes<HTMLDivElement> {
  classes?: ClassNameMap;
  maxHeight: number;
  maxWidth: number;
  maxScale: number;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  }),
);

export const Autoscale: React.FC<AutoscaleProps> = ({
  className,
  classes: classesProp = {},
  children,
  maxHeight,
  maxWidth,
  maxScale,
  ...other
}) => {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const [wrapperSize, setWrapperSize] = useState<Size>({ width: 0, height: 0 });
  const [contentSize, setContentSize] = useState<Size>({ width: 0, height: 0 });
  const wrapper = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const containerHeight = scale * contentSize.height;
  const containerWidth = scale * contentSize.width;

  function updateState(newState: { wrapperSize: Size; contentSize: Size }) {
    const {
      wrapperSize: newWrapperSize,
      contentSize: newContentSize,
    } = newState;

    let newScale = newWrapperSize.width / newContentSize.width;

    if (maxHeight) {
      newScale = Math.min(newScale, maxHeight / newContentSize.height);
    }
    if (maxWidth) {
      newScale = Math.min(newScale, maxWidth / newContentSize.width);
    }
    if (maxScale) {
      newScale = Math.min(newScale, maxScale);
    }

    setScale(newScale);
    setWrapperSize(newWrapperSize);
    setContentSize(newContentSize);
  }

  useEffect(() => {
    if (!content.current || !wrapper.current) {
      return;
    }

    const actualContent = content.current.children[0];

    updateState({
      wrapperSize: {
        width: wrapper.current.offsetWidth,
        height: wrapper.current.offsetHeight,
      },
      contentSize: {
        width: actualContent.getBoundingClientRect().width,
        height: actualContent.getBoundingClientRect().height,
      },
    });
  }, []);

  return (
    <div ref={wrapper} className={classesProp.wrapper}>
      <div
        ref={container}
        className={classesProp.container}
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          width: containerWidth + 'px',
          height: containerHeight + 'px',
        }}
      >
        <div
          ref={content}
          className={classesProp.content}
          style={{
            transform: 'scale(' + scale + ')',
            transformOrigin: '0 0 0',
          }}
        >
          {React.Children.only(children)}
        </div>
      </div>
    </div>
  );
};
