import {
  EditorDocument,
  stripDocumentMetadata,
  isElementOfType,
  Element,
} from '@21st-night/editor';
import { Node, NodeEntry, Range } from 'slate';
import { CardLinkElement } from '../../types';

export function getSelectionCardLinks(
  document: EditorDocument,
  selection: Range,
): NodeEntry<CardLinkElement>[] {
  const [children] = stripDocumentMetadata(document);
  const nodes: NodeEntry<CardLinkElement>[] = [];
  const fragment = Node.fragment(
    { children, id: '', type: '' } as Element,
    selection,
  );

  fragment.forEach(node => {
    const fragmentNodes = Array.from(Node.descendants(node));
    fragmentNodes.forEach(entry => {
      if (isElementOfType(entry[0], 'card-link')) {
        nodes.push(entry as NodeEntry<CardLinkElement>);
      }
    });
  });

  return nodes;
}
