import React from 'react';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatStrikethrough,
} from '@21st-night/icons';
import { EditorToolbarButton as Button } from '../components/EditorToolbarButton';
import {
  EditorWithRichTextPlugin,
  RichTextFormat,
} from '@braindrop-editor/plugin-rich-text';

export interface ToolbarRichTextFormattingButtonsProps {
  editor: EditorWithRichTextPlugin;
  touch?: boolean;
}

export const ToolbarRichTextFormattingButtons: React.FC<ToolbarRichTextFormattingButtonsProps> = ({
  editor,
  touch,
}) => {
  function toggleFormatting(format: RichTextFormat) {
    return (event: React.MouseEvent): void => {
      event.preventDefault();
      editor.toggleRichTextFormat(format);
    };
  }

  return (
    <>
      <Button
        tooltip="Bold"
        shortcut="ctrl+B"
        touch={touch}
        active={editor.isRichTextFormatActive('bold')}
        onMouseDown={toggleFormatting('bold')}
      >
        <FormatBold />
      </Button>
      <Button
        tooltip="Italic"
        shortcut="ctrl+I"
        touch={touch}
        active={editor.isRichTextFormatActive('italic')}
        onMouseDown={toggleFormatting('italic')}
      >
        <FormatItalic />
      </Button>
      <Button
        tooltip="Underlined"
        shortcut="ctrl+U"
        touch={touch}
        active={editor.isRichTextFormatActive('underline')}
        onMouseDown={toggleFormatting('underline')}
      >
        <FormatUnderlined />
      </Button>
      <Button
        tooltip="Strike-through"
        shortcut="ctrl+Shift+S"
        touch={touch}
        active={editor.isRichTextFormatActive('strike-through')}
        onMouseDown={toggleFormatting('strike-through')}
      >
        <FormatStrikethrough />
      </Button>
    </>
  );
};
