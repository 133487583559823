import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const SquareRoot: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <path
      d="M11.67 41.1633C13.3804 41.1633 14.2721 39.9625 14.7634 38.3976L23.0428 11.7395H42.4222C43.4594 11.7395 44.1692 11.1026 44.1692 10.1018C44.1692 9.13736 43.4594 8.50049 42.4222 8.50049H23.0064C21.1504 8.50049 20.3134 9.19196 19.822 10.7933L11.8701 36.9053H11.5608L8.13982 24.841C7.84867 23.8402 7.30277 23.3671 6.44753 23.3671C5.48311 23.3671 4.77344 24.0585 4.77344 24.9502C4.77344 25.3141 4.86443 25.6416 4.93721 25.9147L8.7949 38.5977C9.24981 40.0716 10.0687 41.1633 11.67 41.1633Z"
      fill="black"
    />
    <rect x="24" y="18" width="16" height="16" rx="2" fill="#2EB0FF" />
  </SvgIcon>
);
