import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createContext } from '@21st-night/utils-web';
import { Range, Node, Transforms, Element } from 'slate';
import {
  CardAnswerElement,
  CardQuestionElement,
  EditorDocument,
  generateEditorElement,
  isDocumentEmpty,
} from '@21st-night/editor-web';
import { useSlate } from 'slate-react';
import { CardType } from '@21st-night/cards';
import { CardFormData } from '../CardCreationPlugin.types';

export interface CardCreationPluginProviderProps {
  editorRef: React.RefObject<HTMLDivElement>;
  onSubmitCreateCard: (data: CardFormData, document: EditorDocument) => void;
}

export interface CardCreationPluginContext {
  position?: DOMRect;
  selection?: Range;
  fragment: Node[] | null;
  cardFormValue: EditorDocument;
  editorRef: React.RefObject<HTMLDivElement>;
  setFragment: (value: Node[] | null) => void;
  setSelection: (value: Range | undefined) => void;
  setPosition: (value: DOMRect | undefined) => void;
  setCardFormValue: (value: EditorDocument) => void;
  createCard: (type: CardType) => void;
}

const [hook, Provider] = createContext<CardCreationPluginContext>();

function createCardDocument(question: Node[]): EditorDocument {
  return [
    generateEditorElement(
      'card-question',
      JSON.parse(JSON.stringify(question)),
    ),
    generateEditorElement('card-answer', generateEditorElement('paragraph')),
    generateEditorElement(
      'card-explanation',
      generateEditorElement('paragraph'),
    ),
    generateEditorElement('card-summary', generateEditorElement('paragraph')),
  ];
}

export const CardCreationPluginProvider: React.FC<CardCreationPluginProviderProps> = ({
  children,
  editorRef,
  onSubmitCreateCard,
}) => {
  const editor = useSlate();
  const [fragment, setFragment] = useState<Node[] | null>(null);
  const [selection, setSelection] = useState<Range>();
  const [position, setPosition] = useState<DOMRect>();
  const [cardFormValue, setCardFormValue] = useState<EditorDocument>(
    createCardDocument([generateEditorElement('paragraph')]),
  );
  const cardValueRef = useRef<EditorDocument>(cardFormValue);
  const selectionRef = useRef<Range | undefined>(selection);

  useEffect(() => {
    if (fragment) {
      setCardFormValue(createCardDocument(fragment));
    }
  }, [fragment]);

  useEffect(() => {
    cardValueRef.current = cardFormValue;
  }, [cardFormValue]);

  useEffect(() => {
    selectionRef.current = selection;
  }, [selection]);

  const handleCreateCard = useCallback((type: CardType) => {
    const [
      question,
      answer,
      explanation,
      summary,
    ] = cardValueRef.current as Element[];

    if (isDocumentEmpty(question.children as EditorDocument)) {
      const withError = [...cardValueRef.current];
      withError[0] = {
        ...withError[0],
        error: 'Required',
      } as CardQuestionElement;
      setCardFormValue(withError);
      return;
    }

    if (isDocumentEmpty(answer.children as EditorDocument)) {
      const withError = [...cardValueRef.current];
      withError[1] = {
        ...withError[1],
        error: 'Required',
      } as CardAnswerElement;
      setCardFormValue(withError);
      return;
    }

    const cardId = uuid();

    Transforms.wrapNodes(
      editor,
      editor.generateElement('card-link', {
        cardId: cardId,
      }),
      { split: true, at: selectionRef.current },
    );

    const cardData: CardFormData = {
      type,
      id: cardId,
      question: question.children as EditorDocument,
      answer: answer.children as EditorDocument,
    };

    if (!isDocumentEmpty(explanation.children as EditorDocument)) {
      cardData.explanation = explanation.children as EditorDocument;
    }

    if (!isDocumentEmpty(summary.children as EditorDocument)) {
      cardData.summary = summary.children as EditorDocument;
    }

    onSubmitCreateCard(cardData, editor.children as EditorDocument);

    setPosition(undefined);
  }, []);

  return (
    <Provider
      value={{
        fragment,
        selection,
        position,
        editorRef,
        cardFormValue,
        setFragment,
        setPosition,
        setSelection,
        setCardFormValue,
        createCard: handleCreateCard,
      }}
    >
      {children}
    </Provider>
  );
};

export const useCardCreationPlugin = hook;
