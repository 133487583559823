import React, { useState, useCallback } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import { createContext } from '@21st-night/utils';
import { getCookie, setCookie } from 'tiny-cookie';
import { lightTheme, darkTheme, ThemeVariant } from './theme';

export type ThemeVariantSetting = ThemeVariant | 'system';

export interface ThemeProviderProps {
  defaultVariant?: ThemeVariantSetting;
}

export interface ThemeContext {
  toggleThemeVariant: () => void;
  setThemeVariant: (setting: ThemeVariantSetting) => void;
  currentVariant: ThemeVariantSetting;
}

const [hook, Provider] = createContext<ThemeContext>();

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  defaultVariant: defaultVariantProp = 'system',
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferredVariant = prefersDarkMode ? 'dark' : 'light';
  let defaultVariant =
    (getCookie('themeVariant') as ThemeVariantSetting) || defaultVariantProp;
  if (defaultVariant === 'system') {
    defaultVariant = preferredVariant;
  }
  const [variant, setVariant] = useState<ThemeVariantSetting>(defaultVariant);
  const theme = React.useMemo(() => {
    const nextTheme = createMuiTheme(
      variant === 'dark' ? darkTheme : lightTheme,
    );

    return nextTheme;
  }, [variant]);

  const toggleThemeVariant = useCallback(() => {
    const nextVariant = variant === 'light' ? 'dark' : 'light';
    setVariant(nextVariant);
    setCookie('themeVariant', nextVariant, { expires: '1Y' });
  }, [variant]);

  const setThemeVariant = useCallback((variant: ThemeVariantSetting) => {
    setVariant(variant);
    setCookie('themeVariant', variant, { expires: '1Y' });
  }, []);

  return (
    <Provider
      value={{
        toggleThemeVariant,
        setThemeVariant,
        currentVariant: variant,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </Provider>
  );
};

export const useThemeVariant = hook;
