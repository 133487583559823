import React from 'react';
import { makeStyles } from '@21st-night/styles';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 20,
  },
}));

export const ToolbarSpacer: React.FC = () => {
  const classes = useStyles();

  return <div className={classes.root} />;
};
