import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography, LoadingIndicator } from '@21st-night/ui';

export interface ImportFromQuizletCreatingCardsProps {
  count: number;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 388,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    title: {
      margin: theme.spacing(2, 0, 1),
    },
  }),
);

export const ImportFromQuizletCreatingCards: React.FC<ImportFromQuizletCreatingCardsProps> = ({
  count,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoadingIndicator size={60} />
      <Typography variant="h6" className={classes.title}>
        Creating {count} cards
      </Typography>
    </div>
  );
};
