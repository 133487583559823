import { useCallback, useEffect, useState } from 'react';
import { DocumentReference } from '@21st-night/utils';
import {
  StudyPlanTaskTemplate,
  StudyPlanTaskTemplateDocument,
  StudyPlanTemplate,
  StudyPlanTemplateDocument,
  StudyPlanTemplateUpdateData,
} from '../../types';
import {
  updateStudyPlanTemplate,
  deserializeStudyPlanTemplate,
  deleteStudyPlanTemplate,
  deserializeStudyPlanTaskTemplate,
  createStudyPlanTaskTemplate,
  GenerateStudyPlanTaskTemplateOptions,
  generateStudyPlanTaskTemplate,
} from '../../api';

export type UpdateStudyPlanTemplateFn = (
  data: StudyPlanTemplateUpdateData,
) => Promise<void>;
export type DeleteStudyPlanTemplateFn = () => Promise<void>;
export type AddStudyPlanTaskTemplateFn = (
  options: GenerateStudyPlanTaskTemplateOptions,
) => Promise<void>;

interface StudyPlanTemplateHookBase {
  update: UpdateStudyPlanTemplateFn;
  delete: DeleteStudyPlanTemplateFn;
  addTask: AddStudyPlanTaskTemplateFn;
  tasks: StudyPlanTaskTemplate[];
  loadingTemplate: boolean;
  loadingTasks: boolean;
}

export interface StudyPlanTemplateHookLoading
  extends StudyPlanTemplateHookBase {
  loading: true;
}

export interface StudyPlanTemplateHookLoaded
  extends StudyPlanTemplateHookBase,
    StudyPlanTemplate {
  loading: false;
}

export type StudyPlanTemplateHook =
  | StudyPlanTemplateHookLoading
  | StudyPlanTemplateHookLoaded;

export const useStudyPlanTemplate = (
  templateRef: DocumentReference,
): StudyPlanTemplateHook => {
  const [loadingTemplate, setLoadingTemplate] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [template, setTemplate] = useState<StudyPlanTemplate | null>(null);
  const [tasks, setTasks] = useState<StudyPlanTaskTemplate[]>([]);

  useEffect(() => {
    let isMounted = true;
    setLoadingTemplate(true);
    setLoadingTasks(true);

    const unsubscribeFromTemplate = templateRef.onSnapshot(snapshot => {
      if (isMounted && snapshot && snapshot.exists) {
        setTemplate(
          deserializeStudyPlanTemplate(
            snapshot.data() as StudyPlanTemplateDocument,
          ),
        );
        setLoadingTemplate(false);
      }
    });
    const unsubscribeFromTasks = templateRef
      .collection('tasks')
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        if (isMounted && snapshot) {
          setTasks(
            snapshot.docs.map(doc =>
              deserializeStudyPlanTaskTemplate(
                doc.data() as StudyPlanTaskTemplateDocument,
              ),
            ),
          );
          setLoadingTasks(false);
        }
      });

    return () => {
      isMounted = false;
      unsubscribeFromTemplate();
      unsubscribeFromTasks();
    };
  }, [templateRef]);

  const addTask: AddStudyPlanTaskTemplateFn = useCallback(
    data => {
      const task = generateStudyPlanTaskTemplate(data);

      return createStudyPlanTaskTemplate(templateRef, task);
    },
    [templateRef],
  );

  let state: StudyPlanTemplateHook;
  if (!loadingTemplate && !loadingTasks && template) {
    state = {
      ...template,
      loading: false,
    } as StudyPlanTemplateHookLoaded;
  } else {
    state = {
      loading: true,
    } as StudyPlanTemplateHookLoading;
  }

  return {
    ...state,
    loadingTasks,
    loadingTemplate,
    tasks,
    addTask,
    update: data => updateStudyPlanTemplate(templateRef, data),
    delete: () => deleteStudyPlanTemplate(templateRef),
  };
};
