import { DocumentReference } from '@21st-night/utils';
import { StudyPlanTaskTemplate } from '../../types';
import { validateNewStudyPlanTaskTemplate } from '../validators';

export function createStudyPlanTaskTemplate(
  templateRef: DocumentReference,
  taskTemplate: StudyPlanTaskTemplate,
): Promise<void> {
  validateNewStudyPlanTaskTemplate(taskTemplate);

  return templateRef.collection('tasks').doc(taskTemplate.id).set(taskTemplate);
}
