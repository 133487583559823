import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  Button,
} from '@21st-night/ui';
import { DeckContentNote } from '@21st-night/deck';
import { DeckNoteSelector, DeckNoteSelectorProps } from '../DeckNoteSelector';

export interface DeckNoteSelectionDialogProps extends DialogProps {
  onSelectNote: (note: DeckContentNote) => void;
  exclude?: DeckNoteSelectorProps['exclude'];
  onClose: () => void;
}

export const DeckNoteSelectionDialog: React.FC<DeckNoteSelectionDialogProps> = ({
  onSelectNote,
  exclude,
  onClose,
  ...other
}) => {
  const handleSelectNote = (note: DeckContentNote) => {
    onSelectNote(note);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { minHeight: '80vh' } }}
      onClose={onClose}
      {...other}
    >
      <DialogContent>
        <DeckNoteSelector onSelectNote={handleSelectNote} exclude={exclude} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
