import React, { useMemo } from 'react';
import { makeStyles } from '@21st-night/styles';
import { Editor, EditorProps } from '@21st-night/editor-web';
import { createCardLinkPlugin } from '@21st-night/cards-web';

export interface NoteHighlighterProps extends Omit<EditorProps, 'onChange'> {
  emphasizeCardLink?: string;
  onClickCardLink?: (cardId: string) => void;
  onRemoveCardLink?: (cardId: string) => void;
  onClickPreviewCard?: (cardId: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    '&::selection': {
      background: '#ffb7b7',
    },
  },
}));

export const NoteHighlighter: React.FC<NoteHighlighterProps> = ({
  db,
  functions,
  storage,
  emphasizeCardLink,
  plugins = [],
  onClickCardLink,
  onRemoveCardLink,
  onClickPreviewCard,
  ...other
}) => {
  const classes = useStyles();
  const CardLinkPlugin = useMemo(
    () =>
      createCardLinkPlugin({
        emphasizeCard: emphasizeCardLink,
        onClickCardLink,
        onClickPreviewCard,
        onRemoveCardLink,
      }),
    [onClickPreviewCard, onRemoveCardLink],
  );

  return (
    <Editor
      placeholder="Empty note"
      className={classes.root}
      spellCheck={false}
      db={db}
      functions={functions}
      storage={storage}
      onChange={() => null}
      {...other}
      plugins={[CardLinkPlugin, ...plugins]}
    />
  );
};
