import React, { useEffect } from 'react';
import { RenderElementProps } from '@braindrop-editor/core';
import {
  CardQuestionElement,
  isDocumentEmpty,
  EditorDocument,
} from '@21st-night/editor-web';
import { cn, makeStyles } from '@21st-night/styles';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';

interface RenderQuestionElementProps
  extends RenderElementProps<CardQuestionElement> {
  minHeight?: number;
  autoFocus?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    '&::before': {
      content: 'attr(data-pseudo-content)',
      ...theme.typography.subtitle1,
      display: 'block',
      color: '#616161',
      fontWeight: 'bold',
      userSelect: 'none',
      pointerEvents: 'none',
    },
  },
  error: {
    '&::before': {
      color: theme.palette.error.main,
    },
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  contentInner: {
    position: 'relative',
  },
  placeholder: {
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    color: '#BDBDBD',
    cursor: 'text',
    top: 5,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  editable: {
    position: 'relative',
    zIndex: 10,
  },
}));

export const ElementQuestion: React.FC<RenderQuestionElementProps> = ({
  attributes,
  children,
  element,
  autoFocus,
  minHeight = 30,
}) => {
  const classes = useStyles();
  const editor = useSlate();
  const error = element.error;
  const hasContent = !isDocumentEmpty(element.children as EditorDocument, true);

  useEffect(() => {
    if (autoFocus) {
      Transforms.select(editor, [0]);
      Transforms.collapse(editor, { edge: 'end' });
      setTimeout(() => {
        ReactEditor.focus(editor);
      });
    }
  }, []);

  return (
    <div
      {...attributes}
      className={cn(classes.root, element.error && classes.error)}
      data-pseudo-content={`Question* ${error ? `- ${error}` : ''}`}
    >
      <div className={classes.content} style={{ minHeight }}>
        <div className={classes.contentInner}>
          {!hasContent && (
            <div contentEditable={false} className={classes.placeholder}>
              Write the question here
            </div>
          )}
          <div className={classes.editable}>{children}</div>
        </div>
      </div>
    </div>
  );
};
