import React, { HTMLAttributes } from 'react';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

export interface MediaPlayerProps extends HTMLAttributes<HTMLDivElement> {
  PlayerProps?: ReactPlayerProps;
  className?: string;
  url: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    },
    player: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }),
);

export const MediaPlayer: React.FC<MediaPlayerProps> = ({
  PlayerProps,
  className,
  url,
  ...other
}) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.root, className)} {...other}>
      <ReactPlayer
        controls
        url={url}
        width="100%"
        height="100%"
        className={classes.player}
        {...PlayerProps}
      />
    </div>
  );
};
