import {
  EditorDocument,
  EditorBlockElement,
  EditorInlineElement,
  EditorTextNode,
  BLOCK_ELEMENT_TYPES,
} from '../../Editor.types';
import { generateEditorElement } from '../generateEditorElement';
import { isElement } from '../isElement';
import { isText } from '../isText';
import { stripDocumentMetadata } from '../stripDocumentMetadata';

export interface TruncateDocumentOptions {
  maxChars: number;
  maxElements?: number;
  stripMedia?: boolean;
}

function truncateTextNode(
  node: EditorTextNode,
  maxLength: number,
): [EditorTextNode, number] {
  let remainingCharCount = maxLength;
  const truncated: EditorTextNode = { ...node, text: '' };
  const textLength = typeof node.text === 'string' ? node.text.length : 0;

  if (textLength > maxLength) {
    let truncatedText = node.text.slice(0, maxLength);
    while ([',', ' '].includes(truncatedText.slice(-1)[0])) {
      truncatedText = truncatedText.slice(0, -1);
    }
    truncated.text = `${truncatedText}...`;
  } else {
    truncated.text = node.text;
  }

  remainingCharCount = Math.max(maxLength - textLength, 0);

  return [truncated, remainingCharCount];
}

function truncateInlineElement(
  element: EditorInlineElement,
  maxLength: number,
): [EditorInlineElement, number] {
  let remainingCharCount = maxLength;
  const truncated: EditorInlineElement = { ...element, children: [] };

  element.children.forEach(child => {
    if (remainingCharCount > 0) {
      if (isText(child)) {
        const [truncatedChild, remaining] = truncateTextNode(
          child,
          remainingCharCount,
        );
        truncated.children.push(truncatedChild);
        remainingCharCount = remaining;
      } else {
        const [truncatedChild, remaining] = truncateInlineElement(
          child as EditorInlineElement,
          remainingCharCount,
        );
        truncated.children.push(truncatedChild);
        remainingCharCount = remaining;
      }
    }
  });

  return [truncated, remainingCharCount];
}

function truncateBlockElement(
  element: EditorBlockElement,
  maxLength: number,
): [EditorBlockElement, number] {
  let remainingCharCount = maxLength;
  const truncated: EditorBlockElement = { ...element, children: [] };
  const children = element.children;

  if (
    children &&
    children.length &&
    !BLOCK_ELEMENT_TYPES.includes((children[0] as EditorBlockElement).type)
  ) {
    const truncatedChildren: (EditorInlineElement | EditorTextNode)[] = [];
    (children as (EditorInlineElement | EditorTextNode)[]).forEach(child => {
      if (remainingCharCount > 0) {
        const [truncatedChild, remaining] = isElement(child)
          ? truncateInlineElement(
              child as EditorInlineElement,
              remainingCharCount,
            )
          : truncateTextNode(child as EditorTextNode, remainingCharCount);
        truncatedChildren.push(truncatedChild);
        remainingCharCount = remaining;
      }
    });
    truncated.children = truncatedChildren;
  } else if (children) {
    const truncatedChildren: EditorBlockElement[] = [];
    (children as EditorBlockElement[]).forEach(child => {
      if (remainingCharCount > 0) {
        const [truncatedChild, remaining] = truncateBlockElement(
          child,
          remainingCharCount,
        );
        truncatedChildren.push(truncatedChild);
        remainingCharCount = remaining;
      }
    });
    truncated.children = truncatedChildren;
  }

  return [truncated, remainingCharCount];
}

export function truncateDocument(
  document: EditorDocument,
  options: TruncateDocumentOptions,
): EditorDocument {
  const [wihtoutMetadata] = stripDocumentMetadata(document);
  const truncated: EditorDocument = [];
  let remainingCharCount = options.maxChars;

  wihtoutMetadata.forEach(node => {
    const element = node as EditorBlockElement;
    if (remainingCharCount < 1) {
      return;
    }

    if (
      options.stripMedia &&
      ['audio', 'image', 'video'].includes(element.type)
    ) {
      let placeholderText = '';

      switch (element.type) {
        case 'audio':
          placeholderText = '[ Audio ]';
          break;
        case 'image':
          placeholderText = '[ Image ]';
          break;
        case 'video':
          placeholderText = '[ Video ]';
          break;
        default:
          placeholderText = '';
      }

      if (placeholderText) {
        truncated.push(generateEditorElement('paragraph', placeholderText));
      }
      return;
    }

    const [truncatedElement, remaining] = truncateBlockElement(
      element,
      remainingCharCount,
    );
    truncated.push(truncatedElement);
    remainingCharCount = remaining;
  });

  return options.maxElements
    ? truncated.slice(0, options.maxElements + 1)
    : truncated;
}
