import { v4 as uuid } from 'uuid';
import {
  generateRichTextDocument,
  serializeDocument,
} from '@21st-night/editor';
import { StudyPlanTemplate } from '../../types';

export function generateStudyPlanTemplate(
  options: Partial<StudyPlanTemplate> = {},
): StudyPlanTemplate {
  return {
    id: uuid(),
    length: 60,
    name: '',
    description: serializeDocument(generateRichTextDocument()),
    createdAt: new Date(),
    ...options,
  };
}
