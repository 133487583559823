export function getUserDeckPath(
  userId: string,
  deckId: string,
  subcollection?: string,
  subcollectionDoc?: string,
): string {
  let path = `/users/${userId}/decks/${deckId}`;

  if (subcollection) {
    path = `${path}/${subcollection}`;
  }

  if (subcollectionDoc) {
    path = `${path}/${subcollection}/${subcollectionDoc}`;
  }

  return path;
}
