import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeckNote } from '../updateDeckNote';

export function unstarNote(
  db: DB,
  deckRef: DocumentReference,
  noteId: string,
): Promise<void> {
  return updateDeckNote(deckRef, noteId, {
    starred: db.delete(),
  });
}
