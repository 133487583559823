import React from 'react';
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
} from '@material-ui/core';

export type DialogTitleProps = MuiDialogTitleProps;

export const DialogTitle: React.FC<DialogTitleProps> = ({ ...other }) => {
  return <MuiDialogTitle {...other} />;
};
