import React from 'react';
import { Popover, PopoverProps, TextInput, DeleteButton } from '@21st-night/ui';

export interface CategorySelectEditMenuProps
  extends Omit<PopoverProps, 'onClose'> {
  name: string;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onClose: () => void;
}

export const CategorySelectEditMenu: React.FC<CategorySelectEditMenuProps> = ({
  open,
  onClose,
  name,
  onChangeName,
  onDelete,
  ...other
}) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{ style: { padding: 8 } }}
      {...other}
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          onClose();
        }}
      >
        <TextInput
          autoFocus
          value={name}
          onChange={onChangeName}
          style={{ marginBottom: 8 }}
        />
      </form>
      <DeleteButton size="small" onConfirm={onDelete} />
    </Popover>
  );
};
