import React from 'react';
import { makeStyles } from '@21st-night/styles';
import { HoveringToolbar } from '@braindrop-editor/plugin-hovering-toolbar';
import { EditorWithLinkPlugin } from './plugins/plugin-link';
import { EditorWithInlineEquationPlugin } from './plugins/plugin-equation';
import {
  ToolbarInsertInlineEquationButton,
  ToolbarInsertLinkButton,
  ToolbarRichTextFormattingButtons,
} from './Toolbar';
import { CoreEditor } from './core-editor-plugins';
import { useSlate } from 'slate-react';

type Editor = CoreEditor &
  EditorWithInlineEquationPlugin &
  EditorWithLinkPlugin;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 3,
    padding: 2,
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: 2,
    },
    boxShadow:
      'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
  },
  spacer: {
    width: 1,
    height: '100%',
    backgroundColor: '#BDBDBD',
  },
}));

export const EditorHoveringToolbar: React.FC = ({ children }) => {
  const classes = useStyles();
  const editor = useSlate() as Editor;

  return (
    <HoveringToolbar>
      <div className={classes.root}>
        {children}
        <ToolbarRichTextFormattingButtons editor={editor} />
        <div className={classes.spacer} />
        <ToolbarInsertLinkButton editor={editor} />
        <ToolbarInsertInlineEquationButton editor={editor} />
      </div>
    </HoveringToolbar>
  );
};
