import React from 'react';
import { EditorToolbar } from './EditorToolbar';
import { EditorHoveringToolbar } from './EditorHoveringToolbar';
import { Editor, EditorProps } from './Editor';

export type FullEditorProps = EditorProps;

export type FullEditor = Editor;

export const FullEditor: React.FC<FullEditorProps> = ({
  children,
  ...other
}) => {
  return (
    <Editor {...other}>
      <EditorToolbar />
      <EditorHoveringToolbar />
      {children}
    </Editor>
  );
};
