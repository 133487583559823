import React, { useMemo } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { DeckCard } from '@21st-night/deck';
import {
  getProficiencyKey,
  ProficiencyKey,
  PROFICIENCY_LABELS,
} from '@21st-night/review';
import { Paper, Typography, Tooltip, Grid } from '@21st-night/ui';
import {
  calculateRetentionRate,
  generateDateRangeAnalyticsData,
} from '@21st-night/analytics';
import { SimpleLineChart } from '../SimpleLineChart';
import dayjs from 'dayjs';
import { StudyTimeAreaChart } from '../StudyTimeAreaChart';
import { RetentionRateAreaChart } from '../RetentionRateAreaChart';

export interface CategoryAnalyticsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  rangeStart: Date;
  category: string;
  cards: DeckCard[];
}

interface Counts extends Record<ProficiencyKey, number> {
  total: number;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(1, 2),
    },
    content: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    subcategoryName: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: '14px',
      marginBottom: 4,
    },
    categoryBar: {
      borderRadius: 3,
      overflow: 'hidden',
      height: 12,
      display: 'flex',
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    subcategoryBar: {
      borderRadius: 2,
      overflow: 'hidden',
      height: 6,
      display: 'flex',
      width: '100%',
      marginBottom: 8,
    },
    retired: {
      height: '100%',
      background: theme.palette.proficiency.retired,
    },
    'not-started': {
      height: '100%',
      background: theme.palette.proficiency.notStarted,
    },
    'needs-work': {
      height: '100%',
      background: theme.palette.proficiency.needsWork,
    },
    'in-progress': {
      height: '100%',
      background: theme.palette.proficiency.inProgress,
    },
    mastered: {
      height: '100%',
      background: theme.palette.proficiency.mastered,
    },
    grid: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  }),
);

const EMPTY_COUNTS = {
  'not-started': 0,
  'needs-work': 0,
  'in-progress': 0,
  mastered: 0,
  retired: 0,
  total: 0,
};

const PROFICIENCY_KEYS: ProficiencyKey[] = [
  'not-started',
  'needs-work',
  'in-progress',
  'mastered',
  'retired',
];

export const CategoryAnalytics: React.FC<CategoryAnalyticsProps> = ({
  className,
  category,
  cards,
  rangeStart,
  ...other
}) => {
  const classes = useStyles();
  const range = useMemo(
    () =>
      generateDateRangeAnalyticsData(rangeStart, cards).map(day => {
        const retention = calculateRetentionRate(day.ratings);

        return {
          label: dayjs(day.date).format('MMM D'),
          reviewed: day.ratings.length,
          added: day.added,
          duration: day.duration,
          retention,
          retentionDisplay: `${day.ratings.length ? retention : '--'}%`,
        };
      }),
    [rangeStart],
  );

  const counts: Counts = {
    ...EMPTY_COUNTS,
  };

  const subcategories: Record<string, Counts> = {};

  cards.forEach(card => {
    const key = getProficiencyKey(card);

    card.subcategories.forEach(subcategory => {
      if (!subcategories[subcategory]) {
        subcategories[subcategory] = {
          ...EMPTY_COUNTS,
        };
      }

      subcategories[subcategory][key] += 1;
      subcategories[subcategory].total += 1;
    });

    counts[key] += 1;
    counts.total += 1;
  });

  return (
    <Paper elevation={2} className={cn(classes.root, className)} {...other}>
      <Typography gutterBottom variant="h5">
        {category}
      </Typography>
      <div className={classes.categoryBar}>
        {PROFICIENCY_KEYS.map(proficiency => (
          <Tooltip
            placement="top"
            key={proficiency}
            title={`${PROFICIENCY_LABELS[proficiency]}: ${Math.round(
              (counts[proficiency as ProficiencyKey] / (counts.total || 1)) *
                100,
            )}%`}
          >
            <span
              className={classes[proficiency as ProficiencyKey]}
              style={{
                width: `${
                  (counts[proficiency as ProficiencyKey] /
                    (counts.total || 1)) *
                  100
                }%`,
              }}
            />
          </Tooltip>
        ))}
      </div>
      {Object.keys(subcategories).map(subcategory => (
        <div key={subcategory}>
          <Typography className={classes.subcategoryName}>
            {subcategory}
          </Typography>
          <div className={classes.subcategoryBar}>
            {PROFICIENCY_KEYS.map(proficiency => (
              <Tooltip
                placement="top"
                key={proficiency}
                title={`${PROFICIENCY_LABELS[proficiency]}: ${Math.round(
                  (subcategories[subcategory][proficiency as ProficiencyKey] /
                    (subcategories[subcategory].total || 1)) *
                    100,
                )}%`}
              >
                <span
                  className={classes[proficiency as ProficiencyKey]}
                  style={{
                    width: `${
                      (subcategories[subcategory][
                        proficiency as ProficiencyKey
                      ] /
                        (subcategories[subcategory].total || 1)) *
                      100
                    }%`,
                  }}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      ))}
      <div className={classes.grid}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <SimpleLineChart
              mini
              label="Cards reviewed"
              data={range}
              dataKey="reviewed"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleLineChart
              mini
              label="Cards added"
              data={range}
              dataKey="added"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StudyTimeAreaChart mini data={range} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RetentionRateAreaChart mini data={range} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};
