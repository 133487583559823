import {
  CardLinkElement,
  EditorDocument,
  isElementOfType,
  stripDocumentMetadata,
} from '@21st-night/editor';
import { createEditor, Transforms, Range } from 'slate';

export function replaceCardLinksInDocument(
  document: EditorDocument,
  at: Range,
  cardId: string,
): EditorDocument {
  const [children] = stripDocumentMetadata(document);
  const editor = createEditor();
  const { isInline } = editor;
  editor.isInline = node =>
    isElementOfType(node, 'card-link') || isInline(node);

  editor.children = children;

  Transforms.setNodes(
    editor,
    {
      cardId,
    } as Partial<CardLinkElement>,
    {
      at,
      match: node => isElementOfType(node, 'card-link'),
      split: false,
    },
  );

  return editor.children as EditorDocument;
}
