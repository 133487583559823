import { validateDoc, DocValidator } from '@21st-night/utils';
import {
  StudyPlanTaskTemplate,
  StudyPlanTaskTemplateUpdateData,
} from '../../types/StudyPlanTaskTemplate';

export const NewStudyPlanTaskTemplateValidator: DocValidator<StudyPlanTaskTemplate> = {
  id: 'string',
  createdAt: 'date',
  action: ['empty-string', 'string'],
  count: 'number',
  object: ['empty-string', 'string'],
  source: ['empty-string', 'string'],
  days: 'number-array',
  start: 'number',
  end: 'number',
  subtasks: 'string-array',
};

export const UpdateStudyPlanTaskTemplateValidator: DocValidator<StudyPlanTaskTemplateUpdateData> = {
  action: ['optional', 'string', 'empty-string'],
  count: ['optional', 'number'],
  object: ['optional', 'string', 'empty-string'],
  source: ['optional', 'string', 'empty-string'],
  days: ['optional', 'number-array'],
  start: ['optional', 'number'],
  end: ['optional', 'number'],
  subtasks: ['optional', 'string-array'],
};

export function validateNewStudyPlanTaskTemplate(
  doc: StudyPlanTaskTemplate,
): boolean {
  return validateDoc(NewStudyPlanTaskTemplateValidator, doc);
}

export function validateStudyPlanTaskTemplateUpdate(
  doc: StudyPlanTaskTemplateUpdateData,
): boolean {
  return validateDoc(UpdateStudyPlanTaskTemplateValidator, doc);
}
