import {
  OnboardingAnswers,
  SegmentationData,
  OnboardingUserType,
  OnboardingContentType,
  StandardizedTest,
  OnboardingRangeSelectionValue,
  OnboardingStudyingFor,
  OnboardingStudyMethod,
} from '../../onboarding.types';

export function serializeSegmentationAnswers(
  answers: OnboardingAnswers,
): SegmentationData {
  const useCase = answers['use-case'] as OnboardingStudyingFor;
  const data: SegmentationData = {
    method: answers['method'] as OnboardingStudyMethod,
    userType: (answers['user-type'] as OnboardingUserType) || 'student',
    contentType: (answers['content-type'] as OnboardingContentType) || null,
    for: useCase || null,
  };

  if (useCase === 'standardized-test') {
    data.standardizedTest = answers['content-type'] as StandardizedTest;
  }

  const goalScore = answers['goal-score'];

  if (goalScore) {
    data.goalScore = goalScore as Record<string, OnboardingRangeSelectionValue>;
  }

  const currentScore = answers['current-score'];

  if (currentScore) {
    data.currentScore = currentScore as Record<
      string,
      OnboardingRangeSelectionValue
    >;
  }

  return data;
}
