import { v4 as uuid } from 'uuid';
import {
  generateRichTextDocument,
  serializeDocument,
} from '@21st-night/editor';
import { Note } from '../../Notes.types';

export function generateNote(
  deck: string,
  author: string,
  options: Partial<Omit<Note, 'createdAt'>> = {},
): Note {
  return {
    deck,
    author,
    type: 'note',
    owner: author,
    id: uuid(),
    createdAt: new Date(),
    title: '',
    content: serializeDocument(generateRichTextDocument()),
    cards: [],
    ...options,
  };
}
