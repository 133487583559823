import { Range } from 'slate';
import { FieldValue, Timestamp } from '@21st-night/utils';
import { Card, ErrorLog, Flashcard } from '@21st-night/cards';
import { Note } from '@21st-night/notes';
import {
  RatingEvent,
  CardStatus,
  CardReviewProgress,
  FlashcardReviewProgress,
} from '@21st-night/review';
import { OnboardingContentType } from '@21st-night/onboarding';

export type StanderdizedTest = '';

export const DECK_TYPES = ['template', 'student'] as const;
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export type DeckType = ElementType<typeof DECK_TYPES>;

export interface DeckItemBase {
  id: string;
  createdAt: Date;
  category: string | null;
  subcategories: string[];
  starred?: boolean;
}

export interface DeckCard extends DeckItemBase, Partial<CardReviewProgress> {
  question: string;
  type: Card['type'];
}

export interface DeckFlashcard
  extends DeckItemBase,
    Partial<FlashcardReviewProgress> {
  type: 'flashcard';
}

export interface DeckErrorLog
  extends DeckItemBase,
    Partial<CardReviewProgress> {
  type: 'error-log';
}

export interface DeckContentCard extends DeckCard, Card {}

export interface DeckContentFlashcard extends DeckFlashcard, Flashcard {}

export interface DeckContentErrorLog extends DeckErrorLog, ErrorLog {}

export interface DeckCardEditorData
  extends Pick<Card, 'question' | 'answer' | 'explanation' | 'summary'>,
    Pick<DeckCard, 'category' | 'subcategories'> {
  noteSelection: Range | null;
  note: string | null;
}

export interface DeckContentCreateCard {
  id: string;
  createdAt: Date;
  type: 'create-flashcard' | 'create-error-log';
  category: DeckContentCard['category'];
  subcategories: DeckContentCard['subcategories'];
  note?: DeckCardEditorData['note'];
  noteSelection?: DeckCardEditorData['noteSelection'];
}

export interface DeckNote extends DeckItemBase {
  type: Note['type'];
}

export interface DeckContentNote extends DeckNote, Note {}

export interface DeckContentNoteFileUpload extends DeckItemBase {
  file: File;
  type: 'pdf-upload' | 'image-upload' | 'text-upload';
}

export interface DeckNoteFileUpload extends DeckItemBase {
  type: DeckContentNoteFileUpload['type'];
}

export interface DeckCreateCard extends DeckItemBase {
  type: DeckContentCreateCard['type'];
}

export type DeckItem =
  | DeckCard
  | DeckNote
  | DeckNoteFileUpload
  | DeckCreateCard;
export type DeckContentItem =
  | DeckContentCard
  | DeckContentCreateCard
  | DeckContentNote
  | DeckContentNoteFileUpload;

export interface Deck {
  type: DeckType;
  createdAt: Date;
  id: string;
  parent?: string;
  name: string;
  lastActivity?: Date;
  description?: string;
  categories: string[];
  subcategories: string[];
  cards: Record<string, DeckCard>;
  notes: Record<string, DeckNote>;
}

export type DeckFilterType =
  | 'category'
  | 'subcategory'
  | 'boolean'
  | 'date'
  | 'link'
  | 'proficiency'
  | 'item-type';
export type DeckFilterGroup =
  | 'metadata'
  | 'card-metadata'
  | 'categories'
  | 'subcategories';
export interface DeckFilterOption {
  value: string;
  label: string;
  type: DeckFilterType;
  group: DeckFilterGroup;
  progress?: number;
}

export const DECK_SORT_GLOBAL_BY = ['createdAt'] as const;
export const DECK_SORT_CARDS_BY = [
  'nextReview',
  'lastReview',
  'proficiency',
] as const;
export type DeckSortGlobalByValue = ElementType<typeof DECK_SORT_GLOBAL_BY>;
export type DeckSortCardsByValue = ElementType<typeof DECK_SORT_CARDS_BY>;
export type DeckSortByValue = DeckSortGlobalByValue | DeckSortCardsByValue;
export type DeckSortGroup = 'global' | 'cards';
export type DeckSortOrderValue = 'asc' | 'desc';
export type DeckSortValue =
  | 'createdAt_desc'
  | 'createdAt_asc'
  | 'nextReview_desc'
  | 'nextReview_asc'
  | 'lastReview_desc'
  | 'proficiency_desc'
  | 'proficiency_asc';
export interface DeckSortOption {
  value: DeckSortValue;
  label: string;
  group: DeckSortGroup;
}

export interface OfficialDeckFile {
  id: string;
  name: string;
}

export interface OfficialDeck extends Deck {
  published: boolean;
  premium: boolean;
  price?: number;
  files: OfficialDeckFile[];
  exam?: OnboardingContentType;
}

export interface PremiumDeck extends OfficialDeck {
  premium: true;
  price: number;
}

export interface DeckCardUpdateData {
  question?: string;
  category?: string | null;
  subcategories?: string[] | FieldValue;
  starred?: boolean | FieldValue;
  retired?: boolean | FieldValue;
  nextReview?: Date;
  lastReview?: Date;
  proficiency?: number;
  notStarted?: boolean;
  attempts?: RatingEvent[] | FieldValue;
  firstReview?: Date;
  status?: CardStatus;
  easeFactor?: number;
  interval?: number;
  stepsIndex?: number;
}

export interface DeckNoteUpdateData {
  category?: string | null;
  subcategories?: string[] | FieldValue;
  starred?: boolean | FieldValue;
}

export type DeckNotesUpdateData = Record<string, DeckNoteUpdateData>;
export type DeckCardsUpdateData = Record<string, DeckCardUpdateData>;

export interface DeckUpdateData {
  cards?: DeckCardsUpdateData;
  notes?: DeckNotesUpdateData;
  name?: string;
  description?: string;
  lastActivity?: Date;
  categories?: string[] | FieldValue;
  subcategories?: string[] | FieldValue;
}

export interface OfficialDeckUpdateData extends DeckUpdateData {
  published?: boolean;
  premium?: boolean;
  price?: number | FieldValue;
  files?: OfficialDeckFile[] | FieldValue;
  exam?: OnboardingContentType | FieldValue;
}

export interface DeckDocumentNote extends Omit<DeckNote, 'createdAt'> {
  createdAt: Timestamp;
}

export interface DeckDocumentCard
  extends Omit<DeckCard, 'createdAt' | 'nextReview' | 'lastReview'> {
  createdAt: Timestamp;
  nextReview: Timestamp;
  lastReview: Timestamp;
}

export interface DeckDocument
  extends Omit<Deck, 'createdAt' | 'cards' | 'notes'> {
  createdAt: Timestamp;
  cards: Record<string, DeckDocumentCard>;
  notes: Record<string, DeckDocumentNote>;
}

export interface OfficialDeckDocument
  extends Omit<OfficialDeck, 'createdAt' | 'cards' | 'notes'> {
  createdAt: Timestamp;
  cards: Record<string, DeckDocumentCard>;
  notes: Record<string, DeckDocumentNote>;
  exam?: OnboardingContentType;
}

export interface PremiumDeckDocument
  extends Omit<PremiumDeck, 'createdAt' | 'cards' | 'notes'> {
  createdAt: Timestamp;
  cards: Record<string, DeckDocumentCard>;
  notes: Record<string, DeckDocumentNote>;
}

export interface DeckCardCheatSheetItem {
  card: string;
  content: string;
  createdAt: Date;
}
