import React from 'react';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export interface DeckCategoryTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  category: string;
  readonly?: boolean;
}

export const DeckCategoryTag: React.FC<DeckCategoryTagProps> = ({
  category,
  readonly,
  ...other
}) => {
  const deck = useDeck();

  return (
    <CategoryTag
      variant="category"
      label={category}
      onClick={
        !readonly ? () => deck.toggleCategoryFilter(category) : undefined
      }
      {...other}
    />
  );
};
