import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { RenderElementProps, useUI } from '@braindrop-editor/core';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { ClozeDeletionElement } from '../ClozeDeletionPlugin.types';

type ElementClozeDeletionProps = RenderElementProps<ClozeDeletionElement>;

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    padding: 2,
    backgroundColor: '#80D8FF',
    borderRadius: 2,
  },
}));

export const ElementClozeDeletion: React.FC<ElementClozeDeletionProps> = ({
  children,
  element,
  attributes,
}) => {
  const classes = useStyles();
  const editor = useSlate();
  const { Popover, TextField, Tooltip } = useUI();
  const span = useRef<HTMLSpanElement>(null);
  const [hintOpen, setHintOpen] = useState(false);

  function openHint() {
    setHintOpen(true);
  }

  function closeHint() {
    setHintOpen(false);
  }

  useEffect(() => {
    if (element.showHintField) {
      Transforms.unsetNodes(editor, ['showHintField'], {
        at: ReactEditor.findPath(editor, element),
      });
      openHint();
    }
  }, [element.showHintField]);

  function handleHintChange(event: React.ChangeEvent<HTMLInputElement>) {
    Transforms.setNodes(
      editor,
      { hint: event.target.value } as Partial<ClozeDeletionElement>,
      { at: ReactEditor.findPath(editor, element) },
    );
  }

  return (
    <>
      <Tooltip title="Click to edit hint">
        <span
          role="button"
          tabIndex={0}
          onClick={openHint}
          className={classes.root}
          {...attributes}
        >
          <span ref={span}>{children}</span>
        </span>
      </Tooltip>
      <Popover open={hintOpen} onClose={closeHint} anchorEl={span.current}>
        <TextField
          autoFocus
          placeholder="Hint (optional)"
          value={element.hint}
          onChange={handleHintChange}
        />
      </Popover>
    </>
  );
};
