import React, { useCallback, useEffect, useState } from 'react';
import { createContext, useFirebase } from '@21st-night/utils';
import { AuthenticatedUserContext, useUser } from '@21st-night/user';
import * as api from '../api';
import { RecentStreak, UserStatistic } from '../Gamification.types';

type IncrementStatistic = (
  statistic: UserStatistic,
  value?: number,
) => Promise<void>;
type IncrementStreak = () => Promise<void>;

export interface GamificationContext {
  recentStreak: RecentStreak;
  incrementStatistic: IncrementStatistic;
  incrementStreak: IncrementStreak;
  currentStreak: number;
  lastStreakIncrement: Date | null;
}

const [hook, Provider, Consumer] = createContext<GamificationContext>();

export const GamificationProvider: React.FC = ({ children }) => {
  const { db } = useFirebase();
  const user = useUser() as AuthenticatedUserContext;
  const [recentStreak, setRecentStreak] = useState<RecentStreak>([]);
  const currentStreak =
    api.isStreakIntact(user.lastStreakIncrement) && user.currentStreak
      ? user.currentStreak
      : 0;

  useEffect(() => {
    let mounted = true;
    let unsubscribe: VoidFunction;

    if (user.authenticated) {
      unsubscribe = db
        .collection('users')
        .doc(user.uid)
        .collection('streak-days')
        .limit(7)
        .orderBy('createdAt', 'desc')
        .onSnapshot(snapshot => {
          if (mounted && snapshot) {
            setRecentStreak(
              api.generateRecentStreakDaysArray(
                snapshot.docs.map(streakDay =>
                  api.deserializeStreakDay(streakDay),
                ),
                7,
              ),
            );
          }
        });
    }

    return () => {
      mounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user.authenticated, user.uid]);

  const incrementStatistic: IncrementStatistic = useCallback(
    (statistic, value = 1) => {
      if (!user.uid) {
        throw new Error('User is not logged in.');
      }

      return api.incrementStatistic(db, user.uid, statistic, value);
    },
    [db, user.uid],
  );

  const incrementStreak: IncrementStreak = useCallback(() => {
    if (!user.uid) {
      throw new Error('User is not logged in.');
    }

    return api.incrementStreak(db, user.uid);
  }, [db, user.uid]);

  return (
    <Provider
      value={{
        recentStreak,
        incrementStatistic,
        incrementStreak,
        currentStreak,
        lastStreakIncrement: user.lastStreakIncrement || null,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGamification = hook;
export const GamificationConsumer = Consumer;
