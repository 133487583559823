import React, { useEffect } from 'react';
import { DB } from '@21st-night/utils';
import { useDebounce } from 'use-debounce';
import dayjs from 'dayjs';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  StudyPlanTask,
  updateStudyPlanTask,
  deleteStudyPlanTask,
} from '@21st-night/study-plan';
import { TaskForm, TaskFormProps } from '../TaskForm';

export interface TaskFormContainerProps
  extends Pick<TaskFormProps, 'minStartDate' | 'maxEndDate'> {
  db: DB;
  task: StudyPlanTask;
  userId: string;
  minStartDate: Date;
  maxEndDate: Date;
  startWeekOn?: number;
}

export const TaskFormContainer: React.FC<TaskFormContainerProps> = ({
  task,
  db,
  userId,
  minStartDate,
  maxEndDate,
  startWeekOn,
  ...other
}) => {
  const { register, watch, control, setValue } = useForm({
    defaultValues: {
      ...task,
      startDate: task.startDate.getTime(),
      endDate: task.endDate.getTime(),
      subtasks: task.subtasks.map(subtask => ({ title: subtask })),
      days: task.days.map(day => `${day}`),
      count: task.count !== 0 ? task.count : '',
    },
  });
  const { fields: subtasks, append, remove } = useFieldArray({
    control,
    name: 'subtasks',
  });
  const count = watch('count', task.count) as string;
  const stringDays = watch(
    'days',
    task.days.map(day => `${day}`),
  ) as string[];
  const days = stringDays.map(day => parseInt(day));
  const subtasksVal = watch('subtasks', []);
  const value = watch([
    'action',
    'count',
    'object',
    'source',
    'startDate',
    'endDate',
    'days',
  ]);

  const [debouncedValue] = useDebounce(
    JSON.stringify({ ...value, subtasks: subtasksVal }),
    300,
    {
      maxWait: 600,
    },
  );

  useEffect(() => {
    const {
      action,
      count,
      object,
      source,
      days: daysValue,
      subtasks: subtasksValue,
      startDate: startDateString,
      endDate: endDateString,
    } = JSON.parse(debouncedValue);

    const startDate = new Date(parseInt(startDateString));
    const endDate = new Date(parseInt(endDateString));

    if (
      task.days &&
      days &&
      (action !== task.action ||
        count !== task.count ||
        object !== task.object ||
        source !== task.source ||
        JSON.stringify(daysValue) !== JSON.stringify(task.days) ||
        JSON.stringify(subtasksValue) !== JSON.stringify(task.subtasks) ||
        !dayjs(startDate).isSame(task.startDate, 'day') ||
        !dayjs(endDate).isSame(task.endDate, 'day'))
    ) {
      updateStudyPlanTask(db, userId, task.id, {
        action: action || '',
        count: parseInt(count) || 0,
        object: object || '',
        source: source || '',
        days: days || [],
        startDate: startDate || minStartDate,
        endDate: endDate || maxEndDate,
        subtasks: (subtasksValue || []).map(
          (subtask: { title: string }) => subtask.title,
        ),
      });
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  function handleClickDelete() {
    deleteStudyPlanTask(db, userId, task.id);
  }

  const addSubtask = () => append({ title: '' });
  const removeSubtask = (event: React.MouseEvent, index: number) =>
    remove(index);

  return (
    <TaskForm
      onClickAddSubtask={addSubtask}
      onClickRemoveSubtask={removeSubtask}
      onClickDelete={handleClickDelete}
      maxEndDate={maxEndDate}
      minStartDate={minStartDate}
      subtasks={subtasks.map((subtask, index) => ({
        id: subtask.id as string,
        InputProps: {
          inputRef: register({ required: true }),
          name: `subtasks[${index}].title`,
          defaultValue: subtask.title,
        },
      }))}
      ActionInputProps={{
        inputRef: register(),
        defaultValue: task.action || '',
      }}
      CountInputProps={{
        inputRef: register({ min: 1, required: true }),
        defaultValue: task.count || '',
      }}
      ObjectInputProps={{
        inputRef: register({ required: true }),
        defaultValue: task.object || '',
      }}
      SourceInputProps={{
        inputRef: register(),
        defaultValue: task.source || '',
      }}
      DayCheckboxProps={{
        inputRef: register({}),
        name: 'days',
      }}
      DaysOfTheWeekProps={{
        defaultValue: task.days,
        startWeekOn,
      }}
      DateRangeSliderProps={{
        taskDays: days,
        taskCount: typeof parseInt(count) === 'number' ? parseInt(count) : 0,
        defaultValue: [task.startDate, task.endDate],
        onChange: (event: React.ChangeEvent, value: Date[]) => {
          setValue('startDate', value[0].getTime());
          setValue('endDate', value[1].getTime());
        },
      }}
      startDateInputProps={{
        ref: register({ required: true }),
      }}
      endDateInputProps={{
        ref: register({ required: true }),
      }}
      {...other}
    />
  );
};
