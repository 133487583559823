import React, { useReducer, useMemo, useCallback } from 'react';
import { createContext } from '@21st-night/utils';
import {
  StudyPlanItem,
  StudyPlan,
  StudyPlanDay,
  StudyPlanWeek,
  StudyPlanWeekDay,
} from '../types';
import { generateStudyPlanWeeks } from '../api';
import dayjs from 'dayjs';

export interface StudyPlanState {
  startWeekOn: number;
  description: string | null;
  startDate: Date | null;
  endDate: Date | null;
  items: Record<string, StudyPlanItem>;
  days: Record<string, StudyPlanDay>;
}

export type LoadPlan = (plan: StudyPlan) => void;
export type LoadDays = (days: StudyPlanDay[]) => void;
export type LoadItems = (items: StudyPlanItem[]) => void;
export type Reset = () => void;
export type AddDay = (item: StudyPlanDay) => void;
export type UpdateDay = (id: string, data: Partial<StudyPlanDay>) => void;
export type AddItem = (item: StudyPlanItem) => void;
export type AddSubItem = (item: StudyPlanItem) => void;
export type RemoveItem = (item: StudyPlanItem) => void;
export type RemoveSubItem = (item: StudyPlanItem) => void;
export type UpdateItem = (id: string, data: Partial<StudyPlanItem>) => void;
export type GetWeek = (date: Date) => StudyPlanWeek | null;
export type GetDay = (date: Date) => StudyPlanWeekDay | null;

type Action =
  | { type: 'RESET' }
  | { type: 'LOAD_PLAN'; plan: StudyPlan }
  | { type: 'LOAD_DAYS'; days: StudyPlanDay[] }
  | { type: 'LOAD_ITEMS'; items: StudyPlanItem[] }
  | { type: 'ADD_DAY'; day: StudyPlanDay }
  | { type: 'UPDATE_DAY'; id: string; data: Partial<StudyPlanDay> }
  | { type: 'ADD_ITEM'; item: StudyPlanItem }
  | { type: 'ADD_SUB_ITEM'; item: StudyPlanItem }
  | { type: 'REMOVE_ITEM'; item: StudyPlanItem }
  | { type: 'REMOVE_SUB_ITEM'; item: StudyPlanItem }
  | { type: 'UPDATE_ITEM'; id: string; data: Partial<StudyPlanItem> };

export interface StudyPlanStateContext extends StudyPlanState {
  weeks: StudyPlanWeek[];
  loadPlan: LoadPlan;
  loadDays: LoadDays;
  loadItems: LoadItems;
  reset: Reset;
  addDay: AddDay;
  updateDay: UpdateDay;
  addItem: AddItem;
  addSubItem: AddSubItem;
  removeItem: AddItem;
  removeSubItem: AddSubItem;
  updateItem: UpdateItem;
  getWeek: GetWeek;
  getDay: GetDay;
}

export function generateDefaultState(): StudyPlanState {
  return {
    items: {},
    days: {},
    startDate: null,
    endDate: null,
    startWeekOn: 0,
    description: null,
  };
}

export function addItemToState(
  state: StudyPlanState,
  item: StudyPlanItem,
): StudyPlanState {
  const newState = { ...state };
  const day = state.days[item.parent];

  // Add item
  newState.items[item.id] = item;
  // Add item ID to parent day's items
  if (day && !day.items.includes(item.id)) {
    newState.days[day.id].items = [...day.items, item.id];
  }

  return newState;
}

export function addSubItemToState(
  state: StudyPlanState,
  item: StudyPlanItem,
): StudyPlanState {
  const newState = { ...state };
  const parent = state.items[item.parent];
  // Add item
  newState.items[item.id] = item;
  // Add item ID to parent parent item's subItems
  if (parent && !parent.subItems.includes(item.id)) {
    newState.items[parent.id].subItems = [...parent.subItems, item.id];
  }

  return newState;
}

export function removeItemFromState(
  state: StudyPlanState,
  item: StudyPlanItem,
): StudyPlanState {
  const newState = { ...state };
  const day = state.days[item.parent];
  // Remove item
  delete newState.items[item.id];
  // Remove item ID from parent day's items
  newState.days[day.id].items = day.items.filter(id => id !== item.id);

  return newState;
}

export function removeSubItemFromState(
  state: StudyPlanState,
  item: StudyPlanItem,
): StudyPlanState {
  const newState = { ...state };
  const parent = state.items[item.parent];
  // Remove item
  delete newState.items[item.id];
  // Remove item ID from parent parent item's subItems
  newState.items[parent.id].subItems = parent.subItems.filter(
    id => id !== item.id,
  );

  return newState;
}

const reducer = (state: StudyPlanState, action: Action): StudyPlanState => {
  switch (action.type) {
    case 'LOAD_PLAN':
      return {
        ...state,
        ...action.plan,
      };
    case 'LOAD_DAYS':
      return {
        ...state,
        days: action.days.reduce(
          (days, day) => ({ ...days, [day.id]: day }),
          {},
        ),
      };
    case 'LOAD_ITEMS':
      return {
        ...state,
        items: action.items.reduce(
          (items, item) => ({ ...items, [item.id]: item }),
          {},
        ),
      };
    case 'RESET':
      return generateDefaultState();
    case 'ADD_DAY':
      return {
        ...state,
        days: {
          ...state.days,
          [action.day.id]: action.day,
        },
      };
    case 'UPDATE_DAY':
      return {
        ...state,
        days: {
          ...state.days,
          [action.id]: {
            ...state.days[action.id],
            ...action.data,
          },
        },
      };
    case 'ADD_ITEM':
      return addItemToState(state, action.item);
    case 'ADD_SUB_ITEM':
      return addSubItemToState(state, action.item);
    case 'REMOVE_ITEM':
      return removeItemFromState(state, action.item);
    case 'REMOVE_SUB_ITEM':
      return removeSubItemFromState(state, action.item);
    case 'UPDATE_ITEM':
      return {
        ...state,
        items: {
          ...state.items,
          [action.id]: {
            ...state.items[action.id],
            ...action.data,
          },
        },
      };
    default:
      throw new Error(`StudyPlanStateProvider action was not handled`);
  }
};

const [hook, Provider, Consumer] = createContext<StudyPlanStateContext>();

export const StudyPlanStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, generateDefaultState());
  const weeks = useMemo(
    () => generateStudyPlanWeeks(state.days, state.items, state.startWeekOn),
    [state.days, state.items, state.startWeekOn],
  );

  // Load plan
  const loadPlan: LoadPlan = useCallback(
    plan => dispatch({ type: 'LOAD_PLAN', plan }),
    [],
  );

  // Load days
  const loadDays: LoadDays = useCallback(
    days => dispatch({ type: 'LOAD_DAYS', days }),
    [],
  );

  // Load items
  const loadItems: LoadItems = useCallback(
    items => dispatch({ type: 'LOAD_ITEMS', items }),
    [],
  );

  // Reset state
  const reset: Reset = useCallback(() => dispatch({ type: 'RESET' }), []);

  // Add a day
  const addDay: AddDay = useCallback(
    day => dispatch({ type: 'ADD_DAY', day }),
    [],
  );

  // Update a day
  const updateDay: UpdateDay = useCallback(
    (id, data) => dispatch({ type: 'UPDATE_DAY', id, data }),
    [],
  );

  // Add an item
  const addItem: AddItem = useCallback(
    item => dispatch({ type: 'ADD_ITEM', item }),
    [],
  );

  // Add a sub-item
  const addSubItem: AddSubItem = useCallback(
    item => dispatch({ type: 'ADD_SUB_ITEM', item }),
    [],
  );

  // Remove an item
  const removeItem: RemoveItem = useCallback(
    item => dispatch({ type: 'REMOVE_ITEM', item }),
    [],
  );

  // Remove a sub-item
  const removeSubItem: RemoveSubItem = useCallback(
    item => dispatch({ type: 'REMOVE_SUB_ITEM', item }),
    [],
  );

  // Update an item
  const updateItem: UpdateItem = useCallback(
    (id, data) => dispatch({ type: 'UPDATE_ITEM', id, data }),
    [],
  );

  // Get a StudyPlanWeek specified by a date
  const getWeek: GetWeek = useCallback(
    date => {
      const week = weeks.find(
        week =>
          (dayjs(week.startDate).isBefore(date) &&
            dayjs(week.endDate).isAfter(date)) ||
          dayjs(week.startDate).isSame(date, 'date') ||
          dayjs(week.endDate).isSame(date, 'date'),
      );

      return week || null;
    },
    [weeks],
  );

  // Get a StudyPlanWeekDay specified by a date
  const getDay: GetDay = useCallback(
    date => {
      const week = getWeek(date);

      if (!week) {
        return null;
      }

      const day = week.days.find(d => dayjs(d.date).isSame(date, 'date'));

      return day || null;
    },
    [getWeek],
  );

  return (
    <Provider
      value={{
        ...state,
        weeks,
        loadPlan,
        loadDays,
        loadItems,
        reset,
        addDay,
        updateDay,
        addItem,
        addSubItem,
        removeItem,
        removeSubItem,
        updateItem,
        getWeek,
        getDay,
      }}
    >
      {children}
    </Provider>
  );
};

export const useStudyPlanState = hook;
export const StudyPlanStateConsumer = Consumer;
