import React, { Fragment, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Rnd, DraggableData } from 'react-rnd';
import Arrow from 'react-xarrows';
import { cn, makeStyles } from '@21st-night/styles';
import { DB, Storage, Functions, useFileUpload } from '@21st-night/utils-web';
import {
  EditorDocument,
  generateRichTextDocument,
  serializeDocument,
} from '@21st-night/editor-web';

import {
  Button,
  Buttons,
  Select,
  Dialog,
  DialogProps,
  Toolbar,
  MenuItem,
  Tooltip,
  Grid,
  Backdrop,
  Hidden,
  LinearProgress,
  LoadingIndicator,
  Typography,
  DialogContent,
  DialogActions,
} from '@21st-night/ui';
import { ImageOcclusionBox } from './ImageOcclusionBox';
import { ImageOcclusionCardPreview as Card } from './ImageOcclusionCardPreview';
import {
  OcclusionBox,
  LabelBox,
  BoxSize,
  BoxPosition,
  ImageOcclusionDocumentOcclusion,
  ImageOcclusionDocument,
  QuestionMode,
  AnswerMode,
  Occlusion,
} from './ImageOcclusion.types';
import {
  imageOcclusionCreateCards as createCards,
  RawCardData,
} from './imageOcclusionCreateCards';
import { CardData } from '@21st-night/cards';

export interface ImageOcclusionDialogProps
  extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
  onClose: () => void;
  onSubmit: (cards: CardData[]) => void;
  image: File;
  storage: Storage;
  db: DB;
  functions: Functions;
}

export interface OcrVertex {
  x: number;
  y: number;
}

export interface OcrBoundingBox {
  vertices: OcrVertex[];
}

export interface OcrSymbol {
  boundingBox: OcrBoundingBox;
  text: string;
}

export interface OcrWord {
  boundingBox: OcrBoundingBox;
  symbols: OcrSymbol[];
}

export interface OcrParagraph {
  boundingBox: OcrBoundingBox;
  words: OcrWord[];
}

export interface OcrBlock {
  id: string;
  boundingBox: OcrBoundingBox;
  paragraphs: OcrParagraph[];
}

export interface OcrBlockRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

const CANVAS_PADDING = 32;

const useStyles = makeStyles(theme => ({
  loading: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    // backgroundColor: theme.palette.primary.light,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  toolbarSpacer: {
    flex: 1,
  },
  grid: {
    height: '100%',
  },
  canvasContainer: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: CANVAS_PADDING,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  canvas: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  cards: {
    height: 'calc(100vh - 80px)',
    overflowY: 'scroll',
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    '& > :not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  occlusionBoxEditMode: {
    zIndex: theme.zIndex.modal + 20,
  },
  occlusionBoxEditBackdrop: {
    zIndex: theme.zIndex.modal + 10,
    background: 'rgba(33, 33, 33, 0.15)',
  },
  labelArrowHeadDragBox: {
    width: 24,
    height: 24,
  },
  modeSelection: {
    display: 'flex',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modeSelectionOption: {
    flex: 1,
    margin: theme.spacing(1),
    textAlign: 'center',
  },
}));

function expandedBlockRect(boundingBox: OcrBoundingBox): OcrBlockRect {
  // Expand block reactangle by 10 px in all directions
  const x1 = boundingBox.vertices[0].x - 10;
  const y1 = boundingBox.vertices[0].y - 10;
  const x2 = boundingBox.vertices[1].x + 10;
  const y4 = boundingBox.vertices[3].y + 10;

  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y4 - y1,
  };
}

function valueInRange(value: number, min: number, max: number): boolean {
  return value >= min && value <= max;
}

function blocksOverlap(block1: OcrBlockRect, block2: OcrBlockRect): boolean {
  const xOverlap =
    valueInRange(block1.x, block2.x, block2.x + block2.width) ||
    valueInRange(block2.x, block1.x, block1.x + block1.width);

  const yOverlap =
    valueInRange(block1.y, block2.y, block2.y + block2.height) ||
    valueInRange(block2.y, block1.y, block1.y + block1.height);

  return xOverlap && yOverlap;
}

function getEdge(
  blocks: OcrBlock[],
  size: 'smallest' | 'largest',
  key: 'x' | 'y',
) {
  let value = blocks[0].boundingBox.vertices[0][key];
  blocks.forEach(block => {
    block.boundingBox.vertices.forEach(vertex => {
      const vetexValue = vertex[key];
      if (
        (size === 'smallest' && vetexValue < value) ||
        (size === 'largest' && vetexValue > value)
      ) {
        value = vetexValue;
      }
    });
  });

  return value;
}

export const ImageOcclusionDialog: React.FC<ImageOcclusionDialogProps> = ({
  children,
  onClose,
  image,
  storage,
  functions,
  db,
  onSubmit,
  ...other
}) => {
  const classes = useStyles();
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasContainer = useRef<HTMLDivElement>(null);
  const [questionMode, setQuestionMode] = useState<QuestionMode>('hide-all');
  const [answerMode, setAnswerMode] = useState<AnswerMode>('reveal-one');
  const [imageId, setImageId] = useState('');
  const [editBox, setEditBox] = useState('');
  const [boxes, setBoxes] = useState<Record<string, OcclusionBox | LabelBox>>(
    {},
  );
  const [createCardsDialogOpen, setCreateCardsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canvasInitialized, setCanvasInitialized] = useState(false);
  const [imageInitialized, setImageInitialized] = useState(false);
  const [canvasRect, setCanvasRect] = useState({
    width: 0,
    height: 0,
  });
  const [imageRect, setImageRect] = useState({
    width: 0,
    height: 0,
  });
  const [draggingBox, setDraggingBox] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [ocrCompleted, setOcrCompleted] = useState(false);
  const [ocrBoxesAdded, setOcrBoxesAdded] = useState(false);
  const [imageOcr, setImageOcr] = useState<OcrBlock[]>([]);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
    aspectRatio: 1,
  });
  const [, setRender] = useState({});
  const forceRerender = () => setRender({});
  const takeScreenshot = functions.httpsCallable('takeScreenshot');
  const extractTextFromImage = functions.httpsCallable('extractTextFromImage');

  async function handleUploadSuccess(downloadUrl: string, id: string) {
    setImageId(id);
    const { data } = await extractTextFromImage({ fileName: id });
    const page =
      data[0] &&
      data[0].fullTextAnnotation &&
      data[0].fullTextAnnotation.pages[0];
    setOcrCompleted(true);
    if (page) {
      setImageOcr(
        page.blocks.map((block: OcrBlock) => ({
          ...block,
          id: uuid(),
        })) as OcrBlock[],
      );
    }
  }

  const { upload, status, progress } = useFileUpload(storage, {
    onUploadSuccess: handleUploadSuccess,
  });

  function addOcclusion(
    {
      x,
      y,
      width,
      height,
      content,
    }: Pick<OcclusionBox, 'x' | 'y' | 'width' | 'height' | 'content'> = {
      x: 100,
      y: 100,
      width: 200,
      height: 120,
      content: generateRichTextDocument(),
    },
  ): void {
    const id = uuid();
    setBoxes(boxes => ({
      ...boxes,
      [id]: {
        type: 'occlusion',
        id,
        x,
        y,
        width,
        height,
        content,
      },
    }));
  }

  useEffect(() => {
    upload(image);
  }, [image]);

  useEffect(() => {
    const blockGroups: string[][] = [];
    const groupedBlocks: string[] = [];

    if (
      !ocrBoxesAdded &&
      ocrCompleted &&
      imageSize.width !== 0 &&
      imageRect.width !== 0 &&
      imageOcr.length &&
      imageInitialized &&
      canvasInitialized
    ) {
      setOcrBoxesAdded(true);
      imageOcr.forEach(block => {
        const isInGroup = groupedBlocks.includes(block.id);
        let group = blockGroups.length;
        const blockRect = expandedBlockRect(block.boundingBox);

        if (isInGroup) {
          group = blockGroups.findIndex(group => group.includes(block.id));
        } else {
          blockGroups.push([block.id]);
          groupedBlocks.push(block.id);
        }

        imageOcr.forEach(block2 => {
          const block2Rect = expandedBlockRect(block2.boundingBox);
          const doOverlap = blocksOverlap(blockRect, block2Rect);

          if (doOverlap && !blockGroups[group].includes(block2.id)) {
            blockGroups[group].push(block2.id);
            groupedBlocks.push(block2.id);
          }
        });
      });

      blockGroups.forEach(blockGroup => {
        const groupBlocks = imageOcr.filter(block =>
          blockGroup.includes(block.id),
        );

        const text: string[] = [];

        groupBlocks.forEach(block => {
          block.paragraphs.forEach(paragraph => {
            let paragraphText = '';
            paragraph.words.forEach(word => {
              let wordText = '';
              word.symbols.forEach(symbol => {
                wordText = `${wordText}${symbol.text}`;
              });
              paragraphText = `${paragraphText} ${wordText}`;
            });
            text.push(paragraphText);
          });
        });

        const x = getEdge(groupBlocks, 'smallest', 'x') / imageSize.width;
        const y = getEdge(groupBlocks, 'smallest', 'y') / imageSize.height;
        const x2 = getEdge(groupBlocks, 'largest', 'x') / imageSize.width;
        const y2 = getEdge(groupBlocks, 'largest', 'y') / imageSize.height;
        const width = x2 - x;
        const height = y2 - y;

        const adjustedX = Math.max(x * imageRect.width - 10, 0);
        let adjustedWidth = width * imageRect.width + 20;
        if (adjustedX + adjustedWidth > imageRect.width) {
          const overflow = adjustedX + adjustedWidth - imageRect.width;
          adjustedWidth = adjustedWidth - overflow;
        }
        const adjustedY = Math.max(y * imageRect.height - 10, 0);
        let adjustedHeight = height * imageRect.height + 20;
        if (adjustedY + adjustedHeight > imageRect.height) {
          const overflow = adjustedY + adjustedHeight - imageRect.height;
          adjustedHeight = adjustedHeight - overflow;
        }

        addOcclusion({
          x: adjustedX,
          y: adjustedY,
          width: adjustedWidth,
          height: adjustedHeight,
          content: generateRichTextDocument([text.join('\n')]),
        });
      });
    }
  }, [
    imageSize,
    imageRect,
    imageOcr,
    imageRect,
    ocrBoxesAdded,
    ocrCompleted,
    imageInitialized,
    canvasInitialized,
  ]);

  useEffect(() => {
    if (!canvasContainer.current) {
      return;
    }

    const rect = canvasContainer.current.getBoundingClientRect();
    setCanvasRect({
      width: rect.width - CANVAS_PADDING * 2,
      height: rect.height - CANVAS_PADDING * 2,
    });
    setCanvasInitialized(true);
  }, [canvasContainer.current]);

  useEffect(() => {
    if (!image) {
      return;
    }

    const reader = new FileReader();

    const img = new Image();

    img.onload = () => {
      setImageSize({
        height: img.height,
        width: img.width,
        aspectRatio: img.width / img.height || 1,
      });
      setImageInitialized(true);
    };

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      if (!event.target || typeof event.target.result !== 'string') {
        return;
      }

      const src = event.target.result;
      img.src = src;

      setImageSrc(src);
    };

    reader.readAsDataURL(image);
  }, [image]);

  function calculateImageRect() {
    if (!imageRef.current) {
      return;
    }
    const rect = imageRef.current.getBoundingClientRect();
    setImageRect({
      width: rect.width,
      height: rect.height,
    });
  }

  async function generateRawCardData(
    imageId: string,
    box: Occlusion,
    width: number,
    height: number,
  ): Promise<RawCardData> {
    const [question, answer] = await Promise.all([
      takeScreenshot({
        url: `${process.env.REACT_APP_UTILS_SITE_URL}/image-occlusion?imageId=${imageId}&boxId=${box.id}&mode=${questionMode}`,
        selector: '#image-loaded',
        width,
        height,
      }),
      takeScreenshot({
        url: `${process.env.REACT_APP_UTILS_SITE_URL}/image-occlusion?imageId=${imageId}&boxId=${box.id}&mode=${answerMode}`,
        selector: '#image-loaded',
        width,
        height,
      }),
    ]);

    return {
      id: box.id,
      content: box.content,
      questionImage: question.data.file,
      answerImage: answer.data.file,
    };
  }

  async function handleSubmit() {
    setLoading(true);
    setCreateCardsDialogOpen(false);
    const doc: ImageOcclusionDocument = {
      id: imageId,
      createdAt: new Date(),
      questionMode,
      answerMode,
      width: Math.floor(imageRect.width),
      height: Math.floor(imageRect.height),
      occlusions: Object.values(boxes).map(box => {
        const boxData: ImageOcclusionDocumentOcclusion = {
          type: box.type,
          width: box.width,
          height: box.height,
          x: box.x,
          y: box.y,
          content: serializeDocument(box.content),
          id: box.id,
        };
        if (box.type === 'label') {
          boxData.arrowPosition = box.arrowPosition;
        }
        return boxData;
      }),
    };

    await db.collection('image-occlusions').doc(imageId).set(doc);

    const cardImages = await Promise.all(
      Object.values(boxes).map(box => {
        return generateRawCardData(
          imageId,
          box,
          Math.floor(imageRect.width),
          Math.floor(imageRect.height),
        );
      }),
    );

    const cards = await createCards(storage, cardImages);

    onSubmit(cards);
  }

  function addLabel(): void {
    const id = uuid();
    setBoxes(boxes => ({
      ...boxes,
      [id]: {
        type: 'label',
        id,
        x: 100,
        y: 100,
        width: 200,
        height: 120,
        content: generateRichTextDocument(),
        boxRef: React.createRef(),
        arrowHeadRef: React.createRef(),
        arrowPosition: {
          x: 500,
          y: 150,
        },
      },
    }));
  }

  function handleDeleteBox(boxId: string): void {
    const nextBoxes = { ...boxes };
    delete nextBoxes[boxId];

    setBoxes(nextBoxes);
  }

  function handleBoxContentChange(boxId: string, value: EditorDocument): void {
    setBoxes(boxes => ({
      ...boxes,
      [boxId]: {
        ...boxes[boxId],
        content: value,
      },
    }));
  }

  function handleBoxPositionChange(
    boxId: string,
    position: DraggableData,
  ): void {
    setBoxes(boxes => ({
      ...boxes,
      [boxId]: {
        ...boxes[boxId],
        x: position.x,
        y: position.y,
      },
    }));
  }

  function handleBoxSizeChange(
    boxId: string,
    size: BoxSize,
    position: BoxPosition,
  ): void {
    setBoxes(boxes => ({
      ...boxes,
      [boxId]: {
        ...boxes[boxId],
        width: size.width,
        height: size.height,
        x: position.x,
        y: position.y,
      },
    }));
  }
  function handleArrowHeadPositionChange(
    boxId: string,
    position: DraggableData,
  ): void {
    setBoxes(boxes => ({
      ...boxes,
      [boxId]: {
        ...boxes[boxId],
        arrowPosition: {
          x: position.x,
          y: position.y,
        },
      },
    }));
  }

  function enableBoxEditMode(boxId: string) {
    if (!draggingBox) {
      setEditBox(boxId);
    }
  }

  return (
    <Dialog fullScreen onClose={onClose} {...other}>
      {(status !== 'complete' || !ocrCompleted) && (
        <div className={classes.loading}>
          <LoadingIndicator size="large" style={{ marginBottom: 8 }} />
          {status === 'uploading' && (
            <Typography gutterBottom variant="h6">
              Uploading image
            </Typography>
          )}
          {status !== 'uploading' && (
            <Typography gutterBottom variant="h6">
              Analyzing image text
            </Typography>
          )}
          {status === 'uploading' && (
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ width: 200 }}
            />
          )}
        </div>
      )}
      {loading && (
        <div className={classes.loading}>
          <LoadingIndicator size="large" style={{ marginBottom: 8 }} />
          <Typography gutterBottom variant="h6">
            Creating {Object.values(boxes).length} card
            {Object.values(boxes).length !== 1 ? 's' : ''}
          </Typography>
          <Typography variant="caption">
            This can take up to a couple of minutes.
          </Typography>
        </div>
      )}
      {!loading && status === 'complete' && ocrCompleted && (
        <>
          <Toolbar className={classes.toolbar}>
            <Buttons>
              <Tooltip title="Hide an existing label">
                <Button onClick={() => addOcclusion()}>Add occlusion</Button>
              </Tooltip>
              <Tooltip title="Label something in the image with an arrow">
                <Button onClick={addLabel}>Add label</Button>
              </Tooltip>
            </Buttons>
            <div className={classes.toolbarSpacer} />
            <Buttons>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateCardsDialogOpen(true)}
                disabled={!Object.values(boxes).length}
              >
                Create cards
              </Button>
            </Buttons>
          </Toolbar>
          <Grid container className={classes.grid}>
            <Grid item xs={12} lg={9}>
              <div ref={canvasContainer} className={classes.canvasContainer}>
                <div id="canvas" className={classes.canvas}>
                  {canvasInitialized && imageInitialized && (
                    <img
                      src={imageSrc}
                      ref={imageRef}
                      onLoad={calculateImageRect}
                      style={{
                        maxWidth: canvasRect.width,
                        maxHeight: canvasRect.height,
                      }}
                    />
                  )}
                  {canvasInitialized &&
                    Object.values(boxes).map(box => (
                      <Rnd
                        enableUserSelectHack
                        disableDragging={editBox === box.id}
                        onDragStop={(event, data) => {
                          handleBoxPositionChange(box.id, data);
                          setTimeout(() => {
                            setDraggingBox('');
                          }, 200);
                        }}
                        onDrag={(event, data) => {
                          if (data.x - box.x !== 0 || data.y - box.y !== 0) {
                            setDraggingBox(box.id);
                          }
                          if (box.type === 'label') {
                            forceRerender();
                          }
                        }}
                        onResizeStop={(e, direction, ref, delta, position) =>
                          handleBoxSizeChange(
                            box.id,
                            {
                              width: parseInt(ref.style.width),
                              height: parseInt(ref.style.height),
                            },
                            position,
                          )
                        }
                        bounds="parent"
                        className={cn(
                          editBox === box.id && classes.occlusionBoxEditMode,
                        )}
                        default={{
                          width: box.width,
                          height: box.height,
                          x: box.x,
                          y: box.y,
                        }}
                        key={box.id}
                      >
                        {box.type === 'occlusion' && (
                          <ImageOcclusionBox
                            editMode={editBox === box.id}
                            onClickEdit={() => enableBoxEditMode(box.id)}
                            value={box.content}
                            onClickDelete={() => handleDeleteBox(box.id)}
                            onChange={value =>
                              handleBoxContentChange(box.id, value)
                            }
                          />
                        )}
                        {box.type === 'label' && (
                          <div
                            ref={(box as LabelBox).boxRef}
                            style={{ height: '100%' }}
                          >
                            <ImageOcclusionBox
                              clickToAddContent
                              editMode={editBox === box.id}
                              onClickEdit={() => enableBoxEditMode(box.id)}
                              value={box.content}
                              onClickDelete={() => handleDeleteBox(box.id)}
                              onChange={value =>
                                handleBoxContentChange(box.id, value)
                              }
                            />
                          </div>
                        )}
                      </Rnd>
                    ))}
                  {canvasInitialized &&
                    Object.values(boxes)
                      .filter(box => box.type === 'label')
                      .map(box => (
                        <Fragment key={box.id}>
                          <Rnd
                            enableResizing={false}
                            onDragStop={(event, data) =>
                              handleArrowHeadPositionChange(box.id, data)
                            }
                            onDrag={forceRerender}
                            bounds="parent"
                            default={{
                              width: 24,
                              height: 24,
                              ...(box as LabelBox).arrowPosition,
                            }}
                          >
                            <div
                              className={classes.labelArrowHeadDragBox}
                              ref={(box as LabelBox).arrowHeadRef}
                            />
                          </Rnd>
                          <Arrow
                            path="straight"
                            color="#000"
                            headSize={6}
                            strokeWidth={3}
                            endAnchor={{
                              position: 'middle',
                              offset: { rightness: 4, bottomness: 0 },
                            }}
                            start={(box as LabelBox).boxRef}
                            end={(box as LabelBox).arrowHeadRef}
                          />
                        </Fragment>
                      ))}
                </div>
              </div>
            </Grid>
            <Hidden mdDown>
              <Grid item lg={3}>
                <div className={classes.cards}>
                  {imageInitialized &&
                    Object.values(boxes).map(box => (
                      <Card
                        key={box.id}
                        image={imageSrc}
                        canvasSize={{ ...imageRect }}
                        boxes={Object.values(boxes)}
                        boxId={box.id}
                      />
                    ))}
                </div>
              </Grid>
            </Hidden>
          </Grid>
          <Backdrop
            className={classes.occlusionBoxEditBackdrop}
            open={Boolean(editBox)}
            onClick={() => setEditBox('')}
          />
        </>
      )}
      <Dialog
        fullWidth
        disableBackdropClick
        maxWidth="sm"
        open={createCardsDialogOpen}
      >
        <DialogContent>
          {createCardsDialogOpen && (
            <div className={classes.modeSelection}>
              <div className={classes.modeSelectionOption}>
                <Typography gutterBottom variant="h6">
                  Question mode
                </Typography>
                <Card
                  image={imageSrc}
                  mode={questionMode}
                  canvasSize={{ ...imageRect }}
                  boxes={Object.values(boxes)}
                  boxId={Object.values(boxes)[0].id}
                />
                <Select
                  style={{ marginTop: 8 }}
                  value={questionMode}
                  onChange={event =>
                    setQuestionMode(event.target.value as QuestionMode)
                  }
                >
                  <MenuItem value="hide-all">Hide all</MenuItem>
                  <MenuItem value="hide-one">Hide one</MenuItem>
                </Select>
              </div>
              <div className={classes.modeSelectionOption}>
                <Typography gutterBottom variant="h6">
                  Answer mode
                </Typography>
                <Card
                  image={imageSrc}
                  mode={answerMode}
                  canvasSize={{ ...imageRect }}
                  boxes={Object.values(boxes)}
                  boxId={Object.values(boxes)[0].id}
                />
                <Select
                  style={{ marginTop: 8 }}
                  value={answerMode}
                  onChange={event =>
                    setAnswerMode(event.target.value as AnswerMode)
                  }
                >
                  <MenuItem value="reveal-one">Reveal one</MenuItem>
                  <MenuItem value="reveal-all">Reveal all</MenuItem>
                </Select>
              </div>
            </div>
          )}
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateCardsDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Create cards
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
