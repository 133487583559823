import { validateDoc, DocValidator } from '@21st-night/utils';
import { ReviewSession } from '../../Review.types';
import { NewRatingEventValidator } from './validateNewRatingEvent';

export const NewReviewSessionValidator: DocValidator<ReviewSession> = {
  id: 'string',
  startedAt: 'date',
  lastActivity: 'date',
  completed: 'boolean',
  duration: 'number',
  cards: 'string-array',
  cardAttempts: {
    type: 'map',
    keys: 'string',
    items: { type: 'array', items: NewRatingEventValidator },
  },
};

export function validateNewReviewSession(doc: ReviewSession): boolean {
  return validateDoc(NewReviewSessionValidator, doc);
}
