import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import {
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Button,
} from '@21st-night/ui';

export const FORGOT_PASSWORD_HELPER_TEXT =
  "Enter your email address below and we'll send you an email with a link to reset your password.";

export type EmailAuthenticationFormView =
  | 'login'
  | 'signup'
  | 'forgot-password';

export interface EmailAuthenticationFormFieldValues {
  email: string;
  password: string;
  repeatPassword?: string;
  terms?: boolean;
}

export interface EmailAuthenticationFormFieldErrors {
  email?: string;
  password?: string;
  repeatPassword?: string;
  terms?: string;
  other?: string;
}

export interface EmailAuthenticationFormProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  disabled?: boolean;
  disableEmailField?: boolean;
  errors?: EmailAuthenticationFormFieldErrors;
  onFieldChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: (
    view: EmailAuthenticationFormView,
    values: EmailAuthenticationFormFieldValues,
  ) => void;
  values: EmailAuthenticationFormFieldValues;
  view: EmailAuthenticationFormView;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    terms: {
      marginTop: theme.spacing(1),
    },
    termsLabel: {
      fontSize: '.8rem',
      lineHeight: '1rem',
    },
  }),
);

export const EmailAuthenticationForm: React.FC<EmailAuthenticationFormProps> = ({
  className,
  disabled,
  disableEmailField,
  errors = {},
  onFieldChange,
  onSubmit,
  values,
  view,
  ...other
}) => {
  const classes = useStyles();
  let submitButtonLabel = 'Log in';

  if (view === 'signup') {
    submitButtonLabel = 'Sign up';
  } else if (view === 'forgot-password') {
    submitButtonLabel = 'Send recovery email';
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    onSubmit(view, values);
  }

  return (
    <div className={cn(classes.root, className)} {...other}>
      {view === 'forgot-password' && (
        <FormHelperText variant="outlined">
          {FORGOT_PASSWORD_HELPER_TEXT}
        </FormHelperText>
      )}
      {errors.other && <FormHelperText error>{errors.other}</FormHelperText>}
      <form onSubmit={handleSubmit}>
        <TextField
          autoFocus
          fullWidth
          autoComplete="email"
          margin="normal"
          id="email-field"
          label="Email"
          name="email"
          type="email"
          disabled={disabled || disableEmailField}
          value={values.email}
          onChange={onFieldChange}
          error={errors.email ? { message: errors.email } : undefined}
        />
        {(view === 'login' || view === 'signup') && (
          <TextField
            fullWidth
            autoComplete="password"
            margin="normal"
            id="password-field"
            label="Password"
            name="password"
            type="password"
            disabled={disabled}
            value={values.password}
            onChange={onFieldChange}
            error={errors.password ? { message: errors.password } : undefined}
            helperText={errors.password}
          />
        )}
        {view === 'signup' && (
          <TextField
            fullWidth
            margin="normal"
            autoComplete="password"
            id="repeatPassword-field"
            label="Password again"
            name="repeatPassword"
            type="password"
            value={values.repeatPassword || ''}
            disabled={disabled}
            onChange={onFieldChange}
            error={
              errors.repeatPassword
                ? { message: errors.repeatPassword }
                : undefined
            }
            helperText={errors.repeatPassword}
          />
        )}
        {view === 'signup' && (
          <>
            <FormControlLabel
              id="terms"
              control={
                <Checkbox
                  name="terms"
                  checked={values.terms || false}
                  onChange={onFieldChange}
                  disabled={disabled}
                />
              }
              classes={{ root: classes.terms, label: classes.termsLabel }}
              label={
                <span>
                  I have read and agree to 21st Night&apos;s{' '}
                  {disabled ? (
                    'Terms of Use'
                  ) : (
                    <a
                      href="https://get21stnight.com/terms-of-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                  )}{' '}
                  and{' '}
                  {disabled ? (
                    'Privacy Policy'
                  ) : (
                    <a
                      href="https://get21stnight.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  )}
                </span>
              }
            />
            {errors.terms && (
              <FormHelperText error>{errors.terms}</FormHelperText>
            )}
          </>
        )}
        <Button
          fullWidth
          variant="contained"
          type="submit"
          color="primary"
          disabled={disabled}
          className={classes.submitButton}
        >
          {submitButtonLabel}
        </Button>
      </form>
    </div>
  );
};
