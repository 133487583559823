import { DB, DocumentReference } from '@21st-night/utils';

export function deleteStudyPlanItem(
  db: DB,
  planRef: DocumentReference,
  dayId: string,
  itemId: string,
): Promise<void[]> {
  return Promise.all([
    planRef.collection('items').doc(itemId).delete(),
    planRef
      .collection('days')
      .doc(dayId)
      .update({ items: db.arrayRemove(itemId) }),
  ]);
}
