import React, { useState } from 'react';
import { Button, Spaced } from '@21st-night/ui';
import { EmailButton } from '../EmailButton';
import { GoogleButton } from '../GoogleButton';
import { AppleButton } from '../AppleButton';
import {
  EmailAuthenticationForm,
  EmailAuthenticationFormProps,
} from '../EmailAuthenticationForm';

export interface SignupFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onClickGoogleButton: () => void;
  onClickAppleButton: () => void;
  onSubmitEmailForm: EmailAuthenticationFormProps['onSubmit'];
  emailFormValues: EmailAuthenticationFormProps['values'];
  emailFormErrors: EmailAuthenticationFormProps['errors'];
  onEmailFormFieldChange: EmailAuthenticationFormProps['onFieldChange'];
  disableEmailForm?: boolean;
}

export const SignupForm: React.FC<SignupFormProps> = ({
  onClickAppleButton,
  onClickGoogleButton,
  onEmailFormFieldChange,
  emailFormValues,
  emailFormErrors,
  onSubmitEmailForm,
  disableEmailForm,
}) => {
  const [showEmailAuth, setShowEmailAuth] = useState(false);

  function onClickEmailButton() {
    setShowEmailAuth(true);
  }

  function backToMainView() {
    setShowEmailAuth(false);
  }

  return (
    <>
      {!showEmailAuth && (
        <Spaced vertical spacing={2}>
          <EmailButton variant="sign-up" onClick={onClickEmailButton} />
          <GoogleButton onClick={onClickGoogleButton} />
          <AppleButton onClick={onClickAppleButton} />
        </Spaced>
      )}

      {showEmailAuth && (
        <>
          <Button variant="text" color="primary" onClick={backToMainView}>
            Back
          </Button>

          <EmailAuthenticationForm
            view="signup"
            disabled={disableEmailForm}
            onFieldChange={onEmailFormFieldChange}
            onSubmit={onSubmitEmailForm}
            values={emailFormValues}
            errors={emailFormErrors}
          />
        </>
      )}
    </>
  );
};
