import { DB } from '@21st-night/utils';
import { updateNote } from '../updateNote';

export function removeCardLink(
  db: DB,
  noteId: string,
  cardId: string,
): Promise<void> {
  return updateNote(db, noteId, {
    cards: db.arrayRemove(cardId),
  });
}
