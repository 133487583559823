import { EditorProps, BraindropEditor } from '@braindrop-editor/core';
import {
  createRichTextPlugin,
  EditorWithRichTextPlugin,
} from '@braindrop-editor/plugin-rich-text';
import {
  createHeadingPlugin,
  EditorWithHeadingPlugin,
} from '@braindrop-editor/plugin-heading';
import { createOrderedListPlugin } from '@braindrop-editor/plugin-ordered-list';
import { createUnorderedListPlugin } from '@braindrop-editor/plugin-unordered-list';
import {
  createCodePlugin,
  EditorWithCodePlugin,
} from '@braindrop-editor/plugin-code';
import { createBlockquotePlugin } from '@braindrop-editor/plugin-blockquote';
import { createParagraphPlugin } from '@braindrop-editor/plugin-paragraph';
import { Node as SlateNode } from 'slate';
import { EditorDocument } from '@21st-night/editor';
import { OLItem } from './components/OLItem';
import { ULItem } from './components/ULItem';
import { ElementCode } from './components/ElementCode';
import {
  createEditorApiPlugin,
  EditorWithApiPlugin,
} from './plugins/plugin-editor-api';

export type Node = SlateNode;

export interface CoreEditorProps
  extends Partial<
    Pick<
      EditorProps,
      | 'placeholder'
      | 'plugins'
      | 'spellCheck'
      | 'onSelectionChange'
      | 'readOnly'
      | 'autoFocus'
    >
  > {
  onChange: (value: EditorDocument) => void;
  value: EditorDocument;
}

export interface CoreEditor
  extends BraindropEditor,
    EditorWithApiPlugin,
    EditorWithRichTextPlugin,
    EditorWithHeadingPlugin,
    EditorWithCodePlugin {}

export const RichTextPlugin = createRichTextPlugin({
  marks: {
    bold: 'b',
    italic: 'i',
    'strike-through': 's',
    underline: 'u',
  },
});
export const HeadingPlugin = createHeadingPlugin({
  types: {
    'heading-1': 'h1',
    'heading-2': 'h2',
  },
  hotkeys: {
    'heading-1': 'mod+alt+1',
    'heading-2': 'mod+alt+2',
    'heading-3': false,
  },
});

export const OLPlugin = createOrderedListPlugin({
  // component: OLItem,
  hotkeys: ['mod+alt+5'],
});

export const ULPlugin = createUnorderedListPlugin({
  // component: ULItem,
  hotkeys: ['mod+alt+4'],
});

export const CodePlugin = createCodePlugin({
  block: { hotkeys: ['mod+alt+9'], component: ElementCode },
});

export const ParagraphPlugin = createParagraphPlugin();

export const ApiPlugin = createEditorApiPlugin();

const BlockquotePlugin = createBlockquotePlugin({
  hotkeys: ['mod+alt+3'],
});

export const corePlugins = [
  ApiPlugin,
  RichTextPlugin,
  ParagraphPlugin,
  HeadingPlugin,
  OLPlugin,
  ULPlugin,
  CodePlugin,
  BlockquotePlugin,
];
