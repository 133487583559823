import { EditorDocument } from '../../Editor.types';
import { deserializeLegacyDocument } from '../deserializeLegacyDocument';
import { generateRichTextDocument } from '../generateRichTextDocument';

export function deserializeDocument(document: string): EditorDocument {
  try {
    let doc = JSON.parse(document) as EditorDocument;

    if (!doc[0] || doc[0].type !== 'metadata') {
      doc = deserializeLegacyDocument(document);
    }

    return doc;
  } catch (err) {
    return generateRichTextDocument();
  }
}
