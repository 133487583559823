import { CardType } from '@21st-night/cards';
import {
  generateCardReviewProgress,
  generateFlashcardReviewProgress,
  CardReviewProgress,
} from '@21st-night/review';

/**
 * Generates DeckCardReviewData.
 */
export function generateDeckCardReviewData(
  type: CardType,
  options: Partial<CardReviewProgress> = {},
): CardReviewProgress {
  let data: CardReviewProgress = {
    ...generateCardReviewProgress(type, options),
    ...options,
  };

  if (type === 'flashcard') {
    data = {
      ...data,
      ...generateFlashcardReviewProgress(),
      ...options,
    };
  }

  return data;
}
