import { DocumentReference } from '@21st-night/utils';
import { updateDeckNote } from '../updateDeckNote';

export function starNote(
  deckRef: DocumentReference,
  noteId: string,
): Promise<void> {
  return updateDeckNote(deckRef, noteId, {
    starred: true,
  });
}
