import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { VisibilityOff, RemoveBox } from '@21st-night/icons';
import { Button } from '@21st-night/ui';
import { useSlate } from 'slate-react';
import { Editor, isElementOfType } from '@21st-night/editor-web';
import { Range, Node, Transforms, NodeEntry, Descendant } from 'slate';
import { useUI } from '@braindrop-editor/core';

interface EditorClozeDeletionButtonProps {
  mobile?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
  },
  startIcon: {
    marginRight: 4,
  },
  mobile: {
    color: theme.palette.grey[600],
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
  },
}));

const getSelectionClozeDeletions = (
  editor: Editor,
): NodeEntry<Descendant>[] => {
  const nodes: NodeEntry<Descendant>[] = [];
  const fragment = Node.fragment(editor, editor.selection as Range);
  fragment.forEach(node => {
    const fragmentNodes = Array.from(Node.descendants(node));
    fragmentNodes.forEach(entry => {
      if (isElementOfType(entry[0], 'cloze-deletion')) {
        nodes.push(entry);
      }
    });
  });

  return nodes;
};

export const EditorClozeDeletionButton: React.FC<EditorClozeDeletionButtonProps> = ({
  mobile,
  ...other
}) => {
  const [clozeDeletionsInSelection, setCardLinksInSelection] = useState(0);
  const { Tooltip } = useUI();
  const classes = useStyles();
  const editor = useSlate() as Editor;

  useEffect(() => {
    if (editor.selection) {
      setCardLinksInSelection(0);
      setCardLinksInSelection(getSelectionClozeDeletions(editor).length);
    }
  }, [editor.selection]);

  const handleClickAdd = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!editor.selection) {
        return;
      }

      if (Range.isExpanded(editor.selection)) {
        Transforms.wrapNodes(
          editor,
          editor.generateElement('cloze-deletion', {
            hint: '',
            wrapped: true,
            showHintField: true,
          }),
          {
            at: editor.selection,
            split: true,
          },
        );
        Transforms.collapse(editor, { edge: 'end' });
      } else {
        Transforms.insertNodes(
          editor,
          editor.generateElement('cloze-deletion', {
            hint: '',
            inserted: true,
            showHintField: true,
          }),
          {
            at: editor.selection,
          },
        );
      }
    },
    [editor.selection],
  );

  const handleClickRemove = useCallback(() => {
    if (editor.selection) {
      Transforms.unwrapNodes(editor, {
        at: editor.selection,
        match: node => isElementOfType(node, 'cloze-deletion'),
        split: true,
      });
    }
  }, [editor.selection]);

  if (mobile) {
    return (
      <Button
        variant="text"
        size="small"
        onMouseDown={handleClickAdd}
        classes={{
          root: classes.mobile,
          startIcon: classes.startIcon,
        }}
        startIcon={<VisibilityOff />}
        {...other}
      >
        Cloze
      </Button>
    );
  }

  return (
    <>
      {clozeDeletionsInSelection === 0 && (
        <Tooltip title="Create cloze deletion">
          <Button
            variant="text"
            size="small"
            onClick={handleClickAdd}
            classes={{
              root: classes.root,
              startIcon: classes.startIcon,
            }}
            startIcon={<VisibilityOff />}
            {...other}
          >
            Cloze deletion
          </Button>
        </Tooltip>
      )}
      {clozeDeletionsInSelection > 0 && (
        <Button
          variant="text"
          size="small"
          onClick={handleClickRemove}
          classes={{
            root: classes.root,
            startIcon: classes.startIcon,
          }}
          startIcon={<RemoveBox />}
          {...other}
        >
          Remove cloze deletion{clozeDeletionsInSelection > 1 && 's'}
        </Button>
      )}
    </>
  );
};
