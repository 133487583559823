import { BraindropEditor, Transforms } from '@braindrop-editor/core';
import { parseNativeEditorEvent } from '@21st-night/web-view';
import { ReactEditor } from 'slate-react';

export function handleNativeEditorMessage(
  editor: BraindropEditor,
  message: string,
): void {
  const event = parseNativeEditorEvent(message);

  if (event.type !== 'INSERT_AUDIO') {
    return;
  }

  ReactEditor.focus(editor);

  setTimeout(() => {
    const { fileId, fileName, fileSize } = event.payload;

    Transforms.insertNodes(
      editor,
      editor.generateElement('audio', {
        fileName,
        fileId,
        fileSize,
      }),
    );
  });
}
