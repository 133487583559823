import React, { useState, useRef, useCallback, useEffect } from 'react';
import filesize from 'filesize';
import { Transforms } from 'slate';
import { RenderElementProps } from '@braindrop-editor/core';
import { makeStyles, cn } from '@21st-night/styles';
import Player from 'react-player';
import {
  InsertPhoto,
  AddBlockBelow,
  AddBlockAbove,
  Delete,
} from '@21st-night/icons';
import { ReactEditor, useSlate, useSelected } from 'slate-react';
import { LoadingIndicator, Typography } from '@21st-night/ui';
import {
  parseNativeEditorFileEvent,
  addWebViewMessageListener,
  removeWebViewMessageListener,
} from '@21st-night/web-view';
import { BlockPlaceholder } from '../../../components';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';
import { WebEditor } from '../../../web-editor-plugins';
import { AudioElement } from '../AudioPlugin.types';

export interface MobileAudioElement extends AudioElement {
  fileId: string;
  fileName: string;
  fileSize: number;
}

export interface ElementAudioMobileProps
  extends Omit<RenderElementProps, 'element'> {
  element: MobileAudioElement;
}

export interface FileData {
  name: string;
  size: number;
}
export type Status = 'upload' | 'display' | 'error';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  hasAudio: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  blockPlaceholder: {
    zIndex: 30,
  },
  popoverContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  helperText: {
    userSelect: 'none',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  qrCode: {
    margin: theme.spacing(1),
  },
  fileInput: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    left: -1000,
    top: -1000,
  },
  uploadProgress: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
  fetchingProgress: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
  selectedOverlay: {
    zIndex: 40,
    cursor: 'pointer',
    backgroundColor: 'rgba(33, 150, 243, 0.3)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  player: {
    userSelect: 'none',
    maxHeight: 56,
    margin: theme.spacing(1, 0),
    '& > audio': {
      outline: 'none',
      height: 56,
    },
  },
}));

export const ElementAudioMobile: React.FC<ElementAudioMobileProps> = ({
  attributes,
  children,
  element,
}) => {
  const classes = useStyles();
  const editor = useSlate() as WebEditor;
  const selected = useSelected();
  const [active, setActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [status, setStatus] = useState<Status>(
    element.file ? 'display' : 'upload',
  );

  const divRef = attributes.ref || useRef<HTMLDivElement>(null);

  const updateNodeData = useCallback((data: Partial<MobileAudioElement>) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, data, { at: path });
    ReactEditor.blur(editor);
  }, []);

  useEffect(() => {
    if (!selected) {
      setActive(false);
    }
  }, [selected]);

  useEffect(() => {
    function handleMessage(message: string) {
      const event = parseNativeEditorFileEvent(message, element.fileId);

      switch (event.type) {
        case 'FILE_UPLOAD_PROGRESS':
          setProgress(event.payload.progress);
          break;
        case 'FILE_UPLOAD_ERROR':
          setStatus('error');
          setError(event.payload.error);
          break;
        case 'FILE_UPLOAD_COMPLETE':
          setStatus('display');
          updateNodeData({
            file: event.payload.fileId,
            url: event.payload.url,
          });
          break;
        default:
      }
    }

    addWebViewMessageListener(handleMessage);

    return () => removeWebViewMessageListener(handleMessage);
  }, [element]);

  const deleteNode = useCallback(() => {
    editor.deleteElement(element);
  }, [element]);

  return (
    <div
      {...attributes}
      ref={divRef}
      className={cn(classes.root, element.file && classes.hasAudio)}
      contentEditable={false}
    >
      {!element.file && (
        <BlockPlaceholder
          onClick={() => null}
          className={classes.blockPlaceholder}
          onClickDelete={deleteNode}
          label={
            <div>
              {status === 'upload' && (
                <div>
                  <div>{element.fileName}</div>
                  <div className={classes.uploadProgress}>
                    {filesize(element.fileSize)} —{' '}
                    <LoadingIndicator size={14} />{' '}
                    <span style={{ marginLeft: 4 }}>{progress}%</span>
                  </div>
                </div>
              )}
              {error && (
                <Typography color="error" variant="caption">
                  {error}
                </Typography>
              )}
            </div>
          }
          icon={<InsertPhoto />}
        />
      )}
      {element.url && !element.base64 && (
        <div contentEditable={false}>
          <Player
            config={{ file: { forceAudio: true } }}
            controls
            url={element.url as string}
            width="100%"
            className={classes.player}
          />
        </div>
      )}
      {element.base64 && (
        <div contentEditable={false}>
          <Player
            config={{ file: { forceAudio: true } }}
            controls
            url={element.base64 as string}
            width="100%"
            className={classes.player}
          />
        </div>
      )}
      {active && (element.file || element.base64) && (
        <div
          role="button"
          // onDoubleClick={handleOpenFullScreen}
          className={classes.selectedOverlay}
        />
      )}
      {(element.file || element.base64) && (
        <div className={classes.toolbar}>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph above"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphAbove(element);
            }}
          >
            <AddBlockAbove />
          </EditorToolbarButton>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph below"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphBelow(element);
            }}
          >
            <AddBlockBelow />
          </EditorToolbarButton>
          <EditorToolbarButton contrast onClick={deleteNode}>
            <Delete />
          </EditorToolbarButton>
        </div>
      )}
      {children}
    </div>
  );
};
