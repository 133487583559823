import { WebViewWindow } from '../../types';

declare let window: WebViewWindow;

export function postMessageToWebView(message: string): void {
  if (!window.webViewEventListeners) {
    return;
  }

  window.webViewEventListeners.forEach(listener => listener(message));
}
