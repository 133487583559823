import { DB, DocumentReference } from '@21st-night/utils';
import dayjs from 'dayjs';
import { DeckAnalyticsEvent } from '../../Analytics.types';

export async function logTimeSpent(
  db: DB,
  deckRef: DocumentReference,
  data: Omit<DeckAnalyticsEvent, 'createdAt'>,
): Promise<void> {
  const docId = dayjs(new Date()).format('DD-MM-YYYY');
  const existingLog = await deckRef.collection('time-logs').doc(docId).get();

  if (!existingLog.exists) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    await deckRef
      .collection('time-logs')
      .doc(docId)
      .set({ date, notes: 0, deck: 0 });
  }

  deckRef
    .collection('time-logs')
    .doc(docId)
    .set(
      {
        [data.type]: db.increment(data.time),
      },
      { merge: true },
    );
}
