import { Note } from '@21st-night/notes';
import { DeckNote } from '../../Deck.types';

type Options = Partial<
  Pick<DeckNote, 'category' | 'subcategories' | 'starred'>
>;

/**
 * Generates a DeckNote from a Note with optional
 * DeckNote properties configuration.
 */
export function generateDeckNote(note: Note, options: Options = {}): DeckNote {
  return {
    createdAt: note.createdAt,
    id: note.id,
    type: note.type,
    ...options,
    category: options.category || null,
    subcategories: options.subcategories || [],
  };
}
