import React, { useState, useRef } from 'react';
import { captureException } from '@sentry/browser';
import { Storage, UploadTask } from '@21st-night/utils';
import { v4 as uuid } from 'uuid';

type status = 'empty' | 'uploading' | 'complete' | 'error';

export interface ImageDimensions {
  width: number;
  height: number;
  aspectRatio: number;
}

export interface UseFileUploadOptions {
  /**
   * Maximum file size in MB.
   */
  maxSize?: number;
  onGetDimensions?: (dimensions: ImageDimensions) => void;
  onUploadStart?: (id: string, file: File) => void;
  onUploadSuccess?: (
    downloadURL: string,
    id: string,
    file: File,
  ) => void | Promise<void>;
}

export interface UseFileUpload {
  clear: () => void;
  error: string;
  file: File | null;
  id: string | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  progress: number;
  status: status;
  upload: (file: File) => void;
  url: string;
  value: string;
  dimensions?: ImageDimensions;
}

/**
 * Hook for handling single file uploads.
 */
export function useFileUpload(
  storage: Storage,
  {
    onUploadSuccess,
    onUploadStart,
    onGetDimensions,
    maxSize = 10,
  }: UseFileUploadOptions,
): UseFileUpload {
  // Used to get image file dimensions
  const { current: image } = useRef(new Image());
  const { current: reader } = useRef(new FileReader());
  const [dimensions, setDimensions] = useState<ImageDimensions>();
  // ID of the file stored in Firebase Cloud Storage
  const [storageId, setStorageId] = useState<string | null>(null);
  // Status is one of: empty, processing, uploading, complete
  const [status, setStatus] = useState<status>('empty');
  // The latest file object from the input file list
  const [file, setFile] = useState<null | File>(null);
  // The file name, used to clear the input
  // by setting it to an empty string
  const [value, setValue] = useState('');
  // The uploaded file's download URL
  const [url, setUrl] = useState('');
  // File upload progress percentage
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  // The firebase storage upload task
  let uploadTask: UploadTask;

  reader.onload = (event: ProgressEvent<FileReader>): void => {
    if (!event.target || typeof event.target.result !== 'string') {
      return;
    }

    image.src = event.target.result;
  };

  image.onload = (): void => {
    const dimens = {
      height: image.height,
      width: image.width,
      aspectRatio: image.width / image.height || 1,
    };
    if (onGetDimensions) {
      onGetDimensions(dimens);
    }
    setDimensions(dimens);
  };

  // Callback applied to a file input field
  function upload(uploadFile: File): void {
    if (uploadFile) {
      const id = `${uuid()}.${uploadFile.name.split('.').pop()}`;
      setStorageId(id);
      setFile(uploadFile);
      setValue(uploadFile.name);

      if (uploadFile.size / 1024 / 1024 > maxSize) {
        setStatus('error');
        setError(`Your file is over the ${maxSize}MB limit.`);
        return;
      }

      // If the file is an image, read it with the reader
      // in order to get its dimensions
      if (uploadFile.type && uploadFile.type.substr(0, 5) === 'image') {
        reader.readAsDataURL(uploadFile);
      }

      try {
        // Upload file
        setStatus('uploading');
        uploadTask = storage.ref(id).put(uploadFile);
        if (onUploadStart) {
          onUploadStart(id, uploadFile);
        }

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          'state_changed',
          snapshot => {
            // Observe progress state
            // Calculate task progress by dividing the number of bytes
            // uploaded by the total number of bytes to be uploaded
            setProgress(
              Math.floor(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
              ),
            );
          },
          error => {
            // Handle unsuccessful uploads
            setError(error.message);
            // Log the error with Sentry
            captureException(error);
          },
          () => {
            // Handle successful uploads
            // Get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              setStatus('complete');
              setUrl(downloadURL);

              // If an onUploadSuccess callback is
              // defined, call it
              if (uploadFile && onUploadSuccess) {
                onUploadSuccess(downloadURL, id, uploadFile);
              }
            });
          },
        );
      } catch (error) {
        setError(error);
        // Log the error with Sentry
        captureException(error);
      }
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // This hook only supports single file uploads
    // so we get the first file from the file list
    if (event.target && event.target.files) {
      const uploadFile = event.target.files[0];
      upload(uploadFile);
    }
  }

  // Clear the field and reset internal state
  function clear(): void {
    setValue('');
    setFile(null);
    setUrl('');
    setError('');
    setProgress(0);
    setStatus('empty');
    // Cancel the upload if it exists
    if (typeof uploadTask !== 'undefined') {
      uploadTask.cancel();
    }
  }

  return {
    file,
    value,
    onChange,
    upload,
    status,
    url,
    error,
    progress,
    clear,
    dimensions,
    id: storageId,
  };
}
