import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { ZoomIn, ZoomOut, ArrowForward, ArrowBack } from '@21st-night/icons';
import { IconButton } from '../IconButton';
import { LoadingIndicator } from '../LoadingIndicator';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PdfViewerProps extends React.HTMLAttributes<HTMLDivElement> {
  file: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      height: '100%',
    },
    toolbar: {
      padding: theme.spacing(0, 1, 1, 1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    loading: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pdf: {
      display: 'flex',
      justifyContent: 'center',
      overflow: 'scroll',
      maxHeight: 500,
    },
  }),
);

export const PdfViewer: React.FC<PdfViewerProps> = ({
  className,
  file,
  ...other
}) => {
  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(500);
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (container.current) {
      setWidth(container.current.clientWidth);
    }
  }, []);

  function onPageLoadSuccess(): void {
    if (container.current) {
      setHeight(container.current.clientHeight);
    }
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function nextPage(): void {
    if (pageNumber < numPages) {
      setPageNumber(number => number + 1);
    }
  }

  function previousPage(): void {
    if (pageNumber > 1) {
      setPageNumber(number => number - 1);
    }
  }

  function zoomIn(): void {
    if (scale < 3) {
      setScale(number => number + 0.5);
    }
  }

  function zoomOut(): void {
    if (scale > 0.5) {
      setScale(number => number - 0.5);
    }
  }

  return (
    <div ref={container} className={cn(classes.root, className)} {...other}>
      <div className={classes.toolbar}>
        <div>
          <IconButton onClick={zoomOut} disabled={scale === 0.5}>
            <ZoomOut />
          </IconButton>
          <IconButton onClick={zoomIn} disabled={scale === 3}>
            <ZoomIn />
          </IconButton>
        </div>
        <div>
          <IconButton onClick={previousPage} disabled={pageNumber === 1}>
            <ArrowBack />
          </IconButton>
          {pageNumber}/{numPages}
          <IconButton onClick={nextPage} disabled={pageNumber === numPages}>
            <ArrowForward />
          </IconButton>
        </div>
        <div style={{ width: 96 }} />
      </div>
      <div className={classes.pdf} style={{ width, height }}>
        <Document onLoadSuccess={onDocumentLoadSuccess} file={file}>
          <Page
            scale={scale}
            pageNumber={pageNumber}
            width={width}
            onLoadSuccess={onPageLoadSuccess}
            loading={
              <div className={classes.loading} style={{ height }}>
                <LoadingIndicator size="medium" />
              </div>
            }
          />
        </Document>
      </div>
    </div>
  );
};
