import React, { useEffect, useState } from 'react';
import { useFirebase } from '@21st-night/utils';
import { useTimeActive } from '@21st-night/utils-web';
import { createAnalyticsEvent } from '@21st-night/analytics-web';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import {
  Card,
  createCard,
  generateCard,
  getSelectionCardLinks,
  removeNoteLink,
} from '@21st-night/cards-web';
import { addCardLink, addContentCardLink } from '@21st-night/notes-web';
import { deserializeDocument } from '@21st-night/editor-web';
import {
  useDeck,
  DeckCardEditorData,
  generateCardCheatSheetItem,
  generateDeckCard,
  generateDeckCardReviewData,
} from '@21st-night/deck';
import {
  DeckCardEditorDialog,
  DeckCardEditorDialogProps,
} from '../DeckCardEditorDialog';

export interface DeckCreateCardDialogProps
  extends Omit<
    DeckCardEditorDialogProps,
    'card' | 'submitButtonLabel' | 'onSubmit'
  > {
  type: Card['type'];
  imageUrl: string;
  enableAnalytics?: boolean;
  enableGamification?: boolean;
}

export const DeckCreateCardDialog: React.FC<DeckCreateCardDialogProps> = ({
  type,
  open,
  enableAnalytics,
  enableGamification,
  ...other
}) => {
  const { db, auth } = useFirebase();
  const [time] = useTimeActive(30);
  const deck = useDeck();
  const [categoriesData, setCategoriesData] = useState<
    Partial<DeckCardEditorData>
  >({
    subcategories: deck.subcategoryFilters.map(filter => filter.value),
    category: deck.categoryFilters[0] ? deck.categoryFilters[0].value : null,
  });

  useEffect(() => {
    if (open) {
      setCategoriesData({
        subcategories: deck.subcategoryFilters.map(filter => filter.value),
        category: deck.categoryFilters[0]
          ? deck.categoryFilters[0].value
          : null,
      });
    }
  }, [open, deck.categoryFilters, deck.subcategoryFilters]);

  function handleSubmit(data: DeckCardEditorData) {
    if (!auth.currentUser) {
      return;
    }

    const card = {
      ...generateCard({
        type,
        author: auth.currentUser.uid,
        deck: deck.id,
        note: data.note,
        question: data.question,
        answer: data.answer,
        explanation: data.explanation,
        summary: data.summary,
      }),
    };

    createCard(db, card);

    let deckCard = generateDeckCard(card, {
      category: data.category,
      subcategories: data.subcategories,
    });

    if (deck.type === 'student') {
      deckCard = { ...deckCard, ...generateDeckCardReviewData(type) };

      // First card should be due immedeatly for
      // onboarding purposes
      if (deck.cards.length === 0) {
        deckCard.nextReview = new Date();
      }
    }

    deck.addCardToDeck(deckCard);

    if (type === 'error-log' && card.summary) {
      deck.addCardToCheatSheet(
        generateCardCheatSheetItem({ id: card.id, summary: card.summary }),
      );
    }

    if (enableAnalytics) {
      // Add time spent creating card to deck analytics
      createAnalyticsEvent(deck.docRef, {
        type: 'create-card',
        card: card.id,
        time,
        createdAt: new Date(),
      });
    }

    if (enableGamification) {
      incrementStreak(db, auth.currentUser.uid);
      incrementStatistic(db, auth.currentUser.uid, 'cards-created');
    }

    if (data.note) {
      if (!data.noteSelection) {
        addCardLink(db, data.note, card.id);
      } else {
        const note = deck.notes.find(deckNote => deckNote.id === data.note);
        if (note) {
          const existingLinks = getSelectionCardLinks(
            deserializeDocument(note.content),
            data.noteSelection,
          );
          if (existingLinks.length) {
            existingLinks.forEach(([node]) => {
              removeNoteLink(db, node.cardId);
            });
          }
          addContentCardLink(db, note, data.noteSelection, card.id);
        }
      }
    }
  }

  return (
    <DeckCardEditorDialog
      submitButtonLabel="Create"
      type={type}
      open={open}
      onSubmit={handleSubmit}
      data={categoriesData}
      {...other}
    />
  );
};
