import { toDate } from '@21st-night/utils';
import {
  StudyPlanTaskTemplate,
  StudyPlanTaskTemplateDocument,
} from '../../types';

export function deserializeStudyPlanTaskTemplate(
  template: StudyPlanTaskTemplateDocument,
): StudyPlanTaskTemplate {
  return {
    ...template,
    createdAt: toDate(template.createdAt),
  };
}
