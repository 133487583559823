import React from 'react';
import { BraindropEditorPlugin, BraindropEditor } from '@braindrop-editor/core';
import { CardQuestionElement, Editor } from '@21st-night/editor-web';
import { ElementQuestion } from './ElementQuestion';
import { CardFormEditable } from './CardFormEditable';
import { addCardFormNormalizer } from './addCardFormNormalizer';

interface CardFormPluginOptions {
  position?: DOMRect;
  onClickSubmitFlashcard: (event: React.MouseEvent) => void;
  onClickSubmitErrorLog: (event: React.MouseEvent) => void;
  onClickCancel: (event: React.MouseEvent) => void;
}

export const createCardFormPlugin = ({
  position,
  onClickSubmitFlashcard,
  onClickSubmitErrorLog,
  onClickCancel,
}: CardFormPluginOptions) => (
  baseEditor: BraindropEditor,
): BraindropEditorPlugin<CardQuestionElement> => {
  const editor = baseEditor as Editor;
  const { renderEditable } = editor;

  editor.renderEditable = props => (
    <CardFormEditable
      onClickSubmitFlashcard={onClickSubmitFlashcard}
      onClickSubmitErrorLog={onClickSubmitErrorLog}
      onClickCancel={onClickCancel}
      position={position}
    >
      {renderEditable(props)}
    </CardFormEditable>
  );

  addCardFormNormalizer(editor);

  return {
    elements: [
      {
        type: 'card-question',
        component: ElementQuestion,
      },
    ],
  };
};
