import React from 'react';
import {
  Hidden as MuiHidden,
  HiddenProps as MuiHiddenProps,
} from '@material-ui/core';

export type HiddenProps = MuiHiddenProps;

export const Hidden: React.FC<HiddenProps> = ({ ...other }) => {
  return <MuiHidden {...other} />;
};
