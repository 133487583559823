import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import isHotkey from 'is-hotkey';
import { createPortal } from 'react-dom';
import filesize from 'filesize';
import { Range, Transforms } from 'slate';
import {
  RenderElementProps,
  useEditorState,
  useUI,
} from '@braindrop-editor/core';
import QRCode from 'qrcode.react';
import { makeStyles, useTheme, cn } from '@21st-night/styles';
import {
  InsertPhoto,
  AddBlockBelow,
  AddBlockAbove,
  MoreHoriz,
  Delete,
  Crop as CropIcon,
  Replace,
  Fullscreen,
} from '@21st-night/icons';
import {
  Button,
  LoadingIndicator,
  Typography,
  Backdrop,
  Button as UiButton,
} from '@21st-night/ui';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { useFileUpload, DB, Storage, Functions } from '@21st-night/utils-web';
import { ReactEditor, useSlate, useSelected } from 'slate-react';
import { generateRichTextDocument, ImageElementCrop } from '@21st-night/editor';
import { BlockPlaceholder } from '../../../components';
import { Tab } from '../../../components/EditorPopoverTab';
import { Tabs } from '../../../components/EditorPopoverTabs';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';
import { EditorWithApiPlugin } from '../../plugin-editor-api';
import {
  FileData,
  ImageNodeData,
  MobileUploadDocument,
  TabsValue,
  ImageElement,
  EditorWithImagePlugin,
} from '../ImagePlugins.types';

export interface ElementImageProps extends Omit<RenderElementProps, 'element'> {
  element: ImageElement;
  functions: Functions;
  storage: Storage;
  db: DB;
  imageUrl: string;
}

export type ExtractTextStatus =
  | 'waiting'
  | 'analyzing'
  | 'ready'
  | 'no-text'
  | 'error';

interface OCRSymbol {
  text: string;
}

interface OCRWord {
  symbols: OCRSymbol[];
}

interface OCRParagraph {
  words: OCRWord[];
}

interface OCRBlock {
  paragraphs: OCRParagraph[];
}

interface OCRPage {
  blocks: OCRBlock[];
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  hasImage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  blockPlaceholder: {
    zIndex: 30,
  },
  popoverContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  helperText: {
    userSelect: 'none',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  qrCode: {
    margin: theme.spacing(1),
  },
  fileInput: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    left: -1000,
    top: -1000,
  },
  uploadProgress: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
  fetchingProgress: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
  selectedOverlay: {
    zIndex: 40,
    cursor: 'pointer',
    backgroundColor: 'rgba(33, 150, 243, 0.3)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  menuPopoverAnchor: {
    position: 'absolute',
    top: theme.spacing(1) + 4,
    right: theme.spacing(1) + 4,
  },
  fullScreenBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: 60,
  },
  fullScreenImage: {
    maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,
    maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
  },
  fullScreenToolbar: {
    zIndex: 70,
    borderRadius: 3,
    padding: 4,
    color: '#FFF',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
}));

export const ElementImage: React.FC<ElementImageProps> = ({
  attributes,
  children,
  element,
  storage,
  db,
  imageUrl,
  functions,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const editor = useSlate() as EditorWithImagePlugin & EditorWithApiPlugin;
  const selected = useSelected();
  const fileInput = useRef<HTMLInputElement>(null);
  const [mobileUpload, setMobileUpload] = useState<MobileUploadDocument | null>(
    null,
  );
  const [active, setActive] = useState(false);

  const [tab, setTab] = useState<TabsValue>(element.initialTab || 'upload');
  const [link, setLink] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [cropMode, setCropMode] = useState(false);
  const [crop, setCrop] = useState<ImageElementCrop>(
    element.crop || {
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    },
  );
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [fetchingImage, setFetchingImage] = useState(false);
  const [fetchingError, setFetchingError] = useState('');
  const [extractStatus, setExtractStatus] = useState<ExtractTextStatus>(
    'waiting',
  );
  const { Popover, TextField, List, MenuItem } = useUI();
  const { toggle, turnOff, turnOn } = useEditorState();
  const divRef = attributes.ref || useRef<HTMLDivElement>(null);
  const menuPopoverAnchor = useRef<HTMLDivElement>(null);
  const toggleKey = `image-popover-${element.id}`;
  const open = toggle(toggleKey);
  // Initialize uploadFromUrl Firebase function
  const uploadFromUrl = useMemo(
    () => functions.httpsCallable('uploadFromUrl'),
    [],
  );
  // Initialize extractTextFromImage Firebase function
  const extractTextFromImage = functions.httpsCallable('extractTextFromImage');

  const updateNodeData = useCallback((data: Partial<ImageNodeData>) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, data, { at: path });
    ReactEditor.blur(editor);
  }, []);

  const resetNodeData = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.unsetNodes(
      editor,
      ['file', 'url', 'originalUrl', 'crop', 'dimensions', 'initialTab'],
      { at: path },
    );
  }, []);

  const selectNode = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.select(editor, path);
    ReactEditor.focus(editor);
    setActive(true);
  }, []);

  const closePopover = useCallback(() => {
    turnOff(toggleKey);
  }, []);

  useEffect(() => {
    if (mobileUpload) {
      closePopover();
    }
  }, [mobileUpload]);

  useEffect(() => {
    if (!selected) {
      setActive(false);
    }
  }, [selected]);

  const extractText = useCallback(
    async (file: string, preserveImage = false) => {
      try {
        setExtractStatus('analyzing');
        const result = await extractTextFromImage({ fileName: file });
        const annotation = result.data[0].fullTextAnnotation;
        if (annotation) {
          const document: string[] = [];
          // pages > blocks > paragraphs > words > symbols > text
          annotation.pages.forEach((page: OCRPage) => {
            page.blocks.forEach(block => {
              block.paragraphs.forEach(paragraph => {
                let paragraphText = '';
                paragraph.words.forEach((word, index) => {
                  let wordText = index === 0 ? '' : ' ';
                  word.symbols.forEach(symbol => {
                    wordText += symbol.text;
                  });
                  paragraphText += wordText;
                });
                document.push(paragraphText);
              });
            });
          });

          setExtractStatus('ready');
          const path = ReactEditor.findPath(editor, element);
          Transforms.insertNodes(editor, generateRichTextDocument(document), {
            at: [path[0] + 1],
          });
          if (!preserveImage) {
            deleteNode();
          }
        } else {
          setExtractStatus('no-text');
        }
      } catch (err) {
        setExtractStatus('error');
      }
    },
    [],
  );

  const handleUploadSuccess = useCallback(
    (url: string, id: string) => {
      if (tab === 'extract') {
        extractText(id);
      } else {
        updateNodeData({ url, file: id });
      }
    },
    [tab],
  );

  const handleUploadStart = useCallback(() => {
    closePopover();
    resetNodeData();
    setActive(false);
  }, []);

  const {
    onChange,
    progress,
    status: fileUploadStatus,
    error,
    file,
    upload,
  } = useFileUpload(storage, {
    onUploadSuccess: handleUploadSuccess,
    onUploadStart: handleUploadStart,
    onGetDimensions: dimensions => updateNodeData({ dimensions }),
  });

  const handleUploadFromUrl = useCallback(async (url: string) => {
    resetNodeData();
    setActive(false);
    setFetchingError('');
    setFetchingImage(true);
    closePopover();
    try {
      const result = await uploadFromUrl({ url });

      const { originalUrl, id, dimensions } = result.data as FileData;
      const downloadUrl = await storage.ref(id).getDownloadURL();
      updateNodeData({ originalUrl, url: downloadUrl, file: id, dimensions });
      setFetchingImage(false);
    } catch (err) {
      setFetchingImage(false);
      setFetchingError(
        'An error occured when trying to fetch the image. Try downloading the image yourself and uploading it here.',
      );
    }
  }, []);

  useEffect(() => {
    if (element.uploadFile && element.uploadFile instanceof File) {
      const { uploadFile } = element;
      upload(uploadFile);
      resetNodeData();
    }
  }, [element.uploadFile]);

  useEffect(() => {
    if (element.uploadUrl && typeof element.uploadUrl === 'string') {
      const { uploadUrl } = element;
      setLink(uploadUrl);
      handleUploadFromUrl(uploadUrl);
    }
  }, [element.uploadFile]);

  useEffect(() => {
    let isMounted = true;
    let unsubscribe: VoidFunction;

    if (element.id) {
      unsubscribe = db
        .collection('mobile-uploads')
        .doc(element.id)
        .onSnapshot(snapshot => {
          if (isMounted && snapshot && snapshot.exists) {
            const data = snapshot.data() as MobileUploadDocument;
            if (data.status === 'ready') {
              updateNodeData({
                url: data.fileUrl as string,
                file: data.fileId,
                dimensions: data.dimensions,
              });
            } else {
              resetNodeData();
              setActive(false);
              setMobileUpload(snapshot.data() as MobileUploadDocument);
            }
          }
        });
    }

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const openPopover = useCallback(() => {
    if (fileUploadStatus === 'uploading' || fetchingImage || mobileUpload) {
      return;
    }
    if (!element.initialTab) {
      setTab('upload');
    }
    turnOn(toggleKey);
  }, [fileUploadStatus, fetchingImage, mobileUpload, element.initialTab]);

  const handleTabChange = useCallback((event, value: TabsValue) => {
    setTab(value);
  }, []);

  const openFileSelectionDialog = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }, [fileInput.current]);

  const handleSubmitLink = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!link) {
        return;
      }
      handleUploadFromUrl(link);
    },
    [link],
  );

  useEffect(() => {
    if (
      !element.uploadFile &&
      !element.uploadUrl &&
      !element.file &&
      editor.selection &&
      selected &&
      Range.isCollapsed(editor.selection)
    ) {
      openPopover();
    }
  }, [selected, editor.selection, element.file, element.uploadFile]);

  const handleClickImage = useCallback(() => {
    selectNode();
    const path = ReactEditor.findPath(editor, element);
    ReactEditor.focus(editor);
    Transforms.select(editor, path);
  }, []);

  const openMenu = useCallback(() => {
    setActive(true);
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setActive(false);
    setMenuOpen(false);
  }, []);

  const deleteNode = useCallback(() => {
    closePopover();
    closeMenu();
    editor.deleteElement(element);
  }, [element]);

  const handleClickReplace = useCallback(() => {
    setMenuOpen(false);
    setLink('');
    setActive(true);
    openPopover();
  }, []);

  const handleCloseFullScreen = useCallback(() => {
    if (cropMode) {
      return;
    }
    selectNode();
    setFullScreenOpen(false);
  }, [cropMode]);

  const handleOpenFullScreen = useCallback(() => {
    closeMenu();
    setFullScreenOpen(true);
  }, []);

  const handleOpenCrop = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    closeMenu();
    setFullScreenOpen(true);
    setCropMode(true);
  }, []);

  const handleCancelCrop = useCallback(() => {
    selectNode();
    setFullScreenOpen(false);
    setCropMode(false);
  }, []);

  const handleSaveCrop = useCallback(() => {
    setFullScreenOpen(false);
    setCropMode(false);
    updateNodeData({ crop });
  }, [crop]);

  const handleEscapeCloseFullScreen = useCallback((event: KeyboardEvent) => {
    if (isHotkey('Escape', event)) {
      handleCloseFullScreen();
    }
  }, []);

  useEffect(() => {
    if (fullScreenOpen) {
      document.addEventListener('keydown', handleEscapeCloseFullScreen);
    } else {
      document.removeEventListener('keydown', handleEscapeCloseFullScreen);
    }

    return () =>
      document.removeEventListener('keydown', handleEscapeCloseFullScreen);
  }, [fullScreenOpen]);

  let srcCrop = '';

  if (element.crop && element.dimensions && !cropMode) {
    const { dimensions } = element;
    const cropXPercent = (crop.x || 0) / 100;
    const cropYPercent = (crop.y || 0) / 100;
    const cropWidthPercent = (crop.width || 100) / 100;
    const cropHeightPercent = (crop.height || 100) / 100;
    const x = Math.round(cropXPercent * dimensions.width);
    const y = Math.round(cropYPercent * dimensions.height);
    const width = Math.round(dimensions.width * cropWidthPercent);
    const height = Math.round(dimensions.height * cropHeightPercent);

    srcCrop += `rect=${x},${y},${width},${height}`;
  }

  return (
    <div
      {...attributes}
      ref={divRef}
      className={cn(classes.root, element.file && classes.hasImage)}
    >
      {!element.file && (
        <BlockPlaceholder
          className={classes.blockPlaceholder}
          onClick={openPopover}
          onClickDelete={deleteNode}
          label={
            <div>
              {extractStatus === 'analyzing' && (
                <div className={classes.fetchingProgress}>
                  <LoadingIndicator size={14} /> Analyzing image
                </div>
              )}
              {extractStatus === 'no-text' && (
                <Typography>
                  We couldn&apos;t find any text in this image.
                </Typography>
              )}
              {extractStatus === 'error' && (
                <Typography color="error">
                  Oops! An error occured when trying to analyze your image.
                  Please try again later.
                </Typography>
              )}
              {fileUploadStatus === 'uploading' && file && (
                <div>
                  <div>{file.name}</div>
                  <div className={classes.uploadProgress}>
                    {filesize(file.size)} — <LoadingIndicator size={14} />{' '}
                    <span style={{ marginLeft: 4 }}>{progress}%</span>
                  </div>
                </div>
              )}
              {mobileUpload && mobileUpload.status === 'uploading' && (
                <div>
                  <div>{mobileUpload.fileName}</div>
                  <div className={classes.uploadProgress}>
                    {filesize(mobileUpload.fileSize)} —{' '}
                    <LoadingIndicator size={14} />{' '}
                    <span style={{ marginLeft: 4 }}>Uploading</span>
                  </div>
                </div>
              )}
              {fileUploadStatus === 'empty' &&
                !mobileUpload &&
                !fetchingImage &&
                !fetchingError &&
                'Add an image'}
              {fetchingImage && (
                <div className={classes.fetchingProgress}>
                  <LoadingIndicator size={14} /> Fetching image
                </div>
              )}
              {fetchingError && fileUploadStatus !== 'uploading' && (
                <Typography color="error" variant="caption">
                  {fetchingError}
                </Typography>
              )}
              {error && fileUploadStatus === 'error' && (
                <Typography color="error" variant="caption">
                  {error}
                </Typography>
              )}
            </div>
          }
          icon={<InsertPhoto />}
        />
      )}
      {element.file && (
        <div
          role="button"
          onDoubleClick={handleOpenFullScreen}
          onClick={handleClickImage}
          contentEditable={false}
        >
          <img
            src={`${imageUrl}/${element.file}?w=1400${
              srcCrop ? `&${srcCrop}` : ''
            }`}
            className={classes.image}
          />
        </div>
      )}
      {active && element.file && (
        <div
          role="button"
          onDoubleClick={handleOpenFullScreen}
          className={classes.selectedOverlay}
        />
      )}
      {element.file && (
        <div className={classes.toolbar}>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph above"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphAbove(element);
            }}
          >
            <AddBlockAbove />
          </EditorToolbarButton>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph below"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphBelow(element);
            }}
          >
            <AddBlockBelow />
          </EditorToolbarButton>
          <EditorToolbarButton
            contrast
            tooltip="Image options"
            onClick={openMenu}
          >
            <MoreHoriz />
          </EditorToolbarButton>
        </div>
      )}
      <div className={classes.menuPopoverAnchor} ref={menuPopoverAnchor} />
      {children}
      <Popover
        open={open}
        anchorEl={divRef.current}
        onClose={closePopover}
        anchorOrigin={{
          vertical: element.file ? 'top' : 'bottom',
          horizontal: 'center',
        }}
      >
        {/** @ts-ignore */}
        <Tabs onChange={handleTabChange} value={tab}>
          <Tab label="Upload" value="upload" />
          <Tab label="Embed link" value="link" />
          <Tab label="From phone" value="phone" />
          <Tab label="Extract text" value="extract" />
        </Tabs>
        <div className={classes.popoverContent}>
          {(tab === 'upload' || tab === 'extract') && (
            <>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={openFileSelectionDialog}
              >
                Choose an image
              </Button>
              {!error && (
                <Typography
                  color="textSecondary"
                  variant="caption"
                  className={classes.helperText}
                >
                  {tab === 'extract' &&
                    'Extracts text from an image and inserts it into the editor. '}
                  The maximum file size is 10MB{tab === 'extract' && '.'}
                </Typography>
              )}
              {error && (
                <Typography
                  color="error"
                  variant="caption"
                  className={classes.helperText}
                >
                  {error}
                </Typography>
              )}
            </>
          )}
          {tab === 'link' && (
            <>
              <form style={{ width: '100%' }} onSubmit={handleSubmitLink}>
                <TextField
                  autoFocus
                  placeholder="Paste the image link..."
                  className={classes.textField}
                  value={link}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setLink(event.target.value)
                  }
                />
              </form>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleSubmitLink}
              >
                Embed image
              </Button>
              <Typography
                color="textSecondary"
                variant="caption"
                className={classes.helperText}
              >
                Works with any image from the web
              </Typography>
            </>
          )}
          {tab === 'phone' && (
            <>
              <QRCode
                bgColor={theme.palette.background.paper}
                size={100}
                value={`${
                  process.env.REACT_APP_BASE_URL ||
                  'https://app.get21stnight.com'
                }/upload/image/${element.id}`}
                className={classes.qrCode}
              />
              <Typography
                color="textSecondary"
                variant="caption"
                className={classes.helperText}
              >
                Scan the QR code above with your phone and then select an image
              </Typography>
            </>
          )}
        </div>
      </Popover>
      <Popover
        open={menuOpen}
        anchorEl={menuPopoverAnchor.current}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <MenuItem
            primaryText="Full screen"
            icon={<Fullscreen fontSize="small" />}
            onClick={handleOpenFullScreen}
          />
          <MenuItem
            primaryText="Crop"
            icon={<CropIcon fontSize="small" />}
            onClick={handleOpenCrop}
          />
          <MenuItem
            primaryText="Replace"
            icon={<Replace fontSize="small" />}
            onClick={handleClickReplace}
          />
          <MenuItem
            primaryText="Delete"
            icon={<Delete fontSize="small" />}
            onClick={deleteNode}
          />
        </List>
      </Popover>
      {createPortal(
        <Backdrop
          className={classes.fullScreenBackdrop}
          open={fullScreenOpen}
          onClick={handleCloseFullScreen}
        >
          <div>
            <div className={classes.fullScreenToolbar}>
              {!cropMode && (
                <>
                  <UiButton
                    color="inherit"
                    onClick={handleOpenCrop}
                    startIcon={<CropIcon color="inherit" />}
                  >
                    Crop
                  </UiButton>
                  <UiButton color="inherit" onClick={handleCloseFullScreen}>
                    Close
                  </UiButton>
                </>
              )}
              {cropMode && (
                <>
                  <UiButton
                    color="primary"
                    variant="contained"
                    onClick={handleSaveCrop}
                  >
                    Save Crop
                  </UiButton>
                  <UiButton color="inherit" onClick={handleCancelCrop}>
                    Cancel
                  </UiButton>
                </>
              )}
            </div>
            {!cropMode && element.file && (
              <img
                src={`${imageUrl}/${element.file}${
                  srcCrop ? `?${srcCrop}` : ''
                }`}
                className={classes.fullScreenImage}
              />
            )}
            {cropMode && (
              <ReactCrop
                imageStyle={{
                  maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,
                  maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
                }}
                src={`${imageUrl}/${element.file}`}
                crop={{ ...crop, unit: '%' }}
                onChange={(newCrop, { x, y, height, width }): void =>
                  setCrop({
                    x,
                    y,
                    height,
                    width,
                  })
                }
              />
            )}
          </div>
        </Backdrop>,
        document.body,
      )}
      {createPortal(
        <input
          ref={fileInput}
          type="file"
          accept="image/*"
          className={classes.fileInput}
          onChange={onChange}
        />,
        document.body,
      )}
    </div>
  );
};
