import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  SliderClassKey as MuiSliderClassKey,
  Mark,
} from '@material-ui/core';

export type SliderProps = MuiSliderProps;
export type SliderMark = Mark;
export type SliderClassKey =
  | 'root'
  | 'thumb'
  | 'active'
  | 'disabled'
  | 'valueLabel'
  | 'track'
  | 'rail';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: 8,
    },
    thumb: {
      height: 18,
      width: 18,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid currentColor',
      marginTop: -7,
      marginLeft: -9,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
      '&$disabled': {
        height: 18,
        width: 18,
        marginTop: -7,
        marginLeft: -9,
      },
    },
    active: {},
    disabled: {},
    valueLabel: {
      left: 'calc(-50% - 1px)',
    },
    track: {
      height: 4,
      borderRadius: 1,
    },
    rail: {
      height: 4,
      borderRadius: 1,
    },
  }),
);

export const Slider: React.FC<SliderProps> = ({
  classes: classesProp = {},
  ...other
}) => {
  const classes = useStyles();

  return (
    <MuiSlider
      classes={{
        ...classesProp,
        ...Object.keys(classes).reduce((cls, className) => {
          return {
            ...cls,
            [className]: clsx(
              classes[className as SliderClassKey],
              classesProp[className as MuiSliderClassKey],
            ),
          };
        }, {}),
      }}
      {...other}
    />
  );
};
