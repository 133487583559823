import React, { useCallback } from 'react';
import {
  ElementCode as SheetsElementCode,
  ElementCodeProps,
} from '@braindrop-editor/plugin-code';
import { Delete, AddBlockAbove, AddBlockBelow } from '@21st-night/icons';
import { makeStyles } from '@21st-night/styles';
import { useEditor } from 'slate-react';
import { WebEditor } from '../../web-editor-plugins';
import { EditorToolbarButton } from '../EditorToolbarButton';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0),
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
}));

export const ElementCode: React.FC<ElementCodeProps> = ({
  children,
  element,
  ...other
}) => {
  const classes = useStyles();
  const editor = useEditor() as WebEditor;

  const deleteNode = useCallback(() => {
    editor.deleteElement(element);
  }, [element]);

  return (
    <div className={classes.root}>
      <div contentEditable={false} className={classes.toolbar}>
        <EditorToolbarButton
          contrast
          tooltip="Add a paragraph above"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            editor.insertParagraphAbove(element);
          }}
        >
          <AddBlockAbove />
        </EditorToolbarButton>
        <EditorToolbarButton
          contrast
          tooltip="Add a paragraph below"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            editor.insertParagraphBelow(element);
          }}
        >
          <AddBlockBelow />
        </EditorToolbarButton>
        <EditorToolbarButton contrast tooltip="Delete" onClick={deleteNode}>
          <Delete />
        </EditorToolbarButton>
      </div>
      <SheetsElementCode element={element} {...other}>
        {children}
      </SheetsElementCode>
    </div>
  );
};
