import dayjs from 'dayjs';
import { DeckCard } from '@21st-night/deck';
import { RatingEvent } from '@21st-night/review';

export function getDayRatings(date: Date, cards: DeckCard[]): RatingEvent[] {
  let ratings: RatingEvent[] = [];

  cards.forEach(card => {
    if (card.attempts) {
      ratings = [
        ...ratings,
        ...card.attempts.filter(attempt =>
          dayjs(attempt.createdAt).isSame(date, 'date'),
        ),
      ];
    }
  });

  return ratings;
}
