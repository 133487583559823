import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { makeStyles, cn } from '@21st-night/styles';
import { IconButton } from '@21st-night/ui';
import { ChevronLeft, ChevronRight } from '@21st-night/icons';
import { daysBetween } from '@21st-night/study-plan';

export interface CalendarProps extends React.HTMLProps<HTMLTableElement> {
  startWeekOn: number;
  selectedDay?: Date;
  onClickDay?: (value: Date) => void;
  month: number;
  year: number;
  examDate: Date;
  activeDays: number[];
  completedDays: number[];
  onClickNextMonth: () => void;
  onClickPreviousMonth: () => void;
}

type Weeks = Date[][];

const daysOfTheWeek: string[] = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

function isWeekActive(week: Date[], selectedDay: Date): boolean {
  return (
    dayjs(week[0]).subtract(1, 'day').isBefore(selectedDay, 'day') &&
    dayjs(week.slice(-1)[0]).add(1, 'day').isAfter(selectedDay, 'day')
  );
}

const useStyles = makeStyles(theme => ({
  table: {
    boxSizing: 'border-box',
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  month: {
    textAlign: 'left',
    paddingLeft: 8,
    height: 30,
    color: '#293449',
  },
  buttons: {
    textAlign: 'right',
    paddingRight: 3,
  },
  button: {
    padding: 0,
  },
  th: {
    textAlign: 'center',
    width: 50,
    padding: theme.spacing(1, 0),
    fontWeight: 400,
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    margin: 0,
  },
  day: {
    width: 40,
    height: 40,
    textAlign: 'center',
    color: '#243448',
    fontSize: theme.typography.pxToRem(18),
    paddingBottom: 4,
    paddingTop: 4,
  },
  date: {
    width: 34,
    height: 34,
    borderRadius: 17,
    display: 'inline-block',
    lineHeight: '34px',
    position: 'relative',
  },
  today: {
    color: theme.palette.primary.main,
    fontWeight: 800,
  },
  dot: {
    display: 'inline-block',
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: '#FF553D',
    position: 'absolute',
    bottom: 0,
    left: 'calc(50% - 3px)',
  },
  dotCompleted: {
    backgroundColor: '#FAA8B3',
  },
  examDay: {
    display: 'inline-block',
    width: 20,
    height: 3,
    backgroundColor: '#FF553D',
    position: 'absolute',
    bottom: 0,
    left: 'calc(50% - 10px)',
    borderRadius: 2,
  },
  hoverableWeek: {
    cursor: 'pointer',
    borderRadius: 3,
    '& > :first-child': {
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
    },
    '& > :last-child': {
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
    },
    '&:hover': {
      '& > td': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  activeWeek: {
    '& > td': {
      backgroundColor: theme.palette.action.selected,
    },
    '&:hover': {
      '& > td': {
        backgroundColor: theme.palette.action.selected,
      },
    },
  },
}));

export const Calendar: React.FC<CalendarProps> = ({
  activeDays,
  completedDays,
  examDate,
  month: monthProp,
  onClickNextMonth,
  onClickPreviousMonth,
  startWeekOn,
  year,
  selectedDay,
  onClickDay,
  ...other
}) => {
  const classes = useStyles();
  const today = useMemo(() => new Date(), []);
  const week = useMemo(
    () => [
      ...daysOfTheWeek.slice(startWeekOn),
      ...daysOfTheWeek.slice(0, startWeekOn),
    ],
    [startWeekOn],
  );
  const month = useMemo(() => {
    const date = new Date();
    date.setFullYear(year, monthProp, 1);
    return date;
  }, [monthProp, year]);

  const weeks: Weeks = useMemo(() => {
    const dayCount = dayjs(month).daysInMonth();
    const endOfMonth = new Date(month.getTime());
    endOfMonth.setDate(dayCount);
    const allDays = daysBetween(month, endOfMonth);
    return allDays.reduce((chunks: Weeks, day, index) => {
      if (index === 0 || day.getDay() === startWeekOn) {
        chunks.push([day]);
      } else {
        chunks[chunks.length - 1].push(day);
      }

      return chunks;
    }, []);
  }, [month, startWeekOn]);

  const startFillers = useMemo(() => {
    const cells = [];
    let count = 7 - weeks[0].length;

    while (count > 0) {
      cells.push(
        <td
          className={classes.day}
          key={`fillter-${count}`}
          role={onClickDay ? 'button' : undefined}
          onClick={
            onClickDay ? () => onClickDay(weeks[0].slice(-1)[0]) : undefined
          }
        />,
      );
      count -= 1;
    }

    return cells;
  }, [weeks]);

  const endFillers = useMemo(() => {
    const lastWeek = weeks.slice(-1)[0];
    const cells = [];
    let count = 7 - lastWeek.length;

    while (count > 0) {
      cells.push(
        <td
          className={classes.day}
          key={`fillter-${count}`}
          role={onClickDay ? 'button' : undefined}
          onClick={
            onClickDay ? () => onClickDay(lastWeek.slice(-1)[0]) : undefined
          }
        />,
      );
      count -= 1;
    }

    return cells;
  }, [weeks, onClickDay]);

  return (
    <table className={classes.table} {...other}>
      <thead>
        <tr>
          <th colSpan={5} className={classes.month}>
            {dayjs(month).format('MMMM YYYY')}
          </th>
          <th colSpan={2} className={classes.buttons}>
            <IconButton
              className={classes.button}
              style={{ marginRight: 8 }}
              onClick={onClickPreviousMonth}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton className={classes.button} onClick={onClickNextMonth}>
              <ChevronRight />
            </IconButton>
          </th>
          <th></th>
        </tr>
        <tr>
          {week.map(day => (
            <th key={day} className={classes.th}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, index) => (
          <tr
            key={`week-${index}`}
            className={cn(
              !!onClickDay && classes.hoverableWeek,
              selectedDay &&
                isWeekActive(week, selectedDay) &&
                classes.activeWeek,
            )}
          >
            {index === 0 && startFillers}
            {week.map(day => (
              <td
                role={onClickDay ? 'button' : undefined}
                onClick={onClickDay ? () => onClickDay(day) : undefined}
                className={classes.day}
                key={day.getDate()}
              >
                <span
                  className={cn(
                    classes.date,
                    dayjs(day).isSame(today, 'day') && classes.today,
                  )}
                >
                  {day.getDate()}
                  {dayjs(day).isSame(examDate, 'day') ? (
                    <span className={classes.examDay} />
                  ) : (
                    <span
                      className={
                        activeDays.includes(day.getDate())
                          ? cn(
                              classes.dot,
                              completedDays.includes(day.getDate()) &&
                                classes.dotCompleted,
                            )
                          : ''
                      }
                    />
                  )}
                </span>
              </td>
            ))}
            {index === weeks.length - 1 && endFillers}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
