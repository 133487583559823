import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, createStyles } from '@21st-night/styles';
import { useFirebase } from '@21st-night/utils';
import { useTimeActive } from '@21st-night/utils-web';
import { CardData, ImageOcclusionDialog } from '@21st-night/cards-web';
import { Typography } from '@21st-night/ui';
import { DeckCategorySelect } from '../DeckCategorySelect';
import { DeckSubcategoriesSelect } from '../DeckSubcategoriesSelect';

export interface DeckImageOcclusionDialogProps {
  fileInputRef: React.RefObject<HTMLInputElement> | null;
  createCards: (data: CardData[], time: number) => Promise<void>;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    hint: {
      color: theme.palette.text.hint,
    },
  }),
);

export const DeckImageOcclusionDialog: React.FC<DeckImageOcclusionDialogProps> = ({
  fileInputRef,
  createCards,
}) => {
  const classes = useStyles();
  const { db, storage, functions } = useFirebase();
  const [time] = useTimeActive(30);
  const [image, setImage] = useState<File | null>(null);
  const [category, setCategory] = useState<string | null>(null);
  const [subcategories, setSubcategories] = useState<string[]>([]);

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  }

  if (!image) {
    return (
      <>
        {ReactDOM.createPortal(
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{
              opacity: 0,
              pointerEvents: 'none',
              position: 'fixed',
              left: -1000,
              top: -1000,
            }}
            onChange={handleFileChange}
          />,
          document.body,
        )}
      </>
    );
  }

  async function handleSubmit(cards: CardData[]) {
    await createCards(cards, time);
    setImage(null);
  }

  return (
    <>
      <ImageOcclusionDialog
        {...{ db, storage, functions }}
        open={!!image}
        image={image}
        onClose={() => setImage(null)}
        onSubmit={handleSubmit}
      >
        <div style={{ minHeight: 240, marginTop: 36 }}>
          <Typography variant="subtitle1">
            Category{' '}
            <span className={classes.hint}>(applied to all cards)</span>
          </Typography>
          <DeckCategorySelect value={category} onChange={setCategory} />
          <div style={{ height: 24 }} />
          <Typography variant="subtitle1">
            Subcategories{' '}
            <span className={classes.hint}>(applied to all cards)</span>
          </Typography>
          <DeckSubcategoriesSelect
            value={subcategories}
            onChange={setSubcategories}
          />
        </div>
      </ImageOcclusionDialog>
    </>
  );
};
