/**
 * Randomize array element order in-place
 * using Durstenfeld shuffle algorithm.
 *
 * @param array The array to shuffle.
 *
 * @returns The shuffled array.
 */
export function shuffle<A>(array: A[]): A[] {
  const shuffled = array.slice(0);
  for (let i = shuffled.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = temp;
  }
  return shuffled;
}
