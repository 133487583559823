/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import { postMessageToWebView, WebViewWindow } from '@21st-night/web-view';
import createVideoPlugin, {
  EditorWithVideoPlugin,
} from '../plugins/plugin-video';
import {
  createEquationPlugin,
  EditorWithInlineEquationPlugin,
} from '../plugins/plugin-equation';
import {
  createEquationBlockPlugin,
  EditorWithEquationBlockPlugin,
} from '../plugins/plugin-equation-block';
import { corePlugins, CoreEditor } from '../core-editor-plugins';
import createImagePlugin, {
  EditorWithImagePlugin,
} from '../plugins/plugin-image';
import createAudioPlugin, {
  EditorWithAudioPlugin,
} from '../plugins/plugin-audio';
import { createLinkPlugin, EditorWithLinkPlugin } from '../plugins/plugin-link';
import { BaseEditor, BaseEditorProps } from '../BaseEditor';

declare let window: WebViewWindow;

window.postMessageToWebView = postMessageToWebView;

export interface MobileEditorProps extends BaseEditorProps {
  imageUrl: string;
}

export interface MobileEditor
  extends CoreEditor,
    EditorWithImagePlugin,
    EditorWithVideoPlugin,
    EditorWithAudioPlugin,
    EditorWithLinkPlugin,
    EditorWithInlineEquationPlugin,
    EditorWithEquationBlockPlugin {}

const VideoPlugin = createVideoPlugin();

export const MobileEditor: React.FC<MobileEditorProps> = ({
  imageUrl,
  children,
  plugins: pluginsProp = [],
  ...other
}) => {
  const MobileImagePlugin = useMemo(
    () =>
      createImagePlugin({
        mobile: true,
        imageUrl,
      }),
    [],
  );
  const MobileAudioPlugin = useMemo(
    () => createAudioPlugin({ mobile: true }),
    [],
  );
  const EquationPlugin = useMemo(
    () => createEquationPlugin({ mobile: true }),
    [],
  );
  const LinkPlugin = useMemo(() => createLinkPlugin({ mobile: true }), []);
  const EquationBlockPlugin = useMemo(
    () => createEquationBlockPlugin({ mobile: true }),
    [],
  );

  return (
    <BaseEditor
      disableScrolling
      maxHeight="none"
      minHeight={800}
      style={{ paddingBottom: 300, overflowY: 'visible' }}
      plugins={[
        ...corePlugins,
        MobileImagePlugin,
        VideoPlugin,
        MobileAudioPlugin,
        EquationPlugin,
        LinkPlugin,
        EquationBlockPlugin,
        ...pluginsProp,
      ]}
      {...other}
    >
      {children}
    </BaseEditor>
  );
};
