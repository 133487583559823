import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { RatingEvent } from '@21st-night/review';
import { Typography } from '@21st-night/ui';
import { CardRatingsTimelineChart } from '../CardRatingsTimelineChart';

export interface CardAnalyticsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  createdAt: Date;
  ratings: RatingEvent[];
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    statistics: {
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
    statisticLeft: {
      flex: 1,
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      paddingRight: theme.spacing(2),
    },
    statisticRight: {
      flex: 1,
      paddingLeft: theme.spacing(2),
    },
  }),
);

export const CardAnalytics: React.FC<CardAnalyticsProps> = ({
  className,
  createdAt,
  ratings,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, className)} {...other}>
      <Typography gutterBottom variant="subtitle1">
        Review history
      </Typography>
      <CardRatingsTimelineChart createdAt={createdAt} ratings={ratings} />
    </div>
  );
};
