import React from 'react';
import { List } from '@21st-night/ui';
import { CardListItem } from '@21st-night/cards-web';
import { useFirebase } from '@21st-night/utils';
import {
  DeckFilterOption,
  generateDeckCategoryFilters,
  generateDeckSubcategoryFilters,
  useDeck,
  useDeckFilter,
  DeckContentCard,
} from '@21st-night/deck';
import { DeckFilterBar } from '../DeckFilter';
import { DeckItemMetadata } from '../DeckItemMetadata';

export interface DeckCardSelectorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onSelectCard: (card: DeckContentCard) => void;
  exclude?: string[];
}

const metadataFilters: DeckFilterOption[] = [
  {
    type: 'boolean',
    group: 'metadata',
    value: 'starred',
    label: '⭐️ Starred',
  },
  {
    type: 'item-type',
    group: 'metadata',
    value: 'flashcard',
    label: '❓ Flashcards',
  },
  {
    type: 'item-type',
    group: 'metadata',
    value: 'error-log',
    label: '❗ Error logs',
  },
  {
    type: 'boolean',
    group: 'metadata',
    value: 'due-now',
    label: '🗓 Due now',
  },
  {
    type: 'boolean',
    group: 'metadata',
    value: 'retired',
    label: '🗃️ Retired',
  },
];

export const DeckCardSelector: React.FC<DeckCardSelectorProps> = ({
  onSelectCard,
  exclude = [],
  ...other
}) => {
  const { imageUrl } = useFirebase();
  const deck = useDeck();
  const { setFilters, filters, filter } = useDeckFilter([
    ...deck.categoryFilters,
    ...deck.subcategoryFilters,
  ]);
  const cards = filter(deck.cards.filter(card => !exclude.includes(card.id)));

  return (
    <div {...other}>
      <DeckFilterBar
        placeholder="Filter cards"
        options={[
          ...metadataFilters,
          ...generateDeckCategoryFilters(deck.categories),
          ...generateDeckSubcategoryFilters(deck.subcategories),
        ]}
        onChange={(event, val) => setFilters(val)}
        value={filters}
      />
      <List>
        {cards.map(card => (
          <CardListItem
            button
            onClick={() => onSelectCard(card)}
            key={card.id}
            imageUrl={imageUrl}
            card={card}
            secondary={<DeckItemMetadata readonly item={card} />}
          />
        ))}
      </List>
    </div>
  );
};
