import { v4 as uuid } from 'uuid';
import { EditorDocumentMetadata } from '../../Editor.types';

export function generateEditorDocumentMetadata(): EditorDocumentMetadata {
  return {
    type: 'metadata',
    id: uuid(),
    children: [{ text: '' }],
    apiVersion: '2.0.0',
    createdAt: new Date(),
    lastUpdatedAt: new Date(),
  };
}
