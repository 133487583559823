export async function getPlainTextFileContent(file: File): Promise<string[]> {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = (fileLoadedEvent): void => {
      if (fileLoadedEvent && fileLoadedEvent.target) {
        const textFromFileLoaded = fileLoadedEvent.target.result;
        if (textFromFileLoaded && typeof textFromFileLoaded === 'string') {
          resolve(textFromFileLoaded.split('\\n'));
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    };
    fileReader.readAsText(file, 'UTF-8');
  });
}
