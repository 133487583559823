import {
  BraindropEditorPluginFactory,
  BraindropEditor,
  BraindropEditorPlugin,
} from '@braindrop-editor/core';
import { Node as SlateNode, Transforms, Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { DEFAULT_ELEMENT_TYPE, isElement } from '@21st-night/editor';
import { EditorWithApiPlugin } from './EditorApiPlugin.types';
import { isFocusElementOfType } from '../../api';

export const createEditorApiPlugin = (): BraindropEditorPluginFactory => (
  braindropEditor: BraindropEditor,
): BraindropEditorPlugin => {
  const editor = braindropEditor as EditorWithApiPlugin;
  const { normalizeNode } = editor;

  editor.insertParagraphAbove = (element?: SlateNode) => {
    let path: Path = [0];

    if (element) {
      path = ReactEditor.findPath(editor, element);
    } else if (editor.selection) {
      path = editor.selection.anchor.path;
    }

    Transforms.insertNodes(
      editor,
      editor.generateElement(DEFAULT_ELEMENT_TYPE),
      { at: path, hanging: true, select: true },
    );
    ReactEditor.focus(editor);
  };

  editor.insertParagraphBelow = (element?: SlateNode) => {
    let path: Path = [0];

    if (element) {
      path = ReactEditor.findPath(editor, element);
    } else if (editor.selection) {
      path = editor.selection.anchor.path;
    }

    path = Path.next(path);

    Transforms.insertNodes(
      editor,
      editor.generateElement(DEFAULT_ELEMENT_TYPE),
      { at: path, hanging: true, select: true },
    );
    ReactEditor.focus(editor);
  };

  editor.toggleElementType = (type: string) => {
    Transforms.setNodes(editor, {
      type: isFocusElementOfType(editor, type) ? DEFAULT_ELEMENT_TYPE : type,
    });
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (path.length === 1 && path[0] === editor.children.length - 1) {
      if (isElement(node) && editor.isVoid(node)) {
        Transforms.insertNodes(
          editor,
          editor.generateElement(DEFAULT_ELEMENT_TYPE),
          { at: Path.next(path) },
        );
      }
    }

    return normalizeNode(entry);
  };

  return {};
};
