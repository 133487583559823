import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Tooltip, TooltipProps } from '@21st-night/ui';

export interface TaskFormSliderValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
  index?: number;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    tooltip: {
      background: 'none',
      padding: 0,
      marginTop: 2,
      ...theme.typography.body2,
      color: theme.palette.secondary.main,
    },
  }),
);

export const TaskFormSliderValueLabel: React.FC<TaskFormSliderValueLabelProps> = ({
  children,
  open,
  value: valueProp,
  index: indexProp,
}) => {
  const classes = useStyles();
  const value = (valueProp as unknown) as string;
  const index = indexProp || 0;
  let placement: TooltipProps['placement'] = 'top';

  if (index === 0) {
    placement = value === 'Today' ? 'top-start' : 'top-end';
  } else {
    placement = value === 'Exam day' ? 'top-end' : 'top-start';
  }

  return (
    <Tooltip
      classes={classes}
      open={open}
      enterTouchDelay={0}
      placement={placement}
      title={value}
    >
      {children}
    </Tooltip>
  );
};
