import React, { useState, useEffect } from 'react';
import { Collapse } from '@21st-night/ui';
import { OnboardingContentType, useOnboarding } from '@21st-night/onboarding';
import { OnboardingBanner } from '../OnboardingBanner';
import { OnboardingChecklistItem } from '../OnboardingChecklistItem';
import { generateSteps } from './generateSteps';

export type OnboardingBannerContainerProps = React.HTMLAttributes<HTMLDivElement>;

export const OnboardingBannerContainer: React.FC<OnboardingBannerContainerProps> = ({
  ...other
}) => {
  const onboarding = useOnboarding();
  const { answers } = onboarding;
  const [steps] = useState(
    generateSteps(
      (answers['user-type'] === 'tutor'
        ? 'TUTOR'
        : (answers['content-type'] as OnboardingContentType)) || 'MIXED',
    ),
  );
  const remainingSteps = onboarding.completedSteps
    ? steps.filter(({ id }) => ![...onboarding.completedSteps].includes(id))
    : steps;
  const [currentStepId, setCurrentStepId] = useState(
    remainingSteps.length ? remainingSteps[0].id : '',
  );

  const currentStep = remainingSteps[0];

  function skipCurrentStep() {
    onboarding.completeStep(currentStep.id);
  }

  useEffect(() => {
    setTimeout(() => {
      setCurrentStepId(remainingSteps.length ? remainingSteps[0].id : '');
    }, 1500);
  }, [remainingSteps]);

  return (
    <OnboardingBanner
      onClickSkip={skipCurrentStep}
      currentStep={steps.findIndex(({ id }) => id === currentStepId)}
      stepCount={steps.length}
      {...other}
    >
      {remainingSteps.length !== 0 &&
        steps.map(step => (
          <Collapse key={step.id} in={currentStepId === step.id}>
            <OnboardingChecklistItem
              checked={
                remainingSteps.findIndex(({ id }) => id === step.id) === -1
              }
              onCheck={skipCurrentStep}
              primary={step.title}
              secondary={step.subtitle}
            />
          </Collapse>
        ))}
    </OnboardingBanner>
  );
};
