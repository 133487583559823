import { v4 as uuid } from 'uuid';
import { StudyPlanDay } from '../../types';

export function generateStudyPlanDay(date: Date): StudyPlanDay {
  return {
    date,
    id: uuid(),
    createdAt: new Date(),
    items: [],
  };
}
