import { v4 as uuid } from 'uuid';
import { StudyPlanTaskTemplate } from '../../types';

export interface GenerateStudyPlanTaskTemplateOptions
  extends Partial<StudyPlanTaskTemplate> {
  end: number;
}

export function generateStudyPlanTaskTemplate(
  options: GenerateStudyPlanTaskTemplateOptions,
): StudyPlanTaskTemplate {
  return {
    createdAt: new Date(),
    id: uuid(),
    start: 0,
    days: [],
    subtasks: [],
    action: '',
    count: 0,
    object: '',
    source: '',
    ...options,
  };
}
