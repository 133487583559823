import { Storage, DB } from '@21st-night/utils-web';
import {
  deserializeDocument,
  serializeDocument,
  generateEditorElement,
} from '@21st-night/editor-web';
import { CardData } from '@21st-night/cards';

type CardSection = 'question' | 'answer' | 'explanation' | 'summary';
type CardImageType =
  | 'questionImage'
  | 'answerImage'
  | 'explanationImage'
  | 'summaryImage';
type CardAudioType =
  | 'questionAudio'
  | 'answerAudio'
  | 'explanationAudio'
  | 'summaryAudio';

type CardDataMap = Record<string, CardData>;

function addImageToCard(
  storage: Storage,
  cards: CardDataMap,
  cardId: string,
  section: CardSection,
): Promise<void> {
  return new Promise(resolve => {
    const card = cards[cardId];
    const imageType = `${section}Image` as CardImageType;
    const file = storage.ref(card[imageType]);
    file.getDownloadURL().then(url => {
      const img = new Image();
      img.onload = (): void => {
        if (card[section]) {
          cards[card.id][section] = serializeDocument([
            ...deserializeDocument(card[section] as string),
            generateEditorElement('image', '', {
              file: card[imageType],
              url,
              dimensions: {
                height: img.height,
                width: img.width,
                aspectRatio: img.width / img.height || 1,
              },
            }),
          ]);
        }
        resolve();
      };

      img.src = url;
    });
  });
}

function addAudioToCard(
  storage: Storage,
  cards: CardDataMap,
  cardId: string,
  section: CardSection,
): Promise<void> {
  return new Promise(resolve => {
    const card = cards[cardId];
    const audioType = `${section}Audio` as CardAudioType;
    const file = storage.ref(card[audioType]);
    file.getDownloadURL().then(url => {
      cards[card.id][section] = serializeDocument([
        ...deserializeDocument(card[section] as string),
        generateEditorElement('audio', '', {
          file: card[audioType],
          url,
        }),
      ]);
      resolve();
    });
  });
}

export async function addMediaToCards(
  data: CardData[],
  db: DB,
  storage: Storage,
): Promise<CardData[]> {
  const sections: CardSection[] = [
    'question',
    'answer',
    'explanation',
    'summary',
  ];
  const mediaPromises: Promise<void>[] = [];
  const cards = data.reduce((map, card) => {
    map[card.id] = card;
    return map;
  }, {} as CardDataMap);

  data.forEach(card => {
    sections.forEach(section => {
      const imageType = `${section}Image` as CardImageType;
      if (card[imageType]) {
        mediaPromises.push(addImageToCard(storage, cards, card.id, section));
      }
      const audioType = `${section}Audio` as CardAudioType;
      if (card[audioType]) {
        mediaPromises.push(addAudioToCard(storage, cards, card.id, section));
      }
    });
  });

  await Promise.all(mediaPromises);

  return Object.values(cards);
}
