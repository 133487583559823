import React from 'react';
import { useFocused } from 'slate-react';
import { EditorToolbar } from '@21st-night/editor-web';
import { Typography } from '@21st-night/ui';

export const NoteEditorToolbar: React.FC = () => {
  const isFocused = useFocused();

  if (!isFocused) {
    return (
      <Typography
        variant="subtitle1"
        style={{
          userSelect: 'none',
          fontWeight: 'normal',
          fontSize: '16px',
          color: '#90A4AE',
          height: 34,
          lineHeight: '34px',
        }}
      >
        Note
      </Typography>
    );
  }

  return <EditorToolbar />;
};
