import React, { useCallback } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Toolbar, Button, LinearProgress } from '@21st-night/ui';
import { ArrowBack } from '@21st-night/icons';
import {
  useOnboardingState,
  OnboardingRangeSelectionValue,
  OnboardingAnswers,
  OnboardingQuestionId,
  OnboardingQuestionAnswerOption,
  segmentationQuestions as questions,
} from '@21st-night/onboarding';
import { OnboardingMultipleChoiceQuestion } from '../OnboardingMultipleChoiceQuestion';
import { OnboardingDateSelectionQuestion } from '../OnboardingDateSelectionQuestion';
import { OnboardingRangeSelectionQuestion } from '../OnboardingRangeSelectionQuestion';

export interface OnboardingQuestionairProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  onSubmit: (answers: OnboardingAnswers) => void;
  onSkip: (answers: OnboardingAnswers) => void;
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    toolbarSegment: {
      flex: 1,
    },
    progressBar: {
      borderRadius: 3,
      maxWidth: 260,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    questionContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const OnboardingQuestionair: React.FC<OnboardingQuestionairProps> = ({
  className,
  onSubmit,
  onSkip,
  ...other
}) => {
  const classes = useStyles();
  const state = useOnboardingState();
  const question = state.question ? questions[state.question] : null;

  const handleAnserMCQ = useCallback(
    (option: OnboardingQuestionAnswerOption) => {
      if (!question) {
        return;
      }

      state.answerQuestion(
        question,
        option.value,
        questions[
          (option.nextQuestion || question.nextQuestion) as OnboardingQuestionId
        ],
      );

      if (!option.nextQuestion && !question.nextQuestion) {
        onSubmit({ ...state.answers, [question.answerKey]: option.value });
      }
    },
    [state, question, onSubmit],
  );

  const handleAnswerRange = useCallback(
    (values: Record<string, OnboardingRangeSelectionValue>) => {
      if (!question) {
        return;
      }

      state.answerQuestion(
        question,
        values,
        questions[question.nextQuestion as OnboardingQuestionId],
      );

      if (!question.nextQuestion) {
        onSubmit({ ...state.answers, [question.answerKey]: values });
      }
    },
    [state, question, onSubmit],
  );

  const handleAnswerDate = useCallback(
    (value: Date) => {
      if (!question) {
        return;
      }

      state.answerQuestion(
        question,
        value,
        questions[question.nextQuestion as OnboardingQuestionId],
      );

      if (!question.nextQuestion) {
        onSubmit({ ...state.answers, [question.answerKey]: value });
      }
    },
    [state, question, onSubmit],
  );

  const handleSkip = useCallback(() => {
    onSkip(state.answers);
  }, [onSkip, state.answers]);

  return (
    <div className={cn(classes.root, className)} {...other}>
      <Toolbar>
        <div className={classes.toolbarSegment}>
          {state.answeredQuestions.length > 0 && (
            <Button
              startIcon={<ArrowBack />}
              onClick={state.backToPreviousQuestion}
            >
              Back
            </Button>
          )}
        </div>
        <div className={classes.toolbarSegment}>
          <LinearProgress
            variant="determinate"
            color="primary"
            value={state.progress}
            className={classes.progressBar}
          />
        </div>
        <div className={classes.toolbarSegment} />
      </Toolbar>
      {question && (
        <div className={classes.questionContainer}>
          {question.type === 'multiple-choice' && (
            <OnboardingMultipleChoiceQuestion
              question={question.question}
              options={question.options}
              onSkip={question.skippable ? handleSkip : undefined}
              onClickOption={handleAnserMCQ}
            />
          )}

          {question.type === 'range' && (
            <OnboardingRangeSelectionQuestion
              question={question.question}
              sliders={question.ranges}
              onSubmit={handleAnswerRange}
              defaultValues={
                (state.question &&
                  (state.answers[questions[state.question].answerKey] as Record<
                    string,
                    OnboardingRangeSelectionValue
                  >)) ||
                undefined
              }
            />
          )}

          {question.type === 'date' && (
            <OnboardingDateSelectionQuestion
              question={question.question}
              inputFormat={question.inputFormat}
              onSubmit={handleAnswerDate}
              defaultValue={
                (state.question &&
                  (state.answers[
                    questions[state.question].answerKey
                  ] as Date)) ||
                new Date()
              }
            />
          )}
        </div>
      )}
    </div>
  );
};
