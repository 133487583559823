import { useState } from 'react';

export interface SplitViewListItem {
  id: string;
}

export function useSplitViewList<T extends SplitViewListItem>(): {
  rows: (items: T[]) => (T | [T, T[]])[];
  splitItems: (leftId: string, rightId: string) => void;
  addToSplit: (
    leftId: string,
    rightId: string,
    placement?: 'top' | 'bottom',
  ) => void;
  unsplitItems: (leftId: string) => void;
  removeFromSplit: (leftId: string, rightId: string) => void;
} {
  const [splits, setSplits] = useState<[string, string[]][]>([]);

  function splitItems(leftId: string, rightId: string) {
    setSplits(value => [...value, [leftId, [rightId]]]);
  }

  function addToSplit(
    leftId: string,
    rightId: string,
    placement: 'top' | 'bottom' = 'bottom',
  ) {
    const nextSplits = splits.filter(split => split[0] !== leftId);
    const split = splits.find(split => split[0] === leftId);

    if (split) {
      const nextSplit: [string, string[]] =
        placement === 'top'
          ? [split[0], [rightId, ...split[1]]]
          : [split[0], [...split[1], rightId]];

      nextSplits.push(nextSplit);

      setSplits(nextSplits);
    }
  }

  function unsplitItems(leftId: string) {
    setSplits(value => value.filter(split => split[0] !== leftId));
  }

  function removeFromSplit(leftId: string, rightId: string) {
    const nextSplits = splits.filter(split => split[0] !== leftId);
    const split = splits.find(split => split[0] === leftId);

    if (split) {
      const nextSplit: [string, string[]] = [
        split[0],
        split[1].filter(id => id !== rightId),
      ];

      if (!nextSplit[1].length) {
        unsplitItems(leftId);
      } else {
        nextSplits.push(nextSplit);
        setSplits(nextSplits);
      }
    }
  }

  function rows<T extends SplitViewListItem>(items: T[]): (T | [T, T[]])[] {
    const splitIds: string[] = splits.reduce(
      (ids, item) => [...ids, item[0], ...item[1]],
      [] as string[],
    );
    const itemRows: (T | [T, T[]])[] = [];

    items.forEach(item => {
      if (!splitIds.includes(item.id)) {
        // Item is not split so we just add it to the rows as is
        itemRows.push(item);
      } else {
        // Check if item is the left item in a split
        const split = splits.find(spl => spl[0] === item.id);
        if (split) {
          // Find the split's right hand items
          const rightItems = items
            .filter(item => split[1].includes(item.id))
            .sort((a, b) => split[1].indexOf(a.id) - split[1].indexOf(b.id));

          // Add split to rows
          itemRows.push([item, rightItems]);
        }
      }
    });

    return itemRows;
  }

  return { rows, splitItems, unsplitItems, addToSplit, removeFromSplit };
}
