import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Paper, Autoscale } from '@21st-night/ui';
import { OcclusionBox, LabelBox } from '../ImageOcclusion.types';
import {
  ImageOcclusionCard,
  ImageOcclusionCardProps,
} from '../ImageOcclusionCard';

interface Size {
  width: number;
  height: number;
}

export interface ImageOcclusionCardPreviewProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  canvasSize: Size;
  boxes: (OcclusionBox | LabelBox)[];
  boxId: string;
  mode?: ImageOcclusionCardProps['mode'];
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      borderRadius: 3,
      boxShadow:
        'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
    },
  }),
);

export const ImageOcclusionCardPreview: React.FC<ImageOcclusionCardPreviewProps> = ({
  className,
  image,
  canvasSize,
  boxes: boxesProp,
  boxId,
  mode = 'hide-all',
}) => {
  const classes = useStyles();
  const boxes = boxesProp.map(box =>
    box.type === 'label'
      ? {
          ...box,
          boxRef: React.createRef<HTMLDivElement>(),
          arrowHeadRef: React.createRef<HTMLDivElement>(),
        }
      : box,
  );

  return (
    <div style={{ width: '100%' }}>
      <Paper className={cn(classes.root, className)}>
        <Autoscale maxWidth={2000} maxHeight={2000} maxScale={1}>
          <ImageOcclusionCard
            mode={mode}
            image={image}
            canvasSize={canvasSize}
            boxId={boxId}
            boxes={boxes}
          />
        </Autoscale>
      </Paper>
    </div>
  );
};
