import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '../Typography';

export interface LabelledRowProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    label: {
      color: '#616161',
      fontWeight: 'bold',
      userSelect: 'none',
    },
    children: {
      flex: 1,
    },
  }),
);

export const LabelledRow: React.FC<LabelledRowProps> = ({
  className,
  label,
  children,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, className)} {...other}>
      <Typography variant="subtitle1" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.children}>{children}</div>
    </div>
  );
};
