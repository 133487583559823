import React from 'react';
import { makeStyles } from '@21st-night/styles';
import { Element } from '@sheets-editor/core';

export interface NumberedListItemProps {
  element: Element;
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    paddingLeft: 2,
    width: '100%',
    alignItems: 'flex-start',
    padding: '3px 0 3px 2px',
  },
  numberContainer: {
    marginRight: 4,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(1em + 3px + 3px)',
    flexGrow: 0,
    flexShrink: 0,
    paddingRight: 2,
    marginTop: '0.05em',
  },
  contentContainer: {
    flex: '1 1 0px',
    minWidth: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '3px 0',
  },
}));

export const NumberedListItem: React.FC<NumberedListItemProps> = ({
  element,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.numberContainer}>{element.number}.</div>
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};
