import React, { useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@21st-night/styles';
import {
  Dialog,
  Typography,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Button,
  IconButton,
  Hidden,
} from '@21st-night/ui';
import { Clear } from '@21st-night/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';
import { OnboardingContentType } from '@21st-night/onboarding';

export interface OnboardingCoachingInfoDialogProps {
  open: boolean;
  onClose: () => void;
  onClickActionButton: () => void;
  variant: OnboardingContentType;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: 600,
    },
    dialogContent: {
      padding: 0,
    },
    content: {
      padding: theme.spacing(1, 3),
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(1, 2),
      },
    },
    closeButtonContainer: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: theme.zIndex.modal + 999,
    },
    header: {
      position: 'relative',
      paddingTop: '42%',
      backgroundImage: `url(${require('./header.png')})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    title: {
      [theme.breakpoints.only('xs')]: {
        fontSize: '24px',
      },
      color: '#FFF',
      textAlign: 'center',
      position: 'absolute',
      top: '23%',
      right: 0,
      left: 0,
    },
    h2: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
    h2Subtext: {
      textAlign: 'center',
    },
    listItemIcon: {
      width: 40,
      height: 40,
      [theme.breakpoints.only('xs')]: {
        width: 24,
        height: 24,
      },
    },
    actionButtonContainer: {
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    actionButton: {
      backgroundColor: '#452D9E',
      borderRadius: 21,
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#5937D7',
      },
    },
    testimonialSlide: {
      height: 150,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      background: '#452D9F',
      [theme.breakpoints.only('xs')]: {
        height: 160,
      },
    },
    testimonialText: {
      color: '#FFF',
      fontSize: '18px',
      textAlign: 'left',
      [theme.breakpoints.only('xs')]: {
        fontSize: '16px',
      },
    },
    testimonialName: {
      color: '#FFF',
      fontSize: '20px',
      textAlign: 'left',
      [theme.breakpoints.only('xs')]: {
        fontSize: '16px',
      },
    },
  }),
);

export const OnboardingCoachingInfoDialog: React.FC<OnboardingCoachingInfoDialogProps> = ({
  variant: variantProp,
  onClose,
  onClickActionButton,
  ...other
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const content = useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState(0);
  const variant = ['SKILL', 'FACT', 'MIXED'].includes(variantProp)
    ? 'ACADEMIC'
    : variantProp;
  const variantName = variant === 'ACADEMIC' ? 'academic' : variant;

  useEffect(() => {
    new Image().src = require('./header.png');
    new Image().src = require('./rocket.png');
  }, []);

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      maxWidth="sm"
      onClose={onClose}
      {...other}
    >
      <div className={classes.closeButtonContainer}>
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </div>
      <DialogContent
        className={classes.dialogContent}
        style={{ paddingTop: 0 }}
        onScroll={() => {
          if (content.current) {
            setScrolled(content.current.scrollTop);
          }
        }}
        innerRef={content}
      >
        <div className={classes.header}>
          <Typography variant="h1" className={classes.title}>
            Let&apos;s study together
          </Typography>
          <Hidden xsDown>
            <img
              src={require('./rocket.png')}
              style={{
                position: 'absolute',
                left: 34,
                bottom: 40 + scrolled * 1.5,
                width: 81,
                height: 81,
              }}
            />
          </Hidden>
        </div>
        <div className={classes.content}>
          <Typography paragraph variant="subtitle2">
            Wondering if you&apos;re on track for your goal score?
          </Typography>
          <Typography paragraph>
            Start {variantName} coaching to keep yourself organized, continually
            improving, and on target.
          </Typography>
          {variant !== 'ACADEMIC' && (
            <Typography paragraph>
              Not only did all of our coaches score in the{' '}
              <strong>98th percentile</strong> or above on their own{' '}
              {variantName}, they&apos;ve also helped dozens of students just
              like you achieve their goal scores on theirs.
            </Typography>
          )}
          {variant === 'ACADEMIC' && (
            <Typography paragraph>
              Not only are all of our coaches excellent students themselves,
              they&apos;ve also helped dozens of students just like you become
              excellent students as well.
            </Typography>
          )}
          <Typography variant="h2" className={classes.h2}>
            Coaching includes
          </Typography>
          <List>
            <ListItem disableGutters={isMobile}>
              <Hidden smUp>
                <ListItemIcon>
                  <Avatar
                    variant="square"
                    src={require('./study-plan.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemIcon>
              </Hidden>
              <Hidden only="xs">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={require('./study-plan.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemAvatar>
              </Hidden>
              <ListItemText>
                A customized study plan to address your weaknesses and get your
                goal score (no cookie-cutter plans here)
              </ListItemText>
            </ListItem>
            <ListItem disableGutters={isMobile}>
              <Hidden smUp>
                <ListItemIcon>
                  <Avatar
                    variant="square"
                    src={require('./resources.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemIcon>
              </Hidden>
              <Hidden only="xs">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={require('./resources.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemAvatar>
              </Hidden>
              <ListItemText>
                The resources we used to achieve our own top scores
              </ListItemText>
            </ListItem>
            <ListItem disableGutters={isMobile}>
              <Hidden smUp>
                <ListItemIcon>
                  <Avatar
                    variant="square"
                    src={require('./check-in.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemIcon>
              </Hidden>
              <Hidden only="xs">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={require('./check-in.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemAvatar>
              </Hidden>
              <ListItemText>
                Weekly check-ins, with quizzing, content review, and homework
                assigned
              </ListItemText>
            </ListItem>
            <ListItem disableGutters={isMobile}>
              <Hidden smUp>
                <ListItemIcon>
                  <Avatar
                    variant="square"
                    src={require('./phone.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemIcon>
              </Hidden>
              <Hidden only="xs">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={require('./phone.png')}
                    className={classes.listItemIcon}
                  />
                </ListItemAvatar>
              </Hidden>
              <ListItemText>
                Our phone numbers. Seriously. Text us anytime with any question
                about {variant === 'ACADEMIC' ? 'your exam' : `the ${variant}`}{' '}
                and we will respond as soon as we possibly can.
              </ListItemText>
            </ListItem>
          </List>
          <Typography variant="h2" className={classes.h2}>
            ${variant === 'ACADEMIC' ? '99' : '299'}/month
          </Typography>
          <Typography gutterBottom className={classes.h2Subtext}>
            7 day money back guarantee
          </Typography>
          <div className={classes.actionButtonContainer}>
            <Button
              size="large"
              variant="contained"
              color="inherit"
              onClick={onClickActionButton}
              className={classes.actionButton}
            >
              Fill out the form for a free consultation!
            </Button>
          </div>
        </div>
        <Carousel showThumbs={false} showStatus={false}>
          <div
            className={classes.testimonialSlide}
            style={{
              backgroundImage: isMobile
                ? 'none'
                : `url(${require('./testimonial-1.png')})`,
              padding: isMobile
                ? theme.spacing(1.5, 2)
                : '16px 70px 40px 110px',
            }}
          >
            <Typography className={classes.testimonialText}>
              “Coaching took me from the 54th percentile on my first attempt to
              the 95th on my retake. Thank you for the assistance!”
            </Typography>
            <Typography className={classes.testimonialName}>
              — Snehal Bindra
            </Typography>
          </div>
          <div
            className={classes.testimonialSlide}
            style={{
              backgroundImage: isMobile
                ? 'none'
                : `url(${require('./testimonial-2.png')})`,
              padding: isMobile ? theme.spacing(4, 2) : '30px 170px 40px 60px',
            }}
          >
            <Typography className={classes.testimonialText}>
              “My coach has been a great mentor to me in my studying.”
            </Typography>
            <Typography className={classes.testimonialName}>
              — Kyle Dusch
            </Typography>
          </div>
          <div
            className={classes.testimonialSlide}
            style={{
              backgroundImage: isMobile
                ? 'none'
                : `url(${require('./testimonial-3.png')})`,
              padding: isMobile ? theme.spacing(2) : '30px 70px 40px 130px',
            }}
          >
            <Typography className={classes.testimonialText}>
              “Thanks to coaching, retaking the exam this time felt so much
              better. My coach was amazing.”
            </Typography>
            <Typography className={classes.testimonialName}>
              — Kristin Recker
            </Typography>
          </div>
          <div
            className={classes.testimonialSlide}
            style={{
              backgroundImage: isMobile
                ? 'none'
                : `url(${require('./testimonial-4.png')})`,
              padding: isMobile ? theme.spacing(4, 2) : '40px 170px 40px 80px',
            }}
          >
            <Typography className={classes.testimonialText}>
              “Working with my coach was incredible.”
            </Typography>
            <Typography className={classes.testimonialName}>
              — Jordan Erickson
            </Typography>
          </div>
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};
