import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Range, Transforms } from 'slate';
import {
  RenderElementProps,
  useEditorState,
  useUI,
} from '@braindrop-editor/core';
import {
  InsertVideo,
  OpenInNew,
  Delete,
  Replace,
  AddBlockAbove,
  AddBlockBelow,
  MoreHoriz,
} from '@21st-night/icons';
import { makeStyles } from '@21st-night/styles';
import { Button, Typography } from '@21st-night/ui';
import { ReactEditor, useSlate, useSelected } from 'slate-react';
import { BlockPlaceholder } from '../../../components';
import { MediaPlayer } from '../../../components/MediaPlayer';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';
import { VideoElement, EditorWithVideoPlugin } from '../VideoPlugin.types';
import { EditorWithApiPlugin } from '../../plugin-editor-api';

export type ElementVideoProps = RenderElementProps<VideoElement>;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  player: {
    margin: theme.spacing(1, 0),
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: '#000',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
  popoverContent: {
    width: 400,
    maxWidth: '80vw',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  helperText: {
    userSelect: 'none',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  menuPopoverAnchor: {
    position: 'absolute',
    top: theme.spacing(1) + 4,
    right: theme.spacing(1) + 4,
  },
}));

export const ElementVideo: React.FC<ElementVideoProps> = ({
  attributes,
  children,
  element,
}) => {
  const classes = useStyles();
  const editor = useSlate() as EditorWithVideoPlugin & EditorWithApiPlugin;
  const selected = useSelected();
  const [value, setValue] = useState('');
  const { Popover, TextField, List, MenuItem } = useUI();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuPopoverAnchor = useRef<HTMLDivElement>(null);
  const { toggle, turnOff, turnOn } = useEditorState();
  const divRef = attributes.ref || useRef<HTMLDivElement>(null);
  const toggleKey = `video-popover-${element.id}`;
  const open = toggle(toggleKey);

  const openPopover = useCallback(() => {
    turnOn(toggleKey);
  }, []);

  const closePopover = useCallback(() => {
    turnOff(toggleKey);
  }, []);

  useEffect(() => {
    if (
      editor.selection &&
      selected &&
      Range.isCollapsed(editor.selection) &&
      !element.url
    ) {
      openPopover();
    }
  }, [selected, editor.selection]);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      closePopover();
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { url: value } as Partial<VideoElement>, {
        at: path,
      });
    },
    [value, element],
  );

  const deleteNode = useCallback(() => {
    editor.deleteElement(element);
  }, [element]);

  const openMenu = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleClickOpenLink = useCallback(() => {
    closeMenu();
    if (!element.url) {
      return;
    }
    window.open(element.url as string, '_blank');
  }, [element.url]);

  const handleClickReplace = useCallback(() => {
    closeMenu();
    setValue('');
    openPopover();
  }, []);

  return (
    <div {...attributes} ref={divRef} className={classes.root}>
      {!element.url && (
        <BlockPlaceholder
          onClick={openPopover}
          onClickDelete={deleteNode}
          label="Add a video"
          icon={<InsertVideo />}
        />
      )}
      {element.url && (
        <div contentEditable={false} className={classes.player}>
          <MediaPlayer url={element.url as string} />
        </div>
      )}
      {element.url && (
        <div className={classes.toolbar}>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph above"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphAbove(element);
            }}
          >
            <AddBlockAbove />
          </EditorToolbarButton>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph below"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphBelow(element);
            }}
          >
            <AddBlockBelow />
          </EditorToolbarButton>
          <EditorToolbarButton
            contrast
            tooltip="Image options"
            onClick={openMenu}
          >
            <MoreHoriz />
          </EditorToolbarButton>
        </div>
      )}
      <div
        className={classes.menuPopoverAnchor}
        contentEditable={false}
        ref={menuPopoverAnchor}
      />
      {children}
      <Popover
        open={open}
        anchorEl={divRef.current}
        onClose={closePopover}
        anchorOrigin={{
          vertical: element.url ? 'top' : 'bottom',
          horizontal: 'center',
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className={classes.popoverContent}>
            <TextField
              autoFocus
              placeholder="Paste the video link..."
              className={classes.textField}
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValue(event.target.value)
              }
            />

            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.button}
              type="submit"
            >
              Embed video
            </Button>
            <Typography variant="caption" className={classes.helperText}>
              Works with YouTube, Vimeo, and more
            </Typography>
          </div>
        </form>
      </Popover>
      <Popover
        open={menuOpen}
        anchorEl={menuPopoverAnchor.current}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <MenuItem
            primaryText="Open link"
            icon={<OpenInNew fontSize="small" />}
            onClick={handleClickOpenLink}
          />
          <MenuItem
            primaryText="Replace"
            icon={<Replace fontSize="small" />}
            onClick={handleClickReplace}
          />
          <MenuItem
            primaryText="Delete"
            icon={<Delete fontSize="small" />}
            onClick={deleteNode}
          />
        </List>
      </Popover>
    </div>
  );
};
