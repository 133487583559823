import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  Button,
} from '@21st-night/ui';
import { DeckContentCard } from '@21st-night/deck';
import { DeckCardSelector, DeckCardSelectorProps } from '../DeckCardSelector';

export interface DeckCardSelectionDialogProps extends DialogProps {
  onSelectCard: (card: DeckContentCard) => void;
  exclude?: DeckCardSelectorProps['exclude'];
  onClose: () => void;
}

export const DeckCardSelectionDialog: React.FC<DeckCardSelectionDialogProps> = ({
  onSelectCard,
  exclude,
  onClose,
  ...other
}) => {
  const handleSelectCard = (card: DeckContentCard) => {
    onSelectCard(card);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { minHeight: '80vh' } }}
      onClose={onClose}
      {...other}
    >
      <DialogContent>
        <DeckCardSelector onSelectCard={handleSelectCard} exclude={exclude} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
