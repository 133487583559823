import { v4 as uuid } from 'uuid';
import { ReviewSession } from '../../Review.types';

export function generateReviewSession(
  options?: Partial<ReviewSession>,
): ReviewSession {
  return {
    id: uuid(),
    startedAt: new Date(),
    lastActivity: new Date(),
    cards: [],
    cardAttempts: {},
    duration: 0,
    completed: false,
    ...options,
  };
}
