import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from '@21st-night/ui';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { OnboardingContentType } from '@21st-night/onboarding';

export const headingImagePosition: Record<OnboardingContentType, string> = {
  TUTOR: '0 0',
  MIXED: '0 -64px',
  SKILL: '0 -128px',
  FACT: '0 -192px',
  GMAT: '0 -256px',
  GRE: '0 -320px',
  LSAT: '0 -384px',
  MCAT: '0 -448px',
  ARE: '0 -448px',
};

export interface OnboardingCompletedDialogProps extends DialogProps {
  onboardingType: OnboardingContentType;
  onClickClose: () => void;
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
    },
    caption: {
      display: 'block',
      textAlign: 'center',
    },
    headingImage: {
      width: 400,
      display: 'block',
      height: 64,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '400px 512px',
      background: `url(${require('./heading.png')})`,
      backgroundPosition: ({
        onboardingType,
      }: {
        onboardingType: OnboardingContentType;
      }) => headingImagePosition[onboardingType],
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(3),
    },
    tasksImage: {
      marginTop: theme.spacing(4),
      maxWidth: 800,
      paddingTop: '22.5%',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      background: `url(${require('./tasks.png')}) 0 0`,
      backgroundSize: 'contain',
    },
    tasksCaption: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const OnboardingCompletedDialog: React.FC<OnboardingCompletedDialogProps> = ({
  onboardingType,
  onClickClose,
  ...other
}) => {
  const classes = useStyles({ onboardingType });

  return (
    <Dialog classes={{ paper: classes.paper }} {...other}>
      <DialogContent>
        <DialogContentText>
          <Typography color="textPrimary">
            You completed your pre-studying checklist. Congrats! You’re ready to
            start your study plan. Today’s to-dos are located on the main
            screen, which you can click 21st Night to access.
          </Typography>
          <a href="/">
            <div className={classes.headingImage} />
          </a>
          <Typography variant="caption" className={classes.caption}>
            Click on &ldquo;21<sup>st</sup> Night&rdquo;
          </Typography>
          <div className={classes.tasksImage} />
          <Typography
            variant="caption"
            className={cn(classes.caption, classes.tasksCaption)}
          >
            This week&apos;s tasks are below your decks
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClickClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
