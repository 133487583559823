import { validateDoc, DocValidator } from '@21st-night/utils';
import { NOTE_TYPES, Note, NoteUpdateData } from '../Notes.types';

export const NewDeckNoteValidator: DocValidator<Note> = {
  type: [NOTE_TYPES],
  id: 'string',
  createdAt: 'date',
  deck: 'string',
  author: 'string',
  owner: 'string',
  cards: ['string-array'],
  title: ['string', 'empty-string'],
  content: 'string',
  file: ['optional', 'string'],
  fileId: ['optional', 'string'],
};

export const DeckNoteUpdateValidator: DocValidator<NoteUpdateData> = {
  cards: ['optional', 'string-array', 'arrayUnion', 'arrayRemove'],
  content: ['optional', 'string'],
  title: ['optional', 'string', 'empty-string'],
};

export function validateNewNote(doc: Note): boolean {
  return validateDoc(NewDeckNoteValidator, doc);
}

export function validateNoteUpdate(doc: NoteUpdateData): boolean {
  return validateDoc(DeckNoteUpdateValidator, doc);
}
