import { WebViewWindow } from '../../types';

declare let window: WebViewWindow;

export function addWebViewMessageListener(
  callback: (message: string) => void,
): void {
  if (!window.webViewEventListeners) {
    window.webViewEventListeners = [];
  }

  window.webViewEventListeners.push(callback);
}
