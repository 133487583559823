import React from 'react';
import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@material-ui/core';

export type ListItemProps = MuiListItemProps;

export const ListItem: React.FC<ListItemProps> = ({ ...other }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <MuiListItem {...other} />;
};
