import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Typography, Button, Link } from '@21st-night/ui';

export interface ImportFromAnkiFileSelectionProps {
  fileInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  file?: File;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    section: {
      marginBottom: theme.spacing(3),
    },
    fileInput: {
      opacity: 0,
      pointerEvents: 'none',
      position: 'fixed',
      left: -1000,
      top: -1000,
    },
    instructions: {
      marginTop: theme.spacing(2),
    },
  }),
);

function shortenFileName(file: File) {
  if (file.name.length > 30) {
    return `${file.name.slice(0, 30).trim()}...`;
  }

  return file.name;
}

export const ImportFromAnkiFileSelection: React.FC<ImportFromAnkiFileSelectionProps> = ({
  fileInputProps = {},
  file,
}) => {
  const classes = useStyles();
  const fileInput = useRef<HTMLInputElement>(null);

  function openCsvFileSelect() {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }

  return (
    <>
      <div className={classes.section}>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={openCsvFileSelect}
        >
          {file ? shortenFileName(file) : 'Choose an Anki deck file'}
        </Button>
      </div>
      <div className={classes.section}>
        <Typography variant="subtitle1">Supported formats</Typography>
        <Typography gutterBottom color="textSecondary">
          <ul>
            <li>Rich text (bold, italic, underlined)</li>
            <li>Equations (written in MathJax or LaTeX)</li>
            <li>Cloze deletions</li>
            <li>Embedded images and audio</li>
          </ul>
        </Typography>
      </div>
      {createPortal(
        <input
          accept=".apkg"
          {...fileInputProps}
          ref={fileInput}
          type="file"
          className={classes.fileInput}
        />,
        document.body,
      )}
    </>
  );
};
