import { DeckCard, DeckItem, DeckNote } from '../../Deck.types';

export function filterByCategory<T extends DeckItem | DeckCard | DeckNote>(
  items: T[],
  filterCategories: string[],
): T[] {
  return items.filter(
    item => item.category && filterCategories.includes(item.category),
  );
}
