import React from 'react';
import { isBlockAboveEmpty, Transforms } from '@braindrop-editor/core';
import { Code } from '@21st-night/icons';
import {
  EditorToolbarButton as Button,
  EditorToolbarButtonProps,
} from '../components/EditorToolbarButton';
import { isFocusElementOfType } from '../api';
import {
  CodeElement,
  EditorWithCodePlugin,
} from '@braindrop-editor/plugin-code';

export interface ToolbarInsertCodeBlockButtonProps
  extends Partial<EditorToolbarButtonProps> {
  editor: EditorWithCodePlugin;
}

export const ToolbarInsertCodeBlockButton: React.FC<ToolbarInsertCodeBlockButtonProps> = ({
  editor,
  ...other
}) => {
  return (
    <Button
      tooltip="Insert code block"
      shortcut="ctrl+alt+9"
      active={isFocusElementOfType(editor, 'code')}
      onMouseDown={(event): void => {
        event.preventDefault();
        if (isFocusElementOfType(editor, 'code')) {
          Transforms.setNodes(editor, { type: 'paragraph' });
        } else {
          if (isBlockAboveEmpty(editor)) {
            Transforms.setNodes(editor, {
              type: 'code',
              language: 'javascript',
            } as CodeElement);
          } else {
            editor.insertElement('code');
          }
        }
      }}
      {...other}
    >
      <Code />
    </Button>
  );
};
