import React, { useReducer, useCallback } from 'react';
import { createContext } from '@21st-night/utils';
import {
  OnboardingQuestionId,
  OnboardingStepId,
  OnboardingAnswer,
  OnboardingAnswers,
  OnboardingQuestion,
} from '../onboarding.types';

interface QuestionHistoryItem {
  id: OnboardingQuestionId;
  progress: number;
}

type CompleteStep = (
  step: OnboardingStepId,
  nextStep?: OnboardingStepId,
) => void;
type CompleteOnboarding = () => void;
type AnswerQuestion = (
  question: OnboardingQuestion,
  answer: OnboardingAnswer,
  nextQuestion?: OnboardingQuestion,
) => void;
type SetProgress = (progress: number) => void;
type BackToPreviousQuestion = () => void;

export interface OnboardingState {
  completed: boolean;
  progress: number;
  segmentationCompleted: boolean;
  step: OnboardingStepId | null;
  userType: 'student' | 'tutor' | 'parent' | null;
  question: OnboardingQuestionId | null;
  answers: OnboardingAnswers;
  answeredQuestions: QuestionHistoryItem[];
  completedSteps: OnboardingStepId[];
}

export interface OnboardingStateProviderProps {
  initialState?: Partial<OnboardingState>;
}

export interface OnboardingStateContext extends OnboardingState {
  completeStep: CompleteStep;
  completeOnboarding: CompleteOnboarding;
  answerQuestion: AnswerQuestion;
  setProgress: SetProgress;
  backToPreviousQuestion: BackToPreviousQuestion;
}

const [hook, Provider] = createContext<OnboardingStateContext>();

type Action =
  | { type: 'RESET' }
  | { type: 'LOAD'; state: OnboardingState }
  | {
      type: 'COMPLETE_STEP';
      step: OnboardingStepId;
      nextStep?: OnboardingStepId;
    }
  | { type: 'COMPLETE_ONBOARDING' }
  | {
      type: 'ANSWER_QUESTION';
      question: OnboardingQuestion;
      answer: OnboardingAnswer;
      nextQuestion?: OnboardingQuestion;
    }
  | {
      type: 'SET_PROGRESS';
      progress: number;
    }
  | {
      type: 'PREVIOUS_QUESTION';
    };

function generateDefaultState(
  initialState?: Partial<OnboardingState>,
): OnboardingState {
  return {
    step: 'segmentation',
    progress: 0,
    completed: false,
    segmentationCompleted: false,
    userType: null,
    question: 'method',
    answers: {},
    answeredQuestions: [],
    completedSteps: [],
    ...initialState,
  };
}

const reducer = (state: OnboardingState, action: Action): OnboardingState => {
  switch (action.type) {
    case 'RESET':
      return generateDefaultState();
    case 'COMPLETE_STEP':
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.step],
        step: action.nextStep || null,
      };
    case 'COMPLETE_ONBOARDING':
      return {
        ...state,
        completed: true,
      };
    case 'ANSWER_QUESTION':
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.question.answerKey]: action.answer,
        },
        question: action.nextQuestion ? action.nextQuestion.id : null,
        progress: action.nextQuestion
          ? action.nextQuestion.progress
          : state.progress,
        answeredQuestions: [
          ...state.answeredQuestions,
          { id: action.question.id, progress: action.question.progress },
        ],
      };
    case 'SET_PROGRESS':
      return {
        ...state,
        progress: action.progress,
      };
    case 'PREVIOUS_QUESTION':
      return state.answeredQuestions.length
        ? {
            ...state,
            question: state.answeredQuestions.slice(-1)[0].id,
            progress: state.answeredQuestions.slice(-1)[0].progress,
            answeredQuestions: state.answeredQuestions.slice(0, -1),
          }
        : state;
    default:
      throw new Error('OnboardingStateProvider action was not handled');
  }
};

export const OnboardingStateProvider: React.FC<OnboardingStateProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    generateDefaultState(initialState),
  );

  const completeStep: CompleteStep = useCallback((step, nextStep) => {
    dispatch({ type: 'COMPLETE_STEP', step, nextStep });
  }, []);

  const completeOnboarding: CompleteOnboarding = useCallback(() => {
    dispatch({ type: 'COMPLETE_ONBOARDING' });
  }, []);

  const answerQuestion: AnswerQuestion = useCallback(
    (question, answer, nextQuestion) => {
      dispatch({ type: 'ANSWER_QUESTION', question, answer, nextQuestion });
    },
    [],
  );

  const setProgress: SetProgress = useCallback(progress => {
    dispatch({ type: 'SET_PROGRESS', progress });
  }, []);

  const backToPreviousQuestion: BackToPreviousQuestion = useCallback(() => {
    dispatch({ type: 'PREVIOUS_QUESTION' });
  }, []);

  return (
    <Provider
      value={{
        ...state,
        completeStep,
        completeOnboarding,
        answerQuestion,
        setProgress,
        backToPreviousQuestion,
      }}
    >
      {children}
    </Provider>
  );
};

export const useOnboardingState = hook;
