import React, { useEffect, useRef, useState } from 'react';
import { Editor as SlateEditor, Range, Node } from 'slate';
import { makeStyles } from '@21st-night/styles';
import { useSlate, useFocused, ReactEditor } from 'slate-react';
import {
  KeyboardHide,
  InsertPhoto,
  InsertAudio,
  PhotoLibrary,
  Camera,
  Folder,
  Cancel,
} from '@21st-night/icons';
import { Transforms } from '@braindrop-editor/core';
import { isText } from '@21st-night/editor';
import { postWebViewEditorEvent } from '@21st-night/web-view';
import { EditorToolbarButton as Button } from '../components/EditorToolbarButton';
import { MobileMenuItem } from '../components/MobileMenuItem';
import { useMobileEditorToolbar } from './MobileEditorToolbarProvider';
import {
  ToolbarSpacer,
  ToolbarBlockFormattingButtons,
  ToolbarInsertCodeBlockButton,
  ToolbarInsertEquationBlockButton,
  ToolbarInsertInlineEquationButton,
  ToolbarInsertLinkButton,
  ToolbarRichTextFormattingButtons,
} from '../Toolbar';
import { MobileEditor } from './MobileEditor';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1000,
    width: '100%',
    display: 'flex',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  buttons: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(3),
    flex: 1,
    overflowY: 'scroll',
    display: 'flex',
    MsOverflowStyle: 'none',
    scrollbarWidth: 'none',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > :not(:first-child)': {
      marginLeft: 2,
    },
  },
  hideKeyboardButton: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

function focusEditor(editor: SlateEditor, lastSelection: Range | null) {
  if (!editor.selection && !lastSelection) {
    const [node, path] = Node.last(editor, []);
    let offset = 0;
    if (isText(node)) {
      offset = node.text.length;
    }
    Transforms.select(editor, {
      focus: { path, offset },
      anchor: { path, offset },
    });
  } else if (lastSelection) {
    Transforms.select(editor, lastSelection);
  }
}

export const MobileEditorToolbar: React.FC = ({ children }) => {
  const classes = useStyles();
  const editor = useSlate() as MobileEditor;
  const focused = useFocused();
  const {
    editorToolbarShown,
    showEditorToolbar,
    hideEditorToolbar,
  } = useMobileEditorToolbar();
  const [lastSelection, setLastSelection] = useState(editor.selection);
  const [imageMenuOpen, setImageMenuOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const imageButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (editor.selection) {
      setLastSelection(editor.selection);
    }
  }, [editor.selection]);

  useEffect(() => {
    if (imageMenuOpen) {
      setTimeout(() => {
        ReactEditor.focus(editor);
      });
    }
  }, [imageMenuOpen, editor]);

  useEffect(() => {
    if (focused) {
      showEditorToolbar();
    } else {
      hideEditorToolbar();
    }
  }, [focused]);

  if (!editorToolbarShown) {
    return null;
  }

  if (imageMenuOpen) {
    return (
      <div ref={rootRef} className={classes.root}>
        <div className={classes.buttons} style={{ justifyContent: 'center' }}>
          <MobileMenuItem
            primaryText="Library"
            icon={<PhotoLibrary fontSize="small" />}
            onMouseDown={event => {
              event.preventDefault();
              focusEditor(editor, lastSelection);
              postWebViewEditorEvent('INSERT_IMAGE', 'library');
              setImageMenuOpen(false);
            }}
          />
          <MobileMenuItem
            primaryText="Camera"
            icon={<Camera fontSize="small" />}
            onMouseDown={event => {
              event.preventDefault();
              focusEditor(editor, lastSelection);
              postWebViewEditorEvent('INSERT_IMAGE', 'camera');
              setImageMenuOpen(false);
            }}
          />
          <MobileMenuItem
            primaryText="Browse"
            icon={<Folder fontSize="small" />}
            onMouseDown={event => {
              event.preventDefault();
              focusEditor(editor, lastSelection);
              postWebViewEditorEvent('INSERT_IMAGE', 'files');
              setImageMenuOpen(false);
            }}
          />
        </div>
        <div className={classes.hideKeyboardButton}>
          <Button
            touch
            onMouseDown={event => {
              event.preventDefault();
              focusEditor(editor, lastSelection);
              setImageMenuOpen(false);
            }}
          >
            <Cancel />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div ref={rootRef} className={classes.root}>
      <div className={classes.buttons}>
        {children}
        <ToolbarRichTextFormattingButtons touch editor={editor} />
        <ToolbarInsertLinkButton touch editor={editor} />
        <ToolbarInsertInlineEquationButton touch editor={editor} />
        <ToolbarSpacer />
        <ToolbarBlockFormattingButtons touch editor={editor} />
        <ToolbarSpacer />
        <span ref={imageButton}>
          <Button
            touch
            onMouseDown={(event): void => {
              event.preventDefault();
              focusEditor(editor, lastSelection);
              setImageMenuOpen(true);
            }}
          >
            <InsertPhoto />
          </Button>
        </span>
        <Button
          touch
          onMouseDown={event => {
            event.preventDefault();
            focusEditor(editor, lastSelection);
            postWebViewEditorEvent('INSERT_AUDIO');
          }}
        >
          <InsertAudio />
        </Button>
        <ToolbarSpacer />
        <ToolbarInsertCodeBlockButton touch editor={editor} />
        <ToolbarInsertEquationBlockButton touch editor={editor} />
        <ToolbarSpacer />
      </div>
      <div className={classes.hideKeyboardButton}>
        <Button
          touch
          onMouseDown={(): void => {
            ReactEditor.blur(editor);
            hideEditorToolbar();
          }}
        >
          <KeyboardHide />
        </Button>
      </div>
    </div>
  );
};
