import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Papa from 'papaparse';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@21st-night/ui';
import { useFileUpload, Storage, Functions, DB } from '@21st-night/utils-web';
import {
  generateRichTextDocument,
  serializeDocument,
} from '@21st-night/editor-web';
import { CardData } from '@21st-night/cards';
import {
  CsvRow,
  ColumnMap,
  ColumnType,
  ParseResults,
  CardMediaMatches,
} from './ImportFromCsv.types';
import { ImportFromCsvFileSelection as FileSelection } from './ImportFromCsvFileSelection';
import { ImportFromCsvSettings as Settings } from './ImportFromCsvSettings';
import { ImportFromCsvParsing as Parsing } from './ImportFromCsvParsing';
import { ImportFromCsvCreatingCards as CreatingCards } from './ImportFromCsvCreatingCards';
import { ImportFromCsvDone as Done } from './ImportFromCsvDone';
import { ImportFromCsvConfirmation as Confirmation } from './ImportFromCsvConfirmation';
import {
  ImportFromCsvMediaUploadProgress as MediaUploadProgress,
  MediaFileStatus,
} from './ImportFromCsvMediaUploadProgress';
import { addMediaToCards } from './addMediaToCards';

export type View =
  | 'file-selection'
  | 'column-configuration'
  | 'media-upload-progress'
  | 'parsing'
  | 'confirmation'
  | 'creating-cards'
  | 'done';

export type MediaFilesMap = Record<string, string>;

export interface ImportFromCsvDialogProps extends Omit<DialogProps, 'onClose'> {
  storage: Storage;
  db: DB;
  functions: Functions;
  createCards: (cards: CardData[]) => Promise<void>;
  onClose: () => void;
}

function delimiterToSelectValue(delimiter: string): string {
  if (delimiter.charCodeAt(0) === 13 || delimiter.charCodeAt(0) === 10) {
    return 'newline';
  } else if (delimiter.charCodeAt(0) === 9) {
    return 'tab';
  } else if (delimiter === ',') {
    return 'comma';
  } else if (delimiter === ';') {
    return 'semicolon';
  }

  return 'custom';
}

export const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      width: '100%',
    },
    flex: {
      flex: 1,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    stepCounter: {
      color: theme.palette.text.secondary,
    },
  }),
);

function getRowProperty(
  property: ColumnType,
  row: CsvRow,
  columnMap: ColumnMap,
): string | undefined {
  let index = -1;

  Object.keys(columnMap).forEach(column => {
    const colNumber = parseInt(column);
    if (columnMap[colNumber] === property) {
      index = colNumber;
    }
  });

  if (index !== -1) {
    return row[index];
  }

  return undefined;
}

function generateCardData(
  row: CsvRow,
  columnMap: ColumnMap,
  subcategoriesDelimiter: string,
): CardData {
  const card: CardData = {
    id: uuid(),
    question: getRowProperty('question', row, columnMap) || '',
    answer: getRowProperty('answer', row, columnMap) || '',
    explanation: getRowProperty('explanation', row, columnMap),
    summary: getRowProperty('summary', row, columnMap),
    category: getRowProperty('category', row, columnMap),
    // Images
    questionImage: getRowProperty('questionImage', row, columnMap),
    answerImage: getRowProperty('answerImage', row, columnMap),
    explanationImage: getRowProperty('explanationImage', row, columnMap),
    summaryImage: getRowProperty('summaryImage', row, columnMap),
    // Audio
    questionAudio: getRowProperty('questionAudio', row, columnMap),
    answerAudio: getRowProperty('answerAudio', row, columnMap),
    explanationAudio: getRowProperty('explanationAudio', row, columnMap),
    summaryAudio: getRowProperty('summaryAudio', row, columnMap),
  };

  const subcategories = getRowProperty('subcategories', row, columnMap);

  if (card.question) {
    card.question = serializeDocument(
      generateRichTextDocument([card.question]),
    );
  }

  if (card.answer) {
    card.answer = serializeDocument(generateRichTextDocument([card.answer]));
  }

  if (card.explanation) {
    card.explanation = serializeDocument(
      generateRichTextDocument([card.explanation]),
    );
  } else if (card.explanationImage || card.explanationAudio) {
    card.explanation = serializeDocument(generateRichTextDocument());
  }

  if (card.summary) {
    card.summary = serializeDocument(generateRichTextDocument([card.summary]));
  } else if (card.summaryImage || card.summaryAudio) {
    card.summary = serializeDocument(generateRichTextDocument());
  }

  if (subcategories) {
    if (subcategoriesDelimiter) {
      // Contains multiple subcategories
      card.subcategories = subcategories.split(subcategoriesDelimiter);
    } else {
      // Contains a single subcategory
      card.subcategories = [subcategories];
    }
  }

  return card;
}

export const ImportFromCsvDialog: React.FC<ImportFromCsvDialogProps> = ({
  db,
  storage,
  functions,
  createCards,
  onClose,
  open,
  ...other
}) => {
  const classes = useStyles();
  const [view, setView] = useState<View>('file-selection');
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState<File>();
  const [rawCards, setRawCards] = useState<CardData[]>([]);
  const [mediaFilesMap, setMediaFilesMap] = useState<MediaFilesMap>({});
  const [mediaFile, setMediaFile] = useState<File>();
  const [mediaFileStatus, setMediaFileStatus] = useState<MediaFileStatus>(
    'none',
  );
  const [columnCount, setColumnCount] = useState(2);
  const [hasHeaderRow, setHasHeaderRow] = useState(false);
  const [headerRow, setHeaderRow] = useState<string[]>();
  const [columnDelimiter, setColumnDelimiter] = useState('');
  const [customColumnDelimiter, setCustomColumnDelimiter] = useState('');
  const [subcategoriesDelimiter, setSubcategoriesDelimiter] = useState('');
  const [rowDelimiter, setRowDelimiter] = useState('');
  const [customRowDelimiter, setCustomRowDelimiter] = useState('');
  const [parseResults, setParseResults] = useState<ParseResults>({
    success: [],
    missingMedia: [],
    missingQuestion: [],
    missingAnswer: [],
  });
  const [columnMap, setColumnMap] = useState<ColumnMap>({
    0: 'question',
    1: 'answer',
  });
  const hasRequiredTypes =
    Object.values(columnMap).includes('question') &&
    Object.values(columnMap).includes('answer');
  // Initialize extractTextFromImage Firebase function
  const unzipMediaFile = functions.httpsCallable('unzip');

  function extensionToLowerCase(name: string): string {
    const parts = name.split('.');

    if (parts.length > 1) {
      parts[parts.length - 1] = parts[parts.length - 1].toLocaleLowerCase();
    }
    return parts.join('.');
  }

  useEffect(() => {
    if (!open && csvFile) {
      setTimeout(() => {
        setView('file-selection');
        setCsvFile(undefined);
        setMediaFile(undefined);
        setMediaFileStatus('none');
        setSubcategoriesDelimiter('');
        setLoading(false);
        setRawCards([]);
        setColumnCount(2);
        setHasHeaderRow(false);
        setHeaderRow(undefined);
      }, 1500);
    }
  }, [open, csvFile]);

  const parseCsv = () => {
    if (!csvFile) {
      return;
    }

    setLoading(true);
    setView('parsing');

    let delimiter = columnDelimiter;

    if (delimiter === 'custom') {
      delimiter = customColumnDelimiter;
    } else if (delimiter === 'comma') {
      delimiter = ',';
    } else if (delimiter === 'semicolon') {
      delimiter = ';';
    } else if (delimiter === 'tab') {
      delimiter = '\t';
    }

    let newline = rowDelimiter;

    if (newline === 'custom') {
      newline = customRowDelimiter;
    } else if (newline === 'semicolon') {
      newline = ';';
    } else if (newline === 'newline') {
      newline = '\n';
    } else if (newline === 'tab') {
      newline = '\t';
    }

    Papa.parse(csvFile, {
      delimiter,
      newline,
      complete: function (results) {
        let data = results.data as CsvRow[];
        if (hasHeaderRow) {
          data = data.slice(1);
        }
        const cardsData: CardData[] = [];
        const mediaMatches: CardMediaMatches[] = [];
        const missingQuestion: CardData[] = [];
        const missingAnswer: CardData[] = [];
        const success: CardData[] = [];
        const sortedMediaFileNames = Object.keys(mediaFilesMap)
          .sort(function (a, b) {
            return b.length - a.length;
          })
          // Ignore folder names
          .filter(name => !name.includes('/'));

        data.forEach((row, index) => {
          const card = {
            ...generateCardData(row, columnMap, subcategoriesDelimiter),
            row: index + (hasHeaderRow ? 2 : 1),
          };

          cardsData.push(card);

          const noQuestion =
            !card.question && !card.questionImage && !card.questionAudio;
          const noAnswer =
            !card.answer && !card.answerImage && !card.answerAudio;

          if (noQuestion) {
            missingQuestion.push(card);
          }

          if (noAnswer) {
            missingAnswer.push(card);
          }
          if (!noQuestion && !noAnswer) {
            success.push(card);
          }
        });

        if (sortedMediaFileNames.length) {
          // Sorted longest to shortest
          cardsData.forEach(card => {
            let questionImageMatched = !card.questionImage;
            let answerImageMatched = !card.answerImage;
            let explanationImageMatched = !card.explanationImage;
            let summaryImageMatched = !card.summaryImage;
            let questionAudioMatched = !card.questionAudio;
            let answerAudioMatched = !card.answerAudio;
            let explanationAudioMatched = !card.explanationAudio;
            let summaryAudioMatched = !card.summaryAudio;

            sortedMediaFileNames.forEach(rawMediaFileName => {
              const mediaFileName = extensionToLowerCase(rawMediaFileName);

              if (card.questionImage) {
                if (card.questionImage.includes(mediaFileName)) {
                  card.questionImage = mediaFilesMap[mediaFileName];
                  questionImageMatched = true;
                }
              }
              if (card.answerImage) {
                if (card.answerImage.includes(mediaFileName)) {
                  card.answerImage = mediaFilesMap[mediaFileName];
                  answerImageMatched = true;
                }
              }
              if (card.explanationImage) {
                if (card.explanationImage.includes(mediaFileName)) {
                  card.explanationImage = mediaFilesMap[mediaFileName];
                  explanationImageMatched = true;
                }
              }
              if (card.summaryImage) {
                if (card.summaryImage.includes(mediaFileName)) {
                  card.summaryImage = mediaFilesMap[mediaFileName];
                  summaryImageMatched = true;
                }
              }
              if (card.questionAudio) {
                if (card.questionAudio.includes(mediaFileName)) {
                  card.questionAudio = mediaFilesMap[mediaFileName];
                  questionAudioMatched = true;
                }
              }
              if (card.answerAudio) {
                if (card.answerAudio.includes(mediaFileName)) {
                  card.answerAudio = mediaFilesMap[mediaFileName];
                  answerAudioMatched = true;
                }
              }
              if (card.explanationAudio) {
                if (card.explanationAudio.includes(mediaFileName)) {
                  card.explanationAudio = mediaFilesMap[mediaFileName];
                  explanationAudioMatched = true;
                }
              }
              if (card.summaryAudio) {
                if (card.summaryAudio.includes(mediaFileName)) {
                  card.summaryAudio = mediaFilesMap[mediaFileName];
                  summaryAudioMatched = true;
                }
              }
            });

            if (!questionImageMatched) {
              delete card.questionImage;
            }
            if (!answerImageMatched) {
              delete card.answerImage;
            }
            if (!explanationImageMatched) {
              delete card.explanationImage;
            }
            if (!summaryImageMatched) {
              delete card.summaryImage;
            }
            if (!questionAudioMatched) {
              delete card.questionAudio;
            }
            if (!answerAudioMatched) {
              delete card.answerAudio;
            }
            if (!explanationAudioMatched) {
              delete card.explanationAudio;
            }
            if (!summaryAudioMatched) {
              delete card.summaryAudio;
            }

            mediaMatches.push({
              card: card.id,
              row: card.row as number,
              hasUnmatchedMedia:
                !questionImageMatched ||
                !answerImageMatched ||
                !explanationImageMatched ||
                !summaryImageMatched ||
                !questionAudioMatched ||
                !answerAudioMatched ||
                !explanationAudioMatched ||
                !summaryAudioMatched,
              questionImage: questionImageMatched,
              answerImage: answerImageMatched,
              explanationImage: explanationImageMatched,
              summaryImage: summaryImageMatched,
              questionAudio: questionAudioMatched,
              answerAudio: answerAudioMatched,
              explanationAudio: explanationAudioMatched,
              summaryAudio: summaryAudioMatched,
            });
          });
        }

        const missingMedia = mediaMatches.filter(
          card => card.hasUnmatchedMedia,
        );

        setRawCards(cardsData.filter(card => !!card.question && !!card.answer));

        setParseResults({
          success,
          missingAnswer,
          missingQuestion,
          missingMedia,
        });
        setView('confirmation');

        setLoading(false);
      },
    });
  };

  const handleMediaZipUploaded = async (url: string, id: string) => {
    setMediaFileStatus('processing');
    const result = await unzipMediaFile({ file: id });
    const withLowercaseExtensions = Object.keys(result.data.files).reduce(
      (fileMap, filename) => ({
        ...fileMap,
        [extensionToLowerCase(filename)]: result.data.files[filename],
      }),
      {},
    );
    setMediaFilesMap(withLowercaseExtensions);
    setMediaFileStatus('ready');
  };

  useEffect(() => {
    if (mediaFileStatus === 'ready' && view === 'media-upload-progress') {
      parseCsv();
    }
  }, [mediaFileStatus, view]);

  const { upload, progress } = useFileUpload(storage, {
    maxSize: 1000,
    onUploadSuccess: handleMediaZipUploaded,
  });

  const parseMetadata = () => {
    if (!csvFile) {
      return;
    }

    setLoading(true);

    Papa.parse(csvFile, {
      complete: function (results) {
        if (!columnDelimiter) {
          setColumnDelimiter(delimiterToSelectValue(results.meta.delimiter));
        }
        if (!rowDelimiter) {
          setRowDelimiter(delimiterToSelectValue(results.meta.linebreak));
        }
        const data = results.data as CsvRow[];
        let colCount = 0;
        data.forEach(row => {
          if (row.length > colCount) {
            colCount = row.length;
          }
        });

        if (data.length) {
          let hasHeaderRow = false;
          data[0].forEach(column => {
            if (
              column &&
              ['question', 'answer', 'front', 'back'].includes(
                column.toLowerCase(),
              )
            ) {
              hasHeaderRow = true;
            }
          });
          setHasHeaderRow(hasHeaderRow);
          const colMap = columnMap;
          if (hasHeaderRow) {
            setHeaderRow(data[0]);

            data[0].forEach((column, index) => {
              switch (column.toLowerCase()) {
                case 'question':
                case 'front':
                  colMap[index] = 'question';
                  break;
                case 'image':
                case 'picture':
                  colMap[index] = 'questionImage';
                  break;
                case 'audio':
                case 'sound':
                  colMap[index] = 'questionAudio';
                  break;
                case 'answer':
                case 'back':
                  colMap[index] = 'answer';
                  break;
                case 'explanation':
                case 'process':
                  colMap[index] = 'explanation';
                  break;
                case 'summary':
                case 'takeaways':
                  colMap[index] = 'summary';
                  break;
                case 'category':
                  colMap[index] = 'category';
                  break;
                case 'subcategory':
                case 'subcategories':
                  colMap[index] = 'subcategories';
                  break;
                default:
                  colMap[index] = 'unknown';
              }
            });
            setColumnMap(colMap);
          }
        }

        setColumnCount(colCount);
        setView('column-configuration');
        setLoading(false);
      },
    });
  };

  const nextStep = () => {
    if (view === 'file-selection' && csvFile) {
      parseMetadata();
      if (mediaFile) {
        setMediaFileStatus('uploading');
        upload(mediaFile);
      }
    } else if (view === 'column-configuration' && hasRequiredTypes) {
      if (mediaFileStatus === 'uploading' || mediaFileStatus === 'processing') {
        setView('media-upload-progress');
      } else {
        parseCsv();
      }
    }
  };

  const handleSetColumnType = (column: number, type: ColumnType) => {
    setColumnMap(map => ({
      ...map,
      [column]: type,
    }));
  };

  const handleClickBack = () => {
    switch (view) {
      case 'column-configuration':
        setView('file-selection');
        break;
      case 'confirmation':
        setView('column-configuration');
        break;
    }
  };

  const handleChangeHasHeaderRow = () => {
    setHasHeaderRow(value => !value);
  };

  const handleCreateCards = async () => {
    setView('creating-cards');
    const cardsWithMedia = await addMediaToCards(rawCards, db, storage);
    await createCards(cardsWithMedia);
    setView('done');
  };

  let step = 3;

  if (view === 'file-selection') {
    step = 1;
  } else if (view === 'column-configuration') {
    step = 2;
  }

  return (
    <Dialog
      disableBackdropClick
      maxWidth="xs"
      open={open}
      classes={{ paper: classes.paper }}
      {...other}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Import from CSV</Typography>
        <Typography className={classes.stepCounter}>Step {step}/3</Typography>
      </DialogTitle>
      <DialogContent>
        {view === 'file-selection' && (
          <FileSelection
            csvFile={csvFile}
            mediaFile={mediaFile}
            csvFileInputProps={{
              onChange: event => {
                if (event.target.files) {
                  setCsvFile(event.target.files[0]);
                  setColumnMap({
                    0: 'question',
                    1: 'answer',
                  });
                }
              },
            }}
            mediaFileInputProps={{
              onChange: event => {
                if (event.target.files) {
                  setMediaFile(event.target.files[0]);
                }
              },
            }}
          />
        )}
        {view === 'column-configuration' && (
          <Settings
            hasMedia={!!mediaFile}
            hasHeaderRow={hasHeaderRow}
            headerRow={headerRow}
            columnCount={columnCount}
            columnMap={columnMap}
            columnDelimiter={columnDelimiter}
            rowDelimiter={rowDelimiter}
            subcategoriesDelimiter={subcategoriesDelimiter}
            onSetColumnDelimiter={setColumnDelimiter}
            onSetRowDelimiter={setRowDelimiter}
            onSetSubcategoriesDelimiter={setSubcategoriesDelimiter}
            onSetColumnType={handleSetColumnType}
            customColumnDelimiter={customColumnDelimiter}
            customRowDelimiter={customRowDelimiter}
            onSetCustomColumnDelimiter={setCustomColumnDelimiter}
            onSetCustomRowDelimiter={setCustomRowDelimiter}
            onChangeHasHeaderRow={handleChangeHasHeaderRow}
          />
        )}
        {view === 'media-upload-progress' && mediaFile && (
          <MediaUploadProgress
            progress={progress}
            fileName={mediaFile.name}
            status={mediaFileStatus}
          />
        )}
        {view === 'parsing' && csvFile && <Parsing fileName={csvFile.name} />}
        {view === 'confirmation' && <Confirmation {...parseResults} />}
        {view === 'creating-cards' && <CreatingCards count={rawCards.length} />}
        {view === 'done' && <Done count={rawCards.length} />}
      </DialogContent>
      <DialogActions>
        {['confirmation', 'column-configuration'].includes(view) && (
          <Button onClick={handleClickBack}>Back</Button>
        )}
        <div className={classes.flex} />
        {!['done', 'creating-cards'].includes(view) && (
          <Button onClick={onClose}>Cancel</Button>
        )}
        {view === 'done' && <Button onClick={onClose}>Close</Button>}
        {view === 'file-selection' && (
          <Button color="primary" onClick={nextStep} disabled={!csvFile}>
            Next
          </Button>
        )}
        {view === 'column-configuration' && (
          <Button
            color="primary"
            onClick={nextStep}
            disabled={loading || !hasRequiredTypes}
          >
            Next
          </Button>
        )}
        {view === 'confirmation' && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleCreateCards}
            disabled={loading || !hasRequiredTypes}
          >
            Create cards
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
