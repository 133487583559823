import { toDate } from '@21st-night/utils';
import { StudyPlanTemplate, StudyPlanTemplateDocument } from '../../types';

export function deserializeStudyPlanTemplate(
  template: StudyPlanTemplateDocument,
): StudyPlanTemplate {
  return {
    ...template,
    createdAt: toDate(template.createdAt),
  };
}
