import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@21st-night/styles';

export interface DataItem {
  label: string;
  value: number;
  color: 'mastered' | 'needsWork' | 'inProgress' | 'notStarted' | 'retired';
}

export interface RatingsPieChartProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data: DataItem[];
  syncId?: string;
}

interface RenderDotProps {
  payload: DataItem;
  cx: number;
  cy: number;
}

export const RatingsPieChart: React.FC<RatingsPieChartProps> = ({
  data,
  ...other
}) => {
  const theme = useTheme();

  return (
    <div {...other}>
      <ResponsiveContainer height={80} width={80}>
        <PieChart data={data}>
          <Pie
            data={data}
            labelLine={false}
            outerRadius={40}
            nameKey="label"
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={theme.palette.proficiency[entry.color]}
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value: number) => `${value}%`}
            contentStyle={{ borderRadius: 3, padding: '4px 8px' }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
