import { validateDoc, DocValidator } from '@21st-night/utils';
import {
  StudyPlanTemplate,
  StudyPlanTemplateUpdateData,
} from '../../types/StudyPlanTemplate';

export const NewStudyPlanTemplateValidator: DocValidator<StudyPlanTemplate> = {
  id: 'string',
  createdAt: 'date',
  description: ['null', 'string'],
  name: ['empty-string', 'string'],
  length: 'number',
};

export const UpdateStudyPlanTemplateValidator: DocValidator<StudyPlanTemplateUpdateData> = {
  description: ['optional', 'string', 'null'],
  name: ['optional', 'string', 'empty-string'],
  length: ['optional', 'number'],
};

export function validateNewStudyPlanTemplate(doc: StudyPlanTemplate): boolean {
  return validateDoc(NewStudyPlanTemplateValidator, doc);
}

export function validateStudyPlanTemplateUpdate(
  doc: StudyPlanTemplateUpdateData,
): boolean {
  return validateDoc(UpdateStudyPlanTemplateValidator, doc);
}
