import React from 'react';
import { makeStyles } from '@21st-night/styles';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from '@21st-night/ui';

export interface OnboardingWelcomeDialogProps extends DialogProps {
  onClickNext: () => void;
}

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      backgroundColor: '#EAE8F5',
    },
  },
  image: {
    width: '50%',
    height: '50%',
    [theme.breakpoints.only('xs')]: {
      width: '60%',
      marginLeft: '20%',
      marginRight: '20%',
    },
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
}));

export const OnboardingWelcomeDialog: React.FC<OnboardingWelcomeDialogProps> = ({
  onClickNext,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Dialog {...other}>
      <div className={classes.root}>
        <img
          src={require('../images/headshot.jpg')}
          className={classes.image}
        />
        <div className={classes.content}>
          <DialogContent>
            <DialogContentText>
              <Typography color="textPrimary">
                Hey, hope you’re excited to start your new study plan! Before
                you jump into studying, let’s go through our pre-studying
                checklist. I want to show you everything 21st Night has to
                offer.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onClickNext}>
              Next
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};
