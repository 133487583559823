import { v4 as uuid } from 'uuid';
import { StudyPlanTaskTemplate, StudyPlanTask } from '../../types';
import { daysBetween } from '../daysBetween';

export function generateStudyPlanTaskFromTemplate(
  template: StudyPlanTaskTemplate,
  length: number,
  startDate: Date,
  endDate: Date,
): StudyPlanTask {
  const {
    action,
    count,
    object,
    source,
    days: daysOfTheWeek,
    subtasks,
  } = template;
  const days = daysBetween(startDate, endDate);
  const planLength = days.length;
  let startIndex = template.start - 1;
  let endIndex = template.end - 1;

  if (planLength < length) {
    startIndex = Math.floor((template.start / length) * (planLength - 1));
    endIndex = Math.floor((template.end / length) * (planLength - 1));
  }

  return {
    createdAt: new Date(),
    id: uuid(),
    startDate: days[startIndex],
    endDate: days[endIndex],
    action,
    count,
    object,
    source,
    subtasks,
    days: daysOfTheWeek,
  };
}
