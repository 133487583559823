import React, { useState } from 'react';
import { useFirebase, HttpsCallableResult } from '@21st-night/utils';
import { Card } from '@21st-night/cards-web';
import { Deck } from '@21st-night/deck';
import { DeckImportDialog } from '../DeckImportDialog';

type ImportCardsFromDeckFn = (data: {
  from: string;
  to: string;
  type: Card['type'];
}) => Promise<HttpsCallableResult>;

export interface DeckImportCardsDialogProps {
  type: Card['type'];
  open: boolean;
  onClose: () => void;
  decks: Deck[];
  to: string;
}

export const DeckImportCardsDialog: React.FC<DeckImportCardsDialogProps> = ({
  type,
  onClose,
  decks,
  to,
  ...other
}) => {
  const { functions } = useFirebase();
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    const importCards: ImportCardsFromDeckFn = functions.httpsCallable(
      'importCardsFromDeck',
    );

    await importCards({ from: selected, to, type });

    setLoading(false);
    onClose();
  }

  return (
    <DeckImportDialog
      variant={type}
      onClose={onClose}
      decks={decks}
      selected={selected}
      onChange={value => setSelected(value)}
      onSubmit={handleSubmit}
      loading={loading}
      {...other}
    />
  );
};
