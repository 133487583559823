import { postEventFromWebView } from '../postEventFromWebView';

import {
  WebViewEditorEventType,
  WebViewInsertImagePayload,
  WebViewOnChangePayload,
  WebViewOpenLinkPayload,
  WebViewEventPayload,
} from '../../types';

export function postWebViewEditorEvent(
  event: 'ON_CHANGE',
  payload: WebViewOnChangePayload,
): void;
export function postWebViewEditorEvent(
  event: 'OPEN_LINK',
  payload: WebViewOpenLinkPayload,
): void;
export function postWebViewEditorEvent(
  event: 'INSERT_IMAGE',
  payload: WebViewInsertImagePayload,
): void;
export function postWebViewEditorEvent(event: 'INSERT_AUDIO'): void;
export function postWebViewEditorEvent(
  event: WebViewEditorEventType,
  payload?: WebViewEventPayload,
): void {
  postEventFromWebView(event, payload);
}
