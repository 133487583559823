import { StudyPlanDay, StudyPlanItem } from '../../types';

export function addStudyPlanItemsToDays(
  days: StudyPlanDay[],
  items: StudyPlanItem[],
): StudyPlanDay[] {
  return days.reduce((daysWithItems, day) => {
    return [
      ...daysWithItems,
      {
        ...day,
        items: items
          .filter(item => item.parent === day.id)
          .map(item => item.id),
      },
    ] as StudyPlanDay[];
  }, [] as StudyPlanDay[]);
}
