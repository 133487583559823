import dayjs from 'dayjs';
import { wasYesterday } from '../wasYesterday';

export function isStreakIntact(lastStreakIncrement?: Date | null): boolean {
  return (
    !!lastStreakIncrement &&
    (wasYesterday(lastStreakIncrement) ||
      dayjs().isSame(lastStreakIncrement, 'date'))
  );
}
