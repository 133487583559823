import React from 'react';
import {
  DeckAddCardButton,
  DeckAddCardButtonProps,
} from '../DeckAddCardButton';

export type DeckAddFlashcardButtonProps = Omit<DeckAddCardButtonProps, 'type'>;

export const DeckAddFlashcardButton: React.FC<DeckAddFlashcardButtonProps> = props => (
  <DeckAddCardButton type="flashcard" {...props} />
);
