import { EditorDocument, generateRichTextDocument } from '@21st-night/editor';
import { useState } from 'react';

export interface CardEditorState {
  question: EditorDocument;
  answer: EditorDocument;
  explanation: EditorDocument;
  summary: EditorDocument;
  onQuestionChange: (value: EditorDocument) => void;
  onAnswerChange: (value: EditorDocument) => void;
  onExplanationChange: (value: EditorDocument) => void;
  onSummaryChange: (value: EditorDocument) => void;
}

type State = Pick<
  CardEditorState,
  'question' | 'answer' | 'explanation' | 'summary'
>;

export function useCardEditorState(
  initialState: Partial<State> = {},
): CardEditorState {
  const state: State = {
    question: initialState.question || generateRichTextDocument(),
    answer: initialState.question || generateRichTextDocument(),
    explanation: initialState.question || generateRichTextDocument(),
    summary: initialState.question || generateRichTextDocument(),
    ...initialState,
  };
  const [question, setQuestion] = useState(state.question);
  const [answer, setAnswer] = useState(state.answer);
  const [explanation, setExplanation] = useState(state.explanation);
  const [summary, setSummary] = useState(state.summary);

  return {
    question,
    answer,
    explanation,
    summary,
    onQuestionChange: setQuestion,
    onAnswerChange: setAnswer,
    onExplanationChange: setExplanation,
    onSummaryChange: setSummary,
  };
}
