import { toDate, DocumentSnapshot } from '@21st-night/utils';
import { deserializeDocument, isDocumentEmpty } from '@21st-night/editor';
import { StudyPlan, StudyPlanDocument } from '../../types';

export function deserializeStudyPlanDocument(doc: DocumentSnapshot): StudyPlan {
  const data = doc.data() as StudyPlanDocument;

  return {
    ...data,
    createdAt: toDate(data.createdAt),
    startDate: toDate(data.startDate),
    endDate: toDate(data.endDate),
    description:
      !!data.description &&
      !isDocumentEmpty(deserializeDocument(data.description))
        ? data.description
        : null,
  };
}
