import { DeckCard } from '../../Deck.types';
import dayjs from 'dayjs';

export function isCardDueNow(card: DeckCard): boolean {
  const { retired, nextReview, type } = card;

  // Retired cards are never due
  if (retired) {
    return false;
  }

  // New cards without a next review date are due now
  if (!nextReview) {
    return true;
  }

  // Flashcards are due down to the minute
  if (dayjs(nextReview).isBefore(new Date(), 'minute')) {
    return true;
  }

  // Error logs are due down to the day
  if (type === 'error-log') {
    return dayjs(nextReview).isSame(new Date(), 'day');
  }

  return false;
}
