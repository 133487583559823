import { EditorDocument } from '../../Editor.types';
import { generateEditorElement } from '../generateEditorElement';
import { generateEditorDocumentMetadata } from '../generateEditorDocumentMetadata';

export function generateRichTextDocument(
  content: string[] | string = '',
  generateMetadata = true,
): EditorDocument {
  const strings = typeof content === 'string' ? [content] : content;
  const document: EditorDocument = strings.map(text =>
    generateEditorElement('paragraph', text),
  );

  if (generateMetadata) {
    const metadata = generateEditorDocumentMetadata();
    document.unshift(metadata);
  }

  return document;
}
