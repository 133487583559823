import {
  WebViewCardEditorEventPayload,
  WebViewCardEditorEventType,
  WebViewOnChangeCardContentEventPayload,
} from '../../types';
import { postEventFromWebView } from '../postEventFromWebView';

export function postWebViewCardEditorEvent(event: 'OPEN_CATEGORY_SELECT'): void;
export function postWebViewCardEditorEvent(
  event: 'OPEN_SUBCATEGORIES_SELECT',
): void;
export function postWebViewCardEditorEvent(event: 'OPEN_NOTE_SELECT'): void;
export function postWebViewCardEditorEvent(
  event: 'ON_CHANGE_CONTENT',
  payload: WebViewOnChangeCardContentEventPayload,
): void;
export function postWebViewCardEditorEvent(
  event: WebViewCardEditorEventType,
  payload?: WebViewCardEditorEventPayload,
): void {
  postEventFromWebView(event, payload);
}
