import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  DeckFilterBarOption,
  DeckFilterBarOptionProps,
} from './DeckFilterBarOption';

export interface DeckFilterBarTag extends DeckFilterBarOptionProps {
  'data-tag-index'?: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
    },
  }),
);

export const DeckFilterBarTag: React.FC<DeckFilterBarOptionProps> = ({
  value,
  label,
  type,
  group,
  selected,
  onDelete,
  ...other
}) => {
  const classes = useStyles();

  return (
    <span className={classes.root} {...other}>
      <DeckFilterBarOption
        selected={selected}
        onDelete={onDelete}
        value={value}
        label={label}
        type={type}
        group={group}
      />
    </span>
  );
};
