import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeckCard } from '../updateDeckCard';

export function unstarCard(
  db: DB,
  deckRef: DocumentReference,
  cardId: string,
): Promise<void> {
  return updateDeckCard(deckRef, cardId, {
    starred: db.delete(),
  });
}
