import {
  BraindropEditorPluginFactory,
  BraindropEditorPlugin,
  BraindropEditor,
  Transforms,
  InsertOptions,
  Element,
  TurnIntoOptions,
  getBlockAbove,
} from '@braindrop-editor/core';
import { isElement } from '@21st-night/editor';
import { ElementVideo } from './ElementVideo';
import { EditorWithVideoPlugin } from './VideoPlugin.types';

const VideoPlugin = (): BraindropEditorPluginFactory => (
  baseEditor: BraindropEditor,
): BraindropEditorPlugin => {
  const editor = baseEditor as EditorWithVideoPlugin;
  const insertVideo = (editor: BraindropEditor, options?: InsertOptions) => {
    Transforms.insertNodes(editor, editor.generateElement('video'), options);
  };

  const turnIntoVideo = (
    editor: BraindropEditor,
    element: Element,
    options?: InsertOptions,
  ) => {
    Transforms.unsetNodes(editor, ['url'], options);
    Transforms.setNodes(
      editor,
      {
        type: 'video',
      },
      options,
    );
  };

  editor.insertVideo = (options?: InsertOptions) =>
    insertVideo(editor, options);
  editor.turnIntoVideo = (options?: TurnIntoOptions) => {
    const entry = getBlockAbove(editor);
    if (isElement(entry[0])) {
      turnIntoVideo(editor, entry[0], options);
    }
  };

  return {
    elements: [
      {
        insert: insertVideo,
        turnInto: turnIntoVideo,
        isVoid: true,
        component: ElementVideo,
        type: 'video',
        hotkeys: ['mod+alt+7'],
      },
    ],
  };
};

export default VideoPlugin;
