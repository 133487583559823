import React, { useCallback, useState } from 'react';
import { useFirebase } from '@21st-night/utils';
import { useTimeActive } from '@21st-night/utils-web';
import { createAnalyticsEvent } from '@21st-night/analytics-web';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import {
  Card,
  createCard,
  generateCard,
  getSelectionCardLinks,
  removeNoteLink,
} from '@21st-night/cards-web';
import { addCardLink, addContentCardLink } from '@21st-night/notes-web';
import { deserializeDocument } from '@21st-night/editor-web';
import {
  useDeckContent,
  DeckCardEditorData,
  generateCardCheatSheetItem,
  generateDeckCard,
  generateDeckCardReviewData,
  DeckContentCard,
} from '@21st-night/deck';
import type { DeckContentCreateCard } from '@21st-night/deck';
import { DeckCardEditorProvider } from '../hooks';
import { DeckCardEditor, DeckCardEditorProps } from '../DeckCardEditor';

export interface DeckItemCreateCardProps
  extends Omit<
    DeckCardEditorProps,
    'card' | 'submitButtonLabel' | 'onSubmit' | 'onClickCancel'
  > {
  type: Card['type'];
  data: DeckContentCreateCard;
  onCancel?: () => void;
  enableAnalytics?: boolean;
  enableGamification?: boolean;
}

export const DeckItemCreateCard: React.FC<DeckItemCreateCardProps> = ({
  type,
  data,
  onCancel,
  enableAnalytics,
  enableGamification,
  ...other
}) => {
  const { db, auth } = useFirebase();
  const deck = useDeckContent();
  const [category] = useState<DeckContentCard['category']>(
    data.category ||
      (deck.categoryFilters[0] ? deck.categoryFilters[0].value : null),
  );
  const [subcategories] = useState<DeckContentCard['subcategories']>(
    data.subcategories && data.subcategories.length
      ? data.subcategories
      : deck.subcategoryFilters.map(filter => filter.value),
  );
  const [time] = useTimeActive(30);

  function handleSubmit(editorData: DeckCardEditorData) {
    if (!auth.currentUser) {
      return;
    }

    const card = {
      ...generateCard({
        type,
        id: data.id,
        author: auth.currentUser.uid,
        deck: deck.id,
        note: editorData.note,
        question: editorData.question,
        answer: editorData.answer,
        explanation: editorData.explanation,
        summary: editorData.summary,
      }),
    };

    createCard(db, card);

    let deckCard = generateDeckCard(card, {
      category: editorData.category,
      subcategories: editorData.subcategories,
    });

    if (deck.type === 'student') {
      deckCard = { ...deckCard, ...generateDeckCardReviewData(type) };

      // First card should be due immedeatly for
      // onboarding purposes
      if (deck.cards.length === 0) {
        deckCard.nextReview = new Date();
      }
    }

    deck.addCardToDeck(deckCard);
    deck.removeCardEditor(deckCard.id);

    if (type === 'error-log' && card.summary) {
      deck.addCardToCheatSheet(
        generateCardCheatSheetItem({ id: card.id, summary: card.summary }),
      );
    }

    if (enableAnalytics) {
      // Add time spent creating card to deck analytics
      createAnalyticsEvent(deck.docRef, {
        type: 'create-card',
        card: card.id,
        time,
        createdAt: new Date(),
      });
    }

    if (enableGamification) {
      incrementStreak(db, auth.currentUser.uid);
      incrementStatistic(db, auth.currentUser.uid, 'cards-created');
    }

    if (data.note) {
      if (!data.noteSelection) {
        addCardLink(db, data.note, card.id);
      } else {
        const note = deck.notes.find(deckNote => deckNote.id === data.note);
        if (note) {
          const existingLinks = getSelectionCardLinks(
            deserializeDocument(note.content),
            data.noteSelection,
          );
          if (existingLinks.length) {
            existingLinks.forEach(([node]) => {
              removeNoteLink(db, node.cardId);
            });
          }
          addContentCardLink(db, note, data.noteSelection, card.id);
        }
      }
    }
  }

  const handleCancel = useCallback(() => {
    deck.removeCardEditor(data.id);
    if (onCancel) {
      onCancel();
    }
  }, []);

  return (
    <DeckCardEditorProvider
      notes={deck.notes}
      onSubmit={handleSubmit}
      data={{
        category,
        subcategories,
        note: data.note,
        noteSelection: data.noteSelection,
      }}
    >
      <DeckCardEditor
        type={type}
        submitButtonLabel="Create"
        onClickCancel={handleCancel}
        {...other}
      />
    </DeckCardEditorProvider>
  );
};
