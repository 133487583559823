import React from 'react';
import { SlashPluginFactory, SlashPlugin } from '@sheets-editor/core';
import { ElementCardLink } from './ElementCardLink';

interface CardLinkPluginOptions {
  emphasizeCard?: string;
  onClickCardLink?: (cardId: string) => void;
  onClickPreviewCard?: (cardId: string) => void;
  onClickStudyCard?: (cardId: string) => void;
  onRemoveCardLink?: (cardId: string) => void;
}
export const createCardLinkPlugin = ({
  emphasizeCard,
  onClickCardLink,
  onClickPreviewCard,
  onClickStudyCard,
  onRemoveCardLink,
}: CardLinkPluginOptions): SlashPluginFactory => (): SlashPlugin => {
  return {
    elements: [
      {
        isInline: true,
        component: props => (
          <ElementCardLink
            emphasize={
              !!emphasizeCard && props.element.cardId === emphasizeCard
            }
            onClick={onClickCardLink}
            onClickPreview={onClickPreviewCard}
            onClickStudy={onClickStudyCard}
            onRemoveCardLink={onRemoveCardLink}
            {...props}
          />
        ),
        type: 'card-link',
      },
    ],
  };
};
