import { DB } from '@21st-night/utils';
import { OfficialDeckUpdateData } from '../../Deck.types';

export function updateOfficialDeck(
  db: DB,
  id: string,
  data: OfficialDeckUpdateData,
): Promise<void> {
  return db.collection('official-decks').doc(id).update(data);
}
