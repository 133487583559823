import { Node, Element } from 'slate';
import { isElementOfType } from '../isElementOfType';
import { EditorDocument, EquationElement } from '../../Editor.types';

export function containsText(
  document?: EditorDocument,
  allowEmptyVoids = false,
): boolean {
  if (!document) {
    return false;
  }

  let hasText = false;

  try {
    document.forEach(element => {
      if (!hasText) {
        let string = Node.string(element);

        if (string && string.length) {
          string = string.replace(/\r?\n|\r/g, '').trim();
        }
        hasText = string.length !== 0;
      }
    });

    if (hasText) {
      return true;
    }

    let hasFilledTextVoid = false;

    document.forEach(element => {
      if (!hasFilledTextVoid) {
        switch (element.type) {
          case 'tex':
            hasFilledTextVoid = allowEmptyVoids || !!element.tex;
            break;
          default:
            ((element || { children: [] }) as Element).children.forEach(
              child => {
                if (isElementOfType(child, 'equation-inline')) {
                  hasFilledTextVoid =
                    allowEmptyVoids || !!(child as EquationElement).tex;
                }
              },
            );
        }
      }
    });

    return hasFilledTextVoid;
  } catch (err) {
    return true;
  }
}
