import React from 'react';
import { cn, makeStyles } from '@21st-night/styles';
import { useFirebase } from '@21st-night/utils';
import { deserializeDocument, RichText } from '@21st-night/editor-web';
import {
  Paper,
  PdfViewer,
  LightboxImage,
  Spaced,
  Typography,
} from '@21st-night/ui';
import { DeckContentNote } from '@21st-night/deck';
import { DeckCategoryTag } from '../DeckCategoryTag';
import { DeckSubcategoryTag } from '../DeckSubcategoryTag';
import { DeckLinkedCardsTag } from '../DeckLinkedCardsTag';

export interface DeckItemNotePreviewProps
  extends React.HTMLAttributes<HTMLDivElement> {
  note: DeckContentNote;
  autoFocus?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    userSelect: 'none',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#90A4AE',
    height: 34,
    lineHeight: '34px',
  },
  content: {
    maxHeight: 500,
    overflowY: 'scroll',
  },
  metaToolbar: {
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
}));

export const DeckItemNotePreview: React.FC<DeckItemNotePreviewProps> = ({
  note,
  className,
  ...other
}) => {
  const classes = useStyles();
  const { imageUrl } = useFirebase();

  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <Typography variant="subtitle1" className={classes.label}>
        Note
      </Typography>
      {note.type === 'note' && (
        <div className={classes.content}>
          <RichText
            imageUrl={imageUrl}
            document={deserializeDocument(note.content)}
          />
        </div>
      )}
      {note.type === 'pdf' && note.file && <PdfViewer file={note.file} />}
      {note.type === 'image' && note.file && (
        <LightboxImage
          maxHeight={500}
          alt={note.title}
          src={note.file}
          expandedSrc={note.file}
        />
      )}
      <Spaced className={classes.metaToolbar}>
        {note.category && <DeckCategoryTag category={note.category} />}
        {note.subcategories.map(subcategory => (
          <DeckSubcategoryTag key={subcategory} subcategory={subcategory} />
        ))}
        {note.cards.length > 0 && (
          <DeckLinkedCardsTag value={note.id} count={note.cards.length} />
        )}
      </Spaced>
    </Paper>
  );
};
