import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Tex: React.FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M0.33427 9.475L0.60727 6.303V6.225H9.38227V6.303C9.56427 8.409 9.65527 9.46633 9.65527 9.475V9.553H8.84927V9.475C8.7626 8.591 8.62827 8.00167 8.44627 7.707C8.27294 7.421 7.9696 7.22167 7.53627 7.109C7.38894 7.06567 7.0726 7.03967 6.58727 7.031H6.01527V14.194H8.00427V15H7.79627C7.58827 14.974 6.65227 14.961 4.98827 14.961C3.33294 14.961 2.40127 14.974 2.19327 15H1.98527V14.194H3.97427V7.031H3.40227C2.91694 7.03967 2.6006 7.06567 2.45327 7.109C2.01994 7.22167 1.7166 7.421 1.54327 7.707C1.36127 8.00167 1.22694 8.591 1.14027 9.475V9.553H0.33427V9.475Z"
        fill="inherit"
      />
      <path
        d="M11.2088 13.008C11.7375 12.9907 12.0972 12.8997 12.2878 12.735C12.4785 12.5703 12.5825 12.2367 12.5998 11.734V11.487H13.4058V15.335H12.5998V15.088C12.5825 14.7327 12.5262 14.464 12.4308 14.282C12.3442 14.1 12.1795 13.97 11.9368 13.892C11.7895 13.8487 11.5468 13.8227 11.2088 13.814H10.7928V17.194H11.7158C12.5738 17.194 13.2065 17.155 13.6138 17.077C14.0298 16.9903 14.3852 16.7823 14.6798 16.453C14.9485 16.141 15.1565 15.6513 15.3038 14.984C15.3905 14.5073 15.4338 14.2603 15.4338 14.243H16.2398V14.282C16.2225 14.2993 16.1185 14.9147 15.9278 16.128C15.7458 17.3327 15.6505 17.9437 15.6418 17.961V18H7.34782V17.194H8.75182V9.966H7.34782V9.16H15.4208V9.212C15.4382 9.264 15.5032 9.80133 15.6158 10.824C15.7285 11.8467 15.7978 12.384 15.8238 12.436V12.488H15.0178V12.436C15.0092 12.4187 14.9962 12.3493 14.9788 12.228C14.8922 11.5347 14.7145 11.0147 14.4458 10.668C14.1858 10.3127 13.7612 10.096 13.1718 10.018C12.9292 9.98333 12.4395 9.966 11.7028 9.966H10.7928V13.008H11.2088Z"
        fill="inherit"
      />
      <path
        d="M17.7715 15C17.5722 14.974 16.9092 14.961 15.7825 14.961C14.7772 14.961 14.2095 14.974 14.0795 15H13.9495V14.194H14.7945L15.6265 14.181L16.9135 12.439L18.2005 10.671C17.8018 10.099 17.3598 9.46633 16.8745 8.773L15.5615 6.888H14.1445V6.082H14.2875C14.4435 6.108 15.1282 6.121 16.3415 6.121C17.7282 6.121 18.4995 6.108 18.6555 6.082H18.8245V6.888H17.8755L18.6295 7.98L19.3965 9.046L20.9695 6.901C20.9695 6.89233 20.7745 6.888 20.3845 6.888H19.7995V6.082H19.9425C20.0725 6.108 20.7312 6.121 21.9185 6.121C22.9238 6.121 23.4958 6.108 23.6345 6.082H23.7515V6.888H22.0745L20.9955 8.357C20.2848 9.33633 19.9295 9.83033 19.9295 9.839L20.1245 10.112C20.2458 10.2853 20.4408 10.567 20.7095 10.957C20.9782 11.3383 21.2295 11.6937 21.4635 12.023L22.9715 14.194H24.3755V15H24.2325C24.0765 14.974 23.3962 14.961 22.1915 14.961C20.8048 14.961 20.0335 14.974 19.8775 15H19.7085V14.194H20.1895L20.6575 14.181C20.0508 13.323 19.4095 12.4087 18.7335 11.438L17.7325 12.803L16.7315 14.181C16.7315 14.1897 16.9265 14.194 17.3165 14.194H17.9145V15H17.7715Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
