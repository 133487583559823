import React from 'react';
import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core';

export type LinearProgressProps = MuiLinearProgressProps;

export const LinearProgress: React.FC<LinearProgressProps> = ({ ...other }) => {
  return <MuiLinearProgress {...other} />;
};
