import React from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core';

export type FormControlLabelProps = MuiFormControlLabelProps;

export const FormControlLabel: React.FC<FormControlLabelProps> = ({
  ...other
}) => {
  return <MuiFormControlLabel {...other} />;
};
