import { DB } from '@21st-night/utils';
import { StudyPlanTask } from '../../types';

export function createStudyPlanTask(
  db: DB,
  userId: string,
  task: StudyPlanTask,
): Promise<void> {
  return db
    .collection('users')
    .doc(userId)
    .collection('study-plan')
    .doc('plan')
    .collection('tasks')
    .doc(task.id)
    .set(task);
}
