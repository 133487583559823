import { Timestamp, FieldValue } from '@21st-night/utils';
import { Element } from 'slate';

export const CARD_TYPES = ['flashcard', 'error-log'] as const;
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export type CardType = ElementType<typeof CARD_TYPES>;

export interface Card {
  id: string;
  createdAt: Date;
  answer: string;
  author: string;
  deck: string;
  explanation: string | null;
  owner: string;
  question: string;
  summary: string | null;
  type: CardType;
  note?: string;
}

export interface Flashcard extends Card {
  type: 'flashcard';
}

export interface ErrorLog extends Card {
  type: 'error-log';
}

export interface CardDocument extends Omit<Card, 'createdAt'> {
  createdAt: Timestamp;
}

export interface FlashcardDocument extends Omit<Flashcard, 'createdAt'> {
  createdAt: Timestamp;
}

export interface ErrorLogDocument extends Omit<ErrorLog, 'createdAt'> {
  createdAt: Timestamp;
}

export interface CardUpdateData {
  question?: string;
  answer?: string;
  explanation?: string | null;
  summary?: string | null;
  note?: string | FieldValue;
}

export interface CardLinkElement extends Element {
  cardId: string;
}
