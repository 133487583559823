import * as React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';
import { makeStyles } from '@21st-night/styles';
import { OnboardingQuestionAnswerOption } from '@21st-night/onboarding';

export interface OnboardingQuestionAnswerButtonProps
  extends HTMLMotionProps<'button'> {
  option: OnboardingQuestionAnswerOption;
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '24px',
    backgroundColor: '#ECEFF1',
    padding: '8px 16px',
    borderRadius: 10,
    cursor: 'pointer',
    margin: 8,
    display: 'inline-block',
    border: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.only('xs')]: {
      fontSize: '18px',
    },
  },
}));

export const OnboardingQuestionAnswerButton: React.FC<OnboardingQuestionAnswerButtonProps> = ({
  option,
  ...other
}) => {
  const classes = useStyles();

  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      className={classes.root}
      {...other}
    >
      {option.label}
    </motion.button>
  );
};
