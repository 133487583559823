import { DeckCardCheatSheetItem } from '../../Deck.types';

interface CardCheatSheetData {
  summary: string;
  id: string;
}

export function generateCardCheatSheetItem(
  card: CardCheatSheetData,
): DeckCardCheatSheetItem {
  return {
    card: card.id,
    content: card.summary,
    createdAt: new Date(),
  };
}
