import { ReactEditor } from 'slate-react';
import { BraindropEditor, Transforms } from '@braindrop-editor/core';
import { calculateAspectRatio } from '@21st-night/editor';
import { parseNativeEditorEvent } from '@21st-night/web-view';

export function handleNativeEditorMessage(
  editor: BraindropEditor,
  message: string,
): void {
  const event = parseNativeEditorEvent(message);

  if (event.type !== 'INSERT_IMAGE') {
    return;
  }

  ReactEditor.focus(editor);

  const { width, height, ...rest } = event.payload;

  setTimeout(() => {
    Transforms.insertNodes(
      editor,
      editor.generateElement('image', {
        ...rest,
        dimensions: {
          width,
          height,
          aspectRatio: calculateAspectRatio({ width, height }),
        },
      }),
    );
  });
}
