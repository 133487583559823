import { v4 as uuid } from 'uuid';
import { StudyPlanItem } from '../../types';

export function generateStudyPlanSubItem(
  parent: StudyPlanItem,
  content: string,
): StudyPlanItem {
  return {
    id: uuid(),
    title: content,
    createdAt: new Date(),
    parentType: 'item',
    parent: parent.id,
    done: false,
    donePreviously: false,
    date: parent.date,
    subItems: [],
  };
}
