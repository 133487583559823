interface Dimensions {
  width: number;
  height: number;
}

interface CropDimensions {
  width?: number | undefined;
  height?: number | undefined;
}

/**
 * Calculates the aspect ratio of an image
 * accounting for potential crop.
 *
 * @param dimensions The dimensions of the image.
 * @param crop       The crop dimensions of the image.
 * @param percentage If `true`, the aspect ratio will be returned as a percentage value.
 */
export function calculateAspectRatio(
  dimensions: Dimensions,
  crop: CropDimensions = { width: 100, height: 100 },
  percentage = false,
): number {
  const { width, height } = dimensions;
  const { width: cropWidth, height: cropHeight } = crop;

  if (typeof width !== 'number' || typeof height !== 'number') {
    return percentage ? 100 : 1;
  }

  const ratio =
    (width * (cropWidth || 100 / 100)) / (height * (cropHeight || 100 / 100)) ||
    1;

  return percentage ? 100 / ratio : 1;
}
