import React from 'react';
import {
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@21st-night/styles';
import {
  Grid,
  Hidden,
  Tooltip,
  Paper,
  Button,
  Spaced,
  Divider,
  Typography,
  IconButton,
} from '@21st-night/ui';
import { Edit, Star } from '@21st-night/icons';
import { EditorDocument, RichText } from '@21st-night/editor-web';
import { ReviewModeSelect } from '../ReviewModeSelect';
import { ReviewRatingButton } from '../ReviewRatingButton';
import { ReviewAnswerField } from '../ReviewAnswerField';
import {
  ReviewMode,
  ReviewRating,
  ReviewView,
  WrittenAnswerState,
} from '@21st-night/review';

export interface ReviewLayoutProps
  extends React.HTMLAttributes<HTMLDivElement> {
  cardType: 'flashcard' | 'error-log';
  view: ReviewView;
  onChangeView: (view: ReviewView) => void;
  onRate: (rating: ReviewRating) => void;
  reviewMode: ReviewMode;
  onChangeReviewMode: (mode: ReviewMode) => void;
  writtenAnswer: string;
  onChangeWrittenAnswer: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickStar: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSkip: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickBack: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickCheckAnswer: (event: React.MouseEvent<HTMLButtonElement>) => void;
  writtenAnswerState: WrittenAnswerState;
  question: EditorDocument;
  answer: EditorDocument;
  explanation: EditorDocument | null;
  summary: EditorDocument | null;
  note?: EditorDocument;
  starred?: boolean;
  imageUrl: string;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    topToolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    top: {
      paddingTop: theme.spacing(2),
    },
    middle: {
      flex: 1,
      position: 'relative',
      overflow: 'hidden',
      maxHeight: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    bottom: {
      backgroundColor: '#EEEEEE',
      padding: theme.spacing(2, 0),
    },
    left: {
      marginLeft: theme.spacing(2),
    },
    center: {
      maxWidth: 760,
      margin: '0 auto',
      width: '100%',
    },
    right: {
      marginRight: theme.spacing(2),
    },
    contentContainer: {
      display: 'block',
      position: 'relative',
      maxHeight: '100%',
    },
    contentWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      maxHeight: 'calc(100% - 24px)',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    content: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(4, 2),
      overflow: 'scroll',
    },
    quizInput: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    contentButtons: {
      marginTop: theme.spacing(3),
    },
    contentDivider: {
      margin: theme.spacing(2, 0),
      width: '100%',
    },
    ratingCaption: {
      display: 'block',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    secondaryActionButton: {
      width: 112,
      color: theme.palette.text.secondary,
    },
    bottomLeftActionButton: {
      marginTop: 40,
      marginLeft: theme.spacing(2),
    },
    bottomRightIconButtons: {
      marginRight: theme.spacing(2),
      minHeight: 42,
    },
    iconButton: {
      color: theme.palette.primary.main,
    },
    retireButton: {
      color: theme.palette.text.secondary,
    },
    correct: {
      color: '#166A34',
      marginBottom: theme.spacing(2),
    },
    incorrect: {
      color: '#8D282A',
      marginBottom: theme.spacing(2),
    },
  }),
);

export const ReviewLayout: React.FC<ReviewLayoutProps> = ({
  view,
  onChangeView,
  reviewMode,
  onChangeReviewMode,
  onClickStar,
  onClickEdit,
  onClickSkip,
  onClickBack,
  onClickCheckAnswer,
  question,
  answer,
  explanation,
  summary,
  note,
  imageUrl,
  cardType,
  onRate,
  starred,
  writtenAnswer,
  onChangeWrittenAnswer,
  writtenAnswerState,
  ...other
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const questionView = view === 'question' || view === 'question-summary';
  const answerView = view === 'answer' || view === 'answer-explanation';
  const ratingView =
    ((answerView || view === 'note') && reviewMode !== 'answer-first') ||
    (questionView && reviewMode === 'answer-first');
  const summaryLabel = cardType === 'error-log' ? 'Summary' : 'Mnemonic';
  const explanationButtonLabel =
    cardType === 'error-log' ? 'Process' : 'Context';
  const explanationLabel =
    cardType === 'error-log' ? 'Process for answering the question' : 'Context';

  const handleClickInitialShowButton = () => {
    if (reviewMode === 'answer-first') {
      onChangeView('question');
    } else {
      onChangeView('answer');
    }
  };

  const handleClickRatingButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rating: ReviewRating,
  ) => {
    onRate(rating);
  };

  return (
    <div className={classes.root} {...other}>
      <Grid container spacing={0} className={classes.top}>
        <Hidden smDown>
          <Grid item md={3} lg>
            <ReviewModeSelect
              value={reviewMode}
              onChange={onChangeReviewMode}
              className={classes.left}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={7} lg={6}>
          <div className={classes.topToolbar}>
            <Spaced>
              <Hidden mdUp>
                <ReviewModeSelect
                  value={reviewMode}
                  onChange={onChangeReviewMode}
                  className={classes.left}
                />
              </Hidden>
            </Spaced>
            <Spaced>
              {note && answerView && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => onChangeView('note')}
                >
                  Show Note
                </Button>
              )}
              {view === 'note' && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => onChangeView('answer')}
                >
                  Show Answer
                </Button>
              )}
            </Spaced>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item md lg />
        </Hidden>
      </Grid>
      <Grid container spacing={0} className={classes.middle}>
        <Hidden smDown>
          <Grid item md lg />
        </Hidden>
        <Grid item xs={12} md={8} lg={6} className={classes.contentContainer}>
          <div className={classes.contentWrapper}>
            {writtenAnswerState === 'correct' && (
              <Typography variant="h5" className={classes.correct}>
                Correct!
              </Typography>
            )}
            {writtenAnswerState === 'incorrect' && (
              <Typography variant="h5" className={classes.incorrect}>
                Incorrect.
              </Typography>
            )}
            <Paper className={classes.content}>
              {questionView && (
                <RichText imageUrl={imageUrl} document={question} />
              )}
              {view === 'question' && summary && (
                <Spaced centered className={classes.contentButtons}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onChangeView('question-summary')}
                  >
                    Show {summaryLabel}
                  </Button>
                </Spaced>
              )}

              {view === 'question-summary' && summary && (
                <>
                  <Divider className={classes.contentDivider} />
                  <Typography gutterBottom variant="subtitle2">
                    {summaryLabel}
                  </Typography>
                  <RichText
                    imageUrl={imageUrl}
                    document={summary}
                    color={theme.palette.text.secondary}
                  />
                </>
              )}

              {answerView && <RichText imageUrl={imageUrl} document={answer} />}
              {view === 'answer' && explanation && (
                <Spaced centered className={classes.contentButtons}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onChangeView('answer-explanation')}
                  >
                    Show {explanationButtonLabel}
                  </Button>
                </Spaced>
              )}

              {view === 'answer-explanation' && explanation && (
                <>
                  <Divider className={classes.contentDivider} />
                  <Typography gutterBottom variant="subtitle2">
                    {explanationLabel}
                  </Typography>
                  <RichText
                    imageUrl={imageUrl}
                    document={explanation}
                    color={theme.palette.text.secondary}
                  />
                </>
              )}

              {view === 'note' && note && (
                <RichText imageUrl={imageUrl} document={note} />
              )}
            </Paper>

            {reviewMode === 'quiz' && questionView && (
              <ReviewAnswerField
                className={classes.quizInput}
                value={writtenAnswer}
                onChange={onChangeWrittenAnswer}
              />
            )}
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item md lg />
        </Hidden>
      </Grid>
      <Grid container spacing={0} className={classes.bottom}>
        <Hidden smDown>
          <Grid item md={2} lg={3}>
            {(answerView || view === 'note') && reviewMode !== 'answer-first' && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => onChangeView('question')}
                className={classes.bottomLeftActionButton}
              >
                Show Question
              </Button>
            )}
            {questionView && reviewMode === 'answer-first' && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => onChangeView('answer')}
                className={classes.bottomLeftActionButton}
              >
                Show Answer
              </Button>
            )}
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} lg={6}>
          {!ratingView && (
            <Spaced centered spacing={reviewMode === 'quiz' ? 3 : 8}>
              <Button
                color="inherit"
                className={classes.secondaryActionButton}
                onClick={onClickBack}
              >
                Back to Deck
              </Button>
              {reviewMode === 'quiz' && (
                <Button
                  color="primary"
                  variant="contained"
                  size={isMobile ? 'medium' : 'large'}
                  onClick={onClickCheckAnswer}
                >
                  Check Answer
                </Button>
              )}
              <Button
                color="primary"
                variant={reviewMode === 'quiz' ? 'outlined' : 'contained'}
                size={isMobile ? 'medium' : 'large'}
                onClick={handleClickInitialShowButton}
              >
                Show {reviewMode === 'answer-first' ? 'Question' : 'Answer'}
              </Button>
              <Button
                color="inherit"
                className={classes.secondaryActionButton}
                onClick={onClickSkip}
              >
                Skip Card
              </Button>
            </Spaced>
          )}
          {ratingView && (
            <>
              <Typography variant="caption" className={classes.ratingCaption}>
                {reviewMode !== 'answer-first' &&
                  'How well did you understand the answer?'}
                {reviewMode === 'answer-first' &&
                  'How well did you remember the question?'}
              </Typography>
              <Spaced centered spacing={2}>
                <ReviewRatingButton
                  rating="wrong"
                  onClick={handleClickRatingButton}
                />
                <ReviewRatingButton
                  rating="more-work"
                  onClick={handleClickRatingButton}
                />
                <ReviewRatingButton
                  rating="correct"
                  onClick={handleClickRatingButton}
                />
              </Spaced>
            </>
          )}
        </Grid>
        <Hidden smDown>
          <Grid item md={2} lg={3}>
            <Spaced align="end" className={classes.bottomRightIconButtons}>
              <Tooltip placement="top" title="Edit card">
                <IconButton toggle onClick={onClickEdit}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="top"
                title={starred ? 'Unstar card' : 'Star card'}
              >
                <IconButton
                  toggle
                  activeColor="#FFAB00"
                  active={starred}
                  onClick={onClickStar}
                >
                  <Star />
                </IconButton>
              </Tooltip>
            </Spaced>
            {ratingView && (
              <Spaced align="end" className={classes.right}>
                <Button
                  onClick={() => onRate('retire')}
                  className={classes.retireButton}
                >
                  Retire Card
                </Button>
              </Spaced>
            )}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};
