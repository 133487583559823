import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Squared: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <rect
      x="8"
      y="13"
      width="18"
      height="23"
      rx="2"
      stroke="black"
      strokeWidth="3"
      fill="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42 18V14.8981H37.0752C40.4257 12.4993 41.8991 10.7001 41.8991 8.59084C41.8991 5.8198 39.6184 4 36.4495 4C34.5725 4 32.433 4.68242 31 6.29542L32.9174 8.65288C33.8862 7.7223 35.0165 7.14328 36.5101 7.14328C37.4991 7.14328 38.367 7.63959 38.367 8.59084C38.367 9.95569 37.2771 10.9069 31.444 15.229V18H42Z"
      fill="black"
    />
  </SvgIcon>
);
