import React from 'react';
import { Button, ButtonProps } from '@21st-night/ui';

export type SignupButtonProps = Omit<ButtonProps, 'variant' | 'color'>;

export const SignupButton: React.FC<SignupButtonProps> = ({ ...other }) => {
  return (
    <Button fullWidth variant="outlined" {...other}>
      Sign up
    </Button>
  );
};
