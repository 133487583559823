import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Typography } from '@21st-night/ui';

export interface StatisticProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: React.ReactNode;
  value: React.ReactNode;
  label?: React.ReactNode;
  mini?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    titleMini: {
      fontSize: '14px',
    },
    contentMini: {
      display: 'flex',
      alignItems: 'center',
    },
    value: {
      color: theme.palette.primary.main,
    },
    valueMini: {
      fontSize: '16px',
    },
    label: {
      color: theme.palette.grey[500],
      fontWeight: 400,
      minHeight: 22,
      fontSize: '14px',
    },
    labelMini: {
      minHeight: 12,
      fontSize: '12px',
      marginLeft: 6,
    },
  }),
);

export const Statistic: React.FC<StatisticProps> = ({
  className,
  title,
  value,
  label,
  mini,
  ...other
}) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.root, className)} {...other}>
      <Typography variant="subtitle1" className={cn(mini && classes.titleMini)}>
        {title}
      </Typography>
      <div className={cn(mini && classes.contentMini)}>
        <Typography
          variant="h5"
          className={cn(classes.value, mini && classes.valueMini)}
        >
          {value}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          className={cn(classes.label, mini && classes.labelMini)}
        >
          {label}
        </Typography>
      </div>
    </div>
  );
};
