import React from 'react';
import { Google } from '@21st-night/icons';
import { LoginButton } from '../LoginButton';

export type GoogleButtonProps = React.HTMLAttributes<HTMLButtonElement>;

export const GoogleButton: React.FC<GoogleButtonProps> = props => {
  return (
    <LoginButton {...props} backgroundColor="#db3236" icon={<Google />}>
      Sign in with Google
    </LoginButton>
  );
};
