import React, { useState } from 'react';
import { createContext } from '@21st-night/utils-web';

export interface MobileEditorToolbarContext {
  showEditorToolbar: () => void;
  hideEditorToolbar: () => void;
  setEditorToolbarShow: (value: boolean) => void;
  editorToolbarShown: boolean;
}

const [hook, Provider] = createContext<MobileEditorToolbarContext>();

export const MobileEditorToolbarProvider: React.FC = ({ children }) => {
  const [isShown, setIsShown] = useState(false);

  function showEditorToolbar() {
    setIsShown(true);
  }

  function hideEditorToolbar() {
    setIsShown(false);
  }

  return (
    <Provider
      value={{
        editorToolbarShown: isShown,
        showEditorToolbar,
        hideEditorToolbar,
        setEditorToolbarShow: setIsShown,
      }}
    >
      {children}
    </Provider>
  );
};

export const useMobileEditorToolbar = hook;
