import React, { useEffect } from 'react';
import filesize from 'filesize';
import { makeStyles, cn } from '@21st-night/styles';
import { useFirebase } from '@21st-night/utils';
import { useFileUpload } from '@21st-night/utils-web';
import { AttachFile } from '@21st-night/icons';
import { LoadingIndicator } from '@21st-night/ui';

export interface DeckItemNoteFileUploadProps
  extends React.HTMLAttributes<HTMLDivElement> {
  file: File;
  noteId: string;
  onUploadSuccess: (
    downloadURL: string,
    fileId: string,
    file: File,
    noteId: string,
  ) => void | Promise<void>;
}

const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 12px 12px 12px',
    color: 'rgba(55, 53, 47, 0.6)',
    backgroundColor: '#EDECE9',
    fontSize: '14px',
    borderRadius: 3,
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
    fill: 'rgba(55, 53, 47, 0.4)',
    color: 'rgba(55, 53, 47, 0.4)',
    marginRight: 8,
    height: 28,
    width: 28,
  },
  label: {
    flex: 1,
  },
  uploadProgress: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
}));

export const DeckItemNoteFileUpload: React.FC<DeckItemNoteFileUploadProps> = ({
  className,
  file,
  noteId,
  onUploadSuccess,
  ...other
}) => {
  const classes = useStyles();
  const { storage } = useFirebase();
  const { progress, upload } = useFileUpload(storage, {
    onUploadSuccess: (url, fileId, file) =>
      onUploadSuccess(url, fileId, file, noteId),
  });

  useEffect(() => {
    upload(file);
  }, [file]);

  return (
    <div {...other}>
      <div
        contentEditable={false}
        role="button"
        tabIndex={0}
        className={cn(classes.root, className)}
        {...other}
      >
        <span className={classes.icon}>
          <AttachFile color="inherit" />
        </span>
        <div className={classes.label}>
          <div>{file.name}</div>
          <div className={classes.uploadProgress}>
            {filesize(file.size)} — <LoadingIndicator size={14} />{' '}
            <span style={{ marginLeft: 4 }}>{progress}%</span>
          </div>
        </div>
      </div>
    </div>
  );
};
