import React from 'react';
import { useSlate } from 'slate-react';
import { InlineEditor } from './InlineEditor';
import {
  Toolbar,
  ToolbarInsertInlineEquationButton,
  ToolbarRichTextFormattingButtons,
} from './Toolbar';

export const InlineEditorToolbar: React.FC = () => {
  const editor = useSlate() as InlineEditor;

  return (
    <Toolbar>
      <ToolbarRichTextFormattingButtons editor={editor} />
      <ToolbarInsertInlineEquationButton editor={editor} />
    </Toolbar>
  );
};
