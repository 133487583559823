import { Node } from 'slate';
import { Transforms } from '@braindrop-editor/core';
import {
  Editor,
  isElement,
  DEFAULT_ELEMENT_TYPE,
} from '@21st-night/editor-web';

function generateEmptySection(editor: Editor, type: string) {
  return editor.generateElement(type, {
    children: [editor.generateElement(DEFAULT_ELEMENT_TYPE)],
  });
}

export function addCardFormNormalizer(editor: Editor): void {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        Transforms.insertNodes(
          editor,
          generateEmptySection(editor, 'card-question'),
          { at: path.concat(0) },
        );
      }

      if (editor.children.length < 2) {
        Transforms.insertNodes(
          editor,
          generateEmptySection(editor, 'card-answer'),
          { at: path.concat(1) },
        );
      }

      if (editor.children.length < 3) {
        Transforms.insertNodes(
          editor,
          generateEmptySection(editor, 'card-explanation'),
          { at: path.concat(2) },
        );
      }

      if (editor.children.length < 4) {
        Transforms.insertNodes(
          editor,
          generateEmptySection(editor, 'card-summary'),
          { at: path.concat(3) },
        );
      }

      if (editor.children.length > 4) {
        Transforms.removeNodes(editor, { at: path.concat(4) });
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [child, childPath] of Node.children(editor, path)) {
        let type;
        switch (childPath[0]) {
          case 0:
            type = 'card-question';
            break;
          case 1:
            type = 'card-answer';
            break;
          case 2:
            type = 'card-explanation';
            break;
          case 3:
          default:
            type = 'card-summary';
            break;
        }

        if (isElement(child) && child.type !== type) {
          Transforms.setNodes(editor, { type }, { at: childPath });
        }
      }
    }

    // Ensure that sections do not end in a void node
    if (path.length === 1 && isElement(node)) {
      const lastChild = node.children.slice(-1)[0];
      if (isElement(lastChild) && editor.isVoid(lastChild)) {
        Transforms.insertNodes(
          editor,
          editor.generateElement(DEFAULT_ELEMENT_TYPE),
          { at: path.concat(node.children.length) },
        );
      }
    }

    return normalizeNode([node, path]);
  };
}
