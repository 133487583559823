import React from 'react';
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import { TextInput } from '../TextInput';

export type SelectProps = MuiSelectProps;

export const Select: React.FC<SelectProps> = ({ ...other }) => {
  return <MuiSelect input={<TextInput />} {...other} />;
};
