import React from 'react';
import { RenderElementProps, Element } from '@sheets-editor/core';
import { Typography } from '@21st-night/ui';
import { makeStyles } from '@21st-night/styles';

export interface ReadOnlyAnswerElement extends Element {
  content: string;
}

interface RenderAnswerElementProps extends Omit<RenderElementProps, 'element'> {
  element: ReadOnlyAnswerElement;
}

const useStyles = makeStyles(theme => ({
  heading: {
    color: '#616161',
    marginTop: theme.spacing(2),
    userSelect: 'none',
    fontWeight: 'bold',
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export const ElementAnswerReadOnly: React.FC<RenderAnswerElementProps> = ({
  attributes,
  children,
  element,
}) => {
  const classes = useStyles();

  return (
    <div {...attributes}>
      <Typography
        variant="subtitle1"
        contentEditable={false}
        className={classes.heading}
      >
        Answer
      </Typography>
      <div className={classes.content}>
        <span contentEditable={false}>{element.content}</span>
        {children}
      </div>
    </div>
  );
};
