import React, { useRef, useState } from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import {
  Autocomplete,
  InputBase,
  Paper,
  PaperProps,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@21st-night/ui';
import { MoreHoriz } from '@21st-night/icons';
import { CategoryTag, CategoryTagProps } from '../CategoryTag';
import { CategorySelectEditMenu } from './CategorySelectEditMenu';

type OnChangeCategory = (value: string) => void;
type OnChangeSubcategory = (value: string[]) => void;

interface BaseProps {
  className?: string;
  categories: string[];
  fullWidth?: boolean;
  onCreate?: (value: string) => void;
  onDelete?: (value: string) => void;
  onUpdate?: (oldValue: string, newValue: string) => void;
}

// export interface CategorySelectProps extends BaseProps {
//   variant: 'category';
//   value: string;
//   onChange: OnChangeCategory;
// }
export interface SubcategorySelectProps extends BaseProps {
  variant: 'subcategory';
  value: string[];
  onChange: OnChangeSubcategory;
}
export interface CategorySelectProps extends BaseProps {
  variant: 'category';
  value: string[];
  onChange: OnChangeSubcategory;
}

interface PaperComponentProps extends PaperProps {
  empty: boolean;
}

const PaperComponent: React.FC<PaperComponentProps> = ({
  children,
  empty,
  ...other
}) => {
  return (
    <Paper {...other}>
      {empty ? (
        <Typography
          color="textSecondary"
          style={{
            display: 'inline-block',
            padding: '16px 8px',
            fontSize: '12px',
            textAlign: 'center',
          }}
          variant="body1"
        >
          Start typing to create a category...
        </Typography>
      ) : (
        children
      )}
    </Paper>
  );
};

export const useStyles = makeStyles(theme =>
  createStyles({
    input: {
      borderRadius: 3,
      padding: theme.spacing(0, 1),
      '&:hover': {
        backgroundColor: '#EFEFEF',
      },
    },
    inputBlurred: {
      cursor: 'pointer',
    },
    inputFocused: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: `1px solid rgba(15, 15, 15, 0.05)`,
      boxShadow:
        'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
      backgroundColor: '#F7F7F5',
      '&:hover': {
        backgroundColor: '#F7F7F5',
      },
    },
    chip: {
      marginRight: theme.spacing(1),
    },
    option: {
      position: 'relative',
      minHeight: 'auto',
      alignItems: 'flex-start',
      paddingLeft: 4,
      paddingRight: 4,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      // '& > .category-select-secondary-action': {
      //   display: 'none',
      // },
      // '&:hover': {
      //   '& > .category-select-secondary-action': {
      //     display: 'block',
      //   },
      // },
    },
    paper: {
      borderRadius: 0,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
      marginTop: 0,
      boxShadow:
        'rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
    },
  }),
);

interface TagProps extends CategoryTagProps {
  category: string;
  className: string;
  focused: boolean;
}

export interface OptionProps {
  category: string;
  variant: 'category' | 'subcategory';
  onClickEdit: () => void;
  editMenuOpen: boolean;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  nameFieldValue: string;
  onCloseEditMenu: () => void;
  onDelete: () => void;
}

const Option: React.FC<OptionProps> = ({
  category,
  onClickEdit,
  editMenuOpen,
  onCloseEditMenu,
  nameFieldValue,
  onChangeName,
  onDelete,
  variant,
  ...other
}) => {
  const classes = useStyles();
  const editMenuButtonRef = useRef<HTMLDivElement>(null);

  if (category.startsWith('__create:')) {
    return (
      <ListItem
        disableGutters
        dense
        key={category}
        classes={{
          root: classes.option,
        }}
        {...other}
      >
        Create{' '}
        <CategoryTag
          variant={variant}
          label={category.slice(9)}
          style={{ marginLeft: 4 }}
        />
      </ListItem>
    );
  }

  return (
    <>
      <CategoryTag key={category} variant={variant} label={category} />
      <ListItemSecondaryAction style={{ right: 0 }}>
        <div ref={editMenuButtonRef}>
          <IconButton
            size="small"
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onClickEdit();
            }}
          >
            <MoreHoriz />
          </IconButton>
        </div>
      </ListItemSecondaryAction>
      <CategorySelectEditMenu
        anchorEl={editMenuButtonRef.current}
        open={editMenuOpen}
        onClose={onCloseEditMenu}
        name={nameFieldValue}
        onDelete={onDelete}
        onChangeName={onChangeName}
      />
    </>
  );
};

const renderTag: React.FC<TagProps> = ({
  category,
  focused,
  onDelete,
  ...other
}) => {
  return (
    <CategoryTag
      key={category}
      label={category}
      onDelete={focused ? onDelete : undefined}
      {...other}
    />
  );
};

export const CategorySelect: React.FC<
  CategorySelectProps | SubcategorySelectProps
> = ({
  className,
  value: valueProp,
  onChange,
  variant,
  categories,
  onCreate,
  onDelete,
  onUpdate,
  ...other
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState('');
  const [editOption, setEditOption] = useState<null | string>(null);
  const [editOptionValue, setEditOptionValue] = useState<string>('');

  const value =
    typeof valueProp === 'string'
      ? valueProp !== ''
        ? [valueProp]
        : []
      : valueProp;

  let placeholder = `Add ${variant}`;
  const queryHasMatch = query === '' || categories.includes(query);
  const options = [...categories];

  if (!queryHasMatch) {
    options.push(`__create:${query}`);
  }

  if (value.length) {
    placeholder = '';
  } else if (focused || editOption) {
    placeholder = `Search for a ${variant}...`;
  }

  function handleClickEditOption(category: string) {
    setEditOption(category);
    setEditOptionValue(category);
  }

  function handleCloseOptionEditMenu() {
    if (!editOption) {
      return;
    }

    if (editOptionValue !== editOption && onUpdate) {
      onUpdate(editOption, editOptionValue);
    }
    if (value.includes(editOption)) {
      onChange(
        value.map(option => (option === editOption ? editOptionValue : option)),
      );
    }
    setEditOption(null);
    setOpen(true);
  }

  function handleDeleteOption() {
    if (!editOption) {
      return;
    }

    if (onDelete) {
      onDelete(editOption);
    }
    if (value.includes(editOption)) {
      onChange(value.filter(option => option !== editOption));
    }
    setEditOption(null);
    setOpen(true);
  }

  function handleOptionOnChangeName(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    setEditOptionValue(event.target.value);
  }

  function handleChange(
    event: React.ChangeEvent<Record<string, unknown>>,
    rawValue: string[],
  ) {
    if (editOption) {
      return;
    }
    const value = [...rawValue];
    let lastValue = value.pop();
    if (lastValue) {
      if (lastValue.startsWith('__create:')) {
        lastValue = lastValue.slice(9);
        if (onCreate) {
          onCreate(lastValue);
        }
      }
      if (lastValue) {
        value.push(lastValue);
      }
    }

    if (variant === 'category') {
      (onChange as OnChangeSubcategory)(lastValue ? [lastValue] : []);
    } else {
      (onChange as OnChangeSubcategory)(value);
    }
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      autoHighlight
      openOnFocus
      onOpen={() => setOpen(true)}
      open={open || !!editOption}
      onClose={() => setOpen(false)}
      noOptionsText=""
      disableCloseOnSelect={variant === 'subcategory' || !!editOption}
      blurOnSelect={variant === 'category' && !editOption}
      popupIcon={''}
      onChange={handleChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      options={options}
      PaperComponent={props => (
        <PaperComponent empty={!options.length} {...props} />
      )}
      value={value}
      onInputChange={(event, v) => {
        setQuery(v);
      }}
      inputValue={query}
      classes={{ paper: classes.paper, option: classes.option }}
      className={className}
      renderTags={(categories, getTagProps) =>
        categories.map((category, index) =>
          renderTag({
            category,
            focused,
            variant,
            className: classes.chip,
            ...getTagProps({ index }),
          }),
        )
      }
      renderOption={category => (
        <Option
          onClickEdit={() => handleClickEditOption(category)}
          editMenuOpen={editOption === category}
          onCloseEditMenu={handleCloseOptionEditMenu}
          onDelete={handleDeleteOption}
          category={category}
          variant={variant}
          nameFieldValue={editOptionValue}
          onChangeName={handleOptionOnChangeName}
        />
      )}
      renderInput={params => (
        <InputBase
          {...params}
          placeholder={placeholder}
          {...params.InputProps}
          className={cn(
            classes.input,
            !focused && classes.inputBlurred,
            (focused || editOption) && classes.inputFocused,
          )}
          inputProps={{
            ...params.inputProps,
            className: cn(!focused && classes.inputBlurred),
          }}
        />
      )}
      {...other}
    />
  );
};
