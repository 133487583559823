import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';

export interface LoginButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  backgroundColor: string;
  dark?: boolean;
  icon: React.ReactNode;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      borderWidth: '0',
      background: 'white',
      color: '#737373',
      borderRadius: '5px',
      whiteSpace: 'nowrap',
      boxShadow: '1px 1px 0px 1px rgba(0, 0, 0, 0.1)',
      transitionProperty: 'background-color, box-shadow',
      transitionDuration: '150ms',
      transitionTimingFunction: 'ease-in-out',
      cursor: 'pointer',
      padding: '0',
      '&:focus': {
        boxShadow: '1px 4px 5px 1px rgba(0, 0, 0, 0.2)',
      },
      '&:hover': {
        boxShadow: '1px 4px 5px 1px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        backgroundColor: '#e5e5e5',
        boxShadow: 'none',
        transitionDuration: '10ms',
      },
    },
    icon: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      boxSizing: 'border-box',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: '#FFF',
    },
    iconLight: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    text: {
      flex: 1,
      display: 'inline-block',
      padding: '0 24px',
      fontSize: '16px',
      fontWeight: 500,
      color: '#FFF',
      fontFamily: theme.typography.fontFamily,
    },
  }),
);

export const LoginButton: React.FC<LoginButtonProps> = ({
  className,
  backgroundColor,
  children,
  dark = false,
  icon,
  ...other
}) => {
  const classes = useStyles();
  return (
    <button
      type="button"
      className={cn(classes.root, className)}
      style={{ backgroundColor }}
      {...other}
    >
      <span className={cn(classes.icon, dark && classes.iconLight)}>
        {icon}
      </span>
      <span className={classes.text}>{children}</span>
    </button>
  );
};
