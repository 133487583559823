import React from 'react';
import {
  ListItemAvatar as MuiListItemAvatar,
  ListItemAvatarProps as MuiListItemAvatarProps,
} from '@material-ui/core';

export type ListItemAvatarProps = MuiListItemAvatarProps;

export const ListItemAvatar: React.FC<ListItemAvatarProps> = ({ ...other }) => {
  return <MuiListItemAvatar {...other} />;
};
