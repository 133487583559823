import { DeckFilterOption } from '../../Deck.types';

export function generateDeckSubcategoryFilters(
  subcategories: string[],
): DeckFilterOption[] {
  return subcategories.map(subcategory => ({
    type: 'subcategory',
    group: 'subcategories',
    label: subcategory,
    value: subcategory,
  }));
}
