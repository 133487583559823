import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';

export type ButtonsProps = React.HTMLAttributes<HTMLDivElement>;

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

export const Buttons: React.FC<ButtonsProps> = ({
  className,
  children,
  ...other
}) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.root, className)} {...other}>
      {children}
    </div>
  );
};
