import React, { useCallback, useEffect, useState } from 'react';
import { motion, Variants, MotionProps } from 'framer-motion';
import { makeStyles, createStyles } from '@21st-night/styles';
import { OnboardingQuestionAnswerOption } from '@21st-night/onboarding';
import { OnboardingQuestionAnswerButton } from '../OnboardingQuestionAnswerButton';
import { OnboardingQuestionSkipButton } from '../OnboardingQuestionSkipButton';
import { OnboardingQuestionText } from '../OnboardingQuestionText';

export interface OnboardingMultipleChoiceQuestionProps extends MotionProps {
  question: string;
  options: OnboardingQuestionAnswerOption[];
  onClickOption: (option: OnboardingQuestionAnswerOption) => void;
  onSkip?: () => void;
}

export const useStyles = makeStyles(() =>
  createStyles({
    options: {
      width: '100%',
      textAlign: 'center',
      marginTop: 24,
    },
  }),
);

const buttonContainerVariants: Variants = {
  in: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  out: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const buttonVariants: Variants = {
  in: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  out: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const OnboardingMultipleChoiceQuestion: React.FC<OnboardingMultipleChoiceQuestionProps> = ({
  question,
  options,
  onClickOption,
  onSkip,
  ...other
}) => {
  const classes = useStyles();
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplayed(true);
    }, 200);
  }, [question]);

  const handleClickOption = useCallback(
    (option: OnboardingQuestionAnswerOption) => {
      setDisplayed(false);
      setTimeout(() => {
        onClickOption(option);
      }, 500);
    },
    [onClickOption],
  );

  const handleSkip = useCallback(() => {
    if (!onSkip) {
      return;
    }
    setDisplayed(false);
    setTimeout(() => {
      onSkip();
    }, 500);
  }, [onSkip]);

  return (
    <motion.div animate={displayed ? 'in' : 'out'} {...other}>
      <OnboardingQuestionText question={question} />
      <motion.div
        initial={false}
        variants={buttonContainerVariants}
        className={classes.options}
      >
        {options.map(option => (
          <OnboardingQuestionAnswerButton
            key={option.value}
            variants={buttonVariants}
            option={option}
            onClick={() =>
              option.value === 'skip' ? handleSkip() : handleClickOption(option)
            }
          />
        ))}
        {onSkip && (
          <OnboardingQuestionSkipButton
            onClick={handleSkip}
            variants={buttonVariants}
          />
        )}
      </motion.div>
    </motion.div>
  );
};
