import { DocumentReference } from '@21st-night/utils';
import { StudyPlanItemUpdateData } from '../../types';

export function updateStudyPlanItem(
  planRef: DocumentReference,
  id: string,
  data: StudyPlanItemUpdateData,
): Promise<void> {
  return planRef.collection('items').doc(id).update(data);
}
