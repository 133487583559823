import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';

type Size = 'small' | 'medium' | 'large';

export interface LoadingIndicatorProps
  extends React.HTMLAttributes<SVGElement> {
  /**
   * Size in pixels.
   */
  size?: Size | number;
}

type Sizes = { [key in Size]: number };

export const sizes: Sizes = {
  small: 18,
  medium: 24,
  large: 36,
};

export const useStyles = makeStyles(() =>
  createStyles({
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    spin: {
      animation: '$spin infinite 3s linear',
    },
  }),
);

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  className: classNameProp,
  size: sizeProp = 'medium',
  ...other
}) => {
  const classes = useStyles();
  let size = sizeProp;

  if (typeof size === 'string' && Object.keys(sizes).includes(size)) {
    size = sizes[size];
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn(classes.spin, classNameProp)}
      aria-label="Loading"
      {...other}
    >
      <line x1="12" y1="2" x2="12" y2="6" />
      <line x1="12" y1="18" x2="12" y2="22" />
      <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
      <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
      <line x1="2" y1="12" x2="6" y2="12" />
      <line x1="18" y1="12" x2="22" y2="12" />
      <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
      <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
    </svg>
  );
};
