import {
  EditorBlockElement,
  EditorInlineElement,
  EquationElement,
} from '../../Editor.types';
import { isText } from '../isText';
import { isElementOfType } from '../isElementOfType';

export function toPlainText(
  document: (EditorBlockElement | EditorInlineElement)[],
): string {
  let text = '';
  let previousText = '';

  document.forEach((element, index) => {
    if (element.type === 'tex') {
      text = `${text}${element.tex}`;
    } else if (element.type === 'image') {
      text = `${text}<img src="${element.id}" />`;
    } else if (element.type === 'audio') {
      text = `${text}<audio src="${element.id}" />`;
    } else if (element.children) {
      element.children.forEach(child => {
        if (isElementOfType(child, 'equation-inline')) {
          const equation = child as EquationElement;
          text = `${text}${equation.tex}`;
        } else if (!isText(child)) {
          text = `${text}${toPlainText(
            child.children as EditorInlineElement[],
          )}`;
        } else {
          text = `${text}${child.text || ''}`;
        }
      });
    }

    if (index !== document.length - 1 && text !== previousText) {
      text = `${text}\n\n`;
      previousText = text;
    }
  });

  return text;
}
