import React, { useState } from 'react';
import { Button, Typography, Spaced } from '@21st-night/ui';
import { EmailButton } from '../EmailButton';
import { GoogleButton } from '../GoogleButton';
import { AppleButton } from '../AppleButton';
import {
  EmailAuthenticationForm,
  EmailAuthenticationFormView,
  EmailAuthenticationFormFieldErrors,
  EmailAuthenticationFormFieldValues,
} from '../EmailAuthenticationForm';
import { SignupButton } from '../SignupButton';
import { DemoButton } from '../DemoButton';

export interface AuthenticationFormProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onClickGoogleButton: () => void;
  onClickAppleButton: () => void;
  onClickDemoButton: () => void;
  onSubmitEmailForm: (
    view: EmailAuthenticationFormView,
    values: EmailAuthenticationFormFieldValues,
  ) => void;
  emailFormValues: EmailAuthenticationFormFieldValues;
  emailFormErrors: EmailAuthenticationFormFieldErrors;
  onEmailFormFieldChange: React.ChangeEventHandler<HTMLInputElement>;
  passwordRecoveryEmail?: string;
  emailFormDisabled?: boolean;
}

export const AuthenticationForm: React.FC<AuthenticationFormProps> = ({
  onClickAppleButton,
  onClickGoogleButton,
  onClickDemoButton,
  passwordRecoveryEmail,
  onEmailFormFieldChange,
  emailFormValues,
  emailFormErrors,
  onSubmitEmailForm,
  emailFormDisabled,
}) => {
  const [showEmailAuth, setShowEmailAuth] = useState(false);
  const [
    emailAuthView,
    setEmailAuthView,
  ] = useState<EmailAuthenticationFormView>('login');

  function switchEmailAuthView(view: EmailAuthenticationFormView) {
    return () => {
      if (!showEmailAuth) {
        setShowEmailAuth(true);
      }
      setEmailAuthView(view);
    };
  }

  function backToMainView() {
    setShowEmailAuth(false);
    setEmailAuthView('login');
  }

  return (
    <>
      {!showEmailAuth && !passwordRecoveryEmail && (
        <Spaced vertical spacing={4}>
          <Spaced vertical spacing={2}>
            <EmailButton onClick={switchEmailAuthView('login')} />
            <GoogleButton onClick={onClickGoogleButton} />
            <AppleButton onClick={onClickAppleButton} />
          </Spaced>
          <Spaced vertical spacing={2}>
            <Typography>Don&apos;t have an account?</Typography>
            <SignupButton onClick={switchEmailAuthView('signup')} />
            <DemoButton onClick={onClickDemoButton} />
          </Spaced>
        </Spaced>
      )}

      {showEmailAuth && (
        <>
          {emailAuthView === 'forgot-password' && (
            <Spaced align="start">
              <Button
                variant="text"
                size="small"
                onClick={switchEmailAuthView('login')}
                disabled={emailFormDisabled}
              >
                Back
              </Button>
            </Spaced>
          )}

          {emailAuthView !== 'forgot-password' && (
            <Spaced align="start">
              <Button
                variant="text"
                size="small"
                onClick={backToMainView}
                disabled={emailFormDisabled}
              >
                Back
              </Button>
            </Spaced>
          )}

          <EmailAuthenticationForm
            view={emailAuthView}
            onFieldChange={onEmailFormFieldChange}
            onSubmit={onSubmitEmailForm}
            values={emailFormValues}
            errors={emailFormErrors}
            disabled={emailFormDisabled}
          />

          {emailAuthView === 'login' && (
            <Spaced>
              <Button
                variant="text"
                color="primary"
                onClick={switchEmailAuthView('signup')}
                disabled={emailFormDisabled}
              >
                Sign up
              </Button>
              <div style={{ flex: 1 }} />
              <Button
                variant="text"
                onClick={switchEmailAuthView('forgot-password')}
                disabled={emailFormDisabled}
              >
                Forgot password?
              </Button>
            </Spaced>
          )}

          {emailAuthView === 'signup' && (
            <Spaced align="start">
              <Button
                variant="text"
                color="primary"
                onClick={switchEmailAuthView('login')}
                disabled={emailFormDisabled}
              >
                Log in
              </Button>
            </Spaced>
          )}
        </>
      )}

      {passwordRecoveryEmail && (
        <Typography>
          We&apos;ve sent an email with instructions on how to reset your
          password to <strong>{passwordRecoveryEmail}</strong>.
        </Typography>
      )}
    </>
  );
};
