import React from 'react';
import { SimpleLineChart, SimpleLineChartDataItem } from '../SimpleLineChart';

export interface RetentionRateAreaChartDataItem
  extends SimpleLineChartDataItem {
  label: string;
  retention: number;
}

export interface RetentionRateAreaChartProps {
  data: RetentionRateAreaChartDataItem[];
  mini?: boolean;
}

function avrageRetentionRate(data: SimpleLineChartDataItem[]) {
  const nonZero = data.filter(item => item.retention !== 0);
  const total = nonZero.reduce(
    (tot, item) => tot + (item.retention as number),
    0,
  );

  return Math.round(total / nonZero.length);
}

function valueFormatter(value: number) {
  return !value ? '--%' : `${value}%`;
}

export const RetentionRateAreaChart: React.FC<RetentionRateAreaChartProps> = ({
  data,
  mini,
}) => {
  return (
    <SimpleLineChart
      label="Retention rate"
      dataKey="retention"
      totalFormatter={avrageRetentionRate}
      valueFormatter={valueFormatter}
      mini={mini}
      data={data}
    />
  );
};
