import { DB } from '@21st-night/utils';

export function deleteStudyPlanTask(
  db: DB,
  userId: string,
  id: string,
): Promise<void> {
  return db
    .collection('users')
    .doc(userId)
    .collection('study-plan')
    .doc('plan')
    .collection('tasks')
    .doc(id)
    .delete();
}
