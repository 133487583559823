import React, { useMemo } from 'react';
import { RatingEvent, ReviewRating } from '@21st-night/review';
import { cn, makeStyles, Theme, useTheme } from '@21st-night/styles';
import { secondsToHumanReadable } from '@21st-night/analytics';
import { Spaced, Typography } from '@21st-night/ui';
import dayjs from 'dayjs';

export interface CardRatingsTimelineChartProps
  extends React.HTMLAttributes<HTMLDivElement> {
  createdAt: Date;
  ratings: RatingEvent[];
}

const useStyles = makeStyles(theme => ({
  root: {},
  days: {
    display: 'flex',
    paddingTop: theme.spacing(2),
  },
  day: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  dayWithDating: {
    minWidth: 24,
  },
  dot: {
    width: 11,
    height: 11,
    borderRadius: 5.5,
    position: 'relative',
  },
  addedDot: {
    background: theme.palette.proficiency.notStarted,
  },
  xAxis: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
  },
  legend: {
    marginTop: theme.spacing(2),
  },
  legendItem: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  legendDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 5,
  },
  legendDuration: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '2px 8px',
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  legendDurationText: {
    fontSize: '12px',
  },
  duration: {
    position: 'absolute',
    top: -25,
    fontSize: '12px',
    borderRadius: theme.shape.borderRadius,
    transform: 'translate(-50%)',
    left: 5.5,
    margin: '0',
    color: theme.palette.text.primary,
    padding: '0 2px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  durationArrow: {
    bottom: 0,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-5px, 5px)',
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: `5px solid ${theme.palette.grey[300]}`,
  },
}));

function generateDays(createdAt: Date, ratings: RatingEvent[]) {
  const last = ratings.slice(-1)[0];
  const endDate =
    last && last.rating === 'retire' ? last.createdAt : new Date();
  let length = dayjs(endDate).diff(createdAt, 'day');
  const days = [];

  while (length >= 0) {
    const date = dayjs(endDate).subtract(length, 'day').toDate();
    const ratingEvent = ratings.find(event =>
      dayjs(event.createdAt).isSame(date, 'date'),
    );
    days.push({
      date,
      duration: ratingEvent ? ratingEvent.duration : 0,
      rating: ratingEvent ? ratingEvent.rating : null,
    });
    length -= 1;
  }

  return days;
}

function ratingToProficiency(
  rating: ReviewRating,
): keyof Theme['palette']['proficiency'] {
  switch (rating) {
    case 'retire':
      return 'retired';
    case 'correct':
      return 'mastered';
    case 'more-work':
      return 'inProgress';
    case 'wrong':
      return 'needsWork';
    default:
      return 'notStarted';
  }
}

export const CardRatingsTimelineChart: React.FC<CardRatingsTimelineChartProps> = ({
  createdAt,
  ratings,
  ...other
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const days = useMemo(() => generateDays(createdAt, ratings), [
    createdAt,
    ratings,
  ]);

  return (
    <div {...other}>
      <div className={classes.days}>
        {days.map((day, index) => (
          <div
            key={index}
            className={cn(classes.day, day.rating && classes.dayWithDating)}
          >
            {index === 0 && (
              <div className={cn(classes.dot, classes.addedDot)} />
            )}
            {day.rating && (
              <div
                className={classes.dot}
                style={{
                  background:
                    theme.palette.proficiency[ratingToProficiency(day.rating)],
                }}
              >
                <div className={classes.duration}>
                  {secondsToHumanReadable(day.duration, true)}
                  <div className={classes.durationArrow} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.xAxis}>
        <Typography variant="caption">
          {dayjs(days[0].date).format('MMM D')}
        </Typography>
        <Typography variant="caption">{days.length} days</Typography>
        <Typography variant="caption">
          {dayjs(days.slice(-1)[0].date).format('MMM D')}
        </Typography>
      </div>
      <Spaced className={classes.legend} align="center" spacing={2}>
        <div className={classes.legendItem}>
          <div
            className={classes.legendDot}
            style={{ background: theme.palette.proficiency.notStarted }}
          />
          <Typography variant="caption">Added</Typography>
        </div>
        <div className={classes.legendItem}>
          <div
            className={classes.legendDot}
            style={{ background: theme.palette.proficiency.mastered }}
          />
          <Typography variant="caption">Correct</Typography>
        </div>
        <div className={classes.legendItem}>
          <div
            className={classes.legendDot}
            style={{ background: theme.palette.proficiency.inProgress }}
          />
          <Typography variant="caption">Tricky</Typography>
        </div>
        <div className={classes.legendItem}>
          <div
            className={classes.legendDot}
            style={{ background: theme.palette.proficiency.needsWork }}
          />
          <Typography variant="caption">Wrong</Typography>
        </div>
        <div className={classes.legendItem}>
          <div
            className={classes.legendDot}
            style={{ background: theme.palette.proficiency.retired }}
          />
          <Typography variant="caption">Retire</Typography>
        </div>
      </Spaced>
      <Spaced align="center">
        <div className={classes.legendDuration}>
          <Typography className={classes.legendDurationText}>
            Time in minutes
          </Typography>
        </div>
      </Spaced>
    </div>
  );
};
