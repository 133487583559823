import React, { useEffect, useCallback, useState } from 'react';
import {
  createContext,
  arrayMove,
  useFirebase,
  DocumentReference,
} from '@21st-night/utils';
import {
  StudyPlanItem,
  StudyPlanDay,
  StudyPlanItemUpdateData,
  StudyPlanWeek,
} from '../types';
import {
  deserializeStudyPlanDocument,
  deserializeStudyPlanDayDocument,
  deserializeStudyPlanItemDocument,
  deleteStudyPlan,
  createStudyPlanDay,
  generateStudyPlanItem,
  createStudyPlanItem,
  createStudyPlanSubItem,
  updateStudyPlanItem,
  updateStudyPlanDay,
  deleteStudyPlanSubItem,
  deleteStudyPlanItem,
} from '../api';
import { useStudyPlanState, StudyPlanState } from '../StudyPlanStateProvider';

export interface StudyPlanProviderProps {
  planRef: DocumentReference;
  load: boolean;
}

export type DeletePlan = () => Promise<void>;
export type AddDay = (day: StudyPlanDay) => Promise<void>;
export type AddItem = (item: StudyPlanItem) => Promise<void>;
export type AddSubItem = (subItem: StudyPlanItem) => Promise<void>;
export type AddDayWithItem = (
  day: StudyPlanDay,
  title: string,
) => Promise<void>;
export type UpdateItem = (
  id: string,
  data: StudyPlanItemUpdateData,
) => Promise<void>;
export type ToggleItemDone = (item: StudyPlanItem) => Promise<void>;
export type MoveItem = (
  item: StudyPlanItem,
  dayId: string,
  index: number,
) => Promise<void>;
export type SortItem = (item: StudyPlanItem, toIndex: number) => Promise<void>;
export type DeleteItem = (item: StudyPlanItem) => Promise<void>;
export type DeleteSubItem = (item: StudyPlanItem) => Promise<void>;

export interface StudyPlanContext extends StudyPlanState {
  loading: boolean;
  loadingPlan: boolean;
  loadingDays: boolean;
  loadingItems: boolean;
  weeks: StudyPlanWeek[];
  deletePlan: DeletePlan;
  addDay: AddDay;
  addItem: AddItem;
  addSubItem: AddSubItem;
  addDayWithItem: AddDayWithItem;
  updateItem: UpdateItem;
  toggleItemDone: ToggleItemDone;
  moveItem: MoveItem;
  sortItem: SortItem;
  deleteItem: DeleteItem;
  deleteSubItem: DeleteSubItem;
}

const [hook, Provider, Consumer] = createContext<StudyPlanContext>();

export const StudyPlanProvider: React.FC<StudyPlanProviderProps> = ({
  planRef,
  load,
  children,
}) => {
  const { db } = useFirebase();
  const state = useStudyPlanState();
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [loadingDays, setLoadingDays] = useState(true);
  const [loadingItems, setLoadingItems] = useState(true);

  useEffect(() => {
    let mounted = true;

    if (!load) {
      return;
    }

    const subscriptions: VoidFunction[] = [];
    setLoadingPlan(true);
    setLoadingDays(true);
    setLoadingItems(true);

    // Subscribe to plan
    subscriptions.push(
      planRef.onSnapshot(snapshot => {
        if (!mounted) {
          return;
        }

        if (snapshot.exists) {
          state.loadPlan(deserializeStudyPlanDocument(snapshot));
        } else {
          state.reset();
        }

        setLoadingPlan(false);
      }),
    );

    // Subscribe to days
    subscriptions.push(
      planRef.collection('days').onSnapshot(snapshot => {
        if (!mounted) {
          return;
        }

        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            state.addDay(deserializeStudyPlanDayDocument(change.doc));
          } else if (change.type === 'modified') {
            state.updateDay(
              change.doc.id,
              deserializeStudyPlanDayDocument(change.doc),
            );
          }
        });

        setLoadingDays(false);
      }),
    );

    // Subscribe to items
    subscriptions.push(
      planRef.collection('items').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          if (!mounted) {
            return;
          }

          if (change.type === 'added') {
            if ((change.doc.data() as StudyPlanItem).parentType === 'item') {
              state.addSubItem(deserializeStudyPlanItemDocument(change.doc));
            } else {
              state.addItem(deserializeStudyPlanItemDocument(change.doc));
            }
          } else if (change.type === 'modified') {
            state.updateItem(
              change.doc.id,
              deserializeStudyPlanItemDocument(change.doc),
            );
          } else if (change.type === 'removed') {
            if ((change.doc.data() as StudyPlanItem).parentType === 'item') {
              state.removeSubItem(deserializeStudyPlanItemDocument(change.doc));
            } else {
              state.removeItem(deserializeStudyPlanItemDocument(change.doc));
            }
          }
        });

        setLoadingItems(false);
      }),
    );

    return () => {
      mounted = false;
      subscriptions.forEach(unsubscribe => unsubscribe());
      state.reset();
    };
  }, [planRef, load]);

  // Delete study plan
  const deletePlan: DeletePlan = useCallback(async () => {
    // Reset the state
    state.reset();
    // Delete the plan from the database
    await deleteStudyPlan(
      db,
      planRef,
      Object.keys(state.days),
      Object.keys(state.items),
    );
  }, [planRef, db, state.addDay, state.days, state.items]);

  // Add day
  const addDay: AddDay = useCallback(
    day => {
      // Add the day to the state
      state.addDay(day);
      // Create the day in the database
      return createStudyPlanDay(planRef, day);
    },
    [planRef, state.addDay],
  );

  // Add item
  const addItem: AddItem = useCallback(
    async item => {
      // Add the item to the state
      state.addItem(item);
      // Create the item in the database
      await createStudyPlanItem(db, planRef, item);
    },
    [planRef, db, state.addItem],
  );

  // Add sub-item
  const addSubItem: AddSubItem = useCallback(
    async subItem => {
      // Add the sub-item to the state
      state.addSubItem(subItem);
      // Create the sub-item in the database
      await createStudyPlanSubItem(db, planRef, subItem);
    },
    [planRef, db, state.addSubItem],
  );

  // Add a day with an item
  const addDayWithItem: AddDayWithItem = useCallback(
    async (day, title) => {
      // Add the day to the state
      state.addDay(day);
      // Add the item to the state
      const item = generateStudyPlanItem(day, title);
      state.addItem(item);
      // Create the day in the database
      await createStudyPlanDay(planRef, day);
      // Create the item in the database
      await createStudyPlanItem(db, planRef, item);
    },
    [planRef, db, state.addSubItem],
  );

  // Update an item
  const updateItem: UpdateItem = useCallback(
    async (id, data) => {
      // Add the item to the state
      state.updateItem(id, data);
      // Create the item in the database
      await updateStudyPlanItem(planRef, id, data);
    },
    [planRef, state.updateItem],
  );

  // Toggle an item's "done" status
  const toggleItemDone: ToggleItemDone = useCallback(
    async item => {
      // Add the item to the state
      state.updateItem(item.id, { done: !item.done, donePreviously: true });
      // Create the item in the database
      await updateStudyPlanItem(planRef, item.id, {
        done: !item.done,
        donePreviously: true,
      });
    },
    [planRef, state.updateItem],
  );

  // Move an item to a different day
  const moveItem: MoveItem = useCallback(
    async (item, targetDayId, index) => {
      const sourceDay = state.days[item.parent];
      const targetDay = state.days[targetDayId];
      const targetItems = [...targetDay.items];
      targetItems.splice(index, 0, item.id);

      // Add item to target day
      state.updateDay(targetDayId, { items: targetItems });
      // Remove item from source day
      state.updateDay(item.parent, {
        items: sourceDay.items.filter(id => id !== item.id),
      });
      // Update item parent
      state.updateItem(item.id, { parent: targetDayId });

      // Update database values
      await Promise.all([
        updateStudyPlanDay(planRef, targetDayId, { items: targetItems }),
        updateStudyPlanDay(planRef, item.parent, {
          items: db.arrayRemove(item.id),
        }),
        updateStudyPlanItem(planRef, item.id, { parent: targetDayId }),
      ]);
    },
    [planRef, state.days, db, state.updateDay, state.updateItem],
  );

  // Change the position of an item within a day
  const sortItem: SortItem = useCallback(
    async (item, toIndex) => {
      const day = state.days[item.parent];
      let newItems = [...day.items];
      const fromIndex = day.items.indexOf(item.id);
      newItems = arrayMove(newItems, fromIndex, toIndex);

      // Update position in the state
      state.updateDay(item.parent, { items: newItems });
      // Update position in the database
      return updateStudyPlanDay(planRef, item.parent, { items: newItems });
    },
    [planRef, state.days, state.updateDay],
  );

  // Delete an item
  const deleteItem: DeleteItem = useCallback(
    async item => {
      // Remove item from the state
      state.removeItem(item);
      // item from the database
      await deleteStudyPlanItem(db, planRef, item.parent, item.id);
    },
    [planRef, db, state.removeItem],
  );

  // Delete a sub-item
  const deleteSubItem: DeleteSubItem = useCallback(
    async item => {
      // Remove item from the state
      state.removeSubItem(item);
      // item from the database
      await deleteStudyPlanSubItem(db, planRef, item.parent, item.id);
    },
    [planRef, db, state.removeSubItem],
  );

  return (
    <Provider
      value={{
        weeks: state.weeks,
        days: state.days,
        items: state.items,
        description: state.description,
        startDate: state.startDate,
        endDate: state.endDate,
        startWeekOn: state.startWeekOn,
        loadingPlan,
        loadingDays,
        loadingItems,
        loading: loadingPlan || loadingDays || loadingItems,
        deletePlan,
        addDay,
        addItem,
        addSubItem,
        addDayWithItem,
        updateItem,
        toggleItemDone,
        moveItem,
        sortItem,
        deleteItem,
        deleteSubItem,
      }}
    >
      {children}
    </Provider>
  );
};

export const useStudyPlan = hook;
export const StudyPlanConsumer = Consumer;
