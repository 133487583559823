/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import { DB, Storage, Functions } from '@21st-night/utils-web';
import createImagePlugin, {
  EditorWithImagePlugin,
} from './plugins/plugin-image';
import createVideoPlugin, {
  EditorWithVideoPlugin,
} from './plugins/plugin-video';
import createAudioPlugin, {
  EditorWithAudioPlugin,
} from './plugins/plugin-audio';
import { webEditorPlugins, WebEditor } from './web-editor-plugins';
import { BaseEditor, BaseEditorProps } from './BaseEditor';

export interface EditorProps extends BaseEditorProps {
  db: DB;
  storage: Storage;
  functions: Functions;
  imageUrl: string;
}

export interface Editor
  extends WebEditor,
    EditorWithImagePlugin,
    EditorWithVideoPlugin,
    EditorWithAudioPlugin {}

const VideoPlugin = createVideoPlugin();

export const Editor: React.FC<EditorProps> = ({
  storage,
  functions,
  db,
  imageUrl,
  children,
  plugins: pluginsProp = [],
  ...other
}) => {
  const ImagePlugin = useMemo(
    () =>
      createImagePlugin({
        imageUrl,
        functions,
        storage,
        db,
      }),
    [],
  );
  const AudioPlugin = useMemo(
    () =>
      createAudioPlugin({
        functions,
        storage,
        db,
      }),
    [],
  );

  return (
    <BaseEditor
      height="100%"
      plugins={[
        ...webEditorPlugins,
        ImagePlugin,
        VideoPlugin,
        AudioPlugin,
        ...pluginsProp,
      ]}
      {...other}
    >
      {children}
    </BaseEditor>
  );
};
