import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeck } from '../updateDeck';
import { Deck } from '../../Deck.types';

export function deleteCategory(
  db: DB,
  deckRef: DocumentReference,
  deck: Deck,
  category: string,
): Promise<void> {
  // Remove category from cards using it
  const cardUpdates = Object.keys(deck.cards).reduce(
    (updates, cardId) =>
      deck.cards[cardId].category === category
        ? {
            ...updates,
            [cardId]: {
              category: db.delete(),
            },
          }
        : updates,
    {},
  );

  // Remove category from notes using it
  const noteUpdates = Object.keys(deck.notes).reduce(
    (updates, noteId) =>
      deck.notes[noteId].category === category
        ? {
            ...updates,
            [noteId]: {
              category: db.delete(),
            },
          }
        : updates,
    {},
  );

  return updateDeck(deckRef, {
    cards: cardUpdates,
    notes: noteUpdates,
    categories: db.arrayRemove(category),
  });
}
