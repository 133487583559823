import { ReviewRating } from '../../Review.types';
import { RATING_VALUES, RECENT_ATTEMPTS_COUNT } from '../../constants';

/**
 * Calculates a proficiency score based on
 * the recent answer ratings of a card.
 *
 * @param {array} ratings The ratings of answer attempts on the card.
 * @param {number} attemptsCount The number of most recent attempts to use in the calculation.
 */
export function calculateProficiencyScore(
  ratings: ReviewRating[],
  attemptsCount = RECENT_ATTEMPTS_COUNT,
): number {
  // Get the most recent past ratings
  const recentPastRatings = ratings.slice(-attemptsCount);
  // Calculate the rating by totaling the
  // rating values of recent ratings
  const score = recentPastRatings.reduce(
    (total: number, recentRating: ReviewRating) =>
      total + RATING_VALUES[recentRating],
    0,
  );

  return score;
}
