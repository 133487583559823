import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { Range, Transforms } from 'slate';
import {
  RenderElementProps,
  useEditorState,
  useUI,
} from '@braindrop-editor/core';
import QRCode from 'qrcode.react';
import Player from 'react-player';
import filesize from 'filesize';
import { makeStyles, useTheme } from '@21st-night/styles';
import {
  InsertAudio,
  OpenInNew,
  Delete,
  Replace,
  AddBlockAbove,
  AddBlockBelow,
  MoreHoriz,
} from '@21st-night/icons';
import { Button, Typography, LoadingIndicator } from '@21st-night/ui';
import { useFileUpload, DB, Storage, Functions } from '@21st-night/utils-web';
import { ReactEditor, useEditor, useSelected } from 'slate-react';
import { WebEditor } from '../../../web-editor-plugins';
import { BlockPlaceholder } from '../../../components';
import { Tab } from '../../../components/EditorPopoverTab';
import { Tabs } from '../../../components/EditorPopoverTabs';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';
import {
  TabsValue,
  AudioElement,
  FileData,
  AudioNodeData,
  MobileUploadDocument,
} from '../AudioPlugin.types';

export interface ElementAudioProps extends RenderElementProps<AudioElement> {
  storage: Storage;
  db: DB;
  functions: Functions;
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover $toolbar': {
      display: 'block',
    },
  },
  popoverContent: {
    width: 360,
    maxWidth: '80vw',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  player: {
    userSelect: 'none',
    maxHeight: 56,
    margin: theme.spacing(1, 0),
    '& > audio': {
      outline: 'none',
      height: 56,
    },
  },
  uploadProgress: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
  fetchingProgress: {
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  helperText: {
    userSelect: 'none',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    maxWidth: 300,
  },
  textField: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  qrCode: {
    margin: theme.spacing(1),
  },
  toolbar: {
    zIndex: 50,
    display: 'none',
    position: 'absolute',
    borderRadius: 3,
    padding: 4,
    backgroundColor: '#000',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: 4,
    },
  },
  menuPopoverAnchor: {
    position: 'absolute',
    top: theme.spacing(1) + 4,
    right: theme.spacing(1) + 4,
  },
  fileInput: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    left: -1000,
    top: -1000,
  },
}));

export const ElementAudio: React.FC<ElementAudioProps> = ({
  attributes,
  children,
  element,
  storage,
  functions,
  db,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const editor = useEditor() as WebEditor;
  const selected = useSelected();
  const [url, setUrl] = useState('');
  const [fetchingAudio, setFetchingAudio] = useState(false);
  const [fetchingError, setFetchingError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const menuPopoverAnchor = useRef<HTMLDivElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [tab, setTab] = useState<TabsValue>(element.initialTab || 'upload');
  const { Popover, TextField, List, MenuItem } = useUI();
  const { toggle, turnOff, turnOn } = useEditorState();
  const divRef = attributes.ref || useRef<HTMLDivElement>(null);
  const [mobileUpload, setMobileUpload] = useState<MobileUploadDocument | null>(
    null,
  );
  const toggleKey = `audio-popover-${element.id}`;
  const open = toggle(toggleKey);
  // Initialize uploadFromUrl Firebase function
  const uploadFromUrl = useMemo(
    () => functions.httpsCallable('uploadFromUrl'),
    [],
  );

  const closePopover = useCallback(() => {
    turnOff(toggleKey);
  }, []);

  const handleTabChange = useCallback((event, value: TabsValue) => {
    setTab(value);
  }, []);

  useEffect(() => {
    let isMounted = true;
    let unsubscribe: VoidFunction;

    if (element.id) {
      unsubscribe = db
        .collection('mobile-uploads')
        .doc(element.id)
        .onSnapshot(snapshot => {
          if (isMounted && snapshot && snapshot.exists) {
            const data = snapshot.data() as MobileUploadDocument;
            if (data.status === 'ready') {
              updateNodeData({
                url: data.fileUrl as string,
                file: data.fileId,
              });
            } else {
              resetNodeData();
              setMobileUpload(snapshot.data() as MobileUploadDocument);
            }
          }
        });
    }

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (
      !element.uploadFile &&
      !element.uploadUrl &&
      !element.url &&
      editor.selection &&
      selected &&
      Range.isCollapsed(editor.selection)
    ) {
      openPopover();
    }
  }, [selected, editor.selection]);

  const updateNodeData = useCallback((data: Partial<AudioNodeData>) => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, data, { at: path });
    ReactEditor.blur(editor);
  }, []);

  const resetNodeData = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.unsetNodes(
      editor,
      ['file', 'url', 'originalUrl', 'crop', 'dimensions'],
      { at: path },
    );
  }, []);

  const handleUploadSuccess = useCallback(
    (url: string, id: string) => {
      updateNodeData({ url, file: id });
    },
    [tab],
  );

  const handleUploadStart = useCallback(() => {
    closePopover();
    resetNodeData();
  }, []);

  const {
    onChange,
    upload,
    progress,
    status: fileUploadStatus,
    error,
    file,
  } = useFileUpload(storage, {
    onUploadSuccess: handleUploadSuccess,
    onUploadStart: handleUploadStart,
  });

  const handleUploadFromUrl = useCallback(async (link: string) => {
    resetNodeData();
    setFetchingError('');
    setFetchingAudio(true);
    closePopover();
    try {
      const result = await uploadFromUrl({ url: link });

      const { originalUrl, id } = result.data as FileData;
      const downloadUrl = await storage.ref(id).getDownloadURL();
      updateNodeData({ originalUrl, url: downloadUrl, file: id });
      setFetchingAudio(false);
    } catch (err) {
      setFetchingAudio(false);
      setFetchingError(
        'An error occured when trying to fetch the file. Try downloading the file yourself and uploading it here.',
      );
    }
  }, []);

  useEffect(() => {
    if (element.uploadFile && element.uploadFile instanceof File) {
      const { uploadFile } = element;
      upload(uploadFile);
      resetNodeData();
    }
  }, [element.uploadFile]);

  useEffect(() => {
    if (element.uploadUrl && typeof element.uploadUrl === 'string') {
      const { uploadUrl } = element;
      handleUploadFromUrl(uploadUrl);
    }
  }, [element.uploadFile]);

  const openPopover = useCallback(() => {
    if (fileUploadStatus === 'uploading' || fetchingAudio || mobileUpload) {
      return;
    }
    if (!element.initialTab) {
      setTab('upload');
    }
    turnOn(toggleKey);
  }, [fileUploadStatus, fetchingAudio, mobileUpload, element.initialTab]);

  const deleteNode = useCallback(() => {
    editor.deleteElement(element);
  }, [element]);

  const openMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleClickOpenLink = useCallback(() => {
    closeMenu();
    if (!element.url) {
      return;
    }
    window.open(element.url as string, '_blank');
  }, [element.url]);

  const handleClickReplace = useCallback(() => {
    closeMenu();
    setUrl('');
    openPopover();
  }, []);

  const openFileSelectionDialog = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }, [fileInput.current]);

  const handleSubmitLink = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!url) {
        return;
      }
      handleUploadFromUrl(url);
    },
    [url],
  );

  return (
    <div {...attributes} ref={divRef} className={classes.root}>
      {!element.url && (
        <BlockPlaceholder
          onClick={openPopover}
          onClickDelete={deleteNode}
          label={
            <div>
              {fileUploadStatus === 'uploading' && file && (
                <div>
                  <div>{file.name}</div>
                  <div className={classes.uploadProgress}>
                    {filesize(file.size)} — <LoadingIndicator size={14} />{' '}
                    <span style={{ marginLeft: 4 }}>{progress}%</span>
                  </div>
                </div>
              )}
              {mobileUpload && mobileUpload.status === 'uploading' && (
                <div>
                  <div>{mobileUpload.fileName}</div>
                  <div className={classes.uploadProgress}>
                    {filesize(mobileUpload.fileSize)} —{' '}
                    <LoadingIndicator size={14} />{' '}
                    <span style={{ marginLeft: 4 }}>Uploading</span>
                  </div>
                </div>
              )}
              {fileUploadStatus === 'empty' &&
                !mobileUpload &&
                !fetchingAudio &&
                !fetchingError &&
                'Add audio'}
              {fetchingAudio && (
                <div className={classes.fetchingProgress}>
                  <LoadingIndicator size={14} /> Fetching file
                </div>
              )}
              {fetchingError && fileUploadStatus !== 'uploading' && (
                <Typography color="error" variant="caption">
                  {fetchingError}
                </Typography>
              )}
              {error && fileUploadStatus === 'error' && (
                <Typography color="error" variant="caption">
                  {error}
                </Typography>
              )}
            </div>
          }
          icon={<InsertAudio />}
        />
      )}
      {element.url && (
        <div contentEditable={false}>
          <Player
            config={{ file: { forceAudio: true } }}
            controls
            url={element.url as string}
            width="100%"
            className={classes.player}
          />
        </div>
      )}
      {element.url && (
        <div className={classes.toolbar}>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph above"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphAbove(element);
            }}
          >
            <AddBlockAbove />
          </EditorToolbarButton>
          <EditorToolbarButton
            contrast
            tooltip="Add a paragraph below"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              editor.insertParagraphBelow(element);
            }}
          >
            <AddBlockBelow />
          </EditorToolbarButton>
          <EditorToolbarButton contrast tooltip="Options" onClick={openMenu}>
            <MoreHoriz />
          </EditorToolbarButton>
        </div>
      )}
      <div
        className={classes.menuPopoverAnchor}
        contentEditable={false}
        ref={menuPopoverAnchor}
      />
      {children}
      <Popover
        open={open}
        anchorEl={divRef.current}
        onClose={closePopover}
        anchorOrigin={{
          vertical: element.url ? 'top' : 'bottom',
          horizontal: 'center',
        }}
      >
        {/** @ts-ignore */}
        <Tabs onChange={handleTabChange} value={tab}>
          <Tab label="Upload" value="upload" />
          <Tab label="Embed link" value="link" />
          <Tab label="From phone" value="phone" />
        </Tabs>
        <div className={classes.popoverContent}>
          {tab === 'upload' && (
            <>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={openFileSelectionDialog}
              >
                Chose a file
              </Button>
              {!error && (
                <Typography variant="caption" className={classes.helperText}>
                  The maximum file size is 10MB
                </Typography>
              )}
              {error && (
                <Typography
                  color="error"
                  variant="caption"
                  className={classes.helperText}
                >
                  {error}
                </Typography>
              )}
            </>
          )}
          {tab === 'link' && (
            <>
              <form className={classes.form} onSubmit={handleSubmitLink}>
                <TextField
                  autoFocus
                  placeholder="https://..."
                  className={classes.textField}
                  value={url}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUrl(event.target.value)
                  }
                />
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Embed audio
                </Button>
              </form>
              <Typography variant="caption" className={classes.helperText}>
                Works with .MP3s,.WAVs, and .OGGs
              </Typography>
            </>
          )}
          {tab === 'phone' && (
            <>
              <QRCode
                bgColor={theme.palette.background.paper}
                size={100}
                value={`${
                  process.env.REACT_APP_BASE_URL ||
                  'https://app.get21stnight.com'
                }/upload/audio/${element.id}`}
                className={classes.qrCode}
              />
              <Typography variant="caption" className={classes.helperText}>
                Scan the QR code above with your phone and then select an audio
                file
              </Typography>
            </>
          )}
        </div>
      </Popover>
      <Popover
        open={menuOpen}
        anchorEl={menuPopoverAnchor.current}
        onClose={closeMenu}
      >
        <List>
          <MenuItem
            primaryText="Open link"
            icon={<OpenInNew fontSize="small" />}
            onClick={handleClickOpenLink}
          />
          <MenuItem
            primaryText="Replace"
            icon={<Replace fontSize="small" />}
            onClick={handleClickReplace}
          />
          <MenuItem
            primaryText="Delete"
            icon={<Delete fontSize="small" />}
            onClick={deleteNode}
          />
        </List>
      </Popover>
      {createPortal(
        <input
          ref={fileInput}
          type="file"
          accept="audio/*"
          className={classes.fileInput}
          onChange={onChange}
        />,
        document.body,
      )}
    </div>
  );
};
