import React from 'react';
import {
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@21st-night/styles';

export type ListItemIconProps = MuiListItemIconProps;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 42,
    },
  }),
);

export const ListItemIcon: React.FC<ListItemIconProps> = ({ ...other }) => {
  const classes = useStyles();
  return <MuiListItemIcon classes={classes} {...other} />;
};
