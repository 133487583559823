import { serializeWebViewEvent } from '../serializeWebViewEvent';
import { postMessageFromWebView } from '../postMessageFromWebView';
import { WebViewEventPayload, WebViewWindow } from '../../types';

declare let window: WebViewWindow;

export function postEventFromWebView(
  event: string,
  payload?: WebViewEventPayload,
): void {
  postMessageFromWebView(serializeWebViewEvent(event, payload));
}
