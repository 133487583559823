import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const RemoveBox: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19L3 5C3 3.89 3.9 3 5 3L19 3C20.1 3 21 3.89 21 5ZM12 13.414L14.1213 15.5353C14.5102 15.9242 15.1466 15.9242 15.5355 15.5353C15.9244 15.1464 15.9244 14.51 15.5355 14.1211L13.4142 11.9998L15.5355 9.87846C15.9244 9.48955 15.9244 8.85315 15.5355 8.46425C15.1466 8.07534 14.5102 8.07534 14.1213 8.46425L12 10.5856L9.87868 8.46425C9.48977 8.07534 8.85338 8.07534 8.46447 8.46425C8.07556 8.85315 8.07556 9.48955 8.46447 9.87846L10.5858 11.9998L8.46447 14.1211C8.07556 14.51 8.07556 15.1464 8.46447 15.5353C8.85338 15.9242 9.48977 15.9242 9.87868 15.5353L12 13.414Z"
      fill="inherit"
    />
  </SvgIcon>
);
