import React from 'react';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import { Typography, CheckboxProps, Paper, DeleteButton } from '@21st-night/ui';
import {
  TaskTemplateFormSlider,
  TaskTemplateFormSliderProps,
} from './TaskTemplateFormSlider';
import {
  TaskFormDaysOfTheWeek,
  TaskFormDaysOfTheWeekProps,
} from '../TaskForm/TaskFormDaysOfTheWeek';
import {
  TaskFormTextFields,
  TaskFormTextFieldsProps,
} from '../TaskForm/TaskFormTextFields';

export interface TaskTemplateFormProps
  extends TaskFormTextFieldsProps,
    React.HTMLAttributes<HTMLDivElement> {
  /**
   * The length of the study plan.
   */
  studyPlanLength: number;
  /**
   * Properties applied to the day selection Checkbox components.
   */
  DayCheckboxProps?: CheckboxProps;
  /**
   * Properties applied to the start-date hidden input field.
   */
  startInputProps?: TaskTemplateFormSliderProps['startInputProps'];
  /**
   * Properties applied to the end-date hidden input field.
   */
  endInputProps?: TaskTemplateFormSliderProps['endInputProps'];
  /**
   * Properties applied to the TaskTemplateFormSlider component.
   */
  DateRangeSliderProps?: Partial<TaskTemplateFormSliderProps>;
  /**
   * Properties applied to the DaysOfTheWeekProps component.
   */
  DaysOfTheWeekProps?: TaskFormDaysOfTheWeekProps;
  onClickDelete: () => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(3, 4),
      maxWidth: 600 + theme.spacing(4),
    },
    deleteButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    section: {
      marginBottom: theme.spacing(3),
    },
  }),
);

export const TaskTemplateForm: React.FC<TaskTemplateFormProps> = ({
  ActionInputProps = {},
  CountInputProps = {},
  ObjectInputProps = {},
  SourceInputProps = {},
  DayCheckboxProps = {},
  startInputProps = {},
  endInputProps = {},
  DateRangeSliderProps = {},
  DaysOfTheWeekProps = {},
  subtasks = [],
  onClickAddSubtask,
  onClickRemoveSubtask,
  onClickDelete,
  className,
  studyPlanLength,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <DeleteButton
        textButtonsSize="small"
        onConfirm={onClickDelete}
        className={classes.deleteButton}
      />
      <Typography variant="h6">Task</Typography>
      <div className={classes.section}>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          What does this task involve?
        </Typography>
        <TaskFormTextFields
          ActionInputProps={ActionInputProps}
          CountInputProps={CountInputProps}
          ObjectInputProps={ObjectInputProps}
          SourceInputProps={SourceInputProps}
          subtasks={subtasks}
          onClickAddSubtask={onClickAddSubtask}
          onClickRemoveSubtask={onClickRemoveSubtask}
        />
      </div>
      <div className={classes.section}>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          Which days of the week will you work on this task?
        </Typography>
        <TaskFormDaysOfTheWeek
          CheckboxProps={DayCheckboxProps}
          {...DaysOfTheWeekProps}
        />
      </div>
      <div className={classes.section}>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          From when to when will you work on this task?
        </Typography>

        <TaskTemplateFormSlider
          max={studyPlanLength}
          startInputProps={startInputProps}
          endInputProps={endInputProps}
          {...DateRangeSliderProps}
        />
      </div>
    </Paper>
  );
};
