import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';
import { TextInput, TextInputProps } from '../TextInput';

export type DatePickerProps = Omit<MuiDatePickerProps, 'renderInput' | 'error'>;

export const useStyles = makeStyles(theme =>
  createStyles({
    openPickerButton: {
      '&:hover': {
        backgroundColor: 'inherit',
        color: theme.palette.primary.main,
      },
    },
  }),
);

export const DatePicker: React.FC<DatePickerProps> = ({
  OpenPickerButtonProps,
  ...other
}) => {
  const classes = useStyles();
  return (
    <MuiDatePicker
      OpenPickerButtonProps={{
        disableRipple: true,
        classes: { root: classes.openPickerButton },
        ...OpenPickerButtonProps,
      }}
      renderInput={props => (
        <TextInput
          {...((props as unknown) as TextInputProps)}
          {...props.InputProps}
        />
      )}
      {...other}
    />
  );
};
