import {
  EditorDocument,
  EditorInlineElement,
  EditorTextNode,
  isElementOfType,
} from '@21st-night/editor';
import { Element } from 'slate';

export function removeCardLinkFromDocument(
  document: EditorDocument,
  cardId: string,
): EditorDocument {
  const removed = document.map(element => {
    let children: (Element | EditorTextNode)[] = [];
    if (element.children) {
      (element.children as (EditorInlineElement | EditorTextNode)[]).forEach(
        child => {
          if (isElementOfType(child, 'card-link') && child.cardId === cardId) {
            children = [...children, ...child.children];
          } else {
            children.push(child as EditorTextNode);
          }
        },
      );
    }

    return { ...element, children };
  });

  return removed as EditorDocument;
}
