import { EditorDocument, EditorDocumentMetadata } from '../../Editor.types';

export function addDocumentMetadata(
  document: EditorDocument,
  metadata: EditorDocumentMetadata | null,
): EditorDocument {
  if (!metadata) {
    return document;
  }

  if (document[0] && document[0].type === 'metadata') {
    return document;
  }

  const doc = [metadata, ...document];

  return doc;
}
