import { ThemeOptions, Theme as MuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import * as createPalette from '@material-ui/core/styles/createPalette';
import shadows from './shadows';

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    proficiency: {
      notStarted: string;
      needsWork: string;
      inProgress: string;
      mastered: string;
      retired: string;
    };
  }
  interface Palette {
    proficiency: {
      notStarted: string;
      needsWork: string;
      inProgress: string;
      mastered: string;
      retired: string;
    };
  }
}

export type Theme = MuiTheme;
export type ThemeVariant = 'light' | 'dark';

export function createThemeOptions(type: ThemeVariant): ThemeOptions {
  return {
    shadows,
    palette: {
      type: type,
      primary: blue,
      secondary: green,
      background: {},
      grey: grey,
      proficiency: {
        notStarted: '#9E9E9E',
        needsWork: '#FF5252',
        inProgress: '#F5A623',
        mastered: '#7ED321',
        retired: '#9F3D9C',
      },
    },
    typography: {
      h1: {
        fontSize: '2.25rem',
        fontWeight: 400,
        lineHeight: 1.3,
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: 1.3,
      },
      h3: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: 1.3,
      },
      h6: {
        fontWeight: 700,
        fontSize: '16px',
      },
      subtitle2: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
      },
      caption: {
        fontWeight: 'normal',
      },
    },
  };
}

export const theme: ThemeOptions = createThemeOptions('light');
export const lightTheme = theme;
export const darkTheme: ThemeOptions = createThemeOptions('dark');
