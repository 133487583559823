import React, { useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';

export interface EquationTextareaProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  autoSelect?: boolean;
}

export const EquationTextarea: React.FC<EquationTextareaProps> = ({
  autoSelect = true,
  ...other
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  function handleFocus(): void {
    setTimeout(() => {
      if (inputRef.current) {
        if (autoSelect) {
          inputRef.current.select();
        } else {
          inputRef.current.focus();
        }
      }
    });
  }

  return (
    <TextareaAutosize
      autoFocus
      onFocus={handleFocus}
      spellCheck={false}
      data-gramm_editor="false"
      style={{
        border: 0,
        backgroundColor: 'transparent',
        outline: 'none',
        fontSize: '14px',
        wordBreak: 'break-word',
        fontFamily: 'iawriter-mono, Nitti, Menlo, Courier, monospace',
        whiteSpace: 'pre-wrap',
        lineHeight: '22px',
        overflow: 'auto',
        maxHeight: '50vh',
        paddingTop: 3,
        paddingBottom: 3,
        width: '100%',
        resize: 'none',
      }}
      {...other}
      ref={inputRef}
    />
  );
};
