import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { IconButton, Spaced } from '@21st-night/ui';
import { Star } from '@21st-night/icons';
import { DeckItem } from '@21st-night/deck';
import { DeckNextReviewTag } from '../DeckNextReviewTag';
import { DeckRetiredTag } from '../DeckRetiredTag';
import { DeckCategoryTag } from '../DeckCategoryTag';
import { DeckSubcategoryTag } from '../DeckSubcategoryTag';

export interface DeckItemMetadataProps
  extends React.HTMLAttributes<HTMLDivElement> {
  item: DeckItem;
  readonly?: boolean;
  onClickStar?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      flexWrap: 'wrap',
    },
  }),
);

export const DeckItemMetadata: React.FC<DeckItemMetadataProps> = ({
  className,
  item,
  readonly,
  onClickStar,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Spaced className={cn(classes.root, className)} {...other}>
      {(!readonly || item.starred) && (
        <IconButton
          toggle
          size="small"
          activeColor="#FFAB00"
          disabled={readonly}
          onClick={!readonly ? onClickStar : undefined}
          active={item.starred}
        >
          <Star />
        </IconButton>
      )}
      {item.type === 'flashcard' ||
        (item.type === 'error-log' && (
          <>
            {item.nextReview && !item.retired && (
              <DeckNextReviewTag
                readonly={readonly}
                createdAt={item.createdAt}
                nextReview={item.nextReview}
                lastReview={item.lastReview}
              />
            )}
            {item.retired && <DeckRetiredTag readonly={readonly} />}
          </>
        ))}
      {item.category && (
        <DeckCategoryTag readonly={readonly} category={item.category} />
      )}
      {item.subcategories.map(subcategory => (
        <DeckSubcategoryTag
          readonly={readonly}
          key={subcategory}
          subcategory={subcategory}
        />
      ))}
    </Spaced>
  );
};
