import {
  toDate,
  QueryDocumentSnapshot,
  DocumentSnapshot,
} from '@21st-night/utils';
import { Note, NoteDocument } from '../../Notes.types';

export function deserializeNoteDocument(
  doc: QueryDocumentSnapshot | DocumentSnapshot,
): Note {
  const note = doc.data() as NoteDocument;

  return {
    ...note,
    createdAt: toDate(note.createdAt),
    // Legacy notes may be missing these
    type: note.type || 'note',
    id: doc.id,
  };
}
