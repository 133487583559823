import { BaseElement, BaseText } from 'slate';

export interface Element extends BaseElement {
  type: string;
  id: string;
}

export interface ImageElementDimensions {
  width: number;
  height: number;
  aspectRatio: number;
}

export interface ImageElementCrop {
  aspect?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  unit?: 'px' | '%';
}

export interface RichTextNode extends BaseText {
  b?: true;
  i?: true;
  u?: true;
  s?: true;
}

export interface LinkElement extends Element {
  type: 'link';
  url: string;
}

export interface EquationElement extends Element {
  type: 'equation-inline';
  tex: string;
}

export interface ClozeDeletionElement extends Element {
  type: 'cloze-deletion';
  hint?: string;
}

export interface CardLinkElement extends Element {
  type: 'card-link';
  cardId: string;
}

export interface MetadataElement extends Element {
  type: 'metadata';
  apiVersion: string;
  createdAt: Date;
  lastUpdatedAt: Date;
}

export interface ParagraphElement extends Element {
  type: 'paragraph';
}

export interface HeadingOneElement extends Element {
  type: 'h1';
}

export interface HeadingTwoElement extends Element {
  type: 'h2';
}

export interface BlockQuoteElement extends Element {
  type: 'blockquote';
}

export interface ImageElement extends Element {
  type: 'image';
  file?: string;
  crop?: ImageElementCrop;
  dimensions?: ImageElementDimensions;
  url?: string;
  originalUrl?: string;
  base64?: string;
}

export interface AudioElement extends Element {
  type: 'audio';
  file?: string;
  url?: string;
  originalUrl?: string;
  base64?: string;
}

export interface EquationBlockElement extends Element {
  type: 'tex';
  tex: string;
}

export interface CodeElement extends Element {
  type: 'code';
  language: string;
  children: BaseText[];
}

export interface VideoElement extends Element {
  type: 'video';
  url: string;
}

export interface OrderedListElement extends Element {
  type: 'ol';
  number: number;
}

export interface UnorderedListElement extends Element {
  type: 'ul';
}

export interface CardQuestionElement extends Element {
  type: 'card-question';
  error?: string;
}

export interface CardAnswerElement extends Element {
  type: 'card-answer';
  error?: string;
}

export interface CardExplanationElement extends Element {
  type: 'card-explanation';
}

export interface CardSummaryElement extends Element {
  type: 'card-summary';
}

export type EditorTextNode = BaseText;

export const INLINE_ELEMENT_TYPES = [
  'link',
  'equation-inline',
  'cloze-deletion',
  'card-link',
] as const;
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;
export type EditorInlineElementType = ElementType<typeof INLINE_ELEMENT_TYPES>;

export type EditorInlineElement =
  | LinkElement
  | EquationElement
  | ClozeDeletionElement
  | CardLinkElement;

export type EditorBlockElement =
  | MetadataElement
  | ParagraphElement
  | HeadingOneElement
  | HeadingTwoElement
  | BlockQuoteElement
  | EquationBlockElement
  | ImageElement
  | VideoElement
  | AudioElement
  | CodeElement
  | OrderedListElement
  | UnorderedListElement
  | CardQuestionElement
  | CardAnswerElement
  | CardExplanationElement
  | CardSummaryElement;

export const BLOCK_ELEMENT_TYPES = [
  'metadata',
  'paragraph',
  'h1',
  'h2',
  'blockquote',
  'ul',
  'ol',
  'image',
  'video',
  'audio',
  'tex',
  'code',
  'card-question',
  'card-answer',
  'card-explanation',
  'card-summary',
] as const;
type BlockElementType<
  T extends ReadonlyArray<unknown>
> = T extends ReadonlyArray<infer BlockElementType> ? BlockElementType : never;
export type EditorBlockElementType = BlockElementType<
  typeof BLOCK_ELEMENT_TYPES
>;

export interface EditorDocumentMetadata extends Element {
  type: 'metadata';
  createdAt: Date;
  lastUpdatedAt: Date;
  apiVersion: string;
}

export type EditorDocument = EditorBlockElement[];

export type EditorChangeHandler = (document: EditorDocument) => void;

export type MediaFileType = 'image' | 'audio';

export type MediaFileReference = {
  id: string;
  url: string;
  type: MediaFileType;
};

export interface ElementMap {
  'card-link': CardLinkElement;
  paragraph: ParagraphElement;
}
