import { WebViewEvent } from '../../types';
import { parseEventMessage } from '../parseEventMessage';

export function parseWebViewEvent(messageEvent: MessageEvent): WebViewEvent {
  if (!messageEvent || typeof messageEvent.data !== 'string') {
    return { type: '', payload: null };
  }

  return parseEventMessage(messageEvent.data);
}
