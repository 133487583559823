import React, { useState } from 'react';
import { makeStyles, createStyles, cn } from '@21st-night/styles';
import { Paper, IconButton, Buttons, Tooltip } from '@21st-night/ui';
import { Edit, Delete } from '@21st-night/icons';
import {
  InlineEditor,
  InlineEditorProps,
  RichText,
  isDocumentEmpty,
} from '@21st-night/editor-web';

export interface ImageOcclusionBoxProps {
  clickToAddContent?: boolean;
  onChange: InlineEditorProps['onChange'];
  value: InlineEditorProps['value'];
  editMode: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      position: 'relative',
      overflow: 'scroll',
      boxSizing: 'border-box',
      padding: theme.spacing(1),
      borderRadius: 3,
      height: '100%',
      border: '2px solid rgba(15, 15, 15, 0.05)',
      fontSize: theme.typography.pxToRem(12),
    },
    withContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addContentButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
      outline: 'none',
    },
    buttons: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }),
);

export const ImageOcclusionBox = React.forwardRef<
  HTMLDivElement,
  ImageOcclusionBoxProps
>(
  (
    {
      value,
      onChange,
      editMode,
      onClickEdit,
      onClickDelete,
      clickToAddContent = false,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState(false);
    const hasContent = !isDocumentEmpty(value);

    return (
      <Paper
        ref={ref}
        elevation={0}
        className={cn(
          classes.paper,
          hasContent && !editMode && classes.withContent,
        )}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        {mouseOver && !editMode && (
          <Buttons className={classes.buttons}>
            {!clickToAddContent && (
              <Tooltip
                title={
                  hasContent
                    ? 'Edit content'
                    : 'Add content (to replace existing label or to support quiz mode)'
                }
              >
                <IconButton size="small" onClick={onClickEdit}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Remove">
              <IconButton size="small" onClick={onClickDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Buttons>
        )}
        {editMode && (
          <InlineEditor
            autoFocus
            minHeight={84}
            hideToolbar
            value={value}
            onChange={onChange}
          />
        )}
        {!editMode && hasContent && <RichText imageUrl="" document={value} />}
        {clickToAddContent && !editMode && !hasContent && (
          <div
            role="button"
            tabIndex={0}
            onClick={onClickEdit}
            className={classes.addContentButton}
          >
            Click to add label
          </div>
        )}
      </Paper>
    );
  },
);

ImageOcclusionBox.displayName = 'ImageOcclusionBox';
