import React from 'react';
import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps,
} from '@material-ui/core';

export type PaperProps = MuiPaperProps;

export const Paper: React.FC<PaperProps> = ({ ...other }) => {
  return <MuiPaper {...other} />;
};
