import React, { useMemo, useState } from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { makeStyles, useTheme, cn } from '@21st-night/styles';
import { Statistic } from '../Statistic';

export interface DataItem {
  date: Date;
  retire: number;
  wrong: number;
  'more-work': number;
  correct: number;
}

export interface RatingsAreaChartProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data: DataItem[];
  syncId?: string;
}

interface RenderDotProps {
  payload: DataItem;
  cx: number;
  cy: number;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  ratingValue: {
    paddingRight: 12,
  },
}));

export const RatingsAreaChart: React.FC<RatingsAreaChartProps> = ({
  data,
  syncId,
  className,
  ...other
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const totals = useMemo(
    () =>
      data.reduce(
        (tots, day) => ({
          ...tots,
          retire: tots.retire + day.retire,
          correct: tots.correct + day.correct,
          'more-work': tots['more-work'] + day['more-work'],
          wrong: tots.wrong + day.wrong,
        }),
        { date: new Date(), retire: 0, correct: 0, 'more-work': 0, wrong: 0 },
      ),
    [data],
  );
  const [value, setValue] = useState(totals);
  const [valueLabel, setValueLabel] = useState<Date | null>(null);

  function renderDot(props: RenderDotProps) {
    setValue(props.payload);
    setValueLabel(props.payload.date);

    return (
      <svg height="100%" width="100%" style={{ zIndex: 10 }}>
        <circle
          cx={props.cx}
          cy={props.cy}
          r="4"
          strokeWidth="0"
          fill="#2D7EF6"
        />
      </svg>
    );
  }

  function handleMouseLeave() {
    setValue(totals);
    setValueLabel(null);
  }

  function renderValue(val: DataItem) {
    return (
      <span>
        {!val.retire && !val.correct && !val['more-work'] && !val.wrong && (
          <span className={classes.ratingValue}>No reviews</span>
        )}
        {val.retire > 0 && (
          <span
            className={classes.ratingValue}
            style={{ color: theme.palette.proficiency.notStarted }}
          >
            {val.retire} retire
          </span>
        )}
        {val.correct > 0 && (
          <span
            className={classes.ratingValue}
            style={{ color: theme.palette.proficiency.mastered }}
          >
            {val.correct} correct
          </span>
        )}
        {val['more-work'] > 0 && (
          <span
            className={classes.ratingValue}
            style={{ color: theme.palette.proficiency.inProgress }}
          >
            {val['more-work']} tricky
          </span>
        )}
        {val.wrong > 0 && (
          <span
            className={classes.ratingValue}
            style={{ color: theme.palette.proficiency.needsWork }}
          >
            {val.wrong} wrong
          </span>
        )}
      </span>
    );
  }

  return (
    <div className={cn(classes.root, className)} {...other}>
      <Statistic
        title="Card ratings"
        value={renderValue(value)}
        label={valueLabel ? dayjs(valueLabel).format('MMM D') : ''}
      />
      <ResponsiveContainer width="100%" height={230}>
        <AreaChart
          syncId={syncId}
          data={data}
          margin={{ top: 40, left: 4, right: 4 }}
          onMouseLeave={handleMouseLeave}
        >
          <CartesianGrid horizontal={false} stroke="#EBEBEB" />
          <Area
            stackId="1"
            dataKey="wrong"
            stroke={theme.palette.proficiency.needsWork}
            strokeWidth={2}
            fill={theme.palette.proficiency.needsWork}
            fillOpacity={0.75}
            activeDot={false}
            style={{ zIndex: 5 }}
          />
          <Area
            stackId="1"
            dataKey="more-work"
            stroke={theme.palette.proficiency.inProgress}
            strokeWidth={2}
            fill={theme.palette.proficiency.inProgress}
            fillOpacity={0.75}
            activeDot={false}
            style={{ zIndex: 5 }}
          />
          <Area
            stackId="1"
            dataKey="correct"
            stroke={theme.palette.proficiency.mastered}
            strokeWidth={2}
            fill={theme.palette.proficiency.mastered}
            fillOpacity={0.75}
            activeDot={false}
            style={{ zIndex: 5 }}
          />
          <Tooltip
            contentStyle={{ display: 'none' }}
            cursor={{
              strokeWidth: 1,
              strokeDasharray: '1',
              stroke: '#8d8d8d',
            }}
          />
          <Area
            stackId="1"
            dataKey="retire"
            stroke={theme.palette.proficiency.retired}
            strokeWidth={2}
            fill={theme.palette.proficiency.retired}
            fillOpacity={0.75}
            activeDot={renderDot}
            style={{ zIndex: 5 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
