import React, { useCallback } from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { MenuItem, Select, SelectProps, ListSubheader } from '@21st-night/ui';
import { DeckSortValue, DeckSortOption } from '@21st-night/deck';

export interface DeckSortSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange?: (value: DeckSortValue) => void;
}

export const useStyles = makeStyles(() =>
  createStyles({
    subheader: {
      userSelect: 'none',
      pointerEvents: 'none',
    },
  }),
);

const globalOptions: DeckSortOption[] = [
  {
    label: 'Newest first',
    value: 'createdAt_desc',
    group: 'global',
  },
  {
    label: 'Oldest first',
    value: 'createdAt_asc',
    group: 'global',
  },
];

const cardOptions: DeckSortOption[] = [
  {
    label: 'Due next first',
    value: 'nextReview_asc',
    group: 'cards',
  },
  {
    label: 'Last reviewed first',
    value: 'lastReview_desc',
    group: 'cards',
  },
  {
    label: 'Highest proficiency',
    value: 'proficiency_desc',
    group: 'cards',
  },
  {
    label: 'Lowest proficiency',
    value: 'proficiency_asc',
    group: 'cards',
  },
];

export const DeckSortSelect: React.FC<DeckSortSelectProps> = ({
  onChange,
  ...other
}) => {
  const classes = useStyles();

  const handleChange: SelectProps['onChange'] = useCallback(
    event => {
      if (onChange) {
        onChange(event.target.value as DeckSortValue);
      }
    },
    [onChange],
  );

  return (
    <Select onChange={handleChange} {...other}>
      {globalOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      <ListSubheader className={classes.subheader}>Cards</ListSubheader>
      {cardOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
