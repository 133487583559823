import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';

export interface TaskTemplateFormSliderCountMarkProps {
  days: number[];
  count: number;
  start: number;
  end: number;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
  }),
);

function tasksPerReviewDay(
  start: number,
  end: number,
  tasksCount: number,
  activeDaysCount: number,
): string {
  const daysCount = Math.round(((end - start) / 7) * activeDaysCount);
  const max = Math.ceil(tasksCount / daysCount || 1);
  const min = Math.floor(tasksCount / daysCount || 1);

  if (min !== max && min !== 0) {
    return `${min}-${max} tasks per review day`;
  }

  return `${max} task${max !== 1 ? 's' : ''} per review day`;
}

export const TaskTemplateFormSliderCountMark: React.FC<TaskTemplateFormSliderCountMarkProps> = ({
  days,
  count,
  start,
  end,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {count > 0 && days.length > 0
        ? tasksPerReviewDay(start, end, count, days.length)
        : 'No review days'}
    </span>
  );
};
