import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { Paper, PaperProps, InputBase, InputBaseProps } from '@21st-night/ui';

export interface ReviewAnswerFieldProps
  extends Omit<PaperProps, 'onChange' | 'value'> {
  inputProps?: InputBaseProps['inputProps'];
  onChange?: InputBaseProps['onChange'];
  value?: InputBaseProps['value'];
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(1, 2),
    },
    input: {
      fontSize: '1.2em',
    },
  }),
);

export const ReviewAnswerField: React.FC<ReviewAnswerFieldProps> = ({
  className,
  inputProps,
  onChange,
  value,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Paper className={cn(classes.root, className)} {...other}>
      <InputBase
        fullWidth
        autoFocus
        placeholder="Write answer here..."
        className={classes.input}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
      />
    </Paper>
  );
};
