import React from 'react';
import { Editor as SlateEditor, Range, Node, Selection } from 'slate';
import {
  Transforms,
  isBlockAboveEmpty,
  getBlockAbove,
  Element,
} from '@braindrop-editor/core';
import { isText } from '@21st-night/editor';
import { Editor } from '../Editor';
import { EditorToolbarDropdown } from '../components/EditorToolbarDropdown';
import { isFocusElementOfType } from '../api';

export interface ToolbarInsertMediaDropdownProps {
  editor: Editor;
  lastSelection: Selection;
}

function focusEditor(editor: SlateEditor, lastSelection: Range | null) {
  if (!editor.selection && !lastSelection) {
    const [node, path] = Node.last(editor, []);
    let offset = 0;
    if (isText(node)) {
      offset = node.text.length;
    }
    Transforms.select(editor, {
      focus: { path, offset },
      anchor: { path, offset },
    });
  } else if (lastSelection) {
    Transforms.select(editor, lastSelection);
  }
}

export const ToolbarInsertMediaDropdown: React.FC<ToolbarInsertMediaDropdownProps> = ({
  lastSelection,
  editor,
}) => {
  return (
    <EditorToolbarDropdown
      label="Insert"
      items={[
        {
          id: 'upload',
          group: 'Image',
          title: 'Upload an image',
          shortcut: 'ctrl+alt+8',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoImage();
            } else {
              editor.insertImage();
            }
          },
        },
        {
          id: 'link',
          group: 'Image',
          title: 'Embed an image from a link',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoImage('link');
            } else {
              editor.insertImage('link');
            }
          },
        },
        {
          id: 'mobile',
          group: 'Image',
          title: 'Upload an image from your phone',
          subtitle: 'Scan a QR code, no app needed',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoImage('phone');
            } else {
              editor.insertImage('phone');
            }
          },
        },
        {
          id: 'extract-text',
          group: 'Image',
          title: 'Extract text from an image',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoImage('extract');
            } else {
              editor.insertImage('extract');
            }
          },
        },
        {
          id: 'video-link',
          group: 'Video',
          title: 'Embed a video from a link',
          shortcut: 'ctrl+alt+7',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoVideo();
            } else {
              editor.insertVideo();
            }
          },
        },
        {
          id: 'audio-upload',
          group: 'Audio',
          title: 'Upload an audio file',
          shortcut: 'ctrl+alt+9',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoAudio('upload');
            } else {
              editor.insertAudio('upload');
            }
          },
        },
        {
          id: 'audio-link',
          group: 'Audio',
          title: 'Insert an audio file from a link',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoAudio('link');
            } else {
              editor.insertAudio('link');
            }
          },
        },
        {
          id: 'audio-mobile',
          group: 'Audio',
          title: 'Upload an audio file from your phone',
          subtitle: 'Scan a QR code, no app needed',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isBlockAboveEmpty(editor)) {
              editor.turnIntoAudio('phone');
            } else {
              editor.insertAudio('phone');
            }
          },
        },
        {
          id: 'equation-inline',
          group: 'Equation',
          title: 'Insert an inline equation',
          shortcut: 'ctrl+Shift+E',
          action: () => {
            focusEditor(editor, lastSelection);
            editor.insertInlineEquation();
          },
        },
        {
          id: 'tex',
          group: 'Equation',
          title: 'Insert a TeX block',
          shortcut: 'ctrl+alt+0',
          action: () => {
            focusEditor(editor, lastSelection);
            if (isFocusElementOfType(editor, 'tex')) {
              Transforms.setNodes(editor, { type: 'paragraph' });
            } else {
              if (isBlockAboveEmpty(editor)) {
                const block = getBlockAbove(editor);
                editor.turnIntoBlockEquation(block[0] as Element);
              } else {
                editor.insertBlockEquation();
              }
            }
          },
        },
      ]}
    />
  );
};
