import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@21st-night/styles';
import isHotkey, { HotKeyOptions } from 'is-hotkey';
import {
  Help,
  Root,
  SquareRoot,
  Squared,
  Power,
  Subscript,
  InsertFraction,
  TurnIntoFraction,
} from '@21st-night/icons';
import { useUI } from '@braindrop-editor/core';
// Import JQuery, required for the functioning of the equation editor
import $ from 'jquery';
// Import the styles from the Mathquill editor
import 'mathquill/build/mathquill.css';
import './equation-input.css';
import { EditorToolbarButton } from '../../../components/EditorToolbarButton';
import { EquationInputHelp } from './EquationInputHelp';

interface MathField {
  latex(): string;
  latex(code: string): void;
  focus(): void;
  blur(): void;
  reflow(): void;
  select(): void;
  cmd(latex: string): void;
  write(value: string): void;
  typedText(value: string): void;
  keystroke(value: string): void;
  moveToLeftEnd(): void;
  moveToRightEnd(): void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.jQuery = $;

// Import Mathquill editor
require('mathquill/build/mathquill');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
const mathQuill = window.MathQuill.getInterface(2);

const useStyles = makeStyles(theme => ({
  popover: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 3,
    padding: 2,
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: 2,
    },
    boxShadow:
      'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
  },
  table: {
    '& > :nth-child(2n)': {
      backgroundColor: '#f6f8fa',
    },
  },
  tr: {
    borderTop: '1px solid #dfe2e5',
  },
  trStripped: {
    backgroundColor: '#f6f8fa',
  },
}));

export interface EquationInputProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, 'onChange'> {
  focus?: boolean;
  focused: boolean;
  highlight?: boolean;
  onChange(latex: string): void;
  onMoveOutLeft(): void;
  onMoveOutRight(): void;
  onModOutLeft(): void;
  onModOutRight(): void;
  onDeleteOutLeft(value: string): void;
  onDeleteOutRight(value: string): void;
  value: string;
}

export const EquationInput: React.FC<EquationInputProps> = ({
  focus,
  focused,
  highlight,
  onChange,
  onDeleteOutLeft,
  onDeleteOutRight,
  onMoveOutLeft,
  onMoveOutRight,
  onModOutLeft,
  onModOutRight,
  value,
  ...other
}) => {
  const classes = useStyles();
  const { Popover } = useUI();
  const element = useRef<HTMLSpanElement>(null);
  const mathField = useRef<MathField>();
  const [helpOpen, setHelpOpen] = useState(false);

  useEffect(() => {
    if (focus && mathField.current) {
      mathField.current.focus();
    } else if (mathField.current) {
      mathField.current.blur();
    }
  }, [focus]);

  useEffect(() => {
    const field = mathQuill.MathField(element.current, config);
    field.latex(value || '');
    mathField.current = field;
    setTimeout(() => {
      if (!value) {
        field.focus();
      }
    });
  }, []);

  const config = {
    autoCommands: 'pi theta sqrt sum prod alpha beta gamma rho dot',
    autoOperatorNames: 'sin cos tan',
    // substituteTextarea: () => textarea,
    handlers: {
      edit: (field: MathField) => {
        onChange(field.latex());
      },
      moveOutOf: (direction: 1 | -1) => {
        if (mathField.current) {
          mathField.current.blur();
        }
        direction === -1 ? onMoveOutLeft() : onMoveOutRight();
      },
      deleteOutOf: (direction: 1 | -1) => {
        direction === -1 ? onDeleteOutLeft(value) : onDeleteOutRight(value);
      },
    },
  };

  function applyCommand(command: string): () => void {
    return () => {
      if (mathField.current) {
        mathField.current.cmd(command);
        mathField.current.focus();
      }
    };
  }

  function writeLatex(latex: string): () => void {
    return () => {
      if (mathField.current) {
        mathField.current.write(latex);
        mathField.current.focus();
      }
    };
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (mathField.current) {
      const options = (event as unknown) as HotKeyOptions;
      if (isHotkey('alt+ArrowRight', options)) {
        mathField.current.moveToRightEnd();
      } else if (isHotkey('alt+ArrowLeft', options)) {
        mathField.current.moveToLeftEnd();
      } else if (isHotkey('mod+ArrowRight', options)) {
        event.preventDefault();
        event.stopPropagation();
        onModOutRight();
      } else if (isHotkey('mod+ArrowLeft', options)) {
        event.preventDefault();
        event.stopPropagation();
        onModOutLeft();
      }
    }
  }

  function openHelp() {
    setHelpOpen(true);
  }

  function closeHelp() {
    setHelpOpen(false);
  }

  return (
    <>
      <Popover
        disableBackdrop
        open={focused && !!element.current}
        anchorEl={element.current}
        onClose={() => null}
      >
        <div className={classes.popover}>
          <EditorToolbarButton
            tooltip="Square root"
            shortcut="\sqrt"
            tooltipPlacement="bottom"
            onClick={applyCommand('\\sqrt')}
          >
            <SquareRoot />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="nth root"
            shortcut="\nthroot"
            tooltipPlacement="bottom"
            onClick={applyCommand('\\nthroot')}
          >
            <Root />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="Turn into fraction"
            shortcut="/"
            tooltipPlacement="bottom"
            onClick={applyCommand('/')}
          >
            <TurnIntoFraction />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="Insert fraction"
            shortcut="\frac"
            tooltipPlacement="bottom"
            onClick={applyCommand('\\frac')}
          >
            <InsertFraction />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="Squared"
            shortcut="^2"
            tooltipPlacement="bottom"
            onClick={writeLatex('^2')}
          >
            <Squared />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="Exponent"
            shortcut="^"
            tooltipPlacement="bottom"
            onClick={applyCommand('^')}
          >
            <Power />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="Subscript"
            shortcut="_"
            tooltipPlacement="bottom"
            onClick={applyCommand('_')}
          >
            <Subscript />
          </EditorToolbarButton>
          <EditorToolbarButton
            tooltip="Help"
            shortcut="View all functions"
            tooltipPlacement="bottom"
            onClick={openHelp}
          >
            <Help />
          </EditorToolbarButton>
        </div>
      </Popover>
      <EquationInputHelp open={helpOpen} onClose={closeHelp} />
      <span
        onKeyDown={handleKeyDown}
        style={highlight ? { borderRadius: 0, backgroundColor: '#b3d7ff' } : {}}
        ref={element}
        {...other}
      />
    </>
  );
};
