import React from 'react';
import { Range } from 'slate';
import { Equation } from '@21st-night/icons';
import {
  EditorToolbarButton as Button,
  EditorToolbarButtonProps,
} from '../components/EditorToolbarButton';
import { EditorWithInlineEquationPlugin } from '../plugins/plugin-equation';
import { isFocusElementOfType } from '../api';

export interface ToolbarInsertInlineEquationButtonProps
  extends Partial<EditorToolbarButtonProps> {
  editor: EditorWithInlineEquationPlugin;
}

export const ToolbarInsertInlineEquationButton: React.FC<ToolbarInsertInlineEquationButtonProps> = ({
  editor,
  ...other
}) => {
  return (
    <Button
      tooltip="Insert equation"
      shortcut="ctrl+Shift+E"
      active={
        isFocusElementOfType(editor, 'equation-inline') &&
        !!editor.selection &&
        !!Range.isCollapsed(editor.selection)
      }
      onMouseDown={(event): void => {
        event.preventDefault();
        editor.insertInlineEquation();
      }}
      {...other}
    >
      <Equation />
    </Button>
  );
};
