import dayjs from 'dayjs';
import {
  StudyPlanDay,
  StudyPlanItem,
  StudyPlanWeek,
  StudyPlanWeekDay,
  StudyPlanWeekItem,
} from '../../types';

function generateStudyPlanWeekItem(
  item: StudyPlanItem,
  items: Record<string, StudyPlanItem>,
): StudyPlanWeekItem {
  return {
    ...item,
    subItems: item.subItems.reduce((subItems, itemId) => {
      if (items[itemId]) {
        return [...subItems, item];
      }

      return subItems;
    }, [] as StudyPlanItem[]),
  };
}

function generateStudyPlanWeekDay(
  day: StudyPlanDay,
  items: Record<string, StudyPlanItem>,
): StudyPlanWeekDay {
  return {
    ...day,
    isPast: dayjs(day.date).isBefore(new Date(), 'date'),
    items: day.items.reduce((dayItems, itemId) => {
      if (items[itemId]) {
        return [...dayItems, generateStudyPlanWeekItem(items[itemId], items)];
      }

      return dayItems;
    }, [] as StudyPlanWeekItem[]),
  };
}

export function generateStudyPlanWeeks(
  days: Record<string, StudyPlanDay>,
  items: Record<string, StudyPlanItem>,
  startWeekOn: number,
): StudyPlanWeek[] {
  const orderedDays = Object.values(days).sort(
    (a: StudyPlanDay, b: StudyPlanDay) => a.date.getTime() - b.date.getTime(),
  );

  return orderedDays.reduce((weeks: StudyPlanWeek[], day: StudyPlanDay) => {
    const weekday = day.date.getDay();
    const isNewWeek = weekday === startWeekOn;

    if (isNewWeek || weeks.length === 0) {
      const endDate = dayjs(day.date).add(6, 'day').toDate();
      const week: StudyPlanWeek = {
        number: weeks.length + 1,
        isPast: dayjs(endDate).isBefore(new Date(), 'date'),
        startDate: day.date,
        endDate,
        days: [generateStudyPlanWeekDay(day, items)],
      };
      return [...weeks, week];
    }

    weeks[weeks.length - 1].days.push(generateStudyPlanWeekDay(day, items));

    return weeks;
  }, []);
}
