import { DB } from '@21st-night/utils';
import { StudyPlanTask } from '../../types';

export function updateStudyPlanTask(
  db: DB,
  userId: string,
  id: string,
  data: Partial<StudyPlanTask>,
): Promise<void> {
  return db
    .collection('users')
    .doc(userId)
    .collection('study-plan')
    .doc('plan')
    .collection('tasks')
    .doc(id)
    .update(data);
}
