import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { Paper } from '@21st-night/ui';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'relative',
      overflowY: 'scroll',
      padding: theme.spacing(2, 2, 0, 2),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow:
        'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
    },
    button: {
      position: 'absolute',
      bottom: 2,
      right: theme.spacing(1),
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.primary.main,
        background: 'none',
      },
    },
  }),
);

export const DeckFilterBarPaper: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      {children}
      {/* <Button
        size="small"
        variant="text"
        color="inherit"
        className={classes.button}
      >
        Edit categories
      </Button> */}
    </Paper>
  );
};
