/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';
import { Editor } from '@braindrop-editor/core';
import { components as muiComponents } from '@braindrop-editor/material-ui';
import components from './components';
import {
  stripDocumentMetadata,
  addDocumentMetadata,
  EditorDocument,
} from '@21st-night/editor';
import { corePlugins, CoreEditorProps } from './core-editor-plugins';
import {
  createEditorStylePlugin,
  EditorStylePluginOptions,
} from './plugins/plugin-editor-style';

export interface BaseEditorProps
  extends CoreEditorProps,
    EditorStylePluginOptions {}

export const BaseEditor: React.FC<BaseEditorProps> = ({
  children,
  onChange,
  value: valueProp,
  plugins: pluginsProp = [],
  minHeight,
  maxHeight,
  placeholder,
  autoFocus,
  readOnly,
  className,
  style,
}) => {
  const [value, metadata] = stripDocumentMetadata(valueProp);

  const StylePlugin = useMemo(
    () =>
      createEditorStylePlugin({
        minHeight,
        maxHeight,
        placeholder,
        autoFocus,
        readOnly,
        className,
        style,
      }),
    [],
  );

  return (
    <Editor
      // @ts-ignore
      components={{ ...muiComponents, ...components }}
      plugins={[...corePlugins, StylePlugin, ...pluginsProp]}
      onChange={nextValue =>
        onChange(addDocumentMetadata(nextValue as EditorDocument, metadata))
      }
      value={value}
    >
      {children}
    </Editor>
  );
};
