import React, { useCallback } from 'react';
import { createContext, useFirebase } from '@21st-night/utils';
import * as api from '../api';
import { OnboardingAnswers, OnboardingStepId } from '../onboarding.types';
import {
  OnboardingState,
  useOnboardingState,
} from '../OnboardingStateProvider';

export interface OnboardingProviderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  userId: string;
}

export type CompleteStep = (step: OnboardingStepId) => Promise<void>;
export type CompleteOnboarding = () => Promise<void>;
export type SetUserSegmentation = (data: OnboardingAnswers) => Promise<void>;

export interface OnboardingContext extends OnboardingState {
  completeStep: CompleteStep;
  completeOnboarding: CompleteOnboarding;
  setUserSegmentation: SetUserSegmentation;
}

const [hook, Provider] = createContext<OnboardingContext>();

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
  userId,
}) => {
  const { db } = useFirebase();
  const state = useOnboardingState();

  const completeStep: CompleteStep = useCallback(
    step => {
      state.completeStep(step);
      return api.completeStep(db, userId, step);
    },
    [db, userId, state.completeStep],
  );

  const completeOnboarding: CompleteOnboarding = useCallback(() => {
    state.completeOnboarding();
    return api.completeOnboarding(db, userId);
  }, [db, userId, state.completeOnboarding]);

  const setUserSegmentation: SetUserSegmentation = useCallback(
    answers =>
      api.setUserSegmentation(
        db,
        userId,
        api.serializeSegmentationAnswers(answers),
      ),
    [db, userId],
  );

  return (
    <Provider
      value={{
        ...state,
        completeStep,
        completeOnboarding,
        setUserSegmentation,
      }}
    >
      {children}
    </Provider>
  );
};

export const useOnboarding = hook;
