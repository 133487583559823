import React from 'react';
import { cn, makeStyles, createStyles } from '@21st-night/styles';
import { SplitViewListItem } from './useSplitViewList';
import { Tooltip } from '../Tooltip';

export type SplitViewListItemPosition = 'left' | 'right' | 'full-width';

export interface SplitViewListProps<T extends SplitViewListItem>
  extends React.HTMLAttributes<HTMLDivElement> {
  items: (T | [T, T[]])[];
  renderItem: (
    item: T,
    index: number,
    position: SplitViewListItemPosition,
    opposite: null | string | string[],
  ) => React.ReactNode;
  renderSplitButton?: (item: T, index: number) => React.ReactNode;
  renderAddSplitButton?: (item: T, index: number) => React.ReactNode;
  renderCloseButton?: (leftId: string, rightId: string) => React.ReactNode;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    row: {
      display: 'flex',
      flex: 1,
      width: '100%',
    },
    fullWidthItem: {
      width: '100%',
      '&:hover': {
        '& $splitButton': {
          display: 'block',
        },
      },
    },
    fullWidthItemContent: {
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
      maxWidth: 800,
    },
    splitItemContentLeft: {
      marginLeft: 'auto',
      marginBottom: theme.spacing(2),
      maxWidth: 800,
      width: '100%',
    },
    splitItemContentRight: {
      position: 'relative',
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
      maxWidth: 800,
      width: '100%',
    },
    splitItemLeft: {
      flex: 0.5,
      paddingRight: theme.spacing(1),
      display: 'flex',
    },
    splitItemRight: {
      flex: 0.5,
      paddingLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        '& $closeButton': {
          display: 'block',
        },
      },
    },
    splitButton: {
      display: 'none',
      position: 'absolute',
      right: -20,
      top: 'calc(50% - 20px)',
    },
    additionalSplitButton: {
      marginBottom: theme.spacing(4),
      alignSelf: 'center',
    },
    closeButton: {
      display: 'none',
      position: 'absolute',
      right: -12,
      top: -12,
    },
  }),
);

export const SplitViewList: React.FC<SplitViewListProps<any>> = ({
  className,
  items,
  renderItem,
  renderSplitButton,
  renderAddSplitButton,
  renderCloseButton,
  ...other
}) => {
  const classes = useStyles();

  const renderRow = <T extends SplitViewListItem>(
    row: T | [T, T[]],
    index: number,
  ) =>
    Array.isArray(row) ? (
      <div className={classes.row}>
        <div className={classes.splitItemLeft}>
          <div className={classes.splitItemContentLeft}>
            {renderItem(
              row[0],
              index,
              'left',
              row[1].map(item => item.id),
            )}
          </div>
        </div>
        <div className={classes.splitItemRight}>
          {row[1].map(item => (
            <div key={item.id} className={classes.splitItemContentRight}>
              {renderCloseButton && (
                <div className={classes.closeButton}>
                  {renderCloseButton(row[0].id, item.id)}
                </div>
              )}
              {renderItem(item, index, 'right', row[0].id)}
            </div>
          ))}
          <div className={classes.additionalSplitButton}>
            <Tooltip title="Add another item" placement="top">
              <div>
                {renderAddSplitButton && renderAddSplitButton(row[0], index)}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.fullWidthItem}>
        <div className={classes.fullWidthItemContent}>
          {renderItem(row, index, 'full-width', null)}
          {renderSplitButton && (
            <div className={classes.splitButton}>
              <div>{renderSplitButton(row, index)}</div>
            </div>
          )}
        </div>
      </div>
    );

  return (
    <div className={cn(classes.root, className)} {...other}>
      {items.map((item, index) => renderRow(item, index))}
    </div>
  );
};
