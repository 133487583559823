import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeckNote } from '../updateDeckNote';

export function addSubcategoryToNote(
  db: DB,
  deckRef: DocumentReference,
  noteId: string,
  subcategory: string,
): Promise<void> {
  return updateDeckNote(deckRef, noteId, {
    subcategories: db.arrayUnion(subcategory),
  });
}
