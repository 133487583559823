import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { Slider, Typography } from '@21st-night/ui';

export interface OnboardingQuestionSliderProps {
  onChange: (value: number | 'unkown') => void;
  defaultValue: number | 'unkown';
  min: number;
  max: number;
  minSuffix?: string;
  maxSuffix?: string;
  label: string;
  helperText?: string;
}

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  slider: {
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },
  helperText: {
    color: theme.palette.text.hint,
    paddingLeft: theme.spacing(1),
  },
  mark: {
    display: 'none',
  },
}));

type ChangeEvent = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  event: React.ChangeEvent<{}>,
  value: number | number[],
) => void;

interface Mark {
  value: number;
  label?: string;
}

function generateValues(
  min: number,
  max: number,
  minSuffix: string,
  maxSuffix: string,
): Mark[] {
  const range = Math.abs(max - min);
  const unkownValue = min - Math.round(range * 0.2);
  const values: Mark[] = [{ value: unkownValue, label: 'Unknown' }];
  let value = min;

  while (value <= max) {
    values.push({ value });
    value += 1;
  }

  values[1].label = `${values[1].value}${minSuffix}`;
  const last = values.length - 1;
  values[last].label = `${values[last].value}${maxSuffix}`;

  return values;
}

function valuetext(value: number) {
  return `${value}`;
}

function convertDefaultValue(value: number | 'unkown', values: Mark[]): number {
  return value === 'unkown' ? values[0].value : value;
}

export const OnboardingQuestionSlider: React.FC<OnboardingQuestionSliderProps> = ({
  onChange,
  label,
  helperText,
  min,
  max,
  defaultValue,
  minSuffix = '',
  maxSuffix = '',
}) => {
  const classes = useStyles();
  const values = generateValues(min, max, minSuffix, maxSuffix);
  const [value, setValue] = useState(convertDefaultValue(defaultValue, values));

  useEffect(() => {
    setValue(convertDefaultValue(defaultValue, values));
  }, [defaultValue, min, max, minSuffix, maxSuffix]);

  const handleChange = useCallback(
    (event: React.ChangeEvent, val: number) => {
      setValue(val);
      if (val === values[0].value) {
        onChange('unkown');
      } else {
        onChange(val);
      }
    },
    [onChange],
  );

  return (
    <div className={classes.root}>
      <Slider
        value={value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleChange}
        getAriaValueText={valuetext}
        step={null}
        marks={values}
        min={values[0].value}
        max={max}
        classes={{ mark: classes.mark, marked: classes.slider }}
        valueLabelDisplay={value !== values[0].value ? 'on' : 'off'}
      />
      <Typography variant="h6" className={classes.label}>
        {label}
        {helperText && (
          <span className={classes.helperText}>({helperText})</span>
        )}
      </Typography>
    </div>
  );
};
