import { validateDoc, DocValidator } from '@21st-night/utils';
import { RatingEvent, REVIEW_RATING } from '../../Review.types';

export const NewRatingEventValidator: DocValidator<RatingEvent> = {
  createdAt: 'date',
  duration: 'number',
  interval: 'number',
  rating: [REVIEW_RATING],
};

export function validateNewRatingEvent(doc: RatingEvent): boolean {
  return validateDoc(NewRatingEventValidator, doc);
}
