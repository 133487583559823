import { useEffect, useState } from 'react';
import { Note, deserializeNoteDocument } from '@21st-night/notes';
import { useFirebase } from '@21st-night/utils';
import { Deck } from '../../Deck.types';

export type DeckNotesHook = [Note[], boolean];

export const useDeckNotes = (deck: Deck): DeckNotesHook => {
  const { db } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    const deckIds = [deck.id];

    if (deck.parent) {
      deckIds.push(deck.parent);
    }

    const unsubscribe = db
      .collection('notes')
      .where('deck', 'in', deckIds)
      .onSnapshot(snapshot => {
        if (isMounted && snapshot) {
          setNotes(snapshot.docs.map(doc => deserializeNoteDocument(doc)));
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [deck.id]);

  return [notes, loading];
};
