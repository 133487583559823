import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import { CategoryTag } from '@21st-night/categories-web';
import { DeckFilterOption } from '@21st-night/deck';

export interface DeckFilterBarOptionProps extends DeckFilterOption {
  selected?: boolean;
  className?: string;
  onDelete?: () => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }),
);

export const DeckFilterBarOption: React.FC<DeckFilterBarOptionProps> = ({
  className,
  type,
  label,
  selected,
  onDelete,
}) => {
  const variant =
    type === 'category' || type === 'subcategory' ? type : 'metadata';

  return (
    <CategoryTag
      className={className}
      variant={variant}
      label={label}
      style={{ opacity: selected ? 0.5 : 1 }}
      onDelete={onDelete}
    />
  );
};
