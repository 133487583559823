import { useEffect, useRef } from 'react';

/**
 * Accepts a function which is invoked repeatedly
 * with a given interval.
 *
 * @param callback    The function to be invoked.
 * @param delay       The delay between invocations.
 * @param shouldClear Callback fired after every function invocation. The interval is cleared if this function returns true.
 */
export function useInterval(
  callback: () => void,
  delay = 1000,
  shouldClear?: () => boolean,
): void {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let id: number;
    function tick(): void {
      // If the interval exists and shouldClear callback
      // is defined and returns true, clear the interval.
      if (id && shouldClear && shouldClear()) {
        clearInterval(id);
      } else if (savedCallback.current) {
        // Invoke function
        savedCallback.current();
      }
    }

    id = window.setInterval(tick, delay);
    return (): void => clearInterval(id);
  }, [shouldClear, delay]);
}
