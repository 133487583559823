import React, { useState } from 'react';
import { Clear } from '@21st-night/icons';
import { Button, ButtonProps } from '../Button';
import { IconButton, IconButtonProps } from '../IconButton';
import { Typography } from '../Typography';

export interface DeleteButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  cancelLabel?: string;
  confirmLabel?: string;
  confirmText?: React.ReactNode;
  label?: string;
  variant?: 'text' | 'icon';
  onConfirm: (event: React.MouseEvent) => void;
  reverseButtons?: boolean;
  size?: ButtonProps['size'];
  textButtonsSize?: ButtonProps['size'];
  iconButtonSize?: IconButtonProps['size'];
  icon?: IconButtonProps['children'];
  IconButtonProps?: IconButtonProps;
  ButtonProps?: ButtonProps;
  CancelButtonProps?: ButtonProps;
  ConfirmButtonProps?: ButtonProps;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  variant = 'text',
  label = 'Delete',
  confirmLabel = 'Delete',
  cancelLabel = 'Cancel',
  confirmText,
  onConfirm,
  size,
  iconButtonSize: iconButtonSizeProp,
  textButtonsSize: textButtonsSizeProp,
  icon,
  reverseButtons,
  IconButtonProps = {},
  ButtonProps = {},
  CancelButtonProps = {},
  ConfirmButtonProps = {},
  style = {},
  ...other
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const iconButtonSize = iconButtonSizeProp || size;
  const textButtonsSize = textButtonsSizeProp || size;

  function handleClickDelete() {
    setShowConfirm(true);
  }

  function handleClickCancelDelete() {
    setShowConfirm(false);
  }

  const cancelButton = (
    <Button
      size={textButtonsSize}
      onClick={handleClickCancelDelete}
      {...ConfirmButtonProps}
    >
      {cancelLabel}
    </Button>
  );

  return (
    <div style={{ display: 'inline-block', ...style }} {...other}>
      {!showConfirm && variant === 'icon' && (
        <IconButton
          size={iconButtonSize}
          onClick={handleClickDelete}
          {...IconButtonProps}
        >
          {icon || <Clear />}
        </IconButton>
      )}
      {!showConfirm && variant === 'text' && (
        <Button
          size={textButtonsSize}
          onClick={handleClickDelete}
          {...ButtonProps}
        >
          {label}
        </Button>
      )}
      {showConfirm && (
        <div>
          {confirmText && <Typography gutterBottom>{confirmText}</Typography>}
          {reverseButtons && cancelButton}
          <Button
            size={textButtonsSize}
            color="primary"
            onClick={onConfirm}
            {...CancelButtonProps}
          >
            {confirmLabel}
          </Button>
          {!reverseButtons && cancelButton}
        </div>
      )}
    </div>
  );
};
