import { secondsToHumanReadable } from '@21st-night/analytics';
import React from 'react';
import { SimpleLineChart, SimpleLineChartDataItem } from '../SimpleLineChart';

export interface StudyTimeAreaChartDataItem extends SimpleLineChartDataItem {
  label: string;
  duration: number;
}

export interface StudyTimeAreaChartProps {
  data: StudyTimeAreaChartDataItem[];
  mini?: boolean;
}

export const StudyTimeAreaChart: React.FC<StudyTimeAreaChartProps> = ({
  data,
  ...other
}) => {
  return (
    <SimpleLineChart
      data={data}
      dataKey="duration"
      label="Study time"
      valueFormatter={secondsToHumanReadable}
      {...other}
    />
  );
};
