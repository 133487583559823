import React from 'react';
import { RenderElementProps } from '@braindrop-editor/core';
import { EditorWithLinkPlugin, LinkElement } from '../LinkPlugin.types';
import { useSlateStatic } from 'slate-react';

export interface ElementLinkProps extends RenderElementProps {
  element: LinkElement;
  mobile?: boolean;
}

export const ElementLink: React.FC<ElementLinkProps> = ({
  attributes,
  children,
  element,
  mobile,
}) => {
  const editor = useSlateStatic() as EditorWithLinkPlugin;

  function handleClick() {
    if (mobile) {
      return editor.openLinkPopover(element);
    }

    window.open(element.url, '_blank');
  }

  return (
    <a
      href={element.url}
      {...attributes}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit' }}
    >
      <span
        style={{
          borderBottom: '0.05em solid rgba(55,53,47,0.4)',
          opacity: 0.7,
          transition: 'border-color 100ms ease-in, opacity 100ms ease-in',
        }}
      >
        {children}
      </span>
    </a>
  );
};
