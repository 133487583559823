import type * as firebase from 'firebase/app';

/**
 * Converts a Firestore timestamp to a
 * javascript Date object. If a Date object
 * is passed in, it is returned unchanged. If
 * a non date value is passed in, return the
 * default value, which is a new Date by default.
 *
 * @param timestamp    Firestore timestamp or Date.
 * @param defaultValue Returned if timestamp is not a valid date.
 */
export function toDate(
  timestamp: Date | null | undefined | firebase.firestore.Timestamp,
  defaultValue: Date = new Date(),
): Date {
  if (timestamp) {
    const ts = timestamp as firebase.firestore.Timestamp;
    if (typeof ts.toDate === 'function') {
      return ts.toDate();
    }

    if (timestamp instanceof Date) {
      return timestamp;
    }
  }

  return defaultValue;
}
