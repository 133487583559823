import React from 'react';
import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
} from '@material-ui/core';

export type CollapseProps = MuiCollapseProps;

export const Collapse: React.FC<CollapseProps> = ({ ...other }) => {
  return <MuiCollapse {...other} />;
};
