import { DB, toDate } from '@21st-night/utils';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { wasYesterday } from '../wasYesterday';
import {
  UserGamificationData,
  UserGamificationUpdateData,
} from '../../Gamification.types';

export async function incrementStreak(db: DB, userId: string): Promise<void> {
  const userRef = db.collection('users').doc(userId);

  await db.runTransaction(async t => {
    const doc = await t.get(userRef);
    const data = doc.data();

    if (!doc.exists || !data) {
      throw new Error('User does not exist');
    }
    const { currentStreak, gold, statistics } = data as UserGamificationData;
    const lastStreakIncrement = toDate(data.lastStreakIncrement);

    let newStreak = currentStreak || 1;

    // If the previous streak incerement was today
    // we don't need increment the streak, only the
    // last activity.
    if (
      data.lastStreakIncrement &&
      dayjs().isSame(lastStreakIncrement, 'date')
    ) {
      return t.update(userRef, { lastActivity: new Date() });
    }

    if (wasYesterday(lastStreakIncrement)) {
      // If the previous streak increment happened
      // yesterday, we increase the streak again today.
      newStreak += 1;
    } else {
      // The streak has not been updated today or
      // yesterday meaning that it has been broken
      // or has not yet been started (new user).
      // Set the streak to 1.
      newStreak = 1;
    }

    const streakDayRef = db
      .collection('users')
      .doc(userId)
      .collection('streak-days')
      .doc(uuid());

    t.set(streakDayRef, { createdAt: new Date() });

    const userUpdate: UserGamificationUpdateData = {
      currentStreak: newStreak,
      lastStreakIncrement: new Date(),
      lastActivity: new Date(),
    };

    if (newStreak % 3 === 0) {
      userUpdate.gold = gold || 0 + 3;
    }

    if (
      !statistics ||
      !statistics['longest-streak'] ||
      statistics['longest-streak'] < newStreak
    ) {
      userUpdate['statistics.longest-streak'] = newStreak;
    }

    t.update(userRef, userUpdate);
  });
}
