import { DocumentReference } from '@21st-night/utils';
import { Deck, DeckCardUpdateData, DeckNoteUpdateData } from '../../Deck.types';
import { updateDeck } from '../updateDeck';

function contentUpdates(
  deck: Deck,
  contentType: 'notes',
  oldName: string,
  newName: string,
): Record<string, DeckNoteUpdateData>;
function contentUpdates(
  deck: Deck,
  contentType: 'cards',
  oldName: string,
  newName: string,
): Record<string, DeckCardUpdateData>;
function contentUpdates(
  deck: Deck,
  contentType: 'cards' | 'notes',
  oldName: string,
  newName: string,
): Record<string, DeckCardUpdateData | DeckNoteUpdateData> {
  // Find cards/notes with this category and create a field update for
  // each one which will replace the old category with the new one
  return Object.keys(deck[contentType]).reduce(
    (updates, id) =>
      deck[contentType][id].subcategories.includes(oldName)
        ? {
            ...updates,
            [id]: {
              // We don't care about maintaining the order of subcategories
              // so we can just add the new name to the end
              subcategories: [
                ...deck[contentType][id].subcategories.filter(
                  subcategory => subcategory !== oldName,
                ),
                newName,
              ],
            },
          }
        : updates,
    {},
  );
}

export function renameSubcategory(
  deckRef: DocumentReference,
  deck: Deck,
  oldName: string,
  newName: string,
): Promise<void> | void {
  if (!newName || newName === oldName) {
    return;
  }

  // Find cards with this subcategory and create a field update
  // for each one which will replace the old category with the new one.
  const cardUpdates = contentUpdates(deck, 'cards', oldName, newName);
  const noteUpdates = contentUpdates(deck, 'notes', oldName, newName);

  return updateDeck(deckRef, {
    cards: cardUpdates,
    notes: noteUpdates,
    subcategories: [
      // We don't care about maintaining the order of subcategories
      // so we can just add the new name to the end
      ...deck.subcategories.filter(subcategory => subcategory !== oldName),
      newName,
    ],
  });
}
