import React, { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useForm, useFieldArray } from 'react-hook-form';
import { DocumentReference } from '@21st-night/utils';
import { TaskTemplateForm } from '../TaskTemplateForm';
import {
  StudyPlanTaskTemplate,
  updateStudyPlanTaskTemplate,
} from '@21st-night/study-plan';

export interface StudyPlanTaskTemplateEditorProps {
  templateRef: DocumentReference;
  task: StudyPlanTaskTemplate;
  planLength: number;
}

export const StudyPlanTaskTemplateEditor: React.FC<StudyPlanTaskTemplateEditorProps> = ({
  templateRef,
  planLength,
  task,
}) => {
  const { register, watch, control, setValue } = useForm({
    defaultValues: {
      ...task,
      subtasks: task.subtasks.map(subtask => ({ title: subtask })),
      days: task.days.map(day => `${day}`),
    },
  });
  const { fields: subtasks, append, remove } = useFieldArray({
    control,
    name: 'subtasks',
  });
  const count = watch('count', task.count);
  const stringDays = watch(
    'days',
    task.days.map(day => `${day}`),
  ) as string[];
  const days = stringDays.map(day => parseInt(day));
  const subtasksVal = watch('subtasks', []);
  const value = watch([
    'action',
    'count',
    'object',
    'source',
    'start',
    'end',
    'days',
  ]);

  const [debouncedValue] = useDebounce(
    JSON.stringify({ ...value, subtasks: subtasksVal }),
    300,
    {
      maxWait: 600,
    },
  );

  useEffect(() => {
    const {
      action,
      count,
      object,
      source,
      days: daysValue,
      subtasks: subtasksValue,
      start,
      end,
    } = JSON.parse(debouncedValue);

    if (
      task.days &&
      days &&
      (action !== task.action ||
        count !== task.count ||
        object !== task.object ||
        source !== task.source ||
        JSON.stringify(daysValue) !== JSON.stringify(task.days) ||
        JSON.stringify(subtasksValue) !== JSON.stringify(task.subtasks) ||
        start !== task.start ||
        end !== task.end)
    ) {
      updateStudyPlanTaskTemplate(templateRef, task.id, {
        action: action || '',
        count: parseInt(count) || 0,
        object: object || '',
        source: source || '',
        days: days || [],
        start: parseInt(start) || 1,
        end: parseInt(end) || planLength,
        subtasks: (subtasksValue || []).map(
          (subtask: { title: string }) => subtask.title,
        ),
      });
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  const addSubtask = () => append({ title: '' });
  const removeSubtask = (event: React.MouseEvent, index: number) =>
    remove(index);

  function handleClickConfirmDelete() {
    templateRef.collection('tasks').doc(task.id).delete();
  }

  return (
    <TaskTemplateForm
      studyPlanLength={planLength}
      onClickAddSubtask={addSubtask}
      onClickRemoveSubtask={removeSubtask}
      onClickDelete={handleClickConfirmDelete}
      subtasks={subtasks.map((subtask, index) => ({
        id: subtask.id as string,
        InputProps: {
          inputRef: register({ required: true }),
          name: `subtasks[${index}].title`,
          defaultValue: subtask.title,
        },
      }))}
      ActionInputProps={{
        inputRef: register(),
        defaultValue: task.action || '',
      }}
      CountInputProps={{
        inputRef: register({ min: 1, required: true }),
        defaultValue: task.count || '',
      }}
      ObjectInputProps={{
        inputRef: register({ required: true }),
        defaultValue: task.object || '',
      }}
      SourceInputProps={{
        inputRef: register(),
        defaultValue: task.source || '',
      }}
      startInputProps={{
        ref: register({ required: true }),
      }}
      endInputProps={{
        ref: register({ required: true }),
      }}
      DayCheckboxProps={{
        inputRef: register({}),
        name: 'days',
      }}
      DaysOfTheWeekProps={{
        defaultValue: task.days,
      }}
      DateRangeSliderProps={{
        taskDays: days,
        taskCount: count,
        defaultValue: [task.start, task.end],
        onChange: (event: React.ChangeEvent, value: number[]) => {
          setValue('start', value[0]);
          setValue('end', value[1]);
        },
      }}
    />
  );
};
