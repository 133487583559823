import {
  SlashPluginFactory,
  SlashPlugin,
  SlashEditor,
} from '@sheets-editor/core';
import { EditorProps } from '@21st-night/editor-web';

interface CardCreationPluginOptions {
  EditorProps: Omit<EditorProps, 'value' | 'onChange'>;
}

export const createCardCreationPlugin = ({
  EditorProps,
}: CardCreationPluginOptions): SlashPluginFactory => (
  editor: SlashEditor,
): SlashPlugin => {
  return {};
};
