import React from 'react';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core';

export const RatingWrong: React.FC<SvgIconProps> = ({
  style = {},
  ...other
}) => (
  <AddCircleOutlineRounded
    style={{ transform: 'rotate(45deg)', ...style }}
    {...other}
  />
);
