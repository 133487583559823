import React from 'react';
import {
  BraindropEditorPluginFactory,
  BraindropEditorPlugin,
  Transforms,
  InsertOptions,
  BraindropEditor,
} from '@braindrop-editor/core';
import { Range } from 'slate';
import { Equation, EquationElement } from './Equation';
import { EditorWithInlineEquationPlugin } from './EquationPlugin.types';

interface EquationPluginOptions {
  mobile?: boolean;
}

function insertInlineEquation(
  editor: BraindropEditor,
  options?: InsertOptions,
) {
  if (editor.selection && !Range.isCollapsed(editor.selection)) {
    let tex = '';
    const selection = window.getSelection();
    if (selection) {
      tex = selection.toString();
    }

    Transforms.wrapNodes(
      editor,
      {
        type: 'equation-inline',
        tex,
        children: [{ text: '' }],
      } as EquationElement,
      { split: true },
    );
  } else {
    Transforms.insertNodes(
      editor,
      editor.generateElement('equation-inline', { tex: '' }),
      options,
    );
  }
}

export const createEquationPlugin = (
  options?: EquationPluginOptions,
): BraindropEditorPluginFactory => (baseEditor): BraindropEditorPlugin => {
  const editor = baseEditor as EditorWithInlineEquationPlugin;
  const mobile = options && options.mobile;

  editor.insertInlineEquation = (options?: InsertOptions) =>
    insertInlineEquation(editor, options);

  return {
    elements: [
      {
        isVoid: true,
        isInline: true,
        insert: insertInlineEquation,
        component: ({ element, ...props }) => (
          <Equation
            mobile={mobile}
            element={element as EquationElement}
            {...props}
          />
        ),
        type: 'equation-inline',
        hotkeys: ['mod+Shift+e'],
      },
    ],
  };
};
