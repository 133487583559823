import { minutesToDays } from '@21st-night/utils';
import { FlashcardReviewProgress } from '../../Review.types';
import { generateCardReviewProgress } from '../generateCardReviewProgress';
import { STARTING_EASE, NEW_STEPS } from '../../review-constants';

export function generateFlashcardReviewProgress(
  options: Partial<FlashcardReviewProgress> = {},
): FlashcardReviewProgress {
  return {
    ...generateCardReviewProgress('flashcard'),
    stepsIndex: 0,
    easeFactor: STARTING_EASE,
    interval: minutesToDays(NEW_STEPS[0]),
    status: 'new',
    ...options,
  };
}
