import React from 'react';
import {
  DeckAddCardButton,
  DeckAddCardButtonProps,
} from '../DeckAddCardButton';

export type DeckAddErrorLogButtonProps = Omit<DeckAddCardButtonProps, 'type'>;

export const DeckAddErrorLogButton: React.FC<DeckAddErrorLogButtonProps> = props => (
  <DeckAddCardButton
    tooltip={
      <span>
        An error log is a way to remember techniques. Create error logs for math
        questions, science questions, and any questions that involve remembering{' '}
        <em>how</em> to solve a problem.
      </span>
    }
    type="error-log"
    {...props}
  />
);
