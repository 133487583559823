import { DB, validateMap, DocumentReference } from '@21st-night/utils';
import { DeckCard, DeckUpdateData } from '../../Deck.types';
import { NewDeckCardValidator, NewDeckFlashcardValidator } from '../validators';

export function addCardsToDeck(
  db: DB,
  deckRef: DocumentReference,
  cards: DeckCard[],
): Promise<void> {
  const flashcardsMap = cards
    .filter(card => card.type === 'flashcard')
    .reduce((map, card) => ({ ...map, [card.id]: card }), {});
  const otherCardsMap = cards
    .filter(card => card.type !== 'flashcard')
    .reduce((map, card) => ({ ...map, [card.id]: card }), {});

  if (Object.keys(flashcardsMap).length) {
    validateMap(
      { type: 'doc', validator: NewDeckFlashcardValidator },
      flashcardsMap,
    );
  }
  if (Object.keys(otherCardsMap).length) {
    validateMap(
      { type: 'doc', validator: NewDeckCardValidator },
      otherCardsMap,
    );
  }

  const updateData: DeckUpdateData = {
    ...cards.reduce(
      (map, card) => ({
        ...map,
        [`cards.${card.id}`]: card,
      }),
      {},
    ),
  };
  const newCategories: string[] = [];
  const newSubcategories: string[] = [];

  cards.forEach(card => {
    if (card.category && !newCategories.includes(card.category)) {
      newCategories.push(card.category);
    }
    card.subcategories.forEach(subcategory => {
      if (!newSubcategories.includes(subcategory)) {
        newSubcategories.push(subcategory);
      }
    });
  });

  if (newCategories.length) {
    updateData.categories = db.arrayUnion(...newCategories);
  }

  if (newSubcategories.length) {
    updateData.subcategories = db.arrayUnion(...newSubcategories);
  }

  return deckRef.update(updateData);
}
