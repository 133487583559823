import { DocumentReference } from '@21st-night/utils';
import { DeckCard } from '../../Deck.types';
import { validateNewDeckCard } from '../validators';

export function addCardToDeck(
  deckRef: DocumentReference,
  card: DeckCard,
): Promise<void> {
  validateNewDeckCard(card);

  return deckRef.update({
    [`cards.${card.id}`]: card,
    lastActivity: new Date(),
  });
}
