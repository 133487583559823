import dayjs from 'dayjs';
import {
  OnboardingQuestionAnswerOption,
  OnboardingRangeSelection,
  OnboardingQuestions,
} from './onboarding.types';

export const subjectTypes: OnboardingQuestionAnswerOption[] = [
  {
    value: 'FACT',
    label: 'a fact-based subject (like history or vocabulary)',
  },
  {
    value: 'SKILL',
    label: 'a skill-based subject (like math or physics)',
  },
  {
    value: 'MIXED',
    label: 'a combination (like a foreign language)',
  },
];

export const contentTypes: OnboardingQuestionAnswerOption[] = [
  {
    value: 'FACT',
    label: 'memorize content (like vocab)',
  },
  {
    value: 'SKILL',
    label: 'learn a new skill (like algebra)',
  },
  {
    value: 'MIXED',
    label: 'both (like a foreign language)',
  },
];

export const lsatScores: OnboardingRangeSelection[] = [
  {
    name: 'reading-comprehension',
    label: 'Reading Comprehension',
    minSuffix: '+',
    helperText: 'errors per section',
    defaultValue: 'unkown',
    min: -10,
    max: 0,
  },
  {
    name: 'logical-reasoning',
    label: 'Logical Reasoning',
    minSuffix: '+',
    helperText: 'errors per section',
    defaultValue: 'unkown',
    min: -10,
    max: 0,
  },
  {
    name: 'login-games',
    label: 'Logic Games',
    minSuffix: '+',
    helperText: 'errors per section',
    defaultValue: 'unkown',
    min: -10,
    max: 0,
  },
  {
    name: 'score',
    label: 'Overall score',
    defaultValue: 'unkown',
    min: 140,
    max: 180,
  },
];

export const gmatScores: OnboardingRangeSelection[] = [
  {
    name: 'quant',
    label: 'Quant',
    defaultValue: 'unkown',
    min: 6,
    max: 51,
  },
  {
    name: 'verbal',
    label: 'Verbal',
    defaultValue: 'unkown',
    min: 6,
    max: 51,
  },
  {
    name: 'score',
    label: 'Overall score',
    defaultValue: 'unkown',
    min: 200,
    max: 800,
  },
];

export const greScores: OnboardingRangeSelection[] = [
  {
    name: 'quant',
    label: 'Quant',
    defaultValue: 'unkown',
    min: 130,
    max: 170,
  },
  {
    name: 'verbal',
    label: 'Verbal',
    defaultValue: 'unkown',
    min: 130,
    max: 170,
  },
];

export const mcatScores: OnboardingRangeSelection[] = [
  {
    name: 'chem-phys',
    label: 'Chem/Phys',
    defaultValue: 'unkown',
    min: 118,
    max: 132,
  },
  {
    name: 'cars',
    label: 'CARS',
    defaultValue: 'unkown',
    min: 118,
    max: 132,
  },
  {
    name: 'bio-biochem',
    label: 'Bio/Biochem',
    defaultValue: 'unkown',
    min: 118,
    max: 132,
  },
  {
    name: 'psych-soc',
    label: 'Psych/Soc',
    defaultValue: 'unkown',
    min: 118,
    max: 132,
  },
  {
    name: 'score',
    label: 'Overall score',
    defaultValue: 'unkown',
    min: 472,
    max: 528,
  },
];

export const segmentationQuestions: OnboardingQuestions = {
  method: {
    id: 'method',
    answerKey: 'method',
    type: 'multiple-choice',
    progress: 0,
    question: 'What are you using 21st Night for?',
    options: [
      {
        value: 'self-study',
        label: 'self-studying',
        nextQuestion: 'studying-use-case',
      },
      {
        value: 'tutoring',
        label: 'tutoring',
        nextQuestion: 'tutoring-user-type',
      },
    ],
  },
  'studying-use-case': {
    id: 'studying-use-case',
    answerKey: 'use-case',
    type: 'multiple-choice',
    progress: 11,
    question: 'What are you studying for?',
    options: [
      {
        value: 'standardized-test',
        label: 'standardized test',
        nextQuestion: 'student-standardized-test',
      },
      {
        value: 'school',
        label: 'school',
        nextQuestion: 'student-subject-type',
      },
      {
        value: 'other',
        label: 'other',
        nextQuestion: 'student-content-type',
      },
    ],
  },
  'tutoring-user-type': {
    id: 'tutoring-user-type',
    answerKey: 'user-type',
    type: 'multiple-choice',
    progress: 5,
    question: 'Are you a tutor, a student, or a parent?',
    options: [
      {
        value: 'student',
        label: 'student',
        nextQuestion: 'studying-use-case',
      },
      {
        value: 'tutor',
        label: 'tutor',
        nextQuestion: 'tutoring-use-case',
      },
      {
        value: 'parent',
        label: 'parent',
      },
    ],
  },
  'tutoring-use-case': {
    id: 'tutoring-use-case',
    answerKey: 'use-case',
    type: 'multiple-choice',
    progress: 50,
    question: 'What are you tutoring for?',
    options: [
      {
        value: 'standardized-test',
        label: 'standardized test',
        nextQuestion: 'tutor-standardized-test',
      },
      {
        value: 'school',
        label: 'school',
        nextQuestion: 'tutor-subject-type',
      },
      {
        value: 'other',
        label: 'other',
        nextQuestion: 'tutor-content-type',
      },
    ],
  },
  'student-standardized-test': {
    id: 'student-standardized-test',
    answerKey: 'content-type',
    type: 'multiple-choice',
    progress: 22,
    question: 'Which standardized test are you studying for?',
    options: [
      {
        value: 'GMAT',
        label: 'GMAT',
        nextQuestion: 'gmat-goal-score',
      },
      {
        value: 'GRE',
        label: 'GRE',
        nextQuestion: 'gre-goal-score',
      },
      {
        value: 'MCAT',
        label: 'MCAT',
        nextQuestion: 'mcat-goal-score',
      },
      {
        value: 'LSAT',
        label: 'LSAT',
        nextQuestion: 'lsat-goal-score',
      },
      {
        value: 'ARE',
        label: 'ARE',
        nextQuestion: 'study-plan',
      },
      {
        value: 'OTHER',
        label: 'other',
        nextQuestion: 'student-content-type',
      },
    ],
  },
  'tutor-standardized-test': {
    id: 'tutor-standardized-test',
    answerKey: 'content-type',
    type: 'multiple-choice',
    progress: 75,
    question: 'Which standardized test are you tutoring for?',
    options: [
      {
        value: 'GMAT',
        label: 'GMAT',
      },
      {
        value: 'GRE',
        label: 'GRE',
      },
      {
        value: 'MCAT',
        label: 'MCAT',
      },
      {
        value: 'LSAT',
        label: 'LSAT',
      },
      {
        value: 'OTHER',
        label: 'other',
        nextQuestion: 'tutor-content-type',
      },
    ],
  },
  'student-subject-type': {
    id: 'student-subject-type',
    answerKey: 'content-type',
    type: 'multiple-choice',
    progress: 28,
    question: 'What kind of subject are you studying in school?',
    options: subjectTypes,
    nextQuestion: 'study-plan',
  },
  'student-content-type': {
    id: 'student-content-type',
    answerKey: 'content-type',
    type: 'multiple-choice',
    progress: 28,
    question:
      'Do you need to memorize content, learn some new skills, or both?',
    options: contentTypes,
    nextQuestion: 'study-plan',
  },
  'tutor-subject-type': {
    id: 'tutor-subject-type',
    answerKey: 'content-type',
    type: 'multiple-choice',
    progress: 75,
    question: 'What kind of subject are you tutoring?',
    options: subjectTypes,
  },
  'tutor-content-type': {
    id: 'tutor-content-type',
    answerKey: 'content-type',
    type: 'multiple-choice',
    progress: 75,
    question:
      'Do you your students need to memorize content, learn some new skills, or both?',
    options: contentTypes,
  },
  'gmat-goal-score': {
    id: 'gmat-goal-score',
    answerKey: 'goal-score',
    type: 'range',
    question: 'I want to achieve a GMAT score of around...',
    progress: 33,
    ranges: gmatScores.map(range => ({ ...range, defaultValue: range.min })),
    nextQuestion: 'gmat-current-score',
  },
  'gre-goal-score': {
    id: 'gre-goal-score',
    answerKey: 'goal-score',
    type: 'range',
    progress: 33,
    question: 'I want to achieve a GRE score of around...',
    ranges: greScores.map(range => ({ ...range, defaultValue: range.min })),
    nextQuestion: 'gre-current-score',
  },
  'mcat-goal-score': {
    id: 'mcat-goal-score',
    answerKey: 'goal-score',
    type: 'range',
    progress: 33,
    question: 'I want to achieve an MCAT score of around...',
    ranges: mcatScores.map(range => ({ ...range, defaultValue: range.min })),
    nextQuestion: 'mcat-current-score',
  },
  'lsat-goal-score': {
    id: 'lsat-goal-score',
    answerKey: 'goal-score',
    type: 'range',
    progress: 33,
    question: 'I want to achieve an LSAT score of around...',
    ranges: lsatScores.map(range => ({ ...range, defaultValue: range.min })),
    nextQuestion: 'lsat-current-score',
  },
  'gmat-current-score': {
    id: 'gmat-current-score',
    answerKey: 'current-score',
    type: 'range',
    progress: 44,
    question: "What's your starting score?",
    ranges: gmatScores,
    nextQuestion: 'gmat-study-plan',
  },
  'gre-current-score': {
    id: 'gre-current-score',
    answerKey: 'current-score',
    type: 'range',
    progress: 44,
    question: "What's your starting score?",
    ranges: greScores,
    nextQuestion: 'gre-study-plan',
  },
  'mcat-current-score': {
    id: 'mcat-current-score',
    answerKey: 'current-score',
    type: 'range',
    progress: 44,
    question: "What's your starting score?",
    ranges: mcatScores,
    nextQuestion: 'mcat-study-plan',
  },
  'lsat-current-score': {
    id: 'lsat-current-score',
    answerKey: 'current-score',
    type: 'range',
    progress: 44,
    question: "What's your starting score?",
    ranges: lsatScores,
    nextQuestion: 'lsat-study-plan',
  },
  'study-plan': {
    skippable: true,
    id: 'study-plan',
    answerKey: 'study-plan-type',
    type: 'multiple-choice',
    progress: 42,
    question: "Let's get you a personalized study plan!",
    options: [
      {
        value: 'custom',
        label: 'create a study plan',
        nextQuestion: 'exam-date',
      },
      {
        value: 'skip',
        label: 'maybe later',
      },
    ],
  },
  'gmat-study-plan': {
    id: 'gmat-study-plan',
    skippable: true,
    answerKey: 'study-plan-type',
    type: 'multiple-choice',
    progress: 55,
    question:
      "Let's get you a personalized GMAT study plan! Would you rather...",
    options: [
      {
        value: 'custom',
        label: 'Create a study plan from scratch',
        nextQuestion: 'exam-date',
      },
      {
        value: 'template',
        label: 'Adapt one of our study plans',
        nextQuestion: 'exam-date',
      },
    ],
  },
  'gre-study-plan': {
    id: 'gre-study-plan',
    skippable: true,
    answerKey: 'study-plan-type',
    type: 'multiple-choice',
    progress: 55,
    question:
      "Let's get you a personalized GRE study plan! Would you rather...",
    options: [
      {
        value: 'custom',
        label: 'Create a study plan from scratch',
        nextQuestion: 'exam-date',
      },
      {
        value: 'template',
        label: 'Adapt one of our study plans',
        nextQuestion: 'exam-date',
      },
    ],
  },
  'mcat-study-plan': {
    id: 'mcat-study-plan',
    skippable: true,
    answerKey: 'study-plan-type',
    type: 'multiple-choice',
    progress: 55,
    question:
      "Let's get you a personalized MCAT study plan! Would you rather...",
    options: [
      {
        value: 'custom',
        label: 'Create a study plan from scratch',
        nextQuestion: 'exam-date',
      },
      {
        value: 'template',
        label: 'Adapt one of our study plans',
        nextQuestion: 'exam-date',
      },
    ],
  },
  'lsat-study-plan': {
    id: 'lsat-study-plan',
    skippable: true,
    answerKey: 'study-plan-type',
    type: 'multiple-choice',
    progress: 55,
    question:
      "Let's get you a personalized LSAT study plan! Would you rather...",
    options: [
      {
        value: 'custom',
        label: 'Create a study plan from scratch',
        nextQuestion: 'exam-date',
      },
      {
        value: 'template',
        label: 'Adapt one of our study plans',
        nextQuestion: 'exam-date',
      },
    ],
  },
  'exam-date': {
    id: 'exam-date',
    answerKey: 'exam-date',
    type: 'date',
    progress: 66,
    question: 'When is your test?',
    nextQuestion: 'start-studying-date',
  },
  'start-studying-date': {
    id: 'start-studying-date',
    answerKey: 'start-studying-date',
    type: 'date',
    progress: 77,
    question: 'When will you start studying?',
    inputFormat: (date: Date): string =>
      dayjs().isSame(date, 'day') ? '[Today]' : 'D MMMM, YYYY',
  },
};
