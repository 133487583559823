import React from 'react';
import { CategoryTag, CategoryTagProps } from '@21st-night/categories-web';
import { useDeck } from '@21st-night/deck';

export interface DeckLinkedCardsTagProps
  extends Omit<CategoryTagProps, 'variant' | 'label' | 'onClick'> {
  readonly?: boolean;
  count: number;
  value: string;
}

export const DeckLinkedCardsTag: React.FC<DeckLinkedCardsTagProps> = ({
  readonly,
  count,
  value,
  ...other
}) => {
  const deck = useDeck();

  return (
    <CategoryTag
      variant="metadata"
      label={`🔗 ${count} card${count > 1 ? 's' : ''}`}
      onClick={
        !readonly
          ? () =>
              deck.toggleFilter({
                value,
                type: 'link',
                group: 'metadata',
                label: '🔗 Linked',
              })
          : undefined
      }
      {...other}
    />
  );
};
