import { DocumentReference } from '@21st-night/utils';
import { DeckCardUpdateData } from '../../Deck.types';
import { serializeDeckCardsUpdateData } from '../serializeDeckCardsUpdateData';

export function updateDeckCard(
  deckRef: DocumentReference,
  cardId: string,
  data: DeckCardUpdateData,
): Promise<void> {
  const serializedData = serializeDeckCardsUpdateData({ [cardId]: data });

  return deckRef.update(serializedData);
}
