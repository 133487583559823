import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  generateRichTextDocument,
  serializeDocument,
} from '@21st-night/editor-web';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  TextField,
  TextFieldError,
  Button,
  DialogTitle,
} from '@21st-night/ui';
import { CardData } from '@21st-night/cards';
import { ImportFromQuizletCreatingCards } from './ImportFromQuizletCreatingCards';
import { ImportFromQuizletDone } from './ImportFromQuizletDone';

type View = 'form' | 'loading' | 'done';

export interface ImportFromQuizletDialogProps extends DialogProps {
  createCards: (data: CardData[]) => Promise<void>;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    radios: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    customTextField: {
      maxWidth: 60,
    },
    textarea: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
  }),
);

export const ImportFromQuizletDialog: React.FC<ImportFromQuizletDialogProps> = ({
  onClose,
  createCards,
  ...other
}) => {
  const classes = useStyles();
  const [view, setView] = useState<View>('form');
  const [errors, setErrors] = useState<Record<string, TextFieldError>>({});
  const [columnSeparatorOption, setColumnSeparatorOption] = useState('tab');
  const [customColumnSeparator, setCustomColumnSeparator] = useState('');
  const [rowSeparatorOption, setRowSeparatorOption] = useState('new-line');
  const [customRowSeparator, setCustomRowSeparator] = useState('');
  const [text, setText] = useState('');
  const [count, setCount] = useState(0);

  function handleClose() {
    if (onClose) {
      onClose();
      setText('');
      setCustomRowSeparator('');
      setCustomColumnSeparator('');
      setColumnSeparatorOption('tab');
      setRowSeparatorOption('new-line');
    }
  }

  async function handleSubmit() {
    setView('loading');

    const formErrors: Record<string, TextFieldError> = {};

    let rowSeparator = customRowSeparator;
    let columnSeparator = customColumnSeparator;

    if (rowSeparatorOption === 'new-line') {
      rowSeparator = '\n';
    } else if (rowSeparatorOption === 'semicolon') {
      rowSeparator = ';';
    }

    if (columnSeparatorOption === 'tab') {
      columnSeparator = '	';
    } else if (columnSeparatorOption === 'comma') {
      columnSeparator = ',';
    }

    if (!columnSeparator) {
      formErrors.customColumnSeparator = { type: 'required' };
    }
    if (!rowSeparator) {
      formErrors.customRowSeparator = { type: 'required' };
    }
    if (!text) {
      formErrors.text = { type: 'required' };
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setView('form');
      return;
    }

    const cards: CardData[] = [];
    const rowSeparatorFixed = rowSeparator.split('\\n').join('\n');
    const columnSeparatorFixed = columnSeparator.split('\\n').join('\n');

    const rows: string[] = text.split(rowSeparatorFixed);

    rows.forEach(row => {
      const rowParts = row.split(columnSeparatorFixed);
      if (rowParts[0] && rowParts[1]) {
        cards.push({
          id: uuid(),
          question: serializeDocument(generateRichTextDocument([rowParts[0]])),
          answer: serializeDocument(generateRichTextDocument([rowParts[1]])),
        });
      }
    });

    if (cards.length === 0) {
      setErrors({
        text: {
          message:
            'Error parsing cards. Please ensure that you have set the correct row and column delimiters.',
        },
      });
      setView('form');
      return;
    }

    setCount(cards.length);

    await createCards(cards);

    setView('done');
  }

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="xs" {...other}>
      <DialogTitle>Import from Quizlet</DialogTitle>
      <DialogContent>
        {view === 'form' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <FormControl>
                <FormLabel>Between term and definition</FormLabel>
                <RadioGroup
                  aria-label="Between term and definition"
                  name="columnSeparator"
                  value={columnSeparatorOption}
                  onChange={(event, value) => setColumnSeparatorOption(value)}
                >
                  <FormControlLabel
                    value="tab"
                    control={<Radio />}
                    label="Tab"
                  />
                  <FormControlLabel
                    value="comma"
                    control={<Radio />}
                    label="Comma"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label={
                      <TextField
                        className={classes.customTextField}
                        size="small"
                        helperText="Custom"
                        name="columnSeparatorCustom"
                        id="columnSeparatorCustom-field"
                        value={customColumnSeparator}
                        error={errors.customColumnSeparator}
                        onChange={event =>
                          setCustomColumnSeparator(event.target.value)
                        }
                        onFocus={() => setColumnSeparatorOption('custom')}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <FormLabel>Between rows</FormLabel>
                <RadioGroup
                  aria-label="Between rows"
                  name="rowSeparator"
                  value={rowSeparatorOption}
                  onChange={(event, value) => setRowSeparatorOption(value)}
                >
                  <FormControlLabel
                    value="new-line"
                    control={<Radio />}
                    label="New line"
                  />
                  <FormControlLabel
                    value="semicolon"
                    control={<Radio />}
                    label="Semicolon"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label={
                      <TextField
                        className={classes.customTextField}
                        size="small"
                        helperText="Custom"
                        name="rowSeparatorCustom"
                        id="rowSeparatorCustom-field"
                        value={customRowSeparator}
                        error={errors.customRowSeparator}
                        onChange={event =>
                          setCustomRowSeparator(event.target.value)
                        }
                        onFocus={() => setRowSeparatorOption('custom')}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Paste the text from Quizlet below.</FormLabel>
                <TextField
                  multiline
                  rows={6}
                  name="text"
                  id="text-input"
                  value={text}
                  onChange={event => setText(event.target.value)}
                  error={errors.text}
                  // InputBaseProps={{
                  //   className: classes.textarea,
                  // }}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        {view === 'loading' && <ImportFromQuizletCreatingCards count={count} />}
        {view === 'done' && <ImportFromQuizletDone count={count} />}
      </DialogContent>
      {view === 'form' && (
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Import
          </Button>
        </DialogActions>
      )}
      {view === 'done' && (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
