import { DB, DocumentReference } from '@21st-night/utils';
import { updateDeckCard } from '../updateDeckCard';

export function removeSubcategoryFromCard(
  db: DB,
  deckRef: DocumentReference,
  cardId: string,
  subcategory: string,
): Promise<void> {
  return updateDeckCard(deckRef, cardId, {
    subcategories: db.arrayRemove(subcategory),
  });
}
