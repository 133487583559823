import React from 'react';
import { makeStyles, createStyles } from '@21st-night/styles';
import {
  CheckCircleOutline,
  WarningCircleOutline,
  ErrorCircleOutline,
} from '@21st-night/icons';
import { List, ListItem, ListItemIcon, ListItemText } from '@21st-night/ui';
import { CardData } from '@21st-night/cards';
import { CardMediaMatches, ParseResults } from './ImportFromCsv.types';

export type MediaFileStatus = 'none' | 'uploading' | 'processing' | 'ready';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minHeight: 300,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    error: {
      color: '#D50000',
    },
    warning: {
      color: '#FF6D00',
    },
    success: {
      color: '#00C853',
    },
  }),
);

function printRowNumbers(cards: (CardData | CardMediaMatches)[]): string {
  let rowNumbers = '';

  cards.forEach((card, index) => {
    rowNumbers = `${rowNumbers}${index !== 0 ? ', ' : ''}${card.row}`;
  });

  return rowNumbers;
}

export const ImportFromCsvConfirmation: React.FC<ParseResults> = ({
  success,
  missingAnswer,
  missingQuestion,
  missingMedia,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List>
        {success.length > 0 && (
          <ListItem divider>
            <ListItemIcon>
              <CheckCircleOutline color="inherit" className={classes.success} />
            </ListItemIcon>
            <ListItemText>
              {success.length} card{success.length > 1 ? 's' : ''} successfully
              parsed
            </ListItemText>
          </ListItem>
        )}
        {missingMedia.length > 0 && (
          <ListItem divider>
            <ListItemIcon>
              <WarningCircleOutline
                color="inherit"
                className={classes.warning}
              />
            </ListItemIcon>
            <ListItemText
              secondary={`${missingMedia.length === 1 ? 'This' : 'These'} card${
                missingMedia.length !== 1 ? 's' : ''
              } will be created without media: row ${printRowNumbers(
                missingMedia,
              )}`}
            >
              {missingMedia.length} card
              {missingMedia.length > 1 ? 's are' : ' is'} missing media
            </ListItemText>
          </ListItem>
        )}
        {missingQuestion.length > 0 && (
          <ListItem divider>
            <ListItemIcon>
              <ErrorCircleOutline color="inherit" className={classes.error} />
            </ListItemIcon>
            <ListItemText
              secondary={`${
                missingQuestion.length === 1 ? 'This' : 'These'
              } card${
                missingQuestion.length !== 1 ? 's' : ''
              } will be skipped: row ${printRowNumbers(missingQuestion)}`}
            >
              {missingQuestion.length} card
              {missingQuestion.length > 1 ? 's are' : ' is'} missing a question
            </ListItemText>
          </ListItem>
        )}
        {missingAnswer.length > 0 && (
          <ListItem divider>
            <ListItemIcon>
              <ErrorCircleOutline color="inherit" className={classes.error} />
            </ListItemIcon>
            <ListItemText
              secondary={`${
                missingAnswer.length === 1 ? 'This' : 'These'
              } card${
                missingAnswer.length !== 1 ? 's' : ''
              } will be skipped: row ${printRowNumbers(missingAnswer)}`}
            >
              {missingAnswer.length} card
              {missingAnswer.length > 1 ? 's are' : ' is'} missing an answer
            </ListItemText>
          </ListItem>
        )}
      </List>
    </div>
  );
};
