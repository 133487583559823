import { OfficialDeck } from '../../Deck.types';
import { generateDeck } from '../generateDeck';

export function generateOfficialDeck(
  options: Partial<OfficialDeck> = {},
): OfficialDeck {
  return {
    published: false,
    premium: false,
    files: [],
    ...generateDeck('template'),
    ...options,
  };
}
